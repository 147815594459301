import { Injectable, Component, ElementRef, NgZone, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({ providedIn: 'root'})
export class PageTitleService {
    title: string = '';
    private titleElement: HTMLElement;
    private siteName: string = 'SureWx';

    setTitle(title) {
        this.title = title;
        document.title = title + ' | ' + this.siteName;
        if (this.titleElement) {
            this.titleElement.innerText = title;
        }
    }

    registerTitleElement(element: HTMLElement) {
        this.titleElement = element;
        this.titleElement.innerText = this.title ?? "";
    }

    setSiteName(name: string) {
        this.siteName = name;
        this.setTitle(this.title);
    }
}

@Component({
    selector: 'page-title',
    template: `<span style="display: none;"><ng-content></ng-content></span>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTitleComponent implements OnDestroy, OnInit {
    subscription: Subscription;
    updateTimeout?: any;
    element: HTMLElement;

    constructor(
        private elementRef: ElementRef,
        private ngZone: NgZone,
        private pageTitleService: PageTitleService
    ) {
    }
    
    ngOnInit() {
        this.element = this.elementRef.nativeElement.querySelector('span');

        this.subscription = this.ngZone.onStable
            .subscribe(() => this.updateTitle());
        
        this.updateTitle();
    }
    
    private updateTitle() {
        if (!this.updateTimeout) {
            this.updateTimeout = setTimeout(() => {
                this.pageTitleService.setTitle(this.element.innerText.trim());
                this.updateTimeout = null;
            }, 50);
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
