<div class="noMargins">
    <page-title>{{'Portal logins'|translate}}</page-title>
    <form #form="ngForm" class="toolbar gridFilters">
        <a class="tab" (click)="switchTab('map')" [ngClass]="{active : tab == 'map'}">{{'Map'}}</a>
        <a class="tab" (click)="switchTab('log')" [ngClass]="{active : tab == 'log'}">{{'Log'}}</a>
        
        <label class="inline">
            <span class="caption">{{'Client'|translate}}</span>
            <multiselect [(ngModel)]="query.ClientId" name="ClientId">
                <multiselect-option *ngFor="let client of clients|orderBy:'Name'" [value]="client.Id" [name]="client.Name"></multiselect-option>
            </multiselect>
        </label>
        <label class="inline">
            <span class="caption">{{'User'|translate}}</span>
            <multiselect [(ngModel)]="query.UserId" name="UserId">
                <multiselect-option *ngFor="let user of users|orderBy:'Name'" [value]="user.Id" [name]="user.Email"></multiselect-option>
            </multiselect>
        </label>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" required placeholder="Start Date" name="query.FromDate">
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
        </label>
        <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
        <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button type="button" (click)="apply()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid" ><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
    </form>

    <div [hidden]="tab === 'log'" class="userMap" #userMap></div>
    <grid [hidden]="tab === 'map'" #grid [resource]="resource" [exportResource]="exportResourcePost" [columnDefs]="columnDefs" gridId="" [query]="query" [headerHeight]="96"></grid>
</div>
