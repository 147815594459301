import {Injectable} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {Credentials} from "./Credentials.model";
import {Observable} from "rxjs";
import {TokenDto} from "./TokenDto.model";
import {AuthConfig} from "./AuthConfig.service";

@Injectable()
export class OAuthService {
    constructor(private config: AuthConfig, private http: HttpClient) {
    }

    login(credentials: Credentials): Observable<TokenDto> {
        const data = this.formEncodeCredentials(credentials);

        return this.http.post<TokenDto>(this.config.tokenUrl, data);
    }

    private formEncodeCredentials(credentials: Credentials): HttpParams {
        return new HttpParams()
            .set('grant_type', 'password')
            .set('username', credentials.username)
            .set('password', credentials.password);
    }
}
