<page-title>{{('Most recent weather type count profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('weatherTypes')" [class.active]="tab == 'weatherTypes'" [class.invalid]="!ngForm.form.controls.weatherTypesForm?.valid">{{'Weather types'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditWeatherTypeCounts'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100"/>
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active"/>
        </label>
    </fieldset>

    <fieldset class="form" [disabled]="!('ConfigEditWeatherTypeCounts'|hasPermission)" [hidden]="tab != 'weatherTypes'" ngModelGroup="weatherTypesForm">
        <table class="table" *ngIf="counts != null">
            <tr>
                <td></td>
                <th [attr.colspan]="(weatherTypes|keys).length">{{'Most recent weather type'|translate}}</th>
            </tr>
            <tr>
                <th>{{'Instant weather type'|translate}}</th>
                <th *ngFor="let mrwt of weatherTypes|keys"><span title="{{weatherTypes[mrwt]}}">{{mrwt}}</span></th>
            </tr>
            <tr *ngFor="let iwt of weatherTypes|keys">
                <th><span title="{{weatherTypes[iwt]}}">{{iwt}}</span></th>
                <td *ngFor="let mrwt of weatherTypes|keys"><input *ngIf="iwt != mrwt" type="number" required min="1" step="1" style="width: 30px;" [(ngModel)]="counts[iwt][mrwt].Count" [name]="'counts[' + iwt + '][' + mrwt + '].Count'" /></td>
            </tr>
        </table>
    </fieldset>
</form>
