<div class="noMargins">
    <page-title>{{'Finnair inits'|translate}}</page-title>
    <form #form="ngForm" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Client'|translate}}</span>
            <multiselect [(ngModel)]="query.ClientId" name="ClientId">
                <multiselect-option *ngFor="let client of clients|orderBy:'Name'" [value]="client.Id" [name]="client.Name"></multiselect-option>
            </multiselect>
        </label>
        <label class="inline">
            <span class="caption">{{'Airport'|translate}}</span>
            <multiselect [(ngModel)]="query.AirportId" name="query.AirportId" >
                <multiselect-option *ngFor="let airport of airports|orderBy:['-HasStation','ICAOCode']" [value]="airport.Id" [name]="airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name"></multiselect-option>
            </multiselect>
        </label>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" required placeholder="Start Date" name="query.FromDate">
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
        </label>
        <filters name="query.Filters" [(ngModel)]="query.Filters" [columnDefs]="columnDefs"></filters>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button type="button" (click)="apply()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        </label>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
    </form>
    <grid #grid [resource]="resource" [exportResource]="exportResourcePost" [columnDefs]="columnDefs" gridId="finnairInits" [query]="query" [headerHeight]="96" [parent]="this"></grid>
</div>
