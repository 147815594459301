<div class="noMargins" *ngIf="vm">
    <page-title>{{'Client API'|translate}}</page-title>
    <form class="contentMargins form" name="form" #form="ngForm" (ngSubmit)="send(request.version)">
        <table class="table">
            <tr><th>Prod</th><td style="font-family: monospace;">https://api.surewx.com</td></tr>
            <tr><th>Test</th><td style="font-family: monospace;">https://test-api.surewx.com</td></tr>
            <tr><th>Local server</th><td style="font-family: monospace;">http://localhost:51515</td></tr>
        </table>
        <br />
        <label>
            <span class="caption">{{'Server'|translate}}</span>
            <input type="text" required [(ngModel)]="request.clientApiUrl" style="width: 400px;" name="request.clientApiUrl"/>
        </label>
        <label>
            <span class="caption">{{'API type'|translate}}</span>
            <select required [(ngModel)]="request.version" name="request.version">
                <option value="hotv1">{{'HOT API v1 (WEN)'}}</option>
                <option value="hotv1.5">{{'HOT API v1.5 (BRX)'}}</option>
                <option value="hotv2">{{'HOT API v2 (FDX)'}}</option>
                <option value="mobilehotv2">{{'Mobile HOT API v2'}}</option>
                <option value="mobilehotv3">{{'Mobile HOT API v3 (UAL)'}}</option>
                <option value="deicingreport">{{'Deicing report'}}</option>
                <option value="weather">{{'Weather'}}</option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Private hash'|translate}}</span>
            <input type="text" required [(ngModel)]="request.privateHash" style="width: 700px;" name="request.privateHash"/>
        </label>
        <label>
            <span class="caption">{{'Public hash'|translate}}</span>
            <input type="text" required [(ngModel)]="request.publicHash" style="width: 700px;" name="request.publicHash"/>
        </label>
        <label>
            <span class="caption">{{'Client/airline code'|translate}}</span>
            <input type="text" required [(ngModel)]="request.clientCode" name="request.clientCode"/>
        </label>

        <div *ngIf="request.version === 'hotv1' || request.version === 'weather'">
            <label>
                <span class="caption">{{'Airport ICAO code'|translate}}</span>
                <input type="text" required [(ngModel)]="request.airportCode" maxlength="4" name="request.airportCode"/>
            </label>
        </div>

        <div *ngIf="request.version === 'hotv1.5' || request.version === 'hotv2' || request.version === 'mobilehotv2'">
            <label>
                <span class="caption">{{'Airport ICAO code'|translate}}</span>
                <input type="text" required [(ngModel)]="request.airportCode" maxlength="4" name="request.airportCode"/>
            </label>
            <label>
                <span class="caption">{{'Aircraft tail number'|translate}}</span>
                <input type="text" [(ngModel)]="request.tailNumber" name="request.tailNumber"/>
            </label>
            <label>
                <span class="caption">{{'Call sign'|translate}}</span>
                <input type="text" [(ngModel)]="request.callSign" name="request.callSign"/>
            </label>
            <label>
                <span class="caption">{{'ICAO Equipment type'|translate}}</span>
                <input type="text" [(ngModel)]="request.equipmentTypeCode" name="request.equipmentTypeCode"/>
            </label>
            <div class="field">
                <span class="caption">{{'Wing Surface Types'|translate}}</span>
                <div [(ngModel)]="request.wingMaterialTypes" name="request.wingMaterialTypes">
                    <label *ngFor="let wmt of vm.wingMaterialTypes|keyvalue">
                        <input type="checkbox" [checked]="isWingMaterialTypeEnabled(wmt.key)" (click)="toggleWingMaterialType(wmt.key, form.form)" />{{wmt.value}}
                    </label>
                </div>
            </div>
            <label>
                <span class="caption">{{'Count'|translate}}</span>
                <input type="number" [(ngModel)]="request.count" min="1" step="1" name="request.count"/>
            </label>
            <label>
                <span class="caption">{{'Interval (minutes)'|translate}}</span>
                <input type="number" [(ngModel)]="request.interval" min="1" step="1" name="request.interval"/>
            </label>
        </div>

        <div *ngIf="request.version === 'mobilehotv3'">
            <label>
                <span class="caption">{{'Airport ICAO code'|translate}}</span>
                <input type="text" required [(ngModel)]="request.airportCode" maxlength="4" name="request.airportCode"/>
            </label>
            <label>
                <span class="caption">{{'Aircraft tail number'|translate}}</span>
                <input type="text" [(ngModel)]="request.tailNumber" name="request.tailNumber"/>
            </label>
            <label>
                <span class="caption">{{'Call sign'|translate}}</span>
                <input type="text" [(ngModel)]="request.callSign" name="request.callSign"/>
            </label>
            <label>
                <span class="caption">{{'ICAO Equipment type'|translate}}</span>
                <input type="text" [(ngModel)]="request.equipmentTypeCode" name="request.equipmentTypeCode"/>
            </label>
        </div>

        <div *ngIf="request.version === 'deicingreport'">
            <label>
                <span class="caption">{{'HOT timer start time'|translate}}</span>
                <input date-time [options]="datePickerOptions" required [(ngModel)]="request.startTime" name="request.startTime" format="YYYY-MM-DDTHH:mm:ss[Z]" displayFormat="YYYY-MM-DD HH:mm:ss"> Z
            </label>
            <label>
                <span class="caption">{{'HOT response #'|translate}}</span>
                <input type="number" step="1" min="1" required [(ngModel)]="request.hotResponseId" name="request.hotResponseId"/>
            </label>
            <label>
                <span class="caption">{{'Fluid #'|translate}}</span>
                <input type="number" step="1" min="1" required [(ngModel)]="request.fluidId" name="request.fluidId"/>
            </label>
        </div>

        <br />
        <input type="submit" [disabled]="!form.valid"  [value]="'Send'|translate" />
    </form>
    <div class="contentMargins result" *ngIf="!!result">
        <h2>{{'Result'|translate}}</h2>
        <pre>{{result|json}}</pre>
    </div>
</div>

