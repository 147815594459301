import {Component, OnInit, Input, OnDestroy} from "@angular/core";
import {AppstoreconnectService} from "../appstoreconnect.service";
import { Subscription } from "rxjs";
import { JsonPipe, NgIf } from "@angular/common";

@Component({
    selector: 'swx-appstore-details',
    templateUrl: 'appDetails.component.html',
    standalone: true,
    imports: [NgIf, JsonPipe]
})
export class AppDetailsComponent implements OnInit, OnDestroy {
    @Input()
    set appId(value: string) {
        if (value) {
            this.getApp(value);
        } 
    }

    @Input() format: string;
    app: any = null;

    appDetailsSub: Subscription = null;
    
    constructor(private appstore: AppstoreconnectService,
                ) {
    }
    
    ngOnInit() {
        
    }

    ngOnDestroy() {
        if (this.appDetailsSub) this.appDetailsSub.unsubscribe;
    }
    
    getApp(id: string) {
        this.appDetailsSub = this.appstore.getAppDetails(id).subscribe(app => this.app = app)
    }
}
