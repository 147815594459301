<page-title>{{'Station status'|translate}}</page-title>
<div id="stationStatusPage" class="noMargins">
    <form (submit)="refresh()" #form="ngForm">
        <div class="toolbar">
            <a class="tab" (click)="switchTab('tiles')" [class.active]="tab == 'tiles'"><span class="mdi mdi-view-grid"></span> {{'Tiles'|translate}}</a>
            <a class="tab" (click)="switchTab('map')" [class.active]="tab == 'map'"><span class="mdi mdi-map-marker"></span> {{'Map'|translate}}</a>
            <a class="tab" (click)="switchTab('alarms')" [class.active]="tab == 'alarms'"><span class="mdi mdi-history"></span> {{'Alarm history'|translate}}</a>

            <label class="inline stationSearch" [hidden]="tab === 'alarms'">
                <span class="mdi mdi-magnify"></span>
                <input type="text" #stationSearchElement name="stationSearch" [(ngModel)]="stationSearch" />
                <div class="button mdi mdi-chevron-down"></div>
            </label>
            <ng-container *ngIf="tab !== 'alarms'">
                <label class="inline">
                    <select [required]="tab !== 'alarms'" [(ngModel)]="options.RefreshInterval" name="options.RefreshInterval" (ngModelChange)="updateRefreshInterval()">
                        <option value="15">{{'15 seconds'|translate}}</option>
                        <option value="60">{{'1 minute'|translate}}</option>
                        <option value="300">{{'5 minutes'|translate}}</option>
                        <option value="600">{{'10 minutes'|translate}}</option>
                    </select>
                </label>
                <button type="button" (click)="refresh()"><span class="mdi mdi-refresh"></span> {{'Refresh now'|translate}}</button>
                <label>
                    <span class="caption">{{'Client'|translate}}</span>
                    <select [(ngModel)]="options.ClientId" name="options.ClientId" [required]="tab !== 'alarms'" (ngModelChange)="refresh()" [selectedIndex]="-1">
                        <option *ngFor="let client of clients|orderBy:'Name'" [ngValue]="client.Id">
                            {{client.Name}}
                        </option>
                    </select>
                </label>
            </ng-container>
            <ng-container *ngIf="tab === 'alarms'">
                <label>
                    <span class="caption">{{'Station'|translate}}</span>
                    <multiselect name="query.StationId" [(ngModel)]="query.StationId">
                        <multiselect-option *ngFor="let option of stations|orderBy:'Name'" [value]="option.Id" [name]="option.Name"></multiselect-option>
                    </multiselect>
                </label>
                <label class="inline">
                    <input type="checkbox" [(ngModel)]="query.MinimumCountReached" name="query.MinimumCountReached"/>
                    <span class="caption">{{'Minimum count reached'|translate}}</span>
                </label>
                <label class="inline">
                    <span class="caption">{{'From'|translate}}</span>
                    <input date required [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="query.FromDate"/>
                </label>
                <label class="inline">
                    <span class="caption">{{'To'|translate}}</span>
                    <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate"/>
                </label>
                <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
                <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
                <button type="button" (click)="refresh()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
                <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
                <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid" ><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
            </ng-container>
        </div>
    </form>


    <grid [hidden]="tab !== 'alarms'" #grid
          gridId="preferences.StationProblem.ColumnExport"
          [query]="query"
          [resource]="api.StationProblem"
          [exportResource]="api.StationProblemExport"
          [columnDefs]="columnDefs">
    </grid>
    
    <div class="wrapper" [hidden]="tab === 'alarms'">
        <div class="stations" #leftPane>
            <div *ngIf="tab === 'tiles'" class="stationTiles">
                <div *ngFor="let stationInfo of stationInfos|orderBy:'station.Name'" class="station" (click)="selectStationInfo(stationInfo)" [class.selected]="selectedStationInfo?.station.Id === stationInfo.station.Id">
                    <div class="stationCodeWrapper" [class]="stationInfo.cssClass">
                        <span class="stationCode">
                            <span class="stationName">{{stationInfo.station.Name}}</span>
                            <span class="stationTag">{{stationInfo.station.StationStatusTag}}</span>
                        </span>
                        <span class="statusIcons">
                            <span class="mdi mdi-message" *ngIf="stationInfo.stationState.Status === 'No messages received'" title="{{'No messages received'|translate}}"><span class="mdi mdi-wifi-off"></span></span>
                            <span class="mdi mdi-message" *ngIf="stationInfo.station.MessageSent" title="{{'Message sent'|translate}}"><span class="code">✉</span></span>
                            <span class="mdi barrelIcing mdi-message" *ngIf="stationInfo.stationState.BarrelIcingConditionsPresent" title="{{'Barrel icing'|translate}}"><span class="code">BI</span></span>
                            <span class="mdi activeFrost mdi-message" *ngIf="stationInfo.stationState.ActiveFrostConditionsPresent" title="{{'Active frost'|translate}}"><span class="code">AF</span></span>
                        </span>
                    </div>
                    <div class="weather">
                        <station-weather-icon [stationInfo]="stationInfo"></station-weather-icon>
                        <div class="weatherInfo">
                            <div *ngIf="stationInfo.stationState.WeatherProfile && stationInfo.stationState.WeatherProfile.Temperature != null" class="temperature">{{stationInfo.stationState.WeatherProfile.Temperature|number:"1.1-1"}} &deg;C</div>
                            <div *ngIf="stationInfo.stationState.WeatherProfile && stationInfo.stationState.WeatherProfile.WindSpeed != null" class="windSpeed">{{stationInfo.stationState.WeatherProfile.WindSpeed|number:"1.1-1"}} m/s</div>
                        </div>
                    </div>
                    <div class="hots" *ngIf="!stationInfo.stationState.HotResponse.MetarUsageReason">
                        <div class="hot type1" *ngIf="stationInfo.stationState.Type1Hot?.NonHotMessage && !stationInfo.stationState.Type1Hot.MinHot && !stationInfo.stationState.IsClear" title="{{stationInfo.stationState.Type1Hot.NonHotMessage}}"><span class="{{'mdi ' + (stationInfo.stationState.Type1Hot.HotConditionIcon || 'mdi-alert')}}"></span></div>
                        <div class="hot type1" *ngIf="stationInfo.stationState.Type1Hot?.MinHot">{{stationInfo.stationState.Type1Hot.MinHot}}</div>
                        <div class="hot type2" *ngIf="stationInfo.stationState.Type2Hot?.NonHotMessage && !stationInfo.stationState.Type2Hot.MinHot && !stationInfo.stationState.IsClear" title="{{stationInfo.stationState.Type2Hot.NonHotMessage}}"><span class="{{'mdi ' + (stationInfo.stationState.Type2Hot.HotConditionIcon || 'mdi-alert')}}"></span></div>
                        <div class="hot type2" *ngIf="stationInfo.stationState.Type2Hot?.MinHot">{{stationInfo.stationState.Type2Hot.MinHot}}</div>
                        <div class="hot type3" *ngIf="stationInfo.stationState.Type3Hot?.NonHotMessage && !stationInfo.stationState.Type3Hot.MinHot && !stationInfo.stationState.IsClear" title="{{stationInfo.stationState.Type3Hot.NonHotMessage}}"><span class="{{'mdi ' + (stationInfo.stationState.Type3Hot.HotConditionIcon || 'mdi-alert')}}"></span></div>
                        <div class="hot type3" *ngIf="stationInfo.stationState.Type3Hot?.MinHot">{{stationInfo.stationState.Type3Hot.MinHot}}</div>
                        <div class="hot type4" *ngIf="stationInfo.stationState.Type4Hot?.NonHotMessage && !stationInfo.stationState.Type4Hot.MinHot && !stationInfo.stationState.IsClear" title="{{stationInfo.stationState.Type4Hot.NonHotMessage}}"><span class="{{'mdi ' + (stationInfo.stationState.Type4Hot.HotConditionIcon || 'mdi-alert')}}"></span></div>
                        <div class="hot type4" *ngIf="stationInfo.stationState.Type4Hot?.MinHot">{{stationInfo.stationState.Type4Hot.MinHot}}</div>
                    </div>
                    <div class="metarUsageReason" *ngIf="stationInfo.stationState.HotResponse.MetarUsageReason && stationInfo.stationState.HotResponse.MetarUsageReason !== 'Station under maintenance'">
                        {{stationInfo.stationState.HotResponse.MetarUsageReason}}
                    </div>
                    <div class="info">
                        <span *ngIf="getStationTileProblem(stationInfo) != null" class="stationProblemLevel" [class]="getStationTileProblem(stationInfo).Level">{{stationProblemTypes[getStationTileProblem(stationInfo).Type]}}</span>
                        <span *ngIf="getStationTileProblem(stationInfo) == null">
                            <span *ngIf="stationInfo.stationState.WeatherProfile?.Errors">{{weatherProfileErrors[stationInfo.stationState.WeatherProfile.Errors]}}</span>
                        </span>
                    </div>
                </div>
            </div>
            <station-status-legend *ngIf="tab === 'tiles'"></station-status-legend>

            <div [hidden]="tab !== 'map'" class="stationMap" #stationMap></div>
        </div>

        <div class="border" #border [hidden]="selectedStationInfo == null"></div>

        <div class="details" #rightPane [hidden]="selectedStationInfo == null">
            <div *ngIf="selectedStationInfo != null">
                <div class="stationCodeWrapper">
                    <span class="stationCode" [class]="selectedStationInfo.cssClass">{{selectedStationInfo.station.Name}}</span>
                    <station-weather-icon *ngIf="selectedStationInfo.stationState != null" [stationInfo]="selectedStationInfo"></station-weather-icon>
                    <div class="stationState" *ngIf="selectedStationInfo.stationState != null">
                        <div class="status" *ngIf="selectedStationInfo.stationState.Status">{{selectedStationInfo.stationState.Status}} ({{selectedStationInfo.stationState.StatusChangeDate|utcDateTimeFormat:$root.dateTimeFormat}})</div>
                        <div class="metarUsageReason">{{selectedStationInfo.stationState.HotResponse.MetarUsageReason}}</div>
                    </div>
                </div>
                <portlets *ngIf="selectedStationInfo.stationState != null">
                    <portlet name="basic_info" title="{{'Basic Info'|translate}}">
                        <div class="form">
                            <label>
                                <span class="caption">{{'Last station reading date'|translate}}</span>
                                <span>{{selectedStationInfo.stationState.LastStationReadingDate|utcDateTimeFormat:$root.dateTimeFormat}}</span>
                            </label>
                            <span class="field" *ngIf="selectedStationInfo.station.StationLoggerType === 'SureLogger2'">
                                <span class="caption">{{'Serial maintenance mode?'|translate}}</span>
                                <span>{{selectedStationInfo.stationState.SerialMaintenanceMode == null ? '' : (selectedStationInfo.stationState.SerialMaintenanceMode ? 'Enabled' : 'Disabled')}} <input type="button" *ngIf="('DataStationStatusSureLoggerControl'|hasPermission)" (click)="updateSureLoggerSerialMaintenanceMode(!selectedStationInfo.stationState.SerialMaintenanceMode)" [value]="((selectedStationInfo.stationState.SerialMaintenanceMode ? 'Disable' : 'Enable')|translate)" /></span>
                            </span>
                            <label>
                                <span class="caption">{{'Sensor select matrix'|translate}}</span>
                                <span *ngIf="selectedStationInfo.stationState.WeatherProfile?.SensorSelectMatrixId">{{(sensorSelectMatrices|findById:selectedStationInfo.stationState.WeatherProfile.SensorSelectMatrixId)?.Name}}</span>
                            </label>
                            <label>
                                <span class="caption">{{'Weather type sensor mapping order'|translate}}</span>
                                <span *ngIf="selectedStationInfo.stationState.WeatherProfile?.SensorSelectMatrixWeatherTypeSensorMappingOrder">{{selectedStationInfo.stationState.WeatherProfile?.SensorSelectMatrixWeatherTypeSensorMappingOrder}}</span>
                            </label>
                            <label>
                                <span class="caption">{{'Intensity sensor mapping order'|translate}}</span>
                                <span *ngIf="selectedStationInfo.stationState.WeatherProfile?.SensorSelectMatrixIntensitySensorMappingOrder">{{selectedStationInfo.stationState.WeatherProfile?.SensorSelectMatrixIntensitySensorMappingOrder}}</span>
                            </label>
                            <label>
                                <span class="caption">{{'Last tick'|translate}}</span>
                                <span>{{selectedStationInfo.stationState.LastTick|utcDateTimeFormat:$root.dateTimeFormat}} in {{selectedStationInfo.stationState.TickDuration}} ms</span>
                            </label>
                            <label>
                                <span class="caption">{{'Tick queue depth'|translate}}</span>
                                <span>{{selectedStationInfo.stationState.TickQueueDepth}}</span>
                            </label>
                            <label>
                                <span class="caption">{{'Weather'|translate}}</span>
                                <span class="weather">
                                    <span *ngIf="selectedStationInfo.stationState.WeatherProfile?.WeatherCategory != null">{{selectedStationInfo.stationState.WeatherProfile?.WeatherCategory}}</span>
                                    <span *ngIf="selectedStationInfo.stationState.WeatherProfile?.LWE != null">{{selectedStationInfo.stationState.WeatherProfile?.LWE|number:"1.0-0"}} g/dm²/h</span>
                                    <span *ngIf="selectedStationInfo.stationState.WeatherProfile?.Temperature != null">{{selectedStationInfo.stationState.WeatherProfile?.Temperature|number:"1.1-1"}} °C</span>
                                    <span *ngIf="selectedStationInfo.stationState.WeatherProfile?.WindSpeed != null">{{selectedStationInfo.stationState.WeatherProfile?.WindSpeed|number:"1.1-1"}} m/s</span>
                                </span>
                            </label>
                            <label *ngIf="selectedStationInfo.stationState.WeatherProfile?.Errors != null">
                                <span class="caption">{{'Weather profile errors'|translate}}</span>
                                <span *ngIf="selectedStationInfo.stationState.WeatherProfile?.Errors != null">{{weatherProfileErrors[selectedStationInfo.stationState.WeatherProfile?.Errors]}}</span>
                            </label>
                            <label *ngIf="selectedStationInfo.station.UnderMaintenance && selectedStationInfo.station.UnderMaintenanceNotes">
                                <span class="caption">{{'Under maintenance notes'|translate}}</span>
                                {{selectedStationInfo.station.UnderMaintenanceNotes}}
                            </label>
                        </div>
                    </portlet>

                    <portlet name="connections" title="{{'Connections'|translate}}">
                        <table class="table" style="margin-bottom: 20px;">
                            <tr>
                                <th style="text-align: left;">{{'Connection'|translate}}</th>
                                <th style="text-align: left;" *ngIf="selectedStationInfo.station.StationLoggerType === 'SerialOverTCP'">{{'Serial port'|translate}}</th>
                                <th style="text-align: left;">{{'Status'|translate}}</th>
                                <th style="text-align: left;">{{'Since'|translate}}</th>
                            </tr>
                            <tr *ngFor="let connection of selectedStationInfo.stationState.Connections">
                                <td>{{(connection.ConnectionType == 'TCPClient' || connection.ConnectionType == 'TCPXMLPolling' ? "tcp" : (connection.ConnectionType == 'HTTPChannelsPolling' ? "http" : (connection.ConnectionType == 'SSHClient' ? "ssh" : "mqtt")))}}://{{connection.IP}}:{{connection.Port}}{{(connection.ConnectionType == 'MQTT' ? ('/' + connection.Topic) : '')}}</td>
                                <td *ngIf="selectedStationInfo.station.StationLoggerType === 'SerialOverTCP'">{{connection.SerialPortNumber}}</td>
                                <td>{{connection.Status}}</td>
                                <td>{{connection.StatusChangeDate|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                            </tr>
                        </table>
                    </portlet>

                    <portlet name="log" (openChanged)="logOpenChanged($event)" title="{{'Log'|translate}}" *ngIf="('DataStationStatusSureLoggerControl'|hasPermission) && selectedStationInfo.station.StationLoggerType === 'SerialOverTCP'">
                        <div class="form">
                            <textarea readonly [value]="sureLoggerLog" style="width: 100%; font-family: monospace; white-space: pre;" rows="50" spellcheck="false"></textarea>
                        </div>
                    </portlet>

                    <!--<portlet name="sureLoggerConfiguration" (openChanged)="powerRelayModuleOpenChanged($event)" title="{{'Power relay module'|translate}}" *ngIf="('DataStationStatusSureLoggerControl'|hasPermission) && selectedStationInfo.station.StationLoggerType === 'SureLogger2'">
                        
                    </portlet>-->
                    
                    <portlet name="sureLoggerConfiguration" (openChanged)="sureLoggerConfigurationOpenChanged($event)" title="{{'SureLogger configuration'|translate}}" *ngIf="('DataStationStatusSureLoggerControl'|hasPermission) && selectedStationInfo.station.StationLoggerType === 'SureLogger2'">
                        <form class="form" sureLoggerConfigurationForm="ngForm" (submit)="updateSureLoggerConfiguration()">
                            <textarea required [value]="sureLoggerConfigurationQuery.configuration" style="width: 100%; font-family: monospace; white-space: pre;" rows="30" spellcheck="false"></textarea>
                            <label>
                                <!--<input type="submit" [disabled]="!sureLoggerConfigurationForm.form.valid" value="{{'Send'|translate}}" />-->
                            </label>
                        </form>
                    </portlet>

                    <portlet name="sensors" (openChanged)="sensorsOpenChanged($event)" title="{{'Sensors'|translate}}" *ngIf="('DataStationStatusSureLoggerControl'|hasPermission) && selectedStationInfo.station.StationLoggerType === 'SerialOverTCP'">
                        <table class="table" *ngIf="sureLoggerStatus?.Sensors != null">
                            <tr>
                                <th>{{'Id'|translate}}</th>
                                <th>{{'Read start'|translate}}</th>
                                <th>{{'Read end'|translate}}</th>
                                <th>{{'Last response'|translate}}</th>
                            </tr>
                            <tr *ngFor="let identifier of sureLoggerStatus.Sensors|keys">
                                <td>{{identifier}}</td>
                                <td><span *ngIf="sureLoggerStatus.Sensors[identifier].Start">{{sureLoggerStatus.Sensors[identifier].Start|utcDateTimeFormat:'YYYY-MM-DD HH:mm:ss'}}Z</span></td>
                                <td><span *ngIf="sureLoggerStatus.Sensors[identifier].End">{{sureLoggerStatus.Sensors[identifier].End|utcDateTimeFormat:'YYYY-MM-DD HH:mm:ss'}}Z</span></td>
                                <td><pre>{{formatRawString(sureLoggerStatus.Sensors[identifier].Output)}}</pre></td>
                            </tr>
                        </table>
                    </portlet>

                    <portlet (openChanged)="serialPortsOpenChanged($event)" name="serialPorts" title="{{'Serial ports'|translate}}" *ngIf="('DataStationStatusSureLoggerControl'|hasPermission) && selectedStationInfo.station.StationLoggerType === 'SerialOverTCP'">
                        <table class="table" *ngIf="sureLoggerStatus?.SerialPorts != null">
                            <tr>
                                <th>{{'Port'|translate}}</th>
                                <th>{{'Open?'|translate}}</th>
                                <th>{{'Enabled?'|translate}}</th>
                                <th>{{'Last command'|translate}}</th>
                                <th>{{'Last response'|translate}}</th>
                            </tr>
                            <tr *ngFor="let serialPortState of sureLoggerStatus.SerialPorts">
                                <td>{{serialPortState.PortName}}</td>
                                <td>{{serialPortState.IsOpen ? 'Yes' : 'No'}}</td>
                                <td>
                                    {{serialPortState.Enabled ? 'Yes' : 'No'}}
                                    <button type="button" *ngIf="!serialPortState.Enabled" (click)="setSerialBusEnabled(serialPortState.Id, true)">{{'Enable'|translate}}</button>
                                    <button type="button" *ngIf="serialPortState.Enabled" (click)="setSerialBusEnabled(serialPortState.Id, false)">{{'Disable'|translate}}</button>
                                </td>
                                <td><pre>{{formatRawString(serialPortState.LastCommand)}}</pre></td>
                                <td><pre>{{formatRawString(serialPortState.LastResponse)}}</pre></td>
                            </tr>
                        </table>

                        <h3>{{'Send command'|translate}}</h3>
                        <form class="form" #serialCommandForm="ngForm" (submit)="sendSerialCommand()" *ngIf="sureLoggerStatus?.SerialPorts != null">
                            <span class="field">
                                <span class="caption">{{'Port'|translate}}</span>
                                <select required [(ngModel)]="serialCommandQuery.serialPortId" name="serialCommandQuery.serialPortId">
                                    <option [ngValue]="null">{{'None'|translate}}</option>
                                    <option *ngFor="let option of sureLoggerStatus.SerialPorts" [ngValue]="option.Id">
                                        {{option.PortName}}
                                    </option>
                                </select>
                            </span>
                            <label>
                                <span class="caption">{{'Command'|translate}}</span>
                                <span><input type="text" required [(ngModel)]="serialCommandQuery.command" name="serialCommandQuery.command" style="width: 400px;"></span>
                            </label>
                            <label>
                                <span class="caption">{{'Timeout (ms)'|translate}}</span>
                                <span><input type="number" required min="1" [(ngModel)]="serialCommandQuery.timeoutMs" name="serialCommandQuery.timeoutMs" style="width: 100px;"></span>
                            </label>
                            <label>
                                <span class="caption">{{'Unescape raw command?'|translate}}</span>
                                <span><input type="checkbox" [(ngModel)]="serialCommandQuery.doUnescape" name="serialCommandQuery.doUnescape"></span>
                            </label>
                            <label>
                                <input type="submit" [disabled]="!serialCommandForm.form.valid" value="{{'Send'|translate}}" />
                            </label>
                        </form>

                        <h3>{{'Command history'|translate}}</h3>
                        <table class="table">
                            <tr>
                                <th>{{'Sent'|translate}}</th>
                                <th>{{'Command'|translate}}</th>
                                <th>{{'Response'|translate}}</th>
                            </tr>
                            <tr *ngFor="let commandInfo of serialCommands.slice().reverse()">
                                <td>{{commandInfo.DateTime|utcDateTimeFormat:'YYYY-MM-DD HH:mm:ss'}}</td>
                                <td><pre style="max-width: 400px; overflow: auto;">{{commandInfo.RawCommand}}</pre></td>
                                <td>
                                    <pre *ngIf="commandInfo.Error" style="max-width: 500px; overflow: auto; color: #990000">{{commandInfo.Error}}</pre>
                                    <pre *ngIf="commandInfo.Output" style="max-width: 500px; overflow: auto;">{{commandInfo.Output}}</pre>
                                </td>
                            </tr>
                        </table>
                    </portlet>

                    <portlet name="alarms" title="{{'Alarms'|translate}}">
                        <form class="form" #stationProblemUpdateForm="ngForm" (submit)="updateStationProblems()">
                            <table class="table" style="margin-bottom: 20px;">
                                <tr>
                                    <th style="text-align: left;">{{'Alarm'|translate}}</th>
                                    <th style="text-align: left;">{{'Count'|translate}}</th>
                                    <th style="text-align: left;">{{'Events per hour'|translate}}</th>
                                    <th style="text-align: left;">{{'Start'|translate}}</th>
                                    <th style="text-align: left;">{{'Last update'|translate}}</th>
                                    <th style="text-align: left;">{{'Details'|translate}}</th>
                                    <th style="text-align: left;">{{'Notes'|translate}}</th>
                                    <th style="text-align: left;"><input type="checkbox" (change)="toggleAllCleared($event.target['checked'])"> {{'Clear'|translate}}</th>
                                </tr>
                                <tr *ngFor="let stationProblem of selectedStationInfo.currentProblems">
                                    <td><span class="stationProblemLevel" [class]="stationProblem.Level">{{stationProblemTypes[stationProblem.Type]}}</span></td>
                                    <td>{{stationProblem.Count}}</td>
                                    <td>{{stationProblem.EventsPerHour}}</td>
                                    <td>{{stationProblem.DateTime|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                                    <td>{{stationProblem.LastUpdated|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                                    <td>{{stationProblem.Details}} <a *ngIf="stationProblem.RawData" class="mdi mdi-eye" (click)="showStationProblemRawData(stationProblem.RawData)"></a></td>
                                    <td>
                                        <input type="text" [(ngModel)]="getStationProblemUpdate(stationProblem).Notes" [name]="'stationProblemUpdate[' + stationProblem.Id + '].Notes'" style="width: 300px;">
                                    </td>
                                    <td>
                                        <input type="checkbox" [(ngModel)]="getStationProblemUpdate(stationProblem).Cleared" [name]="'stationProblemUpdate[' + stationProblem.Id + '].Cleared'">
                                    </td>
                                </tr>
                            </table>
                            <br />
                            <input type="submit" [disabled]="!stationProblemUpdateForm.form.valid || stationProblemUpdateForm.form.pristine" value="{{'Save'|translate}}" />
                        </form>
                    </portlet>

                    <portlet name="last_alert_email" title="{{'Last alert email'|translate}}">
                        <table class="table" style="margin-bottom: 20px;">
                            <tr>
                                <th style="text-align: left;">{{'Date'|translate}}</th>
                                <th style="text-align: left;">{{'Sent by'|translate}}</th>
                                <th style="text-align: left;">{{'Subject'|translate}}</th>
                                <th style="text-align: left;">{{'Client'|translate}}</th>
                                <th style="text-align: left;">{{'Contacts'|translate}}</th>
                            </tr>
                            <ng-container *ngIf="selectedStationInfo.stationState.LastStationAlert">
                                <tr *ngFor="let email of selectedStationInfo.stationState.LastStationAlert.Emails">
                                    <td>{{selectedStationInfo.stationState.LastStationAlert.CreatedDate|utcDateTimeFormat:$root.dateTimeFormat}}</td>
                                    <td>{{selectedStationInfo.stationState.LastStationAlert.SentBy}}</td>
                                    <td>{{email.Subject}}</td>
                                    <td>{{email.ClientName}}</td>
                                    <td>
                                        <span *ngIf="email.CustomerRepresentativeEmail">{{email.CustomerRepresentativeEmail}}<span *ngIf="email.Contacts.length > 0">, </span></span>
                                        <span *ngFor="let contact of email.Contacts; let last = last">{{contact.Email}}<span *ngIf="!last">, </span></span>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                    </portlet>

                    <portlet name="hots" title="{{'HOTs'|translate}}">
                        <div *ngIf="selectedStationInfo.stationState.HotResponse.MetarUsageReason">
                            {{selectedStationInfo.stationState.HotResponse.MetarUsageReason}}
                        </div>
                        <div *ngIf="!selectedStationInfo.stationState.HotResponse.MetarUsageReason">
                            <div *ngFor="let hot of selectedStationInfo.stationState.HotResponse.HotResponseHots|orderBy:['FluidOrder', 'FluidType', 'FluidName', '-SortableDilution']">
                                <div class="row">
                                    <div class="col-md-4 fluid"
                                         [class.type1]="hot.FluidType === 'Type1'"
                                         [class.type2]="hot.FluidType === 'Type2'"
                                         [class.type3]="hot.FluidType === 'Type3'"
                                         [class.type4]="hot.FluidType === 'Type4'"
                                         [class.dilution75]="hot.FluidDilution === '75/25'"
                                         [class.dilution50]="hot.FluidDilution === '50/50'"
                                         [class.generic]="hot.IsGeneric"
                                         [class.composite]="hot.IsComposite">
                                        <div style="font-weight: bold; font-size: 18px; margin-bottom: 7px;">
                                            TYPE {{hot.FluidTypeNumber}}
                                        </div>
                                        <div>
                                            <span>{{hot.FluidName}}</span>
                                            <span *ngIf="hot.FluidType != 'Type1'">{{hot.FluidDilution}}</span>
                                        </div>
                                    </div>

                                    <div class="col-md-8" style="margin-left: 10px;">
                                        <div *ngIf="!isEmpty(hot.NonHotMessage)">
                                            <span style="white-space: pre-wrap;">{{hot.NonHotMessage}}</span>
                                        </div>
                                        <div *ngIf="!isEmpty(hot.MinHot)">
                                            <div style="margin: 0 0 9px 15px; overflow: hidden;">
                                                <span [style.display]="getHotStyle(hot).min.display" [style.width.%]="getHotStyle(hot).min.width" [style.text-align]="getHotStyle(hot).min['text-align']" [style.float]="getHotStyle(hot).min.float"><span class="backgroundColour">{{hot.MinHot}} {{'MIN'|translate}}</span></span>
                                                <span [style.display]="getHotStyle(hot).max.display" [style.width.%]="getHotStyle(hot).max.width" [style.text-align]="getHotStyle(hot).max['text-align']" [style.float]="getHotStyle(hot).max.float" *ngIf="!isEmpty(hot.MaxHot)"><span class="backgroundColour">{{getHotStyle(hot).separator}}{{hot.MaxHot}} {{'MIN'|translate}}</span></span>
                                            </div>
                                            <div class="timer">
                                                <div class="middle">
                                                    <div class="progressBar">
                                                        <div class="upperProgressBar thin" style="background-color: grey;"></div>
                                                        <div *ngIf="!isEmpty(hot.MaxHot)" class="lowerProgressBar thin min" [style.width.%]="((hot.MaxHot * 100)/selectedStationInfo.stationState.HotResponse.maxHOTConfig)"></div>
                                                        <div *ngIf="!isEmpty(hot.MinHot)" class="lowerProgressBar thin max" [style.width.%]="((hot.MinHot * 100)/selectedStationInfo.stationState.HotResponse.maxHOTConfig)"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </portlet>

                    <portlet name="raw_data" title="{{'Raw data'|translate}}">
                        <textarea readonly style="width: 100%; height: 400px; overflow: scroll; white-space: pre; font-family: monospace;" spellcheck="false">{{selectedStationInfo.rawData}}</textarea>
                    </portlet>
                </portlets>
            </div>
        </div>
    </div>
</div>
