import { Pipe, PipeTransform } from '@angular/core';

// https://github.com/VadimDez/ng2-filter-pipe/blob/master/src/app/shared/ng2-filter.pipe.ts
@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    public static readonly instance = new FilterPipe();

    private filterByString(filter: string) {
        filter = filter.toLowerCase();
        return value => {
            if (!filter || typeof value === 'string') {
                return !filter || value.toLowerCase().indexOf(filter) !== -1;
            }

            for (let key in value) {
                if (value.hasOwnProperty(key)) {
                    var propertyValue = value[key];
                    if (typeof propertyValue === 'string' && propertyValue.toLowerCase().indexOf(filter) !== -1) {
                        return true;
                    }
                }
            }

            return false;
        }
    }

    private filterByObject(filter) {
        return value => {
            for (let key in filter) {
                if (!value.hasOwnProperty(key)) {
                    return false;
                }

                const type = typeof value[key];
                let isMatching;

                if (type === 'string') {
                    isMatching = this.filterByString(filter[key])(value[key]);
                } else if (type === 'object') {
                    isMatching = this.filterByObject(filter[key])(value[key]);
                } else {
                    isMatching = this.filterDefault(filter[key])(value[key]);
                }

                if (!isMatching) {
                    return false;
                }
            }

            return true;
        }
    }

    /**
     * Defatul filterDefault function
     *
     * @param filter
     * @returns {(value:any)=>boolean}
     */
    private filterDefault(filter) {
        return value => {
            return !filter || filter == value;
        }
    }

    transform(array: any[], filter: any): any {
        const type = typeof filter;
        
        if (filter == null || array == null) return array;

        if (type === 'string') {
            return array.filter(this.filterByString(filter));
        }

        if (type === 'object') {
            return array.filter(this.filterByObject(filter));
        }

        return array.filter(this.filterDefault(filter));
    }
}
