<div class="noMargins">
    <page-title>{{('Holdover taxi time prediction configurations'|translate)}}</page-title>
    <form #form="ngForm" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="grid.searchQuery" name="searchQuery" />
        </label>
        <label class="inline">
            <input type="checkbox" [(ngModel)]="grid.includeInactive" name="includeInactive" />
            <span class="caption">{{'Include inactive'|translate}}</span>
        </label>
        <button *ngIf="'ConfigAddHoldoverTaxiTimeModelConfigurations'|hasPermission" routerLink="/holdoverTaxiTimePredictions/new" type="button" class="green">{{'Add configuration'|translate}}</button>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
    </form>

    <config-grid #grid
                 [query]="query"
                 [parent]="this"
                 [resource]="resource"
                 [exportResource]="exportResourcePost"
                 [columnDefs]="columnDefs">
    </config-grid>
</div>
