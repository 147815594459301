<div class="noMargins">
    <page-title>{{('HOT calculator'|translate)}}</page-title>
    <form class="contentMargins form" #form="ngForm" (ngSubmit)="profile()">
        <label>
            <span class="caption">{{'Date'|translate}}</span>
            <input date-time [options]="datePickerOptions" required [(ngModel)]="request.datetime" name="datetime" format="YYYY-MM-DDTHH:mm:ss[Z]" displayFormat="YYYY-MM-DD HH:mm:ss"> Z
        </label>
        <label>
            <span class="caption">{{'Station'|translate}}</span>
            <select [(ngModel)]="request.stationId" required name="stationId">
                <option *ngFor="let station of stations|orderBy:'Name'" [ngValue]="station.Id">{{station.Name}}</option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Sensor select matrix'|translate}}</span>
            <select [(ngModel)]="request.sensorSelectMatrixId" name="sensorSelectMatrixId" required>
                <option [ngValue]="station.Id" *ngFor="let station of sensorSelectMatrices|filter:{StationType:(stations|findById:request?.stationId)?.StationType || 'Gen3'}">{{station.Name}}</option>
            </select>
        </label>
        <label *ngIf="(stations|findById:request?.stationId)?.StationType == 'Gen3'">
            <span class="caption">{{'METAR warnings'|translate}}</span>
            <select [(ngModel)]="request.metarWarning" name="metarWarning">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let metarWarning of metarWarnings|keyvalue" [ngValue]="metarWarning.key">{{metarWarning.value}}</option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Fluid'|translate}}</span>
            <select [(ngModel)]="request.fluidId" name="request.fluidId" required>
                <option *ngFor="let fluid of fluids|orderBy:['FluidProfileName', 'Type', 'Name']" [ngValue]="fluid.Id">
                    {{(fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' (' + fluid.Dilution + ')')}}
                </option>
            </select>

        </label>
        <label>
            <span class="caption">{{'Fluid version'|translate}}</span>
            <select [(ngModel)]="request.fluidVersionId" name="fluidVersionId" required>
                <option *ngFor="let fluidVersion of fluidVersions|filter:{FluidId:request?.fluidId}|orderBy:['-Order']" [ngValue]="fluidVersion.Id">
                    {{fluidVersion.Name}}
                </option>
            </select>
        </label>
        <br />
        <input type="submit" [disabled]="!form.valid" value="{{'Calculate'|translate}}" />
    </form>
    <div class="contentMargins result" *ngIf="!!result">
        <h2>{{'Result'|translate}}</h2>
        <pre>{{result|json}}</pre>
    </div>
</div>
