import {Component, Input, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {DialogService, FileDownloadService, IAgGridColGroupDef, StorageService, ValueFormatters} from "swx.front-end-lib";

@Component({
    templateUrl: './AcarsOooiReports.component.html'
})
export class AcarsOooiReportsComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey: 'acarsOooiReportsQuery';
    query: any = {};
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.AcarsOooiReport;
    exportResourcePost = this.api.AcarsOooiReportExport;

    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private storage: StorageService,
                private formatters: ValueFormatters,
                private dialog: DialogService) {
    }
    
    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery) {
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }
    
    resetQuery() {
        this.query = {
            Filters: []
        }
    }
    
    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }


    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: 'ACARS',
            children: [
                { colId: "AcarsIncomingMessage.Date", field: "AcarsIncomingMessage.DateTime", headerName: "Date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left' },
                { colId: "AcarsIncomingMessage.Time", field: "AcarsIncomingMessage.DateTime", headerName: "Time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left' },
            ]
        },
        {
            headerName: 'OOOI',
            children: [
                { colId: "AcarsOooiReport.Id", field: "AcarsOooiReport.Id", headerName: "#", width: 70 },
                { colId: "AcarsOooiReport.EventCode", field: "AcarsOooiReport.EventCode", headerName: "Type", width: 70, filterType: "enum", source: "AcarsOooiEventCode" },
            ]
        },
        {
            headerName: 'ACARS',
            children: [
                { colId: "AcarsIncomingMessage.RawData", field: "AcarsIncomingMessage.RawData", headerName: "Raw data", width: 70, cellTemplate: `<a *ngIf='params.data?.AcarsIncomingMessage.RawData' (click)='parent.showAcars(params.data.AcarsIncomingMessage.RawData)'>{{'ACARS'|translate}}</a>` },
                { colId: "ClientName", field: "ClientName", headerName: "Client", width: 120 },
                { colId: "AirportCode", field: "AirportCode", headerName: "Airport", width: 80 },
                { colId: "AcarsIncomingMessage.EquipmentTypeIdentifier", field: "AcarsIncomingMessage.EquipmentTypeIdentifier", headerName: "Equipment type", width: 80 },
            ]
        },
        {
            headerName: 'OOOI',
            children: [
                { colId: "AcarsOooiReport.TailNumber", field: "AcarsOooiReport.TailNumber", headerName: "Registration number", width: 100 },
                { colId: "FlightNumber", field: "FlightNumber", headerName: "Flight number", width: 100 },
                { colId: "OriginAirportCode", field: "OriginAirportCode", headerName: "Origin", width: 100 },
                { colId: "DestinationAirportCode", field: "DestinationAirportCode", headerName: "Destination", width: 100 },
                { colId: "AcarsOooiReport.DepartureDate", field: "AcarsOooiReport.DepartureDate", headerName: "Departure date", width: 90, valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "AcarsOooiReport.OnEventDate", field: "AcarsOooiReport.OnEventTime", headerName: "On date", width: 90, valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "AcarsOooiReport.OnEventTime", field: "AcarsOooiReport.OnEventTime", headerName: "On time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true },
                { colId: "AcarsOooiReport.InEventDate", field: "AcarsOooiReport.InEventTime", headerName: "In date", width: 90, valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "AcarsOooiReport.InEventTime", field: "AcarsOooiReport.InEventTime", headerName: "In time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true },
                { colId: "AcarsOooiReport.OutEventDate", field: "AcarsOooiReport.OutEventTime", headerName: "Out date", width: 90, valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "AcarsOooiReport.OutEventTime", field: "AcarsOooiReport.OutEventTime", headerName: "Out time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true },
                { colId: "AcarsOooiReport.OffEventDate", field: "AcarsOooiReport.OffEventTime", headerName: "Off date", width: 90, valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "AcarsOooiReport.OffEventTime", field: "AcarsOooiReport.OffEventTime", headerName: "Off time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true },
                { colId: "AcarsOooiReport.EstTimeOfArrivalDate", field: "AcarsOooiReport.EstTimeOfArrival", headerName: "ETA date", width: 90, valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "AcarsOooiReport.EstTimeOfArrivalTime", field: "AcarsOooiReport.EstTimeOfArrival", headerName: "ETA time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true },
                { colId: "AcarsOooiReport.UtcDate", field: "AcarsOooiReport.UtcTime", headerName: "UTC date", width: 90, valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "AcarsOooiReport.RevisedOutDate", field: "AcarsOooiReport.RevisedOutTime", headerName: "Reivsed out date", width: 90, valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "AcarsOooiReport.RevisedOutTime", field: "AcarsOooiReport.RevisedOutTime", headerName: "Reivsed out time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true },
                { colId: "AcarsOooiReport.FuelOfBoard", field: "AcarsOooiReport.FuelOfBoard", headerName: "Fuel on board", width: 100 },
                { colId: "AcarsOooiReport.FuelRemaining", field: "AcarsOooiReport.FuelRemaining", headerName: "Fuel remaining", width: 100 },
                { colId: "AcarsOooiReport.Pax", field: "AcarsOooiReport.Pax", headerName: "Passengers", width: 100 },
                { colId: "AcarsOooiReport.Dly1", field: "AcarsOooiReport.Dly1", headerName: "DL1", width: 100 },
                { colId: "AcarsOooiReport.Dly2", field: "AcarsOooiReport.Dly2", headerName: "DL2", width: 100 },
            ]
        },
    ];
    
    showAcars(data) {
        this.dialog.show(this.viewContainerRef, RawAcarsDialogComponent, {
            data: data
        }, {
            title: 'ACARS',
            width: 360,
            height: 540,
            modal: true,
        })
    }
} 

@Component({
    template: `
        <table class="table">
            <tr>
                <th>{{'Request'|translate}}</th>
            </tr>
            <tr>
                <td>
                    <textarea readonly cols="24" rows="18" [(ngModel)]="data"></textarea>
                    <br /><br />
                    <input type="button" [disabled]="!data" (click)="onDownload(data, 'raw_request.txt')" value="{{'Download'|translate}}" />
                </td>
            </tr>
        </table>
    `
})
export class RawAcarsDialogComponent {
    @Input() data;

    constructor(private download: FileDownloadService) {
    }

    onDownload(data: string, fileName: string) {
        this.download.download(new Blob([data]), fileName)
    }
}

