<page-title>{{('Remote endpoint'|translate) + ': ' + (item.Name || 'new')}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="form noMargins">
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" *ngIf="'ConfigEditRemoteEndpoints'|hasPermission" (click)="switchTab('columns')" [ngClass]="{active : tab == 'columns', invalid: !ngForm.form.controls.columnsForm?.valid}">{{'Columns'}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        <button type="button" *ngIf="tab == 'columns' && 'ConfigEditRemoteEndpoints'|hasPermission" (click)="addColumn()" class="button green">{{'Add column'|translate}}</button>
    </div>

    <fieldset class="contentMargins" [disabled]="!('ConfigEditRemoteEndpoints'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
        <label>
            <span class="caption">{{'Type'|translate}}</span>
            <select [(ngModel)]="item.Type" name="item.Type" required [selectedIndex]="-1">
                <option *ngFor="let option of remoteEndpointTypes|keys" [ngValue]="option" [class]="option">
                    {{remoteEndpointTypes[option]}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Format'|translate}}</span>
            <select [(ngModel)]="item.Format" name="item.Format" required [selectedIndex]="-1">
                <option *ngFor="let option of remoteEndpointFormats|keys" [ngValue]="option" [class]="option">
                    {{remoteEndpointFormats[option]}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'URL'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Url" name="item.Url" style="width: 600px;" />
        </label>
        <label>
            <span class="caption">{{'Timeout (ms)'|translate}}</span>
            <input type="number" required min="0" step="1" [(ngModel)]="item.TimeoutMs" name="item.TimeoutMs" />
        </label>
    </fieldset>

    <config-grid #columnsGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'columns'"
                 ngModelGroup="columnsForm"
                 [allRows]="item.RemoteEndpointColumns"
                 [includeInactive]="true"
                 [headerHeight]="75"
                 [columnDefs]="columnDefs">
    </config-grid>
</form>
