<page-title>{{('Engine cover alert profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('airports')" [class.active]="tab == 'airports'" [class.invalid]="!ngForm.form.controls.airportsForm?.valid">{{'Airports'|translate}}</a>
        <a class="tab" (click)="switchTab('emails')" [class.active]="tab == 'emails'" [class.invalid]="!ngForm.form.controls.emailsForm?.valid">{{'Emails'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditEngineCoverAlertProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
        <label>
            <span class="caption">{{'Client'|translate}}</span>
            <select [(ngModel)]="item.ClientId" name="item.ClientId" (ngModelChange)="updateAvailableAirports()">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let client of clients|orderBy:'Name'" [ngValue]="client.Id">
                    {{client.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Email sender'|translate}}</span>
            <select required [(ngModel)]="item.EmailFromId" name="item.EmailFromId">
                <option *ngFor="let emailFrom of emailFroms|orderBy:'Id'" [ngValue]="emailFrom.Id">
                    {{emailFrom.EmailAddress}}
                </option>
            </select>
        </label>
        <br />

        <table class="table">
            <tr>
                <th style="text-align: center" colspan="2">{{'Nowcast LWE12 expection weights'|translate}}</th>
            </tr>
            <tr>
                <td>{{'30 min.'|translate}}</td>
                <td><input [(ngModel)]="item.Lwe12ExpectationWeight30" name="item.Lwe12ExpectationWeight30" type="number" step="any" style="width: 60px;" required /></td>
            </tr>
            <tr>
                <td>{{'60 min.'|translate}}</td>
                <td><input [(ngModel)]="item.Lwe12ExpectationWeight60" name="item.Lwe12ExpectationWeight60" type="number" step="any" style="width: 60px;" required /></td>
            </tr>
            <tr>
                <td>{{'90 min.'|translate}}</td>
                <td><input [(ngModel)]="item.Lwe12ExpectationWeight90" name="item.Lwe12ExpectationWeight90" type="number" step="any" style="width: 60px;" required /></td>
            </tr>
            <tr>
                <td>{{'120 min.'|translate}}</td>
                <td><input [(ngModel)]="item.Lwe12ExpectationWeight120" name="item.Lwe12ExpectationWeight120" type="number" step="any" style="width: 60px;" required /></td>
            </tr>
            <tr>
                <td>{{'150 min.'|translate}}</td>
                <td><input [(ngModel)]="item.Lwe12ExpectationWeight150" name="item.Lwe12ExpectationWeight150" type="number" step="any" style="width: 60px;" required /></td>
            </tr>
            <tr>
                <td>{{'180 min.'|translate}}</td>
                <td><input [(ngModel)]="item.Lwe12ExpectationWeight180" name="item.Lwe12ExpectationWeight180" type="number" step="any" style="width: 60px;" required /></td>
            </tr>
            <tr>
                <td>{{'210 min.'|translate}}</td>
                <td><input [(ngModel)]="item.Lwe12ExpectationWeight210" name="item.Lwe12ExpectationWeight210" type="number" step="any" style="width: 60px;" required /></td>
            </tr>
        </table>
        <br />
        <table class="table">
            <tr>
                <th style="text-align: center" colspan="2">{{'Orange entry criteria'|translate}}</th>
            </tr>
            <tr>
                <td>{{'Minimum duration to persist alarm'|translate}}</td>
                <td><input [(ngModel)]="item.OrangeAlertMinimumDurationMinutes" name="item.OrangeAlertMinimumDurationMinutes" type="number" min="0" step="1" style="width: 60px;" required /> minutes</td>
            </tr>
            <tr>
                <td>{{'Max. temperature'|translate}}</td>
                <td><input [(ngModel)]="item.OrangeMaxTemperature" name="item.OrangeMaxTemperature" type="number" step="any" style="width: 60px;" /> °C</td>
            </tr>
            <tr>
                <td>{{'Weather types'|translate}}</td>
                <td>
                    <multiselect [all]="false" [none]="'Any'" [format]="'string'" [(ngModel)]="item.OrangeCurrentWeatherTypes" [name]="'item.OrangeCurrentWeatherTypes'">
                        <multiselect-option *ngFor="let option of clientWeatherTypeFlags|keys" [value]="option" [name]="clientWeatherTypeFlags[option]"></multiselect-option>
                    </multiselect>
                </td>
            </tr>
            <tr>
                <td>{{'NOAA RAP 240 minute forecast average wind speed breakpoint'|translate}}</td>
                <td><input [(ngModel)]="item.OrangeNoaaRapWindSpeedAverageBreakpoint" name="item.OrangeNoaaRapWindSpeedAverageBreakpoint" type="number" min="0" step="any" style="width: 60px;" required /> m/s</td>
            </tr>
            <tr>
                <td>{{'When &lt;= breakpoint and Nowcast LWE12 expectation &gt;='|translate}}</td>
                <td><input [(ngModel)]="item.OrangeNowcastLwe12ExpectationLow" name="item.OrangeNowcastLwe12ExpectationLow" type="number" min="0" step="any" style="width: 60px;" required /> %</td>
            </tr>
            <tr>
                <td>{{'When &gt; breakpoint and Nowcast LWE12 expectation &gt;='|translate}}</td>
                <td><input [(ngModel)]="item.OrangeNowcastLwe12ExpectationHigh" name="item.OrangeNowcastLwe12ExpectationHigh" type="number" min="0" step="any" style="width: 60px;" required /> %</td>
            </tr>
            <tr>
                <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
                <th style="text-align: center" colspan="2">{{'Red entry criteria'|translate}}</th>
            </tr>
            <tr>
                <td>{{'Gen 1/2 LWE averaging period '|translate}}</td>
                <td><input [(ngModel)]="item.Gen2LWEAveragingPeriod" name="item.Gen2LWEAveragingPeriod" type="number" min="1" max="30" step="1" style="width: 60px;" required /> minutes</td>
            </tr>
            <tr>
                <td>{{'Gen 3/V LWE averaging period '|translate}}</td>
                <td><input [(ngModel)]="item.Gen3LWEAveragingPeriod" name="item.Gen3LWEAveragingPeriod" type="number" min="1" max="30" step="1" style="width: 60px;" required /> minutes</td>
            </tr>
            <tr>
                <td>{{'Minimum duration to persist alarm'|translate}}</td>
                <td><input [(ngModel)]="item.RedAlertMinimumDurationMinutes" name="item.RedAlertMinimumDurationMinutes" type="number" min="0" step="1" style="width: 60px;" required /> minutes</td>
            </tr>
            <tr>
                <td>{{'Max. temperature'|translate}}</td>
                <td><input [(ngModel)]="item.RedMaxTemperature" name="item.RedMaxTemperature" type="number" step="any" style="width: 60px;" /> °C</td>
            </tr>
            <tr>
                <td>{{'Weather types'|translate}}</td>
                <td>
                    <multiselect [all]="false" [none]="'Any'" [format]="'string'" [(ngModel)]="item.RedCurrentWeatherTypes" [name]="'item.RedCurrentWeatherTypes'">
                        <multiselect-option *ngFor="let option of clientWeatherTypeFlags|keys" [value]="option" [name]="clientWeatherTypeFlags[option]"></multiselect-option>
                    </multiselect>
                </td>
            </tr>
            <tr>
                <td>{{'Current wind speed breakpoint'|translate}}</td>
                <td><input [(ngModel)]="item.RedCurrentWindSpeedBreakpoint" name="item.RedCurrentWindSpeedBreakpoint" type="number" min="0" step="any" style="width: 60px;" required /> m/s</td>
            </tr>
            <tr>
                <td>{{'When &lt;= breakpoint and current LWE &gt;='|translate}}</td>
                <td><input [(ngModel)]="item.RedCurrentLweLow" name="item.RedCurrentLweLow" type="number" min="0" step="any" style="width: 60px;" required /> g/dm²/h</td>
            </tr>
            <tr>
                <td>{{'When &gt; breakpoint and current LWE &gt;='|translate}}</td>
                <td><input [(ngModel)]="item.RedCurrentLweHigh" name="item.RedCurrentLweHigh" type="number" min="0" step="any" style="width: 60px;" required /> g/dm²/h</td>
            </tr>
        </table>
    </fieldset>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditEngineCoverAlertProfiles'|hasPermission)" [hidden]="tab != 'airports'" ngModelGroup="airportsForm">
        <select [(ngModel)]="airport" name="airport">
            <option *ngFor="let airportOption of availableAirports" [ngValue]="airportOption">
                {{airportOption.ICAOCode + '/' + airportOption.IATACode + ' - ' + airportOption.Name}}
            </option>
        </select>
        <input type="button" *ngIf="('ConfigEditEngineCoverAlertProfiles'|hasPermission)" (click)="addEngineCoverAlertAirport(airport); airport = null;" value="{{'Add airport'|translate}}" [disabled]="airport == null" />
        <br />
        <br />
        <table class="table">
            <tr>
                <th>{{'Airport'|translate}}</th>
                <th style="width: 250px;">{{'Contact info'|translate}}</th>
                <th></th>
            </tr>
            <tr *ngFor="let engineCoverAlertAirport of item.EngineCoverAlertAirports; let i = index">
                <td>{{(airports|findById:engineCoverAlertAirport.AirportId)?.ICAOCode + ' / ' + (airports|findById:engineCoverAlertAirport.AirportId)?.ICAOCode + ' - ' + (airports|findById:engineCoverAlertAirport.AirportId)?.Name}}</td>
                <td>
                    <input type="text" style="width: 100%;" [(ngModel)]="engineCoverAlertAirport.ContactInfo" [name]="'engineCoverAlertAirport[' + i + '].ContactInfo'" />
                </td>
                <td>
                    <a *ngIf="('ConfigEditEngineCoverAlertProfiles'|hasPermission)" (click)="removeEngineCoverAlertAirport(engineCoverAlertAirport)" class="mdi mdi-delete"></a>
                </td>
            </tr>
        </table>
    </fieldset>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditEngineCoverAlertProfiles'|hasPermission)" [hidden]="tab != 'emails'" ngModelGroup="emailsForm">
        <table class="table">
            <tr>
                <td>
                    <label>
                        <span class="caption">{{'Available tokens'|translate}}</span>
                        <span class="value" style="width: auto;">{{'{AirportCode} {ContactInfo} {OperationManagerName} {OperationManagerPhone} {OperationManagerEmailAddress} {Criteria} {AlertId}'}}</span>
                    </label>
                </td>
            </tr>
            <tr>
                <th style="text-align: center">{{'Red entry'|translate}}</th>
            </tr>
            <tr>
                <td>
                    <label>
                        <span class="caption">{{'Subject'|translate}}</span>
                        <input type="text" required [(ngModel)]="item.RedEnteringSubject" name="item.RedEnteringSubject" style="width: 100%;" />
                    </label>
                    <div class="field">
                        <span class="caption">{{'Body'|translate}}</span>
                        <ckeditor [editor]="RedEnteringBodyEditor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="item.RedEnteringBody" [name]="'item.RedEnteringBody'"></ckeditor>
                    </div>
                </td>
            </tr>
            <tr>
                <th style="text-align: center">{{'Forced orange entry'|translate}}</th>
            </tr>
            <tr>
                <td>
                    <label>
                        <span class="caption">{{'Subject'|translate}}</span>
                        <input type="text" required [(ngModel)]="item.ForcedOrangeEnteringSubject" name="item.ForcedOrangeEnteringSubject" style="width: 100%;" />
                    </label>
                    <div class="field">
                        <span class="caption">{{'Body'|translate}}</span>
                        <ckeditor [editor]="ForcedOrangeEnteringBodyEditor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="item.ForcedOrangeEnteringBody" [name]="'item.ForcedOrangeEnteringBody'"></ckeditor>
                    </div>
                </td>
            </tr>
            <tr>
                <th style="text-align: center">{{'Orange entry'|translate}}</th>
            </tr>
            <tr>
                <td>
                    <label>
                        <span class="caption">{{'Subject'|translate}}</span>
                        <input type="text" required [(ngModel)]="item.OrangeEnteringSubject" name="item.OrangeEnteringSubject" style="width: 100%;" />
                    </label>
                    <div class="field">
                        <span class="caption">{{'Body'|translate}}</span>
                        <ckeditor [editor]="OrangeEnteringBodyEditor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="item.OrangeEnteringBody" [name]="'item.OrangeEnteringBody'"></ckeditor>
                    </div>
                </td>
            </tr>
            <tr>
                <th style="text-align: center">{{'Exiting'|translate}}</th>
            </tr>
            <tr>
                <td>
                    <label>
                        <span class="caption">{{'Subject'|translate}}</span>
                        <input type="text" required [(ngModel)]="item.ExitingSubject" name="item.ExitingSubject" style="width: 100%;" />
                    </label>
                    <div class="field">
                        <span class="caption">{{'Body'|translate}}</span>
                        <ckeditor [editor]="ExitingBodyEditor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="item.ExitingBody" [name]="'item.ExitingBody'"></ckeditor>
                    </div>
                </td>
            </tr>
        </table>
    </fieldset>
</form>
