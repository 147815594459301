<div id="fluidList" class="noMargins">
    <page-title>{{('Fluids'|translate)}}</page-title>
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="grid.searchQuery" name="searchQuery" />
        </label>
        <label class="inline">
            <input type="checkbox" [(ngModel)]="grid.includeInactive" name="includeInactive" />
            <span class="caption">{{'Include inactive'|translate}}</span>
        </label>
        <label class="inline" *ngFor="let fluidProfile of fluidProfiles|filter:(grid.includeInactive ? {} : {Active:true})|orderBy:'Name'">
            <input type="checkbox" [checked]="isFluidProfileSelected(fluidProfile)" (change)="toggleFluidProfile(fluidProfile)" />
            <span class="caption">{{fluidProfile.Name}}</span>
        </label>
        <button *ngIf="'ConfigAddFluids'|hasPermission" [routerLink]="['/fluids', 'new']" type="button" class="green">{{'Add Fluid'|translate}}</button>
        <button type="button" (click)="export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
    </form>

    <config-grid #grid
                 gridId="preferences.Fluids.ColumnExport"
                 [query]="query"
                 [parent]="this"
                 [exportResource]="api.FluidDetailExport"
                 [allRows]="fluids"
                 [columnDefs]="columnDefs">
    </config-grid>
</div>
