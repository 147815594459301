import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ChangesService, ConfirmService, FilterPipe, TranslateService} from "swx.front-end-lib";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import {HasPermissionService} from "../shared/HasPermission.pipe";
import { HttpClient } from "@angular/common/http";
import {AirportService} from "./Airport.service";

@Component({
    templateUrl: './AirportEdit.component.html'
})
export class AirportEditComponent implements OnInit {
    item;
    metarSources = this.api.MetarSource.query();
    countries$ = this.airportService.airportCountries$;
    regions$ = this.airportService.airportRegions$;
    stations = []; 
    sensorSelectMatrices = this.api.SensorSelectMatrix.query();
    useDebugNowcast = false;
    
    returnPath;
    permissions = this.api.Permission.query();
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private translateService: TranslateService,
        private confirmService: ConfirmService,
        public hasPermissionService: HasPermissionService,
        private http: HttpClient,
        private airportService: AirportService,
    ) {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.Airport.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
            });
        } else if (isNew) {
            this.item = this.api.Airport.create();
            this.useDebugNowcast = !!this.item.useDebugNowcast;
        } else {
            this.item = this.api.Airport.get({ id: id });
        }

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }
    
    ngOnInit() {
        this.item.$promise.then(() => {
            this.api.Station.query().$promise.then(stations => {
                this.stations = stations.filter(station => station.AirportId == this.item.Id);
            });
        });
    }

    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);

    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['Airport'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    isPermissionEnabled(option) {
        const selected = (this.item.Permissions || '').split(', ');
        return selected.indexOf(option) > -1;
    };

    togglePermission(option) {
        const selected = this.item.Permissions ? this.item.Permissions.split(', ') : [];
        const index = selected.indexOf(option);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(option);
        }
        this.item.Permissions = selected.join(', ');
        this.ngForm.form.markAsDirty();
    }


    onUseDebugNowcastChange(value)
    {
        if (!value) {
            this.item.DebugNowcast = null;
        } else {
            this.item.DebugNowcast = [];
        }
    }

    // Nowcast implies AllClear
    onNowcastChange() {
        if (this.item.HasNowcast) {
            this.item.HasSureForecastAllClear = true;
        }
    }

    // Uppercase IATACode/ICAOCode and Name
    normalizeAirport(item) {
        let normalized = Object.assign(item);
        normalized.Name = normalized.Name.toUpperCase();
        normalized.ICAOCode = normalized.ICAOCode.toUpperCase();
        normalized.IATACode = normalized.IATACode.toUpperCase();
        return normalized;
    }

    onDebugValueChanged(value) {
        this.item.DebugNowcast = value;
        this.ngForm.setDirty();
    }
}
