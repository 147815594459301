<div id="equipmentTypeList" class="noMargins">
    <page-title>{{('Equipment types'|translate)}}</page-title>
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="grid.searchQuery" name="searchQuery" />
        </label>
        <label class="inline">
            <input type="checkbox" [(ngModel)]="grid.includeInactive" name="includeInactive" />
            <span class="caption">{{'Include inactive'|translate}}</span>
        </label>
        <button *ngIf="'ConfigAddEquipmentTypes'|hasPermission" routerLink="/equipmentTypes/new" type="button" class="green">{{'Add equipment type'|translate}}</button>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
    </form>

    <config-grid #grid
                 gridId="preferences.EquipmentTypes.ColumnExport"
                 [query]="query"
                 [resource]="api.EquipmentType"
                 [exportResource]="api.EquipmentTypeExport"
                 [columnDefs]="columnDefs">
    </config-grid>
</div>
