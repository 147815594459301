import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {IAgGridColGroupDef, StorageService, ValueFormatters} from "swx.front-end-lib";
import {RawDataLinkCellRenderer} from "./RawDataLinkCellRenderer";
import {HotGridColumnsService} from "./HotGridColumns.service";
import {HotResponseMessageCellRendererComponent} from "./HotResponseMessageCellRenderer";
import {WeatherOverrideDeicingReportCellRenderer} from "./WeatherOverrideDeicingReportCellRenderer";

@Component({
    templateUrl: './HotData.component.html'
})
export class HotDataComponent implements OnInit, OnDestroy {
    @ViewChild('grid') grid;
    @ViewChild('form') form;
    destroyed = false;
    storeKey = 'hotDataQuery_v2' 
    clients = this.api.Client.query();   
    airports = this.api.Airport.query();
    fluids = this.api.Fluid.query();
    metarWeatherTypes = this.api.MetarWeatherType.query();
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    query: any;
    columnDefs: IAgGridColGroupDef[];
    components = {
        rawDataLinkCellRenderer: RawDataLinkCellRenderer,
        hotResponseMessageCellRenderer: HotResponseMessageCellRendererComponent,
        weatherOverrideDeicingReportCellRenderer: WeatherOverrideDeicingReportCellRenderer,
    }
    resource: any;
    exportResource: any;
    
    constructor(private api: ApiService, private storage: StorageService, private formatters: ValueFormatters, private hotGridColumns: HotGridColumnsService) {
        this.resource = this.api.Hot;
        this.exportResource = this.api.HotExport;
    }

    ngOnDestroy(): void {
        this.destroyed = true;
    }
    
    ngOnInit() {
        this.query = this.storage.load(this.storeKey)
        if (!this.query) {
            this.resetQuery()
        }

        this.updateColumns('init');
    }
    
    resetQuery() {
        this.query = {
            Filters: []
        }
    }
    
    apply() {
        this.storage.save(this.storeKey, this.query)
        this.grid.refresh() 
    }
    
    updateColumns(source) {
        if (this.destroyed) return;

        Promise.all([this.fluids.$promise]).then(() => {
            var columnDefs = this.hotGridColumns.getBaseColumns();

            if (this.query.RowPerHot) {
                this.resource = this.api.Hot;
                this.exportResource = this.api.HotExport;

                columnDefs = columnDefs.concat([
                    {
                        headerName: 'Fluid HOT',
                        children: [
                            {
                                colId: "HotResponseHot.FluidType",
                                field: "HotResponseHot.FluidType",
                                headerName: "Fluid type",
                                width: 60,
                                filterType: "enum",
                                source: "FluidType"
                            },
                            {
                                colId: "HotResponseHot.FluidName",
                                field: "HotResponseHot.FluidName",
                                headerName: "Client fluid name",
                                width: 200
                            },
                            {
                                colId: "HotResponseHot.FluidDilution",
                                field: "HotResponseHot.FluidDilution",
                                headerName: "Dilution",
                                width: 100
                            },
                            {
                                colId: "HotResponseHot.Message",
                                field: "HotResponseHot.Message",
                                headerName: "HOT",
                                width: 250
                            },
                            {
                                colId: "HotResponseHot.HOT",
                                field: "HotResponseHot.HOT",
                                headerName: "HOT minutes",
                                width: 60
                            },
                            {
                                colId: "HotResponseHot.IsLWERegressionHot",
                                field: "HotResponseHot.IsLWERegressionHot",
                                headerName: "Is METAR LWE regression?",
                                width: 100
                            },
                            {
                                colId: "HotResponseHot.Errors",
                                field: "HotResponseHot.Errors",
                                headerName: "Error",
                                width: 200,
                                initialHide: true,
                                searchable: false
                            },
                        ]
                    },
                    {
                        headerName: 'METAR reference',
                        children: [
                            {
                                colId: "HotResponseHot.ReferenceMetarMinHot",
                                field: "HotResponseHot.ReferenceMetarMinHot",
                                headerName: "METAR Min. HOT (minutes)",
                                width: 60,
                                filterType: "integer"
                            },
                            {
                                colId: "HotResponseHot.ReferenceMetarMaxHot",
                                field: "HotResponseHot.ReferenceMetarMaxHot",
                                headerName: "METAR Max. HOT (minutes)",
                                width: 60,
                                filterType: "integer"
                            },
                        ]
                    },
                    {
                        headerName: 'Fluid HOT',
                        children: [
                            {
                                colId: "HotResponseHot.LWE",
                                field: "HotResponseHot.LWE",
                                headerName: "LWE or LUPR (g/dm²/h)",
                                width: 60,
                                filterType: "float"
                            },
                        ]
                    }
                ]);
            } else {
                this.resource = this.api.HotRequest;
                this.exportResource = this.api.HotRequestExport;

                columnDefs = columnDefs
                    .concat(this.hotGridColumns.generateHotColumns('Type1AHot', 'Type 1 aluminum'))
                    .concat(this.hotGridColumns.generateHotColumns('Type1CHot', 'Type 1 composite'))
                    .concat(this.hotGridColumns.generateHotColumns('Type2Hot', 'Type 2'))
                    .concat(this.hotGridColumns.generateHotColumns('Type3Hot', 'Type 3'))
                    .concat(this.hotGridColumns.generateHotColumns('Type4Hot', 'Type 4'))
                    .concat(this.hotGridColumns.generateHotColumns('Type4HotGeneric', 'Type 4 generic'));
            }

            if (this.query.FluidId && this.query.FluidId.length > 0) {
                var sortedFluidIds = [...this.query.FluidId].sort((a, b) => a - b);

                sortedFluidIds
                    .forEach((fid, index) => {
                        let fluid = this.fluids.find(f => f.Id === fid);
                        if (fluid == null) return;
                        let fluidName = fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' - ' + ' (' + fluid.Dilution + ')';

                        columnDefs = columnDefs.concat([
                            {
                                headerName: fluidName,
                                children: [
                                    {
                                        colId: "Hot_" + fid,
                                        field: "Fluids[" + index + "].Hot",
                                        valueGetter: c => c.data?.Fluids[index]?.Hot,
                                        headerName: "LWE HOT (Minutes)",
                                        width: 250,
                                        sortable: false,
                                        searchable: false,
                                        alwaysSelected: true,
                                    },
                                ]
                            },
                            {
                                headerName: 'METAR',
                                children: [
                                    {
                                        colId: "ReferenceMetarMinHot_" + fid,
                                        field: "Fluids[" + index + "].ReferenceMetarMinHot",
                                        valueGetter: c => c.data?.Fluids[index]?.ReferenceMetarMinHot,
                                        headerName: "METAR Min. HOT (minutes)",
                                        width: 60,
                                        sortable: false,
                                        searchable: false,
                                        alwaysSelected: true,
                                    },
                                    {
                                        colId: "ReferenceMetarMaxHot_" + fid,
                                        field: "Fluids[" + index + "].ReferenceMetarMaxHot",
                                        valueGetter: c => c.data?.Fluids[index]?.ReferenceMetarMaxHot,
                                        headerName: "METAR Max. HOT (minutes)",
                                        width: 60,
                                        sortable: false,
                                        searchable: false,
                                        alwaysSelected: true,
                                    },
                                    {
                                        colId: "ReferenceMetarHotMessage_" + fid,
                                        field: "Fluids[" + index + "].ReferenceMetarHotMessage",
                                        valueGetter: c => c.data?.Fluids[index]?.ReferenceMetarHotMessage,
                                        headerName: "HOT message",
                                        width: 120,
                                        sortable: false,
                                        searchable: false,
                                        alwaysSelected: true,
                                    },
                                ]
                            }
                        ]);
                    })
            }

            this.columnDefs = columnDefs;
            if (source == 'rowperhot' && this.form.valid) {
                this.grid.refresh();
            }
        });
    } 
} 
