import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";

@Component({
    template: `
    <multiselect [width]="width">
        <multiselect-option *ngFor="let option of options|keys" [value]="option" [name]="options[option]"></multiselect-option>
    </multiselect>`
})
export class MultiselectCellRendererComponent implements ICellRendererAngularComp {
    options: any;
    ngModel: any;
    width: number;

    agInit(params: ICellRendererParams): void {
        this.options = params.colDef.cellRendererParams.options;
        this.width = params.colDef.width - 10;
        console.log(params.colDef.cellRendererParams)
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
}
