import { Pipe } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'utcDateTimeFormat',
    pure: false
})
export class UtcDateTimeFormatPipe {
    transform(value: string, format: string): string {
        if (!value) return '';

        return moment.utc(value).format(format);
    }
}
