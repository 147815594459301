import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {DialogService, IAgGridColGroupDef, IAgGridColumnDef, StorageService, ValueFormatters} from "swx.front-end-lib";
import {RawAcarsDialogComponent} from "../acarsOooiReports/AcarsOooiReports.component";

@Component({
    templateUrl: './AcarsIncomingMessage.component.html'
})
export class AcarsIncomingMessageComponent implements OnInit {
    storeKey = 'acarsIncomingMessageQuery'
    @ViewChild('grid') grid;
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };

    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.AcarsIncomingMessage;
    exportResourcePost = this.api.AcarsIncomingMessageExport;

    constructor(private storage: StorageService,
                private viewContainerRef: ViewContainerRef,
                private api: ApiService,
                private dialog: DialogService,
                private formatters: ValueFormatters) {
    }
    
    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery){
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    };

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }


    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: 'ACARS',
            children: [
                { colId: "AcarsIncomingMessage.Date", "field": "AcarsIncomingMessage.DateTime", headerName: "Date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left' },
                { colId: "AcarsIncomingMessage.Time", "field": "AcarsIncomingMessage.DateTime", headerName: "Time", width: 60, excelIgnore: true, pinned: 'left', valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "AcarsIncomingMessage.Id", "field": "AcarsIncomingMessage.Id", headerName: "#", width: 70, filterType: "integer" },
                { colId: "ClientName", "field": "ClientName", headerName: "Client", width: 120 },
                { colId: "AirportCode", "field": "AirportCode", headerName: "Airport", width: 80 },
                { colId: "AcarsIncomingMessage.TailNumber", "field": "AcarsIncomingMessage.TailNumber", headerName: "Registration number", width: 90 },
                { colId: "FlightIdentifier", "field": "FlightIdentifier", headerName: "Flight identifier", width: 75 },
                { colId: "IBMMQServerConfigurationName", "field": "IBMMQServerConfigurationName", headerName: "IBM MQ configuration", width: 200 },
                { colId: "AcarsIncomingMessage.Type", "field": "AcarsIncomingMessage.Type", headerName: "Type", width: 100, filterType: "enum", source: "AcarsType" },
                { colId: "AcarsIncomingMessage.Errors", "field": "AcarsIncomingMessage.Errors", headerName: "Error", width: 200, filterType: "enum", source: "AcarsIncomingMessageError" },
                { colId: "AcarsIncomingMessage.RawData", "field": "AcarsIncomingMessage.RawData", headerName: "Raw data", width: 1000, "cellTemplate": "<a (click)='parent.showRawData(params.data)'>{{params.data?.AcarsIncomingMessage.RawData}}</a>" },
                { colId: "IBMMQServerConfiguration.Type", "field": "IBMMQServerConfiguration.Type", headerName: "Format", width: 70, filterType: "enum", source: "IBMMQServerConfigurationType" },
                { colId: "AcarsIncomingMessage.DatalinkServiceProvider", "field": "AcarsIncomingMessage.DatalinkServiceProvider", headerName: "DSP", width: 50, filterType: "enum", source: "DatalinkServiceProvider" },
            ]
        },
        {
            headerName: 'IBM MQ',
            children: [
                { colId: "AcarsIncomingMessage.CreatedDate", "field": "AcarsIncomingMessage.CreatedDate", headerName: "Received time", width: 75, pinned: 'left', "searchable": false, valueFormatter: this.formatters.utcDateTimeFormatter() },
                { colId: "AcarsIncomingMessage.PutDateTime", "field": "AcarsIncomingMessage.PutDateTime", headerName: "PUT time", width: 75, pinned: 'left', "searchable": false, valueFormatter: this.formatters.utcDateTimeFormatter() },
            ]
        }
    ];


    showRawData(data) {
        this.dialog.show(this.viewContainerRef, RawAcarsDialogComponent,{
                data: data?.AcarsIncomingMessage.RawData
            }, 
            {
            title: 'ACARS',
            width: 360,
            height: 540,
            modal: true,
        });
    }
}
