import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiService} from "../shared/Api.service";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import {ChangesService, FileDownloadService} from "swx.front-end-lib";

@Component({
    templateUrl: './MobileDocumentProfileEdit.component.html'
})
export class MobileDocumentProfileEditComponent implements OnInit {
    @ViewChild('form') form;
    item;
    tab: string = 'basicInfo';
    returnPath = '';
    orderByFieldProcedures = 'procedure.Order';
    orderByFieldSections = 'section.Order';
    orderByFieldSectionDocuments = 'document.Order';
    equipmentTypes = this.api.EquipmentType.query();
    airports = this.api.Airport.query();
    
    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private router: Router,
                private route: ActivatedRoute,
                private ticketTrackingSystem: TicketTrackingSystemService,
                private changes: ChangesService,
                private fileDownload: FileDownloadService) {
    }
    
    ngOnInit() {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';
        
        if (copyId) {
            this.item = this.api.MobileDocumentProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
                if (this.item.MobileProcedures) {
                    this.item.MobileProcedures.forEach(spec => {
                        delete spec.MobileDocumentProfileId;
                    });
                }
                if (this.item.MobileGuidelineSections) {
                    this.item.MobileGuidelineSections.forEach(spec => {
                        delete spec.MobileDocumentProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.MobileDocumentProfile.create();
        } else {
            this.item = this.api.MobileDocumentProfile.get({ id: id });
        }

    }

    switchTab(tab: string): void {
        this.tab = tab;
    }

    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    };

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    };

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: 'MobileDocumentProfile',
            SubjectId: this.item.Id
        });
    };

    export() {
        this.api.MobileDocumentProfileExport.export({ id: this.item.Id }).then(response => {
            this.fileDownload.download(response.body, 'documentProfile.json');
        });
    };
    
    addMobileProcedure() {
        this.item.MobileProcedures = this.item.MobileProcedures || [];
        this.item.MobileProcedures.push({
            Order: this.item.MobileProcedures.map(f => f.Order).reduce((f1, f2) => Math.max(f1, f2), 0) + 1
        });
        this.form.form.markAsDirty(); //markAsDirty();
    }

    removeMobileProcedure(spec) {
        this.item.MobileProcedures.splice(this.item.MobileProcedures.indexOf(spec), 1);
        this.form.form.markAsDirty();
    }

    addMobileGuidelineSection() {
        this.item.MobileGuidelineSections = this.item.MobileGuidelineSections || [];
        this.item.MobileGuidelineSections.push({
            Order: this.item.MobileGuidelineSections.map(f => f.Order).reduce((f1, f2) => Math.max(f1, f2), 0) + 1
        });
        this.form.form.markAsDirty();
    }

    removeMobileGuidelineSection(spec) {
        this.item.MobileGuidelineSections.splice(this.item.MobileGuidelineSections.indexOf(spec), 1);
        this.form.form.markAsDirty();
    }

    addMobileGuidelineSectionDocument(section) {
        section.MobileGuidelineSectionDocuments = section.MobileGuidelineSectionDocuments || [];
        section.MobileGuidelineSectionDocuments.push({
            Order: section.MobileGuidelineSectionDocuments.map(f => f.Order).reduce((f1, f2) => Math.max(f1, f2), 0) + 1
        });
        this.form.form.markAsDirty();
    }

    removeMobileGuidelineSectionDocument(section, spec) {
        section.MobileGuidelineSectionDocuments.splice(section.MobileGuidelineSectionDocuments.indexOf(spec), 1);
        this.form.form.markAsDirty();
    }
}
