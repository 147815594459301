import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import moment from 'moment';

@Component({
    templateUrl: 'WeatherProfileRerun.component.html'
})
export class WeatherProfileRerunComponent implements OnInit {
    datePickerOptions: any;
    stations = this.api.Station.query({ IncludeInactive: false });
    sensorSelectMatricesGen3 = this.api.SensorSelectMatrix.query({StationType: 'Gen3'});
    query: any;
    running: boolean = false;
    results: any;
    moment = moment;

    constructor(private api: ApiService) {
    }

    ngOnInit() {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
            dateFormat: 'yy-mm-dd',
            useUtc: true
        };

        this.query = {
            DeleteExistingWeatherProfiles: true,
        };
    }

    start() {
        this.running = true;
        this.results = '';

        this.api.WeatherProfileRerun.post(this.query).then(response => {
            let subscription = this.api.WeatherProfileRerunTask.stream({ taskId: response.TaskId}).subscribe((result:any) => {
                this.results += result + '\n';
                if (result === 'Done') {
                    subscription.unsubscribe();
                    this.running = false;
                }
            });
        })
            .catch(err => {
                this.running = false;
            });
    }
}
