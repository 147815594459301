<div class="noMargins">
    <page-title>{{'Weather API'|translate}}</page-title>
    <form class="contentMargins form" name="form" #form="ngForm" (ngSubmit)="send()">
        <table class="table">
            <tr><th>Prod</th><td style="font-family: monospace;">https://api.surewx.com</td></tr>
            <tr><th>Test</th><td style="font-family: monospace;">https://test-api.surewx.com</td></tr>
            <tr><th>Local server</th><td style="font-family: monospace;">http://localhost:51515</td></tr>
        </table>
        <br />
        <label>
            <span class="caption">{{'URL'|translate}}</span>
            <input type="text" required [(ngModel)]="request.apiUrl" style="width: 500px;" name="apiUrl"/>
        </label>
        <label>
            <span class="caption">{{'Username (API token code)'|translate}}</span>
            <input type="text" required [(ngModel)]="request.username" name="username"/>
        </label>
        <label>
            <span class="caption">{{'Password (API private hash)'|translate}}</span>
            <input type="text" required [(ngModel)]="request.password" style="width: 400px;" name="password"/>
        </label>
        <label>
            <span class="caption">{{'Airport ICAO code'|translate}}</span>
            <input type="text" required [(ngModel)]="request.airportCode" style="width: 100px;" name="airportCode" />
        </label>

        <br />
        <input type="submit" [disabled]="!form.valid" [value]="'Send'|translate" />
    </form>
    <div class="result" *ngIf="!!result">
        <h2>{{'Result'|translate}}</h2>
        <pre>{{result|json}}</pre>
    </div>    
</div>
