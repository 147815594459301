import { Component } from '@angular/core';
import { AuthenticationService } from 'swx.front-end-lib';
import {ApiService} from "../shared/Api.service";

@Component({
    selector: '[menu]',
    templateUrl: 'Menu.component.html'
})
export class MenuComponent {
    currentUser;
    environment;

    constructor(
        private api: ApiService,
        private authentication: AuthenticationService,
    ) {
        this.api.Environment.queryObject().$promise.then(e => {
            this.environment = e;
        });
        
        this.authentication.getCurrentUser()
            .then(user => this.currentUser = user);

        this.authentication.userChange.subscribe(user => {
            this.currentUser = user;
        });
    }
}
