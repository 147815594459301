import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";

@Component({
    template: '<a [routerLink]="route">{{text}}</a>'
})
export class GridCellLinkRendererComponent implements ICellRendererAngularComp {
    route: string | string[] = []
    text: string = ''
    
    agInit(params: ICellRendererParams): void {
        this.text = params.value.toString()
        this.route = [...params.colDef.cellRendererParams.route, params.data.Id];
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
}
