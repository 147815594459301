import { Component, Input } from '@angular/core';
import { AgGridAngular } from "ag-grid-angular";

@Component({
    selector: 'pagination',
    templateUrl: 'Pagination.component.html',
    styleUrls: ['Pagination.component.css']
})
export class PaginationComponent {
    firstRowOnPage: number;
    lastRowOnPage: number;
    recordCount: number;
    currentPage: number;
    pageTotal: number;

    @Input() agGrid: AgGridAngular;
    @Input() pageSizes: number[];

    ngOnInit(): void {
        // Update on pagination changes
        this.agGrid.paginationChanged.subscribe(e => {
            this.recordCount = e.api.paginationGetRowCount() || 0;
            this.currentPage = e.api.paginationGetCurrentPage() + 1;
            this.pageTotal = e.api.paginationGetTotalPages();

            const pageSize: number = e.api.paginationGetPageSize();
            this.firstRowOnPage = e.api.paginationGetCurrentPage() * pageSize + 1;

            const lastRow = this.firstRowOnPage + pageSize - 1;
            this.lastRowOnPage = lastRow > this.recordCount ? this.recordCount : lastRow;
        });
    }

    // Change to the specified page, defaults to first/last if input cannot be parsed
    goToPage(pageNumber: string) {
        this.agGrid.api.paginationGoToPage(parseInt(pageNumber) - 1);
    }

    goToFirstPage() {
        this.agGrid.api.paginationGoToFirstPage();
    }

    goToPreviousPage() {
        this.agGrid.api.paginationGoToPreviousPage();
    }    

    goToNextPage() {
        this.agGrid.api.paginationGoToNextPage();
    }

    goToLastPage() {
        this.agGrid.api.paginationGoToLastPage();
    }

    // Update the number of items to show per page
    onPageSizeChanged(newPageSize: string) {
        this.agGrid.api.paginationSetPageSize(parseInt(newPageSize));
        this.agGrid.cacheBlockSize = parseInt(newPageSize);
    }
}
