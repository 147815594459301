<div style="display: flex; flex-direction: column; height: 100%;">
    <div>
        <label><input type="checkbox" (change)="toggleSelectedAll($event.target.checked)" [checked]="selectedCount === totalCount" />{{infoMessage}}</label>
        <hr>
    </div>
    <div style="flex: 1; overflow-y: scroll;">
        <ul>
            <ng-container *ngFor="let group of columnPreferences">
                <li *ngIf="group.headerName && group.children.length > 0">
                    <label><input type="checkbox" [checked]="group.allSelected" (change)="toggleSelectedAllGroup($event.target.checked, group)"/>{{group.headerName}}</label>
                    <ul>
                        <li *ngFor="let column of group.children">
                            <label><input type="checkbox" [checked]="column.selected" (change)="toggleSelected($event.target.checked, column)"/>{{column.headerName}}</label>
                        </li>
                    </ul>
                </li>
                <ng-container *ngIf="!group.headerName && group.children.length > 0">
                    <li *ngFor="let column of group.children">
                        <label><input type="checkbox" [checked]="column.selected" (change)="toggleSelected($event.target.checked, column)"/>{{column.headerName}}</label>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </div>
    <div>
        <div>
            <hr>
            <span>Format:</span>
            <label>
                <input type="radio" [(ngModel)]="exportFormat" [value]="'Excel'"> Excel
            </label>
            <label>
                <input type="radio" [(ngModel)]="exportFormat" [value]="'Csv'"> Csv
            </label>
            <button *ngIf="!running" [disabled]="selectedCount == 0" (click)="export()"><span class="mdi mdi-check"></span> {{'Export'|translate}}</button>
            <span><span *ngIf="running" class="busyIndicator"></span> {{status}}&nbsp;&nbsp;</span><button type="button" (click)="cancel()"><span class="mdi mdi-close"></span> {{'Cancel'|translate}}</button>
        </div>
    </div>
</div>
