import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {ConfirmService, GridComponent, IAgGridColumnDef, TranslateService, ValueFormatters} from "swx.front-end-lib";
import {Router} from "@angular/router";
import {HasPermissionService} from "../shared/HasPermission.pipe";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";

@Component({
    templateUrl: './AirportList.component.html'
})
export class AirportListComponent implements OnInit {
    @ViewChild('grid', { static: true }) grid: GridComponent;
    resource = this.api.Airport;
    exportResourcePost = this.api.AirportExport;
    query: any;
    includeInactive: false;
    searchQuery;
    
    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private formatters: ValueFormatters,
                private translateService: TranslateService,
                private router: Router,
                private hasPermission: HasPermissionService,
                private confirmService: ConfirmService,
                private ticketTrackingSystem: TicketTrackingSystemService) {
    }

    ngOnInit() {
        if (window.localStorage['airportsQuery']) {
            this.query = JSON.parse(window.localStorage['airportsQuery']);
            this.refresh();
        } else {
            this.resetQuery();
        }
    }

    columnDefs: IAgGridColumnDef[] = [
        { colId: "Id", field: "Id", headerName: "#", width: 50, filterType: 'integer', pinned: 'left' },
        { colId: "Name", field: "Name", headerName: "Name", width: 300, pinned: 'left', cellTemplate: `<a [routerLink]="['/airports', params.data.Id]">{{params.data.Name}}</a>` },
        { colId: "Active", field: "Active", headerName: "Active?", width: 70, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
        { colId: "Actions", field: "Id", headerName: "", width: 80, cellClass: "actions", cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/airports/' + c.data.Id + '" class="mdi mdi-pencil" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();"></a>'
                    + (this.hasPermission.hasPermission('ConfigDeleteAirports') ? '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>' : '')
                    + (this.hasPermission.hasPermission('ConfigAddAirports') ? '<a href="/airports/new?copy=' + c.data.Id + '" class="mdi mdi-content-copy" title="' + this.translateService.translate('Copy') + '" onclick="event.preventDefault();"></a>' : '');
                Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href'))));
                Array.from(container.querySelectorAll('.mdi-delete')).forEach(n => n.addEventListener('click', () => this.delete(c.data)));
                return container;
            }, pinned: "right", searchable: false, excelIgnore: true },
        { colId: "IATACode", field: "IATACode", headerName: "IATA code", width: 80, pinned: 'left' },
        { colId: "ICAOCode", field: "ICAOCode", headerName: "ICAO code", width: 80, pinned: 'left' },
        { colId: "Latitude", field: "Latitude", headerName: "Latitude", width: 100, filterType: 'integer' },
        { colId: "Longitude", field: "Longitude", headerName: "Longitude", width: 100, filterType: 'integer' },
        { colId: "City", field: "City", headerName: "City code", width: 150 },
        { colId: "Country", field: "Country", headerName: "Country", width: 150 },
        { colId: "Region", field: "Region", headerName: "Region", width: 150 },
        { colId: "TimeZone", field: "TimeZone", headerName: "Time zone", width: 150 },
        { colId: "HasSureForecastAllClear", field: "HasSureForecastAllClear", headerName: "Has SureforeCast AllClear?", width: 100, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
        { colId: "HasNowcast", field: "HasNowcast", headerName: "Has Nowcast?", width: 100, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
        { colId: "UseFlightAwareFirehose", field: "UseFlightAwareFirehose", headerName: "Use FlightAware Firehose?", width: 100, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
        { colId: "MetarSource", field: "MetarSource", headerName: "METAR source", width: 80 },
        { colId: "LastModified", field: "LastModified", headerName: "Modified date", width: 150, filterType: "date", valueFormatter: this.formatters.utcDateTimeFormatter() },
        { colId: "LastModifiedByUserName", field: "LastModifiedByUserName", headerName: "Modified by", width: 100 },
        { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 150, filterType: "date", valueFormatter: this.formatters.utcDateTimeFormatter() },
        { colId: "CreatedByUserName", field: "CreatedByUserName", headerName: "Created by", width: 100 },
    ];

    resetQuery() {
        this.searchQuery = '';
        this.query = {
            Filters: []
        };
        this.refresh();
    };
    
    refresh() {
        window.localStorage['airportsQuery'] = JSON.stringify(this.query);
        this.grid.refresh();
    }

    delete(item) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.ticketTrackingSystem.trackAndDelete(this.viewContainerRef, item, () => this.refresh());
        }).catch(() => {});
    }
}

