<div class="pagination-panel">
    <div class="page-size-input">
        <span>Page Size: </span>
        <select (change)="onPageSizeChanged(pageSizeSelectElement.value)" #pageSizeSelectElement>
            <option *ngFor="let pageSize of pageSizes" [value]="pageSize">{{pageSize}}</option>
        </select>
    </div>
    <span>{{firstRowOnPage}} to {{lastRowOnPage}} of {{recordCount}}</span>
    <span class="pagination-button" (click)="goToFirstPage()">&#xab;</span>
    <span class="pagination-button" (click)="goToPreviousPage()">&#x2039;</span>
    <span>Page <input #pageNumberInput [(ngModel)]="currentPage" (keyup.enter)="goToPage(pageNumberInput.value)" /> of {{pageTotal || 'more'}}</span>
    <span class="pagination-button" (click)="goToNextPage()">&#x203a;</span>
    <span class="pagination-button" (click)="goToLastPage()">&#xbb;</span>
</div>