<div class="noMargins">
    <page-title>{{'ACARS request'|translate}}</page-title>
    <form class="contentMargins form" #form="ngForm">
        <label>
            <span class="caption">{{'Current Time'|translate}}</span>
            <span>{{localDate$|async|date: 'EEEEEE d LLL YY - HH:mm:ss'}}</span>
        </label>
        <label>
            <span class="caption">{{'Current UTC Time'|translate}}</span>
            <span>{{localDate$|async|date: 'EEEEEE d LLL YY - HH:mm:ss': 'utc'}}</span>
        </label>

        <table class="table">
            <tr>
                <th>{{'ACARS request'|translate}}</th>
                <th *ngIf="decodedString?.Input && !(decodedString?.Errors && decodedString?.Errors.includes('DspError'))">{{'Decoded info'|translate}}</th>
                <th *ngIf="!(decodedString?.Errors.length <= 0 || decodedString?.Errors == null)">{{'Error(s)'|translate}}</th>
            </tr>
            <tr>
                <td style="vertical-align: top;width: 300px;">
                    <textarea style="width: 250px; height: 250px; font-family: monospace;" [(ngModel)]="acarsRequest" name="acarsRequest"></textarea>
                    <br/><br/>
                    <label class="inline">
                        <span class="caption">{{'From file'|translate}}</span>
                        <file-as-data required file-as-data [(ngModel)]="acarsRequest" name="acarsRequest"></file-as-data>
                    </label>
                    <br/>
                    <button [disabled]="!form.valid" (click)="decode()">{{'Decode'|translate}}</button>
                </td>

                <ng-container *ngIf="decodedString">
                    <td *ngIf="decodedString?.Input != null" style="width: 400px;">
                        <label>
                            <span class="caption">{{'Priority'|translate}}</span>
                            <span>{{decodedString?.Input.Priority}}</span>
                        </label>
                        <label>
                            <span class="caption">{{'Receiver'|translate}}</span>
                            <span>{{decodedString?.Input.DestinationIataAddress}}</span>
                        </label>
                        <label>
                            <span class="caption">{{'Sender'|translate}}</span>
                            <span>{{decodedString?.Input.SenderIataAddress}}</span>
                        </label>
                        <label>
                            <span class="caption">{{'Timestamp'|translate}}</span>
                            <span>{{decodedString?.Input.DateTime|utcDateTimeFormat:root.timeAcarsFormatLong}}</span>
                        </label>
                        <label>
                            <span class="caption">{{'HOT start time'|translate}}</span>
                            <span>{{decodedString?.Input.HotStartDateTime|utcDateTimeFormat:root.timeAcarsFormatLong}}</span>
                        </label>
                        <label>
                            <span class="caption">{{'Anti-ice time'|translate}}</span>
                            <span>{{decodedString?.Input.AntiIceStartTime|utcDateTimeFormat:root.timeAcarsFormatLong}}</span>
                        </label>
                        <label>
                            <span class="caption">{{'Tail number'|translate}}</span>
                            <span>{{decodedString?.Input.TailNumber}}</span>
                        </label>
                        <label *ngIf="decodedString?.Input.EquipmentTypeIdentifier != null">
                            <span class="caption">{{'Equipment type identifier'|translate}}</span>
                            <span>{{decodedString?.Input.EquipmentTypeIdentifier}}</span>
                        </label>
                        <label>
                            <span class="caption">{{'Flight identifier'|translate}}</span>
                            <span>{{decodedString?.Input.FlightIdentifier}}</span>
                        </label>
                        <label>
                            <span class="caption">{{'Client IATA code'|translate}}</span>
                            <span>{{decodedString?.Input.ClientCode}}</span>
                        </label>
                        <label>
                            <span class="caption">{{'Client'|translate}}</span>
                            <span>{{decodedString?.ClientName}}</span>
                        </label>
                        <label>
                            <span class="caption">{{'Airport code'|translate}}</span>
                            <span>{{decodedString?.Input.AirportCode}}</span>
                        </label>
                        <label *ngIf="decodedString?.Dsp != null">
                            <span class="caption">{{'Dsp target'|translate}}</span>
                            <span>{{decodedString?.Dsp.Address}}</span>
                        </label>
                        <br />
                        <div *ngIf="decodedString?.WheelsUpData != null || decodedString?.DeicingData != null">
                            <div *ngIf="decodedString?.WheelsUpData != null">
                                <h3 align="center">{{'Wheels Up'|translate}}</h3>
                                <hr />
                                <label>
                                    <span class="caption">{{'Message Header'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.MessageHeader}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Flight Number'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.FlightNumber}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Departure Date'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.DepartureDate|utcDateTimeFormat:root.dateFormat}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Out Event Time'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.OutEventTime|utcDateTimeFormat:root.timeFormat}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Off Event Time'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.OffEventTime|utcDateTimeFormat:root.timeFormat}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Est Time Of Arrival'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.EstTimeOfArrival|utcDateTimeFormat:root.timeFormat}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Utc Time'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.UtcTime|utcDateTimeFormat:root.timeFormatLong}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Revised Out Time'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.RevisedOutTime|utcDateTimeFormat:root.timeFormat}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Init Event Time'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.InitEventTime|utcDateTimeFormat:root.timeFormat}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'In Event Time'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.InEventTime|utcDateTimeFormat:root.timeFormat}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Origin Station'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.OriginStation}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Destination Station'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.DestinationStation}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'TailNumber'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.TailNumber}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'ATS Flight ID'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.FlightNumber}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Fuel Of Board'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.FuelOfBoard}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Pax'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.Pax}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'DLY1'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.Dly1}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'DLY2'|translate}}</span>
                                    <span>{{decodedString?.WheelsUpData.Dly2}}</span>
                                </label>
                            </div>
                            <div *ngIf="decodedString?.DeicingData != null">
                                <h3 align="center">{{'De-Icing'}}</h3>
                                <label>
                                    <span class="caption">{{'Start Time'|translate}}</span>
                                    <span>{{decodedString?.DeicingData.StartTime|utcDateTimeFormat:root.timeFormat}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Utc Day'|translate}}</span>
                                    <span>{{decodedString?.DeicingData.UtcDay|utcDateTimeFormat:root.dateFormat}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Utc Time'|translate}}</span>
                                    <span>{{decodedString?.DeicingData.UtcTime|utcDateTimeFormat:root.timeFormat}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Origine Station'|translate}}</span>
                                    <span>{{decodedString?.DeicingData.OriginStation}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Destination Station'|translate}}</span>
                                    <span>{{decodedString?.DeicingData.DestinationStation}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Fluid Type'|translate}}</span>
                                    <span>{{decodedString?.DeicingData.FluidType}}</span>
                                </label>
                                <label>
                                    <span class="caption">{{'Flight Number'|translate}}</span>
                                    <span>{{decodedString?.DeicingData.FlightNumber}}</span>
                                </label>
                            </div>
                        </div>
                    </td>
                    <td *ngIf="!(decodedString?.Errors.length <= 0 || decodedString?.Errors == null)"  style="vertical-align: top">
                        <ul *ngFor="let error of decodedString?.Errors">
                            <li>{{error}}</li>
                        </ul>
                    </td>
                </ng-container>

            </tr>
        </table>
        <br/><br/>
        <table class="table" *ngIf="(decodedString?.Errors && decodedString?.Errors.includes('DspError'))">
                                <tr>
                                <th>{{'Rejection'|translate}}</th>
                                </tr>
                                <tr>
                                <td>
                                <span>{{'Rejected response detected'|translate}}</span><br/>
                                <span ng-hide="mailSample == '' || mailSample == null">{{'email has been sent to the client contacts'|translate}}</span>
                                </td>
                                </tr>
                                </table>
        <input type="button" *ngIf="decodedString?.Errors == null || (decodedString?.Errors.length == 0)" [disabled]="decodedString?.Input == null || (!decodedString?.IsHotRequest)" (click)="getResponse(decodedString?.Input.RawData)" value="{{'Get response'|translate}}"/>
        <br/><br/>
    </form>
    <table class="table" *ngIf="!(response?.Output == '' || response?.Output == null)">
            <tr>
                <th>{{'Response with routing lines'|translate}}</th>
                <th>{{'Response as shown in aircraft'|translate}}</th>
                <th *ngIf="response?.HotResponse">{{'HOT response info'|translate}}</th>
            </tr>
            <tr>
                <td style="vertical-align: top;">
                    <div style="height: 520px;">
                        <textarea readonly cols="{{response?.NbCharactersPerLine}}" [(ngModel)]="response.Output" style="height: 500px;"></textarea>
                    </div>
                    <input type="button" (click)="download(response?.Output)" value="{{'Download'|translate}}" />
                </td>
                <td style="vertical-align: top;">
                    <div style="height: 520px; position: relative; white-space: pre; line-height: 0;">
                        <textarea readonly cols="{{response?.NbCharactersPerLine}}" [style.height.px]="response?.NbLinesPerPage * 3 * 16" [(ngModel)]="response.OutputNoRoutingInfo" style="display: block; position: absolute; background-color: transparent;"></textarea>
                        <textarea disabled cols="{{response?.NbCharactersPerLine}}" [style.height.px]="2 + response?.NbLinesPerPage * 16" style="display: block; position: relative; background-color: transparent; border: 1px solid lightgray;"></textarea>
                        <textarea disabled cols="{{response?.NbCharactersPerLine}}" [style.height.px]="2 + response?.NbLinesPerPage * 16" style="margin: -2px 0 0 0; display: block; position: relative; background-color: transparent; border: 1px solid lightgray;"></textarea>
                    </div>
                    <input type="button" (click)="download(response?.OutputNoRoutingInfo)" value="{{'Download'|translate}}"/>
                </td>
                <td *ngIf="response?.HotResponse" style="vertical-align: top; width: 400px;" class="form">
                    <label>
                        <span class="caption">{{'#'|translate}}</span>
                        <span>{{response?.HotResponse?.Id}}</span>
                    </label>
                    <label>
                        <span class="caption">{{'Wing material type'|translate}}</span>
                        <span>{{response?.HotResponse?.WingMaterialType	}}</span>
                    </label>
                    <label>
                        <span class="caption">{{'Message'|translate}}</span>
                        <span>{{response?.HotResponse?.Message}}</span>
                    </label>
                    <label>
                        <span class="caption">{{'Error'|translate}}</span>
                        <span>{{response?.HotResponse?.Error}}</span>
                    </label>
                    <label>
                        <span class="caption">{{'METAR usage reason'|translate}}</span>
                        <span>{{response?.HotResponse.MetarUsageReason}}</span>
                    </label>
                    <div *ngFor="let hot of response?.HotResponse.HotResponseHots">
                        <label>{{hot.FluidName + (hot.FluidType != 'Type1' ? ' ' + hot.FluidDilution : '') + ' (TYPE ' + hot.FluidType.replace('Type', '') + ') '}}</label>
                        <label>{{hot.Message}}</label>
                    </div>
                </td>
            </tr>
        </table>
</div>

