<page-title>{{('SQS Queue'|translate) + ': ' + (item.Name || 'new')}}</page-title>
<form #form #ngForm="ngForm" (submit)="save()">
    <div id="userEditPage" class="noMargins">
        <div class="toolbar">
            <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <a class="tab" (click)="switchTab('serviceProviders')" [class.active]="tab == 'serviceProviders'" [class.invalid]="!ngForm.form.controls.serviceProvidersForm?.valid">{{'Deicing Service Providers'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        </div>

        <fieldset class="contentMargins form" [disabled]="!('ConfigEditSQSQueueConfigurations'|hasPermission)" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="Name" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="Active" />
            </label>
            <label>
                <span class="caption">{{'Type'|translate}}</span>
                <select [(ngModel)]="item.Type" name="Type">
                    <option *ngFor="let item of configurationTypes|keyvalue" [value]="item.key">{{item.value}}</option>
                </select>
            </label>
            <label>
                <span class="caption">{{'AWS access key'|translate}}</span>
                <input type="text" required [(ngModel)]="item.AWSAccessKey" name="AWSAccessKey" />
            </label>
            <label>
                <span class="caption">{{'AWS secret key'|translate}}</span>
                <input type="text" required [(ngModel)]="item.AWSSecretKey" name="AWSSecretKey" />
            </label>
            <label>
                <span class="caption">{{'Queue URL'|translate}}</span>
                <input type="text" required [(ngModel)]="item.QueueUrl" name="QueueUrl" />
            </label>
            <label>
                <span class="caption">{{'Wait time (seconds)'|translate}}</span>
                <input type="text" required [(ngModel)]="item.WaitTimeSeconds" name="WaitTimeSeconds" />
            </label>
        </fieldset>

        <fieldset [disabled]="!('ConfigEditSQSQueueConfigurations'|hasPermission)" class="tabContent contentMargins form" [hidden]="tab != 'serviceProviders'" ngModelGroup="serviceProvidersForm">
            <div class="contentMargins" *ngIf="item.Type == 'GTAACSV' || item.Type == 'Icelink2' || item.Type == 'SwedaviaSpreadsheet'">
                <label class="inline">
                    <select [(ngModel)]="serviceProvider" name="serviceProvider">
                        <option *ngFor="let serviceProvider of serviceProviders|serviceProviderNotEnabled:item.SQSQueueConfigurationDeicingServiceProviders|orderBy:'Name'" [ngValue]="serviceProvider">
                            {{serviceProvider.Name}}
                        </option>
                    </select>
                    <input type="button" class="green" *ngIf="'ConfigEditSQSQueueConfigurations'|hasPermission" (click)="addServiceProvider()" value="{{'Add service provider'|translate}}" [disabled]="serviceProvider == null" />
                </label>
                <br />
                <table class="table">
                    <tr>
                        <th>{{'Name'|translate}}</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let sqsQueueConfigurationDeicingServiceProvider of item.SQSQueueConfigurationDeicingServiceProviders">
                        <td>{{(serviceProviders|findById:sqsQueueConfigurationDeicingServiceProvider.DeicingServiceProviderId)?.Name}}</td>
                        <td>
                            <a *ngIf="'ConfigEditSQSQueueConfigurations'|hasPermission" (click)="removeServiceProvider(sqsQueueConfigurationDeicingServiceProvider)" class="mdi mdi-delete"></a>
                        </td>
                    </tr>
                </table>
            </div>

            <div class="contentMargins" *ngIf="item.Type == 'Icelink'">
                <label class="inline">
                    <input type="button" class="green" *ngIf="'ConfigEditSQSQueueConfigurations'|hasPermission" (click)="addEmail()" value="{{'Add email'|translate}}" />
                </label>
                <br />
                <table class="table">
                    <tr>
                        <th>{{'Email'|translate}}</th>
                        <th>{{'Service provider'|translate}}</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let sqsQueueConfigurationEmail of item.SQSQueueConfigurationEmails; let i = index">
                        <td>
                            <input type="text" [(ngModel)]="sqsQueueConfigurationEmail.Email" [name]="'sqsQueueConfigurationEmail[' + i + '].Email'" required style="width: 300px;"/>
                        </td>
                        <td>
                            <select required [(ngModel)]="sqsQueueConfigurationEmail.DeicingServiceProviderId" [name]="'sqsQueueConfigurationEmail[' + i + '].DeicingServiceProviderId'">
                                <option *ngFor="let serviceProvider of serviceProviders|orderBy:'Name'" [ngValue]="serviceProvider.Id">
                                    {{serviceProvider.Name}}
                                </option>
                            </select>
                        </td>
                        <td>
                            <a *ngIf="'ConfigEditSQSQueueConfigurations'|hasPermission" (click)="removeEmail(sqsQueueConfigurationEmail)" class="mdi mdi-delete"></a>
                        </td>
                    </tr>
                </table>
            </div>
        </fieldset>
    </div>
</form>
