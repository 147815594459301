<div class="noMargins">
	<page-title>{{('Holdover taxi time prediction configuration'|translate) + ': ' + item.Name}}</page-title>
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [ngClass]="{active : tab === 'basicInfo', invalid: !form.form.controls.basicInfoForm?.valid }">{{'Basic info'}}</a>
        <input type="submit" (click)="save()" [disabled]="!form.form.valid || !form.form.dirty" value="{{'Save'|translate}}"/>
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}"/>
        <input type="button" *ngIf="'DataChanges'|hasPermission" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}"/>
    </div>

    <form class="form" #form="ngForm">
        <fieldset class="contentMargins" [disabled]="!('ConfigEditHoldoverTaxiTimeModelConfigurations'|hasPermission)" *ngIf="tab === 'basicInfo'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="Name" maxlength="100"/>
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="Active"/>
            </label>
	        <label>
				<span class="caption">{{'Number of input flights'|translate}}</span>
		        <input type="number" step="1" min="0" [(ngModel)]="item.NumberOfEvents" name="NumberOfEvents" required />
	        </label>
	        <label>
				<span class="caption">{{'Z-score'|translate}}</span>
		        <input type="number" step="any" [(ngModel)]="item.ZScore" name="ZScore" required />
	        </label>
	        <label>
				<span class="caption">{{'Lambda (λ)'|translate}}</span>
		        <input type="number" step="any" [(ngModel)]="item.Lambda" name="Lambda" required />
	        </label>
	        <label>
				<span class="caption">{{'Delta (δ)'|translate}}</span>
		        <input type="number" step="any" [(ngModel)]="item.Delta" name="Delta" required />
	        </label>
            <label>
				<span class="caption">{{'Regression coefficients β (JSON array "[1,2,3]")'|translate}}</span>
                <textarea required [(ngModel)]="item.RegressionCoefficientsJson" name="RegressionCoefficientsJson" rows="1" cols="85"></textarea>
            </label>
	        <label>
				<span class="caption">{{'Matrix X (JSON nested array "[[1,2,3],[1,2,3]]")'|translate}}</span>
		        <textarea required [(ngModel)]="item.MatrixJson" name="MatrixJson" rows="10" cols="85"></textarea>
	        </label>
	        <label>
				<span class="caption">{{'Minimum valid result'|translate}}</span>
		        <input type="number" step="any" [(ngModel)]="item.MinimumValidResult" name="MinimumValidResult" />
	        </label>
	        <label>
				<span class="caption">{{'Maximum valid result'|translate}}</span>
		        <input type="number" step="any" [(ngModel)]="item.MaximumValidResult" name="MaximumValidResult" />
	        </label>
        </fieldset>
    </form>
</div>
