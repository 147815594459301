import {Component, OnInit, ViewChild} from "@angular/core";
import {IAgGridColumnDef, StorageService, ValueFormatters} from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";

@Component({
    templateUrl: './SureForecastAllClear.component.html'
})
export class SureForecastAllClearComponent implements OnInit {

    @ViewChild('grid') grid;
    storeKey = 'sureForecastAllClearQuery';
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    query: any;

    airports = this.api.Airport.query();
    resource = this.api.SureForecastAllClear;
    exportResourcePost = this.api.SureForecastAllClearExport;

    constructor(private storage: StorageService,
                private api: ApiService,
                private formatters: ValueFormatters) {
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery){
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    };

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }
    
    columnDefs: IAgGridColumnDef[] = [
        { colId: "SureForecastAllClear.Id", field: "SureForecastAllClear.Id", headerName: "#", width: 70, filterType: "integer", pinned: 'left' },
        { colId: "SureForecastAllClear.RetrievedDate", field: "SureForecastAllClear.RetrievedDate", headerName: "Retrieved date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left', filterType: "date" },
        { colId: "SureForecastAllClear.RetrievedTime", field: "SureForecastAllClear.RetrievedDate", headerName: "Retrieved time", width: 80, valueFormatter: this.formatters.utcTimeFormatter(), "excelIgnore": true, pinned: 'left', "searchable": false },
        { colId: "SureForecastAllClear.ResponseTime", field: "SureForecastAllClear.ResponseTime", headerName: "Response time (ms)", width: 80, excelIgnore: true, pinned: 'left' },
        { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 70 },
        { colId: "SureForecastAllClear.ValidFromDate", field: "SureForecastAllClear.ValidFrom", headerName: "Valid from date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "SureForecastAllClear.ValidFromTime", field: "SureForecastAllClear.ValidFrom", headerName: "Valid from time", width: 80, valueFormatter: this.formatters.utcTimeFormatter(), "excelIgnore": true, "searchable": false },
        { colId: "SureForecastAllClear.ValidForMinutes", field: "SureForecastAllClear.ValidForMinutes", headerName: "Valid for (minutes)", width: 100, filterType: "integer" },
        { colId: "SureForecastAllClear.AllClear", field: "SureForecastAllClear.AllClear", headerName: "All clear?", width: 100, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
        { colId: "SureForecastAllClear.Error", field: "SureForecastAllClear.Error", headerName: "Error", width: 250 },
        { colId: "SureForecastAllClear.Confidence", field: "SureForecastAllClear.Confidence", headerName: "Confidence", width: 100, filterType: "float" },
    ];
} 
