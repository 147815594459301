<div class="noMargins">
    <page-title>{{'Weather alerts'|translate}}</page-title>
    <form #form="ngForm" class="toolbar gridFilters">
        <div class="field inline">
            <span class="caption">{{'Client'|translate}}</span>
            <multiselect name="query.ClientId" [(ngModel)]="query.ClientId">
                <multiselect-option *ngFor="let option of clients|orderBy:'Name'" [value]="option.Id" [name]="option.Name"></multiselect-option>
            </multiselect>
        </div>
        <div class="field inline">
            <span class="caption">{{'Airport'|translate}}</span>
            <multiselect [(ngModel)]="query.AirportId" name="query.AirportId">
                <multiselect-option *ngFor="let airport of airports|orderBy:['-HasStation', 'ICAOCode']" [value]="airport.Id" [name]="airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name"></multiselect-option>
            </multiselect>
        </div>
        <div class="field inline">
            <span class="caption">{{'Profile'|translate}}</span>
            <multiselect name="query.WeatherAlertProfileId" [(ngModel)]="query.WeatherAlertProfileId">
                <multiselect-option *ngFor="let option of weatherAlertProfiles|orderBy:'Name'" [value]="option.Id" [name]="option.Name"></multiselect-option>
            </multiselect>
        </div>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="query.FromDate">
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
        </label>
        <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
        <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button type="button" (click)="apply()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid"><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid"><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>            <button type="button" (click)="manualExitClick()">{{'Reset alerts'|translate}}</button>
        <button type="button" (click)="manualExitClick()">{{'Reset alerts'|translate}}</button>
    </form>

    <grid #grid gridId="EngineCoverAlerts" [resource]="resource" [exportResource]="exportResourcePost" [query]="query" [columnDefs]="columnDefs" [headerHeight]="75" [parent]="this"></grid>
</div>
