import { Component } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { StorageService } from "swx.front-end-lib";

@Component({
    templateUrl: './HotCalculator.component.html'
})
export class HotCalculatorComponent {
    private storageKey = 'hotCalculatorRequest';
    request: any;
    result: any;
    selectedFluid: any;
    datePickerOptions: any;
    stations = this.api.Station.query({ IncludeInactive: true });
    sensorSelectMatrices = this.api.SensorSelectMatrix.query({ IncludeInactive: true });
    fluids = this.api.Fluid.query({ IncludeInactive: true });
    fluidVersions = this.api.FluidVersion.query({ IncludeInactive: true });
    weatherCategories = this.api.WeatherCategory.query();
    metarWarnings = this.api.MetarWarning.query();

    constructor(private api: ApiService, private storage: StorageService) {
        let maxDateTime = new Date();
        maxDateTime.setDate(maxDateTime.getDate() + 1);
        this.datePickerOptions = {
            showTime: true,
            timezone: 0,
            showTimezone: true,
            maxDateTime: maxDateTime,
            dateFormat: 'yy-mm-dd',
            timeFormat: 'HH:mm:ss',
            useUtc: true,
        };

        this.request = this.storage.load(this.storageKey) || {};
    }

    profile() {
        this.storage.save(this.storageKey, this.request);

        this.api.HotCalculation.queryObject(this.request).$promise.then(result => {
            this.result = result;
        });
    }
}
