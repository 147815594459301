import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import moment from 'moment';
import { NotificationService } from './Notification.service';
import { AuthenticationService } from './auth/Authentication.service';
import { BusyIndicatorService } from './BusyIndicator.service';
import { EnvironmentConfiguration } from "./EnvironmentConfiguration.model";
import { DialogService } from "./Dialog.service";

@Injectable()
export abstract class ExportApi {
    abstract ExcelExportTask: IResource<any>;
    abstract ExcelExportResult: IResource<any>;
    abstract Change: IResource<any>;
    abstract ChangeExport: IResource<any>;
    abstract ChangeAlert: IResource<any>;
}

export declare type QueryParams = HttpParams
    | {
        [param: string]: string | string[];
    }
    | object;

export interface IResourceItem {
    $save(options?: Options, params?: QueryParams): Promise<any>;
    $delete(options?: Options, params?: QueryParams): Promise<any>;
    $promise: Promise<any>;
}

export interface IResourceItemList<T extends IResourceItem> extends Array<T> {
    $headers: HttpHeaders;
    $promise: Promise<IResourceItemList<T>>;
}

export interface IResourceItemMap<T> extends Map<string, T> {
    $promise: Promise<Map<string, T>>;
}

export interface IEnumResource {
    query(): IEnumObject<string>;
}

export type IEnumObject<TKey extends string> = {
    [key in TKey]: TKey extends "$promise" ? Promise<IEnumObject<TKey>> : string;
} & {
    $promise: Promise<IEnumObject<TKey>>
}

export interface IResource<T extends IResourceItem> {
    post(body, options?: Options, params?: QueryParams): Promise<any>;
    delete(params?: QueryParams, options?: Options): Promise<any>;
    create(values?: any, options?: Options): T | IResourceItem;
    put(id: any, body, options?: Options): Promise<any>;
    put(body, options?: Options): Promise<any>;
    patch(id: any, body, options?: Options): Promise<any>;
    get(id: any, options?: Options): T | IResourceItem;
    query(params?: QueryParams, options?: Options): IResourceItemList<T>;
    queryObject(params?: QueryParams, options?: Options): T;
    queryMap<TValue>(params?: QueryParams, options?: Options): IResourceItemMap<TValue>;
    export(params?: any, options?: Options): Promise<HttpResponse<Blob>>;
    exportItem(id: any, options?: Options): Promise<HttpResponse<Blob>>;
    exportPost(body, options?: Options, params?: QueryParams): Promise<HttpResponse<Blob>>;
    stream(params?: QueryParams): Observable<T>;
    download(filename: string, params?: QueryParams);
}

export abstract class Options {
    anonymous?: boolean;
    ignoreErrors?: boolean;
    silent?: boolean;
    //observe?: 'body' | 'events' | 'response';
}

@Injectable({ providedIn: 'root' })
export class ApiFactoryService {
    private enumCache = new Map<string, IEnumObject<string>>();
    private serviceOfflineSince: moment.Moment = null;
    private serviceOfflineNotification: any = null;
    minimumMinutesForOfflineNotification = 0;
    
    constructor(
        public http: HttpClient,
        public authentication: AuthenticationService,
        public busyIndicator: BusyIndicatorService,
        private notificationService: NotificationService,
        private dialogService: DialogService,
        public environment: EnvironmentConfiguration,
    ) {
    }

    private startRequest(options?: Options) {
        if (options == null || !options.silent) {
            this.busyIndicator.setBusy(true);
        }
    }

    private endRequest(options?: Options) {
        if (options == null || !options.silent) {
            this.busyIndicator.setBusy(false);
        }

        if (this.serviceOfflineSince) {
            this.serviceOfflineSince = null;
            this.serviceOfflineNotification?.remove();
            this.serviceOfflineNotification = null;
        }
    }

    private handleError(options: Options, error: HttpErrorResponse) {
        if (options == null || !options.silent) {
            this.busyIndicator.setBusy(false);
        }
        
        if (options == null || !options.ignoreErrors) {
            if (error.status === 0 || error.status === 504) {
                if (this.serviceOfflineSince == null) {
                    this.serviceOfflineSince = moment();
                } else if (moment().diff(this.serviceOfflineSince, 'minute') >= this.minimumMinutesForOfflineNotification) {
                    const errorMessageEl = jQuery('<div>Please close this window and try again.</div>');
                    this.serviceOfflineNotification = this.notificationService.show(errorMessageEl,
                        {
                            type: 'error',
                            sticky: true,
                            clickToDismiss: false,
                            showClose: false,
                        });
                }
            } else if (error.status !== 401) {
                var data = error.error ? error.error : null;
                if (error.error instanceof Blob) {
                    var reader = new FileReader();
                    reader.onload = () => {
                        data = JSON.parse(reader.result as string);
                        this.showError(error, error.error);
                    };
                    reader.readAsText(data);
                } else {
                    this.showError(error, data);
                }
            }
        }
        return Promise.reject(error);
    }

    private showError(error: HttpErrorResponse, data?: any) {
        let title = 'Error (' + error.status + ')';
        let width = 400;
        let errorMessage = '';
        
        if (typeof data === 'string') {
            errorMessage += '<div style="text-align: left; font-weight: bold; margin-top: 15px; font-size: 18px;">' + data + '</div>';
            title += ': ' + error.statusText;
            if (error.url) {
                errorMessage += '<div style="font-size: 12px; color: #aaaaaa;"><h3>URL</h3>' + error.url + '</div>';
            }
        } else if (typeof data === 'object' && data != null) {
            width = 800;
            title += ': ' + error.statusText;
            errorMessage = '<div style="text-align: left;"><h3>Message</h3>' + (data.Message || data.error_description || '');
            if (data.MessageDetail) {
                errorMessage += '<br /><h3>Detail</h3>' + data.MessageDetail;
            }
            if (data.ExceptionMessage) {
                errorMessage += '<br /><h3>Exception message</h3>' + data.ExceptionMessage;
            }
            if (data.ExceptionType) {
                errorMessage += '<br /><h3>Type</h3>' + data.ExceptionType;
            }
            if (data.StackTrace) {
                errorMessage += '<br /><h3>Stack trace</h3><pre>' + data.StackTrace + '</pre>';
            }
            if (error.url) {
                errorMessage += '<h3>URL</h3>' + error.url;
            }
            errorMessage += '</div>';
        } else {
            errorMessage = error.statusText;
            if (error.url) {
                errorMessage += '<div style="font-size: 12px; color: #aaaaaa;"><h3>URL</h3>' + error.url + '</div>';
            }
        }
        
        this.dialogService.showForHtml(errorMessage, {
            title: title,
            width: width,
            dialogClass: 'errorPopup',
            modal: true,
        });
        this.busyIndicator.setBusy(false);
    }
    
    enumResourceFactory(url: string): IEnumResource {
        var self = this;
        return {
            query(): IEnumObject<string> {
                if (self.enumCache.has(url)) {
                    return self.enumCache.get(url);
                }

                var result = {};

                self.enumCache.set(url, result as any);
                
                Object.defineProperty(result, "$promise", {
                    enumerable: false,
                    writable: true,
                    value: self.http
                        .get(self.environment.apiUrl + url)
                        .toPromise()
                        .then(items => {
                            self.endRequest();
                            Object.entries(items).map(([key, value]) => {
                                result[key] = value;
                            });
                        }).then(_ => result)
                });

                self.startRequest();

                return result as any;
            }
        }
    }

    resourceFactory<T extends IResourceItem>(url: string, type?: { new(): T }): IResource<T> {
        var self = this;

        function decorateItem(item) {
            if (typeof item === 'string') {
                return null;
            }

            let $save = (options?: Options, params?: QueryParams): Promise<Response> => {
                self.startRequest(options)

                var promise = item.Id
                    ? self.http.put(self.environment.apiUrl + url + '/' + item.Id,
                        item,
                        {
                            headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                            params: params as any
                        }).toPromise()
                    : self.http.post(self.environment.apiUrl + url,
                        item,
                        {
                            headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                            params: params as any
                        }).toPromise();

                promise
                    .then(response => {
                        self.endRequest(options);
                        if (!item.Id) item.Id = response;
                    })
                    .catch(error => self.handleError(options, error));

                return promise as any;
            };

            let $delete = (options?: Options, params?: QueryParams): Promise<Response> => {
                self.startRequest(options)

                var promise = self.http.delete(self.environment.apiUrl + url + '/' + item.Id,
                    {
                        headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                        params: params as any
                    }).toPromise();

                promise
                    .then(() => {
                        self.endRequest(options);
                    })
                    .catch(error => self.handleError(options, error));

                return promise as any;
            };

            Object.defineProperty(item, "$save", {
                enumerable: false,
                writable: true,
                value: $save,
            });

            Object.defineProperty(item, "$delete", {
                enumerable: false,
                writable: true,
                value: $delete,
            });
        }

        return {
            post(body, options?: Options, params?: QueryParams): Promise<object> {
                self.startRequest(options)

                return self.http.post(self.environment.apiUrl + url,
                    body,
                    {
                        headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                        params: params as any
                    })
                    .toPromise()
                    .then(result => {
                        self.endRequest(options);
                        return result;
                    })
                    .catch(error => self.handleError(options, error));
            },
            put(id: any, body, options?: Options): Promise<object> {
                if (typeof id === 'object') id = id['id'];

                self.startRequest(options)

                return self.http.put(self.environment.apiUrl + url + '/' + id,
                    body,
                    {
                        headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                    })
                    .toPromise()
                    .then(result => {
                        self.endRequest(options);
                        return result;
                    })
                    .catch(error => self.handleError(options, error));
            },
            patch(id: any, body, options?: Options): Promise<object> {
	            if (typeof id === 'object' && id != null) id = id['id'];

	            self.startRequest(options)

	            return self.http.patch(self.environment.apiUrl + url + '/' + id,
		            body,
		            {
			            headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
		            })
                    .toPromise()
		            .then(result => {
			            self.endRequest(options);
                        return result;
		            })
		            .catch(error => self.handleError(options, error));
            },
            delete(params?: QueryParams, options?: Options): Promise<object> {
                self.startRequest(options)

                return self.http.delete(self.environment.apiUrl + url, {
                        params: params as any,
                        headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                    }).toPromise()
                    .then(result => {
                        self.endRequest(options);
                        return result;
                    })
                    .catch(error => self.handleError(options, error));
            },
            create(values?: any): T {
                const item = (type ? new type() : {}) as T | IResourceItem;

                if (values) {
                    Object.keys(values).forEach(i => item[i] = values[i]);
                }

                decorateItem(item);

                Object.defineProperty(item, "$promise", {
                    enumerable: false,
                    writable: true,
                    value: Promise.resolve(item),
                });
                
                return item as T;
            },
            get(id: any, options?: Options): T {
                if (typeof id === 'object') id = id['id'];

                const item = type ? new type() : {};

                decorateItem(item);

                self.startRequest(options)

                Object.defineProperty(item, "$promise", {
                    enumerable: false,
                    writable: true,
                    value: self.http.get(self.environment.apiUrl + url + '/' + id,
                        {
                            headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                        })
                        .toPromise()
                        .then(obj => {
                            self.endRequest(options);

                            for (let key in obj) {
                                if (obj.hasOwnProperty(key)) {
                                    item[key] = obj[key];
                                }
                            }

                            return item;
                        })
                        .catch(error => self.handleError(options, error)),
                });

                return item as T;
            },
            query(params?: QueryParams, options?: Options): IResourceItemList<T> {
                var items = new Array<T>() as IResourceItemList<T>;

                self.startRequest(options)

                Object.defineProperty(items, "$promise", {
                    enumerable: false,
                    writable: true,
                    value: self.http.get<T[]>(self.environment.apiUrl + url, {
                            params: params as any,
                            headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                            observe: "response"
                        })
                        .toPromise()
                        .then(response => {
                            self.endRequest(options);
                            Array.prototype.push.apply(items, response.body);
                            items.$headers = response.headers;
                            items.forEach(decorateItem);
                            return items;
                        })
                        .catch(error => self.handleError(options, error)),
                });
                
                return items;
            },
            queryObject(params?: QueryParams, options?: Options): T {
                const item = type ? new type() : {};

                self.startRequest(options)

                Object.defineProperty(item, "$promise", {
                    enumerable: false,
                    writable: true,
                    value: self.http.get(self.environment.apiUrl + url, {
                            params: params as any,
                            headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                        })
                        .toPromise()
                        .then(obj => {
                            self.endRequest(options);

                            if (typeof obj === "string") return obj;
                            
                            for (let key in obj) {
                                if (obj.hasOwnProperty(key)) {
                                    item[key] = obj[key];
                                }
                            }

                            return item;
                        })
                        .catch(error => self.handleError(options, error)),
                });

                return item as T;
            },
            queryMap<TValue>(params?: QueryParams, options?: Options): IResourceItemMap<TValue> {
                var items = new Map<string, TValue>() as IResourceItemMap<TValue>;

                self.startRequest(options)

                Object.defineProperty(items, "$promise", {
                    enumerable: false,
                    writable: true,
                    value: self.http.get(self.environment.apiUrl + url, {
                            params: params as any,
                            headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                        })
                        .toPromise()
                        .then(obj => {
                            self.endRequest(options);

                            for (let key in obj) {
                                if (obj.hasOwnProperty(key)) {
                                    items.set(key, obj[key]);
                                }
                            }
                            
                            return items;
                        })
                        .catch(error => self.handleError(options, error)),
                });

                return items;
            },
            "export"(params?: QueryParams, options?: Options): Promise<HttpResponse<Blob>> {
                self.startRequest(options)

                return self.http.get(self.environment.apiUrl + url, {
                        params: params as any,
                        headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                        responseType: 'blob',
                        observe: 'response',
                    })
                    .toPromise()
                    .then(result => {
                        self.endRequest(options);
                        return result;
                    })
                    .catch(error => self.handleError(options, error)) as any;
            },
            exportItem(id: any, options?: Options): Promise<HttpResponse<Blob>> {
	            self.startRequest(options)
                
	            if (typeof id === 'object') id = id['id'];

	            return self.http.get(self.environment.apiUrl + url + '/' + id, {
                        headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                        responseType: 'blob',
                        observe: 'response',
                    })
                    .toPromise()
		            .then(result => {
			            self.endRequest(options);
                        return result;
		            })
		            .catch(error => self.handleError(options, error));
            },
            exportPost(body, options?: Options, params?: QueryParams): Promise<HttpResponse<Blob>> {
                self.startRequest(options)

                return self.http.post(self.environment.apiUrl + url,
                    body,
                    {
                        params: params as any,
                        headers: (options && options.anonymous) ? null : self.authentication.getHeaders(),
                        responseType: 'blob',
                        observe: 'response',
                    })
                    .toPromise()
                    .then(result => {
                        self.endRequest(options);
                        return result;
                    })
                    .catch(error => self.handleError(options, error));
            },
            stream(params?: QueryParams): Observable<T> {
                return new Observable((observer) => {
                    var fullUrl = self.environment.apiUrl + url;
                    if (params != null) {
                        fullUrl += '?' + ((params instanceof HttpParams) ? params : new HttpParams(({ fromObject: params as any }))).toString();
                    }

                    var source = new EventSource(fullUrl);

                    source.addEventListener('message', e => {
                        var data = JSON.parse(e.data);
                        observer.next(data);
                    }, false);

                    return {
                        unsubscribe() {
                            source.close();
                        }
                    };
                });
            },
            download(filename: string, params?: QueryParams) {
                var fullUrl = self.environment.apiUrl + url;
                if (params != null) {
                    fullUrl += '?' + ((params instanceof HttpParams) ? params : new HttpParams(({ fromObject: params as any }))).toString();
                }

                var a = document.createElement("a");
                // safari doesn't support this yet
                if (typeof a.download === 'undefined') {
                    window.location.href = fullUrl;
                } else {
                    a.href = fullUrl;
                    a.download = filename;
                    a.target = '_blank';
                    document.body.appendChild(a);
                    setTimeout(() => {
                        a.click();
                    }, 1);
                }
            },
        }
    }
}
