import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { IAgGridColGroupDef } from "swx.front-end-lib";
import { RootScope } from "../shared/RootScope.service";
import { ValueFormatterParams } from "ag-grid-community";
import moment from 'moment';

@Component({
    templateUrl: 'ReportHumanObservationsWithWeatherProfiles.component.html'
})
export class ReportHumanObservationsWithWeatherProfilesComponent implements OnInit {
    stations: Array<any> = [];
    resource = this.api.HumanObservationWeatherProfile;
    exportResourcePost = this.api.HumanObservationWeatherProfileExport;
    query: any;
    sensorSelectMatrices: any;
    metarWeatherMappingProfiles: any;
    datePickerOptions: any;

    dateFormatter() {
        return (params: ValueFormatterParams) => params.value ? moment(params.value).format(this.root.dateFormat) : '';
    }

    timeFormatter() {
        return (params: ValueFormatterParams) => params.value ? moment(params.value).format(this.root.timeFormat) : '';
    }

    constructor(
        private api: ApiService,
        private root: RootScope,
    ) {
    }

    ngOnInit() {
        this.query = {
            StationId: null,
            SensorSelectMatrixId: null,
            MetarWeatherMappingProfileId: null,
            FromDate: null,
            ToDate: null,
        }

        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
            dateFormat: 'yy-mm-dd',
            useUtc: true
        }

        Promise.all([
            this.api.Station.query().$promise,
            this.api.SensorSelectMatrix.query().$promise,
            this.api.MetarWeatherMappingProfile.query().$promise,
        ]).then(([stations, sensorSelectMatrices, metarWeatherMappingProfiles]) => {
            this.stations = stations;
            this.sensorSelectMatrices = sensorSelectMatrices;
            this.metarWeatherMappingProfiles = metarWeatherMappingProfiles;
        });
    }

    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: "Human observation",
            children: [
                { colId: "HumanObservation.DateTimeWalkingBackDate", field: "HumanObservation.DateTimeWalkingBack", headerName: "Walking back date", width: 90, pinned: 'left', valueFormatter: this.dateFormatter() },
                { colId: "HumanObservation.DateTimeWalkingBackTime", field: "HumanObservation.DateTimeWalkingBack", headerName: "Walking back time", width: 60, excelIgnore: true, pinned: 'left', valueFormatter: this.timeFormatter() },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "StationName", field: "StationName", headerName: "Station", width: 80 },
                { colId: "SensorSelectMatrixName", field: "SensorSelectMatrixName", headerName: "Sensor select matrix", width: 200 },
            ]
        },
        {
            headerName: "Human observation",
            children: [
                { colId: "HumanObservation.DataPointNumber", field: "HumanObservation.DataPointNumber", headerName: "Data point #", width: 60 },
                { colId: "HumanObservation.TestNumber", field: "HumanObservation.TestNumber", headerName: "Test #", width: 60 },
                { colId: "HumanObservation.LWE", field: "HumanObservation.LWE", headerName: "Pan 1 & 2 average LWE (g/dm²/h)", width: 60 },
                { colId: "HumanObservation.WeatherType", field: "HumanObservation.WeatherType", headerName: "Weather type", width: 70 },
                { colId: "HumanObservation.Comments", field: "HumanObservation.Comments", headerName: "Comments", width: 100 },
                { colId: "HumanObservation.IsRateValid", field: "HumanObservation.IsRateValid", headerName: "Is rate valid?", width: 70 },
                { colId: "HumanObservation.IsWeatherTypeValid", field: "HumanObservation.IsWeatherTypeValid", headerName: "Is weather type visible?", width: 70 },
            ]
        },
        {
            headerName: 'Weather profile',
            children: [
                { colId: "WeatherProfile.DateTimeDate", field: "WeatherProfile.DateTime", headerName: "Time", width: 60, initialHide: true, valueFormatter: this.dateFormatter() },
                { colId: "WeatherProfile.DateTimeTime", field: "WeatherProfile.DateTime", headerName: "Time", width: 60, excelIgnore: true, valueFormatter: this.timeFormatter() },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "WeatherProfile.LWE", field: "WeatherProfile.LWE", headerName: "LWE (g/dm²/h)", width: 60 },
                { colId: "WeatherProfile.Temperature", field: "WeatherProfile.Temperature", headerName: "Temperature (°C)", width: 110 },
                { colId: "WeatherProfile.RelativeHumidity", field: "WeatherProfile.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 80 },
                { colId: "WeatherProfile.DewPoint", field: "WeatherProfile.DewPoint", headerName: "Dew point (°C)", width: 80 },
                { colId: "DewPointDifference", field: "DewPointDifference", headerName: "T - Dew point (°C)", width: 80 },
                { colId: "WeatherProfile.ConeSurfaceTemperature", field: "WeatherProfile.ConeSurfaceTemperature", headerName: "Active Frost sensor temperature (°C)", width: 110 },
                { colId: "WeatherProfile.LWE", field: "WeatherProfile.LWE", headerName: "LWE (g/dm²/h)", width: 60 },
                { colId: "WeatherProfile.WindSpeed", field: "WeatherProfile.WindSpeed", headerName: "Wind speed (m/s)", width: 60 },
                { colId: "WeatherProfile.WeatherCategory", field: "WeatherProfile.WeatherCategory", headerName: "Weather category", width: 80 },
                { colId: "WeatherProfile.WeatherType", field: "WeatherProfile.WeatherType", headerName: "Weather type", width: 80 },
                { colId: "WeatherProfile.WeatherWarnings", field: "WeatherProfile.WeatherWarnings", headerName: "Weather warnings", width: 150 },
                { colId: "WeatherProfile.CampbellPWS100WeatherTypeNg", field: "WeatherProfile.CampbellPWS100WeatherTypeNg", headerName: "Campbell PWS100 weather type (NG)", width: 80 },
                { colId: "WeatherProfile.ThiesLPMWeatherTypeNg", field: "WeatherProfile.ThiesLPMWeatherTypeNg", headerName: "Thies LPM weather type (NG)", width: 80 },
                { colId: "WeatherProfile.InstantWeatherTypeNg", field: "WeatherProfile.InstantWeatherTypeNg", headerName: "Instant weather type (NG)", width: 80 },
                { colId: "WeatherProfile.MostRecentWeatherTypeNg", field: "WeatherProfile.MostRecentWeatherTypeNg", headerName: "Most recent weather type (NG)", width: 80 },
                { colId: "WeatherProfile.WeatherTypeSubroutine", field: "WeatherProfile.WeatherTypeSubroutine", headerName: "Subroutine (NG)", width: 100 },
                { colId: "WeatherProfile.Errors", field: "WeatherProfile.Errors", headerName: "Errors", width: 400 },
            ]
        },
        {
            headerName: 'Campbell PWS100',
            children: [
                { colId: "WeatherProfile.WeatherCategoryCampbellPWS100", field: "WeatherProfile.WeatherCategoryCampbellPWS100", headerName: "Weather category", width: 80 },
            ]
        },
        {
            headerName: 'Thies LPM',
            children: [
                { colId: "WeatherProfile.WeatherCategoryThiesLPM", field: "WeatherProfile.WeatherCategoryThiesLPM", headerName: "Weather category", width: 80 },
            ]
        },
        {
            headerName: 'Vaisala FD12P',
            children: [
                { colId: "WeatherProfile.WeatherCategoryVaisalaFD12P", field: "WeatherProfile.WeatherCategoryVaisalaFD12P", headerName: "Weather category", width: 80 },
            ]
        },
        {
            headerName: 'Sartorius',
            children: [
                { colId: "WeatherProfile.RawLWESartorius", field: "WeatherProfile.RawLWESartorius", headerName: "Pre-regression LWE (g/dm²/h)", width: 80 },
            ]
        },
        {
            headerName: 'Campbell PWS100',
            children: [
                { colId: "WeatherProfile.RawLWECampbellPWS100", field: "WeatherProfile.RawLWECampbellPWS100", headerName: "Pre-regression LWE (g/dm²/h)", width: 105 },
            ]
        },
        {
            headerName: 'Thies LPM',
            children: [
                { colId: "WeatherProfile.RawLWEThiesLPM", field: "WeatherProfile.RawLWEThiesLPM", headerName: "Pre-regression LWE (g/dm²/h)", width: 80 },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "WeatherProfile.LWESartorius", field: "WeatherProfile.LWESartorius", headerName: "Avg. uncorrected LWE (g/dm²/h)", width: 90 },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "WeatherProfile.LWECampbellPWS100", field: "WeatherProfile.LWECampbellPWS100", headerName: "Avg. uncorrected LWE (g/dm²/h)", width: 110 },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "WeatherProfile.LWEThiesLPM", field: "WeatherProfile.LWEThiesLPM", headerName: "Avg. uncorrected LWE (g/dm²/h)", width: 90 },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "WeatherProfile.CombinedLWE", field: "WeatherProfile.CombinedLWE", headerName: "Combined LWE (g/dm²/h)", width: 100 },
                { colId: "WeatherProfile.UnroundedLWE", field: "WeatherProfile.UnroundedLWE", headerName: "Unrounded LWE (g/dm²/h)", width: 100 },
                { colId: "WeatherProfile.OperationalToleranceLWE", field: "WeatherProfile.OperationalToleranceLWE", headerName: "Operational tolerance LWE (g/dm²/h)", width: 100 },
            ]
        },
        {
            headerName: 'Sensor reading',
            children: [
                { colId: "TelegramNumber", field: "TelegramNumber", headerName: "Telegram number", width: 90 },
            ]
        },
        {
            headerName: 'Thies LPM',
            children: [
                { colId: "ThiesLPMWMOCode", field: "ThiesLPMWMOCode", headerName: "WMO", width: 60 },
                { colId: "ThiesLPMIntensity", field: "ThiesLPMIntensity", headerName: "Intensity (mm/h)", width: 75 },
                { colId: "ThiesLPMVisibility", field: "ThiesLPMVisibility", headerName: "Visibility (m)", width: 75 },
            ]
        },
        {
            headerName: 'Campbell PWS100',
            children: [
                { colId: "CampbellPWS100WMOCode", field: "CampbellPWS100WMOCode", headerName: "WMO", width: 60 },
                { colId: "CampbellPWS100Intensity", field: "CampbellPWS100Intensity", headerName: "Intensity (mm/h)", width: 100 },
                { colId: "CampbellPWS100Visibility", field: "CampbellPWS100Visibility", headerName: "Visibility (m)", width: 100 },
            ]
        },
        {
            headerName: 'Vaisala FD12P',
            children: [
                { colId: "VaisalaFD12PWMOCode", field: "VaisalaFD12PWMOCode", headerName: "WMO", width: 60 },
                { colId: "VaisalaFD12PWMOCode15Minutes", field: "VaisalaFD12PWMOCode15Minutes", headerName: "WMO 15 min.", width: 100 },
                { colId: "VaisalaFD12PIntensity", field: "VaisalaFD12PIntensity", headerName: "Intensity (mm/h)", width: 100 },
                { colId: "VaisalaFD12PVisibility", field: "VaisalaFD12PVisibility", headerName: "Visibility (m)", width: 100 },
                { colId: "VaisalaFD12PCumulativeWater", field: "VaisalaFD12PCumulativeWater", headerName: "Water precipitation sum (mm)", width: 100 },
                { colId: "VaisalaFD12PCumulativeSnow", field: "VaisalaFD12PCumulativeSnow", headerName: "Snow precipitation sum (mm)", width: 100 },
            ]
        },
        {
            headerName: 'Biral SWS-200/250',
            children: [
                { colId: "BiralSWS200WMOCode", field: "BiralSWS200WMOCode", headerName: "WMO", width: 60 },
                { colId: "BiralSWS200Intensity", field: "BiralSWS200Intensity", headerName: "Intensity (mm/h)", width: 100 },
            ]
        },
        {
            headerName: 'METAR',
            children: [
                { colId: "MetarReadingAge", field: "MetarReadingAge", headerName: "Age (minutes)", width: 60 },
                { colId: "MetarReading.WxString", field: "MetarReading.WxString", headerName: "Weather string", width: 90 },
                { colId: "FinalMetarWeatherType", field: "FinalMetarWeatherType", headerName: "Final weather Type", width: 90 },
                { colId: "MetarReading.VisibilityStatuteMi", field: "MetarReading.VisibilityStatuteMi", headerName: "Visibility (SM)", width: 90 },
                { colId: "MetarReading.MetarSource", field: "MetarReading.MetarSource", headerName: "Source", width: 110 },
                { colId: "MetarReading.CumulativePrecipitation1Hour", field: "MetarReading.CumulativePrecipitation1Hour", headerName: "Past hour precipitation (mm)", width: 110 },
                { colId: "MetarReading.PrecipitationSincePreviousMetarReading", field: "MetarReading.PrecipitationSincePreviousMetarReading", headerName: "Precipitation since previous METAR (mm)", width: 110 },
                { colId: "MinutesSincePreviousMetarReading", field: "MinutesSincePreviousMetarReading", headerName: "Minutes since previous METAR", width: 110 },
                { colId: "MetarReading.RawData", field: "MetarReading.RawData", headerName: "Raw", width: 1000}
            ]
        },
    ];
}
