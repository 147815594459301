import {Component, Input, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {DialogService, IAgGridColGroupDef, ValueFormatters} from "swx.front-end-lib";
import {formatNumber} from "@angular/common";
import {StationDataRawDataDialogComponent} from "../stationDataGen2/StationDataRawDataDialog.component";
import moment from 'moment';

@Component({
    selector: 'swx-barrel-icing-emails',
    template: `
        <div style="flex: 0.5 1 auto; display: flex; flex-direction: column; height: 100%">
            <grid gridId="barrelIcingEmails" #grid [resource]="resource" [query]="query" [columnDefs]="columnDefs" [parent]="this"></grid>
        </div>`
})
export class BarrelIcingEmailsComponent {
    _query: any;
    @Input() set query(value: any) {
        if (this.grid) this.grid.refresh();
        this._query = value;
    }
    
    get query() {
        return this._query;
    }
    
    @ViewChild('grid') grid;
    resource = this.api.BarrelIcingEmail;
    
    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private formatters: ValueFormatters,
                private dialog: DialogService) {
    }

    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: "Emails",
            children: [
                { colId: "EmailInfo.CreatedDate", field: "EmailInfo.CreatedDate", headerName: "Date", width: 90, pinned: 'left', valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "EmailInfo.CreatedTime", field: "EmailInfo.CreatedDate", headerName: "Time", width: 60, excelIgnore: true, pinned: 'left', valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "EmailInfo.Id", field: "EmailInfo.Id", headerName: "Id", width: 50 },
                { colId: "TypeName", field: "TypeName", headerName: "Type", width: 150},
                { colId: "AirportIcaoCode", field: "AirportIcaoCode", headerName: "Airport", width: 80},
                { colId: "StationName", field: "StationName", headerName: "Station", width: 80},
                { colId: "ClientName", field: "ClientName", headerName: "Client", editable: false },
                { colId: "ContactName", field: "ContactName", headerName: "Contact", editable: false },
                { colId: "EmailInfo.Recipient", field: "EmailInfo.Recipient", headerName: "Recipient", editable: false, width: 190 },
                { colId: "Body", field: "Body", headerName: "Body", cellTemplate: `<a (click)='parent.showEmail(params.data)' class='mdi mdi-eye'></a>`, width: 50  },
                { colId: "Temperature", field: "Temperature", headerName: "Temp (°C)", width: 80,  valueFormatter: (params) => params.value ? formatNumber(params.value, 'en-us','1.1-1'): ''},
                { colId: "TemperatureSource", field: "TemperatureSource", headerName: "Temp source", width: 90},
                { colId: "TemperatureDewPointDiff", field: "TemperatureDewPointDiff", headerName: "(Temperature - Dew point) (°C)", valueFormatter: (params) => params.value ? formatNumber(params.value, 'en-us','1.1-1'): ''},
                { colId: "Lwe", field: "Lwe", headerName: "LWE", width: 60, valueFormatter: (params) => params.value ? formatNumber(params.value, 'en-us','1.2-2') : ''},
            ],
        },
        {
            headerName: "Amazon SES",
            children: [
                { colId: "AmazonSESStatus", field: "AmazonSESStatus", headerName: "Status", width: 100, cellTemplate: `<div [style.background-color]="params.data?.AmazonSESStatus === 'Bounce' ? '#ffaaaa' : 'unset'">{{params.data?.AmazonSESStatus}}</div>` },
                { colId: "AmazonSESStatusUpdateDate", field: "AmazonSESStatusUpdateDate", headerName: "Status updated on", width: 200, valueFormatter: this.formatters.utcDateTimeFormatter(), filterType: 'date' }
            ],
        },
    ];
    
    showEmail(data) {
        var bodyIndex = data.Body?.lastIndexOf("<body>");
        const body = bodyIndex > -1 ? (data.Body?.substring(bodyIndex + 6, data.Body.lastIndexOf("</body>"))) : data.Body;

        this.dialog.show(this.viewContainerRef, StationDataRawDataDialogComponent, {
            html: body || data.Body,
        }, {
            title: data.EmailInfo.Subject,
            width: 1030,
            height: 680,
            modal: true,
        })
    }
}
