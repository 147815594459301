import { Component, Input } from "@angular/core";
import { IAgGridColumnDef, ValueFormatters } from 'swx.front-end-lib';

interface FlightExceedingHot {
    HoldoverStartTime: string;
    AirportICAOCode: string;
    FlightIdentifier: string;
    HotRequestStartTime: string;
    WheelsUpTime: string;
    Type1Hot: string;
    Type4Hot: string;
    TaxiTime: string;
    WasType4Applied: boolean;
    ExceededBy: string;
    DeicingRecordId: number;
    DeicingRecordHoldoverStartTime;
}

@Component({
    selector: 'flights-exceeding-hot',
    templateUrl: 'FlightsExceedingHot.component.html'
})
export class FlightsExceedingHotComponent {
    @Input() flight: FlightExceedingHot[];

    constructor(private formatters: ValueFormatters) {
    }

    colDefs: IAgGridColumnDef[] = [
        { field: 'HoldoverStartTime', colId: 'HoldoverStartTime', headerName: 'Date', valueFormatter: this.formatters.utcDateFormatter()},
        { field: 'AirportICAOCode', colId: 'AirportICAOCode', headerName: 'Airport'},
        { field: 'FlightIdentifier', colId: 'FlightIdentifier', headerName: 'Flight number'},
        { field: 'HotRequestDateTime', colId: 'HotRequestDateTime', headerName: 'HOT request time', valueFormatter: this.formatters.utcTimeFormatter()},
        { field: 'HoldoverStartTime', colId: 'HoldoverStartTime', headerName: 'Holdover start time', valueFormatter: this.formatters.utcTimeFormatter()},
        { field: 'WheelsUpTime', colId: 'WheelsUpTime', headerName: 'Wheels up time', valueFormatter: this.formatters.utcTimeFormatter()},
        { field: 'Type1Hot', colId: 'Type1Hot', headerName: 'Type 1 HOT (minutes)'},
        { field: 'Type4Hot', colId: 'Type4Hot', headerName: 'Type 4 HOT (minutes)'},
        { field: 'TaxiTime', colId: 'TaxiTime', headerName: 'Taxi time (minutes)'},
        { field: 'WasType4Applied', colId: 'WasType4Applied', headerName: 'Was type 4 applied?'},
        { field: 'ExceededBy', colId: 'ExceededBy', headerName: 'Exceeded by (minutes)'},
        { field: 'DeicingRecordId', colId: 'DeicingRecordId', headerName: 'Deicing record #'},
        { field: 'DeicingRecordHoldoverStartTime', colId: 'DeicingRecordHoldoverStartTime', headerName: 'Deicing record holdover start time', valueFormatter: this.formatters.utcTimeFormatter()},
    ];
}
