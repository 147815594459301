<th>{{'Sensor'|translate}}</th>
<th>{{'Enabled'|translate}}</th>
<th>{{'Priority'|translate}}</th>
<th>{{'Aggregation'|translate}}</th>
<th width="80">{{'# of 1 min. samples'|translate}}</th>
<th width="80">{{'Minimum samples'|translate}}</th>
<th width="120">
    <span *ngIf="type == 'WMO'">{{'WMO code mapping profile'|translate}}</span>
    <span *ngIf="type == 'Intensity'">{{'Snow spike correction?'|translate}}</span>
</th>
