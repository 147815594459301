<div id="metarReadings" class="noMargins">
    <page-title>{{'METARS'|translate}}</page-title>
    <form #form="ngForm">
        <div class="toolbar gridFilters">
            <span class="tab" (click)="switchTab('grid')" [ngClass]="{active : tab === 'grid'}">{{'Grid'}}</span>
            <span class="tab" (click)="switchTab('map')" [ngClass]="{active : tab === 'map'}">{{'Map'}}</span>
            <input *ngIf="tab === 'map'" type="button" (click)="refreshMap()" value="{{'Refresh now'|translate}}" />
        </div>

        <div class="toolbar gridFilters" *ngIf="tab === 'grid'">
            <div class="field inline">
                <span class="caption">{{'Airport'|translate}}</span>
                <multiselect [(ngModel)]="query.AirportId" name="query.AirportId">
                    <multiselect-option *ngFor="let airport of airports|orderBy:['-HasStation', 'ICAOCode']" [value]="airport.Id" [name]="airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name"></multiselect-option>
                </multiselect>
            </div>
            <label class="inline">
                <span class="caption">{{'Final type/HOT reference client'|translate}}</span>
                <select [(ngModel)]="query.ClientId" name="query.ClientId">
                    <option [ngValue]="null">{{'None'|translate}}</option>
                    <option *ngFor="let client of clients|orderBy:'Name'" [value]="client.Id">
                        {{client.Name}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Fluid'|translate}}</span>
                <select [(ngModel)]="query.fluidId" name="query.FluidId">
                    <option [ngValue]="null">{{'None'|translate}}</option>
                    <option *ngFor="let fluid of fluids|orderBy:['FluidProfileName', 'Type', 'Name']" [value]="fluid.Id">
                        {{fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + '(' + fluid.Dilution + ')'}}
                    </option>
                </select>
            </label>
            <label class="inline">
                <span class="caption">{{'From'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="query.FromDate">
            </label>
            <label class="inline">
                <span class="caption">{{'To'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
            </label>
            <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
            <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
            <button type="button" (click)="apply()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
            <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
            <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid" ><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
        </div>
    </form>

    <grid [hidden]="tab === 'map'" #grid gridId='preferences.MetarReading.ColumnExport' [resource]="resource" [exportResource]="exportResourcePost" [query]="query" [columnDefs]="columnDefs" [headerHeight]="76"></grid>
    
    <div [hidden]="tab === 'grid'" #metarMap class="userMap"></div>

</div>
