import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {DialogService, IAgGridColGroupDef, QuerySerializerService, StorageService, ValueFormatters} from "swx.front-end-lib";
import {HotGridColumnsService} from "../hotData/HotGridColumns.service";
import {RawAcarsDialogComponent} from "../acarsOooiReports/AcarsOooiReports.component";
import {RequestDetailDialogComponent} from "../hotMap/RequestDetailDialog.component";

@Component({
    templateUrl: './DeicingReports.component.html'
})
export class DeicingReportsComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'deicingReportsQuery';
    query: any = {};
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.DeicingReport;
    exportResourcePost = this.api.DeicingReportExport;
    hotGridcolumnDefs = this.hotGridColumns.getBaseColumns()
        .concat(this.hotGridColumns.generateHotColumns('Type1AHot', 'Type 1 aluminum'))
        .concat(this.hotGridColumns.generateHotColumns('Type1CHot', 'Type 1 composite'))
        .concat(this.hotGridColumns.generateHotColumns('Type2Hot', 'Type 2'))
        .concat(this.hotGridColumns.generateHotColumns('Type3Hot', 'Type 3'))
        .concat(this.hotGridColumns.generateHotColumns('Type4Hot', 'Type 4'))
        .concat(this.hotGridColumns.generateHotColumns('Type4HotGeneric', 'Type 4 generic'));
    metarWeatherTypes = this.api.MetarWeatherType.query();

    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: 'Deicing report',
            children: [
                { colId: "Date", field: "CreatedDate", headerName: "Received date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left', filterType: "date" },
                { colId: "Time", field: "CreatedDate", headerName: "Received time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left', searchable: false },
                { colId: "Id", field: "Id", headerName: "#", width: 70, filterType: "integer", pinned: 'left' },
                { colId: "Type", field: "Type", headerName: "Type", width: 100, pinned: 'left', filterType: "enum", source: "DeicingReportType" },
                { colId: "StartDate", field: "StartTime", headerName: "Start date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left', filterType: "date" },
                { colId: "StartTime", field: "StartTime", headerName: "Start time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left', searchable: false },
                { colId: "ClientName", field: "ClientName", headerName: "Client", width: 120 },
                { colId: "AirportCode", field: "AirportCode", headerName: "Airport", width: 80 },
                { colId: "TailNumber", field: "TailNumber", headerName: "Airplane", width: 80 },
                { colId: "EquipmentTypeIdentifier", field: "EquipmentTypeIdentifier", headerName: "Equipment type identifier", width: 100 },
                { colId: "FlightIdentifier", field: "FlightIdentifier", headerName: "Flight identifier", width: 100 },
                { colId: "FluidType", field: "FluidType", headerName: "Fluid type", width: 80 },
            ]
        },
        {
            headerName: 'Mobile app',
            children: [
                { colId: "FluidName", field: "FluidName", headerName: "Fluid", width: 250 },
                { colId: "HotRequestId", field: "HotRequestId", headerName: "HOT request #", width: 100, filterType: "integer", cellTemplate: `<div *ngIf='params.data?.HotRequestId'><a (click)='parent.showHotRequest(params.data)'>{{params.data?.HotRequestId}}</a></div>` },
            ]
        },
        {
            headerName: 'Online HOT response',
            children: [
                { colId: "WeatherReport", field: "WeatherReport", headerName: "Weather report", width: 150 },
                { colId: "UseAdjustedHot", field: "UseAdjustedHot", headerName: "Adjusted HOT used?", width: 70, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
            ]
        },
        {
            headerName: 'ACARS',
            children: [
                { colId: "RawData", field: "RawData", headerName: "Raw data", width: 70, cellTemplate: `<div *ngIf='params.data?.RawData'><a (click)='parent.showAcars(params.data.RawData)'>{{'ACARS'|translate}}</a></div>` },
                { colId: "DestinationStation", field: "DestinationStation", headerName: "Destination", width: 100 },
                { colId: "EndDate", field: "EndDate", headerName: "End date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), "searchable": false },
                { colId: "EndTime", field: "EndTime", headerName: "End time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), "searchable": false },
            ]
        },
        {
            headerName: 'Weather override',
            children: [
                { colId: "MetarWeatherType", field: "MetarWeatherType", headerName: "Weather type", width: 150, valueFormatter: (c) => 
                        c.value && typeof this.metarWeatherTypes[c.value] !== 'undefined' ? this.metarWeatherTypes[c.value]: c.value
                    , filterType: "enum", source: "MetarWeatherType" },
                { colId: "Temperature", field: "Temperature", headerName: "Temperature", width: 100, filterType: "float" },
                { colId: "MinHot", field: "MinHot", headerName: "Min HOT", width: 80, filterType: "float" },
                { colId: "MaxHot", field: "MaxHot", headerName: "Max HOT", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'Deicing report',
            children: [
                { colId: "Latitude", field: "Latitude", headerName: "Latitude", width: 100, filterType: "float" },
                { colId: "Longitude", field: "Longitude", headerName: "Longitude", width: 100, filterType: "float" },
            ]
        }
    ];

    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private storage: StorageService,
                private dialog: DialogService,
                private hotGridColumns: HotGridColumnsService,
                private formatters: ValueFormatters,
                private querySerializer: QuerySerializerService,
    ) {
    }
    
    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery) {
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }
    
    resetQuery() {
        this.query = {
            Filters: []
        }
    }
    
    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }


    showAcars(data) {
        this.dialog.show(this.viewContainerRef, RawAcarsDialogComponent, {
            data: data
        }, {
            title: 'ACARS',
            width: 360,
            height: 540,
            modal: true,
        });
    }


    showHotRequest(data) {
        console.log('showHotRequest, data=', data);
        this.api.HotRequest
            .query(this.querySerializer.toHttpParams({
                Filters: [
                    {
                        Field: 'HotRequest.Id',
                        Operator: 'Equals',
                        Value: data.HotRequestId
                    }
                ]
            }))
            .$promise
            .then(hots => {
                this.dialog.show(this.viewContainerRef, RequestDetailDialogComponent, {
                    data: hots,
                    columnDefs: this.hotGridcolumnDefs
                },{
                    title: 'HOT request #' + data.HotRequestId,
                    width: '80%',
                    modal: true,
                });
            });
    }
} 
