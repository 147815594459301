import {Component, OnInit, ViewChild} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {IAgGridColGroupDef, StorageService, ValueFormatters} from "swx.front-end-lib";

@Component({
    templateUrl: './WeatherProfileData.component.html'
})
export class WeatherProfileDataComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'weatherProfileDataQuery'
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    columnDefs: IAgGridColGroupDef[];
    stations = this.api.Station.query();
    sensorSelectMatrices = this.api.SensorSelectMatrix.query();
    clients = this.api.Client.query();
    fluids = this.api.Fluid.query();
    lweEquationProfiles = this.api.LWEEquationProfile.query();
    metarWeatherTypes = this.api.MetarWeatherType.query();
    resource = this.api.WeatherProfile;
    exportResourcePost = this.api.WeatherProfileExport;

    constructor(private api: ApiService,
                private storage: StorageService,
                private formatters: ValueFormatters) {
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey)
        if (storedQuery) {
            this.query = storedQuery
        } else {
          this.resetQuery()  
        }
        
        this.updateColumnDefs(); 
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    }
    

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }


    baseColumnDefs: IAgGridColGroupDef[] = [
        {
            headerName: '',
            children: [
                { colId: "WeatherProfile.Id", field: "WeatherProfile.Id", headerName: "#", width: 90, filterType: "integer" },
                { colId: "WeatherProfile.DateTimeDate", field: "WeatherProfile.DateTime", headerName: "Date", width: 90, pinned: 'left', filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "WeatherProfile.DateTimeTime", field: "WeatherProfile.DateTime", headerName: "Time", width: 80, "excelIgnore": true, pinned: 'left', "searchable": false, valueFormatter: this.formatters.utcTimeLongFormatter() },
                { colId: "StationName", field: "StationName", headerName: "Station", width: 80, pinned: 'left' },
                { colId: "StationType", field: "StationType", headerName: "Station type", width: 80, filterType: "integer", pinned: 'left' },
                { colId: "SensorSelectMatrixName", field: "SensorSelectMatrixName", headerName: "Sensor select matrix", width: 200, pinned: 'left' },

                { colId: "WeatherProfile.LWE", field: "WeatherProfile.LWE", headerName: "LWE (g/dm²/h)", width: 70, filterType: "float", pinned: 'left' },
                { colId: "WeatherProfile.Temperature", field: "WeatherProfile.Temperature", headerName: "Temperature (°C)", width: 110, filterType: "float", pinned: 'left' },
                { colId: "WeatherProfile.RelativeHumidity", field: "WeatherProfile.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 80, filterType: "float" },
                { colId: "WeatherProfile.DewPoint", field: "WeatherProfile.DewPoint", headerName: "Dew point (°C)", width: 80, filterType: "float" },
                { colId: "DewPointDifference", field: "DewPointDifference", headerName: "T - Dew point (°C)", width: 80, filterType: "float" },
                { colId: "WeatherProfile.FrostPoint", field: "WeatherProfile.FrostPoint", headerName: "Frost point (°C)", width: 80, filterType: "float" },
                { colId: "FrostPointDifference", field: "FrostPointDifference", headerName: "Frost point T - Active Frost sensor T (°C)", width: 110, filterType: "float" },

                { colId: "WeatherProfile.WindSpeed", field: "WeatherProfile.WindSpeed", headerName: "Wind speed (m/s)", width: 60, filterType: "float" },
                { colId: "WeatherProfile.WindDirection", field: "WeatherProfile.WindDirection", headerName: "Wind direction (°)", width: 80, filterType: "float" },
                { colId: "WeatherProfile.Visibility", field: "WeatherProfile.Visibility", headerName: "Visibility (m)", width: 80, filterType: "float" },

                { colId: "WeatherProfile.WeatherCategory", field: "WeatherProfile.WeatherCategory", headerName: "Weather category", width: 80, filterType: "enum", source: "WeatherCategory" },
                { colId: "WeatherProfile.WeatherType", field: "WeatherProfile.WeatherType", headerName: "Weather type", width: 80, filterType: "enum", "source": "ClientWeatherType" },
                { colId: "WeatherProfile.WeatherWarnings", field: "WeatherProfile.WeatherWarnings", headerName: "Weather warnings", width: 150, filterType: "enum", source: "WeatherWarning" },

                { colId: "WeatherProfile.ActiveFrostTemperature1", field: "WeatherProfile.ActiveFrostTemperature1", headerName: "Active Frost temperature 1 (°C)", width: 120, filterType: "float" },
                { colId: "WeatherProfile.ActiveFrostTemperature2", field: "WeatherProfile.ActiveFrostTemperature2", headerName: "Active Frost temperature 2 (°C)", width: 120, filterType: "float" },
                { colId: "WeatherProfile.ActiveFrostTemperature3", field: "WeatherProfile.ActiveFrostTemperature3", headerName: "Active Frost temperature 3 (°C)", width: 120, filterType: "float" },
                { colId: "WeatherProfile.ActiveFrostTemperature4", field: "WeatherProfile.ActiveFrostTemperature4", headerName: "Active Frost temperature 4 (°C)", width: 120, filterType: "float" },
                { colId: "WeatherProfile.ConeSurfaceTemperature", field: "WeatherProfile.ConeSurfaceTemperature", headerName: "Active Frost sensor temperature (°C)", width: 110, filterType: "float" },

                { colId: "WeatherProfile.CampbellPWS100WeatherTypeNg", field: "WeatherProfile.CampbellPWS100WeatherTypeNg", headerName: "Campbell PWS100 weather type (NG)", width: 80, filterType: "enum", source: "WeatherTypeNg" },
                { colId: "WeatherProfile.ThiesLPMSecondaryWeatherTypeNg", field: "WeatherProfile.ThiesLPMSecondaryWeatherTypeNg", headerName: "Thies LPM Secondary weather type (NG)", width: 80, filterType: "enum", source: "WeatherTypeNg" },
                { colId: "WeatherProfile.ThiesLPMWeatherTypeNg", field: "WeatherProfile.ThiesLPMWeatherTypeNg", headerName: "Thies LPM weather type (NG)", width: 80, filterType: "enum", source: "WeatherTypeNg" },
                { colId: "WeatherProfile.OTTParsivel2WeatherTypeNg", field: "WeatherProfile.OTTParsivel2WeatherTypeNg", headerName: "OTT Parsivel 2 weather type (NG)", width: 80, filterType: "enum", source: "WeatherTypeNg" },
                { colId: "WeatherProfile.OTTParsivel2SecondaryWeatherTypeNg", field: "WeatherProfile.OTTParsivel2SecondaryWeatherTypeNg", headerName: "OTT Parsivel 2 Secondary weather type (NG)", width: 80, filterType: "enum", source: "WeatherTypeNg" },
                { colId: "WeatherProfile.VaisalaPWD22WeatherTypeNg", field: "WeatherProfile.VaisalaPWD22WeatherTypeNg", headerName: "Vaisala PWD22 weather type (NG)", width: 80, filterType: "enum", "source": "WeatherTypeNg" },
                { colId: "WeatherProfile.VaisalaPWD22SecondaryWeatherTypeNg", field: "WeatherProfile.VaisalaPWD22SecondaryWeatherTypeNg", headerName: "Vaisala PWD22 Secondary weather type (NG)", width: 80, filterType: "enum", source: "WeatherTypeNg" },
                { colId: "WeatherProfile.VaisalaFD71PWeatherTypeNg", field: "WeatherProfile.VaisalaFD71PWeatherTypeNg", headerName: "Vaisala FD71P weather type (NG)", width: 80, filterType: "enum", source: "WeatherTypeNg" },
                { colId: "WeatherProfile.MiniOFSMappedWMOCode", field: "WeatherProfile.MiniOFSMappedWMOCode", headerName: "MiniOFS Mapped WMO", width: 60, filterType: "integer" },
                { colId: "WeatherProfile.MiniOFSWeatherTypeNg", field: "WeatherProfile.MiniOFSWeatherTypeNg", headerName: "MiniOFS weather type (NG)", width: 80, filterType: "enum", source: "WeatherTypeNg" },
                { colId: "WeatherProfile.InstantWeatherTypeNg", field: "WeatherProfile.InstantWeatherTypeNg", headerName: "Instant weather type (NG)", width: 80, filterType: "enum", "source": "WeatherTypeNg" },
                { colId: "WeatherProfile.MostRecentWeatherTypeNg", field: "WeatherProfile.MostRecentWeatherTypeNg", headerName: "Most recent weather type (NG)", width: 80, filterType: "enum", "source": "WeatherTypeNg" },

                { colId: "WeatherProfile.Errors", field: "WeatherProfile.Errors", headerName: "Errors", width: 400, filterType: "enum", "source": "WeatherProfileError" },
                { colId: "WeatherProfile.ValiditySeconds", field: "WeatherProfile.ValiditySeconds", headerName: "Valid for (seconds)", width: 80, filterType: "integer" },

                { colId: "WeatherProfile.SensorSelectMatrixWeatherTypeSensorMappingId", field: "WeatherProfile.SensorSelectMatrixWeatherTypeSensorMappingId", headerName: "Weather type sensor mapping #", width: 80, filterType: "integer" },
                { colId: "WeatherProfile.SensorSelectMatrixWeatherTypeSensorMappingOrder", field: "WeatherProfile.SensorSelectMatrixWeatherTypeSensorMappingOrder", headerName: "Weather type sensor mapping order", width: 80, filterType: "integer" },
                { colId: "WeatherCategoryRemoteEndpointName", field: "WeatherCategoryRemoteEndpointName", headerName: "Weather category endpoint", width: 150, },
                { colId: "WeatherProfile.WeatherCategoryEndpointResponseTimeMs", field: "WeatherProfile.WeatherCategoryEndpointResponseTimeMs", headerName: "Weather category endpoint response time (ms)", width: 110, filterType: "float" },
                { colId: "WeatherProfile.WeatherTypeSubroutine", field: "WeatherProfile.WeatherTypeSubroutine", headerName: "Instant weather type subroutine (NG)", width: 100 },
                { colId: "WeatherProfile.AssumedLWEZeroBecauseWeatherIsClear", field: "WeatherProfile.AssumedLWEZeroBecauseWeatherIsClear", headerName: "Assumed LWE 0 because weather is clear?", width: 100, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
                { colId: "WeatherProfile.SensorSelectMatrixIntensitySensorMappingId", field: "WeatherProfile.SensorSelectMatrixIntensitySensorMappingId", headerName: "Intensity sensor mapping #", width: 80, filterType: "integer" },
                { colId: "WeatherProfile.SensorSelectMatrixIntensitySensorMappingOrder", field: "WeatherProfile.SensorSelectMatrixIntensitySensorMappingOrder", headerName: "Intensity sensor mapping order", width: 80, filterType: "integer" },
                { colId: "LWERemoteEndpointName", field: "LWERemoteEndpointName", headerName: "LWE remote endpoint", width: 150 },
                { colId: "WeatherProfile.LWEEndpointResponseTimeMs", field: "WeatherProfile.LWEEndpointResponseTimeMs", headerName: "LWE endpoint response time (ms)", width: 110, filterType: "float" },
                { colId: "LWEEquationProfileName", field: "LWEEquationProfileName", headerName: "LWE equation profile", width: 150 },
                { colId: "WeatherProfile.LWEEquationId", field: "WeatherProfile.LWEEquationId", headerName: "LWE equation #", width: 80, filterType: "integer" },
            ]
        },
        {
            headerName: 'Sartorius',
            children: [
                { colId: "WeatherProfile.RawLWESartorius", field: "WeatherProfile.RawLWESartorius", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'Campbell PWS100',
            children: [
                { colId: "WeatherProfile.RawLWECampbellPWS100", field: "WeatherProfile.RawLWECampbellPWS100", headerName: "Average LWE (g/dm²/h)", width: 105, filterType: "float" },
            ]
        },
        {
            headerName: 'Thies LPM',
            children: [
                { colId: "WeatherProfile.RawLWEThiesLPM", field: "WeatherProfile.RawLWEThiesLPM", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'Thies LPM Secondary',
            children: [
                { colId: "WeatherProfile.RawLWEThiesLPMSecondary", field: "WeatherProfile.RawLWEThiesLPMSecondary", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Pluvio2',
            children: [
                { colId: "WeatherProfile.RawLWEOTTPluvio2", field: "WeatherProfile.RawLWEOTTPluvio2", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Pluvio2 (NCAR)',
            children: [
                { colId: "WeatherProfile.RawLWEOTTPluvio2NCAR", field: "WeatherProfile.RawLWEOTTPluvio2NCAR", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Pluvio2 DFAR',
            children: [
                { colId: "WeatherProfile.RawLWEOTTPluvio2DFAR", field: "WeatherProfile.RawLWEOTTPluvio2DFAR", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Pluvio2 DFAR (NCAR)',
            children: [
                { colId: "WeatherProfile.RawLWEOTTPluvio2DFARNCAR", field: "WeatherProfile.RawLWEOTTPluvio2DFARNCAR", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Parsivel 2',
            children: [
                { colId: "WeatherProfile.RawLWEOTTParsivel2", field: "WeatherProfile.RawLWEOTTParsivel2", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Parsivel 2 Secondary',
            children: [
                { colId: "WeatherProfile.RawLWEOTTParsivel2Secondary", field: "WeatherProfile.RawLWEOTTParsivel2Secondary", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala PWD22',
            children: [
                { colId: "WeatherProfile.RawLWEVaisalaPWD22", field: "WeatherProfile.RawLWEVaisalaPWD22", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala PWD22 Secondary',
            children: [
                { colId: "WeatherProfile.RawLWEVaisalaPWD22Secondary", field: "WeatherProfile.RawLWEVaisalaPWD22Secondary", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala FD71P',
            children: [
                { colId: "WeatherProfile.RawLWEVaisalaFD71P", field: "WeatherProfile.RawLWEVaisalaFD71P", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'Pond Hot Plate',
            children: [
                { colId: "WeatherProfile.RawLWEPondHotPlate", field: "WeatherProfile.RawLWEPondHotPlate", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
           headerName: 'Geonor T200DA',
           children: [
               { colId: "WeatherProfile.RawLWEGeonorT200DA", field: "WeatherProfile.RawLWEGeonorT200DA", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
           ] 
        },
        {
            headerName: 'Geonor T200 DA (NCAR)',
            children: [
                { colId: "WeatherProfile.RawLWEGeonorT200DANCAR", field: "WeatherProfile.RawLWEGeonorT200DANCAR", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'Geonor T200 DFAR',
            children: [
                { colId: "WeatherProfile.RawLWEGeonorT200DFAR", field: "WeatherProfile.RawLWEGeonorT200DFAR", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'Geonor T200 DFAR (NCAR)',
            children: [
                { colId: "WeatherProfile.RawLWEGeonorT200DFARNCAR", field: "WeatherProfile.RawLWEGeonorT200DFARNCAR", headerName: "Average LWE (g/dm²/h)", width: 80, filterType: "float" },
            ]
        },
        {
            headerName: 'Sartorius',
            children: [
                { colId: "WeatherProfile.LWESartorius", field: "WeatherProfile.LWESartorius", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'Campbell PWS100',
            children: [
                { colId: "WeatherProfile.LWECampbellPWS100", field: "WeatherProfile.LWECampbellPWS100", headerName: "Sensor LWE (g/dm²/h)", width: 110, filterType: "float" },
            ]
        },
        {
            headerName: 'Thies LPM',
            children: [
                { colId: "WeatherProfile.LWEThiesLPM", field: "WeatherProfile.LWEThiesLPM", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'Thies LPM Secondary',
            children: [
                { colId: "WeatherProfile.LWEThiesLPMSecondary", field: "WeatherProfile.LWEThiesLPMSecondary", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Pluvio2',
            children: [
                { colId: "WeatherProfile.LWEOTTPluvio2", field: "WeatherProfile.LWEOTTPluvio2", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Pluvio2 (NCAR)',
            children: [
                { colId: "WeatherProfile.LWEOTTPluvio2NCAR", field: "WeatherProfile.LWEOTTPluvio2NCAR", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Pluvio2 DFAR',
            children: [
                { colId: "WeatherProfile.LWEOTTPluvio2DFAR", field: "WeatherProfile.LWEOTTPluvio2DFAR", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Pluvio2 DFAR (NCAR)',
            children: [
                { colId: "WeatherProfile.LWEOTTPluvio2DFARNCAR", field: "WeatherProfile.LWEOTTPluvio2DFARNCAR", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Parsivel 2',
            children: [
                { colId: "WeatherProfile.LWEOTTParsivel2", field: "WeatherProfile.LWEOTTParsivel2", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Parsivel 2 Secondary',
            children: [
                { colId: "WeatherProfile.LWEOTTParsivel2Secondary", field: "WeatherProfile.LWEOTTParsivel2Secondary", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala PWD22',
            children: [
                { colId: "WeatherProfile.LWEVaisalaPWD22", field: "WeatherProfile.LWEVaisalaPWD22", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala PWD22 Secondary',
            children: [
                { colId: "WeatherProfile.LWEVaisalaPWD22Secondary", field: "WeatherProfile.LWEVaisalaPWD22Secondary", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala FD71P',
            children: [
                { colId: "WeatherProfile.LWEVaisalaFD71P", field: "WeatherProfile.LWEVaisalaFD71P", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'Pond Hot Plate',
            children: [
                { colId: "WeatherProfile.LWEPondHotPlate", field: "WeatherProfile.LWEPondHotPlate", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'Geonor T200 DA',
            children: [
                { colId: "WeatherProfile.LWEGeonorT200DA", field: "WeatherProfile.LWEGeonorT200DA", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'Geonor T200 (NCAR)',
            children: [
                { colId: "WeatherProfile.LWEGeonorT200DANCAR", field: "WeatherProfile.LWEGeonorT200DANCAR", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'Geonor T200 DFAR',
            children: [
                { colId: "WeatherProfile.LWEGeonorT200DFAR", field: "WeatherProfile.LWEGeonorT200DFAR", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'Geonor T200 DFAR (NCAR)',
            children: [
                { colId: "WeatherProfile.LWEGeonorT200DFARNCAR", field: "WeatherProfile.LWEGeonorT200DFARNCAR", headerName: "Sensor LWE (g/dm²/h)", width: 90, filterType: "float" },
            ]
        },
        {
            headerName: 'Campbell PWS100',
            children: [
                { colId: "WeatherProfile.LWECampbellPWS100SpikeCorrected", field: "WeatherProfile.LWECampbellPWS100SpikeCorrected", headerName: "Snow spike corrected?", width: 110 },
            ]
        },
        {
            headerName: 'Thies LPM',
            children: [
                { colId: "WeatherProfile.LWEThiesLPMSpikeCorrected", field: "WeatherProfile.LWEThiesLPMSpikeCorrected", headerName: "Snow spike corrected?", width: 90 },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "WeatherProfile.CombinedLWE", field: "WeatherProfile.CombinedLWE", headerName: "Combined LWE (g/dm²/h)", width: 100, filterType: "float" },
                { colId: "WeatherProfile.RegressedLWE", field: "WeatherProfile.RegressedLWE", headerName: "Regressed LWE (g/dm²/h)", width: 90, filterType: "float" },
                { colId: "WeatherProfile.OperationalToleranceLWE", field: "WeatherProfile.OperationalToleranceLWE", headerName: "Operational tolerance LWE (g/dm²/h)", width: 100, filterType: "float" },
                { colId: "WeatherProfile.UnroundedLWE", field: "WeatherProfile.UnroundedLWE", headerName: "Unrounded LWE (g/dm²/h)", width: 100, filterType: "float" },
                { colId: "WeatherProfile.LongAverageLWE", field: "WeatherProfile.LongAverageLWE", headerName: "Long average LWE (g/dm²/h)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Station reading',
            children: [
                { colId: "StationProblemNames", field: "StationProblemNames", headerName: "Station alarms", width: 350, sortable: false, "searchable": false },
                { colId: "TelegramNumber", field: "TelegramNumber", headerName: "Telegram number", width: 90, filterType: "integer" },
            ]
        },
        {
            headerName: 'Thies LPM',
            children: [
                { colId: "ThiesLPMWMOCode", field: "ThiesLPMWMOCode", headerName: "WMO", width: 60, filterType: "integer" },
                { colId: "WeatherProfile.ThiesLPMMappedWMOCode", field: "WeatherProfile.ThiesLPMMappedWMOCode", headerName: "Mapped WMO", width: 60, filterType: "integer" },
                { colId: "ThiesLPMIntensity", field: "ThiesLPMIntensity", headerName: "Intensity (mm/h)", width: 75, filterType: "float" },
                { colId: "ThiesLPMVisibility", field: "ThiesLPMVisibility", headerName: "Visibility (m)", width: 75, filterType: "float" },
                { colId: "ThiesLPMMeasuringQuality", field: "ThiesLPMMeasuringQuality", headerName: "Measuring quality (%)", width: 75, filterType: "float" },
            ]
        },
        {
            headerName: 'Thies LPM Secondary',
            children: [
                { colId: "ThiesLPMSecondaryWMOCode", field: "ThiesLPMSecondaryWMOCode", headerName: "WMO", width: 60, filterType: "integer" },
                { colId: "ThiesLPMSecondaryIntensity", field: "ThiesLPMSecondaryIntensity", headerName: "Intensity (mm/h)", width: 75, filterType: "float" },
                { colId: "ThiesLPMSecondaryVisibility", field: "ThiesLPMSecondaryVisibility", headerName: "Visibility (m)", width: 75, filterType: "float" },
            ]
        },
        {
            headerName: 'Campbell PWS100',
            children: [
                { colId: "CampbellPWS100WMOCode", field: "CampbellPWS100WMOCode", headerName: "WMO", width: 60, filterType: "integer" },
                { colId: "CampbellPWS100Intensity", field: "CampbellPWS100Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                { colId: "CampbellPWS100Visibility", field: "CampbellPWS100Visibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala FD12P/PWD22',
            children: [
                { colId: "VaisalaFD12PWMOCode", field: "VaisalaFD12PWMOCode", headerName: "WMO", width: 60, filterType: "integer" },
                { colId: "VaisalaFD12PWMOCode15Minutes", field: "VaisalaFD12PWMOCode15Minutes", headerName: "WMO 15 min.", width: 100, filterType: "integer" },
                { colId: "VaisalaFD12PIntensity", field: "VaisalaFD12PIntensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
                { colId: "VaisalaFD12PVisibility", field: "VaisalaFD12PVisibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
                { colId: "VaisalaFD12PCumulativeWater", field: "VaisalaFD12PCumulativeWater", headerName: "Water precipitation sum (mm)", width: 100, filterType: "float" },
                { colId: "VaisalaFD12PCumulativeSnow", field: "VaisalaFD12PCumulativeSnow", headerName: "Snow precipitation sum (mm)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Thies 2D compact',
            children: [
                { colId: "Thies2DCompactWindDirection", field: "Thies2DCompactWindDirection", headerName: "Wind direction (°)", width: 100, filterType: "integer" },
            ]
        },
        {
            headerName: 'Vaisala WS425',
            children: [
                { colId: "VaisalaWS425WindDirection", field: "VaisalaWS425WindDirection", headerName: "Wind direction (°)", width: 100, filterType: "integer" },
            ]
        },
        {
            headerName: 'Thies WP',
            children: [
                { colId: "ThiesWPWindDirection", field: "ThiesWPWindDirection", headerName: "Wind direction (°)", width: 100, filterType: "integer" },
            ]
        },
        {
            headerName: 'Vaisala WMT700',
            children: [
                { colId: "VaisalaWMT700WindDirection", field: "VaisalaWMT700WindDirection", headerName: "Wind direction (°)", width: 100, filterType: "integer" },
            ]
        },
        {
            headerName: 'Biral SWS-200/250',
            children: [
                { colId: "BiralSWS200WMOCode", field: "BiralSWS200WMOCode", headerName: "WMO", width: 60, filterType: "integer" },
                { colId: "BiralSWS200Intensity", field: "BiralSWS200Intensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Pluvio2',
            children: [
                { colId: "OTTPluvio2BucketUnfiltered", field: "OTTPluvio2BucketUnfiltered", headerName: "Bucket unfiltered (mm)", width: 100, filterType: "float" },
                { colId: "OTTPluvio2BucketFiltered", field: "OTTPluvio2BucketFiltered", headerName: "Bucket filtered (mm)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Pluvio2 DFAR',
            children: [
                { colId: "OTTPluvio2DFARBucketUnfiltered", field: "OTTPluvio2DFARBucketUnfiltered", headerName: "Bucket unfiltered (mm)", width: 100, filterType: "float" },
                { colId: "OTTPluvio2DFARBucketFiltered", field: "OTTPluvio2DFARBucketFiltered", headerName: "Bucket filtered (mm)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Geonor T200 DA',
            children: [
                { colId: "GeonorT200DAAccumulatedPrecipitationTotal1", field: "GeonorT200DAAccumulatedPrecipitationTotal1", headerName: "Accumulated precipitation total 1 (mm)", width: 100, filterType: "float" },
                { colId: "GeonorT200DAAccumulatedPrecipitationTotal2", field: "GeonorT200DAAccumulatedPrecipitationTotal2", headerName: "Accumulated precipitation total 2 (mm)", width: 100, filterType: "float" },
                { colId: "GeonorT200DAAccumulatedPrecipitationTotal3", field: "GeonorT200DAAccumulatedPrecipitationTotal3", headerName: "Accumulated precipitation total 3 (mm)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Geonor T200 DFAR',
            children: [
                { colId: "GeonorT200DFARAccumulatedPrecipitationTotal1", field: "GeonorT200DFARAccumulatedPrecipitationTotal1", headerName: "Accumulated precipitation total 1 (mm)", width: 100, filterType: "float" },
                { colId: "GeonorT200DFARAccumulatedPrecipitationTotal2", field: "GeonorT200DFARAccumulatedPrecipitationTotal2", headerName: "Accumulated precipitation total 2 (mm)", width: 100, filterType: "float" },
                { colId: "GeonorT200DFARAccumulatedPrecipitationTotal3", field: "GeonorT200DFARAccumulatedPrecipitationTotal3", headerName: "Accumulated precipitation total 3 (mm)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Parsivel 2',
            children: [
                { colId: "OTTParsivel2WMOCode", field: "OTTParsivel2WMOCode", headerName: "WMO", width: 100, filterType: "float" },
                { colId: "OTTParsivel2RainIntensity", field: "OTTParsivel2RainIntensity", headerName: "Rain intensity (mm/h)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'OTT Parsivel 2 Secondary',
            children: [
                { colId: "OTTParsivel2SecondaryWMOCode", field: "OTTParsivel2SecondaryWMOCode", headerName: "WMO", width: 100, filterType: "float" },
                { colId: "OTTParsivel2SecondaryRainIntensity", field: "OTTParsivel2SecondaryRainIntensity", headerName: "Rain intensity (mm/h)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala PWD22 Secondary',
            children: [
                { colId: "VaisalaPWD22SecondaryWMOCode", field: "VaisalaPWD22SecondaryWMOCode", headerName: "WMO", width: 100, filterType: "float" },
                { colId: "VaisalaPWD22SecondaryIntensity", field: "VaisalaPWD22SecondaryIntensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Vaisala FD71P',
            children: [
                { colId: "VaisalaFD71PWMOCode", field: "VaisalaFD71PWMOCode", headerName: "WMO", width: 100, filterType: "float" },
                { colId: "VaisalaFD71PIntensity", field: "VaisalaFD71PIntensity", headerName: "Intensity (mm/h)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'Pond Hot Plate',
            children: [
                { colId: "PondHotPlatePrecipitationRate", field: "PondHotPlatePrecipitationRate", headerName: "Precipitation rate (mm/h)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'METAR',
            children: [
                { colId: "MetarReadingAge", field: "MetarReadingAge", headerName: "Age (minutes)", width: 60, filterType: "integer" },
                { colId: "MetarReading.WxString", field: "MetarReading.WxString", headerName: "Weather string", width: 90 },
                { colId: "MetarReading.TempC", field: "MetarReading.TempC", headerName: "Temperature (°C)", width: 60, filterType: "float" },
                { colId: "MetarReading.RelativeHumidity", field: "MetarReading.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 80, filterType: "float" },
                { colId: "MetarReading.WindDirDegrees", field: "MetarReading.WindDirDegrees", headerName: "Wind direction (°)", width: 90, filterType: "float" },
                { colId: "MetarReadingWindSpeedKt", field: "MetarReadingWindSpeedKt", headerName: "Wind speed (KT)", width: 60, filterType: "float" },
                { colId: "MetarReading.WindGustKt", field: "MetarReading.WindGustKt", headerName: "Wind gust (KT)", width: 60, filterType: "float" },
                { colId: "FinalMetarWeatherType", field: "FinalMetarWeatherType", headerName: "Final weather Type", width: 90, valueFormatter: c => c.value == null ? "" : this.metarWeatherTypes[c.value], filterType: "enum", source: "MetarWeatherType" },
                { colId: "MetarReading.VisibilityStatuteMi", field: "MetarReading.VisibilityStatuteMi", headerName: "Visibility (SM)", width: 90, filterType: "float" },
                { colId: "MetarReading.MetarSource", field: "MetarReading.MetarSource", headerName: "Source", width: 110, filterType: "enum", "source": "MetarSource" },
                { colId: "MetarReading.CumulativePrecipitation1Hour", field: "MetarReading.CumulativePrecipitation1Hour", headerName: "Past hour precipitation (mm)", width: 110, filterType: "float" },
                { colId: "MetarReading.PrecipitationSincePreviousMetarReading", field: "MetarReading.PrecipitationSincePreviousMetarReading", headerName: "Precipitation since previous METAR (mm)", width: 110, filterType: "float" },
                { colId: "MinutesSincePreviousMetarReading", field: "MinutesSincePreviousMetarReading", headerName: "Minutes since previous METAR (minutes)", width: 110, filterType: "integer" },
                { colId: "MetarReading.RawData", field: "MetarReading.RawData", headerName: "Raw", width: 150 },
            ]
        }
    ];

    updateColumnDefs() {
        Promise.all([this.fluids.$promise, this.lweEquationProfiles.$promise]).then(() => {
            let columnDefs = this.baseColumnDefs.slice(0)

            if (this.query.FluidId && this.query.FluidId.length > 0) {
                var sortedFluidIds = [...this.query.FluidId].sort((a, b) => a - b);

                sortedFluidIds
                    .forEach((fid, index) => {
                        let fluid = this.fluids.find(f => f.Id === fid);
                        let fluidName = fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' - ' + ' (' + fluid.Dilution + ')';

                        columnDefs = columnDefs.concat([
                            {
                                headerName: fluidName,
                                children: [
                                    {
                                        colId: "Hot_" + fid,
                                        field: "Fluids[" + index + "].Hot",
                                        valueGetter: c => c.data?.Fluids[index]?.Hot,
                                        headerName: "LWE HOT (Minutes)",
                                        width: 250,
                                        sortable: false,
                                        searchable: false,
                                        alwaysSelected: true,
                                    },
                                ]
                            },
                            {
                                headerName: 'METAR',
                                children: [
                                    {
                                        colId: "ReferenceMetarMinHot_" + fid,
                                        field: "Fluids[" + index + "].ReferenceMetarMinHot",
                                        valueGetter: c => c.data?.Fluids[index]?.ReferenceMetarMinHot,
                                        headerName: "METAR Min. HOT (minutes)",
                                        width: 60,
                                        sortable: false,
                                        searchable: false,
                                        alwaysSelected: true,
                                    },
                                    {
                                        colId: "ReferenceMetarMaxHot_" + fid,
                                        field: "Fluids[" + index + "].ReferenceMetarMaxHot",
                                        valueGetter: c => c.data?.Fluids[index]?.ReferenceMetarMaxHot,
                                        headerName: "METAR Max. HOT (minutes)",
                                        width: 60,
                                        sortable: false,
                                        searchable: false,
                                        alwaysSelected: true,
                                    },
                                    {
                                        colId: "ReferenceMetarHotMessage_" + fid,
                                        field: "Fluids[" + index + "].ReferenceMetarHotMessage",
                                        valueGetter: c => c.data?.Fluids[index]?.ReferenceMetarHotMessage,
                                        headerName: "HOT message",
                                        width: 120,
                                        sortable: false,
                                        searchable: false,
                                        alwaysSelected: true,
                                    },
                                ]
                            }
                        ]);
                    })
            }

            if (this.query.LWEEquationProfile && this.query.LWEEquationProfileId.length > 0) {
                this.query.LWEEquationProfileId.sort((a, b) => a - b)
                    .forEach((pid, index) => {
                        let lweEquationProfile = this.lweEquationProfiles.find(p => p.Id === pid);

                        columnDefs = columnDefs.concat([{
                            headerName: lweEquationProfile.Name,
                            children: [
                                {
                                    colId: "ComparisonLWE_" + pid,
                                    field: "ComparisonLWEs[" + index + "]",
                                    valueGetter: c => c.data?.ComparisonLWEs[index],
                                    headerName: "LWE (g/dm²/h)",
                                    width: 100,
                                    sortable: false,
                                    searchable: false,
                                    alwaysSelected: true,
                                },
                            ]
                        }
                        ]);
                    })
            }

            this.columnDefs = columnDefs;
        })

    }
}
