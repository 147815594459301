import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import 'moment-timezone';
import { ApiService } from '../shared/Api.service';
import { DialogService, GridComponent, ValueFormatters } from 'swx.front-end-lib';
import {HeatFlapsAlertDialogComponent} from "./HeatFlapsAlertDialog.component";

@Component({
    templateUrl: 'HeatFlapsAlertList.component.html',
})
export class HeatFlapsAlertListComponent {
    @ViewChild('grid', { static: true }) grid: GridComponent;
    search;
    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    datePickerOptions: any;
    query: any;
    columnDefs = [
        {
            "headerName": "",
            "children": [
                { colId: "Id", field: "Id", headerName: "#", width: 90, pinned: 'left' },
                { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left' },
                { colId: "CreatedTime", field: "CreatedDate", headerName: "Created time", width: 70, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left' },
                { colId: "EstimatedTouchdownTime", field: "EstimatedTouchdownTime", headerName: "Estimated touchdown time", width: 100, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "ActualTouchdownTime", field: "ActualTouchdownTime", headerName: "Actual touchdown time", width: 100, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "ClientName", field: "ClientName", headerName: "Client", width: 140, pinned: 'left' },
                { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 80, pinned: 'left' },
                { colId: "EquipmentTypeIdentifier", field: "EquipmentTypeIdentifier", headerName: "Equipment type", width: 140 },
                { colId: "RegistrationNumber", field: "RegistrationNumber", headerName: "Registration number", width: 140 },
                { colId: "FlightNumber", field: "FlightNumber", headerName: "Flight number", width: 140 },
                { colId: "Temperature", field: "Temperature", headerName: "METAR temperature (°C)", width: 120 },
                { colId: "HeatFlapsAlertProfileName", field: "HeatFlapsAlertProfileName", headerName: "Profile", width: 140 },
                { colId: "Message", field: "Message", headerName: "Message", cellTemplate: "<center><a (click)='parent.showMessage(params.data)' class='mdi mdi-eye'></a></center>", width: 100 },
            ]
        }
    ];

    constructor(
        public api: ApiService,
        private viewContainerRef: ViewContainerRef,
        private formatters: ValueFormatters,
        private dialog: DialogService
    ) {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        };

        if (window.localStorage['heatFlapsAlertQuery']) {
            this.query = JSON.parse(window.localStorage['heatFlapsAlertQuery']);
        } else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            ClientId: [],
            AirportId: [],
            FromDate: null,
            ToDate: null,
            Filters: []
        };
    };

    apply() {
        window.localStorage['heatFlapsAlertQuery'] = JSON.stringify(this.query);
        this.grid.refresh();
    }

    showMessage(data) {
        this.dialog.show(this.viewContainerRef, HeatFlapsAlertDialogComponent, {
            message: data.Message,
        }, {
            title: 'ACARS message',
            width: 260,
            height: 280,
            modal: true,
        })
    }
}
