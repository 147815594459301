import {Injectable} from "@angular/core";
import {IAgGridColGroupDef, ValueFormatters} from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";

@Injectable({
    providedIn: 'root'
})
export class HotGridColumnsService {
    metarWeatherTypes = this.api.MetarWeatherType.query();
    
    constructor(private formatters: ValueFormatters, private api: ApiService) {
    }

    generateHotColumns= (baseField, superTitle): IAgGridColGroupDef[] => [
        {
            headerName: superTitle,
            children: [
                { colId: baseField + ".Fluid", field: baseField + ".FluidName", headerName: "Fluid minutes", width: 300 },
                { colId: baseField + ".Message", field: baseField + ".HotResponseHot.Message", headerName: "Message", width: 250 },
                { colId: baseField + ".HOT", field: baseField + ".HotResponseHot.HOT", headerName: "HOT minutes", width: 60, filterType: "float" },
                { colId: baseField + ".IsLWERegressionHot", field: baseField + ".IsLWERegressionHot", headerName: "Is METAR LWE regression?", width: 100 },
                { colId: baseField + ".Errors", field: baseField + ".HotResponseHot.Errors", headerName: "Error", width: 200, initialHide: true, searchable: false },
                { colId: baseField + ".ReferenceMetarMinHot", field: baseField + ".HotResponseHot.ReferenceMetarMinHot", headerName: "METAR reference min. HOT (minutes)", width: 60, filterType: "float" },
                { colId: baseField + ".ReferenceMetarMaxHot", field: baseField + ".HotResponseHot.ReferenceMetarMaxHot", headerName: "METAR reference max. HOT (minutes)", width: 60, filterType: "float" },
            ]
        }
    ]
    
    getBaseColumns = (): IAgGridColGroupDef[] => [
        {
            headerName: 'Request',
            children: [
                { colId: 'HotRequest.Time', field: 'HotRequest.DateTime', headerName: 'Date', width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left' },
                { colId: "HotRequest.Time", field: "HotRequest.DateTime", headerName: "Time", "width": 70, valueFormatter: this.formatters.utcTimeLongFormatter(), excelIgnore: true, pinned: 'left', searchable: false },
            ]
        },
        {
            headerName: 'IBM MQ',
            children: [
                { colId: "PutDateTime", field: "PutDateTime", headerName: "PUT time", width: 70, valueFormatter: this.formatters.utcTimeLongFormatter(), pinned: 'left', searchable: false },
            ]
        },
        {
            headerName: 'Request',
            children: [
                { colId: "HotRequest.Id", field: "HotRequest.Id", headerName: "#", width: 70, filterType: "integer", pinned: 'left' },
                { colId: "HotRequest.Type", field: "HotRequest.Type", headerName: "Type", width: 230, pinned: 'left', cellRenderer: 'rawDataLinkCellRenderer', filterType: "enum", source: "HotRequestType", },
                { colId: "RawRequest", field: "RawRequest", headerName: "Raw request", width: 200, initialHide: true, searchable: false },
            ]
        },
        {
            headerName: 'Response',
            children: [
                { colId: "HotResponse.RawData", field: "HotResponse.RawData", headerName: "Raw mobile response", width: 200, initialHide: true, searchable: false },
                { colId: "RawAcarsResponse", field: "RawAcarsResponse", headerName: "Raw ACARS response", width: 200, initialHide: true, searchable: false },
            ]
        },
        {
            headerName: 'Request',
            children: [
                { colId: "ClientName", field: "ClientName", headerName: "Client", width: 120, pinned: 'left' },
                { colId: "AirportCode", field: "AirportCode", headerName: "Airport", width: 80, pinned: 'left' },
                { colId: "GivenAircraftIdentifier", field: "GivenAircraftIdentifier", headerName: "Given aircraft identifier", width: 80, pinned: 'left' },
                { colId: "AircraftIdentifier", field: "AircraftIdentifier", headerName: "Mapped aircraft identifier", width: 80, pinned: 'left' },
                { colId: "GivenFlightIdentifier", field: "GivenFlightIdentifier", headerName: "Flight identifier / call sign", width: 85 },
                { colId: "GivenEquipmentTypeIdentifier", field: "GivenEquipmentTypeIdentifier", headerName: "Given equipment type code", width: 90 },
                { colId: "EquipmentTypeIdentifier", field: "EquipmentTypeIdentifier", headerName: "Mapped equipment type code", width: 90 },

            ]
        },
        {
            headerName: 'TWA HOT start time',
            children: [
                { colId: "HotRequest.HotStartDate", field: "HotRequest.HotStartDateTime", headerName: "Date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "HotRequest.HotStartTime", field: "HotRequest.HotStartDateTime", headerName: "Time", width: 60, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeFormatter() },
            ]
        },
        {
            headerName: 'Anti-ice start time',
            children: [
                { colId: "AntiIceStartDate", field: "AntiIceStartTime", headerName: "Date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter()},
                { colId: "AntiIceStartTime", field: "AntiIceStartTime", headerName: "Time", width: 60, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeFormatter() },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "HotRequest.UseAdjustedHot", field: "HotRequest.UseAdjustedHot", headerName: "Override to adjusted HOT?", width: 100, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
                { colId: "HotResponse.UseAdjustedHot", field: "HotResponse.UseAdjustedHot", headerName: "Adjusted HOT used?", width: 70, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
            ]
        },
        {
            headerName: 'Weather',
            children: [
                { colId: "HotResponse.Time", field: "HotResponse.DateTime", headerName: "Time", width: 60, filterType: "date", valueFormatter: this.formatters.utcTimeFormatter() },
            ]
        },
        {
            headerName: 'Request',
            children: [
                { colId: "HotRequest.CreatedTime", field: "HotRequest.CreatedDate", headerName: "Created time", width: 70, filterType: "date", valueFormatter: this.formatters.utcTimeLongFormatter() },
            ]
        },
        {
            headerName: 'Response',
            children: [
                { colId: "HotResponse.CreatedTime", field: "HotResponse.CreatedDate", headerName: "Created time", width: 70, filterType: "date", valueFormatter: this.formatters.utcTimeLongFormatter() },
                { colId: "HotResponse.Type", field: "HotResponse.Type", headerName: "Type", width: 70, filterType: "enum", "source": "HotResponseType" },
                { colId: "HotResponse.MetarUsageReason", field: "HotResponse.MetarUsageReason", headerName: "METAR usage reason", width: 170 },
                { colId: "HotResponse.WingMaterialType", field: "HotResponse.WingMaterialType", headerName: "Wing Surface Type", width: 170 },
                { colId: "HotResponse.WeatherReport", field: "HotResponse.WeatherReport", headerName: "Weather report", width: 150 },
                { colId: "HotResponse.Temperature", field: "HotResponse.Temperature", headerName: "Temperature (°C)", width: 90, filterType: "float" },
                { colId: "HotResponse.OverrideTemperature", field: "HotResponse.OverrideTemperature", headerName: "Override temperature (°C)", width: 90, filterType: "float" },
                { colId: "HotResponse.OverrideMetarWeatherType", field: "HotResponse.OverrideMetarWeatherType", headerName: "Override METAR weather type", width: 100, valueFormatter: c => c.value == null ? "" : this.metarWeatherTypes[c.value], filterType: "enum", source: "MetarWeatherType" }, // TODO: cell filter
                { colId: "HotResponse.MetarMixedPrecipitationProfileName", field: "HotResponse.MetarMixedPrecipitationProfileName", headerName: "Mixed precip profile", width: 170 },
                { colId: "HotResponse.Message", field: "HotResponse.Message", headerName: "Warning", width: 250, cellRenderer: 'hotResponseMessageCellRenderer'},
                { colId: "HotResponse.IsRestrictiveCondition", field: "HotResponse.IsRestrictiveCondition", headerName: "Is restrictive condition?", width: 100, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
                { colId: "HotResponse.IsPTOCCNotAuthorized", field: "HotResponse.IsPTOCCNotAuthorized", headerName: "Is PTOCC not authorized?", width: 100, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
                { colId: "HotResponse.Error", field: "HotResponse.Error", headerName: "Error", width: 200, initialHide: true, searchable: false },
                { colId: "HotResponse.ForecastMessage", field: "HotResponse.ForecastMessage", headerName: "Forecast message", width: 250 },
                { colId: "HotResponse.AntiIceRecommendationMessage", field: "HotResponse.AntiIceRecommendationMessage", headerName: "Anti-ice recommendation message", width: 250 },
                { colId: "HotResponse.HoldoverTaxiTimeMessage", field: "HotResponse.HoldoverTaxiTimeMessage", headerName: "Holdover taxi time message", width: 250 },
                { colId: "HotResponse.HoldoverTaxiTimeLower", field: "HotResponse.HoldoverTaxiTimeLower", headerName: "Holdover taxi time (lower)", width: 90, filterType: "float" },
                { colId: "HotResponse.HoldoverTaxiTimeUpper", field: "HotResponse.HoldoverTaxiTimeUpper", headerName: "Holdover taxi time (upper)", width: 90, filterType: "float" },
                { colId: "HotResponse.HoldoverTaxiTimeError", field: "HotResponse.HoldoverTaxiTimeError", headerName: "Holdover taxi time error", width: 250 },
            ]
        },
        {
            headerName: 'Weather profile',
            children: [
                { colId: "StationName", field: "StationName", headerName: "Station", width: 80 },
                { colId: "StationType", field: "StationType", headerName: "Station type", width: 80 },
                { colId: "SensorSelectMatrixName", field: "SensorSelectMatrixName", headerName: "Sensor select matrix", width: 80 },
                { colId: "WeatherProfile.Id", field: "WeatherProfile.Id", headerName: "#", width: 80, filterType: "integer" },
                { colId: "WeatherProfile.LWE", field: "WeatherProfile.LWE", headerName: "LWE (g/dm²/h)", width: 70, filterType: "float" },
                { colId: "WeatherProfile.WeatherCategory", field: "WeatherProfile.WeatherCategory", headerName: "Weather category", width: 80, filterType: "enum", source: "WeatherCategory" },
                { colId: "WeatherProfile.WeatherType", field: "WeatherProfile.WeatherType", headerName: "Weather type", width: 80, filterType: "enum", source: "ClientWeatherType" },
                { colId: "WeatherProfile.WeatherWarnings", field: "WeatherProfile.WeatherWarnings", headerName: "Weather warnings", width: 150, filterType: "enum", source: "WeatherWarning" },
                { colId: "WeatherProfile.WindSpeed", field: "WeatherProfile.WindSpeed", headerName: "Wind speed (m/s)", width: 60, filterType: "float" },
                { colId: "WeatherProfile.RelativeHumidity", field: "WeatherProfile.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 80, filterType: "float" },
                { colId: "WeatherProfile.Errors", field: "WeatherProfile.Errors", headerName: "Errors", width: 60, initialHide: true, "searchable": false },
                { colId: "WeatherProfile.SensorSelectMatrixWeatherTypeSensorMappingId", field: "WeatherProfile.SensorSelectMatrixWeatherTypeSensorMappingId", headerName: "Weather type sensor mapping #", width: 80, filterType: "integer" },
                { colId: "WeatherProfile.SensorSelectMatrixWeatherTypeSensorMappingOrder", field: "WeatherProfile.SensorSelectMatrixWeatherTypeSensorMappingOrder", headerName: "Weather type sensor mapping order", width: 80, filterType: "integer" },
                { colId: "WeatherCategoryRemoteEndpointName", field: "WeatherCategoryRemoteEndpointName", headerName: "Weather category endpoint", width: 150, },
                { colId: "WeatherProfile.WeatherCategoryEndpointResponseTimeMs", field: "WeatherProfile.WeatherCategoryEndpointResponseTimeMs", headerName: "Weather category endpoint response time (ms)", width: 110, filterType: "float" },
                { colId: "WeatherProfile.WeatherTypeSubroutine", field: "WeatherProfile.WeatherTypeSubroutine", headerName: "Instant weather type subroutine (NG)", width: 100 },
                { colId: "WeatherProfile.AssumedLWEZeroBecauseWeatherIsClear", field: "WeatherProfile.AssumedLWEZeroBecauseWeatherIsClear", headerName: "Assumed LWE 0 because weather is clear?", width: 100, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
                { colId: "WeatherProfile.SensorSelectMatrixIntensitySensorMappingId", field: "WeatherProfile.SensorSelectMatrixIntensitySensorMappingId", headerName: "Intensity sensor mapping #", width: 80, filterType: "integer" },
                { colId: "WeatherProfile.SensorSelectMatrixIntensitySensorMappingOrder", field: "WeatherProfile.SensorSelectMatrixIntensitySensorMappingOrder", headerName: "Intensity sensor mapping order", width: 80, filterType: "integer" },
                { colId: "LWERemoteEndpointName", field: "LWERemoteEndpointName", headerName: "LWE remote endpoint", width: 150 },
                { colId: "WeatherProfile.LWEEndpointResponseTimeMs", field: "WeatherProfile.LWEEndpointResponseTimeMs (ms)", headerName: "LWE endpoint response time", width: 110, filterType: "float" },
                { colId: "LWEEquationProfileName", field: "LWEEquationProfileName", headerName: "LWE equation profile", width: 150 },
                { colId: "WeatherProfile.LWEEquationId", field: "WeatherProfile.LWEEquationId", headerName: "LWE equation #", width: 80, filterType: "integer" },
            ]
        },
        {
            headerName: 'METAR',
            children: [
                { colId: "MetarReading.Id", field: "MetarReading.Id", headerName: "#", width: 80, filterType: "integer" },
                { colId: "MetarReading.VisibilityStatuteMi", field: "MetarReading.VisibilityStatuteMi", headerName: "Visibility SM", width: 70, filterType: "float" },
                { colId: "HotResponse.UsedSurfaceVisibility", field: "HotResponse.UsedSurfaceVisibility", headerName: "Surface visibility used?", width: 70, filterType: "boolean", valueFormatter: this.formatters.yesNoFormatter() },
                { colId: "MetarReading.WxString", field: "MetarReading.WxString", headerName: "Weather string", width: 80 },
                { colId: "FinalMetarWeatherType", field: "FinalMetarWeatherType", headerName: "Final weather Type", width: 90, valueFormatter: c => c.value == null ? "" : this.metarWeatherTypes[c.value], filterType: "enum", source: "MetarWeatherType" },
            ]
        },
        {
            headerName: 'Request',
            children: [
                { colId: "PortalUserEmail", field: "PortalUserEmail", headerName: "Portal user", width: 200 },
                { colId: "APIClientCode", field: "APIClientCode", headerName: "API token", width: 90 },
                { colId: "Internal", field: "Internal", headerName: "Internal", width: 80, valueFormatter: this.formatters.yesNoFormatter() },
                { colId: "DeviceVersion", field: "DeviceVersion", headerName: "Device version", width: 80 },
                { colId: "AppVersion", field: "AppVersion", headerName: "Version", width: 80 },
                { colId: "HotRequest.IPAddress", field: "HotRequest.IPAddress", headerName: "IP address", width: 130 },
                { colId: "HotRequest.Uuid", field: "HotRequest.Uuid", headerName: "Device UUID", width: 300 },
                //{ colId: "HotRequest.UserAgent", field: "HotRequest.UserAgent", headerName: "User agent", width: 500 },
                { colId: "HotRequest.Latitude", field: "HotRequest.Latitude", headerName: "Latitude", width: 100, filterType: "float" },
                { colId: "HotRequest.Longitude", field: "HotRequest.Longitude", headerName: "Longitude", width: 100, filterType: "float" },
                { colId: "ClosestClientAirportCode", field: "ClosestClientAirportCode", headerName: "Closest client airport", width: 80 },
                { colId: "ClosestAirportCode", field: "ClosestAirportCode", headerName: "Closest airport", width: 80 },
            ]
        },
        {
            headerName: 'Deicing report',
            children: [
                { colId: "DeicingReport.StartTime", field: "DeicingReport.StartTime", headerName: "HOT start time", width: 60, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "DeicingReport.CreatedDate", field: "DeicingReport.CreatedDate", headerName: "Confirm time", width: 60, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "DeicingReportFluidName", field: "DeicingReportFluidName", headerName: "Selected fluid", width: 300 },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "HasWeatherOverrideDeicingReports", field: "HasWeatherOverrideDeicingReports", headerName: "Weather override deicing reports", cellRenderer: "weatherOverrideDeicingReportCellRenderer", width: 120 },
            ]
        }
    ] 
}
