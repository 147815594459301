import { Component, OnInit, OnChanges, ElementRef, Directive, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import moment from 'moment';
import 'moment-timezone';
import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/autocomplete.js';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui-timepicker-addon/src/jquery-ui-timepicker-addon.js';

@Directive({
    selector: '[date]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateDirective),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DateDirective),
            multi: true
        }
    ],
    host: {
        '[style.width]': "'100px'",
    },
})
export class DateDirective implements ControlValueAccessor, OnInit, OnChanges, Validator {
    @Input('options') options: JQueryUI.DatepickerOptions = {};
    @Input('validator') validator: (date: Date) => any = null;
    @Input('timeZone') timeZone = 'UTC';
    @Input('displayFormat') displayFormat = 'YYYY-MM-DD';
    @Input('format') format = 'YYYY-MM-DDTHH:mm:00[Z]';

    value: string;
    onchange: (_: any) => void = () => { };
    ontouched: (_: any) => void = () => { };
    input: JQuery;

    constructor(
        private elementRef: ElementRef
    ) {
    }

    ngOnInit() {
        this.input = jQuery(this.elementRef.nativeElement);

        this.input.datepicker(this.getOptions());

        this.input.bind('propertychange keyup paste blur', () => this.onChange());
    }

    private onChange() {
        var dateString = this.input.val();
        var mdate = dateString ? moment.tz(dateString, this.timeZone).utc() : null;
        var formattedDate = mdate ? mdate.format(this.format) : null;

        if (this.value !== formattedDate) {
            this.value = formattedDate;
            this.onchange(this.value);
            this.ontouched(this.value);
        }
    }

    private getOptions() : JQueryUI.DatepickerOptions {
        return Object.assign({
            dateFormat: 'yy-mm-dd'
        }, this.options, {
            onSelect: () => this.onChange()
        });
    }

    ngOnChanges(changes) {
        if (this.input != null) {
            if (changes.options != null) {
                this.input.datepicker('destroy', null);
                this.input.datepicker(this.getOptions());
            }

            if (changes.timeZone != null) {
                this.onChange();
            }
        }
    }

    writeValue(value): void {
        var mdate = value ? moment(value).utc() : null;
        var localString = mdate ? mdate.clone()['tz'](this.timeZone).format(this.displayFormat) : null;
        this.input.val(localString);
        var formattedDate = mdate ? mdate.format(this.format) : null;
        this.value = formattedDate;
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void {
        this.ontouched = fn;
    }

    validate(control: AbstractControl): { [index: string]: any; } {
        if (control.value) {
            try {
                var date = moment(control.value).toDate();
                if (this.options.maxDate != null && date > this.options.maxDate) return { 'date': false };
                if (this.options.minDate != null && date < this.options.minDate) return { 'date': false };

                if (this.validator != null) {
                    return this.validator(date);
                }
            } catch (ex) {
                return {
                    'date': false
                };
            }
        }

        return null;
    }
}

@Directive({
    selector: '[date-time]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimeDirective),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DateTimeDirective),
            multi: true
        }
    ],
    host: {
        '[style.width]': "'160px'",
    },
})
export class DateTimeDirective implements ControlValueAccessor, OnInit, OnChanges, Validator {
    @Input('options') options: DateTimePickerOptions = {};
    @Input('validator') validator: (date: Date) => any = null;
    @Input('timeZone') timeZone = 'UTC';
    @Input('displayFormat') displayFormat = 'YYYY-MM-DD HH:mm';
    @Input('format') format = 'YYYY-MM-DDTHH:mm:00[Z]';

    value: string;
    onchange: (_: any) => void = () => { };
    ontouched: (_: any) => void = () => { };
    input: JQuery;

    constructor(
        private elementRef: ElementRef
    ) {
    }

    ngOnInit() {
        this.input = jQuery(this.elementRef.nativeElement);

        this.input.datetimepicker(this.getOptions());

        this.input.bind('propertychange keyup paste blur', () => this.onChange());
    }

    private onChange() {
        var dateString = this.input.val();
        var mdate = dateString ? moment.tz(dateString, this.timeZone).utc() : null;
        var formattedDate = mdate ? mdate.format(this.format) : null;

        if (this.value !== formattedDate) {
            this.value = formattedDate;
            this.onchange(this.value);
            this.ontouched(this.value);
        }
    }

    private getOptions() : DateTimePickerOptions {
        return Object.assign({
            dateFormat: 'yy-mm-dd',
            timeFormat: 'HH:mm'
        }, this.options, {
            onSelect: () => this.onChange(),
            showTime: true
        });
    }

    ngOnChanges(changes) {
        if (this.input != null) {
            if (changes.options != null) {
                this.input.datetimepicker('destroy', null);
                this.input.datetimepicker(this.getOptions());
            }

            if (changes.timeZone != null) {
                this.onChange();
            }
        }
    }

    writeValue(value): void {
        var mdate = value ? moment(value).utc() : null;
        var localString = mdate ? mdate.clone()['tz'](this.timeZone).format(this.displayFormat) : null;
        this.input.val(localString);
        var formattedDate = mdate ? mdate.format(this.format) : null;
        this.value = formattedDate;
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void {
        this.ontouched = fn;
    }

    validate(control: AbstractControl): { [index: string]: any; } {
        if (control.value) {
            try {
                var date = moment(control.value).toDate();
                if (this.options.maxDateTime != null && date > this.options.maxDateTime) return { 'date-time': false };
                if (this.options.maxDate != null && date > this.options.maxDate) return { 'date-time': false };
                if (this.options.minDateTime != null && date < this.options.minDateTime) return { 'date-time': false };
                if (this.options.minDate != null && date < this.options.minDate) return { 'date-time': false };

                if (this.validator != null) {
                    return this.validator(date);
                }
            } catch (ex) {
                return {
                    'date-time': false
                };
            }
        }

        return null;
    }
}

@Component({
    selector: 'time',
    template: `<input type="text" (value)="value" style="width: 60px;" (change)="$event.target.value = (timeRegexp.test($event.target.value) ? $event.target.value.substring(0, 2) + ':' + $event.target.value.substring(2, 4) : $event.target.value);" />`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TimeComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TimeComponent),
            multi: true
        }
    ]
})
export class TimeComponent implements ControlValueAccessor, OnInit, OnChanges, Validator {
    @Input('options') options: any = {};

    value: string;
    onchange: (_: any) => void = () => { };
    ontouched: (_: any) => void = () => { };
    input: JQuery;
    timeRegexp = new RegExp('^[0-9]{4}$');

    constructor(
        private elementRef: ElementRef
    ) {
    }

    ngOnInit() {
        this.input = jQuery(this.elementRef.nativeElement).find('input');

        this.input['timepicker'](this.options);

        this.input.bind('propertychange keyup paste blur change', () => {
            setTimeout(() => {
                var value = this.input.val() as string;
                if (this.value !== value) {
                    this.value = value;
                    this.onchange(this.value);
                    this.ontouched(this.value);
                }
            }, 200);
        });
    }

    ngOnChanges(changes) {
        if (this.input) {
            this.input['timepicker']('destroy');
            this.input['timepicker'](this.options);
        }
    }

    writeValue(value): void {
        if (value) {
            this.value = value;
        } else {
            this.value = null;
        }

        this.input.val(this.value);
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void {
        this.ontouched = fn;
    }

    validate(control: AbstractControl): { [index: string]: any; } {
        if (control.value && !new RegExp('^([0-1][0-9]|2[0-3]):[0-5][0-9]?$').test(control.value)) {
            return {
                'time': false
            };
        }

        return null;
    }
}
