import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import {Base64UploadAdapterPlugin, ChangesService, OrderByPipe} from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
    templateUrl: 'EngineCoverAlertProfileDetail.component.html'
})
export class EngineCoverAlertProfileDetailComponent {
    item: any;
    returnPath;
    tab;
    clientWeatherTypeFlags = this.api.ClientWeatherTypeFlags.query();
    clients = this.api.Client.query();
    client;
    airports = this.api.Airport.query();
    availableAirports: any = [];
    airport;
    emailFroms = this.api.EmailFrom.query();
    @ViewChild('ngForm', {static: true}) ngForm;
    RedEnteringBodyEditor = ClassicEditor;
    OrangeEnteringBodyEditor = ClassicEditor;
    ForcedOrangeEnteringBodyEditor = ClassicEditor;
    ExitingBodyEditor = ClassicEditor;
    ckConfig = {
        extraPlugins: [ Base64UploadAdapterPlugin ],
        toolbar: { items: [ 'bold', 'italic','|', 'link', 'imageUpload', 'heading', 'insertTable', 'bulletedList', 'numberedList', 'outdent', 'indent', '|', 'undo', 'redo' ]}
    };

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private orderByPipe: OrderByPipe,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.EngineCoverAlertProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.EngineCoverAlertAirports) {
                    this.item.EngineCoverAlertAirports.forEach(related => {
                        delete related.EngineCoverAlertProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.EngineCoverAlertProfile.create({
                Active: true,
                RedEnteringSubject: `{AirportCode} Engine Cover Alert Conditions are Present`,
                RedEnteringBody: `<strong>ATTENTION:</strong>
<br />
<br />
*** ENGINE COVER ALERT CONDITIONS ARE PRESENT ***
<br />
<br />
Following RED alert criteria were met:
<br />
<br />
{Criteria}
<br />
<br />
For additional information please contact {OperationManagerName}, Winter Operations Manager at {OperationManagerPhone} or {OperationManagerEmailAddress}.
<br />
<br />
END`,
                OrangeEnteringSubject: `{AirportCode} Engine Cover Alert Conditions are Present`,
                OrangeEnteringBody: `<strong>ATTENTION:</strong>
<br />
<br />
*** ENGINE COVER ALERT CONDITIONS ARE PRESENT ***
<br />
<br />
Following ORANGE alert criteria were met:
<br />
<br />
{Criteria}
<br />
<br />
For additional information please contact {OperationManagerName}, Winter Operations Manager at {OperationManagerPhone} or {OperationManagerEmailAddress}.
<br />
<br />
END`,
                ForcedOrangeEnteringSubject: `{AirportCode} Engine Cover Alert Conditions are Present`,
                ForcedOrangeEnteringBody: `<strong>ATTENTION:</strong>
<br />
<br />
*** ENGINE COVER ALERT CONDITIONS ARE PRESENT ***
<br />
<br />
For additional information please contact {OperationManagerName}, Winter Operations Manager at {OperationManagerPhone} or {OperationManagerEmailAddress}.
<br />
<br />
END`,
                ExitingSubject: `*Cancelled* {AirportCode} Engine Cover Alert Conditions`,
                ExitingBody: `<strong>ATTENTION:</strong>
<br />
<br />
Please be advised that Engine Cover Alert Conditions for 
<strong>{AirportCode}</strong> have been cancelled.
<br />
<br />
For additional information please contact {OperationManagerName}, Winter Operations Manager at {OperationManagerPhone} or {OperationManagerEmailAddress}.
<br />
<br />
END`,
            });
        } else {
            this.item = this.api.EngineCoverAlertProfile.get({ id: id });
        }

        this.item.$promise.then(() => {
            this.updateAvailableAirports();
        });
    }

    save() {
        this.item.$promise.then(item => {
            this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['EngineCoverAlertProfile'],
            SubjectId: this.item.Id
        });
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }

    updateAvailableAirports() {
        if (this.item.ClientId) {
            if (this.client == null || this.client.Id !== this.item.ClientId) {
                this.client = this.api.Client.get(this.item.ClientId);
            }

            this.client.$promise.then(client => {
                this.airports.$promise.then(() => {
                    this.item.EngineCoverAlertAirports = this.item.EngineCoverAlertAirports || [];
                    
                    this.availableAirports = this.orderByPipe.transform(this.airports
                        .filter(a => {
                            var clientAirport = client.ClientAirports.find(ca => ca.AirportId === a.Id);

                            if (clientAirport == null
                                || (clientAirport.StationSimulationId == null
                                    && clientAirport.StationGen2Id == null
                                    && clientAirport.StationGen3Id == null
                                    && clientAirport.StationGenVId == null)) return false;

                            return this.item.EngineCoverAlertAirports.every(ca => ca.AirportId !== a.Id);
                        }), 'ICAOCode');
                });
            });
        }
    }

    addEngineCoverAlertAirport(airport) {
        this.item.EngineCoverAlertAirports = this.item.EngineCoverAlertAirports || [];
        this.item.EngineCoverAlertAirports.push({
            AirportId: airport.Id,
        });
        this.ngForm.form.markAsDirty();
        this.updateAvailableAirports();
    }

    removeEngineCoverAlertAirport(engineCoverAlertAirport) {
        this.item.EngineCoverAlertAirports.splice(this.item.EngineCoverAlertAirports.indexOf(engineCoverAlertAirport), 1);
        this.ngForm.form.markAsDirty();
        this.updateAvailableAirports();
    }
}
