<div class="noMargins">
    <page-title>{{'Sartorius'|translate}}</page-title>
    <div class="toolbar">
        <input type="submit" (click)="save()" [disabled]="!form.valid || !form.dirty" value="{{'Save'|translate}}" />
        <input type="button" *ngIf="'DataChanges'|hasPermission" (click)="viewHistory()" value="{{'View history'|translate}}" />
    </div>
    <form #form="ngForm">
        <fieldset class="contentMargins form" [disabled]="!('ConfigEditGen2'|hasPermission)">
            <label>
                <span class="caption">{{'Snow collector area (dm²)'|translate}}</span>
                <input type="number" step="any" [(ngModel)]="item.SnowCollectorArea" min="0" required name="SnowCollectorArea"/>
            </label>
            <label>
                <span class="caption">{{'Accumulation period (seconds)'|translate}}</span>
                <input type="number" step="1" [(ngModel)]="item.AccumulationPeriod" min="0" required name="AccumulationPeriod"/>
            </label>
        </fieldset>
    </form>
</div>
