<div class="noMargins" *ngIf="stations">
    <page-title>{{'Station Reading Import'|translate}}</page-title>
    <form class="contentMargins form" name="form" #form="ngForm" (ngSubmit)="start()">
        <label>
            <span class="caption">{{'Station'|translate}}</span>
            <select [(ngModel)]="query.StationId" required name="stationId">
                <option *ngFor="let station of stations|filter:{StationType: 'Gen3'}|orderBy:'Name'" [ngValue]="station.Id">
                    {{station.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'From'|translate}}</span>
            <input date required [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="fromDate">
        </label>
        <label>
            <span class="caption">{{'To'|translate}}</span>
            <input date required [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="toDate">
        </label>
        <label>
            <span class="caption">{{'IP'|translate}}</span>
            <input type="text" [(ngModel)]="query.IP" name="ip"/>
        </label>
        <label>
            <span class="caption">{{'Port'|translate}}</span>
            <input type="number" min="1" max="65535" [(ngModel)]="query.Port" name="port"/>
        </label>
        <label>
            <span class="caption">{{'Delete station readings/weather profiles'|translate}}</span>
            <input type="checkbox" [(ngModel)]="query.DeleteExistingStationReadings" name="deleteExistingStationReadings"/>
        </label>
        <label>
            <span class="caption">{{'Generate weather profiles'|translate}}</span>
            <input type="checkbox" [(ngModel)]="query.GenerateWeatherProfiles" name="generateWeatherProfiles"/>
        </label>
        <br />
        <input type="submit" [disabled]="!form.valid || !form.dirty || running" [value]="'Start'|translate" />
    </form>
    <div class="result" *ngIf="!!results">
        <h2>{{'Result'|translate}}</h2>
        <pre>{{results}}</pre>
        <br />
    </div>
</div>

