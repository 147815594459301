import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { StorageService } from "swx.front-end-lib";

@Component({
    templateUrl: 'VoiceCallerTest.component.html'
})
export class VoiceCallerTestComponent implements OnInit {
    private storageKey = 'eventTestQuery';
    query: any = {};
    result: any;

    constructor(
        private api: ApiService,
        private storage: StorageService,
    ) {}

    ngOnInit() {
        //this.query = this.storage.load(this.storageKey) || { EventName: '', Data: '' };
    }

    send() {
        //this.storage.save(this.storageKey, this.query);
        console.log('query=', this.query);
        this.api.VoiceCallerTest.post(this.query).then(result => {
            this.result = result;
        });
    }
}
