import { ColDef, ColGroupDef } from 'ag-grid-community';

export interface IBaseQuery {
    pageSize?: number;
    pageNumber?: number;
    sortField?: string;
    sortDirection?: SortDirection;
    output?: OutputType;
    filters?: Filter[];
}

export interface IAgGridBaseQuery extends IBaseQuery {
    columnDefinitions?: IAgGridColumnDef[];
}

export interface IAgGridColumnDef extends ColDef {
    excelFormat?: string;
    excelIgnore?: boolean;
    excelField?: string;
    searchable?: boolean;
    alwaysSelected?: boolean;
    filterType?: FilterType;
    source?: string;
    isUtc?: boolean;
    isFahrenheit?: boolean;
    isGallons?: boolean;
    multiselectWidth?: number;
    cellTemplate?: string;
    componentRef?: string;
}

export interface IAgGridColGroupDef extends ColGroupDef {
    headerName?: string;
    children: IAgGridColumnDef[];
}

export interface ColumnPreferenceGroup {
    headerName: string;
    children: ColumnPreference[];
    allSelected?: boolean,
}

export interface ColumnPreference extends IAgGridColumnDef {
    selected: boolean;
}

export class FieldGroup {
    Label: string;
    Fields: string[];
}

export class Field {
    Field: string;
    Label: string;
    GroupLabel: string;
    Type: FilterType;
    Operators: {};
    MultiselectWidth?: number;
    Options?: any;
}

export class Filter {
    Field: string;
    Type: FilterType;
    Operator: FilterOperator;
    Value?: string[];
}

export enum SortDirection {
    Asc,
    Desc
}

export enum OutputType {
    JSON,
    Excel,
    Csv,
    Sql
}

export type FilterOperator = "Equals" | "NotEquals" | "GreaterThan" | "LessThan" | "Contains" | "NotContains" | "Empty" | "NotEmpty";

export type FilterType = "boolean" | "enum" | "date" | "integer" | "float";
