import {Component, OnInit, ViewChild} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {IAgGridColGroupDef, StorageService, ValueFormatters} from "swx.front-end-lib";

@Component({
    templateUrl: './NoaaRapReport.component.html'
})
export class NoaaRapReportComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'noaaRapReportQuery';
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    airports = this.api.Airport.query();
    resource = this.api.NoaaRapReport;
    exportResourcePost = this.api.NoaaRapReportExport;

    constructor(private api: ApiService,
                private storage: StorageService,
                private formatters: ValueFormatters) {
    }
    
    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery) {
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }
    
    resetQuery() {
        this.query = {
            Filters: []
        };
    }
    
    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }
    
    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: '',
            children: [
                { colId: "NoaaRapReport.Id", field: "NoaaRapReport.Id", headerName: "#", width: 70, filterType: "integer", pinned: 'left' },
                { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 70, pinned: 'left' },
                { colId: "NoaaRapReport.ReportHourDate", field: "NoaaRapReport.ReportHour", headerName: "Report date", width: 90, filterType: "date", pinned: 'left', valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "NoaaRapReport.ReportHourTime", field: "NoaaRapReport.ReportHour", headerName: "Report time", width: 80, excelIgnore: true, pinned: 'left', searchable: false, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "NoaaRapReport.ForecastDate", field: "NoaaRapReport.ForecastHour", headerName: "Forecast date", width: 90, filterType: "date", pinned: 'left', valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "NoaaRapReport.ForecastTime", field: "NoaaRapReport.ForecastHour", headerName: "Forecast time", width: 80, excelIgnore: true, pinned: 'left', searchable: false, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "NoaaRapReport.RetrievedDate", field: "NoaaRapReport.RetrievedDate", headerName: "Retrieved date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "NoaaRapReport.RetrievedTime", field: "NoaaRapReport.RetrievedDate", headerName: "Retrieved time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeFormatter() },
            ]
        },
        {
            headerName: 'GUST',
            children: [
                { colId: "NoaaRapReport.Gust", field: "NoaaRapReport.Gust", headerName: "Gust (m/s)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'PRATE',
            children: [
                { colId: "NoaaRapReport.PrecipitationRate", field: "NoaaRapReport.PrecipitationRate", headerName: "Precipitation rate (g/dm²/h)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'CSNOW',
            children: [
                { colId: "NoaaRapReport.CategoricalSnow", field: "NoaaRapReport.CategoricalSnow", headerName: "Categorical snow (0/1)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'CFRZR',
            children: [
                { colId: "NoaaRapReport.CategoricalFreezingRain", field: "NoaaRapReport.CategoricalFreezingRain", headerName: "Categorical freezing rain (0/1)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: "CRAIN",
            children: [
                { colId: "NoaaRapReport.CategoricalRain", field: "NoaaRapReport.CategoricalRain", headerName: "Categorical rain (0/1)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'VIS',
            children: [
                { colId: "NoaaRapReport.Visibility", field: "NoaaRapReport.Visibility", headerName: "Visibility (m)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'ASNOW',
            children: [
                { colId: "NoaaRapReport.TotalSnowfall", field: "NoaaRapReport.TotalSnowfall", headerName: "Total snowfall (m)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'FRZR',
            children: [
                { colId: "NoaaRapReport.FreezingRain", field: "NoaaRapReport.FreezingRain", headerName: "Freezing rain (kg/m²)", width: 100, filterType: "float" },
            ]
        },
        {
           headerName: 'TMP',
            children: [
                { colId: "NoaaRapReport.Temperature", field: "NoaaRapReport.Temperature", headerName: "Temperature (°C)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'FRZR',
            children: [
                { colId: "NoaaRapReport.SpecificHumidity", field: "NoaaRapReport.SpecificHumidity", headerName: "Specific humidity (kg/kg)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'UGRD',
            children: [
                { colId: "NoaaRapReport.WindUComponent", field: "NoaaRapReport.WindUComponent", headerName: "Wind U component (m/s)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: 'VGRD',
            children: [
                { colId: "NoaaRapReport.WindVComponent", field: "NoaaRapReport.WindVComponent", headerName: "Wind V component (m/s)", width: 100, filterType: "float" },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "NoaaRapReport.WindSpeed", field: "NoaaRapReport.WindSpeed", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
                { colId: "NoaaRapReport.WindDirection", field: "NoaaRapReport.WindDirection", headerName: "Wind direction (°)", width: 100, filterType: "float" },
            ]
        }
    ];
} 
