<page-title>{{('LWE message mapping profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('mappings')" [class.active]="tab == 'mappings'" [class.invalid]="!ngForm.form.controls.mappingsForm?.valid">{{'Mappings'|translate}}</a>
        <a class="tab" (click)="switchTab('exceptions')" [class.active]="tab == 'exceptions'" [class.invalid]="!ngForm.form.controls.exceptionsForm?.valid">{{'HUPR exceptions'|translate}}</a>
        <a class="tab" (click)="switchTab('categories')" [class.active]="tab == 'categories'" [class.invalid]="!ngForm.form.controls.categoriesForm?.valid">{{'Category labels'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" class="green" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        <button *ngIf="('ConfigEditLWEMessageMappings'|hasPermission) && tab == 'categories'" type="button" (click)="addCategory()" class="green">{{'Add category'|translate}}</button>
        <button *ngIf="('ConfigEditLWEMessageMappings'|hasPermission) && tab == 'mappings'" type="button" (click)="addMapping()" class="green">{{'Add mapping'|translate}}</button>
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditLWEMessageMappings'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100"/>
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active"/>
        </label>
    </fieldset>

    <config-grid #exceptionsGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'exceptions'"
                 ngModelGroup="exceptionsForm"
                 #exceptionsForm="ngModelGroup"
                 [rowData]="exceptionsData"
                 [columnDefs]="exceptionsColumnDefs"
                 [headerHeight]="56"
                 [rowHeight]="65">
    </config-grid>

    <config-grid #categoriesGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'categories'"
                 ngModelGroup="categoriesForm"
                 #categoriesForm="ngModelGroup"
                 [rowData]="item.WeatherCategoryProperties"
                 [headerHeight]="75"
                 [columnDefs]="categoriesColumnDefs">
    </config-grid>

    <config-grid #mappingsGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'mappings'"
                 ngModelGroup="mappingsForm"
                 #mappingsForm="ngModelGroup"
                 [rowData]="item.LWEMessageMappings"
                 [columnDefs]="mappingsColumnDefs"
                 [headerHeight]="56">
    </config-grid>
</form>
