import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Credentials } from "./Credentials.model";
import { StorageService } from "../Storage.service";
import { AuthConfig } from "./AuthConfig.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";
import { TokenDto } from "./TokenDto.model";
import { ParamsEncoder } from "./ParamsEncoder";

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject = new Subject<any>();
    private currentUser: any;
    private checkCredentialsPromise: Promise<any> = null;
    userChange = this.currentUserSubject.asObservable();

    constructor(
        private http: HttpClient,
        private router: Router,
        private config: AuthConfig,
        private storage: StorageService,
    ) {
        if (typeof localStorage === 'object') {
            try {
                localStorage.setItem('localStorageTest', '1');
                localStorage.removeItem('localStorageTest');
            } catch (e) {
                window['fakeStorage'] = window['fakeStorage'] || {};
                Storage.prototype.setItem = (name, value) => {
                    window['fakeStorage'][name] = value;
                };
                Storage.prototype.getItem = name => {
                    return window['fakeStorage'][name];
                };
            }
        }
    }

    private authenticate(credentials: Credentials) {
        return new Promise((resolve, reject) => {
            var data = this.formEncodeCredentials(credentials);
            this.http
                .post<TokenDto>(this.config.tokenUrl, data)
                .subscribe(token => {
                        if (token != null) {
                            this.storage.save(this.config.storageKey, token);

                            this.http
                                .get(this.config.userUrl)
                                .toPromise()
                                .then(user => {
                                    this.currentUser = user;
                                    this.currentUserSubject.next(user);
                                    resolve(user);
                                });
                        } else {
                            reject({
                                status: 403,
                                statusText: 'Invalid username or password'
                            });
                        }
                    },
                    error => {
                        //this.busyIndicator.setBusy(false);
                        reject(error);
                    });
        });
    }

    getHeaders(): HttpHeaders {
        return new HttpHeaders(); // so it won't break current API
    }

    responseError(error) {
        if (typeof error.config.anonymous === 'undefined' || !error.config.anonymous) {
            if (error.status === 401) {
                this.clearCredentials();
                this.router.navigateByUrl('/login');
            }
        }
        return new Promise((resolve, reject) => { reject(error) });
    }

    getCurrentUser(clientCode?: string): Promise<any> {
        return this.currentUser ? new Promise((resolve, reject) => { resolve(this.currentUser) }) : this.checkCredentials(clientCode);
    }

    private checkCredentials(clientCode?: string): Promise<any> {
        if (window.localStorage.getItem('access_token')) {
            var token = JSON.parse(window.localStorage.getItem('access_token'));
            if (token) {
                if (this.checkCredentialsPromise == null) {
                    this.checkCredentialsPromise = this.http
                        .get(this.config.userUrl)
                        .toPromise()
                        .then((user: any) => {
                            if (clientCode && clientCode.toLowerCase() !== user.ClientPortalCode.toLowerCase()) {
                                return new Promise((resolve, reject) => { reject('Client code mismatch')});
                            }

                            this.currentUser = user;
                            this.currentUserSubject.next(user);
                            return user;
                        })
                        .catch(error => {
                            this.clearCredentials();
                            return new Promise((resolve, reject) => { reject(error) });
                        });
                }
                return this.checkCredentialsPromise;
            } else {
                return new Promise((resolve, reject) => { reject('Invalid access token stored') });
            }
        } else {
            return new Promise((resolve, reject) => { reject('No access token stored') });
        }
    }

    setCredentials(username: string, password: string): Promise<any> {
        return this.authenticate({
            username: username,
            password: password
        });
    }

    clearCredentials(): void {
        window.localStorage.removeItem('access_token');
        this.currentUser = null;
        this.checkCredentialsPromise = null;
        this.currentUserSubject.next(null);

        if (localStorage['sessionId']) {
            Object.keys(localStorage).filter(key => {
                return key.indexOf(localStorage['sessionId']) === 0;
            })
            .forEach(key => {
                localStorage.removeItem(key);
            });
            localStorage.removeItem('sessionId');
        }
    }

    private formEncodeCredentials(credentials: Credentials): HttpParams {
        return new HttpParams({ encoder: new ParamsEncoder() })
            .set('grant_type', 'password')
            .set('username', credentials.username)
            .set('password', credentials.password);
    }

    getSessionId(): string {
        if (!localStorage['sessionId']) {
            localStorage['sessionId'] = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
                var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }

        return localStorage['sessionId'];
    }
}
