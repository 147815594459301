<page-title>{{('METAR mixed precipitation profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" class="green" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditMetarMixedPrecipitationProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
        <h2>{{'Plausibility criteria for presence of mixed precipitation'|translate}}</h2>
        <label>
            <span class="caption">{{'Temp &lt; (°C)'|translate}}</span>
            <input type="number" step="any" [(ngModel)]="item.MaxTemperatureC" name="item.MaxTemperatureC" />
        </label>
        <label>
            <span class="caption">{{'Temp &gt; (°C)'|translate}}</span>
            <input type="number" step="any" [(ngModel)]="item.MinTemperatureC" name="item.MinTemperatureC" />
        </label>
        <label>
            <span class="caption">{{'Visibility &lt; (m)'|translate}}</span>
            <input type="number" step="any" min="0" [(ngModel)]="item.MaxVisibilityMeters" name="item.MaxVisibilityMeters" />
        </label>
        <label>
            <span class="caption">{{'Visibility &gt; (m)'|translate}}</span>
            <input type="number" step="any" min="0" [(ngModel)]="item.MinVisibilityMeters" name="item.MinVisibilityMeters" />
        </label>
        <label>
            <span class="caption">{{'Temp - dew point &lt; (°C)'|translate}}</span>
            <input type="number" step="any" min="0" [(ngModel)]="item.MaxTemperateDewPointDeltaC" name="item.MaxTemperateDewPointDeltaC" />
        </label>
        <label>
            <span class="caption">{{'Temp - dew point &gt; (°C)'|translate}}</span>
            <input type="number" step="any" min="0" [(ngModel)]="item.MinTemperateDewPointDeltaC" name="item.MinTemperateDewPointDeltaC" />
        </label>
        <label>
            <span class="caption">{{'Automatically select mixed precipitation option when criteria are met'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.AutoSelectOption2" name="item.AutoSelectOption2" />
        </label>
        <h2 *ngIf="!item.AutoSelectOption2">{{'Mobile app dialog if plausibility criteria are met'|translate}}</h2>
        <label *ngIf="!item.AutoSelectOption2">
            <span class="caption">{{'Dialog title'|translate}}</span>
            <input type="text" required [(ngModel)]="item.DialogTitle" name="item.DialogTitle" style="width: 250px" />
        </label>
        <label *ngIf="!item.AutoSelectOption2">
            <span class="caption">{{'Dialog message'|translate}}</span>
            <input type="text" required [(ngModel)]="item.DialogMessage" name="item.DialogMessage" style="width: 400px" />
        </label>
        <h3>{{'Not mixed precipitation'|translate}}</h3>
        <label *ngIf="!item.AutoSelectOption2">
            <span class="caption">{{'Button label'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Option1Label" name="item.Option1Label" style="width: 250px" />
        </label>
        <label>
            <span class="caption">{{'Weather type'|translate}}</span>
            <select [(ngModel)]="item.Option1MetarWeatherType" name="item.Option1MetarWeatherType" [selectedIndex]="-1">
                <option [ngValue]="null">{{'Use final type from combination mapping'|translate}}</option>
                <option *ngFor="let option of metarWeatherTypes|keys" [ngValue]="option">
                    {{metarWeatherTypes[option]}}
                </option>
            </select>
        </label>
        <label *ngIf="!item.AutoSelectOption2">
            <span class="caption">{{'Show HOT and message?'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Option1ShowHotAndMessage" name="item.Option1ShowHotAndMessage" />
        </label>
        <label *ngIf="!item.AutoSelectOption2">
            <span class="caption">{{'Message'|translate}}</span>
            <textarea [(ngModel)]="item.Option1Message" name="item.Option1Message" cols="24" rows="15" style="font-family: monospace; white-space: pre; overflow: auto;"></textarea>
        </label>

        <h3>{{'Mixed precipitation'|translate}}</h3>
        <label *ngIf="!item.AutoSelectOption2">
            <span class="caption">{{'Button label'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Option2Label" name="item.Option2Label" style="width: 250px" />
        </label>
        <label>
            <span class="caption">{{'Weather type'|translate}}</span>
            <select [(ngModel)]="item.Option2MetarWeatherType" name="item.Option2MetarWeatherType" [selectedIndex]="-1">
                <option [ngValue]="null">{{'Use final type from combination mapping'|translate}}</option>
                <option *ngFor="let option of metarWeatherTypes|keys" [ngValue]="option">
                    {{metarWeatherTypes[option]}}
                </option>
            </select>
        </label>
        <label *ngIf="item.AutoSelectOption2">
            <span class="caption">{{'Show HOT and message?'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Option2ShowHotAndMessage" name="item.Option2ShowHotAndMessage" />
        </label>
        <label *ngIf="item.AutoSelectOption2">
            <span class="caption">{{'Message'|translate}}</span>
            <textarea [(ngModel)]="item.Option2Message" name="item.Option2Message" cols="24" rows="15" style="font-family: monospace; white-space: pre; overflow: auto;"></textarea>
        </label>
    </fieldset>
</form>
