import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'clientSearchFilter'
})
export class ClientSearchFilterPipe implements PipeTransform {
    transform(items, expression, searchType, scope): any {
        if (typeof items === 'undefined' || items === null || typeof items.length === 'undefined' || items.length === 0) {
            return [];
        }

        if (!expression) {
            return items;
        }

        switch (searchType) {
            case 'aircraft':
                var result = items.filter(item => {
                    var equipementResult = scope.equipmentTypes.filter(val => {
                        return val.Id === item.EquipmentTypeId;
                    });

                    var currentEquipmentType = equipementResult.shift();
                    var equipementTypeExpression = currentEquipmentType != null ? currentEquipmentType.IATACode + currentEquipmentType.ICAOCode : "";

                    return this.isFound(expression, item.TailNumber)
                        || this.isFound(expression, item.SecondaryIdentifier)
                        || this.isFound(expression, equipementTypeExpression)
                        || this.isFound(expression, scope.wingMaterialTypes[item.WingMaterialType]);
                });
                return result;

            default:
                return items;
        }
    }

    private isFound(search, value) {
        return value !== null
            && typeof value !== 'undefined'
            && search !== null
            && typeof search !== 'undefined'
            && String(value).toUpperCase().indexOf(String(search).toUpperCase()) > -1;
    }
}
