<form #form #ngForm="ngForm" (submit)="save()">
	<page-title>{{('Mobile app release'|translate) + ': ' + (item.Name || 'new')}}</page-title>
	<div id="userEditPage" class="noMargins">
		<div class="toolbar">
			<a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
		</div>

		<fieldset class="contentMargins form" [disabled]="!('ConfigEditMobileAppReleases'|hasPermission)" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
			<label>
				<span class="caption">{{'Mobile app'|translate}}</span>
				<select [(ngModel)]="item.MobileAppId" name="item.MobileAppId" required [selectedIndex]="-1">
					<option *ngFor="let mobileApp of mobileApps|orderBy:'AppName'" [ngValue]="mobileApp.Id">
						{{mobileApp.AppName}}
					</option>
				</select>
			</label>
			<label>
				<span class="caption">{{'Version'|translate}}</span>
				<input type="text" required [(ngModel)]="item.Version" name="item.Version" style="width: 80px;" />
			</label>
			<label>
				<span class="caption">{{'Active'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
			</label>
			<label>
				<span class="caption">{{'Developer comments'|translate}}</span>
				<textarea [(ngModel)]="item.Comments" name="item.Comments" rows="4" cols="60"></textarea>
			</label>
            <label>
				<span class="caption">{{'Show on portal'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.ShowOnPortal" name="item.ShowOnPortal" />
            </label>
			<label>
				<span class="caption">{{'Portal comments'|translate}}</span>
				<textarea [(ngModel)]="item.PortalComments" name="item.PortalComments" rows="4" cols="60"></textarea>
			</label>
			<label>
				<span class="caption">{{'ProvisioningProfileId'|translate}}</span>
				<input type="text" [(ngModel)]="item.ProvisioningProfileId" name="item.ProvisioningProfileId" style="width: 250px;" />
			</label>
			<div class="field">
				<span class="caption">{{'Build file'|translate}}</span>
				<file-upload [(ngModel)]="item.BuildFileUploadId" name="item.BuildFileUploadId"></file-upload>
			</div>
			<div class="field">
				<span class="caption">{{'.xarchive file'|translate}}</span>
				<file-upload [(ngModel)]="item.XArchiveFileUploadId" name="item.XArchiveFileUploadId"></file-upload>
			</div>
		</fieldset>
	</div>
</form>
