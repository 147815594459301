import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import {TranslateService, FilterPipe, NotificationService, ChangesService, OrderByPipe} from 'swx.front-end-lib';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import {XmlToJsonService} from "./XmlToJsonService";

@Component({
    templateUrl: 'ClientApiTokenDetail.component.html',
})
export class ClientApiTokenDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    mobileDeicingRequestProviderTab;
    deicingRequestServiceTypes = this.api.DeicingRequestServiceType.query();
    serviceLevelLogos = this.api.ServiceLevelLogo.query();
    airports = this.api.Airport.query();
    clients = this.api.Client.query();
    fluids = this.api.Fluid.query();
    fluidSeasons = this.api.FluidSeason.query();
    enabledFluids = [];
    airlines = this.api.DeicingAirline.query();
    lweMessageMappingProfiles = this.api.LWEMessageMappingProfile.query();
    metarMessageMappingProfiles = this.api.MetarMessageMappingProfile.query();
    metarWeatherMappingProfiles = this.api.MetarWeatherMappingProfile.query();
    mobileDocumentProfiles = this.api.MobileDocumentProfile.query();
    equipmentTypes = this.api.EquipmentType.query();
    mobileWeatherMappingProfiles = this.api.MobileWeatherMappingProfile.query();
    roundingMethods = this.api.RoundingMethod.query();
    mobileMetarVisibilities = this.api.MobileMetarVisibility.query();
    mobileTimerTimeZoneOptions = this.api.MobileTimerTimeZoneOption.query();
    airportsDict = {};
    mobileFileTemplateMappings = [];
    showFileTemplateMapping = false;
    templateInfos: any;
    mobileExternalInputs: any;
    mobileNotificationTooltip = "When the min fluid HOT is equal to the max fluid HOT, the max METAR HOT pop-up/notification settings will be applied. If there are no max METAR HOT pop-up/notificaiton settings, then the min METAR HOT settings will be applied.";
    client;
    airlineOption;
    clientApiTokenEquipmentTypeOption;
    airportOption;
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private translateService: TranslateService,
        private notificationService: NotificationService,
        public hasPermissionService: HasPermissionService,
        private xmlToJson: XmlToJsonService,
        private orderByPipe: OrderByPipe,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
        
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.ClientApiToken.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.MobileDeicingRequestProviders) {
                    this.item.MobileDeicingRequestProviders.forEach(related => {
                        delete related.ClientApiTokenId;

                        if (related.MobileDeicingRequestProviderOptions) {
                            related.MobileDeicingRequestProviderOptions.forEach(subRelated => {
                                delete subRelated.Id;
                                delete subRelated.MobileDeicingRequestProviderId;

                                if (subRelated.MobileDeicingRequestProviderOptionEquipmentTypes) {
                                    subRelated.MobileDeicingRequestProviderOptionEquipmentTypes.forEach(subSubRelated => {
                                        delete subSubRelated.MobileDeicingRequestProviderOptionId;
                                    });
                                }
                            });
                        }

                        if (related.MobileDeicingRequestProviderAirports) {
                            related.MobileDeicingRequestProviderAirports.forEach(subRelated => {
                                delete subRelated.MobileDeicingRequestProviderId;
                            });
                        }
                    });
                }

                if (this.item.ClientApiTokenMobileInitFlightDeicingAirlines) {
                    this.item.ClientApiTokenMobileInitFlightDeicingAirlines.forEach(related => {
                        delete related.ClientApiTokenId;
                    });
                }

                if (this.item.ClientApiTokenEquipmentTypes) {
                    this.item.ClientApiTokenEquipmentTypes.forEach(related => {
                        delete related.ClientApiTokenId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.ClientApiToken.create({
                Active: true,
            });
        } else {
            this.item = this.api.ClientApiToken.get({ id: id });
        }

        this.airports.$promise.then(() => {
            this.airports.forEach(airport => {
                this.airportsDict[airport.Id] = airport;
            });
        });
        
        this.item.$promise.then(() => {
            this.checkMobileMappings();
            
            if (this.item.ClientId) this.updateClient();

            if (this.item.MobileDeicingRequestProviders && this.item.MobileDeicingRequestProviders.length > 0) {
                this.item.MobileDeicingRequestProviders.forEach(cdsp => {
                    cdsp.MobileDeicingRequestProviderOptions = this.orderByPipe.transform<any>(cdsp.MobileDeicingRequestProviderOptions || [], ['Order', 'Name']);
                
                    cdsp.MobileDeicingRequestProviderOptions.forEach(cdspo => {
                        cdspo.MobileDeicingRequestProviderOptionEquipmentTypes = cdspo.MobileDeicingRequestProviderOptionEquipmentTypes.map(et => et.EquipmentTypeId);
                    });
                });

                this.switchMobileDeicingRequestProviderTab(this.item.MobileDeicingRequestProviders[0]);
            }
        });
    }
    
    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);

    save() {
        this.checkMobileMappings();

        // transform equipment type multiselect from [Id] -> [{EquipmentTypeId: Id]
        this.item.MobileDeicingRequestProviders.forEach(cdrp => {
            cdrp.MobileDeicingRequestProviderOptions.forEach(cdrpo => {
                cdrpo.MobileDeicingRequestProviderOptionEquipmentTypes =
                    cdrpo.MobileDeicingRequestProviderOptionEquipmentTypes?.map(et =>
                        ({ EquipmentTypeId: et, MobileDeicingRequestProviderOptionId: cdrpo.Id })) ||
                    [];
            });
        });
        
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['ClientApiToken'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    updateClient() {
        this.api.Client.get(this.item.ClientId).$promise.then(client => {
            this.client = client;

            Promise.all([this.fluids.$promise, this.fluids.$promise]).then(() => {
                this.enabledFluids = this.fluids.filter(f => client.ClientFluids.some(cfv => cfv.FluidId === f.Id));
            });
        });
    }

    addMobileDeicingRequestProvider() {
        this.item.MobileDeicingRequestProviders = this.item.MobileDeicingRequestProviders || [];
        this.item.MobileDeicingRequestProviders.push({});
        this.ngForm.form.markAsDirty();
    }

    removeMobileDeicingRequestProvider(mobiledeicingRequestProvider) {
        this.item.MobileDeicingRequestProviders.splice(this.item.MobileDeicingRequestProviders.indexOf(mobiledeicingRequestProvider), 1);
        this.ngForm.form.markAsDirty();
    }

    addMobileDeicingRequestProviderOption(mobileDeicingRequestProvider) {
        mobileDeicingRequestProvider.MobileDeicingRequestProviderOptions = mobileDeicingRequestProvider.MobileDeicingRequestProviderOptions || [];
        mobileDeicingRequestProvider.MobileDeicingRequestProviderOptions.push({});
        this.ngForm.form.markAsDirty();
    }

    removeMobileDeicingRequestProviderOption(mobileDeicingRequestProvider, item) {
        mobileDeicingRequestProvider.MobileDeicingRequestProviderOptions.splice(mobileDeicingRequestProvider.MobileDeicingRequestProviderOptions.indexOf(item), 1);
        this.ngForm.form.markAsDirty();
    }

    addMobileDeicingRequestProviderAirport(mobileDeicingRequestProvider, clientAirport) {
        mobileDeicingRequestProvider.MobileDeicingRequestProviderAirports = mobileDeicingRequestProvider.MobileDeicingRequestProviderAirports || [];
        mobileDeicingRequestProvider.MobileDeicingRequestProviderAirports.push({
            AirportId: clientAirport.AirportId,
        });
        this.ngForm.form.markAsDirty();
    }

    clientAirportsNotEnabled() {
        return this.client?.ClientAirports.filter(ca => !this.item.MobileDeicingRequestProviders?.some(p => p.MobileDeicingRequestProviderAirports?.some(pa => pa.AirportId === ca.AirportId))) || [];
    }

    availableFailsafeAirports() {
        return this.client?.ClientAirports.filter(ca => this.airports.find(a => a.Id === ca.AirportId)?.IsFailsafeAirport) || [];
    }

    removeMobileDeicingRequestProviderAirport(mobileDeicingRequestProvider, item) {
        mobileDeicingRequestProvider.MobileDeicingRequestProviderAirports.splice(mobileDeicingRequestProvider.MobileDeicingRequestProviderAirports.indexOf(item), 1);
        this.ngForm.form.markAsDirty();
    }
    
    switchMobileDeicingRequestProviderTab(token) {
        this.mobileDeicingRequestProviderTab = token;
    }

    getFileTemplateMapping() {
        this.templateInfos = this.xmlToJson.getInfo(this.item.MobileFileTemplateSettings) || [];
        this.mobileFileTemplateMappings = [];

        if (!this.item.MobileSettingsFilePath) return;

        for (let property in this.mobileExternalInputs) {
            if (this.mobileExternalInputs.hasOwnProperty(property)
                && property !== '$promise'
                && property !== '$resolved'
            ) {
                this.mobileFileTemplateMappings.push({
                    FieldName: property,
                    FieldValue: this.mobileExternalInputs[property],
                    FieldPath: ""
                });
            }
        }
    }

    checkMobileMappings() {
        if (this.item.MobileSettingsFilePath) {
            if (!this.mobileFileTemplateMappings && this.item.MobileFileTemplateMappings) {
                this.getFileTemplateMapping();
                var mappings = JSON.parse(this.item.MobileFileTemplateMappings);
                this.mobileFileTemplateMappings.forEach(mapping => {
                    var exists = mappings.find(x => x.FieldName === mapping.FieldName);
                    mapping.FieldPath = exists ? exists.FieldPath : mapping.FieldPath;
                });
            }

            if ((this.mobileFileTemplateMappings || []).length > 0) {
                this.item.MobileFileTemplateMappings = JSON.stringify(this.mobileFileTemplateMappings.filter(item => item.FieldPath));
            } else if (!this.item.MobileFileTemplateSettings) {
                this.item.MobileFileTemplateMappings = null;
            }
        }
    }

    addClientApiTokenEquipmentType(clientApiTokenEquipmentTypeOption) {
        this.item.ClientApiTokenEquipmentTypes = this.item.ClientApiTokenEquipmentTypes || [];
        this.item.ClientApiTokenEquipmentTypes.push({
            EquipmentTypeId: clientApiTokenEquipmentTypeOption.Id,
            Name: clientApiTokenEquipmentTypeOption.ManufacturerAndModelDescription
        });
        this.ngForm.form.markAsDirty();
    }

    removeClientApiTokenEquipmentType(clientApiTokenEquipmentType) {
        this.item.ClientApiTokenEquipmentTypes.splice(this.item.ClientApiTokenEquipmentTypes.indexOf(clientApiTokenEquipmentType), 1);
        this.ngForm.form.markAsDirty();
    }

    addClientApiTokenMobileInitFlightDeicingAirline(airlineOption) {
        this.item.ClientApiTokenMobileInitFlightDeicingAirlines = this.item.ClientApiTokenMobileInitFlightDeicingAirlines || [];
        this.item.ClientApiTokenMobileInitFlightDeicingAirlines.push({
            DeicingAirlineId: airlineOption.Id,
        });
        this.ngForm.form.markAsDirty();
    }

    removeClientApiTokenMobileInitFlightDeicingAirline(clientApiTokenMobileInitFlightDeicingAirline) {
        this.item.ClientApiTokenMobileInitFlightDeicingAirlines.splice(this.item.ClientApiTokenMobileInitFlightDeicingAirlines.indexOf(clientApiTokenMobileInitFlightDeicingAirline), 1);
        this.ngForm.form.markAsDirty();
    }
}
