import {Component, OnDestroy, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import {
    ChangesService,
    DialogService,
    FileDownloadService,
    IAgGridColGroupDef,
    IAgGridColumnDef,
    NotificationService,
    TranslateService,
    ValueFormatters,
} from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import { ConfigGridComponent } from "swx.front-end-lib";
import {TableauUserDialogComponent} from "./TableauUserDialog.component";

@Component({
    templateUrl: 'PortalUserEdit.component.html'
})
export class PortalUserEditComponent implements OnDestroy {
    item: any;
    client: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    @ViewChild('grid', { static: true }) grid: ConfigGridComponent;
    tab;
    portalUserPermissions = this.api.PortalUserPermission.query();
    columnDefs: IAgGridColumnDef[];
    tableauUsersChanged = false;

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private notificationService: NotificationService,
        private translateService: TranslateService,
        private fileDownloadService: FileDownloadService,
        private formatters: ValueFormatters,
        private dialogService: DialogService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'portalUsers';

        this.returnPath = '/clients';
        const id = this.route.snapshot.params['id'];

        this.item = this.api.ClientPortalUser.get({ id: id });
        this.client = this.api.Client.get({ id: id });

        window.addEventListener('beforeunload', this.beforeunload);

        Promise.all([this.item.$promise, this.client.$promise, this.portalUserPermissions.$promise]).then(() => {
            this.item.PortalUsers.forEach(u => {
                u.ConfirmPassword = u.Password;
            });
            
            var columnDefs: IAgGridColumnDef[] = [
                { colId: "Id", field: "Id", headerName: "#", width: 80, filterType: 'integer', pinned: 'left' },
                { colId: "Email", field: "Email", headerName: "Username", width: 250, pinned: 'left', cellTemplate: `<input type="email" [readonly]="params.data.Id != null" required [(ngModel)]="params.data.Email" [name]=\"'params.data[' + params.node.id + '].Email'\" style="width: 99%;" />` },
                { colId: "Password", field: "Password", headerName: "Password / Confirm password", pinned: 'left', width: 360, cellClass: "select", cellTemplate: `
                    <a *ngIf="!updatePassword && params.data.Id" (click)="updatePassword = !updatePassword" title="" class="mdi mdi-pencil"></a>
                    <a *ngIf="updatePassword && params.data.Id" (click)="updatePassword = !updatePassword" title="" class="mdi mdi-close"></a>&nbsp;&nbsp;
                    <span *ngIf="updatePassword || !params.data.Id">
                    <input type="password" style="width: 150px;" [required]="updatePassword || !params.data.Id" [(ngModel)]="params.data.Password" [name]=\"'params.data[' + params.node.id + '].Password'\" [placeholder]="'Password'|translate" />&nbsp;&nbsp;/
                    &nbsp;&nbsp;<input type="password" style="width: 150px;" [required]="updatePassword || !params.data.Id" [(ngModel)]="params.data.ConfirmPassword" [name]=\"'params.data[' + params.node.id + '].ConfirmPassword'\" [password-match]="params.data.Password" [placeholder]="'Confirm password'|translate" />
                    </span>` },
                { colId: "Active", field: "Active", headerName: 'Active?', width: 70, pinned: 'left', cellTemplate: `<input type="checkbox" [(ngModel)]="params.data.Active" [name]="'params.data[' + params.node.id + '].Active'" />` },
            ];
            
            if (this.client.PortalSaml2IdentityProvider) {
                columnDefs.push(
                    { colId: "SamlEmail", field: "SamlEmail", headerName: "SAML email address", width: 250, cellTemplate: `<input type="email" [(ngModel)]="params.data.SamlEmail" [name]=\"'params.data[' + params.node.id + '].SamlEmail'\" style="width: 99%;" />` }
                );
            }

            if (this.client.PortalTableauTrustedAuthenticationTokenUrl) {
                columnDefs.push(
                    { colId: "TableauTrustedAuthenticationUsername", field: "TableauTrustedAuthenticationUsername", headerName: "Tableau trusted auth user", width: 250, cellTemplate: `
                        <span style="padding: 0 3px;" *ngIf="params.data.TableauTrustedAuthenticationUsername != null">{{params.data.TableauTrustedAuthenticationUsername}}</span>
                        <button (click)="parent.manageTableauTrustedUser(params.data)" type="button">{{'Manage...'|translate}}</button>` }
                );
            }

            Object.keys(this.portalUserPermissions).forEach(permission => {
                columnDefs.push({ colId: "HasAccessTo_" + permission, field: "HasAccessTo_" + permission, headerName: "Has access to " + this.portalUserPermissions[permission] + '?', width: 120, cellTemplate: `<input type="checkbox" [disabled]="('${permission}' === 'Analytics' && (!parent.client.ClientPortalTableauDashboards || parent.client.ClientPortalTableauDashboards.length === 0)) || ('${permission}' === 'LWEGrid' && !parent.client.PortalEnableLWEGrid) || ('${permission}' === 'DispatchHoldoverTaxiTimePrediction' && !parent.client.UseHoldoverTaxiTimePrediction)" [checked]="parent.isPermissionEnabled(params.data, '${permission}')" (click)="parent.togglePermission(params.data, '${permission}')" />`, sortable: false });
            });

            columnDefs.push(
                { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 150, filterType: 'date', valueFormatter: this.formatters.utcDateTimeFormatter() },
                { colId: "LastModified", field: "LastModified", headerName: "Modified date", width: 150, filterType: 'date', valueFormatter: this.formatters.utcDateTimeFormatter() },
                { colId: "LastSessionDateTime", field: "LastSessionDateTime", headerName: "Last seen", width: 150, filterType: 'date', valueFormatter: this.formatters.utcDateTimeFormatter() },
                { colId: "LastSessionIP", field: "LastSessionIP", headerName: "IP address", width: 150 },
                { colId: "LastSessionLongitude", field: "LastSessionLongitude", headerName: "Longitude", width: 150 },
                { colId: "LastSessionLatitude", field: "LastSessionLatitude", headerName: "Latitude", width: 150 },
            );

            columnDefs.push(
                { colId: "Delete", field: "Delete", headerName: "", width: 40, cellTemplate: `<a *ngIf="!params.data.HasHotRequests" (click)="parent.removePortalUser(params.data)" class="mdi mdi-delete" title="Delete"></a><a *ngIf="params.data.HasHotRequests" (click)="parent.portalUserInUse(params.data)" class="mdi mdi-block-helper" title="In use"></a>`, pinned: 'right', sortable: false }
            );
            
            this.columnDefs = columnDefs;
        });
    }

    beforeunload(e) {
        if (this.tableauUsersChanged && !this.ngForm.form.pristine) {
            e.preventDefault();
            e.returnValue = '';
            return 'Are you sure you want to discard your changes?';
        }

        return null;
    }

    ngOnDestroy(): void {
        window.removeEventListener('beforeunload', this.beforeunload);
    }

    save() {
        this.item.$promise.then(_ => {
            this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['Client'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addPortalUser() {
        this.item.PortalUsers = this.item.PortalUsers || [];
        this.item.PortalUsers.unshift({
            Active: true,
        });
        this.grid.gridApi.applyTransaction({ addIndex: 0, add: this.item.PortalUsers.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    removePortalUser(portalUser) {
        this.item.PortalUsers.splice(this.item.PortalUsers.indexOf(portalUser), 1);
        this.grid.updateFilteredRows();
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    }

    portalUserInUse(item) {
        this.notificationService.show(this.translateService.translate('HOT requests found for user ' + item.Email), { type: 'error' });
    }

    isPermissionEnabled(user, option) {
        const selected = (user.Permissions || '').split(', ');
        return selected.indexOf(option) > -1;
    }

    togglePermission(user, option) {
        let selected = user.Permissions ? user.Permissions.split(', ') : [];
        const index = selected.indexOf(option);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(option);
        }
        user.Permissions = selected.join(', ');
        this.ngForm.form.markAsDirty();
    }

    exportPortalUsers() {
        var query = {
            id: this.item.Id,
        };
        this.api.ClientPortalUserExport
            .exportPost(query)
            .then((response) => this.fileDownloadService.download(response.body, (header) => response.headers.get(header)));
    }

    manageTableauTrustedUser(portalUser) {
        this.dialogService.show(this.viewContainerRef, TableauUserDialogComponent,
            {
                portalUser: portalUser,
                tableauUserProvisioning: (portalUser.TableauTrustedAuthenticationUserId == null ? (portalUser.TableauTrustedAuthenticationUsername == null ? '' : 'manual') : 'automatic'),
                createTableauUser: () => {
                    this.api.TableauUser.post({
                        username: portalUser.Email,
                        group: this.client.ICAOCode.toUpperCase(),
                    }).then(result => {
                        portalUser.TableauTrustedAuthenticationUserId = result.UserId;
                        portalUser.TableauTrustedAuthenticationUsername = portalUser.Email;
                        portalUser.TableauTrustedAuthenticationGroupId = result.GroupId;
                        portalUser.TableauTrustedAuthenticationGroupName = this.client.ICAOCode.toUpperCase();

                        this.tableauUsersChanged = true;
                        this.ngForm.form.markAsDirty();
                    });
                },
                deleteTableauUser: () => {
                    if (confirm(this.translateService.translate('Are you sure?'))) {
                        this.api.TableauUser.delete({ id: portalUser.TableauTrustedAuthenticationUserId }).then(result => {
                            portalUser.TableauTrustedAuthenticationUserId = null;
                            portalUser.TableauTrustedAuthenticationUsername = null;
                            portalUser.TableauTrustedAuthenticationGroupId = null;
                            portalUser.TableauTrustedAuthenticationGroupName = null;

                            this.tableauUsersChanged = true;
                            this.ngForm.form.markAsDirty();
                        });
                    }
                }
            },
            {
                title: "Manage Tableau user",
                width: 550,
                height: 350,
                modal: true,
            });
    }
}
