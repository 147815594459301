import {Component} from "@angular/core";

@Component({
    templateUrl: './BarrelIcing.component.html'
})
export class BarrelIcingComponent {
    query: any = {};
    constructor() {
    }

    onAirplaneRefresh(query) {
        this.query = {...query};
    }
} 
