<table class="table">
    <tr>
        <th sortable-column="item.Id">{{'#'}}</th>
        <th sortable-column="item.StartTime|utcDateTimeFormat:$root.dateTimeFormat">{{'Start time'|translate}}</th>
        <th sortable-column="item.FluidName">{{'Selected fluid'|translate}}</th>
        <th sortable-column="item.WeatherTypeName">{{'Weather type'|translate}}</th>
        <th sortable-column="item.Temperature">{{'Temperature'|translate}}</th>
        <th sortable-column="item.MinHot">{{'Min Hot'|translate}}</th>
        <th sortable-column="item.MaxHot">{{'Max Hot'|translate}}</th>
    </tr>
    <tr *ngFor="let item of deicingReports">
        <td>{{item.Id}}</td>
        <td>{{item.StartTime|utcDateTimeFormat:dateTimeFormat}}</td>
        <td>{{item.FluidName}}</td>
        <td>{{(item.MetarWeatherType ? metarWeatherTypes[item.MetarWeatherType] : '')}}</td>
        <td>{{item.Temperature}}</td>
        <td>{{item.MinHot}}</td>
        <td>{{item.MaxHot}}</td>
    </tr>
</table>
