import {Injectable, ViewContainerRef} from '@angular/core'
import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/dialog.js';

@Injectable({ providedIn: 'root'})
export class DialogService {
    show<T>(viewContainerRef: ViewContainerRef, type: { new(... args: any[]): T; }, context?: T|any, options?: JQueryUI.DialogOptions) {
        const componentRef = viewContainerRef.createComponent(type);
        const container = jQuery('<div></div>');

        componentRef.instance['container'] = container;

        if (context) {
            Object.keys(context).forEach(i => componentRef.instance[i] = context[i]);
        }

        container.append(componentRef.location.nativeElement as any as Element);

        options = Object.assign(options || {}, {
            open: () => {
                jQuery('.ui-widget-overlay')
                    .bind('click',
                        () => {
                            container.dialog('close');
                        });
            },
            beforeClose: () => {
                componentRef.destroy();
	        },
        });

        container.dialog(options);
    }

    showForHtml(html: string, options?: JQueryUI.DialogOptions | any) {
        let container = document.createElement('div');
        container.style.padding = '10px;'
        container.innerHTML = html;
        return this.showForElement(container, options);
    }

    showForElement(element: Element, options?: JQueryUI.DialogOptions | any) {
        const container = jQuery('<div></div>');

        container.append(element);
        
        options = Object.assign(options || {}, {
            open: () => {
                jQuery('.ui-widget-overlay')
                    .bind('click',
                        () => {
                            container.dialog('close');
                        });
            },
        });

        container.dialog(options);
        
        return container[0];
    }
}
