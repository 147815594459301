<script src="../../../node_modules/@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter.js"></script>
<page-title>{{('Weather alert profile'|translate) + ': ' + (item.Name || 'new')}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" id="weatherAlertProfileDetail">
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('levels')" [class.active]="tab == 'levels'" [class.invalid]="!ngForm.form.controls.levelsForm?.valid">{{'Levels'|translate}}</a>
        <a class="tab" (click)="switchTab('airports')" [class.active]="tab == 'airports'" [class.invalid]="!ngForm.form.controls.airportsForm?.valid">{{'Airports'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        <input type="button" class="green" *ngIf="tab == 'levels' && ('ConfigAddWeatherAlertProfiles'|hasPermission)" (click)="addLevel()" value="{{'Add level'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditWeatherAlertProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
        <label>
            <span class="caption">{{'Client'|translate}}</span>
            <select [(ngModel)]="item.ClientId" name="item.ClientId">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let client of clients|orderBy:'Name'" [ngValue]="client.Id">
                    {{client.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Email sender'|translate}}</span>
            <select required [(ngModel)]="item.EmailFromId" name="item.EmailFromId">
                <option *ngFor="let emailFrom of emailFroms|orderBy:'Id'" [ngValue]="emailFrom.Id">
                    {{emailFrom.EmailAddress}}
                </option>
            </select>
        </label>
    </fieldset>

    <fieldset class="form contentMargins" [disabled]="!('ConfigEditWeatherAlertProfiles'|hasPermission)" [hidden]="tab != 'levels'" ngModelGroup="levelsForm">
        <table class="table" *ngFor="let level of item.WeatherAlertLevels|orderBy:'Order'; let i = index" style="margin-bottom: 20px; width: 100%;">
            <tr>
                <th style="width: 70px;">{{'Order'|translate}}</th>
                <th>{{'Name'|translate}}</th>
                <th style="width: 80px;">{{'Minimum duration (minutes)'|translate}}</th>
                <th style="width: 50px;"></th>
            </tr>
            <tr>
                <td>
                    <input type="number" required step="1" min="0" style="width: 100%;" [(ngModel)]="level.Order" [name]="'level[' + i + '].Order'" />
                </td>
                <td>
                    <input type="text" style="width: 100%;" [(ngModel)]="level.Name" [name]="'level[' + i + '].Name'" />
                </td>
                <td>
                    <input type="number" required step="1" min="0" style="width: 100%;" [(ngModel)]="level.MinimumDurationMinutes" [name]="'level[' + i + '].MinimumDurationMinutes'" />
                </td>
                <td>
                    <a *ngIf="'ConfigEditWeatherAlertProfiles'|hasPermission" (click)="removeLevel(level)" class="mdi mdi-delete"></a>
                </td>
            </tr>
            <tr>
                <th colspan="4">
                    {{'Entering conditions'|translate}}

                    <select required [(ngModel)]="level.EnteringConditionType" [name]="'level[' + i + '].EnteringConditionType'">
                        <option [ngValue]="'OrGroup'">{{'Any of'|translate}}</option>
                        <option [ngValue]="'AndGroup'">{{'All of'|translate}}</option>
                    </select>
                </th>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="conditions">
                        <weather-alert-condition-list [ngForm]="ngForm" [conditions]="level.WeatherAlertEnteringConditions" [parent]="null"></weather-alert-condition-list>
                    </div>
                </td>
            </tr>
            <tr>
                <th colspan="4">{{'Entering email'|translate}}</th>
            </tr>
            <tr>
                <td colspan="4">
                    <label>
                        <span class="caption">{{'Available tokens'|translate}}</span>
                        <span class="value" style="width: auto;">{{'{AirportCode} {ContactInfo} {OperationManagerName} {OperationManagerPhone} {OperationManagerEmailAddress} {AlertId}'}}</span>
                    </label>
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <input type="text" style="width: 100%;" [(ngModel)]="level.EnteringSubject" [name]="'level[' + i + '].EnteringSubject'" placeholder="Subject" />
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <ckeditor [editor]="Editor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="level.EnteringBody" [name]="'level[' + i + '].EnteringBody'"></ckeditor>
                </td>
            </tr>
            <tr>
                <th colspan="4">
                    {{'Exiting conditions'|translate}}

                    <select required [(ngModel)]="level.ExitingConditionType" [name]="'level[' + i + '].ExitingConditionType'">
                        <option [ngValue]="'OrGroup'">{{'Any of'|translate}}</option>
                        <option [ngValue]="'AndGroup'">{{'All of'|translate}}</option>
                    </select>
                </th>
            </tr>
            <tr>
                <td colspan="4">
                    <div class="conditions">
                        <weather-alert-condition-list [ngForm]="ngForm" [conditions]="level.WeatherAlertExitingConditions" [parent]="null"></weather-alert-condition-list>
                    </div>
                </td>
            </tr>
            <tr>
                <th colspan="4">{{'Exiting email'|translate}}</th>
            </tr>
            <tr>
                <td colspan="4">
                    <label>
                        <span class="caption">{{'Available tokens'|translate}}</span>
                        <span class="value" style="width: auto;">{{'{AirportCode} {ContactInfo} {OperationManagerName} {OperationManagerPhone} {OperationManagerEmailAddress}'}}</span>
                    </label>
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <input type="text" style="width: 100%;" [(ngModel)]="level.ExitingSubject" [name]="'level[' + i + '].ExitingSubject'" placeholder="Subject" />
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <ckeditor [editor]="Editor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="level.ExitingBody" [name]="'level[' + i + '].ExitingBody'"></ckeditor>
                </td>
            </tr>
        </table>
    </fieldset>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditWeatherAlertProfiles'|hasPermission)" [hidden]="tab != 'airports'" ngModelGroup="airportsForm">
        <select [(ngModel)]="airport" name="airport">
            <option *ngFor="let airportOption of airports|airportsNotEnabled:item.WeatherAlertAirports|orderBy:['ICAOCode']" [ngValue]="airportOption">
                {{airportOption.ICAOCode + '/' + airportOption.IATACode + ' - ' + airportOption.Name}}
            </option>
        </select>
        <input type="button" *ngIf="('ConfigEditWeatherAlertProfiles'|hasPermission)" (click)="addWeatherAlertAirport(airport); airport = null;" value="{{'Add airport'|translate}}" [disabled]="airport == null" />
        <br />
        <br />
        <table class="table">
            <tr>
                <th>{{'Airport'|translate}}</th>
                <th style="width: 250px;">{{'Contact info'|translate}}</th>
                <th></th>
            </tr>
            <tr *ngFor="let weatherAlertAirport of item.WeatherAlertAirports; let i = index">
                <td>{{(airports|findById:weatherAlertAirport.AirportId)?.ICAOCode + ' / ' + (airports|findById:weatherAlertAirport.AirportId)?.ICAOCode + ' - ' + (airports|findById:weatherAlertAirport.AirportId)?.Name}}</td>
                <td>
                    <input type="text" style="width: 100%;" [(ngModel)]="weatherAlertAirport.ContactInfo" [name]="'weatherAlertAirport[' + i + '].ContactInfo'" />
                </td>
                <td>
                    <a *ngIf="('ConfigEditWeatherAlertProfiles'|hasPermission)" (click)="removeWeatherAlertAirport(weatherAlertAirport)" class="mdi mdi-delete"></a>
                </td>
            </tr>
        </table>
    </fieldset>
</form>
