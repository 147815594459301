<div id="mobileAppList" class="noMargins">
    <div class="modal-overlay"></div>
    <page-title>{{('Apps'|translate)}}</page-title>
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="grid.searchQuery" name="searchQuery" />
        </label>
        <label class="inline">
            <input type="checkbox" [(ngModel)]="grid.includeInactive" name="includeInactive" />
            <span class="caption">{{'Include inactive'|translate}}</span>
        </label>
        <label class="inline">
            <span class="caption">{{'Client'|translate}}</span>
            <multiselect [(ngModel)]="query.ClientId" name="ClientId">
                <multiselect-option *ngFor="let client of clients|orderBy:'Name'" [value]="client.Id" [name]="client.Name"></multiselect-option>
            </multiselect>
        </label>
        <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
        <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button type="button" (click)="refresh()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid" ><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
        <button type="button" (click)="syncMDM()"><span class="mdi mdi-sync"></span> {{'Sync MDM'|translate}}</button>
        <button type="button" *ngIf="'ConfigEditMobileApps'|hasPermission" routerLink="/mobileApps/new" class="green">{{'Add app'|translate}}</button>
        <button type="button" *ngIf="'ConfigEditMobileAppReleases'|hasPermission" routerLink="/mobileAppReleases/new" class="green">{{'Add version'|translate}}</button>
    </form>

    
    <config-grid #grid
                 gridId="preferences.MobileApp.ColumnExport"
                 [query]="query"
                 [resource]="api.MobileApp"
                 [exportResource]="api.MobileAppExport"
                 [columnDefs]="columnDefs">
    </config-grid>
    
    
</div>
