<page-title>{{'Manual sensor entry'|translate}}</page-title>
<form class="form contentMargins" #entryForm="ngForm" (ngSubmit)="decode()">
    <label>
        <span class="caption">{{'Sensor'|translate}}</span>
        <select [(ngModel)]="type" required name="type">
            <option *ngFor="let property of sensorTypes|keyvalue" [ngValue]="property.key">
                {{property.value}}
            </option>
        </select>
    </label>
    <label>
        <span class="caption">{{'Sensor string'|translate}}</span>
        <textarea required [(ngModel)]="rawData" style="width: 500px; height: 200px;" name="rawData"></textarea>
    </label>
    <br />
    <input type="submit" [disabled]="!entryForm.valid" [value]="'Decode'|translate" />
</form>
<div class="result" *ngIf="!!result">
    <h2>{{'Result'|translate}}</h2>
    <pre>{{result|json}}</pre>
</div>
