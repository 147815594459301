<page-title>{{('Station'|translate) + ': ' + (item.Name || 'new')}}</page-title>
<div class="noMargins">
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" *ngIf="item.StationType !== 'Simulation'" (click)="switchTab('channels')" [class.active]="tab == 'channels'" [class.invalid]="!ngForm.form.controls.channelsForm?.valid">{{'Channels'|translate}}</a>
        <a class="tab" *ngIf="item.StationType !== 'Simulation'" (click)="switchTab('calibration')" [class.active]="tab == 'calibration'" [class.invalid]="!ngForm.form.controls.calibrationForm?.valid">{{'Calibration'|translate}}</a>
        <a class="tab" *ngIf="item.StationType !== 'Simulation'" (click)="switchTab('connections')" [class.active]="tab == 'connections'" [class.invalid]="!ngForm.form.controls.connectionsForm?.valid">{{'Connections'|translate}}</a>
        <a class="tab" *ngIf="item.StationType !== 'Simulation' && item.StationLoggerType == 'SerialOverTCP'" (click)="switchTab('serial')" [class.active]="tab == 'serial'" [class.invalid]="!ngForm.form.controls.serialForm?.valid">{{'Serial configuration'|translate}}</a>
        <a class="tab" *ngIf="item.StationType !== 'Simulation' && item.Id" (click)="switchTab('alerts')" [class.active]="tab == 'alerts'" [class.invalid]="tab == 'alerts' && !alertsForm?.form?.valid">{{'Alerts emails'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" (click)="save()" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>
    
    <form #ngForm="ngForm" (submit)="save()" class="form">
        <fieldset class="contentMargins" [disabled]="!('ConfigEditStations'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
            </label>
            <label>
                <span class="caption">{{'Station type'|translate}}</span>
                <select [disabled]="item.Id > 0" [(ngModel)]="item.StationType" name="item.StationType" required [selectedIndex]="-1">
                    <option *ngFor="let option of stationTypes|keys" [ngValue]="option">
                        {{stationTypes[option]}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Airport'|translate}}</span>
                <select required [(ngModel)]="item.AirportId" name="item.AirportId">
                    <option *ngFor="let airport of airports|orderBy:['-HasStation', 'ICAOCode']" [ngValue]="airport.Id">
                        {{airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Under maintenance'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.UnderMaintenance" name="item.UnderMaintenance" />
            </label>
            <ng-container *ngIf="item.StationType !== 'Simulation'">
                <label *ngIf="item.UnderMaintenance">
                    <span class="caption">{{'Under maintenance notes'|translate}}</span>
                    <textarea [(ngModel)]="item.UnderMaintenanceNotes" name="item.UnderMaintenanceNotes" style="width: 300px;" rows="5"></textarea>
                </label>
                <label>
                    <span class="caption">{{'Station status tag'|translate}}</span>
                    <input type="text" [(ngModel)]="item.StationStatusTag" name="item.StationStatusTag" maxlength="20" style="width: 60px" />
                </label>
                <label>
                    <span class="caption">{{'Station alarm profile'|translate}}</span>
                    <select [(ngModel)]="item.StationProblemConfigurationProfileId" name="item.StationProblemConfigurationProfileId">
                        <option [ngValue]="null">{{'None (disable alarms)'|translate}}</option>
                        <option *ngFor="let option of stationProblemConfigurationProfiles|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Forward station readings to MQTT'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.ForwardStationReadingsToMqtt" name="item.ForwardStationReadingsToMqtt" />
                </label>
                <label *ngIf="item.Active && item.ForwardStationReadingsToMqtt">
                    <span class="caption">{{'Forward to MQTT topic'|translate}}</span>
                    <input type="text" readonly value="StationReading/{{item.Name}}" />
                </label>
                <label>
                    <span class="caption">{{'Show icon on status page for message sent'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.MessageSent" name="item.MessageSent" />
                </label>
                <label *ngIf="item.StationType == 'Gen2'">
                    <span class="caption">{{'Sartorius CRC'|translate}}</span>
                    <input type="text" [(ngModel)]="item.SartoriusCrc" name="item.SartoriusCrc" />
                </label>
                <label *ngIf="item.StationType == 'Gen2'">
                    <span class="caption">{{'Sartorius weight 1 offset'|translate}}</span>
                    <input type="number" step="any" [(ngModel)]="item.SartoriusWeight1Offset" name="item.SartoriusWeight1Offset" />
                </label>
                <label *ngIf="item.StationType == 'Gen2'">
                    <span class="caption">{{'Sartorius weight 2 offset'|translate}}</span>
                    <input type="number" step="any" [(ngModel)]="item.SartoriusWeight2Offset" name="item.SartoriusWeight2Offset" />
                </label>
                <label *ngIf="item.StationType == 'Gen3' || item.StationType == 'GenV'">
                    <span class="caption">{{'Logger type'|translate}}</span>
                    <select [(ngModel)]="item.StationLoggerType" name="item.StationLoggerType" [selectedIndex]="-1">
                        <option [ngValue]="null">{{'None'|translate}}</option>
                        <option *ngFor="let option of stationLoggerTypes|keys" [ngValue]="option">
                            {{stationLoggerTypes[option]}}
                        </option>
                    </select>
                </label>
                <label>
                    <span class="caption">{{'Save only 1 reading per minute'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.DiscardExtraReadings" name="item.DiscardExtraReadings" />
                </label>
                <label>
                    <span class="caption">{{'Use station time (not recommended)'|translate}}</span>
                    <input type="checkbox" [(ngModel)]="item.UseStationTime" name="item.UseStationTime" />
                </label>
                <label *ngIf="item.StationLoggerType == 'SureLogger2'">
                    <span class="caption">{{'Control MQTT topic'|translate}}</span>
                    <input type="text" [(ngModel)]="item.ControlMQTTTopic" name="item.ControlMQTTTopic" required />
                </label>
                <label *ngIf="item.StationLoggerType == 'SureLogger2'">
                    <span class="caption">{{'Event MQTT topic'|translate}}</span>
                    <input type="text" [(ngModel)]="item.EventMQTTTopic" name="item.EventMQTTTopic" required />
                </label>
                <label *ngIf="item.StationLoggerType == 'DataTaker' || item.StationLoggerType == 'SureLogger'">
                    <span class="caption">{{'Main logger TCP IP'|translate}}</span>
                    <input type="text" [(ngModel)]="item.LoggerIP" name="item.LoggerIP" required />
                </label>
                <label *ngIf="item.StationLoggerType == 'DataTaker' || item.StationLoggerType == 'SureLogger'">
                    <span class="caption">{{'Main logger TCP port'|translate}}</span>
                    <input type="number" [(ngModel)]="item.LoggerPort" name="item.LoggerPort" step="1" required min="1" max="65535" />
                </label>
                <label *ngIf="item.StationLoggerType == 'SerialOverTCP'">
                    <span class="caption">{{'Start at seconds'|translate}}</span>
                    <input type="number" [(ngModel)]="item.StartAtSeconds" name="item.StartAtSeconds" step="1" min="0" max="59" style="width: 50px;" />
                </label>
                <label *ngIf="item.StationLoggerType == 'SerialOverTCP'">
                    <span class="caption">{{'Power relay module TCP IP'|translate}}</span>
                    <input type="text" [(ngModel)]="item.PowerRelayModuleIP" name="item.PowerRelayModuleIP" />
                </label>
                <label *ngIf="item.StationLoggerType == 'SerialOverTCP'">
                    <span class="caption">{{'Power relay module TCP port'|translate}}</span>
                    <input type="number" [(ngModel)]="item.PowerRelayModulePort" name="item.PowerRelayModulePort" step="1" min="1" max="65535" />
                </label>
                <label *ngIf="item.StationLoggerType == 'SerialOverTCP'">
                    <span class="caption">{{'Power relay module username'|translate}}</span>
                    <input type="text" [(ngModel)]="item.PowerRelayModuleUsername" name="item.PowerRelayModuleUsername" />
                </label>
                <label *ngIf="item.StationLoggerType == 'SerialOverTCP'">
                    <span class="caption">{{'Power relay module password'|translate}}</span>
                    <input type="text" [(ngModel)]="item.PowerRelayModulePassword" name="item.PowerRelayModulePassword" />
                </label>
                <label *ngIf="item.StationLoggerType == 'SerialOverTCP' || item.StationLoggerType == 'SureLogger2'">
                    <span class="caption">{{'Power relay module AC OK port'|translate}}</span>
                    <input type="number" [(ngModel)]="item.PowerRelayModuleACOKPort" name="item.PowerRelayModuleACOKPort" step="1" min="1" max="8" />
                </label>
                <label *ngIf="item.StationLoggerType == 'SerialOverTCP' || item.StationLoggerType == 'SureLogger2'">
                    <span class="caption">{{'Power relay module DC OK port'|translate}}</span>
                    <input type="number" [(ngModel)]="item.PowerRelayModuleDCOKPort" name="item.PowerRelayModuleDCOKPort" step="1" min="1" max="8" />
                </label>
                <label *ngIf="item.StationLoggerType == 'SerialOverTCP' || item.StationLoggerType == 'SureLogger2'">
                    <span class="caption">{{'Power relay module Batt Low port'|translate}}</span>
                    <input type="number" [(ngModel)]="item.PowerRelayModuleBattLowPort" name="item.PowerRelayModuleBattLowPort" step="1" min="1" max="8" />
                </label>
                <ng-container *ngIf="item.StationLoggerType == 'SerialOverTCP' || item.StationLoggerType == 'SureLogger2'">
                    <label>
                        <span class="caption">{{'Campbell PWS100 laser control mode'|translate}}</span>
                        <select [(ngModel)]="item.CampbellPWS100LaserControlMode" name="item.CampbellPWS100LaserControlMode" [selectedIndex]="-1">
                            <option [ngValue]="null">{{'None'|translate}}</option>
                            <option *ngFor="let option of campbellPWS100LaserControlModes|keys" [ngValue]="option">
                                {{campbellPWS100LaserControlModes[option]}}
                            </option>
                        </select>
                    </label>
                    <ng-container *ngIf="item.CampbellPWS100LaserControlMode == 'Automatic'">
                        <label>
                            <span class="caption">{{'Laser OFF min temp (°C)'|translate}}</span>
                            <input type="number" required step="any" [(ngModel)]="item.CampbellPWS100LaserControlOffMinTemp" name="item.CampbellPWS100LaserControlOffMinTemp" />
                        </label>
                        <label>
                            <span class="caption">{{'Laser OFF min METAR visibility (SM)'|translate}}</span>
                            <input type="number" step="any" min="0" [(ngModel)]="item.CampbellPWS100LaserOffMininumMetarVisibility" name="item.CampbellPWS100LaserOffMininumMetarVisibility" />
                        </label>
                        <label>
                            <span class="caption">{{'Laser OFF maximum Thies LPM WMO unavailable duration (minutes)'|translate}}</span>
                            <input type="number" step="1" min="0" [(ngModel)]="item.CampbellPWS100LaserOffMaximumThiesLpmUnavailableMinutes" name="item.CampbellPWS100LaserOffMaximumThiesLpmUnavailableMinutes" />
                        </label>
                        <label>
                            <span class="caption">{{'Laser OFF minimum Thies LPM WMO clear duration (minutes)'|translate}}</span>
                            <input type="number" step="1" min="0" [(ngModel)]="item.CampbellPWS100LaserOffMinimumThiesLpmClearMinutes" name="item.CampbellPWS100LaserOffMinimumThiesLpmClearMinutes" />
                        </label>
                        <label>
                            <span class="caption">{{'Laser OFF minimum ON duration (minutes)'|translate}}</span>
                            <input type="number" step="1" min="0" [(ngModel)]="item.CampbellPWS100LaserMinimumOnMinutes" name="item.CampbellPWS100LaserMinimumOnMinutes" />
                        </label>
                        <label>
                            <span class="caption">{{'Sensor warming up duration (minutes)'|translate}}</span>
                            <input type="number" step="1" min="0" [(ngModel)]="item.CampbellPWS100WarmingUpMinutes" name="item.CampbellPWS100WarmingUpMinutes" />
                        </label>
                    </ng-container>
                </ng-container>
            </ng-container>
        </fieldset>

        <fieldset class="contentMargins" *ngIf="item.StationType !== 'Simulation'" [disabled]="!('ConfigEditStations'|hasPermission)" [hidden]="tab != 'channels'" ngModelGroup="channelsForm">
            <div *ngIf="item.StationType == 'Gen2'">
                <h3>{{'Sensor indices'|translate}}</h3>
                <table class="table">
                    <tr>
                        <th>{{'Sensor'|translate}}</th>
                        <th>{{'Index'|translate}}</th>
                    </tr>
                    <tr>
                        <td>{{'Thies temperature'|translate}}</td>
                        <td><input type="number" step="1" [(ngModel)]="item.ThiesTemperatureIndex" name="item.ThiesTemperatureIndex" /></td>
                    </tr>
                    <tr>
                        <td>{{'Thies relative humidity'|translate}}</td>
                        <td><input type="number" step="1" [(ngModel)]="item.ThiesRelativeHumidityIndex" name="item.ThiesRelativeHumidityIndex" /></td>
                    </tr>
                    <tr>
                        <td>{{'Vaisala/Digital temperature'|translate}}</td>
                        <td><input type="number" step="1" [(ngModel)]="item.VaisalaTemperatureIndex" name="item.VaisalaTemperatureIndex" /></td>
                    </tr>
                    <tr>
                        <td>{{'Thies LPM'|translate}}</td>
                        <td><input type="number" step="1" [(ngModel)]="item.ThiesLPMIndex" name="item.ThiesLPMIndex" /></td>
                    </tr>
                    <tr>
                        <td>{{'Campbell PWS100'|translate}}</td>
                        <td><input type="number" step="1" [(ngModel)]="item.CampbellPWS100Index" name="item.CampbellPWS100Index" /></td>
                    </tr>
                    <tr>
                        <td>{{'Vaisala FD12P'|translate}}</td>
                        <td><input type="number" step="1" [(ngModel)]="item.VaisalaFD12PIndex" name="item.VaisalaFD12PIndex" /></td>
                    </tr>
                    <tr>
                        <td>{{'Biral SWS200'|translate}}</td>
                        <td><input type="number" step="1" [(ngModel)]="item.BiralSWS200Index" name="item.BiralSWS200Index" /></td>
                    </tr>
                    <tr>
                        <td>{{'Thies 2D Compact'|translate}}</td>
                        <td><input type="number" step="1" [(ngModel)]="item.Thies2DCompactIndex" name="item.Thies2DCompactIndex" /></td>
                    </tr>
                    <tr>
                        <td>{{'Vaisala WS425'|translate}}</td>
                        <td><input type="number" step="1" [(ngModel)]="item.VaisalaWS425Index" name="item.VaisalaWS425Index" /></td>
                    </tr>
                </table>
            </div>
            <div *ngIf="item.StationType == 'Gen3' || item.StationType == 'GenV'">
                <table class="table">
                    <tr>
                        <th>{{'Sensor'|translate}}</th>
                        <th>{{'Channel names (comma separated if multiple)'|translate}}</th>
                    </tr>
                    <tr>
                        <td>{{'Thies LPM'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.ThiesLPMChannel" name="item.ThiesLPMChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Thies LPM extra'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.ThiesLPMExtraChannel" name="item.ThiesLPMExtraChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Campbell PWS100'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.CampbellPWS100Channel" name="item.CampbellPWS100Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Campbell PWS100 debug'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.CampbellPWS100DebugChannel" name="item.CampbellPWS100DebugChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Thies 2D Compact'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.Thies2DCompactChannel" name="item.Thies2DCompactChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Thies WP'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.ThiesWPChannel" name="item.ThiesWPChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Thies WP 1 sec'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.ThiesWP1SecChannel" name="item.ThiesWP1SecChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Biral SWS-200/250'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.BiralSWS200Channel" name="item.BiralSWS200Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Biral VPF730'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.BiralVPF730Channel" name="item.BiralVPF730Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Biral FRD500'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.BiralFRD500Channel" name="item.BiralFRD500Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'OSI OWI 400'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.OSIOWI400Channel" name="item.OSIOWI400Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Campbell CS125'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.CampbellCS125Channel" name="item.CampbellCS125Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'OTT Parsivel 2'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.OTTParsivel2Channel" name="item.OTTParsivel2Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'OTT Parsivel 2 Secondary'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.OTTParsivel2SecondaryChannel" name="item.OTTParsivel2SecondaryChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Thies 3D'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.Thies3DChannel" name="item.Thies3DChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Digital temp/RH probe (SDI-12 format)'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.DigitalTempChannel" name="item.DigitalTempChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Temperature'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.TempChannel" name="item.TempChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Relative humididty'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.RelativeHumididtyChannel" name="item.RelativeHumididtyChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Active frost temp 1'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.ActiveFrostTemp1Channel" name="item.ActiveFrostTemp1Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Active frost temp 2'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.ActiveFrostTemp2Channel" name="item.ActiveFrostTemp2Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Active frost temp 3'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.ActiveFrostTemp3Channel" name="item.ActiveFrostTemp3Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Active frost temp 4'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.ActiveFrostTemp4Channel" name="item.ActiveFrostTemp4Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Vaisala FD71P'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.VaisalaFD71PChannel" name="item.VaisalaFD71PChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Campbell 3D'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.Campbell3DWSChannel" name="item.Campbell3DWSChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'RUT modem data A'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.RUTModemDataAChannel" name="item.RUTModemDataAChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'RUT modem data B'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.RUTModemDataBChannel" name="item.RUTModemDataBChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Lufft VS20k'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.LufftVS20kChannel" name="item.LufftVS20kChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Goodrich LP1'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.GoodrichLP1Channel" name="item.GoodrichLP1Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Thies WP Secondary'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.ThiesWPSecondaryChannel" name="item.ThiesWPSecondaryChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Thies LPM Secondary'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.ThiesLPMSecondaryChannel" name="item.ThiesLPMSecondaryChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Thies LPM Secondary extra'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.ThiesLPMSecondaryExtraChannel" name="item.ThiesLPMSecondaryExtraChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Vaisala WMT700'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.VaisalaWMT700Channel" name="item.VaisalaWMT700Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Vaisala PWD22'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.VaisalaFD12PChannel" name="item.VaisalaFD12PChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Vaisala PWD22 Status'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.VaisalaFD12PStatusChannel" name="item.VaisalaFD12PStatusChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Vaisala PWD22 Secondary'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.VaisalaPWD22SecondaryChannel" name="item.VaisalaPWD22SecondaryChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Vaisala PWD22 Secondary Status'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.VaisalaPWD22SecondaryStatusChannel" name="item.VaisalaPWD22SecondaryStatusChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'OTT Pluvio2'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.OTTPluvio2Channel" name="item.OTTPluvio2Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'OTT Pluvio2 DFAR'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.OTTPluvio2DFARChannel" name="item.OTTPluvio2DFARChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Geonor T200 DA'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.GeonorT200DAChannel" name="item.GeonorT200DAChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Geonor T200 DFAR'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.GeonorT200DFARChannel" name="item.GeonorT200DFARChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Pond Hot Plate'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.PondHotPlateChannel" name="item.PondHotPlateChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr *ngIf="item.PondHotPlateChannel">
                        <td>{{'Pond Hot Plate Alarm Code'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.PondHotPlateAlarmCodeChannel" name="item.PondHotPlateAlarmCodeChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr *ngIf="item.PondHotPlateChannel">
                        <td>{{'Pond Hot Plate Sensor Plate Temp'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.PondHotPlateSensorPlateTempChannel" name="item.PondHotPlateSensorPlateTempChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr *ngIf="item.PondHotPlateChannel">
                        <td>{{'Pond Hot Plate Reference Plate Temp'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.PondHotPlateReferencePlateTempChannel" name="item.PondHotPlateRefPlateTempChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Lufft WS100'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.LufftWS100Channel" name="item.LufftWS100Channel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'MiniOFS'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.MiniOFSChannel" name="item.MiniOFSChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Thies FZRA'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.ThiesFZRAChannel" name="item.ThiesFZRAChannel" style="width: 400px;" /></td>
                    </tr>
                    <tr>
                        <td>{{'Power Relay Module'|translate}}</td>
                        <td><input type="text" [(ngModel)]="item.PowerRelayModuleChannel" name="item.PowerRelayModuleChannel" style="width: 400px;" /></td>
                    </tr>
                </table>
            </div>
        </fieldset>

        <fieldset class="contentMargins" *ngIf="item.StationType !== 'Simulation'" [disabled]="!('ConfigEditStations'|hasPermission)" [hidden]="tab != 'calibration'" ngModelGroup="calibrationForm">
            <table class="table">
                <tr><th>{{'Intensity'}}</th></tr>
                <tr sensor-calibration-configuration-header type="Intensity"></tr>
                <tr *ngIf="item.StationType == 'Gen2'">
                    <td>{{'Sartorius'|translate}}</td>
                    <td><input required type="number" step="any" [(ngModel)]="item.IntensitySartorius.Factor" name="item.IntensitySartorius.Factor" style="width: 50px;" /></td>
                    <td><input required type="number" step="any" [(ngModel)]="item.IntensitySartorius.Offset" name="item.IntensitySartorius.Offset" style="width: 50px;" /></td>
                    <td><input required type="number" step="any" max="{{item.IntensitySartorius.DiscardMax - 1}}" [(ngModel)]="item.IntensitySartorius.DiscardMin" name="item.IntensitySartorius.DiscardMin" style="width: 70px;" /></td>
                    <td><input required type="number" step="any" min="{{item.IntensitySartorius.DiscardMin + 1}}" [(ngModel)]="item.IntensitySartorius.DiscardMax" name="item.IntensitySartorius.DiscardMax" style="width: 70px;" /></td>
                </tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.ThiesLPMChannel != null" [sensor-calibration-configuration]="item.IntensityThiesLPM" property="item.IntensityThiesLPM" type="Intensity" [name]="'Thies LPM'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.ThiesLPMSecondaryChannel != null" [sensor-calibration-configuration]="item.IntensityThiesLPMSecondary" property="item.IntensityThiesLPMSecondary" type="Intensity" [name]="'Thies LPM Secondary'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.CampbellPWS100Channel != null" [sensor-calibration-configuration]="item.IntensityCampbellPWS100" property="item.IntensityCampbellPWS100" type="Intensity" [name]="'Campbell PWS100'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.OTTParsivel2Channel != null" [sensor-calibration-configuration]="item.IntensityOTTParsivel2" property="item.IntensityOTTParsivel2" type="Intensity" [name]="'OTT Parsivel 2'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.OTTParsivel2SecondaryChannel != null" [sensor-calibration-configuration]="item.IntensityOTTParsivel2Secondary" property="item.IntensityOTTParsivel2Secondary" type="Intensity" [name]="'OTT Parsivel 2 Secondary'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.VaisalaFD12PChannel != null" [sensor-calibration-configuration]="item.IntensityVaisalaPWD22" property="item.IntensityVaisalaPWD22" type="Intensity" [name]="'Vaisala PWD22'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.VaisalaPWD22SecondaryChannel != null" [sensor-calibration-configuration]="item.IntensityVaisalaPWD22Secondary" property="item.IntensityVaisalaPWD22Secondary" type="Intensity" [name]="'Vaisala PWD22 Secondary'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.VaisalaFD71PChannel != null" [sensor-calibration-configuration]="item.IntensityVaisalaFD71P" property="item.IntensityVaisalaFD71P" type="Intensity" [name]="'Vaisala FD71P'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.PondHotPlateChannel != null" [sensor-calibration-configuration]="item.IntensityPondHotPlate" property="item.IntensityPondHotPlate" type="Intensity" [name]="'Pond Hot Plate'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.OTTPluvio2Channel != null" [sensor-calibration-configuration]="item.IntensityOTTPluvio2" property="item.IntensityOTTPluvio2" type="Intensity" [name]="'OTT Pluvio2'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.OTTPluvio2DFARChannel != null" [sensor-calibration-configuration]="item.IntensityOTTPluvio2DFAR" property="item.IntensityOTTPluvio2DFAR" type="Intensity" [name]="'OTT Pluvio2 DFAR'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.GeonorT200DAChannel != null" [sensor-calibration-configuration]="item.IntensityGeonorT200DA" property="item.IntensityGeonorT200DA" type="Intensity" [name]="'Geonor T200 DA'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.GeonorT200DFARChannel != null" [sensor-calibration-configuration]="item.IntensityGeonorT200DFAR" property="item.IntensityGeonorT200DFAR" type="Intensity" [name]="'Geonor T200 DFAR'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr><th>{{'WMO'}}</th></tr>
                <tr sensor-calibration-configuration-header type="WMO"></tr>
                <tr *ngIf="item.StationType == 'Gen2' && item.VaisalaFD12PIndex > 0" [sensor-calibration-configuration]="item.WMOVaisalaFD12P" property="item.WMOVaisalaFD12P" type="WMO" [name]="'Vaisala FD12P'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' && item.VaisalaFD12PIndex > 0" [sensor-calibration-configuration]="item.WMOVaisalaFD12P15Minute" property="item.WMOVaisalaFD12P15Minute" type="WMO" [name]="'Vaisala FD12P 15 min.'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' ? item.ThiesLPMIndex > 0 : item.ThiesLPMChannel != null" [sensor-calibration-configuration]="item.WMOThiesLPM" property="item.WMOThiesLPM" type="WMO" [name]="'Thies LPM'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.ThiesLPMSecondaryChannel != null" [sensor-calibration-configuration]="item.WMOThiesLPMSecondary" property="item.WMOThiesLPMSecondary" type="WMO" [name]="'Thies LPM Secondary'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' ? item.CampbellPWS100Index > 0 : item.CampbellPWS100Channel != null" [sensor-calibration-configuration]="item.WMOCampbellPWS100" property="item.WMOCampbellPWS100" type="WMO" [name]="'Campbell PWS100'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.OTTParsivel2Channel != null" [sensor-calibration-configuration]="item.WMOOTTParsivel2" property="item.WMOOTTParsivel2" type="WMO" [name]="'OTT Parsivel 2'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.OTTParsivel2SecondaryChannel != null" [sensor-calibration-configuration]="item.WMOOTTParsivel2Secondary" property="item.WMOOTTParsivel2Secondary" type="WMO" [name]="'OTT Parsivel 2 Secondary'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.VaisalaFD12PChannel != null" [sensor-calibration-configuration]="item.WMOVaisalaPWD22" property="item.WMOVaisalaPWD22" type="WMO" [name]="'Vaisala PWD22'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.VaisalaPWD22SecondaryChannel != null" [sensor-calibration-configuration]="item.WMOVaisalaPWD22Secondary" property="item.WMOVaisalaPWD22Secondary" type="WMO" [name]="'Vaisala PWD22 Secondary'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.VaisalaFD71PChannel != null" [sensor-calibration-configuration]="item.WMOVaisalaFD71P" property="item.WMOVaisalaFD71P" type="WMO" [name]="'Vaisala FD71P'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.MiniOFSChannel != null" [sensor-calibration-configuration]="item.WMOMiniOFS" property="item.WMOMiniOFS" type="WMO" [name]="'MiniOFS'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <!-- Wind Speed -->
                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr><th>{{'Wind Speed'}}</th></tr>
                <tr sensor-calibration-configuration-header></tr>
                <tr *ngIf="item.StationType == 'Gen2' && item.VaisalaWS425Index > 0" [sensor-calibration-configuration]="item.WindVaisalaWS425" property="item.WindVaisalaWS425" [name]="'Vaisala WS425'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' ? item.Thies2DCompactIndex > 0 : item.Thies2DCompactChannel != null" [sensor-calibration-configuration]="item.WindThies2DCompact" property="item.WindThies2DCompact" [name]="'Thies 2D Compact'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.VaisalaWMT700Channel != null" [sensor-calibration-configuration]="item.WindVaisalaWMT700" property="item.WindVaisalaWMT700" [name]="'Vaisala WMT700'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.ThiesWPChannel != null" [sensor-calibration-configuration]="item.WindThiesWP" property="item.WindThiesWP" [name]="'Thies WP'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <!-- Wind Gust Speed -->
                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr><th>{{'Wind Gust Speed'}}</th></tr>
                <tr sensor-calibration-configuration-header></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.ThiesWPChannel != null" [sensor-calibration-configuration]="item.WindGustSpeedThiesWP" property="item.WindGustSpeedThiesWP" [name]="'Thies WP'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <!-- Wind Direction -->
                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr><th>{{'Wind Direction'}}</th></tr>
                <tr sensor-calibration-configuration-header></tr>
                <tr *ngIf="item.StationType == 'Gen2' && item.VaisalaWS425Index > 0" [sensor-calibration-configuration]="item.WindDirectionVaisalaWS425" property="item.WindDirectionVaisalaWS425" [name]="'Vaisala WS425'|translate"></tr>
                <tr *ngIf="(item.StationType == 'Gen2' && item.Thies2DCompactIndex > 0) && item.Thies2DCompactChannel != null" [sensor-calibration-configuration]="item.WindDirectionThies2DCompact" property="item.WindDirectionThies2DCompact" [name]="'Thies 2D Compact'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.VaisalaWMT700Channel != null" [sensor-calibration-configuration]="item.WindDirectionVaisalaWMT700" property="item.WindDirectionVaisalaWMT700" [name]="'Vaisala WMT700'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.ThiesWPChannel != null" [sensor-calibration-configuration]="item.WindDirectionThiesWP" property="item.WindDirectionThiesWP" [name]="'Thies WP'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <!-- Wind Gust Direction -->
                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr><th>{{'Wind Gust Direction'}}</th></tr>
                <tr sensor-calibration-configuration-header></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.ThiesWPChannel != null" [sensor-calibration-configuration]="item.WindGustDirectionThiesWP" property="item.WindGustDirectionThiesWP" [name]="'Thies WP'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <!-- Visibility -->
                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr><th>{{'Visibility'}}</th></tr>
                <tr sensor-calibration-configuration-header></tr>
                <tr *ngIf="item.StationType == 'Gen2' && item.VaisalaFD12PIndex > 0" [sensor-calibration-configuration]="item.VisibilityVaisalaFD12P" property="item.VisibilityVaisalaFD12P" [name]="'Vaisala FD12P'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' ? item.BiralSWS200Index > 0 : item.BiralSWS200Channel != null" [sensor-calibration-configuration]="item.VisibilityBiralSWS200" property="item.VisibilityBiralSWS200" [name]="'Biral SWS200'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.OTTParsivel2Channel != null" [sensor-calibration-configuration]="item.VisibilityOTTParsivel2" property="item.VisibilityOTTParsivel2" [name]="'OTT Parsivel 2'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.VaisalaFD71PChannel != null" [sensor-calibration-configuration]="item.VisibilityVaisalaFD71P" property="item.VisibilityVaisalaFD71P" [name]="'Vaisala FD71P'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.CampbellPWS100Channel != null" [sensor-calibration-configuration]="item.VisibilityCampbellPWS100" property="item.VisibilityCampbellPWS100" [name]="'Campbell PWS100'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && item.MiniOFSChannel != null" [sensor-calibration-configuration]="item.VisibilityMiniOFS" property="item.VisibilityMiniOFS" [name]="'MiniOFS'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <!-- Temperature -->
                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr><th>{{'Temperature'}}</th></tr>
                <tr sensor-calibration-configuration-header type="Temperature"></tr>
                <tr *ngIf="item.StationType == 'Gen2' && item.VaisalaTemperatureIndex > 0" [sensor-calibration-configuration]="item.TemperatureVaisalaTempProbe" property="item.TemperatureVaisalaTempProbe" type="Temperature" [name]="'Vaisala/Digital Temp Probe'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' && item.VaisalaFD12PIndex > 0" [sensor-calibration-configuration]="item.TemperatureVaisalaFD12P" property="item.TemperatureVaisalaFD12P" type="Temperature" [name]="'Vaisala FD12P'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' ? item.ThiesTemperatureIndex > 0 : item.StationLoggerType == 'DataTaker'" [sensor-calibration-configuration]="item.TemperatureThiesTempProbe" property="item.TemperatureThiesTempProbe" type="Temperature" [name]="'Thies Temp Probe'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' ? item.ThiesLPMIndex > 0 : item.ThiesLPMChannel != null" [sensor-calibration-configuration]="item.TemperatureThiesLPM" property="item.TemperatureThiesLPM" type="Temperature" [name]="'Thies LPM'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && (item.DigitalTempChannel != null || item.TempChannel != null)" [sensor-calibration-configuration]="item.TemperatureDigitalTempProbe" property="item.TemperatureDigitalTempProbe" type="Temperature" [name]="'Digital Temp Probe'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' ? item.Thies2DCompactIndex > 0 : item.Thies2DCompactChannel != null" [sensor-calibration-configuration]="item.TemperatureThies2DCompact" property="item.TemperatureThies2DCompact" type="Temperature" [name]="'Thies 2D Compact'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' ? item.CampbellPWS100Index > 0 : item.CampbellPWS100Channel != null" [sensor-calibration-configuration]="item.TemperatureCampbellPWS100" property="item.TemperatureCampbellPWS100" type="Temperature" [name]="'Campbell PWS100'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' ? item.BiralSWS200Index > 0 : item.BiralSWS200Channel != null" [sensor-calibration-configuration]="item.TemperatureBiralSWS200" property="item.TemperatureBiralSWS200" type="Temperature" [name]="'Biral SWS-200/250'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' ? item.VaisalaFD12PIndex > 0 : item.VaisalaFD12PChannel != null" [sensor-calibration-configuration]="item.TemperatureVaisalaPWD22" property="item.TemperatureVaisalaPWD22" type="Temperature" [name]="'Vaisala PWD22'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <!-- Humidity -->
                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr><th>{{'Humidity'}}</th></tr>
                <tr sensor-calibration-configuration-header></tr>
                <tr *ngIf="item.StationType == 'Gen2' && item.VaisalaRelativeHumidityIndex > 0" [sensor-calibration-configuration]="item.HumidityVaisalaTempProbe" property="item.HumidityVaisalaTempProbe" [name]="'Vaisala/Digital Temp Probe'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' ? item.ThiesRelativeHumidityIndex > 0 : item.StationLoggerType == 'DataTaker'" [sensor-calibration-configuration]="item.HumidityThiesTempProbe" property="item.HumidityThiesTempProbe" [name]="'Thies Temp Probe'|translate"></tr>
                <tr *ngIf="item.StationType != 'Gen2' && (item.DigitalTempChannel != null || item.RelativeHumididtyChannel != null) != null" [sensor-calibration-configuration]="item.HumidityDigitalTempProbe" property="item.HumidityDigitalTempProbe" [name]="'Digital Temp Probe'|translate"></tr>
                <tr *ngIf="item.StationType == 'Gen2' ? item.CampbellPWS100Index > 0 : item.CampbellPWS100Channel != null" [sensor-calibration-configuration]="item.HumidityCampbellPWS100" property="item.HumidityCampbellPWS100" [name]="'Campbell PWS100'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>

                <!-- Active frost -->
                <tr><td style="border-style: none; background-color: #ffffff;">&nbsp;</td></tr>
                <tr><th>{{'Active frost'}}</th></tr>
                <tr sensor-calibration-configuration-header type="ActiveFrost"></tr>
                <tr *ngIf="item.StationType == 'Gen2' || item.ActiveFrostTemp1Channel != null" [sensor-calibration-configuration]="item.ActiveFrostTemperature1" property="item.ActiveFrostTemperature1" type="ActiveFrost" [name]="'Active frost temperature 1'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>
                <tr *ngIf="item.StationType == 'Gen2' || item.ActiveFrostTemp2Channel != null" [sensor-calibration-configuration]="item.ActiveFrostTemperature2" property="item.ActiveFrostTemperature2" type="ActiveFrost" [name]="'Active frost temperature 2'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>
                <tr *ngIf="item.StationType == 'Gen2' || item.ActiveFrostTemp3Channel != null" [sensor-calibration-configuration]="item.ActiveFrostTemperature3" property="item.ActiveFrostTemperature3" type="ActiveFrost" [name]="'Active frost temperature 3'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>
                <tr *ngIf="item.StationType == 'Gen2' || item.ActiveFrostTemp4Channel != null" [sensor-calibration-configuration]="item.ActiveFrostTemperature4" property="item.ActiveFrostTemperature4" type="ActiveFrost" [name]="'Active frost temperature 4'|translate" style="border-bottom: 1px solid #d4d4d4;"></tr>
            </table>
        </fieldset>

        <fieldset class="contentMargins" *ngIf="item.StationType !== 'Simulation'" [disabled]="!('ConfigEditStations'|hasPermission)" [hidden]="tab != 'connections'" ngModelGroup="connectionsForm">
            <table class="table">
                <tr>
                    <th *ngIf="item.StationLoggerType == 'SerialOverTCP'">{{'Serial port number'|translate}}</th>
                    <th>{{'Order'|translate}}</th>
                    <th>{{'Active'|translate}}</th>
                    <th>{{'Connection type'|translate}}</th>
                    <th>{{'TCP IP'|translate}}</th>
                    <th>{{'TCP port'|translate}}</th>
                    <th>{{'Topic'|translate}}</th>
                    <th>{{'Username'|translate}}</th>
                    <th>{{'Password'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let stationConnectionConfiguration of item.StationConnectionConfigurations; let i = index">
                    <td *ngIf="item.StationLoggerType == 'SerialOverTCP'"><input type="number" [required]="item.StationLoggerType == 'SerialOverTCP'" [(ngModel)]="stationConnectionConfiguration.SerialPortNumber" [name]="'stationConnectionConfiguration[' + i +'].SerialPortNumber'" style="width: 50px;" min="1" step="1" /></td>
                    <td><input type="number" required [(ngModel)]="stationConnectionConfiguration.Order" [name]="'stationConnectionConfiguration[' + i +'].Order'" style="width: 50px;" step="1" /></td>
                    <td><input type="checkbox" [(ngModel)]="stationConnectionConfiguration.Active" [name]="'stationConnectionConfiguration[' + i +'].Active'" /></td>
                    <td *ngIf="item.StationType == 'Gen2'">
                        <select [(ngModel)]="stationConnectionConfiguration.ConnectionType" [name]="'stationConnectionConfiguration[' + i +'].ConnectionType'" required [selectedIndex]="-1">
                            <option *ngFor="let option of gen2StationConnectionTypes|keys" [ngValue]="option">
                                {{gen2StationConnectionTypes[option]}}
                            </option>
                        </select>
                    </td>
                    <td *ngIf="item.StationType == 'Gen3' && item.StationLoggerType != 'SerialOverTCP' && item.StationLoggerType != 'SureLogger2'">
                        <select [(ngModel)]="stationConnectionConfiguration.ConnectionType" [name]="'stationConnectionConfiguration[' + i +'].ConnectionType'" required [selectedIndex]="-1">
                            <option *ngFor="let option of gen3StationConnectionTypes|keys" [ngValue]="option">
                                {{gen3StationConnectionTypes[option]}}
                            </option>
                        </select>
                    </td>
                    <td *ngIf="item.StationType == 'GenV' && item.StationLoggerType != 'SerialOverTCP' && item.StationLoggerType != 'SureLogger2'">
                        <select [(ngModel)]="stationConnectionConfiguration.ConnectionType" [name]="'stationConnectionConfiguration[' + i +'].ConnectionType'" required [selectedIndex]="-1">
                            <option *ngFor="let option of genVStationConnectionTypes|keys" [ngValue]="option">
                                {{genVStationConnectionTypes[option]}}
                            </option>
                        </select>
                    </td>
                    <td *ngIf="item.StationLoggerType == 'SerialOverTCP'">
                        <select [(ngModel)]="stationConnectionConfiguration.ConnectionType" [name]="'stationConnectionConfiguration[' + i +'].ConnectionType'" required [selectedIndex]="-1">
                            <option *ngFor="let option of serialOverTCPStationConnectionTypes|keys" [ngValue]="option">
                                {{serialOverTCPStationConnectionTypes[option]}}
                            </option>
                        </select>
                    </td>
                    <td *ngIf="item.StationLoggerType == 'SureLogger2'">
                        <select [(ngModel)]="stationConnectionConfiguration.ConnectionType" [name]="'stationConnectionConfiguration[' + i +'].ConnectionType'" required [selectedIndex]="-1">
                            <option *ngFor="let option of sureLogger2StationConnectionTypes|keys" [ngValue]="option">
                                {{sureLogger2StationConnectionTypes[option]}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <input *ngIf="stationConnectionConfiguration.ConnectionType !== 'MQTT'" type="text" [(ngModel)]="stationConnectionConfiguration.IP" [name]="'stationConnectionConfiguration[' + i +'].IP'" required />
                        <select *ngIf="stationConnectionConfiguration.ConnectionType === 'MQTT'" required [(ngModel)]="stationConnectionConfiguration.MQTTBrokerId" [name]="'stationConnectionConfiguration[' + i +'].MQTTBrokerId'" r>
                            <option *ngFor="let option of mqttBrokers|orderBy:'Name'" [ngValue]="option.Id">
                                {{option.Name}}
                            </option>
                        </select>
                    </td>
                    <td>
                        <input *ngIf="stationConnectionConfiguration.ConnectionType !== 'MQTT'" type="number" step="1" required min="1" max="65535" [(ngModel)]="stationConnectionConfiguration.Port" [name]="'stationConnectionConfiguration[' + i +'].Port'" style="width: 80px;" />
                    </td>
                    <td>
                        <input *ngIf="stationConnectionConfiguration.ConnectionType === 'MQTT'" type="text" [(ngModel)]="stationConnectionConfiguration.Topic" [name]="'stationConnectionConfiguration[' + i +'].Topic'" [required]="stationConnectionConfiguration.ConnectionType === 'MQTT'" style="width: 250px;" />
                    </td>
                    <td>
                        <input *ngIf="stationConnectionConfiguration.ConnectionType === 'SSHClient'" type="text" [(ngModel)]="stationConnectionConfiguration.Username" [name]="'stationConnectionConfiguration[' + i +'].Topic'" [required]="stationConnectionConfiguration.ConnectionType === 'SSHClient'" />
                    </td>
                    <td>
                        <input *ngIf="stationConnectionConfiguration.ConnectionType === 'SSHClient'" type="text" [(ngModel)]="stationConnectionConfiguration.Password" [name]="'stationConnectionConfiguration[' + i +'].Password'" [required]="stationConnectionConfiguration.ConnectionType === 'SSHClient'" />
                    </td>
                    <td>
                        <a *ngIf="('ConfigDeleteStations'|hasPermission)" (click)="removeStationConnectionConfiguration(stationConnectionConfiguration)" class="mdi mdi-delete"></a>
                    </td>
                </tr>
            </table>
            <br />
            <input type="button" class="green" *ngIf="('ConfigAddStations'|hasPermission)" (click)="addStationConnectionConfiguration()" value="{{'Add connection'|translate}}" />
        </fieldset>

        <fieldset *ngIf="item.StationType !== 'Simulation'" [disabled]="!('ConfigEditStations'|hasPermission)" [hidden]="tab != 'serial'" ngModelGroup="serialForm">
            <div class="form contentMargins">
                <div class="field">
                    <span class="caption">{{'Available read command tokens'|translate}}</span>
                    <span class="caption" style="width: auto;">{{'{StationTemp}'}}</span>
                </div>
            </div>
            <table class="table">
                <tr>
                    <th>{{'Serial port number'|translate}}</th>
                    <th>{{'Order'|translate}}</th>
                    <th>{{'Identifier'|translate}}</th>
                    <th>{{'Read command'|translate}}</th>
                    <th>{{'Start of response'|translate}}</th>
                    <th>{{'End of response'|translate}}</th>
                    <th>{{'Bad response Regex'|translate}}</th>
                    <th>{{'Retry count'|translate}}</th>
                    <th>{{'Sleep (ms)'|translate}}</th>
                    <th>{{'Timeout (ms)'|translate}}</th>
                    <th>{{'Interval'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let stationSerialSensorConfiguration of item.StationSerialSensorConfigurations; let i = index">
                    <td><input type="number" required [(ngModel)]="stationSerialSensorConfiguration.SerialPortNumber" [name]="'stationSerialSensorConfiguration[' + i +'].SerialPortNumber'" style="width: 50px;" min="1" step="1" /></td>
                    <td><input type="number" required [(ngModel)]="stationSerialSensorConfiguration.Order" [name]="'stationSerialSensorConfiguration[' + i +'].Order'" style="width: 50px;" step="1" /></td>
                    <td>
                        <input type="text" [(ngModel)]="stationSerialSensorConfiguration.Identifier" [name]="'stationSerialSensorConfiguration[' + i +'].Identifier'" required />
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="stationSerialSensorConfiguration.ReadCommand" [name]="'stationSerialSensorConfiguration[' + i +'].ReadCommand'" />
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="stationSerialSensorConfiguration.StartOfResponse" [name]="'stationSerialSensorConfiguration[' + i +'].StartOfResponse'" />
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="stationSerialSensorConfiguration.EndOfResponse" [name]="'stationSerialSensorConfiguration[' + i +'].EndOfResponse'" required />
                    </td>
                    <td>
                        <input type="text" [(ngModel)]="stationSerialSensorConfiguration.BadResponseRegex" [name]="'stationSerialSensorConfiguration[' + i +'].BadResponseRegex'" />
                    </td>
                    <td>
                        <input type="number" step="1" min="1" [(ngModel)]="stationSerialSensorConfiguration.RetryCount" [name]="'stationSerialSensorConfiguration[' + i +'].RetryCount'" style="width: 80px;" />
                    </td>
                    <td>
                        <input type="number" step="1" min="0" [(ngModel)]="stationSerialSensorConfiguration.SleepMS" [name]="'stationSerialSensorConfiguration[' + i +'].SleepMS'" style="width: 80px;" />
                    </td>
                    <td>
                        <input type="number" step="1" min="0" [(ngModel)]="stationSerialSensorConfiguration.TimeoutMS" [name]="'stationSerialSensorConfiguration[' + i +'].TimeoutMS'" style="width: 80px;" required />
                    </td>
                    <td>
                        <input type="number" step="1" min="0" [(ngModel)]="stationSerialSensorConfiguration.Interval" [name]="'stationSerialSensorConfiguration[' + i +'].Interval'" style="width: 80px;" />
                    </td>
                    <td>
                        <a *ngIf="('ConfigDeleteStations'|hasPermission)" (click)="removeStationSerialSensorConfiguration(stationSerialSensorConfiguration)" class="mdi mdi-delete"></a>
                    </td>
                </tr>
            </table>
            <br />
            <input type="button" class="green" *ngIf="('ConfigAddStations'|hasPermission)" (click)="addStationSerialSensorConfiguration()" value="{{'Add sensor config'|translate}}" />
        </fieldset>
    </form>
    
    <form class="form" *ngIf="item.StationType !== 'Simulation'" [hidden]="!item.Id || tab != 'alerts'" name="alertsForm" #alertsForm="ngForm">
        <fieldset class="contentMargins">
            <label>
                <span class="caption">{{'Template'|translate}}</span>
                <select [(ngModel)]="stationAlertTemplateId" name="stationAlertTemplateId" (ngModelChange)="stationAlertTemplateIdChanged(stationAlertTemplateId)">
                    <option [ngValue]="null">{{'None'|translate}}</option>
                    <option *ngFor="let option of stationAlertTemplates|orderBy:'Name'" [ngValue]="option.Id">
                        {{option.Name}}
                    </option>
                </select>
            </label>
    
            <h3>{{'Contacts'|translate}}</h3>
            <table class="table">
                <tr>
                    <th style="text-align: center;">{{'Send'|translate}}</th>
                    <th style="text-align: center;" colspan="2">{{'Level'|translate}}</th>
                    <th>{{'Client'|translate}}</th>
                    <th>{{'Name'|translate}}</th>
                    <th>{{'Email'|translate}}</th>
                    <th>{{'Phone'|translate}}</th>
                </tr>
                <tr>
                    <th></th>
                    <th>{{'Lvl 1'}}<input type="checkbox" [(ngModel)]="alertLevel1" name="alertLevel1" (ngModelChange)="alertLevel1Changed(alertLevel1)" /></th>
                    <th>{{'Lvl 2'}}<input type="checkbox" [(ngModel)]="alertLevel2" name="alertLevel2" (ngModelChange)="alertLevel2Changed(alertLevel2)" /></th>
                    <th colspan="4"></th>
                </tr>
                <tr *ngFor="let clientContact of contactsWithAlertLevel(); let i = index">
                    <td><input type="checkbox" *ngIf="clientContact.Email" [(ngModel)]="clientContact.Selected" [name]="'clientContact[' + i + '].Selected'" /></td>
                    <td colspan="2" style="text-align: center;">{{getAlertLevel(clientContact)}}</td>
                    <td>{{(clients|findById:clientContact.ClientId)[0]?.Name}}</td>
                    <td>{{clientContact.Name}}</td>
                    <td>{{clientContact.Email}}</td>
                    <td>{{clientContact.Phone}}</td>
                </tr>
            </table>
    
            <br />
            <br />
    
            <label>
                <span class="caption">{{'From'|translate}}</span>
                <input type="email" disabled [required]="tab == 'alerts'" [(ngModel)]="from" name="from" />
            </label>
            <label>
                <span class="caption">{{'Sent to client customer representatives'|translate}}</span>
                <input type="checkbox" [(ngModel)]="alert.SendToCustomerRepresentatives" name="alert.SendToCustomerRepresentatives" />
            </label>
            <label>
                <span class="caption">{{'CC'|translate}}</span>
                <input type="email" [(ngModel)]="alert.Cc" name="alert.Cc" />
            </label>
            <label>
                <span class="caption">{{'BCC'|translate}}</span>
                <input type="email" [(ngModel)]="alert.Bcc" name="alert.Bcc" />
            </label>
            <label>
                <span class="caption">{{'Subject'|translate}}</span>
                <input type="text" [required]="tab == 'alerts'" [(ngModel)]="alert.Subject" name="alert.Subject" style="width: 450px;" />
            </label>
            <label>
                <span class="caption">{{'Body'|translate}}</span>
                <ckeditor [required]="tab == 'alerts'" [editor]="Editor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="alert.Body" [name]="'alert.Body'"></ckeditor>
            </label>
            <br />
            <label>
                <span class="caption">{{'Show icon on status page for message sent'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.MessageSent" name="item.MessageSent" />
            </label>
            <br />
            <div *ngIf="(clientContacts|filter:{Selected:true}).length === 0">{{'Please select at least one contact'|translate}}<br /><br /></div>
            <label>
                <input type="button" [disabled]="(clientContacts|filter:{Selected:true}).length == 0 || !alertsForm.form?.valid" (click)="sendAlert()" value="{{'Send'|translate}}" />
            </label>
        </fieldset>
    </form>
</div>
