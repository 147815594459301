import { AfterViewInit, Component } from "@angular/core";
import { FileDownloadService } from "swx.front-end-lib";
import { ApiService } from "../shared/Api.service";

@Component({
    templateUrl: 'Log.component.html'
})
export class LogComponent implements AfterViewInit {
    logs: any;
    log: any;
    logFile: any;

    constructor(private api: ApiService, private fileDownload: FileDownloadService) {
    }

    ngAfterViewInit() {
        this.api.Log.query().$promise.then(result => {
            this.logs = result;
        });
    }

    view() {
        this.api.Log.exportItem(this.logFile.FileName).then(response => {
            new Response(response.body).text().then(v => this.log = v);
        });
    }

    download() {
        this.api.Log.exportItem(this.logFile.FileName).then(response => {
            this.fileDownload.download(response.body, header => response.headers.get(header) );
        });
    }
}
