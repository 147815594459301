import {AfterViewInit, Component, Input, ViewChild} from "@angular/core";
import {Tester} from "../../model/Tester";
import {ConfigGridComponent, IAgGridColumnDef} from "swx.front-end-lib";

@Component({
    selector: 'swx-tester-list',
    templateUrl: './testerList.component.html',
    styleUrls: ['./testerList.component.css']
})
export class TesterListComponent {
    @Input() testers: Tester[];
    
    colDefs: IAgGridColumnDef[] = [
        {
            headerName: 'First Name',
            colId: 'FirstName', 
            field: 'FirstName',
        },
        {
            headerName: 'Last Name',
            colId: 'LastName', 
            field: 'LastName',
        },
        {
            headerName: 'Email',
            colId: 'Email',
            field: 'Email'
        }
    ]
    
}
