import {
    Component,
    ComponentRef,
    Injectable,
    ViewContainerRef,
    NgModule,
} from '@angular/core';
import {ICellRendererParams} from "ag-grid-community";
import {
    NgModel,
    NgForm,
    NgModelGroup,
    FormControlName,
    NgSelectOption,
    DefaultValueAccessor,
    CheckboxControlValueAccessor,
    RequiredValidator,
    NumberValueAccessor,
    SelectControlValueAccessor,
    MaxLengthValidator,
    MaxValidator,
    MinValidator,
    MinLengthValidator,
    PatternValidator,
    RadioControlValueAccessor,
    ControlContainer,
    NgControlStatus,
} from "@angular/forms";
import {NgFor, NgForOf, NgIf} from "@angular/common";
import {SwxModule} from "../Swx.module";
import {IAgGridColGroupDef, IAgGridColumnDef} from "./Query.model";
import {RouterLink} from "@angular/router";
import {ColumnPreferencesService} from "./ColumnPreferences.service";

@Injectable({ providedIn: 'root' })
export class CellTemplateService {
    counter = 0;
    
    transformColumnDefCellTemplates(parent, viewContainerRef: ViewContainerRef, columnDefs: (IAgGridColumnDef | IAgGridColGroupDef)[]): Array<ComponentRef<any>> {
        let componentRefs = new Array<ComponentRef<any>>;
        let components = new Array<any>();
        
        ColumnPreferencesService.normalizeColumnDefs(columnDefs)
            .forEach(group => group.children.forEach(colDef => {
                if (colDef.cellRenderer == null && colDef.cellTemplate != null) {
                    let runtimeComponent = Component({
                        selector: 'dynamic-cell-template-' + ++this.counter,
                        template: colDef.cellTemplate,
                        viewProviders: [{provide: ControlContainer, useExisting: NgForm}],
                    })(class {
                    });

                    components.push(runtimeComponent);

                    colDef.cellRenderer = (params: ICellRendererParams) => {
                        let componentRef = viewContainerRef.createComponent(runtimeComponent);
                        componentRefs.push(componentRef);

                        componentRef.instance['parent'] = parent;
                        componentRef.instance['params'] = params;
                        if (colDef.cellRendererParams != null) {
                            Object.assign(componentRef.instance, colDef.cellRendererParams);
                        }

                        let container = document.createElement('div');
                        container.appendChild(componentRef.location.nativeElement);
                        return container;
                    };
                }
            }));

        if (components.length > 0) {
            // importing modules is broken, https://github.com/angular/angular/issues/44660
            NgModule({
                declarations: Array.from(components.values()).concat([
                    NgModel,
                    NgModelGroup,
                    FormControlName,
                    NgSelectOption,
                    DefaultValueAccessor,
                    CheckboxControlValueAccessor,
                    NgControlStatus,
                    NumberValueAccessor,
                    RequiredValidator,
                    SelectControlValueAccessor,
                    RadioControlValueAccessor,
                    MaxLengthValidator,
                    MinLengthValidator,
                    MaxValidator,
                    MinValidator,
                    PatternValidator,
                ]),
                imports: [
                    SwxModule,
                    NgIf,
                    NgFor,
                    NgForOf,
                    RouterLink,
                ],
                providers: [],
            })(class {
            });
        }
        
        return componentRefs;
    }

    cleanup(componentRefs: Array<ComponentRef<any>>) {
        componentRefs.forEach(componentRef => {
            componentRef.destroy();
        });
    }
}
