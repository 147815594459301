<ng-container *ngIf="configuration != null">
    <td>{{name}}</td>
    <td><input type="checkbox" [(ngModel)]="configuration.Enabled" [name]="property + '.Enabled'" (ngModelChange)="enabledUpdated(configuration.Enabled)" [required]="type == 'WMO' && (item.SnowLWESource !== 'RemoteEndpoint' || item.SLDLWESource !== 'RemoteEndpoint') && ((weatherTypeMappingProfileProperty == 'ThiesLPMWTMProfileId' && item.IntensityThiesLPM?.Enabled) || (weatherTypeMappingProfileProperty == 'CampbellPWS100WTMProfileId' && item.IntensityCampbellPWS100?.Enabled))" /></td>
    <td *ngIf="type != 'WMO' && type != 'Intensity'"><input *ngIf="type != 'ActiveFrost' && configuration.Enabled" [required]="configuration.Enabled" type="number" step="1" min="0" unique-priority="group" unique-priority-name="name" [(ngModel)]="configuration.Priority" [name]="property + '.Priority'" style="width: 50px;" /></td>
    <td *ngIf="type != 'WMO' && type != 'Intensity'">
        <select *ngIf="configuration.Enabled" [required]="configuration.Enabled" [(ngModel)]="configuration.Aggregation" [name]="property + '.Aggregation'" [selectedIndex]="-1">
            <option *ngFor="let option of aggregations|keys" [ngValue]="option">
                {{aggregations[option]}}
            </option>
        </select>
    </td>
    <td><input *ngIf="configuration.Enabled" [required]="configuration.Enabled" type="number" step="1" min="1" [(ngModel)]="configuration.SampleCount" [name]="property + '.SampleCount'" style="width: 50px;" /></td>
    <td><input *ngIf="configuration.Enabled" [required]="configuration.Enabled" type="number" step="1" min="1" [max]="configuration.SampleCount" [(ngModel)]="configuration.MinimumSampleCount" [name]="property + '.MinimumSampleCount'" style="width: 50px;" /></td>
    <td>
        <select *ngIf="stationType == 'Gen2' && type == 'WMO' && configuration.Enabled" [required]="configuration.Enabled" [(ngModel)]="item[weatherTypeMappingProfileProperty]" [name]="property + '.' + weatherTypeMappingProfileProperty" [selectedIndex]="-1">
            <option *ngFor="let weatherTypeMappingProfile of weatherTypeMappingProfiles|orderBy:'Name'" [ngValue]="weatherTypeMappingProfile.Id">
                {{weatherTypeMappingProfile.Name}}
            </option>    
        </select>
        <input *ngIf="stationType != 'Gen2' && type == 'Intensity' && configuration.Enabled" type="checkbox" [(ngModel)]="configuration.SnowSpikeCorrection" [name]="property + '.SnowSpikeCorrection'" />
    </td>
</ng-container>
