import {Component, Input} from "@angular/core";

@Component({
    templateUrl: './TableauUserDialog.component.html'
})
export class TableauUserDialogComponent {
    @Input() portalUser: any;
    @Input() tableauUserProvisioning: any;
    @Input() createTableauUser: any;
    @Input() deleteTableauUser: any;
}
