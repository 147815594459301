import { Pipe, PipeTransform } from '@angular/core';
import { Fluid } from '../shared/Api.service';

@Pipe({
    name: 'fluidSort',
    pure: false
})
export class FluidSortPipe implements PipeTransform {
    static sort(fluids: Array<Fluid>): Array<Fluid> {
        return fluids.sort((f1, f2) => {
            if (f1.Order > f2.Order) {
                return 1;
            } else if (f1.Order < f2.Order) {
                return -1;
            }

            if (f1.Type > f2.Type) {
                return 1;
            } else if (f1.Type < f2.Type) {
                return -1;
            }

            if (f1.ClientFluidName > f2.ClientFluidName) {
                return 1;
            } else if (f1.ClientFluidName < f2.ClientFluidName) {
                return -1;
            }

            if (f1.Dilution > f2.Dilution) {
                return 1;
            } else if (f1.Dilution < f2.Dilution) {
                return -1;
            }

            var sortableDilution1 = f1.Type === 'Type1' || !f1.Dilution || f1.Dilution.indexOf("/") === -1
                ? 0
                : parseInt(f1.Dilution.substr(0, f1.Dilution.indexOf("/")));

            var sortableDilution2 = f2.Type === 'Type1' || !f2.Dilution || f2.Dilution.indexOf("/") === -1
                ? 0
                : parseInt(f2.Dilution.substr(0, f2.Dilution.indexOf("/")));

            if (sortableDilution1 < sortableDilution2) {
                return 1;
            } else if (sortableDilution1 > sortableDilution2) {
                return -1;
            }

            return 0;
        });
    }

    transform(fluids: Array<Fluid>): Array<Fluid> {
        return FluidSortPipe.sort(fluids);
    }
}