import {Component} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {IAgGridColGroupDef, ValueFormatters} from "swx.front-end-lib";

@Component({
    templateUrl: './HumanObservationData.component.html'
})
export class HumanObservationDataComponent {
    query: any = {};
    airports = this.api.Airport.query();
    resource = this.api.HumanObservation;
    exportResourcePost = this.api.HumanObservationExport;
    constructor(private api: ApiService,
                private formatters: ValueFormatters) {
    }
    
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };

    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: '',
            children: [
                { colId: "HumanObservation.DateTimeWalkingBackDate", field: "HumanObservation.DateTimeWalkingBack", headerName: "Walking back date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left' },
                { colId: "HumanObservation.DateTimeWalkingBackTime", field: "HumanObservation.DateTimeWalkingBack", headerName: "Walking back time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left' },

                { colId: "AirportName", field: "AirportName", headerName: "Airport", width: 120 },

                { colId: "HumanObservation.DataPointNumber", field: "HumanObservation.DataPointNumber", headerName: "Data point #", width: 60 },
                { colId: "HumanObservation.TestNumber", field: "HumanObservation.TestNumber", headerName: "Test #", width: 60 },
                { colId: "HumanObservation.DateTimeBefore", field: "HumanObservation.DateTimeBefore", headerName: "Before", width: 60, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "HumanObservation.DateTimeAfter", field: "HumanObservation.DateTimeAfter", headerName: "After", width: 60, valueFormatter: this.formatters.utcTimeFormatter() },
            ]
        },
        {
            headerName: 'Pan 1',
            children: [
                { colId: "HumanObservation.Pan1WeightBefore", field: "HumanObservation.Pan1WeightBefore", headerName: "Weight before (g)", width: 80 },
                { colId: "HumanObservation.Pan1WeightAfter", field: "HumanObservation.Pan1WeightAfter", headerName: "Weight after (g)", width: 80 },
                { colId: "HumanObservation.Pan1LWE", field: "HumanObservation.Pan1LWE", headerName: "LWE (g/dm²/h)", width: 60 },
            ]
        },
        {
            headerName: 'Pan 2',
            children: [
                { colId: "HumanObservation.Pan2WeightBefore", field: "HumanObservation.Pan2WeightBefore", headerName: "Weight before (g)", width: 80 },
                { colId: "HumanObservation.Pan2WeightAfter", field: "HumanObservation.Pan2WeightAfter", headerName: "Weight after (g)", width: 80 },
                { colId: "HumanObservation.Pan2LWE", field: "HumanObservation.Pan2LWE", headerName: "LWE (g/dm²/h)", width: 60 },
            ]
        },
        {
            headerName: 'Average',
            children: [
                { colId: "HumanObservation.LWE", field: "HumanObservation.LWE", headerName: "LWE (g/dm²/h)", width: 60 },
            ]
        },
        {
            headerName: '',
            children: [
                { colId: "HumanObservation.WeatherType", field: "HumanObservation.WeatherType", headerName: "Weather type", width: 70 },
                { colId: "HumanObservation.Comments", field: "HumanObservation.Comments", headerName: "Comments", width: 100 },
                { colId: "HumanObservation.IsRateValid", field: "HumanObservation.IsRateValid", headerName: "Is rate valid?", width: 70 },
                { colId: "HumanObservation.IsWeatherTypeValid", field: "HumanObservation.IsWeatherTypeValid", headerName: "Is weather type visible?", width: 70 }
            ]
        }
    ];
} 
