import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, NotificationService, BusyIndicatorService } from 'swx.front-end-lib';
import { ApiService } from '../shared/Api.service';
import { AuthGuard } from '../shared/AuthGuard.service';

@Component({
    templateUrl: 'Login.component.html'
})
export class LoginComponent {
    username: string;
    password: string;
    showLoginForm = false;
    loginAttempted = false;

    constructor(
        private router: Router,
        private authentication: AuthenticationService,
        private authGuard: AuthGuard,
        private notification: NotificationService,
        private busyIndicator: BusyIndicatorService,
        private api: ApiService,
    ) {
        this.api.Environment.queryObject().$promise.then(env => {
            if (env.UseSaml2Authentication
                    && window.location.search.indexOf('skipsaml') === -1) {
                this.authGuard.samlLoginRedirect();
            } else {
                this.showLoginForm = true;
            }
        });
    }

    login() {
        this.busyIndicator.setBusy(true);
        this.loginAttempted = true;

        if (this.username == null || this.password == null) {
            return;
        }

        this.authentication.setCredentials(this.username, this.password)
            .then(() => {
                this.busyIndicator.setBusy(false);
                this.router.navigateByUrl('/');
            },
            error => {
                this.busyIndicator.setBusy(false);
                if (error.status === 400) {
                    this.notification.show('Sorry, that login/password does not match our records.', { type: 'error', sticky: true });
                } else {
                    this.notification.show(error.statusText, { type: 'error', sticky: true });
                }
        });
    }
}
