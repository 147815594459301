import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { StorageService } from "swx.front-end-lib";

@Component({
    templateUrl: './HotSimulator.component.html'
})
export class HotSimulatorComponent implements AfterViewInit, OnInit {
    private storageKey = 'hotSimulatorRequest';
    request: any;
    result: any;
    vm: any;

    constructor(private api: ApiService, private storage: StorageService) {
    }

    ngOnInit() {
        const request = this.storage.load(this.storageKey);
        this.request = request || ({
            ClientId: 2,
            WeatherCategory: 'SN',
            LWE: 8,
            LWETemperature: -4,
            MetarWeatherType: 'SN',
            METARTemperature: -3,
            METARVisibility: "1 3/4SM",
        });
    }

    ngAfterViewInit() {
        Promise.all([
            this.api.Client.query().$promise,
            this.api.Airport.query().$promise,
            this.api.EquipmentType.query().$promise,
            this.api.MetarWeatherType.query(),
            this.api.WeatherCategory.query(),
            this.api.MetarWarning.query()
        ]).then(([clients, airports, equipmentTypes, metarWeatherTypes, weatherCategories, metarWarnings]) => {
            this.vm = {
                clients: clients,
                airports: airports,
                equipmentTypes: equipmentTypes,
                metarWeatherTypes: metarWeatherTypes,
                weatherCategories: weatherCategories,
                metarWarnings: metarWarnings
            };
        });
    }

    send() {
        this.storage.save(this.storageKey, this.request);

        this.api.HotSimulation.post(this.request).then(result => {
            this.result = result;
        });
    }
}
