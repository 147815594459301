import {Component, Input, OnDestroy} from "@angular/core";
import {TestFlightGroup} from "../model/TestFlightGroup";
import {AppstoreconnectService} from "../appstoreconnect.service";
import {Subscription} from "rxjs";
import {BusyIndicatorService} from "swx.front-end-lib";

// Shows TestFlight groups and testers for an app
@Component({
    selector: 'swx-test-flight-details',
    templateUrl: './testFlightDetails.component.html'
})
export class TestFlightDetailsComponent implements OnDestroy {
    @Input() 
    set appId(value: string) {
        if (value) {
            this.getGroupsWithTesters(value);
        }
    }
    
    groups: TestFlightGroup[];
    sub: Subscription;
    
    constructor(private appstore: AppstoreconnectService,
                private busyService: BusyIndicatorService) {
    }
    
    private getGroupsWithTesters(appId: string) {
        this.busyService.setBusy(true);
        this.sub = this.appstore.getAppDetails(appId).subscribe(a => {
            this.groups = a.TestFlightGroups;
            this.busyService.setBusy(false);
        })
    }
    
    ngOnDestroy() {
        if (this.sub) this.sub.unsubscribe();
    }
}
