<page-title>{{('METAR single weather code mapping profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('mappings')" [class.active]="tab == 'mappings'" [class.invalid]="!ngForm.form.controls.mappingsForm?.valid || !areWeatherCodeMappingsValid">{{'Mappings'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine || !areWeatherCodeMappingsValid" class="green" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        <button *ngIf="('ConfigAddMETARWeatherCodeMappingProfiles'|hasPermission) && tab == 'mappings'" type="button" (click)="addCodeMapping()" class="green">{{'Add code mapping'|translate}}</button>
        <button type="button" (click)="exportProfile()" [disabled]="!item.Id"><span class="mdi mdi-download"></span>{{'Excel'|translate}}</button>
        <button type="button" (click)="exportJson()"><span class="mdi mdi-download"></span>{{'Export JSON'|translate}}</button>
        <button type="button" (click)="importJson()"><span class="mdi mdi-upload"></span>{{'Import JSON'|translate}}</button>
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditMETARWeatherCodeMappingProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100"/>
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active"/>
        </label>
        <label>
            <span class="caption">{{'Comment'|translate}}</span>
            <textarea [(ngModel)]="item.Comment" name="item.Comment" rows="4" cols="50"></textarea>
        </label>
    </fieldset>

    <config-grid #mappingsGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'mappings'"
                 ngModelGroup="mappingsForm"
                 #mappingsForm="ngModelGroup"
                 [rowData]="item.MetarWeatherCodeMappings"
                 [columnDefs]="columnDefs"
                 [headerHeight]="56">
    </config-grid>
</form>
