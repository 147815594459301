import { Component } from '@angular/core';

@Component({
    selector: 'station-status-legend',
    templateUrl: './StationStatusLegend.component.html',
    styleUrls: ['./StationStatusLegend.component.scss']
})
export class StationStatusLegendComponent {
    open: boolean = false;

    toggle(): void {
        this.open = !this.open;
    }

    getToggleIcon(): string {
        return `mdi ${this.open ? 'mdi-chevron-down' : 'mdi-chevron-up'}`;
    }
}
