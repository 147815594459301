/// <reference path="../../node_modules/@types/jqueryui/index.d.ts" />
/// <reference path="../../node_modules/@types/jquery.ui.datetimepicker/index.d.ts"/>
import { environment } from './environments/environment';
import { NgModule } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import {
    SwxModule,
    GridModule,
    ExportApi,
    AuthModule,
    TranslatePipe,
    ChangesModule, 
    AuthInterceptorService
} from 'swx.front-end-lib';
import { TicketTrackingSystemService, TicketTrackingCommentDialogComponent } from './shared/TicketTrackingSystem.service';
import { ApiService } from './shared/Api.service';
import { AuthGuard } from './shared/AuthGuard.service';
import { HasPermissionPipe, HasPermissionService } from './shared/HasPermission.pipe';
import { FluidSortPipe } from './shared/FluidSort.pipe';
import { FormatTemperaturePipe } from './shared/FormatTemperature.pipe';
import { AppComponent } from './app.component';
import { FileUploadComponent } from './fileUpload/FileUpload.component';
import { LoginComponent } from './login/Login.component';
import { HomeComponent } from './home/Home.component';
import { MenuComponent } from './menu/Menu.component';
import { UserDetailComponent } from './user/UserDetail.component';
import { UserListComponent } from './user/UserList.component';
import { ChangeGridComponent, DiffCellRendererComponent } from './change/ChangeGrid.component';
import { RequireItemsDirective } from './requireItems/RequireItems.directive';
import { LoginHistoryComponent } from './loginHistory/LoginHistory.component';
import { MobileAppDetailComponent } from './mobileApp/MobileAppDetail.component';
import { MobileAppListComponent } from './mobileApp/MobileAppList.component';
import { MobileAppReleaseDetailComponent } from './mobileAppRelease/MobileAppReleaseDetail.component';
import { WeatherProfilerComponent } from "./weatherProfiler/WeatherProfiler.component";
import { HotCalculatorComponent } from "./hotCalculator/HotCalculator.component";
import { HotRequestComponent } from "./hotRequest/HotRequest.component";
import { HotSimulatorComponent } from "./hotSimulator/HotSimulator.component";
import { MetarWeatherTypeSort } from "./hotSimulator/metarWeatherTypeSort.pipe";
import { ManualSensorEntryComponent } from "./manualSensorEntry/ManualSensorEntry.component";
import { ManualStationEntryCompoment } from "./manualStationEntry/ManualStationEntry.compoment";
import { MetarFinalWeatherTypeComponent } from "./metarFinalWeatherType/MetarFinalWeatherType.component";
import { LogComponent } from "./log/Log.component";
import { ClientApiComponent } from "./clientApi/ClientApi.component";
import { WeatherApiComponent } from "./weatherApi/WeatherApi.component";
import { AcarsRequestCompoment } from "./acars/AcarsRequest.compoment";
import { XmlRequestComponent } from "./xmlRequest/XmlRequest.component";
import { IbmmqPutTestComponent } from "./ibmmqPutTest/IbmmqPutTest.component";
import { EventTestComponent } from "./eventTest/EventTest.component";
import { WeatherProfileRerunComponent } from "./weatherProfileRerun/WeatherProfileRerun.component";
import { StationReadingImportComponent } from "./stationReadingImport/StationReadingImport.component";
import { MDMUploadDialogComponent } from './mobileApp/MDMUploadDialog.component';
import { MDMStateCellRendererService } from './mobileApp/MDMStateCellRenderer.service';
import { MetarValidationComponent } from "./metarValidation/MetarValidation.component";
import { Gen2ValidationComponent } from "./gen2Validation/Gen2Validation.component";
import { Gen3ValidationComponent } from "./gen3Validation/Gen3Validation.component";
import { MetarReadingDataUploadComponent } from "./metarReadingDataUpload/MetarReadingDataUpload.component";
import { HumanObservationDataUploadComponent } from "./humanObservationDataUpload/HumanObservationDataUpload.component";
import { ReportHumanObservationsWithWeatherProfilesComponent } from "./reportHumanObservationsWithWeatherProfiles/ReportHumanObservationsWithWeatherProfiles.component";
import { ReportDeicingComponent } from "./reportDeicing/ReportDeicing.component";
import { ReportDeicingSummaryComponent } from "./reportDeicing/ReportDeicingSummary.component";
import { ReportDeicingErrorCellRendererComponent } from "./reportDeicing/ReportDeicingErrorCellRenderer.component";
import { ReportDeicingHotErrorsDialogComponent } from "./reportDeicing/ReportDeicingHotErrorsDialog.component";
import { ReportMetarWeatherTypesComponent } from "./reportMetarWeatherType/ReportMetarWeatherTypes.component";
import { ReportHotsComponent } from "./reportHOTs/ReportHots.component";
import { FlightsExceedingHotComponent } from "./reportHOTs/FlightsExceedingHot.component";
import { StationStatusComponent } from "./stationStatus/StationStatus.component";
import { StationWeatherIconComponent } from "./stationStatus/StationWeatherIcon.component";
import { StationStatusLegendComponent } from "./stationStatus/StationStatusLegend.component";
import { StationProblemConfigurationProfileListComponent } from "./stationProblemConfiguration/StationProblemConfigurationProfileList.component";
import { StationProblemConfigurationProfileDetailComponent } from "./stationProblemConfiguration/StationProblemConfigurationProfileDetail.component";
import { StationListComponent } from "./station/StationList.component";
import { StationDetailComponent } from "./station/StationDetail.component";
import { SensorCalibrationConfigurationHeaderComponent } from "./station/SensorCalibrationConfigurationHeader.component";
import { SensorCalibrationConfigurationComponent } from "./station/SensorCalibrationConfiguration.component";
import { SensorSelectMatrixListComponent } from "./sensorSelectMatrix/SensorSelectMatrixList.component";
import { SensorSelectionConfigurationHeaderComponent } from "./sensorSelectMatrix/SensorSelectionConfigurationHeader.component";
import { SensorSelectionConfigurationComponent } from "./sensorSelectMatrix/SensorSelectionConfiguration.component";
import { SensorSelectMatrixDetailComponent } from "./sensorSelectMatrix/SensorSelectMatrixDetail.component";
import { StationProblemRawDataDialogComponent } from "./stationStatus/StationProblemRawDataDialog.component";
import { MobileWeatherMappingProfileListComponent } from "./mobileWeatherMappingProfile/MobileWeatherMappingProfileList.component";
import { MobileWeatherMappingProfileDetailComponent } from "./mobileWeatherMappingProfile/MobileWeatherMappingProfileDetail.component";
import { RemoteEndpointListComponent } from "./remoteEndpoint/RemoteEndpointList.component";
import { RemoteEndpointDetailComponent } from "./remoteEndpoint/RemoteEndpointDetail.component";
import { InstantWeatherTypeRuleProfileListComponent } from "./instantWeatherTypeRuleProfile/InstantWeatherTypeRuleProfileList.component";
import { InstantWeatherTypeRuleProfileDetailComponent } from "./instantWeatherTypeRuleProfile/InstantWeatherTypeRuleProfileDetail.component";
import { LWEEquationProfileListComponent } from "./lweEquationProfile/LWEEquationProfileList.component";
import { LWEEquationProfileDetailComponent } from "./lweEquationProfile/LWEEquationProfileDetail.component";
import { LWEMessageMappingProfileListComponent } from "./lweMessageMappingProfile/LWEMessageMappingProfileList.component";
import { LWEMessageMappingProfileDetailComponent } from "./lweMessageMappingProfile/LWEMessageMappingProfileDetail.component";
import { WeatherTypeCountProfileListComponent } from "./weatherTypeCountProfile/WeatherTypeCountProfileList.component";
import { WeatherTypeCountProfileDetailComponent } from "./weatherTypeCountProfile/WeatherTypeCountProfileDetail.component";
import { WeatherTypeMappingProfileListComponent } from "./weatherTypeMappingProfile/WeatherTypeMappingProfileList.component";
import { WeatherTypeMappingProfileDetailComponent } from "./weatherTypeMappingProfile/WeatherTypeMappingProfileDetail.component";
import { WeatherTypeNgToCategoryMappingProfileListComponent } from "./weatherTypeNgToCategoryMappingProfile/WeatherTypeNgToCategoryMappingProfileList.component";
import { WeatherTypeNgToCategoryMappingProfileDetailComponent } from "./weatherTypeNgToCategoryMappingProfile/WeatherTypeNgToCategoryMappingProfileDetail.component";
import { StationMonitoringProfileDetailComponent } from "./stationMonitoringProfile/StationMonitoringProfileDetail.component";
import { StationMonitoringProfileListComponent } from "./stationMonitoringProfile/StationMonitoringProfileList.component";
import { StationMonitoringConditionListComponent } from "./stationMonitoringProfile/StationMonitoringConditionList.component";
import { WeatherAlertProfileDetailComponent } from "./weatherAlertProfile/WeatherAlertProfileDetail.component";
import { WeatherAlertProfileListComponent } from "./weatherAlertProfile/WeatherAlertProfileList.component";
import { WeatherAlertConditionList } from "./weatherAlertProfile/WeatherAlertConditionList.component";
import { MetarMessageMappingProfileListComponent } from "./metarMessageMappingProfile/MetarMessageMappingProfileList.component";
import { MetarMessageMappingProfileDetailComponent} from "./metarMessageMappingProfile/MetarMessageMappingProfileDetail.component";
import { MetarMixedPrecipitationProfileListComponent } from "./metarMixedPrecipitationProfile/MetarMixedPrecipitationProfileList.component";
import { MetarMixedPrecipitationProfileDetailComponent} from "./metarMixedPrecipitationProfile/MetarMixedPrecipitationProfileDetail.component";
import { MetarWeatherCodeMappingProfileListComponent } from "./metarWeatherCodeMappingProfile/MetarWeatherCodeMappingProfileList.component";
import { MetarWeatherCodeMappingProfileDetailComponent} from "./metarWeatherCodeMappingProfile/MetarWeatherCodeMappingProfileDetail.component";
import { MetarWeatherCodeRemappingProfileListComponent } from "./metarWeatherCodeRemappingProfile/MetarWeatherCodeRemappingProfileList.component";
import { MetarWeatherCodeRemappingProfileDetailComponent} from "./metarWeatherCodeRemappingProfile/MetarWeatherCodeRemappingProfileDetail.component";
import { MetarWeatherMappingProfileListComponent } from "./metarWeatherMappingProfile/MetarWeatherMappingProfileList.component";
import { MetarWeatherMappingProfileDetailComponent} from "./metarWeatherMappingProfile/MetarWeatherMappingProfileDetail.component";
import { MetarWeatherTypeMappingProfileListComponent } from "./metarWeatherTypeMappingProfile/MetarWeatherTypeMappingProfileList.component";
import { MetarWeatherTypeMappingProfileDetailComponent} from "./metarWeatherTypeMappingProfile/MetarWeatherTypeMappingProfileDetail.component";
import { MetarSettingsComponent } from "./metarSettings/MetarSettings.component";
import {GridCellLinkRendererComponent} from "./shared/GridCellLinkRenderer.component";
import {GridRowActionsRendererComponent} from "./shared/gridRowActionsRenderer/GridRowActionsRenderer.component";
import {MultiselectCellRendererComponent} from "./shared/multiSelectCellRenderer/MultiselectCellRenderer.component";
import {HotDataComponent} from "./hotData/HotData.component";
import {HotMapComponent} from "./hotMap/HotMap.component";
import {StationDataGen2Component} from "./stationDataGen2/StationDataGen2.component";
import {StationDataGen3Component} from "./stationDataGen3/StationDataGen3.component";
import {StationDataGenVComponent} from "./stationDataGenV/StationDataGenV.component";
import {WeatherProfileDataComponent} from "./weatherProfileData/WeatherProfileData.component";
import {MetarReadingComponent} from "./metarReading/MetarReading.component";
import {BarrelIcingComponent} from "./barrelIcing/BarrelIcing.component";
import {ActiveFrostComponent} from "./activeFrost/ActiveFrost.component";
import {NoaaRapReportComponent} from "./noaaRapReport/NoaaRapReport.component";
import {EngineCoverAlertComponent} from "./engineCoverAlert/EngineCoverAlert.component";
import {WeatherAlertComponent} from "./weatherAlert/WeatherAlert.component";
import {NoaaWpcForecastComponent} from "./noaaWpcForecast/NoaaWpcForecast.component";
import {OpenMeteoForecastComponent} from "./openMeteoForecast/OpenMeteoForecast.component";
import {StationAlertEmailComponent} from "./stationAlertEmail/StationAlertEmail.component";
import {HumanObservationDataComponent} from "./humanObservationData/HumanObservationData.component";
import {PortalUserLoginComponent} from "./portalUserLogin/PortalUserLogin.component";
import {AcarsOooiReportsComponent, RawAcarsDialogComponent} from "./acarsOooiReports/AcarsOooiReports.component";
import {FlairOooiReportComponent} from "./flairOooiReport/FlairOooiReport.component";
import {AidxEventComponent} from "./aidxEvent/AidxEvent.component";
import {FlightAwareFirehoseMessageComponent} from "./flightAwareFirehoseMessage/FlightAwareFirehoseMessage.component";
import {FinnairInitComponent} from "./finnairInit/FinnairInit.component";
import {SureForecastAllClearComponent} from "./sureForecastAllClear/SureForecastAllClear.component";
import {NowcastsLwe12Component} from "./nowcastsLwe12/NowcastsLwe12.component";
import {NowcastsLwe30Component} from "./nowcastsLwe30/NowcastsLwe30.component";
import {DeicingReportsComponent} from "./deicingReports/DeicingReports.component";
import {DeicingRequestComponent} from "./deicingRequest/DeicingRequest.component";
import {AcarsIncomingMessageComponent} from "./acarsIncomingMessage/AcarsIncomingMessage.component";
import {AcarsOutgoingMessageComponent} from "./acarsOutgoingMessage/AcarsOutgoingMessage.component";
import {FlightComponent} from "./flight/Flight.component";
import {HeatFlapsAlertDialogComponent} from "./heatFlapsAlert/HeatFlapsAlertDialog.component";
import {HeatFlapsAlertListComponent} from "./heatFlapsAlert/HeatFlapsAlertList.component";
import {HeatFlapsAlertProfileListComponent} from "./heatFlapsAlertProfile/HeatFlapsAlertProfileList.component";
import {HeatFlapsAlertProfileDetailComponent} from "./heatFlapsAlertProfile/HeatFlapsAlertProfileDetail.component";
import {FlapContaminationAlertDialogComponent} from "./flapContaminationAlert/FlapContaminationAlertDialog.component";
import {FlapContaminationAlertListComponent} from "./flapContaminationAlert/FlapContaminationAlertList.component";
import {FlapContaminationAlertProfileListComponent} from "./flapContaminationAlertProfile/FlapContaminationAlertProfileList.component";
import {FlapContaminationAlertConditionListComponent} from "./flapContaminationAlertProfile/FlapContaminationAlertConditionList.component";
import {FlapContaminationAlertProfileDetailComponent} from "./flapContaminationAlertProfile/FlapContaminationAlertProfileDetail.component";
import {FreezingFogAlertDialogComponent} from "./freezingFogAlert/FreezingFogAlertDialog.component";
import {FreezingFogAlertListComponent} from "./freezingFogAlert/FreezingFogAlertList.component";
import {FreezingFogAlertProfileListComponent} from "./freezingFogAlertProfile/FreezingFogAlertProfileList.component";
import {FreezingFogAlertProfileDetailComponent} from "./freezingFogAlertProfile/FreezingFogAlertProfileDetail.component";
import {NoaaWpcAlertComponent} from "./noaaWpcAlert/NoaaWpcAlert.component";
import {AcarsPreviewDialogComponent} from "./hotData/AcarsPreviewDialog.component";
import {RawDataDialogComponent} from "./hotData/RawDataDialog.component";
import {RawDataLinkCellRenderer} from "./hotData/RawDataLinkCellRenderer";
import {HotResponseMessageCellRendererComponent} from "./hotData/HotResponseMessageCellRenderer";
import {HotErrorsDialogComponent} from "./hotData/HotErrorsDialog.component";
import {WeatherOverrideDeicingReportCellRenderer} from "./hotData/WeatherOverrideDeicingReportCellRenderer";
import {WeatherOverrideDeicingReportsDialogComponent} from "./hotData/WeatherOverrideDeicingReportsDialog.component";
import {MetarReadingDialogComponent} from "./metarReading/MetarReadingDialog.component";
import {RequestDetailDialogComponent} from "./hotMap/RequestDetailDialog.component";
import {StationDataRawCellRendererComponent} from "./stationDataGen2/StationDataRawCellRenderer.component";
import {StationDataRawDataDialogComponent} from "./stationDataGen2/StationDataRawDataDialog.component";
import {BarrelIcingDataComponent} from "./barrelIcing/BarrelIcingData.component";
import {BarrelIcingEmailsComponent} from "./barrelIcing/BarrelIcingEmails.component";
import {ActiveFrostListComponent} from "./activeFrost/ActiveFrostList.component";
import {ActiveFrostEmailsComponent} from "./activeFrost/ActiveFrostEmails.component";
import {NoaaWpcAlertEmailComponent} from "./noaaWpcAlert/NoaaWpcAlertEmail.component";
import {NoaaWpcAlertListComponent} from "./noaaWpcAlert/NoaaWpcAlertList.component";
import {VoiceCallerTestComponent} from "./voiceCallerTest/VoiceCallerTest.component";
import { ActiveFrostProfileDetailComponent } from "./activeFrostProfile/ActiveFrostProfileDetail.component";
import { ActiveFrostProfileListComponent } from "./activeFrostProfile/ActiveFrostProfileList.component";
import { StationAlertTemplateDetailComponent } from "./stationAlertTemplate/StationAlertTemplateDetail.component";
import { StationAlertTemplateListComponent } from "./stationAlertTemplate/StationAlertTemplateList.component";
import { BarrelIcingProfileDetailComponent } from "./barrelIcingProfile/BarrelIcingProfileDetail.component";
import { BarrelIcingProfileListComponent } from "./barrelIcingProfile/BarrelIcingProfileList.component";
import { EngineCoverAlertProfileDetailComponent } from "./engineCoverAlertProfile/EngineCoverAlertProfileDetail.component";
import { EngineCoverAlertProfileListComponent } from "./engineCoverAlertProfile/EngineCoverAlertProfileList.component";
import {AirportListComponent} from "./airport/AirportList.component";
import {SartoriusComponent} from "./sartorius/Sartorius.component";
import {AirportEditComponent} from "./airport/AirportEdit.component";
import {IBMMQServerConfigurationListComponent} from "./ibmMqServerConfiguration/IBMMQServerConfigurationList.component";
import {IBMMQServerConfigurationEditComponent} from "./ibmMqServerConfiguration/IBMMQServerConfigurationEdit.component";
import {MQTTBrokerListComponent} from "./mqttBroker/MQTTBrokerList.component";
import {MQTTBrokerEditComponent} from "./mqttBroker/MQTTBrokerEdit.component";
import {EquipmentTypeListComponent} from "./equipmentType/EquipmentTypeList.component";
import {EquipmentTypeEditComponent} from "./equipmentType/EquipmentTypeEdit.component";
import {IMAPServerConfigurationListComponent} from "./imapServerConfiguration/IMAPServerConfigurationList.component";
import {IMAPServerConfigurationEditComponent} from "./imapServerConfiguration/IMAPServerConfigurationEdit.component";
import {JMSServerConfigurationListComponent} from "./jmsServerConfiguration/JMSServerConfigurationList.component";
import {JMSServerConfigurationEditComponent} from "./jmsServerConfiguration/JMSServerConfigurationEdit.component";
import {SQSQueueConfigurationListComponent} from "./sqsQueueConfiguration/SQSQueueConfigurationList.component";
import {SQSQueueConfigurationEditComponent} from "./sqsQueueConfiguration/SQSQueueConfigurationEdit.component";
import {SFTPServerConfigurationListComponent} from "./sftpServerConfiguration/SFTPServerConfigurationList.component";
import {SFTPServerConfigurationEditComponent} from "./sftpServerConfiguration/SFTPServerConfigurationEdit.component";
import {HotConditionListComponent} from "./hotCondition/HotConditionList.component";
import {HotConditionEditComponent} from "./hotCondition/HotConditionEdit.component";
import {MobileDocumentProfileListComponent} from "./mobileDocumentProfile/MobileDocumentProfileList.component";
import {MobileDocumentProfileEditComponent} from "./mobileDocumentProfile/MobileDocumentProfileEdit.component";
import {
    HoldoverTaxiTimePredictionListComponent
} from "./holdoverTaxiTimePrediction/HoldoverTaxiTimePredictionList.component";
import {
    HoldoverTaxiTimePredictionEditComponent
} from "./holdoverTaxiTimePrediction/HoldoverTaxiTimePredictionEdit.component";
import {ServiceProviderNotEnabledPipe} from "./sqsQueueConfiguration/ServiceProviderNotEnabled.pipe";
import {
    SFTPServerServiceProviderNotEnabledPipe
} from "./sftpServerConfiguration/SFTPServerServiceProviderNotEnabled.pipe";
import {FluidListComponent} from "./fluid/FluidList.component";
import {FluidEditComponent} from "./fluid/FluidEdit.component";
import {StationMonitoringAlertListComponent} from "./stationMonitoringAlert/StationMonitoringAlertList.component";
import {ClientApiTokenDetailComponent} from "./clientApiToken/ClientApiTokenDetail.component";
import {ClientApiTokenListComponent} from "./clientApiToken/ClientApiTokenList.component";
import {XmlToJsonService} from "./clientApiToken/XmlToJsonService";
import {ClientEditComponent} from "./client/ClientEdit.component";
import {ClientContactEditComponent} from "./client/ClientContactEdit.component";
import {PortalUserEditComponent} from "./client/PortalUserEdit.component";
import {TableauUserDialogComponent} from "./client/TableauUserDialog.component";
import {TemplatePreviewDialogComponent} from "./client/TemplatePreviewDialog.component";
import {ClientListComponent} from "./client/ClientList.component";
import {AirportsNotEnabledPipe} from "./client/AirportsNotEnabled.pipe";
import {EquipmentTypeNotEnabledPipe} from "./client/EquipmentTypeNotEnabled.pipe";
import {AirlineNotEnabledPipe} from "./client/AirlineNotEnabled.pipe";
import {ClientSearchFilterPipe} from "./client/ClientSearchFilter.pipe";
import {UserProfileComponent} from "./login/UserProfile.component";
import {Saml2ErrorComponent} from './login/Saml2Error.component';
import {
    ThiesLPMWMOCodeMappingProfileListComponent
} from "./thiesLPMWMOCodeMappingProfile/ThiesLPMWMOCodeMappingProfileList.component";
import {
    ThiesLPMWMOCodeMappingProfileDetailComponent
} from "./thiesLPMWMOCodeMappingProfile/ThiesLPMWMOCodeMappingProfileDetail.component";
import { AgGridModule } from 'ag-grid-angular';
import { AppDetailsComponent } from './appstoreconnect/appDetails/appDetails.component';
import {TestFlightDetailsComponent} from "./appstoreconnect/testFlightDetails/testFlightDetails.component";
import {
    TestFlightGroupDetailsComponent
} from "./appstoreconnect/testFlightDetails/testFlightGroupDetails/testFlightGroupDetails.component";
import {TesterListComponent} from "./appstoreconnect/testFlightDetails/testerList/testerList.component";

@NgModule({
    declarations: [
        AirportsNotEnabledPipe,
        HasPermissionPipe,
        FluidSortPipe,
        FormatTemperaturePipe,
        AppComponent,
        MenuComponent,
        FileUploadComponent,
        LoginComponent,
        HomeComponent,
        UserDetailComponent,
        UserListComponent,
        RequireItemsDirective,
        ChangeGridComponent,
        UserProfileComponent,
        Saml2ErrorComponent,
        DiffCellRendererComponent,
        TicketTrackingCommentDialogComponent,
        LoginHistoryComponent,
        MobileAppDetailComponent,
        MobileAppListComponent,
        MobileAppReleaseDetailComponent,
        WeatherProfilerComponent,
        HotCalculatorComponent,
        HotRequestComponent,
        HotSimulatorComponent,
        MetarWeatherTypeSort,
        ManualSensorEntryComponent,
        ManualStationEntryCompoment,
        MetarFinalWeatherTypeComponent,
        LogComponent,
        ClientApiComponent,
        WeatherApiComponent,
        AcarsRequestCompoment,
        XmlRequestComponent,
        IbmmqPutTestComponent,
        EventTestComponent,
        WeatherProfileRerunComponent,
        StationReadingImportComponent,
        MDMUploadDialogComponent,
        StationMonitoringAlertListComponent,
        MetarValidationComponent,
        Gen2ValidationComponent,
        Gen3ValidationComponent,
        MetarReadingDataUploadComponent,
        HumanObservationDataUploadComponent,
        ReportHumanObservationsWithWeatherProfilesComponent,
        ReportDeicingComponent,
        ReportDeicingSummaryComponent,
        ReportDeicingErrorCellRendererComponent,
        ReportDeicingHotErrorsDialogComponent,
        ReportMetarWeatherTypesComponent,
        ReportHotsComponent,
        FlightsExceedingHotComponent,
        StationStatusComponent,
        StationWeatherIconComponent,
        StationStatusLegendComponent,
        StationProblemConfigurationProfileDetailComponent,
        StationProblemConfigurationProfileListComponent,
        StationDetailComponent,
        StationListComponent,
        SensorCalibrationConfigurationHeaderComponent,
        SensorCalibrationConfigurationComponent,
        SensorSelectMatrixDetailComponent,
        SensorSelectMatrixListComponent,
        SensorSelectionConfigurationHeaderComponent,
        SensorSelectionConfigurationComponent,
        StationProblemRawDataDialogComponent,
        MobileWeatherMappingProfileDetailComponent,
        MobileWeatherMappingProfileListComponent,
        RemoteEndpointDetailComponent,
        RemoteEndpointListComponent,
        InstantWeatherTypeRuleProfileDetailComponent,
        InstantWeatherTypeRuleProfileListComponent,
        LWEEquationProfileDetailComponent,
        LWEEquationProfileListComponent,
        LWEMessageMappingProfileDetailComponent,
        LWEMessageMappingProfileListComponent,
        WeatherTypeCountProfileDetailComponent,
        WeatherTypeCountProfileListComponent,
        WeatherTypeMappingProfileDetailComponent,
        WeatherTypeMappingProfileListComponent,
        WeatherTypeNgToCategoryMappingProfileDetailComponent,
        WeatherTypeNgToCategoryMappingProfileListComponent,
        StationMonitoringProfileDetailComponent,
        StationMonitoringProfileListComponent,
        StationMonitoringConditionListComponent,
        WeatherAlertProfileDetailComponent,
        WeatherAlertProfileListComponent,
        WeatherAlertConditionList,
        BarrelIcingProfileDetailComponent,
        BarrelIcingProfileListComponent,
        ActiveFrostProfileDetailComponent,
        ActiveFrostProfileListComponent,
        EngineCoverAlertProfileDetailComponent,
        EngineCoverAlertProfileListComponent,
        StationAlertTemplateDetailComponent,
        StationAlertTemplateListComponent,
        MetarMessageMappingProfileListComponent,
        MetarMessageMappingProfileDetailComponent,
        MetarMixedPrecipitationProfileListComponent,
        MetarMixedPrecipitationProfileDetailComponent,
        MetarWeatherCodeMappingProfileListComponent,
        MetarWeatherCodeMappingProfileDetailComponent,
        MetarWeatherCodeRemappingProfileListComponent,
        MetarWeatherCodeRemappingProfileDetailComponent,
        MetarWeatherMappingProfileListComponent,
        MetarWeatherMappingProfileDetailComponent,
        MetarWeatherTypeMappingProfileListComponent,
        MetarWeatherTypeMappingProfileDetailComponent,
        MetarSettingsComponent,
        GridCellLinkRendererComponent,
        GridRowActionsRendererComponent,
        MultiselectCellRendererComponent,
        IBMMQServerConfigurationListComponent,
        IBMMQServerConfigurationEditComponent,
        MQTTBrokerListComponent,
        MQTTBrokerEditComponent,
        IMAPServerConfigurationListComponent,
        IMAPServerConfigurationEditComponent,
        JMSServerConfigurationListComponent,
        JMSServerConfigurationEditComponent,
        SQSQueueConfigurationListComponent,
        SQSQueueConfigurationEditComponent,
        SFTPServerConfigurationListComponent,
        SFTPServerConfigurationEditComponent,
        HotConditionListComponent,
        HotConditionEditComponent,
        MobileDocumentProfileListComponent,
        MobileDocumentProfileEditComponent,
        HoldoverTaxiTimePredictionListComponent,
        HoldoverTaxiTimePredictionEditComponent,
        // DATA
        HotDataComponent,
        AcarsPreviewDialogComponent,
        RawDataDialogComponent,
        RawDataLinkCellRenderer,
        HotResponseMessageCellRendererComponent,
        HotErrorsDialogComponent,
        WeatherOverrideDeicingReportCellRenderer,
        WeatherOverrideDeicingReportsDialogComponent,
        HotMapComponent,
        StationDataGen2Component,
        StationDataGen3Component,
        StationDataGenVComponent,
        WeatherProfileDataComponent,
        BarrelIcingComponent,
        NoaaRapReportComponent,
        EngineCoverAlertComponent,
        WeatherAlertComponent,
        NoaaWpcForecastComponent,
        OpenMeteoForecastComponent,
        StationAlertEmailComponent,
        HumanObservationDataComponent,
        PortalUserLoginComponent,
        AcarsOooiReportsComponent,
        FlairOooiReportComponent,
        AidxEventComponent,
        FlightAwareFirehoseMessageComponent,
        FinnairInitComponent,
        SureForecastAllClearComponent,
        NowcastsLwe12Component,
        NowcastsLwe30Component,
        DeicingReportsComponent,
        DeicingRequestComponent,
        AcarsIncomingMessageComponent,
        AcarsOutgoingMessageComponent,
        FlightComponent,
        HeatFlapsAlertListComponent,
        HeatFlapsAlertDialogComponent,
        HeatFlapsAlertProfileListComponent,
        HeatFlapsAlertProfileDetailComponent,
        FlapContaminationAlertListComponent,
        FlapContaminationAlertDialogComponent,
        FlapContaminationAlertProfileListComponent,
        FlapContaminationAlertConditionListComponent,
        FlapContaminationAlertProfileDetailComponent,
        FreezingFogAlertListComponent,
        FreezingFogAlertDialogComponent,
        FreezingFogAlertProfileListComponent,
        FreezingFogAlertProfileDetailComponent,
        MetarReadingComponent,
        MetarReadingDialogComponent,
        RequestDetailDialogComponent,
        StationDataRawCellRendererComponent,
        StationDataRawDataDialogComponent,
        BarrelIcingComponent,
        BarrelIcingDataComponent,
        BarrelIcingEmailsComponent,
        ActiveFrostComponent,
        ActiveFrostListComponent,
        ActiveFrostEmailsComponent,
        NoaaWpcAlertComponent,
        NoaaWpcAlertListComponent,
        NoaaWpcAlertEmailComponent,
        VoiceCallerTestComponent,
        RawAcarsDialogComponent,
        AirportListComponent,
        AirportEditComponent,
        SartoriusComponent,
        ServiceProviderNotEnabledPipe,
        SFTPServerServiceProviderNotEnabledPipe,
        FluidListComponent,
        FluidEditComponent,
        EquipmentTypeListComponent,
        EquipmentTypeEditComponent,
        ClientApiTokenListComponent,
        ClientApiTokenDetailComponent,
        ClientListComponent,
        ClientEditComponent,
        ClientContactEditComponent,
        PortalUserEditComponent,
        TableauUserDialogComponent,
        TemplatePreviewDialogComponent,
        ThiesLPMWMOCodeMappingProfileListComponent,
        ThiesLPMWMOCodeMappingProfileDetailComponent,
        EquipmentTypeNotEnabledPipe,
        AirlineNotEnabledPipe,
        ClientSearchFilterPipe,
        TestFlightDetailsComponent,
        TestFlightGroupDetailsComponent,
        TesterListComponent,
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [BrowserModule,
        RouterModule.forRoot([
            { path: '', component: HomeComponent, canActivate: [AuthGuard] },
            { path: 'login', component: LoginComponent },
            { path: 'users/:id', component: UserDetailComponent, canActivate: [AuthGuard] },
            { path: 'users', component: UserListComponent, canActivate: [AuthGuard] },
            { path: 'changes', component: ChangeGridComponent, canActivate: [AuthGuard] },
            { path: 'userProfile', component: UserProfileComponent, canActivate: [AuthGuard] },
            { path: 'saml2Error', component: Saml2ErrorComponent },
            { path: 'loginHistory', component: LoginHistoryComponent, canActivate: [AuthGuard] },
            { path: 'mobileApps/:id', component: MobileAppDetailComponent, canActivate: [AuthGuard] },
            { path: 'mobileApps', component: MobileAppListComponent, canActivate: [AuthGuard] },
            { path: 'mobileAppReleases/:id', component: MobileAppReleaseDetailComponent, canActivate: [AuthGuard] },
            { path: 'weatherProfiler', component: WeatherProfilerComponent, canActivate: [AuthGuard] },
            { path: 'hotCalculator', component: HotCalculatorComponent, canActivate: [AuthGuard] },
            { path: 'hotRequest', component: HotRequestComponent, canActivate: [AuthGuard] },
            { path: 'stationMonitoringAlerts', component: StationMonitoringAlertListComponent, canActivate: [AuthGuard] },
            { path: 'hotSimulator', component: HotSimulatorComponent, canActivate: [AuthGuard] },
            { path: 'manualSensorEntry', component: ManualSensorEntryComponent, canActivate: [AuthGuard] },
            { path: 'manualStationEntry', component: ManualStationEntryCompoment, canActivate: [AuthGuard] },
            { path: 'metarFinalWeatherType', component: MetarFinalWeatherTypeComponent, canActivate: [AuthGuard] },
            { path: 'log', component: LogComponent, canActivate: [AuthGuard] },
            { path: 'clientApi', component: ClientApiComponent, canActivate: [AuthGuard] },
            { path: 'weatherApi', component: WeatherApiComponent, canActivate: [AuthGuard] },
            { path: 'acarsRequest', component: AcarsRequestCompoment, canActivate: [AuthGuard] },
            { path: 'xmlRequest', component: XmlRequestComponent, canActivate: [AuthGuard] },
            { path: 'ibmmqPutTest', component: IbmmqPutTestComponent, canActivate: [AuthGuard] },
            { path: 'eventTest', component: EventTestComponent, canActivate: [AuthGuard] },
            { path: 'weatherProfileRerun', component: WeatherProfileRerunComponent, canActivate: [AuthGuard] },
            { path: 'stationReadingImport', component: StationReadingImportComponent, canActivate: [AuthGuard] },
            { path: 'metarValidation', component: MetarValidationComponent, canActivate: [AuthGuard] },
            { path: 'gen2Validation', component: Gen2ValidationComponent, canActivate: [AuthGuard] },
            { path: 'gen3Validation', component: Gen3ValidationComponent, canActivate: [AuthGuard] },
            { path: 'metarReadingDataUpload', component: MetarReadingDataUploadComponent, canActivate: [AuthGuard] },
            {
                path: 'humanObservationDataUpload',
                component: HumanObservationDataUploadComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'reportHumanObservationsWithWeatherProfiles',
                component: ReportHumanObservationsWithWeatherProfilesComponent,
                canActivate: [AuthGuard]
            },
            { path: 'reportDeicing', component: ReportDeicingComponent, canActivate: [AuthGuard] },
            { path: 'reportMetarWeatherTypes', component: ReportMetarWeatherTypesComponent, canActivate: [AuthGuard] },
            { path: 'reportHOTs', component: ReportHotsComponent, canActivate: [AuthGuard] },
            { path: 'weatherProfileRerun', component: WeatherProfileRerunComponent, canActivate: [AuthGuard] },
            { path: 'stationReadingImport', component: StationReadingImportComponent, canActivate: [AuthGuard] },
            { path: 'metarValidation', component: MetarValidationComponent, canActivate: [AuthGuard] },
            { path: 'gen2Validation', component: Gen2ValidationComponent, canActivate: [AuthGuard] },
            { path: 'gen3Validation', component: Gen3ValidationComponent, canActivate: [AuthGuard] },
            { path: 'metarReadingDataUpload', component: MetarReadingDataUploadComponent, canActivate: [AuthGuard] },
            {
                path: 'humanObservationDataUpload',
                component: HumanObservationDataUploadComponent,
                canActivate: [AuthGuard]
            },
            { path: 'stations/:id', component: StationDetailComponent, canActivate: [AuthGuard] },
            { path: 'stations', component: StationListComponent, canActivate: [AuthGuard] },
            {
                path: 'stationProblemConfigurations/:id',
                component: StationProblemConfigurationProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'stationProblemConfigurations',
                component: StationProblemConfigurationProfileListComponent,
                canActivate: [AuthGuard]
            },
            { path: 'stationStatus', component: StationStatusComponent, canActivate: [AuthGuard] },
            {
                path: 'sensorSelectMatricesSimulation/:id',
                component: SensorSelectMatrixDetailComponent,
                canActivate: [AuthGuard],
                data: { stationType: 'Simulation' }
            },
            {
                path: 'sensorSelectMatricesGen2/:id',
                component: SensorSelectMatrixDetailComponent,
                canActivate: [AuthGuard],
                data: { stationType: 'Gen2' }
            },
            {
                path: 'sensorSelectMatricesGen3/:id',
                component: SensorSelectMatrixDetailComponent,
                canActivate: [AuthGuard],
                data: { stationType: 'Gen3' }
            },
            {
                path: 'sensorSelectMatricesGenV/:id',
                component: SensorSelectMatrixDetailComponent,
                canActivate: [AuthGuard],
                data: { stationType: 'GenV' }
            },
            { path: 'sensorSelectMatrices', component: SensorSelectMatrixListComponent, canActivate: [AuthGuard] },
            {
                path: 'mobileWeatherMappingProfiles/:id',
                component: MobileWeatherMappingProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'mobileWeatherMappingProfiles',
                component: MobileWeatherMappingProfileListComponent,
                canActivate: [AuthGuard]
            },
            { path: 'remoteEndpoints/:id', component: RemoteEndpointDetailComponent, canActivate: [AuthGuard] },
            { path: 'remoteEndpoints', component: RemoteEndpointListComponent, canActivate: [AuthGuard] },
            { path: 'lweEquationProfiles/:id', component: LWEEquationProfileDetailComponent, canActivate: [AuthGuard] },
            { path: 'lweEquationProfiles', component: LWEEquationProfileListComponent, canActivate: [AuthGuard] },
            {
                path: 'weatherTypeCountProfiles/:id',
                component: WeatherTypeCountProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'weatherTypeCountProfiles',
                component: WeatherTypeCountProfileListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'weatherTypeNgToCategoryMappingProfiles/:id',
                component: WeatherTypeNgToCategoryMappingProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'weatherTypeNgToCategoryMappingProfiles',
                component: WeatherTypeNgToCategoryMappingProfileListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'instantWeatherTypeRuleProfiles/:id',
                component: InstantWeatherTypeRuleProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'instantWeatherTypeRuleProfiles',
                component: InstantWeatherTypeRuleProfileListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'weatherTypeMappingProfiles/:id',
                component: WeatherTypeMappingProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'weatherTypeMappingProfiles',
                component: WeatherTypeMappingProfileListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'lweMessageMappingProfiles/:id',
                component: LWEMessageMappingProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'lweMessageMappingProfiles',
                component: LWEMessageMappingProfileListComponent,
                canActivate: [AuthGuard]
            },
            { path: 'thiesLPMWMOCodeMappingProfiles', component: ThiesLPMWMOCodeMappingProfileListComponent, canActivate: [AuthGuard] },
            { path: 'thiesLPMWMOCodeMappingProfiles/:id', component: ThiesLPMWMOCodeMappingProfileDetailComponent, canActivate: [AuthGuard] },
            { path: 'stationMonitoringProfiles/:id', component: StationMonitoringProfileDetailComponent, canActivate: [AuthGuard] },
            { path: 'stationMonitoringProfiles', component: StationMonitoringProfileListComponent, canActivate: [AuthGuard] },
            { path: 'weatherAlertProfiles/:id', component: WeatherAlertProfileDetailComponent, canActivate: [AuthGuard] },
            { path: 'weatherAlertProfiles', component: WeatherAlertProfileListComponent, canActivate: [AuthGuard] },
            {
                path: 'engineCoverAlertProfiles/:id',
                component: EngineCoverAlertProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'engineCoverAlertProfiles',
                component: EngineCoverAlertProfileListComponent,
                canActivate: [AuthGuard]
            },
            { path: 'barrelIcingProfiles/:id', component: BarrelIcingProfileDetailComponent, canActivate: [AuthGuard] },
            { path: 'barrelIcingProfiles', component: BarrelIcingProfileListComponent, canActivate: [AuthGuard] },
            { path: 'activeFrostProfiles/:id', component: ActiveFrostProfileDetailComponent, canActivate: [AuthGuard] },
            { path: 'activeFrostProfiles', component: ActiveFrostProfileListComponent, canActivate: [AuthGuard] },
            {
                path: 'stationAlertTemplates/:id',
                component: StationAlertTemplateDetailComponent,
                canActivate: [AuthGuard]
            },
            { path: 'stationAlertTemplates', component: StationAlertTemplateListComponent, canActivate: [AuthGuard] },
            { path: 'airports', component: AirportListComponent, canActivate: [AuthGuard] },
            { path: 'airports/:id', component: AirportEditComponent, canActivate: [AuthGuard] },
            { path: 'equipmentTypes', component: EquipmentTypeListComponent, canActivate: [AuthGuard] },
            { path: 'equipmentTypes/:id', component: EquipmentTypeEditComponent, canActivate: [AuthGuard] },
            { path: 'ibmMqServerConfigurations', component: IBMMQServerConfigurationListComponent, canActivate: [AuthGuard] },
            { path: 'ibmMqServerConfigurations/:id', component: IBMMQServerConfigurationEditComponent, canActivate: [AuthGuard] },
            { path: 'mqttBrokers', component: MQTTBrokerListComponent, canActivate: [AuthGuard] },
            { path: 'mqttBrokers/:id', component: MQTTBrokerEditComponent, canActivate: [AuthGuard] },
            {
                path: 'imapServerConfigurations',
                component: IMAPServerConfigurationListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'imapServerConfigurations/:id',
                component: IMAPServerConfigurationEditComponent,
                canActivate: [AuthGuard]
            },
            { path: 'jmsServerConfigurations', component: JMSServerConfigurationListComponent, canActivate: [AuthGuard] },
            {
                path: 'jmsServerConfigurations/:id',
                component: JMSServerConfigurationEditComponent,
                canActivate: [AuthGuard]
            },
            { path: 'sqsQueueConfigurations', component: SQSQueueConfigurationListComponent, canActivate: [AuthGuard] },
            {
                path: 'sqsQueueConfigurations/:id',
                component: SQSQueueConfigurationEditComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'sftpServerConfigurations',
                component: SFTPServerConfigurationListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'sftpServerConfigurations/:id',
                component: SFTPServerConfigurationEditComponent,
                canActivate: [AuthGuard]
            },
            { path: 'hotConditions', component: HotConditionListComponent, canActivate: [AuthGuard] },
            { path: 'hotConditions/:id', component: HotConditionEditComponent, canActivate: [AuthGuard] },
            { path: 'mobileDocuments', component: MobileDocumentProfileListComponent, canActivate: [AuthGuard] },
            { path: 'mobileDocuments/:id', component: MobileDocumentProfileEditComponent, canActivate: [AuthGuard] },
            {
                path: 'holdoverTaxiTimePredictions',
                component: HoldoverTaxiTimePredictionListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'holdoverTaxiTimePredictions/:id',
                component: HoldoverTaxiTimePredictionEditComponent,
                canActivate: [AuthGuard]
            },
            { path: 'hotData', component: HotDataComponent, canActivate: [AuthGuard] },
            { path: 'hotMap', component: HotMapComponent, canActivate: [AuthGuard] },
            { path: 'stationDataGen2', component: StationDataGen2Component, canActivate: [AuthGuard] },
            { path: 'stationDataGen3', component: StationDataGen3Component, canActivate: [AuthGuard] },
            { path: 'stationDataGenV', component: StationDataGenVComponent, canActivate: [AuthGuard] },
            { path: 'weatherProfileData', component: WeatherProfileDataComponent, canActivate: [AuthGuard] },
            { path: 'metarReadings', component: MetarReadingComponent, canActivate: [AuthGuard] },
            { path: 'barrelIcing', component: BarrelIcingComponent, canActivate: [AuthGuard] },
            { path: 'activeFrost', component: ActiveFrostComponent, canActivate: [AuthGuard] },
            { path: 'noaaRapReports', component: NoaaRapReportComponent, canActivate: [AuthGuard] },
            { path: 'engineCoverAlerts', component: EngineCoverAlertComponent, canActivate: [AuthGuard] },
            { path: 'weatherAlerts', component: WeatherAlertComponent, canActivate: [AuthGuard] },
            { path: 'noaaWpcForecasts', component: NoaaWpcForecastComponent, canActivate: [AuthGuard] },
            { path: 'openMeteoForecasts', component: OpenMeteoForecastComponent, canActivate: [AuthGuard] },
            { path: 'noaaWpcAlert', component: NoaaWpcAlertComponent, canActivate: [AuthGuard] },
            { path: 'stationAlertEmails', component: StationAlertEmailComponent, canActivate: [AuthGuard] },
            { path: 'humanObservationData', component: HumanObservationDataComponent, canActivate: [AuthGuard] },
            { path: 'portalUserLogins', component: PortalUserLoginComponent, canActivate: [AuthGuard] },
            { path: 'acarsOooiReports', component: AcarsOooiReportsComponent, canActivate: [AuthGuard] },
            { path: 'flairOooiReports', component: FlairOooiReportComponent, canActivate: [AuthGuard] },
            { path: 'aidxEvents', component: AidxEventComponent, canActivate: [AuthGuard] },
            { path: 'flightAwareFirehoseMessages', component: FlightAwareFirehoseMessageComponent, canActivate: [AuthGuard] },
            { path: 'finnairInits', component: FinnairInitComponent, canActivate: [AuthGuard] },
            { path: 'sureForecastAllClears', component: SureForecastAllClearComponent, canActivate: [AuthGuard] },
            { path: 'nowcastsLwe12', component: NowcastsLwe12Component, canActivate: [AuthGuard] },
            { path: 'nowcastsLwe30', component: NowcastsLwe30Component, canActivate: [AuthGuard] },
            { path: 'deicingReports', component: DeicingReportsComponent, canActivate: [AuthGuard] },
            { path: 'deicingRequests', component: DeicingRequestComponent, canActivate: [AuthGuard] },
            { path: 'acarsIncomingMessages', component: AcarsIncomingMessageComponent, canActivate: [AuthGuard] },
            { path: 'acarsOutgoingMessages', component: AcarsOutgoingMessageComponent, canActivate: [AuthGuard] },
            { path: 'flights', component: FlightComponent, canActivate: [AuthGuard] },
            { path: 'heatFlapsAlerts', component: HeatFlapsAlertListComponent, canActivate: [AuthGuard] },
            { path: 'heatFlapsAlertProfiles/:id', component: HeatFlapsAlertProfileDetailComponent, canActivate: [AuthGuard] },
            { path: 'heatFlapsAlertProfiles', component: HeatFlapsAlertProfileListComponent, canActivate: [AuthGuard] },
            { path: 'flapContaminationAlerts', component: FlapContaminationAlertListComponent, canActivate: [AuthGuard] },
            { path: 'flapContaminationAlertProfiles/:id', component: FlapContaminationAlertProfileDetailComponent, canActivate: [AuthGuard] },
            { path: 'flapContaminationAlertProfiles', component: FlapContaminationAlertProfileListComponent, canActivate: [AuthGuard] },
            { path: 'freezingFogAlerts', component: FreezingFogAlertListComponent, canActivate: [AuthGuard] },
            {
                path: 'freezingFogAlertProfiles/:id',
                component: FreezingFogAlertProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'freezingFogAlertProfiles',
                component: FreezingFogAlertProfileListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'metarMessageMappingProfiles',
                component: MetarMessageMappingProfileListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'metarMessageMappingProfiles/:id',
                component: MetarMessageMappingProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'metarMixedPrecipitationProfiles',
                component: MetarMixedPrecipitationProfileListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'metarMixedPrecipitationProfiles/:id',
                component: MetarMixedPrecipitationProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'metarWeatherCodeMappingProfiles',
                component: MetarWeatherCodeMappingProfileListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'metarWeatherCodeMappingProfiles/:id',
                component: MetarWeatherCodeMappingProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'metarWeatherCodeRemappingProfiles',
                component: MetarWeatherCodeRemappingProfileListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'metarWeatherCodeRemappingProfiles/:id',
                component: MetarWeatherCodeRemappingProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'metarWeatherMappingProfiles',
                component: MetarWeatherMappingProfileListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'metarWeatherMappingProfiles/:id',
                component: MetarWeatherMappingProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'metarWeatherTypeMappingProfiles',
                component: MetarWeatherTypeMappingProfileListComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'metarWeatherTypeMappingProfiles/:id',
                component: MetarWeatherTypeMappingProfileDetailComponent,
                canActivate: [AuthGuard]
            },
            { path: 'metarSettings', component: MetarSettingsComponent, canActivate: [AuthGuard] },
            { path: 'voiceCallerTest', component: VoiceCallerTestComponent, canActivate: [AuthGuard] },
            { path: 'sartorius', component: SartoriusComponent, canActivate: [AuthGuard] },
            { path: 'jmsServerConfigurations', component: JMSServerConfigurationListComponent, canActivate: [AuthGuard] },
            {
                path: 'jmsServerConfigurations/:id',
                component: JMSServerConfigurationEditComponent,
                canActivate: [AuthGuard]
            },
            { path: 'fluids', component: FluidListComponent, canActivate: [AuthGuard] },
            { path: 'fluids/:id', component: FluidEditComponent, canActivate: [AuthGuard] },
            { path: 'equipmentTypes', component: EquipmentTypeListComponent, canActivate: [AuthGuard] },
            { path: 'equipmentTypes/:id', component: EquipmentTypeEditComponent, canActivate: [AuthGuard] },
            { path: 'clientApiTokens', component: ClientApiTokenListComponent, canActivate: [AuthGuard] },
            { path: 'clientApiTokens/:id', component: ClientApiTokenDetailComponent, canActivate: [AuthGuard] },
            { path: 'clients', component: ClientListComponent, canActivate: [AuthGuard] },
            { path: 'clients/:id', component: ClientEditComponent, canActivate: [AuthGuard] },
            { path: 'clientContacts/:id', component: ClientContactEditComponent, canActivate: [AuthGuard] },
            { path: 'portalUsers/:id', component: PortalUserEditComponent, canActivate: [AuthGuard] },
        ]),
        FormsModule,
        ReactiveFormsModule,
        CKEditorModule,
        GridModule,
        AgGridModule,
        SwxModule.forRoot(environment),
        AuthModule.forRoot({
            tokenUrl: `${environment.apiUrl}/oauth/access_token`,
            userUrl: `${environment.apiUrl}/user/me`,
            storageKey: 'access_token',
        }),
        ChangesModule.forRoot(environment),
        AppDetailsComponent
    ],
    providers: [
        DecimalPipe,
        HasPermissionService,
        AuthGuard,
        TicketTrackingSystemService,
        TranslatePipe,
        XmlToJsonService,
        MDMStateCellRendererService,
        ClientSearchFilterPipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        {
            provide: ExportApi,
            useClass: ApiService,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule { }
