<page-title>{{('Client'|translate) + ': ' + item.Name}}</page-title>
<form class="noMargins" #ngForm="ngForm">
    <div class="toolbar">
        <a class="tab" *ngIf="'ConfigViewClientsBasicInfo'|hasPermission" (click)="switchTab('basicInfo')" [ngClass]="{active : tab == 'basicInfo', invalid: !ngForm.form.controls.basicInfoForm?.valid }">{{'Basic info'}}</a>
        <a class="tab" *ngIf="'ConfigViewClientsAirports'|hasPermission" (click)="switchTab('airports')" [ngClass]="{active : tab == 'airports', invalid: !ngForm.form.controls.airportsForm?.valid || airport != null}">{{'Airports'}}</a>
        <a class="tab" *ngIf="'ConfigViewClientsFluids'|hasPermission" (click)="switchTab('fluids')" [ngClass]="{active : tab == 'fluids', invalid: !ngForm.form.controls.fluidsForm?.valid || fluid != null}">{{'Fluids'}}</a>
        <a class="tab" *ngIf="'ConfigViewClientsFluildsAirports'|hasPermission" (click)="switchTab('fluidAirports')" [ngClass]="{active : tab == 'fluidAirports'}">{{'Fluids/Airports'}}</a>
        <a class="tab" *ngIf="'ConfigViewClientsExceptions'|hasPermission" (click)="switchTab('exceptions')" [ngClass]="{active : tab == 'exceptions', invalid: !ngForm.form.controls.exceptionsForm?.valid || !ngForm.form.controls.fluidExceptionsForm?.valid}">{{'Exceptions'}}</a>
        <a class="tab" *ngIf="'ConfigViewClientsForecasts'|hasPermission" (click)="switchTab('forecasts')" [ngClass]="{active : tab == 'forecasts', invalid: !ngForm.form.controls.forecastsForm?.valid }">{{'Forecasts'}}</a>
        <a class="tab" *ngIf="'ConfigViewClientsAcars'|hasPermission" (click)="switchTab('acars')" [ngClass]="{active : tab == 'acars', invalid: !ngForm.form.controls.acarsForm?.valid}">{{'ACARS'}}</a>
        <a class="tab" *ngIf="'ConfigViewClientsAircraft'|hasPermission" (click)="switchTab('fleet')" [ngClass]="{active : tab == 'fleet', invalid: !ngForm.form.controls.fleetForm?.valid || equipmentType != null}">{{'Fleet'}}</a>
        <a class="tab" *ngIf="'ConfigViewClientsTwa'|hasPermission" (click)="switchTab('twa')" [ngClass]="{active : tab == 'twa', invalid: !ngForm.form.controls.twaForm?.valid}">{{'TWA'}}</a>
        <a class="tab" *ngIf="'ConfigViewClientsHoldoverTaxiTimePrediction'|hasPermission" (click)="switchTab('holdoverTaxiTimePrediction')" [ngClass]="{active : tab == 'holdoverTaxiTimePrediction', invalid: !ngForm.form.controls.holdoverTaxiTimePredictionForm?.valid}">{{'Holdover taxi time prediction'}}</a>
        <a class="tab" *ngIf="'ConfigViewClientsPortal'|hasPermission" (click)="switchTab('portal')" [ngClass]="{active : tab == 'portal', invalid: !ngForm.form.controls.portalForm?.valid}">{{'Portal'}}</a>
        <input type="submit" (click)="save()" [disabled]="!ngForm.form.valid || !ngForm.form.dirty || airport != null || fluid != null || equipmentType != null" class="green" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="'DataChanges'|hasPermission" (click)="viewHistory()" [disabled]="!item.Id || !('ConfigViewClientsHistory'|hasPermission)" value="{{'View history'|translate}}" />
        <button type="button" [disabled]="!item.Id" (click)="exportMessageMappings()"><span class="mdi mdi-download"></span> {{'Export message mappings'|translate}}</button>
        <button type="button" *ngIf="tab == 'exceptions'" [disabled]="!item.Id" (click)="exportExceptions()"><span class="mdi mdi-download"></span> {{'Export exceptions'|translate}}</button>
        <input type="button" *ngIf="tab == 'fleet'" (click)="exportFleet()" [disabled]="!item.Id" value="{{'Export fleet'|translate}}"/>
        <button *ngIf="tab == 'fleet' && 'ConfigAddClientsAircraft'|hasPermission" (click)="addAircraft()" class="button green">{{'Add aircraft'|translate}}</button>
    </div>
    
    <fieldset [disabled]="!('ConfigEditClientsBasicInfo'|hasPermission)" class="contentMargins form" [hidden]="tab != 'basicInfo' && 'ConfigViewClientsBasicInfo'|hasPermission" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Client IATA code'|translate}}</span>
            <input type="text" [required]="item.UseAcars" [(ngModel)]="item.IATACode" name="item.IATACode" pattern="^[A-Z0-9]{2,3}$" style="width: 50px;" />
        </label>
        <label>
            <span class="caption">{{'Client ICAO code'|translate}}</span>
            <input type="text" [required]="item.UseAcars" [(ngModel)]="item.ICAOCode" name="item.ICAOCode" pattern="^[A-Z0-9]{2,4}$" />
        </label>
        <label>
            <span class="caption">{{'Service Level'|translate}}</span>
            <input type="text" [(ngModel)]="item.ServiceLevel" name="item.ServiceLevel"/>
        </label>
        <label>
            <span class="caption">{{'Customer representative'|translate}}</span>
            <select [(ngModel)]="item.CustomerRepresentativeUserId" name="item.CustomerRepresentativeUserId">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let option of users|orderBy:'Name'" [ngValue]="option.Id">
                    {{option.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active"/>
        </label>
        <label>
            <span class="caption">{{'LWE message mappings'|translate}}</span>
            <select [(ngModel)]="item.LWEMessageMappingProfileId" name="item.LWEMessageMappingProfile">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let lweMessageMappingProfile of lweMessageMappingProfiles|orderBy:'Name'" [ngValue]="lweMessageMappingProfile.Id">
                    {{lweMessageMappingProfile.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'METAR message mappings'|translate}}</span>
            <select [(ngModel)]="item.MetarMessageMappingProfileId" name="item.MetarMessageMappingProfile">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let metarMessageMappingProfile of metarMessageMappingProfiles|orderBy:'Name'" [ngValue]="metarMessageMappingProfile.Id">
                    {{metarMessageMappingProfile.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'METAR weather mappings'|translate}}</span>
            <select [(ngModel)]="item.MetarWeatherMappingProfileId" name="item.MetarWeatherMappingProfile">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let metarWeatherMappingProfile of metarWeatherMappingProfiles|orderBy:'Name'" [ngValue]="metarWeatherMappingProfile.Id">
                    {{metarWeatherMappingProfile.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Show only minimum METAR HOT'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.ShowOnlyMinHot" name="item.ShowOnlyMinHot" />
        </label>
        <label>
            <span class="caption">{{'Start of season'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="item.StartOfSeasonDate" name="item.StartOfSeasonDate">
        </label>
        <label>
            <span class="caption">{{'End of season'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="item.EndOfSeasonDate" name="item.EndOfSeasonDate">
        </label>
        <label>
            <span class="caption">{{'Skip geolocation for given IPs (comma separated)'|translate}}</span>
            <input type="text" [(ngModel)]="item.GeoIpBlocked" name="item.GeoIpBlocked" style="width: 400px;" />
        </label>
        <label>
            <span class="caption">{{'Use adjusted HOT by default'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseAdjustedHotByDefault" name="item.UseAdjustedHotByDefault" />
        </label>
        <label>
            <span class="caption">{{'Use degree-specifc HOTs if available'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseMetarDegreeSpecificHotIfAvailable" name="item.UseMetarDegreeSpecificHotIfAvailable" />
        </label>
        <label>
            <span class="caption">{{'Customer configuration document url'|translate}}</span>
            <input type="url" [(ngModel)]="item.CustomerConfigurationDocumentUrl" name="item.CustomerConfigurationDocumentUrl" placeholder="http://example.com" />
            <a target="_blank" href="{{item.CustomerConfigurationDocumentUrl}}">{{item.CustomerConfigurationDocumentUrl}}</a>
        </label>
        <label>
            <span class="caption">{{'Exclude from ETL sync'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.ExcludeFromETLSync" name="item.ExcludeFromETLSync"/>
        </label>
        <label>
            <span class="caption">{{'Use client OOOI data for de-icing portal'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseOooiReportsForDeIcingPortal" name="item.UseOooiReportsForDeIcingPortal" />
        </label>
        <label>
            <span class="caption">{{'Use client OOOI data for mobile inits'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseOooiReportsForMobileInits" name="item.UseOooiReportsForMobileInits" />
        </label>
        <label>
            <span class="caption">{{'Use FlightAware for de-icing portal'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseFlightAwareForDeIcingPortal" name="item.UseFlightAwareForDeIcingPortal" />
        </label>
        <label>
            <span class="caption">{{'Use FlightAware for mobile inits'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseFlightAwareForMobileInits" name="item.UseFlightAwareForMobileInits" />
        </label>
    </fieldset>

    <fieldset [disabled]="!('ConfigEditClientsForecasts'|hasPermission)" class="contentMargins form" [hidden]="tab != 'forecasts' || !('ConfigViewClientsForecasts'|hasPermission)" ngModelGroup="forecastsForm">
        <label>
            <span class="caption">{{'Forecast required duration (minutes)'|translate}}</span>
            <input type="text" required [(ngModel)]="item.ForecastRequiredDurationMinutes" name="item.ForecastRequiredDurationMinutes" min="0" step="1" />
        </label>
        <label>
            <span class="caption">{{'Forecast message when clear'|translate}}</span>
            <input type="text" [(ngModel)]="item.ForecastMessageClear" name="item.ForecastMessageClear" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Forecast message when precip ahead'|translate}}</span>
            <input type="text" [(ngModel)]="item.ForecastMessagePrecipAhead" name="item.ForecastMessagePrecipAhead" maxlength="100" disabled />
        </label>
        <label>
            <span class="caption">{{'Forecast message when unavailable'|translate}}</span>
            <input type="text" [(ngModel)]="item.ForecastMessageUnavailable" name="item.ForecastMessageUnavailable" maxlength="100" disabled />
        </label>
    </fieldset>
    
    <div class="airports"[hidden]="tab != 'airports' || !('ConfigViewClientsAirports'|hasPermission)">
        <div class="options form contentMargins">
            <label class="inline">
                <span class="caption">{{'Search'|translate}}</span>
                <input [(ngModel)]="airportsGrid.searchQuery" name="airportsGrid.searchQuery" />
            </label>
            <label class="inline">
                <select [(ngModel)]="airport" name="airport">
                    <option *ngFor="let airport of airports|airportsNotEnabled:item.ClientAirports|orderBy:'ICAOCode'" [ngValue]="airport">
                        {{airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name}}
                    </option>
                </select>
                <input type="button" class="green" *ngIf="'ConfigAddClientsAirports'|hasPermission" (click)="addClientAirport()" value="{{'Add airport'|translate}}" [disabled]="airport == null" />
            </label>
        </div>
    </div>

    <config-grid #airportsGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'airports'"
                 ngModelGroup="airportsForm"
                 [rowSelection]="'multiple'"
                 [allRows]="airportsData"
                 [includeInactive]="true"
                 [headerHeight]="75"
                 [columnDefs]="airportsGridColumnDefs">
    </config-grid>

    <fieldset [disabled]="!('ConfigEditClientsFluids'|hasPermission)" class="contentMargins form" [hidden]="tab != 'fluids' || !('ConfigViewClientsFluids'|hasPermission)" ngModelGroup="fluidsForm" #fluidsForm="ngModelGroup">
        <label>
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="fluidsSearchQuery" name="fluidsSearchQuery"/>
        </label>

        <label>
            <span class="caption">{{'Fluid season'|translate}}</span>
            <select required [(ngModel)]="item.FluidSeasonId" name="item.FluidSeasonId">
                <option *ngFor="let fluidSeason of fluidSeasons" [ngValue]="fluidSeason.Id">
                    {{fluidSeason.Name}}
                </option>
            </select>
        </label>

        <br />
        <label>
            <input type="button" (click)="exportFluidNodes()" [disabled]="!item.Id || !('ConfigViewClientsExportMessageMappings'|hasPermission)" value="{{'Export fluid notes & cautions'|translate}}"/>
        </label>
        <br />
        <br />
        
        <label class="inline">
            <select [(ngModel)]="fluid" name="fluid">
                <option *ngFor="let fluid of availableFluids|orderBy:['FluidProfileName', 'Type', 'Name']" [ngValue]="fluid">
                    {{fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' (' + fluid.Dilution + ')'}}
                </option>
            </select>
            <input type="button" class="green" *ngIf="'ConfigAddClientsFluids'|hasPermission" (click)="addClientFluid()" value="{{'Add fluid'|translate}}" [disabled]="fluid == null"/>
        </label>
        <br/>
        <br/>
        <label *ngFor="let fluidProfile of fluidProfiles">
            <input type="button" (click)="addMissingOverrideFluids(fluidProfile.Id)" value="{{('Add missing ' + fluidProfile.Name + ' override fluids')}}"/>
        </label>
        <h3>{{'Custom fluid names'|translate}}</h3>
        <table class="table">
            <tr>
                <th>{{'Fluid'|translate}}</th>
                <th>{{'Season'|translate}}</th>
                <th>{{'Order'|translate}} <a title="Reset ordering" style="cursor: pointer" (click)="resetOrdering()">&#x21ba;</a></th>
                <th>{{'Custom name'|translate}}</th>
                <th>{{'Add to mobile fluid override'|translate}}</th>
                <th>{{'Anti-ice recommendation title'|translate}}</th>
                <th>{{'Anti-ice recommendation message {minHOT}'|translate}}</th>
                <th></th>
            </tr>
            <tr *ngFor="let clientFluid of item.ClientFluids|filter:{Name:fluidsSearchQuery}; let i = index">
                <td>{{fluidsDict[clientFluid.FluidId]?.FluidProfileName + ' - ' + fluidsDict[clientFluid.FluidId]?.Type + ' - ' + fluidsDict[clientFluid.FluidId]?.Name + ' (' + fluidsDict[clientFluid.FluidId]?.Dilution + ')'}}</td>
                <td>
                    <select [(ngModel)]="clientFluid.FluidSeasonId" [name]="'clientFluid[' + i + '].FluidSeasonId'">
                        <option [ngValue]="null">{{'Default'|translate}}</option>
                        <option *ngFor="let fluidSeason of fluidSeasons" [ngValue]="fluidSeason.Id">
                            {{fluidSeason.Name}}
                        </option>
                    </select>
                </td>
                <td><input type="number" required min="0" step="1" [(ngModel)]="clientFluid.Order" [name]="'clientFluid[' + i + '].Order'" [ngModelOptions]="{ updateOn: 'blur'}" style="width: 50px;" /></td>
                <td><input type="text" required pattern="^[0-9a-zA-Z•\t\- ':\.,\\\/@\+]+$" [(ngModel)]="clientFluid.Name" [name]="'clientFluid[' + i + '].Name'" style="width: 350px;" /></td>
                <td><input type="checkbox" [(ngModel)]="clientFluid.IsOverridable" [name]="'clientFluid[' + i + '].IsOverridable'" /></td>
                <td><input type="text" [(ngModel)]="clientFluid.AntiIceRecommendationTitle" [name]="'clientFluid[' + i + '].AntiIceRecommendationTitle'" style="width: 150px;" /></td>
                <td><input type="text" [(ngModel)]="clientFluid.AntiIceRecommendationMessage" [name]="'clientFluid[' + i + '].AntiIceRecommendationMessage'" style="width: 350px;" /></td>
                <td>
                    <a *ngIf="'ConfigDeleteClientsFluids'|hasPermission" (click)="removeClientFluid(clientFluid)" class="mdi mdi-delete"></a>
                </td>
            </tr>
        </table>
    </fieldset>

    <div class="fluidAirports" [hidden]="tab != 'fluidAirports' || !('ConfigViewClientsFluildsAirports'|hasPermission)">
        <div class="options form contentMargins">
            <label class="inline">
                <span class="caption">{{'Search'|translate}}</span>
                <input [(ngModel)]="fluidAirportsGrid.searchQuery" name="fluidAirportsGrid.searchQuery" />
            </label>
            <label class="inline">
                <input type="button" (click)="exportFluids()" [disabled]="!item.Id" value="{{'Export to Excel'|translate}}"/>
            </label>
        </div>
    </div>

    <config-grid #fluidAirportsGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'fluidAirports'"
                 ngModelGroup="fluidAirportsForm"
                 [allRows]="fluidAirportsData"
                 [includeInactive]="true"
                 [headerHeight]="75"
                 [columnDefs]="fluidAirportsColumnDefs">
    </config-grid>

    <div class="exceptionsGridContainer" [hidden]="tab != 'exceptions' || !('ConfigViewClientsExceptions'|hasPermission)">
        <config-grid class="form"
                     [parent]="this"
                     ngModelGroup="exceptionsForm"
                     [rowData]="exceptionsData"
                     [columnDefs]="exceptionsColumnDefs">
        </config-grid>
    </div>

    <div class="fluidExceptionsGridContainer" [hidden]="tab != 'exceptions' || !('ConfigViewClientsExceptions'|hasPermission)">
        <div class="toolbar">
            <h3>{{'Per fluid messages'|translate}}</h3>
        </div>
        <config-grid class="form"
                     [parent]="this"
                     ngModelGroup="fluidExceptionsForm"
                     [rowData]="fluidExceptionsDate"
                     [columnDefs]="exceptionsColumnDefs">
        </config-grid>
    </div>

    <fieldset [disabled]="!('ConfigEditClientsAcars'|hasPermission)" class="contentMargins form" [hidden]="tab != 'acars' || !('ConfigViewClientsAcars'|hasPermission)" ngModelGroup="acarsForm" #acarsForm="ngModelGroup">
        <label>
            <span class="caption">{{'Use ACARS'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseAcars" name="item.UseAcars" />
        </label>
        <div *ngIf="item.UseAcars">
            <h3>{{'ACARS parameters'|translate}}</h3>
            <label>
                <span class="caption">{{'Response priority'|translate}}</span>
                <select required [(ngModel)]="item.AcarsResponsePriority" name="item.AcarsResponsePriority">
                    <option *ngFor="let option of priorities|keys" [ngValue]="option">
                        {{priorities[option]}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Destination address in incoming messages'|translate}}</span>
                <input [(ngModel)]="item.AcarsDestinationAddress" name="item.AcarsDestinationAddress" />
            </label>
            <label>
                <span class="caption">{{'Requests from a ground system?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.IsAcarsRequestSender" name="item.IsAcarsRequestSender" />
            </label>
            <label *ngIf="item.IsAcarsRequestSender">
                <span class="caption">{{'IATA Address of ground system (Request from)'|translate}}</span>
                <input [(ngModel)]="item.AcarsAddress" name="item.AcarsAddress" />
            </label>
            <label>
                <span class="caption">{{'Responses sent directly to'|translate}}</span>
                <select [(ngModel)]="item.AcarsResponseSendTo" name="item.AcarsResponseSendTo" [selectedIndex]="-1">
                    <option *ngFor="let option of hotResponseReceivers|keys" [ngValue]="option">
                        {{hotResponseReceivers[option]}}
                    </option>
                </select>
            </label>
            <label *ngIf="item.AcarsResponseSendTo === 'AirlineSystem'">
                <span class="caption">{{'IATA Address of ground system (Reply to)'|translate}}</span>
                <input [(ngModel)]="item.AcarsResponseAddress" name="item.AcarsResponseAddress" />
            </label>
            <label>
                <span class="caption">{{'Response template'|translate}}</span>
                <select (ngModelChange)="setTimestamp(item)" [(ngModel)]="item.DefaultAcarsTemplateId" name="item.DefaultAcarsTemplateId">
                    <option *ngFor="let option of templates" [ngValue]="option.Id">
                        {{option.Description}}
                    </option>
                </select>
                <a *ngIf="item.DefaultAcarsTemplateId" (click)="previewTemplate(item.DefaultAcarsTemplateId, false)" class="mdi mdi-eye" style="padding: 5px;"></a>
            </label>
            <label>
                <span class="caption">{{'Include forecast message?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.AcarsIncludeForecastMessage" name="item.AcarsIncludeForecastMessage" />
            </label>
            <label *ngIf="currentTemplate?.HasCustomMessageLabel">
                <span class="caption">{{'Custom response message label'|translate}}</span>
                <input [(ngModel)]="item.AcarsResponseMessageLabel" name="item.AcarsResponseMessageLabel" />
            </label>
            <label *ngIf="currentTemplate?.HasTimestamp">
                <span class="caption">{{'Add Timestamp? (ACARS)'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.HasTimestampInACARSResponse" name="item.HasTimestampInACARSResponse" />
            </label>
            <label>
                <span class="caption">{{'Show tail number?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.AcarsShowTailNumber" name="item.AcarsShowTailNumber" />
            </label>
            <label>
                <span class="caption">{{'Show flight number?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.AcarsShowFlightNumber" name="item.AcarsShowFlightNumber" />
            </label>
            <label *ngIf="item.AcarsShowFlightNumber">
                <span class="caption">{{'Show flight number first?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.AcarsShowFlightNumberFirst" name="item.AcarsShowFlightNumberFirst" />
            </label>
            <label *ngIf="item.AcarsShowFlightNumber">
                <span class="caption">{{'Zero-pad flight number?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.AcarsZeroPadFlightNumber" name="item.AcarsZeroPadFlightNumber" />
            </label>
            <label>
                <span class="caption">{{'Use CRLF line separator?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.AcarsLineSeparatorCRLF" name="item.AcarsLineSeparatorCRLF" />
            </label>
            <label>
                <span class="caption">{{'Ignore requests older than (minutes)'|translate}}</span>
                <input type="number" min="0" max="59" step="1" [required]="item.UseAcars" [(ngModel)]="item.AcarsOlderThanMinutes" name="item.AcarsOlderThanMinutes" maxlength="2" style="width: 50px;" />
            </label>
            <label>
                <span class="caption">{{'Standard Message Identifier (SMI)'|translate}}</span>
                <input placeholder="CMD" [(ngModel)]="item.AcarsStandardMessageIdentifier" name="item.AcarsStandardMessageIdentifier" maxlength="3" style="text-transform: uppercase" />
            </label>
            <div class="field">
                <span class="caption">{{'Datalink Service Providers'|translate}}</span>
                <table>
                    <tr *ngFor="let clientDsp of item.ClientDatalinkServiceProviders; let i = index">
                        <td>
                            <label>
                                <input type="checkbox" [(ngModel)]="clientDsp.IsEnabled" [name]="'clientDsp[' + i + '].IsEnabled'" />
                                <span>{{datalinkServiceProviders[clientDsp.DatalinkServiceProvider]}}</span>
                            </label>
                        </td>
                        <td>
                            <label *ngIf="clientDsp.IsEnabled">
                                <input type="checkbox" [(ngModel)]="clientDsp.SendCopy" [name]="'clientDsp[' + i + '].SendCopy'" />{{'Send response copy?'|translate}}
                            </label>
                        </td>
                        <td>
                            <label *ngIf="clientDsp.SendCopy && clientDsp.IsEnabled">
                                <input [(ngModel)]="clientDsp.CopyAddress" [name]="'clientDsp[' + i + '].CopyAddress'" />{{'Address'|translate}}
                            </label>
                        </td>
                    </tr>
                </table>
            </div>

            <h3>{{'Mobile HOT ACARS copy'|translate}}</h3>
            <label>
                <span class="caption">{{'Enabled'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.MobileAcarsCopyEnabled" name="item.MobileAcarsCopyEnabled" />
            </label>
            <div *ngIf="item.MobileAcarsCopyEnabled">
                <label>
                    <span class="caption">{{'Destination address'|translate}}</span>
                    <input [required]="item.MobileAcarsCopyEnabled" [(ngModel)]="item.MobileAcarsCopyDestinationAddress" name="item.MobileAcarsCopyDestinationAddress" />
                </label>
                <label>
                    <span class="caption">{{'Template'|translate}}</span>
                    <select [(ngModel)]="item.MobileAcarsCopyAcarsTemplateId" name="item.MobileAcarsCopyAcarsTemplateId">
                        <option *ngFor="let option of templates" [ngValue]="option.Id">
                            {{option.Description}}
                        </option>
                    </select>
                    <a *ngIf="item.MobileAcarsCopyEnabled && item.MobileAcarsCopyAcarsTemplateId && item.MobileAcarsCopyDestinationAddress" (click)="previewTemplate(item.MobileAcarsCopyAcarsTemplateId, true)" class="mdi mdi-eye" style="padding: 5px;"></a>
                </label>
                <label>
                    <span class="caption">{{'IBM MQ configuration to send copy'|translate}}</span>
                    <select [(ngModel)]="item.MobileAcarsCopyIBMMQServerConfigurationId" name="item.MobileAcarsCopyIBMMQServerConfigurationId">
                        <option *ngFor="let option of ibmMqServerConfigurations|orderBy:'Name'" [ngValue]="option.Id">
                            {{option.Name}}
                        </option>
                    </select>
                </label>
            </div>
        </div>
    </fieldset>

    <fieldset [disabled]="!('ConfigEditClientsAircraft'|hasPermission)" class="contentMargins form" style="padding-bottom: 0;" [hidden]="tab != 'fleet' || !('ConfigViewClientsAircraft'|hasPermission)" ngModelGroup="fleetForm" #fleetForm="ngModelGroup">
        <label style="display: flex;">
            <span class="caption" style="min-width: 200px; margin-bottom: 10px">{{'Allow requests with unknown tail numbers/equipment types'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseDefaultEquipmentType" name="item.UseDefaultEquipmentType" style="margin-left: 6px"/>
            <span>{{'If the aircraft/equipment type cannot be determined in the ACARS or mobile request, a response will be generated assuming the default wing surface type and 24x9 screen.'|translate}}</span>
        </label>
        <div class="field">
            <span class="caption">{{'Default wing surface types'|translate}}</span>
            <multiselect-enum [enum]="wingMaterialTypes" width="300" required [(ngModel)]="item.DefaultWingMaterialTypes" name="item.DefaultWingMaterialTypes"></multiselect-enum>
        </div>
        <label class="field">
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="aircraftGrid.searchQuery" name="aircraftGrid.searchQuery" />
        </label>
    </fieldset>

    <config-grid #aircraftGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'fleet'"
                 ngModelGroup="fleetForm"
                 [allRows]="item.Airplanes"
                 [includeInactive]="true"
                 [headerHeight]="75"
                 [columnDefs]="aircraftColumnDefs">
    </config-grid>

    <fieldset [disabled]="!('ConfigEditClientsTwa'|hasPermission)" class="contentMargins form" [hidden]="tab != 'twa' || !('ConfigViewClientsTwa'|hasPermission)" ngModelGroup="twaForm">
        <label>
            <span class="caption">{{'TWA HOT start time required (for ACARS and XML requests only)'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.TWAHotStartTimeRequired" name="item.TWAHotStartTimeRequired" />
        </label>
        <label>
            <span class="caption">{{'Use METAR if TWA is unavailable'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseMetarIfTWAUnavailable" name="item.UseMetarIfTWAUnavailable" />
        </label>
        <label>
            <span class="caption">{{'TWA max % of missing weather profile values since start of deicing time'|translate}}</span>
            <input type="number" [(ngModel)]="item.TWAAcceptableMissingHotsPercent" name="item.TWAAcceptableMissingHotsPercent" min="0" max="100" step="any" />
        </label>
        <label>
            <span class="caption">{{'TWA max % of continuous missing weather profile values'|translate}}</span>
            <input type="number" [(ngModel)]="item.TWAAcceptableConsecutiveMissingHotsPercent" name="item.TWAAcceptableConsecutiveMissingHotsPercent" min="0" max="100" step="any" />
        </label>
        <label>
            <span class="caption">{{'HOT equivalent for clear weather'|translate}}</span>
            <input type="number" [(ngModel)]="item.TWAHOTForClear" name="item.TWAHOTForClear" min="0" step="1" />
            <span class="caption">{{'(in minutes, leave empty for infinite)'|translate}}</span>
        </label>
        <br />
        <table class="table">
            <tr>
                <th>{{'Condition'|translate}}</th>
                <th>{{'Allow TWA for actual condition'|translate}}</th>
                <th>{{'Continue on intermediate condition'|translate}}</th>
            </tr>
            <tr>
                <td>{{'OAT below fluid LOUT'|translate}}</td>
                <td><input type="checkbox" [(ngModel)]="item.AllowTWAHotWhenOnOATBelowFluidLOUT" name="item.AllowTWAHotWhenOnOATBelowFluidLOUT" /></td>
                <td><input type="checkbox" [(ngModel)]="item.TWAContinueOnIntermediateOATBelowFluidLOUT" name="item.TWAContinueOnIntermediateOATBelowFluidLOUT" /></td>
            </tr>
            <tr>
                <td>{{'Snow LWE too high'|translate}}</td>
                <td><input type="checkbox" [(ngModel)]="item.AllowTWAHotWhenSnowLWETooHigh" name="item.AllowTWAHotWhenSnowLWETooHigh" /></td>
                <td><input type="checkbox" [(ngModel)]="item.TWAContinueOnIntermediateSnowLWETooHigh" name="item.TWAContinueOnIntermediateSnowLWETooHigh" /></td>
            </tr>
            <tr>
                <td>{{'SLD/FZDZ/FZRA LWE too high'|translate}}</td>
                <td><input type="checkbox" [(ngModel)]="item.AllowTWAHotWhenSLDLWETooHigh" name="item.AllowTWAHotWhenSLDLWETooHigh" /></td>
                <td><input type="checkbox" [(ngModel)]="item.TWAContinueOnIntermediateSLDLWETooHigh" name="item.TWAContinueOnIntermediateSLDLWETooHigh" /></td>
            </tr>
            <tr>
                <td>{{'FZFG LWE too high'|translate}}</td>
                <td><input type="checkbox" [(ngModel)]="item.AllowTWAHotWhenFZFGLWETooHigh" name="item.AllowTWAHotWhenFZFGLWETooHigh" /></td>
                <td><input type="checkbox" [(ngModel)]="item.TWAContinueOnIntermediateFZFGLWETooHigh" name="item.TWAContinueOnIntermediateFZFGLWETooHigh" /></td>
            </tr>
            <tr>
                <td>{{'Snow temperature too low and wind speed too high'|translate}}</td>
                <td><input type="checkbox" [(ngModel)]="item.AllowTWAHotWhenSnowTemperatureTooLowAndWindSpeedTooHigh" name="item.AllowTWAHotWhenSnowTemperatureTooLowAndWindSpeedTooHigh" /></td>
                <td><input type="checkbox" [(ngModel)]="item.TWAContinueOnIntermediateSnowTemperatureTooLowAndWindSpeedTooHigh" name="item.TWAContinueOnIntermediateSnowTemperatureTooLowAndWindSpeedTooHigh" /></td>
            </tr>
        </table>
    </fieldset>

    <fieldset [disabled]="!('ConfigEditClientsHoldoverTaxiTimePrediction'|hasPermission)" class="contentMargins form" [hidden]="tab != 'holdoverTaxiTimePrediction' || !('ConfigViewClientsHoldoverTaxiTimePrediction'|hasPermission)" ngModelGroup="holdoverTaxiTimePredictionForm">
        <label>
            <span class="caption">{{'Use holdover taxi time prediction'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseHoldoverTaxiTimePrediction" name="item.UseHoldoverTaxiTimePrediction" />
        </label>
        <div *ngIf="item.UseHoldoverTaxiTimePrediction">
            <label>
                <span class="caption">{{'ACARS message template with optional {lower} and/or {upper}'|translate}}</span>
                <input type="text" style="width: 600px;" [(ngModel)]="item.HoldoverTaxiTimePredictionMessage" name="item.HoldoverTaxiTimePredictionMessage" />
            </label>
            <label>
                <span class="caption">{{'Mobile message template with optional {lower} and/or {upper}'|translate}}</span>
                <input type="text" style="width: 600px;" [(ngModel)]="item.HoldoverTaxiTimePredictionMobileMessage" name="item.HoldoverTaxiTimePredictionMobileMessage" />
            </label>
            <label>
                <span class="caption">{{'Error message'|translate}}</span>
                <input type="text" style="width: 600px;" [required]="item.HoldoverTaxiTimePredictionMessage" [(ngModel)]="item.HoldoverTaxiTimePredictionErrorMessage" name="item.HoldoverTaxiTimePredictionErrorMessage" />
            </label>
            <label>
                <span class="caption">{{'Mobile error message'|translate}}</span>
                <input type="text" style="width: 600px;" [required]="item.HoldoverTaxiTimePredictionMobileMessage" [(ngModel)]="item.HoldoverTaxiTimePredictionMobileErrorMessage" name="item.HoldoverTaxiTimePredictionMobileErrorMessage" />
            </label>
        </div>
    </fieldset>

    <fieldset [disabled]="!('ConfigEditClientsPortal'|hasPermission)" class="contentMargins form" [hidden]="tab != 'portal' || !('ConfigViewClientsPortal'|hasPermission)" ngModelGroup="portalForm">
        <label>
            <span class="caption">{{'Use client portal'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseClientPortal" name="item.UseClientPortal" />
        </label>
        <ng-container *ngIf="item.UseClientPortal">
            <label>
                <span class="caption">{{'Allow autoregistration'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.AutoregistrationAllowed" name="item.AutoregistrationAllowed" />
            </label>
            <label>
                <span class="caption">{{'De-icing portal auto-refresh interval (seconds, default 60)'|translate}}</span>
                <input type="number" [(ngModel)]="item.DeIcingPortalRefreshSeconds" name="item.DeIcingPortalRefreshSeconds" min="10" step="1" />
            </label>
            <label>
                <span class="caption">{{'HOT graph duration (minutes, default 120)'|translate}}</span>
                <input type="number" [(ngModel)]="item.PortalHotGraphDuration" name="item.PortalHotGraphDuration" min="1" step="1" />
            </label>
            <label>
                <span class="caption">{{'HOT graph averaging period (minutes, default 30)'|translate}}</span>
                <input type="number" [(ngModel)]="item.PortalHotGraphAveragingPeriod" name="item.PortalHotGraphAveragingPeriod" min="10" step="1" />
            </label>
            <label>
                <span class="caption">{{'HOT request history duration (minutes, default 120)'|translate}}</span>
                <input type="number" [(ngModel)]="item.PortalHotRequestHistoryDuration" name="item.PortalHotRequestHistoryDuration" min="1" step="1" />
            </label>
            <label>
                <span class="caption">{{'Dispatch weather menu'|translate}}</span>
                <select [(ngModel)]="item.PortalMobileWeatherMappingProfileId" name="item.PortalMobileWeatherMappingProfileId" required>
                    <option *ngFor="let option of mobileWeatherMappingProfiles|orderBy:'Name'" [ngValue]="option.Id">
                        {{option.Name}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Dispatch throughput graph averaging period (minutes, default 60)'|translate}}</span>
                <input type="number" [(ngModel)]="item.DispatchThroughputGraphAveragingPeriod" name="item.DispatchThroughputGraphAveragingPeriod" min="10" step="1" />
            </label>
            <label>
                <span class="caption">{{'Dispatch throughput graph duration (minutes, default 960)'|translate}}</span>
                <input type="number" [(ngModel)]="item.DispatchThroughputGraphDuration" name="item.DispatchThroughputGraphDuration" min="1" step="1" />
            </label>
            <label>
                <span class="caption">{{'Nowcast Confidence Acceptance Threshold'|translate}}</span>
                <input type="number" [(ngModel)]="item.NowcastConfidenceAcceptanceThreshold" name="item.NowcastConfidenceAcceptanceThreshold" min=".3" step=".1" max=".9" />
            </label>
            <label>
                <span class="caption">{{'Include forecast message on dispatch portal?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.DispatchIncludeForecastMessage" name="item.DispatchIncludeForecastMessage" />
            </label>
            <label>
                <span class="caption">{{'Allow password reset'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.PortalPasswordResetAllowed" name="item.PortalPasswordResetAllowed" />
            </label>
            <label>
                <span class="caption">{{'Allow user access to settings'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.PortalPasswordChangeAllowed" name="item.PortalPasswordChangeAllowed" />
            </label>
            <label>
                <span class="caption">{{'Off season'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.PortalOffSeason" name="item.PortalOffSeason" />
            </label>
            <label>
                <span class="caption">{{'Display fluid concentration?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.ShowFluidConcentration" name="item.ShowFluidConcentration" />
            </label>
            <label>
                <span class="caption">{{'Header document'|translate}}</span>
                <file-upload [(ngModel)]="item.ClientPortalDocumentId" name="item.ClientPortalDocumentId"></file-upload>
            </label>
            <label *ngIf="item.ClientPortalDocumentId != null">
                <span class="caption">{{'Header document name'|translate}}</span>
                <input type="text" [required]="item.ClientPortalDocumentId != null" [(ngModel)]="item.ClientPortalDocumentName" name="item.ClientPortalDocumentName" />
            </label>
            <label>
                <span class="caption">{{'Client portal code'|translate}}</span>
                <input type="text" [required]="item.UseClientPortal" [(ngModel)]="item.ClientPortalCode" name="item.ClientPortalCode" maxlength="4" style="width: 50px;" />
            </label>
            <div class="field">
                <span class="caption">{{'Client portal logo'|translate}}<a *ngIf="item.ClientPortalLogo != null" (click)="removeLogo();" class="mdi mdi-delete" title="{{'Delete'|translate}}"></a></span>

                <file-as-data-uri accept="image/*" [(ngModel)]="item.ClientPortalLogo" name="item.ClientPortalLogo" (ngModelChange)="logoChanged();"></file-as-data-uri>
                <br />
                <br />
                <img *ngIf="item.ClientPortalLogo != null" [src]="item.ClientPortalLogo" style="max-width: 200px;" />
            </div>

            <label>
                <span class="caption">{{'For a single airport, hide the left pane in Dispatch'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.HideTilesIfSingleAirport" name="item.HideTilesIfSingleAirport" />
            </label>
            <label>
                <span class="caption">{{'Display METAR reference HOT if available'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.ShowMetarRefHot" name="item.ShowMetarRefHot" />
            </label>
            <label>
                <span class="caption">{{'Show Desk Selection'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.ShowShiftSelection" name="item.ShowShiftSelection" />
            </label>

            <br />
            <h3>{{'SureANALYTIX'|translate}}</h3>
            <label>
                <span class="caption">{{'Tableau trusted authentication URL'|translate}}</span>
                <input type="text" [(ngModel)]="item.PortalTableauTrustedAuthenticationTokenUrl" name="item.PortalTableauTrustedAuthenticationTokenUrl" style="width: 500px;" />
            </label>
            <label>
                <span class="caption">{{'Tableau trusted authentication site name (for multiple sites)'|translate}}</span>
                <input type="text" [(ngModel)]="item.PortalTableauTrustedAuthenticationTrustedSite" name="item.PortalTableauTrustedAuthenticationTrustedSite" style="width: 200px;" />
            </label>
            <br />
            <label>
                <button type="button" *ngIf="('ConfigAddClientsPortal'|hasPermission)" (click)="addPortalTableauDashboard()">{{'Add dashboard'|translate}}</button>
            </label>
            <table class="table">
                <tr>
                    <th>{{'Name'|translate}}</th>
                    <th>{{'Dashboard Type'|translate}}</th>
                    <th>{{'URL'|translate}}</th>
                    <th>{{'Order'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let clientPortalTableauDashboard of item.ClientPortalTableauDashboards; let i = index">
                    <td><input type="text" required [(ngModel)]="clientPortalTableauDashboard.Name" [name]="'clientPortalTableauDashboard[' + i + '].Name'" style="width: 200px;" /></td>
                    <td>
                        <select required [(ngModel)]="clientPortalTableauDashboard.DashboardGroup" [name]="'clientPortalTableauDashboard[' + i + '].DashboardGroup'">
                            <option *ngFor="let option of dashboardGroups|keys" [ngValue]="option">
                                {{dashboardGroups[option]}}
                            </option>
                        </select>
                    </td>
                    <td><input type="text" required [(ngModel)]="clientPortalTableauDashboard.Url" [name]="'clientPortalTableauDashboard[' + i + '].Url'" style="width: 700px;" /></td>
                    <td><input type="number" step="1" required [(ngModel)]="clientPortalTableauDashboard.Order" [name]="'clientPortalTableauDashboard[' + i + '].Order'" style="width: 100px;" /></td>
                    <td><a *ngIf="('ConfigDeleteClientsPortal'|hasPermission)" (click)="removePortalTableauDashboard(clientPortalTableauDashboard)" class="mdi mdi-delete"></a></td>
                </tr>
            </table>

            <br />
            <h3>{{'LWE grid'|translate}}</h3>
            <label>
                <span class="caption">{{'Enable LWE grid'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.PortalEnableLWEGrid" name="item.PortalEnableLWEGrid" />
            </label>
            <ng-container *ngIf="item.PortalEnableLWEGrid">
                <h3>{{'Stations'|translate}}</h3>
                <label>
                    <select [(ngModel)]="station" name="station">
                        <option *ngFor="let option of notEnabled(stations, item.ClientPortalLWEGridStations, 'StationId')|orderBy:'Name'" [ngValue]="option">
                            {{option.Name}}
                        </option>
                    </select>
                    <input type="button" class="green" *ngIf="('ConfigAddClientsPortal'|hasPermission)" (click)="addClientPortalLWEGridStation()" value="{{'Add station'|translate}}" [disabled]="station == null" />
                </label>
                <table class="table">
                    <tr>
                        <th>{{'Station'|translate}}</th>
                        <th>{{'Sensor select matrices'|translate}}</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let row of item.ClientPortalLWEGridStations; let i = index">
                        <td>{{(stations|findById:row.StationId).Name}}</td>
                        <td>
                            <table class="table">
                                <tr>
                                    <th>{{'Name'|translate}}</th>
                                    <th></th>
                                </tr>
                                <tr *ngFor="let subrow of row.ClientPortalLWEGridSensorSelectMatrices">
                                    <td>{{(sensorSelectMatrices|findById:subrow.SensorSelectMatrixId).Name}}</td>
                                    <td><a *ngIf="('ConfigDeleteClientsPortal'|hasPermission)" (click)="removeClientPortalLWEGridSensorSelectMatrix(row, subrow)" class="mdi mdi-delete"></a></td>
                                </tr>
                            </table>
                            <label>
                                <select [(ngModel)]="sensorSelectMatrix[i]" [name]="'sensorSelectMatrix[' + i + ']'">
                                    <option *ngFor="let option of notEnabled(sensorSelectMatrices, row.ClientPortalLWEGridSensorSelectMatrices, 'SensorSelectMatrixId')|filter:{StationType:(stations|findById:row.StationId).StationType}|orderBy:'Name'" [ngValue]="option">
                                        {{option.Name}}
                                    </option>
                                </select>
                                <input type="button" class="green" *ngIf="('ConfigAddClientsPortal'|hasPermission)" (click)="addClientPortalLWEGridSensorSelectMatrix(row, sensorSelectMatrix[i]); sensorSelectMatrix[i] = null;" value="{{'Add sensor select matrix'|translate}}" [disabled]="sensorSelectMatrix[i] == null" />
                            </label>
                        </td>
                        <td><a *ngIf="('ConfigDeleteClientsPortal'|hasPermission)" (click)="removeClientPortalLWEGridStation(row)" class="mdi mdi-delete"></a></td>
                    </tr>
                </table>
            </ng-container>

            <br />
            <h3>{{'SAML 2.0 authentication'|translate}}</h3>
            <label>
                <span class="caption">{{'SAML 2.0 service provider support'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.PortalUseSaml2Authentication" name="item.PortalUseSaml2Authentication" />
            </label>
            <label>
                <span class="caption">{{'SAML 2.0 identity provider support'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.PortalSaml2IdentityProvider" name="item.PortalSaml2IdentityProvider" />
            </label>
            <label *ngIf="item.PortalUseSaml2Authentication">
                <input type="button" (click)="downloadServiceProviderMetadata(false)" value="{{'Download service provider metadata'|translate}}" />
            </label>
            <label *ngIf="item.PortalUseSaml2Authentication">
                <input type="button" (click)="downloadServiceProviderMetadata(true)" value="{{'Download service provider metadata (signed)'|translate}}" />
            </label>
            <label *ngIf="item.PortalSaml2IdentityProvider">
                <input type="button" (click)="downloadIdentityProviderMetadata(false)" value="{{'Download identity provider metadata'|translate}}" />
            </label>
            <label *ngIf="item.PortalSaml2IdentityProvider">
                <input type="button" (click)="downloadIdentityProviderMetadata(true)" value="{{'Download identity provider metadata (signed)'|translate}}" />
            </label>
            <label *ngIf="item.PortalUseSaml2Authentication">
                <span class="caption">{{'Create users if not already existing'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.PortalSaml2CreateUserIfDoesntExists" name="item.PortalSaml2CreateUserIfDoesntExists" />
            </label>
            <label *ngIf="item.PortalUseSaml2Authentication">
                <span class="caption">{{'Enable single logout'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.PortalSaml2EnableSingleLogout" name="item.PortalSaml2EnableSingleLogout" />
            </label>
            <label *ngIf="item.PortalUseSaml2Authentication || item.PortalSaml2IdentityProvider">
                <span class="caption">{{'Sign requests'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.PortalSaml2SignRequests" name="item.PortalSaml2SignRequests" />
            </label>
            <label *ngIf="item.PortalUseSaml2Authentication">
                <span class="caption">{{'Identity provider metadata'|translate}}</span>
                <textarea rows="30" cols="75" [required]="item.PortalUseSaml2Authentication" [(ngModel)]="item.PortalSaml2IdentityProviderMetadata" name="item.PortalSaml2IdentityProviderMetadata"></textarea>
            </label>
            <label *ngIf="item.PortalUseSaml2Authentication">
                <span class="caption">{{'Load identity provider metadata from file'|translate}}</span>
                <input type="file" file-as-data [(ngModel)]="item.PortalSaml2IdentityProviderMetadata" name="item.PortalSaml2IdentityProviderMetadata" />
            </label>
            <label *ngIf="item.PortalSaml2IdentityProvider">
                <span class="caption">{{'Service provider metadata'|translate}}</span>
                <textarea rows="30" cols="75" [required]="item.PortalSaml2IdentityProvider" [(ngModel)]="item.PortalSaml2ServiceProviderMetadata" name="item.PortalSaml2ServiceProviderMetadata"></textarea>
            </label>
            <label *ngIf="item.PortalSaml2IdentityProvider">
                <span class="caption">{{'Load service provider metadata from file'|translate}}</span>
                <input type="file" file-as-data [(ngModel)]="item.PortalSaml2ServiceProviderMetadata" name="item.PortalSaml2ServiceProviderMetadata" />
            </label>
            <label *ngIf="item.PortalUseSaml2Authentication || item.PortalSaml2IdentityProvider">
                <span class="caption">{{'Authentication error message'|translate}}</span>
                <textarea rows="10" cols="75" [(ngModel)]="item.PortalSaml2AuthenticationErrorMessage" name="item.PortalSaml2AuthenticationErrorMessage"></textarea>
            </label>
            <ng-container *ngIf="item.PortalUseSaml2Authentication">
                <h3>{{'Permission mappings'|translate}}</h3>
                <label class="inline">
                    <button type="button" *ngIf="('ConfigAddClientsPortal'|hasPermission)" (click)="addPortalSaml2AttributeMapping()">{{'Add permission mapping'|translate}}</button>
                </label>
                <table class="table">
                    <tr>
                        <th>{{'Attribute name'|translate}}</th>
                        <th>{{'Value'|translate}}</th>
                        <th>{{'Permission'|translate}}</th>
                        <th></th>
                    </tr>
                    <tr *ngFor="let clientPortalSaml2AttributeMapping of item.ClientPortalSaml2AttributeMappings; let i = index">
                        <td><input type="text" required [(ngModel)]="clientPortalSaml2AttributeMapping.Attribute" [name]="'clientPortalSaml2AttributeMapping[' + i + '].Attribute'" style="width: 300px;" /></td>
                        <td><input type="text" required [(ngModel)]="clientPortalSaml2AttributeMapping.Value" [name]="'clientPortalSaml2AttributeMapping[' + i + '].Value'" style="width: 100px;" /></td>
                        <td>
                            <select required [(ngModel)]="clientPortalSaml2AttributeMapping.Permission" [name]="'clientPortalSaml2AttributeMapping[' + i + '].Permission'">
                                <option *ngFor="let option of portalUserPermissions|keys" [ngValue]="option">
                                    {{portalUserPermissions[option]}}
                                </option>
                            </select>
                        </td>
                        <td><a *ngIf="('ConfigDeleteClientsPortal'|hasPermission)" (click)="removePortalSaml2AttributeMapping(clientPortalSaml2AttributeMapping)" class="mdi mdi-delete"></a></td>
                    </tr>
                </table>
            </ng-container>
            <br />
            <h3>{{'Domain white-list'|translate}}</h3>
            <label class="inline">
                <button type="button" *ngIf="('ConfigAddClientsPortal'|hasPermission)" (click)="addDomain()">{{'Add domain'|translate}}</button>
            </label>
            <table class="table">
                <tr>
                    <th>{{'Domain'|translate}}</th>
                    <th>{{'Hidden?'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let domain of item.ClientDomains; let i = index">
                    <td><input type="text" pattern="^[0-9a-zA-Z\.\-]+" required [(ngModel)]="domain.Domain" [name]="'domain[' + i + '].Domain'" style="width: 200px;" /></td>
                    <td><input type="checkbox" [(ngModel)]="domain.Hidden" [name]="'domain[' + i + '].Hidden'" /></td>
                    <td><a *ngIf="('ConfigDeleteClientsPortal'|hasPermission)" (click)="removeDomain(domain)" class="mdi mdi-delete"></a></td>
                </tr>
            </table>
        </ng-container>
    </fieldset>
</form>
