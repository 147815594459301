import {Component, Input} from "@angular/core";
import {RootScope} from "../shared/RootScope.service";
import {ApiService} from "../shared/Api.service";

@Component({
    templateUrl: 'WeatherOverrideDeicingReportsDialog.component.html'
})
export class WeatherOverrideDeicingReportsDialogComponent {
    dateTimeFormat = this.root.dateTimeFormat
    metarWeatherTypes = this.api.MetarWeatherType.query();

    constructor(
        private root: RootScope,
        private api: ApiService,
    ) {
    }
    
    @Input() deicingReports;
}
