import { Component } from "@angular/core";
import { ApiService } from "../shared/Api.service";

@Component({
    templateUrl: 'XmlRequest.component.html'
})
export class XmlRequestComponent {
    query = { message: ''};
    result: any;

    constructor(private api: ApiService) {
    }

    send() {
        this.api.XmlRequest.post(this.query).then(result => {
            this.result = result;
        });
    }
}

