import {ICellRendererAngularComp} from "ag-grid-angular";
import {Component, ViewContainerRef} from "@angular/core";
import {ICellRendererParams} from "ag-grid-community";
import {DialogService} from "swx.front-end-lib";
import {ReportDeicingHotErrorsDialogComponent} from "../reportDeicing/ReportDeicingHotErrorsDialog.component";



@Component({
    template: `
        <div><a *ngIf="hasErrors()" (click)="showErrors()" class='mdi mdi-information'></a> {{message}}</div>
    `
})
export class HotResponseMessageCellRendererComponent implements ICellRendererAngularComp {
    message: string;
    data: any;
    
    constructor(private dialog: DialogService,
                private viewContainerRef: ViewContainerRef) {
    }
    
    agInit(params: ICellRendererParams): void {
        this.data = params.data;
        this.message = params.data?.HotResponse?.Message;    
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    
    hasErrors() {
        return (this.data?.HotResponse && this.data.HotResponse.Error)
                || (this.data?.HotResponseHot && this.data.HotResponseHot.Errors)
                || (this.data?.Hots && this.data.Hots.find(h => h.HotResponseHot.Errors))
                || (this.data?.WeatherProfile && this.data.WeatherProfile.Errors)
                || (this.data?.AcarsRequestErrors);
    }
    
    showErrors() {
        this.dialog.show(this.viewContainerRef, ReportDeicingHotErrorsDialogComponent, {
            hot: this.data,
        },
        {
            title: 'Errors',
            width: 400,
            height: 'auto',
            modal: true,
        })
    }
}
