import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { RootScope } from "../shared/RootScope.service";

@Component({
    templateUrl: 'MetarFinalWeatherType.component.html'
})
export class MetarFinalWeatherTypeComponent implements OnInit {
    datePickerOptions: any;
    query: any;
    items: any;
    weatherTypes: any;
    dateTimeFormat: any;

    constructor(private api: ApiService, private root: RootScope) {
    }

    ngOnInit() {
        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
            dateFormat: 'yy-mm-dd',
            useUtc: true
        };

        this.dateTimeFormat = this.root.dateTimeFormat;

        this.query = {};
    }

    getFinalTypes() {
        this.api.MetarFinalType.post(this.query).then(result => {
            this.items = result.Metars;
            this.weatherTypes = result.WeatherTypes;
        });
    }

    getTypeDetails(metarId) {
        return this.weatherTypes.filter(val => val.WeatherType.MetarReadingId === metarId);
    }
}
