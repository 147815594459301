import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { RootScope } from "../shared/RootScope.service";

@Component({
    selector: 'report-deicing-summary',
    templateUrl: 'ReportDeicingSummary.component.html'
})
export class ReportDeicingSummaryComponent implements AfterViewInit {
    @Input() query: any;
    deicingSummary: any;
    dateTimeFormat = this.root.dateTimeFormat;

    constructor(private api: ApiService, private root: RootScope) {
    }

    ngAfterViewInit() {
        this.api.DeicingHotSummary.query(this.query).$promise.then(deicingSummary => {
            this.deicingSummary = deicingSummary;
        })
    }
}
