import {Component} from "@angular/core";

@Component({
    selector: 'swx-noaa-wpc-alert',
    templateUrl: './NoaaWpcAlert.component.html'
})
export class NoaaWpcAlertComponent {
    query: any = {};
    
    onQueryChange(query) {
        this.query = {...query};
    }
} 
