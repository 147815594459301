import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FiltersComponent, IAgGridColGroupDef, SortableComponent, ValueFormatters} from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";
import {ControlContainer, NgModelGroup} from "@angular/forms";
import {Field, FieldGroup} from "swx.front-end-lib/lib/grid/Query.model";

@Component({
    selector: 'station-monitoring-condition-list',
    templateUrl: 'StationMonitoringConditionList.component.html',
    styleUrls: ['./StationMonitoringConditionList.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }]
})
export class StationMonitoringConditionListComponent implements OnInit {
    @Input() conditions;
    @Input() parent;
    @Input() parentList: StationMonitoringConditionListComponent;
    @Input() ngForm;
    @ViewChild('sortable', { static: true }) sortable: SortableComponent;
    availableFields: Map<string, Field>;
    fieldGroups: FieldGroup[];
    sensorSelectMatrices = this.api.SensorSelectMatrix.query();
    metarWeatherMappingProfiles = this.api.MetarWeatherMappingProfile.query();
    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: "Previous alert",
            children: [
                { field: "PreviousMonitoringAlertAgeMinutes", headerName: "Age (minutes)", filterType: "float" },
            ]
        },
        {
            headerName: 'Station alarm',
            children: [
                { field: "Station.Name", headerName: "Station", },
                { field: "Station.UnderMaintenance", headerName: "Under maintenance?", filterType: "boolean" },
                { field: "StationProblemType", headerName: "Alarms", filterType: "enum", "source": "StationProblemType" },
                { field: "HasNoticeAlarm", headerName: "Has notice alarm?", filterType: "boolean" },
                { field: "HasWarningAlarm", headerName: "Has warning alarm?", filterType: "boolean" },
                { field: "HasCriticalAlarm", headerName: "Has critical alarm?", filterType: "boolean" },
                { field: "Airport.ICAOCode", headerName: "Airport ICAO code", },
                { field: "Airport.City", headerName: "Airport city", },
                { field: "Airport.Country", headerName: "Airport country", },
                { field: "Airport.Region", headerName: "Airport region", },
                { field: "Airport.Latitude", headerName: "Airport latitude", filterType: "float" },
                { field: "Airport.Longitudfe", headerName: "Airport longitude", filterType: "float" },
            ]
        },
        {
            headerName: 'Metar reading',
            children: [
                { field: "MetarReadingAgeMinutes", headerName: "Age (minutes)", filterType: "float" },
                { field: "MetarReading.WxString", headerName: "Weather string", },
                { field: "MetarReading.TempC", headerName: "Temperature (°C)", filterType: "float" },
                { field: "MetarReading.IsClearSky", headerName: "Clear sky?", filterType: "integer" },
                { field: "MetarReading.IsActiveFrost", headerName: "Active frost?", filterType: "integer" },
                { field: "MetarReading.VisibilityStatuteMi", headerName: "Visibility (SM)", filterType: "float" },
            ],
        },
        {
            headerName: 'Power relay module',
            children: [
                { field: "PowerRelayModule.Port3_1", headerName: "P3.1", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_2", headerName: "P3.2", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_3", headerName: "P3.3", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_4", headerName: "P3.4", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_5", headerName: "P3.5", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_6", headerName: "P3.6", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_7", headerName: "P3.7", filterType: "boolean" },
                { field: "PowerRelayModule.Port3_8", headerName: "P3.8", filterType: "boolean" },

                { field: "PowerRelayModule.Port5_1", headerName: "P5.1 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_2", headerName: "P5.2 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_3", headerName: "P5.3 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_4", headerName: "P5.4 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_5", headerName: "P5.5 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_6", headerName: "P5.6 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_7", headerName: "P5.7 on", filterType: "boolean" },
                { field: "PowerRelayModule.Port5_8", headerName: "P5.8 on", filterType: "boolean" },

                { field: "PowerRelayModule.Port6_1", headerName: "ADC.1 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_2", headerName: "ADC.2 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_3", headerName: "ADC.3 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_4", headerName: "ADC.4 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_5", headerName: "ADC.5 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_6", headerName: "ADC.6 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_7", headerName: "ADC.7 (V)", filterType: "float" },
                { field: "PowerRelayModule.Port6_8", headerName: "ADC.8 (V)", filterType: "float" },

                { field: "PowerRelayModule.ACOK", headerName: "AC OK", filterType: "boolean" },
                { field: "PowerRelayModule.DCOK", headerName: "DC OK", filterType: "boolean" },
                { field: "PowerRelayModule.BattLow", headerName: "Batt Low", filterType: "boolean" },
            ]
        },
        {
            headerName: 'Forecast next hour',
            children: [
                { field: "ForecastNextHour.TempC", headerName: "Temperature (°C)", filterType: "float", },
                { field: "ForecastNextHour.Precipitation1Hour", headerName: "Precipitation (mm)", filterType: "float", },
                { field: "ForecastNextHour.PrecipitationProbability", headerName: "Precipitation probability", filterType: "float", },
                { field: "ForecastNextHour.WMOCode", headerName: "WMO", filterType: "integer", },
                { field: "ForecastNextHour.VisibilityM", headerName: "Visibility (m)", filterType: "float", },
                { field: "ForecastNextHour.WindSpeedMs", headerName: "Wind speed (m/s)", filterType: "float", },
            ],
        },
        {
            headerName: 'Forecast next 6 hours',
            children: [
                { field: "ForecastNext6Hours.TempC", headerName: "Min. temperature (°C)", filterType: "float", },
                { field: "ForecastNext6Hours.Precipitation1Hour", headerName: "Total precipitation (mm)", filterType: "float", },
                { field: "ForecastNext6Hours.PrecipitationProbability", headerName: "Max. precipitation probability", filterType: "float", },
            ],
        },
        {
            headerName: 'Forecast next 24 hours',
            children: [
                { field: "ForecastNext24Hours.TempC", headerName: "Min. temperature (°C)", filterType: "float", },
                { field: "ForecastNext24Hours.Precipitation1Hour", headerName: "Total precipitation (mm)", filterType: "float", },
                { field: "ForecastNext24Hours.PrecipitationProbability", headerName: "Max. precipitation probability", filterType: "float", },
            ],
        },
    ];
    
    constructor(
        private formatters: ValueFormatters,
        private api: ApiService,
    ) {
    }

    ngOnInit() {
        Promise.all([this.sensorSelectMatrices.$promise, this.metarWeatherMappingProfiles.$promise]).then(() => {
            let columnDefs = this.columnDefs
                .concat(this.sensorSelectMatrices.map(ssm => ({
                    headerName: `Weather profile (${ssm.Name})`,
                    children: [
                        { field: `WeatherProfiles[${ssm.Id}].LWE`, headerName: "LWE (g/dm²/h)", filterType: "float", },
                        { field: `WeatherProfiles[${ssm.Id}].Temperature`, headerName: "Temperature (°C)", filterType: "float", },
                        { field: `WeatherProfiles[${ssm.Id}].WeatherType`, headerName: "Weather type", filterType: "enum", "source": "ClientWeatherType" },
                        { field: `WeatherProfiles[${ssm.Id}].Errors`, headerName: "Errors", filterType: "enum", "source": "WeatherProfileError" },
                        { field: `WeatherProfiles[${ssm.Id}].SensorSelectMatrixWeatherTypeSensorMappingOrder`, headerName: "Weather type sensor mapping order", filterType: "integer" },
                        { field: `WeatherProfiles[${ssm.Id}].SensorSelectMatrixIntensitySensorMappingOrder`, headerName: "Intensity sensor mapping order", filterType: "integer" },
                    ]
                })))
                .concat(this.sensorSelectMatrices.map(ssm => ({
                    headerName: `Weather profile at other stations at airport (${ssm.Name})`,
                    children: [
                        { field: `OtherStationWeatherProfiles[${ssm.Id}].LWE`, headerName: "LWE (g/dm²/h)", filterType: "float", },
                        { field: `OtherStationWeatherProfiles[${ssm.Id}].Temperature`, headerName: "Temperature (°C)", filterType: "float", },
                        { field: `OtherStationWeatherProfiles[${ssm.Id}].WeatherType`, headerName: "Weather type", filterType: "enum", "source": "ClientWeatherType" },
                        { field: `OtherStationWeatherProfiles[${ssm.Id}].Errors`, headerName: "Errors", filterType: "enum", "source": "WeatherProfileError" },
                    ]
                })))
                .concat(this.metarWeatherMappingProfiles.map(p => ({
                    headerName: `METAR final weather type (${p.Name})`,
                    children: [
                        { field: `MetarFinalWeatherTypes[${p.Id}]`, headerName: "METAR weather type", filterType: "enum", "source": "MetarWeatherType" },
                    ]
                })));
            this.availableFields = FiltersComponent.mapColumnDefs(this.api, columnDefs);
            this.fieldGroups = FiltersComponent.getFieldGroups(columnDefs);
        });
    }
    
    addCondition(parent, type: string) {
        var maxOrder = Math.max.apply(null, this.conditions.filter(c => c.ParentConditionId == this.parent?.Id)
            .map(h => h.Order));
        this.conditions.push({
            Id: StationMonitoringConditionListComponent.uuidv4(),
            ParentConditionId: this.parent?.Id,
            Order: Math.max(1, maxOrder + 1),
            ConditionType: type,
            MinimumDurationMinutes: 1,
        });
        this.ngForm.form.markAsDirty();
    }

    //https://stackoverflow.com/a/2117523/530630
    static uuidv4() {
        return `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, c =>
            (parseInt(c) ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> parseInt(c) / 4).toString(16)
        );
    }
    
    childConditions() {
        return this.conditions?.filter(c => c.ParentConditionId == this.parent?.Id) ?? [];
    }

    removeStationMonitoringCondition(parent, condition) {
        this.conditions.splice(this.conditions.indexOf(condition), 1);
        this.ngForm.form.markAsDirty();
    }
}
