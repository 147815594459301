import { Injectable } from '@angular/core';

declare var jQuery: any;

@Injectable({ providedIn: 'root'})
export class NotificationService {
    show(message: string|JQuery|HTMLElement, options?: any): any {
        options = options || {};
        options.type = options.type || 'success';
        options.delay = options.delay || 4000;
        options.sticky = options.sticky === undefined ? false : options.sticky;
        options.fadeSpeed = options.fadeSpeed === undefined ? 100 : options.fadeSpeed;
        options.slideSpeed = options.slideSpeed === undefined ? 100 : options.slideSpeed;
        return jQuery.jnotify(message, options);
    }
}
