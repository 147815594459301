import { Component } from '@angular/core';
import { BusyIndicatorService } from '../BusyIndicator.service';

@Component({
    selector: 'busy-indicator',
    templateUrl: './busyIndicator.component.html'
})
export class BusyIndicatorComponent {
    constructor(private busyIndicatorService: BusyIndicatorService) { }

    get isBusy(): boolean {
        return this.busyIndicatorService.busy;
    }
}
