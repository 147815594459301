<page-title>{{('Flap contamination alert profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" id="flapContaminationAlertProfileDetail">
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('conditions')" [class.active]="tab == 'conditions'" [class.invalid]="!ngForm.form.controls.conditionsForm?.valid">{{'Conditions'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditFlapContaminationAlertProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
        <label>
            <span class="caption">{{'Client'|translate}}</span>
            <select [(ngModel)]="item.ClientId" name="item.ClientId">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let client of clients|orderBy:'Name'" [ngValue]="client.Id">
                    {{client.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'IBM MQ configuration to send ACARS'|translate}}</span>
            <select [(ngModel)]="item.IBMMQServerConfigurationId" name="item.IBMMQServerConfigurationId">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let ibmMqServerConfiguration of ibmMqServerConfigurations|orderBy:'Name'" [ngValue]="ibmMqServerConfiguration.Id">
                    {{ibmMqServerConfiguration.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Minimum duration between alerts (minutes)'|translate}}</span>
            <input type="number" required [(ngModel)]="item.MinimumDurationBetweenAlertsMinutes" name="item.MinimumDurationBetweenAlertsMinutes" step="1" min="0" />
        </label>
        <label>
            <span class="caption">{{'Send inbound alert before estimated touchdown (minutes)'|translate}}</span>
            <input type="number" [(ngModel)]="item.MinutesBeforeEstimatedTochdownTime" name="item.MinutesBeforeEstimatedTochdownTime" required step="1" min="0" />
        </label>
        <label>
            <span class="caption">{{'INIT timeout (minutes)'|translate}}</span>
            <input type="number" [(ngModel)]="item.InitTimeoutMinutes" name="item.InitTimeoutMinutes" step="1" min="0" style="width: 70px;" />
        </label>
        <label>
            <span class="caption">{{'Inbound exiting duration (minutes)'|translate}}</span>
            <input type="number" [(ngModel)]="item.InboundExitingDurationMinutes" name="item.InboundExitingDurationMinutes" required step="1" min="0" />
        </label>
        <label>
            <span class="caption">{{'Inbound message'|translate}}</span>
            <textarea required [(ngModel)]="item.InboundAcarsContent" name="item.InboundAcarsContent" cols="24" rows="10" style="font-family: monospace; white-space: pre; overflow: auto;"></textarea>
        </label>
        <label>
            <span class="caption">{{'Outbound exiting duration (minutes)'|translate}}</span>
            <input type="number" [(ngModel)]="item.OutboundExitingDurationMinutes" name="item.OutboundExitingDurationMinutes" required step="1" min="0" />
        </label>
        <label>
            <span class="caption">{{'Outbound message'|translate}}</span>
            <textarea required [(ngModel)]="item.OutboundAcarsContent" name="item.OutboundAcarsContent" cols="24" rows="10" style="font-family: monospace; white-space: pre; overflow: auto;"></textarea>
        </label>
    </fieldset>

    <fieldset class="form conditions contentMargins" [disabled]="!('ConfigEditFlapContaminationAlertProfiles'|hasPermission)" [hidden]="tab != 'conditions'" ngModelGroup="conditionsForm">
        <select required [(ngModel)]="item.EnteringConditionType" [name]="'item.EnteringConditionType'" style="margin: 0 0 12px 3px; display: block;">
            <option [ngValue]="'OrGroup'">{{'Any of'|translate}}</option>
            <option [ngValue]="'AndGroup'">{{'All of'|translate}}</option>
        </select>
        <flap-contamination-alert-condition-list [ngForm]="ngForm" [conditions]="item.FlapContaminationAlertProfileConditions" [parent]="null"></flap-contamination-alert-condition-list>
    </fieldset>
</form>
