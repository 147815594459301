import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { FilterPipe } from 'swx.front-end-lib';
import { HasPermissionService } from '../shared/HasPermission.pipe';

@Component({
    templateUrl: 'MobileAppReleaseDetail.component.html'
})
export class MobileAppReleaseDetailComponent {
    item;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    mobileApps = this.api.MobileApp.query();

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.MobileAppRelease.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
            });
        } else if (isNew) {
            this.item = this.api.MobileAppRelease.create({
                Active: true,
            });
        } else {
            this.item = this.api.MobileAppRelease.get({ id: id });
        }

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }
    
    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);

    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['MobileAppRelease'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };
}
