<div *ngIf="group">
    <h2>{{group.Name}}</h2>
    <div>
        <span>{{group.IsInternalGroup ? 'Internal' : 'External'}}</span><br>
        <span>Created: {{group.CreatedDate|date:'mediumDate'}}</span><br>
    </div>
    <div *ngIf="group.PublicLinkEnabled">
        <span>{{group.PublicLink}}</span>
    </div>
    
    <swx-tester-list [testers]="group.Testers"></swx-tester-list>
</div>
