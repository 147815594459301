import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {DialogService, IAgGridColGroupDef, StorageService, ValueFormatters} from "swx.front-end-lib";
import {RawAcarsDialogComponent} from "../acarsOooiReports/AcarsOooiReports.component";

@Component({
    templateUrl: './FlairOooiReport.component.html'
})
export class FlairOooiReportComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'flairOooiReportsQuery';
    query: any = {};
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };

    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.FlairOooiReport;
    exportResource = this.api.FlairOooiReportExport;
    
    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private storage: StorageService,
                private dialog: DialogService,
                private formatters: ValueFormatters) {
    }
    
    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery) {
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        }
    }
    
    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }


    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: 'Flair message',
            children: [

                { colId: "FlairOooiReport.Date", field: "FlairOooiReport.TimeStamp", headerName: "Date",  width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left' },
                { colId: "FlairOooiReport.Time", field: "FlairOooiReport.TimeStamp", headerName: "Time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left' },
            ]
        },
        {
            headerName: 'OOOI',
            children: [

                { colId: "FlairOooiReport.Id", field: "FlairOooiReport.Id", headerName: "#",  width: 70 },
                { colId: "FlairOooiReport.EventCode", field: "FlairOooiReport.EventCode", headerName: "Type",  width: 70, filterType: "enum", "source": "AcarsOooiEventCode" },
            ]
        },
        {
            headerName: 'Flair message',
            children: [

                { colId: "FlairOooiReport.RawData", field: "FlairOooiReport.RawData", headerName: "Raw data",  width: 70, cellTemplate: `<a (click)='parent.showRawData(params.data.FlairOooiReport.RawData)'>{{'Email'|translate}}</a>` },
                { colId: "ClientName", field: "ClientName", headerName: "Client",  width: 120 },
                { colId: "AirportCode", field: "AirportCode", headerName: "Airport",  width: 80 },
            ]
        },
        {
            headerName: 'OOOI',
            children: [

                { colId: "FlairOooiReport.TailNumber", field: "FlairOooiReport.TailNumber", headerName: "Airplane",  width: 80 },
            ]
        },
        {
            headerName: 'Flair message',
            children: [

                { colId: "FlairOooiReport.EquipmentTypeIdentifier", field: "FlairOooiReport.EquipmentTypeIdentifier", headerName: "Equipment type",  width: 80 },
            ]
        },
        {
            headerName: 'OOOI',
            children: [

                { colId: "FlightIdentifier", field: "FlightIdentifier", headerName: "Flight number",  width: 100 },
                { colId: "OriginAirportCode", field: "OriginAirportCode", headerName: "Origin",  width: 100 },
                { colId: "DestinationAirportCode", field: "DestinationAirportCode", headerName: "Destination",  width: 100 },
                { colId: "FlairOooiReport.FuelOfBoard", field: "FlairOooiReport.FuelOfBoard", headerName: "Fuel on board",  width: 100 },
                { colId: "FlairOooiReport.FuelRemaining", field: "FlairOooiReport.FuelRemaining", headerName: "Fuel remaining",  width: 100 },
                { colId: "FlairOooiReport.Pax", field: "FlairOooiReport.Pax", headerName: "Passengers",  width: 100 },
                { colId: "FlairOooiReport.Dly1", field: "FlairOooiReport.Dly1", headerName: "DL1",  width: 100 },
                { colId: "FlairOooiReport.Dly2", field: "FlairOooiReport.Dly2", headerName: "DL2",  width: 100 },
            ]
        },
        {
            headerName: 'Departure date',
            children: [

                { colId: "FlairOooiReport.DepartureDate", field: "FlairOooiReport.DepartureDate", headerName: "Date",  width: 90, valueFormatter: this.formatters.utcDateFormatter() },
            ]
        },
        {
            headerName: 'Out date',
            children: [

                { colId: "FlairOooiReport.OutEventDate", field: "FlairOooiReport.OutEventTime", headerName: "Date",  width: 90, valueFormatter: this.formatters.utcDateFormatter() },
            ]
        },
        {
            headerName: 'Out time',
            children: [

                { colId: "FlairOooiReport.OutEventTime", field: "FlairOooiReport.OutEventTime", headerName: "Time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true },
            ]
        },
        {
            headerName: 'Off date',
            children: [

                { colId: "FlairOooiReport.OffEventDate", field: "FlairOooiReport.OffEventTime", headerName: "Date",  width: 90, valueFormatter: this.formatters.utcDateFormatter() },
            ]
        },
        {
            headerName: 'Off time',
            children: [

                { colId: "FlairOooiReport.OffEventTime", field: "FlairOooiReport.OffEventTime", headerName: "Time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true },
            ]
        },
        {
            headerName: 'ETA date',
            children: [

                { colId: "FlairOooiReport.EstTimeOfArrivalDate", field: "FlairOooiReport.EstTimeOfArrival", headerName: "Date",  width: 90, valueFormatter: this.formatters.utcDateFormatter() },
            ]
        },
        {
            headerName: 'ETA time',
            children: [

                { colId: "FlairOooiReport.EstTimeOfArrivalTime", field: "FlairOooiReport.EstTimeOfArrival", headerName: "Time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true },
            ]
        },
        {
            headerName: 'UTC date',
            children: [

                { colId: "FlairOooiReport.UtcDate", field: "FlairOooiReport.UtcTime", headerName: "Date",  width: 90, valueFormatter: this.formatters.utcDateFormatter() },
            ]
        },
        {
            headerName: 'UTC time',
            children: [

                { colId: "FlairOooiReport.UtcTime", field: "FlairOooiReport.UtcTime", headerName: "Time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true },
            ]
        },
        {
            headerName: 'Revised out date',
            children: [

                { colId: "FlairOooiReport.RevisedOutDate", field: "FlairOooiReport.RevisedOutTime", headerName: "Date",  width: 90, valueFormatter: this.formatters.utcDateFormatter() },
            ]
        },
        {
            headerName: 'Revised out time',
            children: [

                { colId: "FlairOooiReport.RevisedOutTime", field: "FlairOooiReport.RevisedOutTime", headerName: "Time",  width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true }
            ]
        },
    ];
    
    showRawData(data) {
        this.dialog.show(this.viewContainerRef, RawAcarsDialogComponent, {
            data: data,
        }, {
            title: 'Email',
            width: 360,
            height: 540,
            modal: true,
        })
    }
} 
