import {
    Component,
    ElementRef,
    forwardRef,
    Input,
    NgZone,
    OnChanges,
    OnInit,
    AfterViewInit,
    Output, EventEmitter
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/sortable.js';

@Component({
    selector: 'sortable',
    template: `<ng-content></ng-content>`,
    styleUrls: ['./Sortable.component.scss']
})
export class SortableComponent implements AfterViewInit, OnChanges {
    @Input() options: JQueryUI.SortableOptions;
    @Output() receive: EventEmitter<SortableItemComponent> = new EventEmitter<SortableItemComponent>();
    
    element: JQuery;
    
    constructor(
        private elementRef: ElementRef,
        private ngZone: NgZone
    ) {
    }

    ngAfterViewInit(): void {
        this.element = jQuery(this.elementRef.nativeElement);
        
        var self = this;
        const baseOptions: JQueryUI.SortableOptions = {
            items: '> sortable-item',
            tolerance: 'pointer',
            receive: (e, ui) => {
                ui.item.sortable['received'] = true;
                var receivedSortableItem = window['ng'].getComponent<SortableItemComponent>(ui.item[0]);
                this.element
                    .find(this.element.sortable('option', 'items'))
                    .each((index, el) => {
                        var valueAccessor = el['valueAccessor'] || ui.item['valueAccessor'];
                        valueAccessor.setOrder(index);
                    });
                setTimeout(() => {
                    self.receive.emit(receivedSortableItem);
                });
            },
            update: (e, ui) => {
                if (ui.item.sortable['received']) {
                    ui.item.sortable['received'] = false;
                } else {
                    this.ngZone.run(() => this.element
                        .find(this.element.sortable('option', 'items'))
                        .each((index, el) => {
                            el['valueAccessor'].setOrder(index);
                        }));
                }
            }
        };

        const options: JQueryUI.SortableOptions = <JQueryUI.SortableOptions>(Object.assign(baseOptions, this.options || {}));

        this.element.sortable(options);
    }

    ngOnChanges(changes) {
        if (this.element == null) return;

        Object.keys(this.options).forEach(key => {
            this.element.sortable('option', key, this.options[key]);
        });
    }
}

@Component({
    selector: 'sortable-item',
    template: `<ng-content></ng-content>`,
    styleUrls: ['./SortableItem.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SortableItemComponent),
            multi: true
        }
    ]
})
export class SortableItemComponent implements ControlValueAccessor, OnInit {
    order: number;
    onchange: (_: any) => void = () => { };
    ontouched: (_: any) => void = () => { };
    element: JQuery;
    @Input() item;

    constructor(
        private elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {
        this.elementRef.nativeElement['valueAccessor'] = this;
    }

    setOrder(order) {
        this.order = order;
        this.onchange(order);
        this.ontouched(order);
    }

    writeValue(value): void {
        if (value) {
            if (typeof value === "string") {
                this.order = parseInt(value);
            } else {
                this.order = value;
            }
        } else {
            this.order = null;
        }
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void {
        this.ontouched = fn;
    }
}
