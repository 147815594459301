import {Component, ViewChild, ViewContainerRef} from "@angular/core";
import {
    ConfirmService,
    GridComponent, IAgGridColumnDef,
    IResourceItem,
    IResourceItemList,
    TranslateService,
    ValueFormatters,
} from "swx.front-end-lib";
import {Router} from "@angular/router";
import {RootScope} from "../shared/RootScope.service";
import {ApiService} from "../shared/Api.service";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";
import {HasPermissionService} from "../shared/HasPermission.pipe";

@Component({
    templateUrl: './HotConditionList.component.html'
})
export class HotConditionListComponent {
    items: IResourceItemList<any>;
    searchQuery;
    query: any;
    includeInactive = false;
    @ViewChild('grid', { static: true }) grid: GridComponent;
    columnDefs: IAgGridColumnDef[] = [
        { "colId": "Id", "field": "Id", "headerName": "#", "width": 60, "pinned": "left", "filterType": "integer" },
        { colId: 'Name', field: 'Name', headerName: 'Name', width: 200 },
        
        { "colId": "Active", "field": "Active", "headerName": "Active?", "width": 70, "valueFormatter": c => c.value == null ? '' : c.value ? 'Yes' : 'No' },
        {colId: 'DefaultColor', field: 'DefaultColor', headerName: 'Default color', width: 150, cellStyle: (params) => ({backgroundColor: '#' + params.value})  },
        {colId: 'CreatedDate', field: 'CreatedDate', headerName: 'Created date', width: 150, valueFormatter: this.formatters.utcDateTimeFormatter()},
        {colId: 'CreatedByUserName', field: 'CreatedByUserName', headerName: 'Created by', width: 100},
        {colId: 'LastModified', field: 'LastModified', headerName: 'Modified date', width: 150, valueFormatter: this.formatters.utcDateTimeFormatter()},
        {colId: 'LastModifiedByUserName', field: 'LastModifiedByUserName', headerName: 'Modified by', width: 100},
        { "colId": "Actions", "field": "Id", "headerName": "", "width": 80, "cellClass": "actions", "cellRenderer": c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/hotConditions/' + c.data.Id + '" class="mdi mdi-pencil" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();"></a>'
                    + (this.hasPermission.hasPermission('ConfigEditHotConditions') ? '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>' : '')
                    + (this.hasPermission.hasPermission('ConfigEditHotConditions') ? '<a href="/hotConditions/new?copy=' + c.data.Id + '" class="mdi mdi-content-copy" title="' + this.translateService.translate('Copy') + '" onclick="event.preventDefault();"></a>' : '');
                Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href'))));
                Array.from(container.querySelectorAll('.mdi-delete')).forEach(n => n.addEventListener('click', () => this.delete(c.data)));
                return container;
            }, "pinned": "right", "searchable": false, "excelIgnore": true },
    ];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private $root: RootScope,
        public api: ApiService,
        private translateService: TranslateService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        private confirmService: ConfirmService,
        private hasPermission: HasPermissionService,
        private formatters: ValueFormatters,
    ) {
    }

    ngOnInit() {
        this.refresh();
    }

    refresh() {
        this.grid.refresh();
    }

    delete(item: IResourceItem) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.ticketTrackingSystem.trackAndDelete(this.viewContainerRef, item, () => this.refresh());
        }).catch(() => {});
    }
}
