import { Component, forwardRef, Input, Host, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'select-menu-option',
    template: `<label [class.selected]="isOptionEnabled()" (click)="toggleOption($event)"><ng-content></ng-content></label>`
})
export class SelectMenuOptionComponent {
    _value: any;

    constructor(
        public el: ElementRef,
        @Host() public selectMenu: SelectMenuComponent
    ) {
        if (this.selectMenu) {
            this.selectMenu._registerOption(this);
        }
    }

    @Input('value')
    set value(value: any) {
        this._value = value;
        if (this.selectMenu) {
            this.selectMenu.writeValue(this.selectMenu.value);
        }
    }

    isOptionEnabled() {
        return this.selectMenu.value === this._value;
    };

    toggleOption(e) {
        this.selectMenu.value = this._value;
        e.target.checked = true;
        this.selectMenu.setValue(this.selectMenu.value);
        this.selectMenu.optionsVisible = false;
    };
}

@Component({
    selector: 'select-menu',
    exportAs: 'select-menu',
    template: `
<div class="display" unselectable="on" onselectstart="return false;" (click)="displayValueClick();" [style.width]="width + 'px'" [class.placeholder]="value == null">{{displayValue}}</div><div (click)="optionsVisible = !optionsVisible;" class="button mdi mdi-chevron-down"></div>
<div class="overlay" *ngIf="optionsVisible" (click)="optionsVisible = false;"></div>
<div class="options" [style.width]="width + 'px'" *ngIf="optionsVisible">
    <ng-content></ng-content>
</div>
`,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectMenuComponent),
            multi: true
        }
    ]
})
export class SelectMenuComponent implements ControlValueAccessor {
    @Input('width') width = 272;
    @Input('placeholder') placeholder;
    onchange: (_: any) => void = () => { };
    ontouched: (_: any) => void = () => { };
    optionsVisible = false;
    value: any = null;
    displayValue = '';
    selectOptions = new Array<SelectMenuOptionComponent>();

    writeValue(value): void {
        this.value = value;
        this.displayValue = this.placeholder;
        this.selectOptions.forEach(option => {
            if (this.value === option._value) {
                this.displayValue = option.el.nativeElement.innerText.trim();
            }
        });
    }

    setValue(value: any) {
        this.writeValue(value);
        this.onchange(this.value);
        this.ontouched(this.value);
    }

    displayValueClick() {
        this.optionsVisible = true;
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void {
        this.ontouched = fn;
    }

    _registerOption(option: SelectMenuOptionComponent) {
        this.selectOptions.push(option);
    }
}