<div id="userAuthenticationsPage" class="noMargins">
    <page-title>{{'HOT request map'|translate}}</page-title>
    <form #form="ngForm">
        <div class="toolbar gridFilters">
            <div class="field inline">
                <span class="caption">{{'Client'|translate}}</span>
                <multiselect [(ngModel)]="query.ClientId" name="query.ClientId">
                    <multiselect-option *ngFor="let client of clients|orderBy:'Name'" [value]="client.Id" [name]="client.Name"></multiselect-option>
                </multiselect>
            </div>
            <div class="field inline">
                <span class="caption">{{'Request type'|translate}}</span>
                <multiselect [(ngModel)]="query.HotRequestType" [all]="false" name="query.HotRequestType">
                    <multiselect-option *ngFor="let kv of hotRequestTypes|keyvalue" [value]="kv.key" [name]="kv.value"></multiselect-option>
                </multiselect>
            </div>
            <label class="inline">
                <span class="caption">{{'From'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" required name="query.FromDate">
            </label>
            <label class="inline">
                <span class="caption">{{'To'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
            </label>
            <filters [(ngModel)]="query.Filters" name="query.Filters" [columnDefs]="columnDefs"></filters>
            <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
            <button type="button" (click)="apply()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        </div>
    </form>

    <div class="userMap" #hotMap></div>
</div>
