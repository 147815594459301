import {Component} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {IResourceItem} from "swx.front-end-lib";

export interface GridRowActionsRendererParams {
    baseUrl: string;
    canView: boolean;
    canEdit: boolean;
    canDelete: boolean;
    canCopy: boolean;
    deleteCallback: (item: IResourceItem) => void;
}

@Component({
    templateUrl: './GridRowActionsRenderer.component.html'
})
export class GridRowActionsRendererComponent implements ICellRendererAngularComp {
    canView: boolean = false;
    canCopy: boolean = false;
    canEdit: boolean = false;
    canDelete: boolean = false;
    item: IResourceItem;
    id: number = 0;
    baseUrl: string = '';
    onDelete: (number) => number;
    
    constructor() {
    }
    
    agInit(params: ICellRendererParams): void {
        this.item = params.data;
        this.id = params.value;
        this.baseUrl = params.colDef.cellRendererParams.baseUrl;
        this.canView = params.colDef.cellRendererParams.canView;
        this.canCopy = params.colDef.cellRendererParams.canCopy;
        this.canEdit = params.colDef.cellRendererParams.canEdit;
        this.canDelete = params.colDef.cellRendererParams.canDelete;
        this.onDelete = params.colDef.cellRendererParams.deleteCallback;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    
    deleteClicked(): void {
        this.onDelete(this.item);
    }
}

