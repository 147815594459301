<div class="reportDeicing noMargins" *ngIf="query">
    <page-title>{{'METAR weather types report'|translate}}</page-title>
    <div class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Airport'|translate}}</span>
            <select required [(ngModel)]="query.AirportId">
                <option *ngFor="let airport of airports|orderBy:'[\'-HasStation\', \'ICAOCode\']'" [ngValue]="airport.Id">
                    {{airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name}}
                </option>
            </select>
        </label>
        <label class="inline">
            <span class="caption">{{'METAR client weather configuration'|translate}}</span>
            <select required [(ngModel)]="query.MetarWeatherMappingProfileId">
                <option *ngFor="let metarWeatherMappingProfile of metarWeatherMappingProfiles|orderBy:'Name'" [ngValue]="metarWeatherMappingProfile.Id">
                    {{metarWeatherMappingProfile.Name}}
                </option>
            </select>
        </label>
        <div class="field inline">
            <span class="caption">{{'Weather types'|translate}}</span>
            <multiselect [(ngModel)]="query.MetarWeatherType" [all]="false" [format]="'string'">
                <label><a (click)="selectDeicingConditions()">{{'Select deicing conditions'|translate}}</a></label>
                <multiselect-option *ngFor="let metarWeatherType of metarWeatherTypes|keys" [value]="metarWeatherType" [name]="metarWeatherTypes[metarWeatherType]"></multiselect-option>
            </multiselect>
        </div>
        <div class="field inline">
            <span class="caption">{{'Months'|translate}}</span>
            <multiselect [(ngModel)]="query.Month">
                <multiselect-option *ngFor="let month of months|keys" [value]="month" [name]="month"></multiselect-option>
            </multiselect>
        </div>
        <div class="field inline">
            <span class="caption">{{'Hours'|translate}}</span>
            <multiselect [(ngModel)]="query.Hour">
                <multiselect-option *ngFor="let hour of hours; let i = index" [value]="hour">
                    {{hour|number:'2.0'}}:00
                </multiselect-option>
            </multiselect>
        </div>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date options="datePickerOptions" [(ngModel)]="query.FromDate">
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date options="datePickerOptions" [(ngModel)]="query.ToDate">
        </label>
        <input type="button" (click)="refresh()" value="{{'Apply'|translate}}"/>
    </div>
    <div class="summary" *ngIf="metarWeatherTypeSummary">
        <table class="table">
            <tr>
                <th></th>
                <th *ngFor="let item of metarWeatherTypeSummary" colspan="2">{{seasonLabel(item.Season)}}</th>
            </tr>
            <tr>
                <th>{{'Weather type'|translate}}</th>
                <ng-container *ngFor="let item of metarWeatherTypeSummary">
                    <th>{{'Hours'|translate}}</th>
                    <th>%</th>
                </ng-container>
            </tr>
            <tr>
                <th>{{'Selected'}}</th>
                <ng-container *ngFor="let item of metarWeatherTypeSummary">
                    <td>{{toHours(item.TotalSelected)}}</td>
                    <td>{{toPercent(item.TotalSelected, item.Total)}}%</td>
                </ng-container>
            </tr>
            <tr>
                <th>{{'Non-selected'}}</th>
                <ng-container *ngFor="let item of metarWeatherTypeSummary">
                    <td>{{toHours(item.TotalNonSelected)}}</td>
                    <td>{{toPercent(item.TotalNonSelected, item.Total)}}%</td>
                </ng-container>
            </tr>
            <tr>
                <th>{{'Total'}}</th>
                <ng-container *ngFor="let item of metarWeatherTypeSummary">
                    <td>{{toHours(item.Total)}}</td>
                    <td>100%</td>
                </ng-container>
            </tr>
            <tr *ngFor="let weatherType of metarWeatherTypeSummary[0].WeatherTypes|keys">
                <th>{{metarWeatherTypeSummary[0].WeatherTypes[weatherType]}}</th>
                <ng-container *ngFor="let item of metarWeatherTypeSummary">
                    <td>{{toHours(item.WeatherTypes[weatherType])}}</td>
                    <td>{{toPercent(item.WeatherTypes[weatherType], item.TotalSelected)}}%</td>
                </ng-container>
            </tr>
        </table>
    </div>
</div>
