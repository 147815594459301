<div class="contentMargins">
    <h1>App Details Work</h1>
    <div *ngIf="app">
        {{app|json}}
    </div>
    <!--<div *ngIf="app">
        <page-title>{{app.attributes.name}}</page-title>
        <h1>{{app.attributes.name}}</h1>
        <div>
            ID: {{app.id}}<br>
            Bundle ID: {{app.attributes.bundleId}}<br>
            SKU: {{app.attributes.sku}}<br>
            Locale: {{app.attributes.primaryLocale}}<br>
        </div>
    </div>
    <div *ngIf="builds.length > 0">
        <h2>Builds</h2>
        <table class="table">
            <tr>
                <th>Version</th>
                <th>Uploaded Date</th>
                <th>Expiration Date</th>
                <th>Expired</th>
                <th>Min iOS Version</th>
            </tr>
            <tr *ngFor="let build of builds">
                <td><a [routerLink]="['/appstore/v1/builds', build.id]">{{build.attributes.version}}</a></td>
                <td>{{build.attributes.uploadedDate|date:'mediumDate'}}</td>
                <td>{{build.attributes.expirationDate|date:'mediumDate'}}</td>
                <td>{{build.attributes.expired}}</td>
                <td>{{build.attributes.minOsVersion}}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="betaGroups.length > 0">
        <h2>TestFlight Groups</h2>
        <table class="table">
            <tr>
                <th>Name</th>
                <th>Created Date</th>
                <th>Internal</th>
                <th>Public Link</th>
                <th></th>
            </tr>
            <tr *ngFor="let group of betaGroups">
                <td><a [routerLink]="[ '/appstore/v1', 'betaGroups', group.id]">{{group.attributes.name}}</a></td>
                <td>{{group.attributes.createdDate|date:'mediumDate'}}</td>
                <td>{{group.attributes.isInternalGroup}}</td>
                <td>{{group.attributes.publicLink}}</td>
                <td><a class="mdi mdi-delete" (click)="deleteBetaGroup(group.id)"></a></td>
            </tr>
        </table>
        <a [routerLink]="['/appstore/v1', 'betaGroups', 'new']" [queryParams]="{appId: app.id}">Add TestFlight Group</a>
    </div>-->
</div>
