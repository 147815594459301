import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'equipmentTypeNotEnabled'
})
export class EquipmentTypeNotEnabledPipe implements PipeTransform {
    
    transform(equipmentTypes: any, clientBarrelIcingEquipmentTypes): any {
        let notEnabledEquipmentTypes = [];
        equipmentTypes.forEach(equipmentType => {
            if (this.equipmentTypeNotEnabled(equipmentType, clientBarrelIcingEquipmentTypes)) {
                notEnabledEquipmentTypes.push(equipmentType);
            }
        });
        return notEnabledEquipmentTypes;
    }

    private equipmentTypeNotEnabled(equipmentType, clientBarrelIcingEquipmentTypes) {
        let notEnabled = true;
        clientBarrelIcingEquipmentTypes.forEach(clientBarrelIcingEquipmentType => {
            if (clientBarrelIcingEquipmentType.EquipmentTypeId === equipmentType.Id) {
                notEnabled = false;
            }
        });
        return notEnabled;
    }
}
