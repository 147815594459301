import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import {ChangesService, IAgGridColGroupDef} from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import {ConfigGridComponent, FileDownloadService, NotificationService, TranslateService} from "swx.front-end-lib";
import {MetarWeatherTypeService} from "../hotSimulator/MetarWeatherType.service";

@Component({
    templateUrl: 'MetarWeatherTypeMappingProfileDetail.component.html'
})
export class MetarWeatherTypeMappingProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    @ViewChild('mappingsGrid', { static: true }) mappingsGrid: ConfigGridComponent;
    tab;
    metarWeatherTypes = this.api.MetarWeatherType.query();
    metarMixedPrecipitationProfiles = this.api.MetarMixedPrecipitationProfile.query();
    equipmentTypes = this.api.EquipmentType.query();
    hotConditions = this.api.HotCondition.query();
    duplicateWeatherTypeMappings = new Map();
    areWeatherTypeMappingsValid = true;
    columnDefs: IAgGridColGroupDef[] = [{
        children: [
            { colId: "Id", field: "Id", headerName: "#", width: 60, pinned: "left", filterType: 'integer', cellClass: "select", cellTemplate: "<div [class.invalid]=\"!params.data._valid\">{{params.data.Id || 'new'}}</div>" },
            { colId: "MetarWeatherType1", field: "MetarWeatherType1", headerName: "Weather type 1", width: 110, cellTemplate: `
                <select style="width: 190px;" [(ngModel)]="params.data.MetarWeatherType1" [name]="'params.data[' + params.node.id + '].MetarWeatherType1'" required (change)="parent.checkDuplicateWeatherTypeMappings()">
                    <option *ngFor="let option of parent.metarWeatherTypes|keys" [ngValue]="option">
                        {{parent.metarWeatherTypes[option]}}
                    </option>
                </select>` },
            { colId: "MetarWeatherType2", field: "MetarWeatherType2", headerName: "Weather type 2", width: 110, cellTemplate: `
                <select style="width: 190px;" [(ngModel)]="params.data.MetarWeatherType2" [name]="'params.data[' + params.node.id + '].MetarWeatherType2'" required (change)="parent.checkDuplicateWeatherTypeMappings()">
                    <option *ngFor="let option of parent.metarWeatherTypes|keys" [ngValue]="option">
                        {{parent.metarWeatherTypes[option]}}
                    </option>
                </select>` },
            { colId: "MetarWeatherType3", field: "MetarWeatherType3", headerName: "Weather type 3", width: 110, cellTemplate: `
                <select style="width: 190px;" [(ngModel)]="params.data.MetarWeatherType3" [name]="'params.data[' + params.node.id + '].MetarWeatherType3'" required (change)="parent.checkDuplicateWeatherTypeMappings()">
                    <option *ngFor="let option of parent.metarWeatherTypes|keys" [ngValue]="option">
                        {{parent.metarWeatherTypes[option]}}
                    </option>
                </select>` },
            { colId: "MetarWeatherTypeResult", field: "MetarWeatherTypeResult", headerName: "Final type", width: 110, cellTemplate: `
                <select style="width: 190px;" [(ngModel)]="params.data.MetarWeatherTypeResult" [name]="'params.data[' + params.node.id + '].MetarWeatherTypeResult'" required>
                    <option *ngFor="let option of parent.metarWeatherTypes|keys" [ngValue]="option">
                        {{parent.metarWeatherTypes[option]}}
                    </option>
                </select>` },
            { colId: "MetarMixedPrecipitationProfileId", field: "MetarMixedPrecipitationProfileId", headerName: "Mixed precip profile", width: 120, "excelField": "MetarMixedPrecipitationProfile.Name", cellClass: "select", cellTemplate: `
                <select [(ngModel)]="params.data.MetarMixedPrecipitationProfileId" [name]="'params.data[' + params.node.id + '].MetarMixedPrecipitationProfileId'" style="width: 100px;">
                    <option [ngValue]="null">{{'None'|translate}}</option>
                    <option *ngFor="let profile of parent.metarMixedPrecipitationProfiles|orderBy:['Name']" [ngValue]="profile.Id">
                        {{profile.Name}}
                    </option>
                </select>` },
            { colId: "Actions", field: "Id", headerName: "", width: 80, pinned: 'right', cellClass: "actions", excelIgnore: true, cellTemplate: `<a *ngIf="parent.hasPermissionService.hasPermission('ConfigEditMETARWeatherTypeMappingProfiles')" (click)="parent.removeTypeMapping(params.data)" class="mdi mdi-delete" title="{{'Remove'|translate}}"></a>` },
        ],
    }];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private fileDownload: FileDownloadService,
        private notification: NotificationService,
        private translateService: TranslateService,
        private metarWeatherTypeService: MetarWeatherTypeService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.MetarWeatherTypeMappingProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                this.importItem(this.item);
            });
        } else if (isNew) {
            this.item = this.api.MetarWeatherTypeMappingProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.MetarWeatherTypeMappingProfile.get({ id: id });
        }

        if (this.item.$promise) {
            this.item.$promise.then(_ => {
                this.metarWeatherTypes.$promise.then(() => {
                    var metarWeatherTypeKeys = Object.keys(this.metarWeatherTypes);
                    this.checkDuplicateWeatherTypeMappings();
                    this.item.MetarWeatherTypeMappings = this.item.MetarWeatherTypeMappings.sort((r1, r2) => {
                        var compare1 = this.metarWeatherTypeService.compare(metarWeatherTypeKeys.find(mwt => mwt === r1.MetarWeatherType1), metarWeatherTypeKeys.find(mwt => mwt === r2.MetarWeatherType1));
                        if (compare1 === 0) {
                            var compare2 = this.metarWeatherTypeService.compare(metarWeatherTypeKeys.find(mwt => mwt === r1.MetarWeatherType2), metarWeatherTypeKeys.find(mwt => mwt === r2.MetarWeatherType2));
                            if (compare2 === 0) {
                                return this.metarWeatherTypeService.compare(metarWeatherTypeKeys.find(mwt => mwt === r1.MetarWeatherType3), metarWeatherTypeKeys.find(mwt => mwt === r2.MetarWeatherType3));
                            }
                            return compare2;
                        }
                        return compare1;
                    });
                });
            });
        }
    }

    save() {
        this.item.$promise.then(_ => {
            this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['MetarWeatherTypeMappingProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addTypeMapping() {
        this.item.MetarWeatherTypeMappings = this.item.MetarWeatherTypeMappings || [];
        this.item.MetarWeatherTypeMappings.unshift({});
        this.mappingsGrid.gridApi.applyTransaction({ addIndex: 0, add: this.mappingsGrid.rowData.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    };

    removeTypeMapping(mapping) {
        if (confirm(this.translateService.translate('Are you sure?'))) {
            this.item.MetarWeatherTypeMappings.splice(this.item.MetarWeatherTypeMappings.indexOf(mapping), 1);
            this.mappingsGrid.updateFilteredRows();
            this.ngForm.form.updateValueAndValidity();
            this.ngForm.form.markAsDirty();
            this.checkDuplicateWeatherTypeMappings();
        }
    };

    exportProfile() {
        var query = {
            Id: this.item.Id,
            ColumnDefs: this.columnDefs,
        };
        
        this.api.MetarWeatherTypeMappingProfileExport
            .exportPost(query)
            .then(response => {
                this.fileDownload.download(response.body, (header) => response.headers.get(header));
            });
    };

    exportJson() {
        var json = JSON.stringify(this.item, null, 4);
        var blob = new Blob([json], { type: 'application/json' });
        this.fileDownload.download(blob, 'item.json');
    };

    importJson() {
        var fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.style.width = '0';
        fileInput.style.height = '0';
        fileInput.style.position = 'absolute';
        document.body.appendChild(fileInput)

        fileInput.addEventListener('change', () => {
            var file = fileInput.files[0];
            var reader = new FileReader();
            reader.onload = e => {
                var json = e.target.result;
                var item = JSON.parse(<string>json);
                this.importItem(item);
                document.body.removeChild(fileInput);
            };
            reader.readAsText(file);
        });

        fileInput.click();
    };

    importItem(item) {
        Object.assign(this.item, item);

        delete this.item.Id;

        if (this.item.MetarWeatherTypeMappings) {
            this.item.MetarWeatherTypeMappings.forEach(related => {
                delete related.MetarWeatherTypeMappingProfileId;
            });
        }
    }

    checkDuplicateWeatherTypeMappings() {
        this.item.MetarWeatherTypeMappings = this.item.MetarWeatherTypeMappings || [];
        this.item.MetarWeatherTypeMappings.forEach(row => {
            var duplicateWeatherTypeMapping = this.item.MetarWeatherTypeMappings.find(weathertypeMapping => {
                return row !== weathertypeMapping
                    && (row.MetarWeatherType1 && row.MetarWeatherType1 === weathertypeMapping.MetarWeatherType1)
                    && (row.MetarWeatherType2 && row.MetarWeatherType2 === weathertypeMapping.MetarWeatherType2)
                    && (row.MetarWeatherType3 && row.MetarWeatherType3 === weathertypeMapping.MetarWeatherType3);
            });

            var valid = typeof duplicateWeatherTypeMapping === 'undefined';
            row._valid = valid;

            var key = row.MetarWeatherType1 + '_' + row.MetarWeatherType2 + '_' + row.MetarWeatherType3;

            if (!valid) {
                if (!this.duplicateWeatherTypeMappings.has(key)) {
                    var weatherType1 = this.metarWeatherTypes[duplicateWeatherTypeMapping.MetarWeatherType1];
                    var weatherType2 = this.metarWeatherTypes[duplicateWeatherTypeMapping.MetarWeatherType2];
                    var weatherType3 = this.metarWeatherTypes[duplicateWeatherTypeMapping.MetarWeatherType3];
                    this.duplicateWeatherTypeMappings.set(key, this.notification.show(this.translateService.translate("Duplicate combination (#" + (row.Id || 'new') + ", " + (duplicateWeatherTypeMapping.Id || 'new') + ": " + weatherType1 + " " + weatherType2 + " " + weatherType3 + ")"), { type: "error", sticky: true }));

                    if (this.areWeatherTypeMappingsValid) {
                        this.areWeatherTypeMappingsValid = false;
                    }
                }
            } else if (this.duplicateWeatherTypeMappings.has(key)) {
                this.duplicateWeatherTypeMappings.get(key).remove(); // remove notification
                this.duplicateWeatherTypeMappings.delete(key);

                if (this.duplicateWeatherTypeMappings.size === 0 && !this.areWeatherTypeMappingsValid) {
                    this.areWeatherTypeMappingsValid = true;
                }
            }
        });
    };
}
