<div class="noMargins">
    <page-title>{{('Weather profiler'|translate)}}</page-title>
    <form #requestForm="ngForm" class="contentMargins form" #form="ngForm" (ngSubmit)="profile()">
        <label>
            <span class="caption">{{'Date'|translate}}</span>
            <input date-time [options]="datePickerOptions" [(ngModel)]="request.datetime" name="datetime" format="YYYY-MM-DDTHH:mm:ss[Z]" displayFormat="YYYY-MM-DD HH:mm:ss"> Z
        </label>
        <label>
            <span class="caption">{{'Station'|translate}}</span>
            <select [(ngModel)]="request.stationId" name="stationId">
                <option *ngFor="let station of stations|orderBy:'Name'" [ngValue]="station.Id">{{station.Name}}</option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Sensor select matrix'|translate}}</span>
            <select required [(ngModel)]="request.sensorSelectMatrixId" name="sensorSelectMatrixId" *ngIf="(stations|findById:request?.stationId)?.StationType && request?.stationId">
                <option [ngValue]="sensorSelectMatrix.Id" *ngFor="let sensorSelectMatrix of sensorSelectMatrices|filter:{StationType:(stations|findById:request?.stationId)?.StationType}">{{sensorSelectMatrix.Name}}</option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Previous most recent weather type (NG)'|translate}}</span>
            <select [(ngModel)]="request.previousMostRecentWeatherTypeNg" name="previousMostRecentWeatherTypeNg">
                <option *ngFor="let previousMostRecentWeatherTypeNg of weatherTypeNgs|keyvalue" [ngValue]="previousMostRecentWeatherTypeNg.key">{{previousMostRecentWeatherTypeNg.value}}</option>
            </select>
        </label>
        <label *ngIf="(stations|findById:request?.stationId)?.StationType == 'Gen3'">
            <span class="caption">{{'METAR warnings'|translate}}</span>
            <select [(ngModel)]="request.metarWarning" name="metarWarning">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option [ngValue]="metarWarning.key" *ngFor="let metarWarning of metarWarnings|keyvalue">{{metarWarning.value}}</option>
            </select>
        </label>
        <input type="submit" [disabled]="!form.valid" value="{{'Profile'|translate}}" />
    </form>
    <div class="contentMargins" *ngIf="result">
        <h3>{{'Trace'|translate}}</h3>
        <pre class="result" [innerHTML]="result.Trace"></pre>
        <h3>{{'Weather profile'|translate}}</h3>
        <pre class="result">{{result.WeatherProfile|dump}}</pre>
    </div>
</div>
