<div class="noMargins">
    <page-title>{{('Station alert templates'|translate)}}</page-title>
    <form #form #ngForm="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="searchQuery" name="searchQuery" (ngModelChange)="grid.setSearchQuery(searchQuery)" />
        </label>
        <label class="inline">
            <input type="checkbox" [(ngModel)]="includeInactive" name="includeInactive" (ngModelChange)="grid.setIncludeInactive(includeInactive)" />
            <span class="caption">{{'Include inactive'|translate}}</span>
        </label>
        <filters name="query.Filters" [(ngModel)]="query.Filters" [columnDefs]="columnDefs"></filters>
        <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button type="submit" [disabled]="!ngForm.form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button type="button" [disabled]="!ngForm.form.valid" (click)="grid.export()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
        <button *ngIf="'ConfigAddStationAlertTemplate'|hasPermission" routerLink="/stationAlertTemplates/new" class="green">{{'Add profile'|translate}}</button>
    </form>

    <config-grid #grid
                 gridId="preferences.StationAlertTemplate.ColumnExport"
                 [query]="query"
                 [resource]="api.StationAlertTemplate"
                 [exportResource]="api.StationAlertTemplate"
                 [exportGet]="true"
                 [columnDefs]="columnDefs">
    </config-grid>
</div>
