<div class="noMargins" *ngIf="vm">
    <page-title>{{'METAR Validation'|translate}}</page-title>
    <form class="contentMargins form" #form="ngForm" (ngSubmit)="validate(form.form)">
        <label>
            <span class="caption">{{'Client'|translate}}</span>
            <select [(ngModel)]="clientId" name="clientId">
                <option *ngFor="let client of vm.clients|orderBy:'Name'" [ngValue]="client.Id">
                    {{client.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'METAR reference date'|translate}}</span>
            <input date [options]="vm.datePickerOptions" [(ngModel)]="referenceDate" name="referenceDate">
        </label>
        <label>
            <span class="caption">{{'File'|translate}}</span>
            <file-as-data-uri required [(ngModel)]="data" name="data"></file-as-data-uri>
        </label>
        <label>
            <span class="caption">{{'Use adjusted HOTs'|translate}}</span>
            <input type="checkbox" [(ngModel)]="useAdjustedHot" name="useAdjustedHot"/>
        </label>
        <label>
            <span class="caption">{{'Use METAR LWE regression HOTs if available'|translate}}</span>
            <input type="checkbox" [(ngModel)]="useMetarLWERegressionHotIfAvailable" name="useMetarLWERegressionHotIfAvailable"/>
        </label>
        <br />
        <input type="submit" [disabled]="!form.valid || !form.dirty || data == null" [value]="'Validate'|translate" />
    </form>
</div>

