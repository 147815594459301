<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('User'|translate) + ': ' + (item.Name || 'new')}}</page-title>
	<div id="userEditPage" class="noMargins">
		<div class="toolbar">
			<a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
		</div>

		<fieldset class="contentMargins form" [disabled]="!('ConfigEditUsers'|hasPermission)" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
			<label>
				<span class="caption">{{'Name'|translate}}</span>
				<input type="text" required [(ngModel)]="item.Name" name="Name" />
			</label>
			<label>
				<span class="caption">{{'Active'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.Active" name="Active" />
			</label>
			<label>
				<span class="caption">{{'Email'|translate}}</span>
				<input type="email" required [(ngModel)]="item.Email" name="Email" autocomplete="off" />
			</label>
			<label>
				<span class="caption">{{'Username'|translate}}</span>
				<input type="text" required [(ngModel)]="item.Username" name="Username" autocomplete="off" />
			</label>
			<label>
				<span class="caption">{{'Password'|translate}}</span>
				<input type="password" required [(ngModel)]="item.Password" name="Password" autocomplete="off" minlength="6" />
			</label>
			<label>
				<span class="caption">{{'Repeat password'|translate}}</span>
				<input type="password" required [(ngModel)]="RepeatPassword" name="RepeatPassword" autocomplete="off" [password-match]="item.Password" />
			</label>
			<label *ngIf="sureWxDomainRegex.test(item.Email) || item.TicketTrackingRequired">
				<span class="caption">{{'Mandatory input of ticket and comment'|translate}}</span>
				<input type="checkbox" [(ngModel)]="item.TicketTrackingRequired" name="item.TicketTrackingRequired" />
			</label>
            <label *ngIf="item.TicketTrackingRequired">
                <span class="caption">{{'Ticket Tracker'|translate}}</span>
                <select [(ngModel)]="item.TicketTracker" name="item.TicketTracker">
                    <option *ngFor="let tracker of ticketTrackers">{{tracker}}</option>
                </select>
            </label>
			<label *ngIf="item.TicketTrackingRequired && item.TicketTracker === 'Gemini'">
				<span class="caption">{{'Ticket tracking username'|translate}}</span>
				<input type="text" [required]="item.TicketTrackingRequired" [(ngModel)]="item.TicketTrackingUsername" name="item.TicketTrackingUsername" />
			</label>
			<label *ngIf="item.TicketTrackingRequired">
				<span class="caption">{{'Ticket tracking api key'|translate}}</span>
				<input type="text" [required]="item.TicketTrackingRequired" [(ngModel)]="item.TicketTrackingApiKey" name="item.TicketTrackingApiKey" />
			</label>
		</fieldset>
        <fieldset class="contentMargins form" [disabled]="!('ConfigEditUsers'|hasPermission)">
            <div>
                <div>{{'Permissions' | translate}}</div>
                <table class="table" style="display: inline-block;">
                    <tr>
                        <th colspan="5" style="text-align: center">
                            {{'Configuration'|translate}}
                            <a *ngIf="!showConfig" (click)="showConfig = !showConfig" title="" class="mdi mdi-plus-box"></a>
                            <a *ngIf="showConfig" (click)="showConfig = !showConfig" title="" class="mdi mdi-minus-box"></a>
                        </th>
                    </tr>
                    <ng-container *ngIf="showConfig">
                    <tr style="font-weight:bold;">
                        <td>&nbsp;</td>
                        <td *ngFor="let crud of cruds" style="text-align: center">{{crud}}</td>
                    </tr>
                    <tr *ngFor="let config of results.config">
                        <td>{{config.title}}</td>
                        <td *ngFor="let crud of cruds" style="text-align: center"><input *ngIf="getConfigItem(crud, config.data)" type="checkbox" [checked]="isPermissionEnabled(getConfigItem(crud, config.data).option)" (click)="togglePermission(getConfigItem(crud, config.data).option)" /></td>
                    </tr> 
                    </ng-container>
                </table>
                
                <table class="table" style="display: inline-block; vertical-align: top; margin-left: 30px;">
                    <tr>
                        <th colspan="5" style="text-align: center">
                            {{'Client profile'|translate}}
                            <a *ngIf="!showClientProfile" (click)="showClientProfile = !showClientProfile" title="" class="mdi mdi-plus-box"></a>
                            <a *ngIf="showClientProfile" (click)="showClientProfile = !showClientProfile" title="" class="mdi mdi-minus-box"></a>
                        </th>
                    </tr>
                    <ng-container *ngIf="showClientProfile">
                        <tr style="font-weight:bold;">
                            <td>&nbsp;</td>
                            <td *ngFor="let crud of cruds" style="text-align: center">{{crud}}</td>
                        </tr>
                        <tr *ngFor="let config of results.clientProfile">
                            <td>{{config.title}}</td>
                            <td *ngFor="let crud of cruds" style="text-align: center"><input *ngIf="getConfigItem(crud, config.data)" type="checkbox" [checked]="isPermissionEnabled(getConfigItem(crud, config.data).option)" (click)="togglePermission(getConfigItem(crud, config.data).option)" /></td>
                        </tr>
                    </ng-container>
                </table>
                <table class="table" style="display: inline-block; vertical-align: top; margin-left: 30px;">
                    <tr>
                        <th colspan="2" style="text-align: center">
                            {{'Data'|translate}}
                            <a *ngIf="!showData" (click)="showData = !showData" title="" class="mdi mdi-plus-box"></a>
                            <a *ngIf="showData" (click)="showData = !showData" title="" class="mdi mdi-minus-box"></a>
                        </th>
                    </tr>
                    <ng-container *ngIf="showData">
                        <tr style="font-weight:bold;">
                            <td>&nbsp;</td>
                            <td style="text-align: center">{{'Permission'|translate}}</td>
                        </tr>
                        <tr *ngFor="let config of results.data">
                            <td>{{config.name}}</td>
                            <td style="text-align: center"><input type="checkbox" [checked]="isPermissionEnabled(config.option)" (click)="togglePermission(config.option)" /></td>
                        </tr>
                    </ng-container>
                </table>
                <table class="table" style="display: inline-block; vertical-align: top; margin-left: 30px;">
                    <tr>
                        <th colspan="2" style="text-align: center">
                            {{'Other'|translate}}
                            <a *ngIf="!showO" (click)="showO = !showO" title="" class="mdi mdi-plus-box"></a>
                            <a *ngIf="showO" (click)="showO = !showO" title="" class="mdi mdi-minus-box"></a>
                        </th>
                    </tr>
                    <ng-container *ngIf="showO">
                        <tr style="font-weight:bold;">
                            <td>&nbsp;</td>
                            <td style="text-align: center">{{'Permission'|translate}}</td>
                        </tr>
                        <tr *ngFor="let config of results.other">
                            <td>{{config.name}}</td>
                            <td style="text-align: center"><input type="checkbox" [checked]="isPermissionEnabled(config.option)" (click)="togglePermission(config.option)" /></td>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </fieldset>
	</div>
</form>
