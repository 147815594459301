<div class="summary" style="margin: 10px;" *ngIf="deicingSummary">
    <table class="table" style="float: left; margin: 10px;">
        <tr>
            <th colspan="2">{{'Summary'|translate}}</th>
        </tr>
        <tr>
            <th>{{'HOT requests (#)'|translate}}</th>
            <td>{{deicingSummary.HotRequestsCount}}</td>
        </tr>
        <tr>
            <th>{{'Deicing records (#)'|translate}}</th>
            <td>{{deicingSummary.DeicingRecordsCount}}</td>
        </tr>
        <tr>
            <th>{{'Deicing records without HOT requests (#)'|translate}}</th>
            <td>{{deicingSummary.DeicingRecordsWithoutHotRequestsCount}}</td>
        </tr>
        <tr>
            <th>{{'HOT requests without deicing records (#)'|translate}}</th>
            <td>{{deicingSummary.HotRequestsWithoutDeicingRecordsCount}}</td>
        </tr>
        <tr>
            <th>{{'Type 1 only (#)'|translate}}</th>
            <td>{{deicingSummary.Type1OnlyCount}}</td>
        </tr>
        <tr>
            <th>{{'Type 4 (#)'|translate}}</th>
            <td>{{deicingSummary.Type4Count}}</td>
        </tr>
        <tr>
            <th>{{'UP HOTs (#)'|translate}}</th>
            <td>{{deicingSummary.UnknownPrecipitationHotCount}}</td>
        </tr>
        <tr>
            <th>{{'Type 1 volume min. (L)'|translate}}</th>
            <td>{{deicingSummary.Type1VolumeMinLitres}}</td>
        </tr>
        <tr>
            <th>{{'Type 4 volume min. (L)'|translate}}</th>
            <td>{{deicingSummary.Type4VolumeMinLitres}}</td>
        </tr>
        <tr>
            <th>{{'Type 1 volume max.'|translate}}</th>
            <td>
                {{'Volume (L)'}}: {{deicingSummary.Type1VolumeMaxLitres?.Volume}}
                <br/>{{'Equipment type'}}: {{deicingSummary.Type1VolumeMax?.EquipmentTypeIdentifier}}
                <br/>{{'Date'}}: {{deicingSummary.Type1VolumeMax?.DateTime|utcDateTimeFormat:dateTimeFormat}}
            </td>
        </tr>
        <tr>
            <th>{{'Type 4 volume max.'|translate}}</th>
            <td>
                {{'Volume (L)'}}: {{deicingSummary.Type4VolumeMaxLitres?.Volume}}
                <br/>{{'Equipment type'}}: {{deicingSummary.Type4VolumeMax?.EquipmentTypeIdentifier}}
                <br/>{{'Date'}}: {{deicingSummary.Type4VolumeMax?.DateTime|utcDateTimeFormat:dateTimeFormat}}
            </td>
        </tr>
        <tr>
            <th>{{'Type 4 needed according to LWE (#)'|translate}}</th>
            <td>{{deicingSummary.Type4NeededLweCount}}</td>
        </tr>
        <tr>
            <th>{{'Type 4 needed according to METAR (#)'|translate}}</th>
            <td>{{deicingSummary.Type4NeededMetarCount}}</td>
        </tr>
        <tr>
            <th>{{'Only type 1 needed (#)'|translate}}</th>
            <td>{{deicingSummary.OnlyType1NeededCount}}</td>
        </tr>
        <tr>
            <th>{{'Potential type 4 saved (L)'|translate}}</th>
            <td>{{deicingSummary.Type4PotentialVolumeSavedTotalLitres}}</td>
        </tr>
        <tr>
            <th>{{'Actual type 4 saved (#)'|translate}}</th>
            <td>{{deicingSummary.Type4SavedVsMetarCount}}</td>
        </tr>
        <tr>
            <th>{{'Actual type 4 saved (L)'|translate}}</th>
            <td>{{deicingSummary.Type4VolumeSavedVsMetarTotalLitres?.toFixed()}}</td>
        </tr>
        <tr>
            <th>{{'Taxi time average'|translate}}</th>
            <td>{{deicingSummary.TaxiTimeAverage?.toFixed()}}</td>
        </tr>
    </table>
    <table class="table" style="float: left; margin: 10px; width: 300px;">
        <tr>
            <th colspan="2">{{'Type 1 average volume by equipment type'|translate}}</th>
        </tr>
        <tr *ngFor="let equipmentType of deicingSummary.Type1VolumeByEquipmentTypeAverage">
            <th style="width: 100px;">{{equipmentType.EquipmentTypeIdentifier}}</th>
            <td>{{equipmentType.Volume?.toFixed()}}</td>
        </tr>
    </table>
    <table class="table" style="float: left; margin: 10px; width: 300px;">
        <tr>
            <th colspan="2">{{'Type 4 average volume by equipment type'|translate}}</th>
        </tr>
        <tr *ngFor="let equipmentType of deicingSummary.Type4VolumeByEquipmentTypeAverage">
            <th style="width: 100px;">{{equipmentType.EquipmentTypeIdentifier}}</th>
            <td>{{equipmentType.Volume?.toFixed()}}</td>
        </tr>
    </table>
    <table class="table" style="float: left; margin: 10px; width: 300px;">
        <tr>
            <th colspan="2">{{'Average taxi time by deicing location'|translate}}</th>
        </tr>
        <tr *ngFor="let deicingLocation of deicingSummary.TaxiTimeByDeicingLocationAverage">
            <th style="width: 100px;">{{deicingLocation.DeicingLocation}}</th>
            <td>{{deicingLocation.TaxiTime?.toFixed()}}</td>
        </tr>
    </table>
    <table class="table" style="float: left; margin: 10px; width: 300px;">
        <tr>
            <th colspan="2">{{'Average taxi time by weather type'|translate}}</th>
        </tr>
        <tr *ngFor="let weatherType of deicingSummary.TaxiTimeByWeatherTypeAverage">
            <th style="width: 100px;">{{weatherType.WeatherType}}</th>
            <td>{{weatherType.TaxiTime?.toFixed()}}</td>
        </tr>
    </table>
</div>
