import { AfterViewInit, Component } from "@angular/core";
import { ApiService } from "../shared/Api.service";

@Component({
    templateUrl: './ManualSensorEntry.component.html'
})
export class ManualSensorEntryComponent implements AfterViewInit {
    sensorTypes: any;
    result: any;
    type: any;
    rawData: any;

    constructor(private api: ApiService) {
    }

    ngAfterViewInit() {
        Promise.resolve(this.api.SensorType.query()).then(result => {
            this.sensorTypes = result
            console.log(this.sensorTypes)
        });
    }

    decode() {
        console.log('type=', this.type);
        console.log('rawData=', this.rawData);

        this.api.SensorDecode.queryObject({
            type: this.type,
            rawData: this.rawData
        }).$promise.then(result => this.result = result);
    }
}
