import { Component, OnInit } from "@angular/core";
import {IAgGridColGroupDef, IAgGridColumnDef, IBaseQuery, ColumnPreferenceGroup, ColumnPreference} from './Query.model';
import { ColumnPreferencesService } from './ColumnPreferences.service';

@Component({
    templateUrl: "./ColumnSelectionDialog.component.html",
    styleUrls: ['ColumnSelectionDialog.component.scss']
})
export class ColumnSelectionDialogComponent implements OnInit {
    container: JQuery;
    columnPreferences: ColumnPreferenceGroup[];
    selectedCount: number;
    totalCount: number;
    infoMessage: string;
    columnDefs: (IAgGridColumnDef | IAgGridColGroupDef)[];
    columnPreferencesStorageKey: string;
    selectionConfirmed: (selectedColumns: ColumnPreferenceGroup[]) => void;

    constructor(
        private columnPreferencesService: ColumnPreferencesService,
    )
    { }
    
    ngOnInit(): void {
        this.columnPreferences = this.columnPreferencesService
            .loadColumnPreferences(this.columnPreferencesStorageKey + '_selected', this.columnDefs)
            .map(g => ({
                ...g,
                children: g.children.filter(c => c.initialHide == null || !c.initialHide),
            }));
        
        this.updateTotals();
    }

    // Close the dialog
    dismiss() {
        this.container.dialog('close');
    }

    confirmSelection() {
        this.columnPreferencesService.savePreferences(this.columnPreferencesStorageKey + '_selected', this.columnPreferences);

        this.selectionConfirmed(this.columnPreferences);
        
        this.container.dialog('close');
    }

    cancel() {
	    this.container.dialog('close');
    }

    // Check/uncheck column
    toggleSelected(checked: boolean, column: ColumnPreference) {
        column.selected = checked;
        this.updateTotals();
    }

    // Check/uncheck everything
    toggleSelectedAll(checked: boolean) {
        this.columnPreferences.forEach(g => g.children.forEach(c => c.selected = checked));
        this.updateTotals();
    }

    // Check/uncheck everything in group
    toggleSelectedAllGroup(checked: boolean, group: ColumnPreferenceGroup) {
        group.children.forEach(c => c.selected = checked);
        this.updateTotals();
    }

    // Helper: Returns an appropriate text message depending on toggle selection
    updateTotals() {
        this.selectedCount = this.columnPreferences.reduce((sum, group) => sum + group.children.filter(c => c.selected || c.alwaysSelected).length, 0);
        this.totalCount = this.columnPreferences.reduce((sum, group) => sum + group.children.length, 0);
        this.columnPreferences.forEach(g => g.allSelected = g.children.every(c => c.selected || c.alwaysSelected));
        
        if (this.selectedCount === 0) {
            this.infoMessage = 'None selected';
        } else if (this.selectedCount === this.totalCount) {
            this.infoMessage = 'All selected';
        } else {
            this.infoMessage = 'Custom selection';
        }
    }
}
