<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('JMS configuration'|translate) + ': ' + (item.Name || 'new')}}</page-title>
    <div id="userEditPage" class="noMargins">
        <div class="toolbar">
            <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        </div>

        <fieldset class="contentMargins form" [disabled]="!('ConfigEditJMSServerConfigurations'|hasPermission)" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="Name" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="Active" />
            </label>
            <label>
                <span class="caption">{{'Type'|translate}}</span>
                <select [(ngModel)]="item.Type" name="Type">
                    <option *ngFor="let item of configurationTypes|keyvalue" [value]="item.key">{{item.value}}</option>
                </select>
            </label>
            <label>
                <span class="caption">{{'URL'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Url" name="Url" />
            </label>
            <label>
                <span class="caption">{{'Wait interval (ms)'|translate}}</span>
                <input type="text" required [(ngModel)]="item.WaitInterval" name="WaitInterval" />
            </label>
            <label>
                <span class="caption">{{'VPN name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.VPNName" name="VPNName" />
            </label>
            <label>
                <span class="caption">{{'Queue'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Queue" name="Queue" />
            </label>
            <label>
                <span class="caption">{{'Username'|translate}}</span>
                <input type="text" [(ngModel)]="item.Username" name="Username" />
            </label>
            <label>
                <span class="caption">{{'Password'|translate}}</span>
                <input type="text" [(ngModel)]="item.Password" name="Password" />
            </label>
            <ng-container *ngIf="item.Type == 'S3Upload'">
                <h3>{{'Amazon S3 destination'|translate}}</h3>
                <label>
                    <span class="caption">{{'AWS access key'|translate}}</span>
                    <input type="text" required [(ngModel)]="item.AWSAccessKey" name="AWSAccessKey" />
                </label>
                <label>
                    <span class="caption">{{'AWS secret key'|translate}}</span>
                    <input type="text" required [(ngModel)]="item.AWSSecretKey" name="AWSSecretKey" />
                </label>
                <label>
                    <span class="caption">{{'Bucket name'|translate}}</span>
                    <input type="text" required [(ngModel)]="item.S3BucketName" name="S3BucketName" />
                </label>
            </ng-container>
        </fieldset>
    </div>
</form>
