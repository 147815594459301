import { Component, OnInit } from "@angular/core";
import { Client } from "../shared/model/Client";
import { ApiService } from "../shared/Api.service";
import { FileDownloadService, IEnumObject, StorageService } from "swx.front-end-lib";
import { Airport } from "../shared/model/Airport";
import { HotSummary } from "../shared/model/HotSummary";
import { RootScope } from "../shared/RootScope.service";

interface Query {
    ClientId: number;
    HotRequestType: string[];
    AirportId: number[];
    FromDate: string;
    ToDate: string;
    MaxHotRequestAgeInMinutes: number;
    IncludeIPs: string;
    ExcludeIPs: string;
}

@Component({
    templateUrl: 'ReportHots.component.html'
})
export class ReportHotsComponent implements OnInit {
    private readonly storeKey = 'reportHOTsQuery';
    query: Query;
    clients: Client[];
    hotRequestTypes: IEnumObject<string>;
    airports: Airport[];
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    hotSummary: HotSummary;
    tab: string; // the active tab

    constructor(
        private api: ApiService,
        private storage: StorageService,
        private fileDownload: FileDownloadService,
        private root: RootScope) {
    }

    ngOnInit() {
        Promise.all([
            this.api.Client.query().$promise,
            this.api.HotRequestType.query(),
            this.api.Airport.query().$promise,
        ]).then(([clients, hotRequestTypes, airports]) => {
            this.clients = clients;
            this.hotRequestTypes = hotRequestTypes;
            this.airports = airports;

            this.query = this.storage.load(this.storeKey) || {
                ClientId: null,
                HotRequestType: [],
                AirportId: [],
                FromDate: null,
                ToDate: null,
                MaxHotRequestAgeInMinutes: 180,
                IncludeIPs: null,
                ExcludeIPs: null,
            }
        })
    }

    Math = window.Math;

    refresh() {
        this.storage.save(this.storeKey, this.query);

        this.api.HotSummary.queryObject(this.query).$promise.then(hotSummary => {
            this.hotSummary = hotSummary;
        });
    }

    export() {
        this.api.HotSummary.export({...this.query, Output: 'Excel'}).then(response => {
            this.fileDownload.download(response.body, (header) => response.headers.get(header));
        });
    }

    switchTab(tab: string) {
        this.tab = tab;
    };

    //************************************
// <tr *ngFor="let flight of hotSummary.FlightsExceedingHot|applySort:orderByFieldFlightsExceedingHot:orderByReverseFlightsExceedingHot:this:'flight'">-->
// <!--                <td>{{flight.HoldoverStartTime|utcDateTimeFormat:root.dateFormat}}</td>-->
// <!--                <td>{{flight.AirportICAOCode}}</td>-->
// <!--                <td>{{flight.FlightIdentifier}}</td>-->
// <!--                <td>{{flight.HotRequestDateTime|utcDateTimeFormat:root.timeFormat}}</td>-->
// <!--                <td>{{flight.HoldoverStartTime|utcDateTimeFormat:root.timeFormat}}</td>-->
// <!--                <td>{{flight.WheelsUpTime|utcDateTimeFormat:root.timeFormat}}</td>-->
// <!--                <td>{{flight.Type1Hot}}</td>-->
// <!--                <td>{{flight.Type4Hot}}</td>-->
// <!--                <td>{{flight.TaxiTime}}</td>-->
// <!--                <td>{{flight.WasType4Applied}}</td>-->
// <!--                <td>{{flight.ExceededBy}}</td>-->
// <!--                <td>{{flight.DeicingRecordId}}</td>-->
// <!--                <td>{{flight.DeicingRecordHoldoverStartTime|utcDateTimeFormat:root.timeFormat}}</td>-->
// <!--            </tr>-->
    //**************************************

}


//     .controller('reportHOTsController', [
//         '$rootScope', '$scope', '$location', 'api', 'fileDownload', function ($rootScope, $scope, $location, api, fileDownload) {
//             $rootScope.title = 'HOT report';
//
//             $scope.tab = $location.hash() || 'summary';
//
//             $scope.switchTab = function (tab) {
//                 $location.hash(tab);
//                 $scope.tab = tab;
//             };
//
//             if (window.localStorage['reportHOTsQuery']) {
//                 $scope.query = JSON.parse(window.localStorage['reportHOTsQuery']);
//                 $scope.query.MaxHotRequestAgeInMinutes = $scope.query.MaxHotRequestAgeInMinutes || 180;
//             } else {
//                 $scope.query = {
//                     MaxHotRequestAgeInMinutes: 180
//                 };
//             }
//             delete $scope.query.Output;
//
//             $scope.airports = api.Airport.query();
//             $scope.hotRequestTypes = api.HotRequestType.query();
//             $scope.clients = api.Client.query();
//
//             $scope.JSON = JSON;
//             $scope.Math = Math;
//             $scope.parseInt = parseInt;
//

//         }
//     ]);
