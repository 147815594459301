import {Component, OnInit, ViewChild, ViewEncapsulation} from "@angular/core";
import {GoogleMapsLoaderService, IAgGridColumnDef, QuerySerializerService, StorageService, ValueFormatters} from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";

@Component({
    templateUrl: './PortalUserLogin.component.html',
    styleUrls: ['../hotMap/HotMap.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PortalUserLoginComponent implements OnInit {
    storeKey = 'portalUserLoginQuery'
    @ViewChild('grid') grid;
    @ViewChild('userMap') userMap;
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    users = this.api.PortalUser.query();
    clients = this.api.Client.query();
    fromDate = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds());
    tab = 'map';

    resource = this.api.PortalUserLogin;
    exportResourcePost = this.api.PortalUserLoginExport;
    mapLoaded: Promise<any> = null;
    markers = [];
    
    constructor(private storage: StorageService,
                private api: ApiService,
                private formatters: ValueFormatters,
                private googleMapsLoaderService: GoogleMapsLoaderService,
                private querySerializerService: QuerySerializerService) {
        this.fromDate.setDate(this.fromDate.getDate() - 7);
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery){
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }

        this.mapLoaded = new Promise((resolve, reject) => {
            this.googleMapsLoaderService.load().then(maps => {
                var mapElement = this.userMap.nativeElement;

                resolve(new maps.Map(mapElement,
                    {
                        zoom: 3,
                        center: new maps.LatLng(45.5017, -73.5673),
                        mapTypeId: maps.MapTypeId.TERRAIN
                    }));
            });
        });
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    };

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.refreshMap();
        this.grid.refresh();
    }

    switchTab(tabName) {
        this.tab = tabName;
    }

    columnDefs: IAgGridColumnDef[] = [
        { colId: "Date", "field": "LastModified", headerName: "Date",  valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left', "searchable": false },
        { colId: "Time", "field": "LastModified", headerName: "Time", valueFormatter: this.formatters.utcTimeFormatter(), "excelIgnore": true, pinned: 'left' },
        { colId: "Id", "field": "Id", headerName: "#", width: 90, filterType: "integer" },
        { colId: "LastModified", "field": "LastModified", headerName: "Full date", filterType: "date", valueFormatter: this.formatters.utcTimeLongFormatter() },
        { colId: "ClientName", "field": "ClientName", headerName: "Client", width: 120 },
        { colId: "UserEmail", "field": "Email", headerName: "User"},
        { colId: "IPAddress", "field": "IPAddress", headerName: "IP"},
        { colId: "Latitude", "field": "Latitude", headerName: "Latitude", width: 120, filterType: "float" },
        { colId: "Longitude", "field": "Longitude", headerName: "Longitude", width: 120, filterType: "float" },
        { colId: "AirportIcaoCode", "field": "AirportIcaoCode", headerName: "Selected Airport", width: 120 }
    ];

    refreshMap() {
        this.googleMapsLoaderService.load().then(maps => {
            this.mapLoaded.then(map => {
                this.markers.forEach(marker => marker.setMap(null));
                this.markers.length = 0;

                let query = {
                    ...this.query,
                    PageSize: 100,
                    PageNumber: 1,
                }
                query.Filters = [...this.query.Filters]

                query.Filters.push({
                    Field: 'HotRequest.Latitude',
                    Operator: 'NotEmpty'
                });
                query.Filters.push({
                    Field: 'HotRequest.Longitude',
                    Operator: 'NotEmpty'
                });
                query = this.querySerializerService.toHttpParams(query);

                this.resource.query(query).$promise.then(items => {
                    items.forEach((item: any) => {
                        var loc = new maps.LatLng(item.Latitude, item.Longitude);
                        var icon = new maps.MarkerImage("//storage.googleapis.com/support-kms-prod/SNP_2752125_en_v0", new maps.Size(9, 9), new maps.Point(0, 0), new maps.Point(0, 0));
                        var date = new Date(item.LastModified);
                        var marker = new maps.Marker({
                            map: map,
                            position: loc,
                            label: `<center>${item.Email}<br>${date.getUTCFullYear()}-${date.getUTCMonth()}-${date.getUTCDate()}-${date.getUTCHours()}:${date.getUTCMinutes()}</center>`,
                            icon: icon
                        });

                        this.markers.push(marker);
                    });
                })
            })
        })
    }
} 
