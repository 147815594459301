<div class="field filter" *ngIf="field">
    <span class="caption" *ngIf="caption">{{field.GroupLabel ? (field.GroupLabel + ' - ') : ''}}{{field.Label}}</span>
    
    <select name="filter.Operator" [(ngModel)]="filter.Operator" required>
        <option *ngFor="let option of field.Operators|keys" [ngValue]="option">
            {{field.Operators[option]}}
        </option>
    </select>
    <select *ngIf="field.Type == 'boolean' && filter.Operator != 'Empty' && filter.Operator != 'NotEmpty'" name="filter.Value" [(ngModel)]="filter.Value">
        <option [ngValue]="true">Yes</option>
        <option [ngValue]="false">No</option>
    </select>
    <input *ngIf="(!field.Type || field.Type == 'string') && filter.Operator != 'Empty' && filter.Operator != 'NotEmpty'" type="text" name="filter.Value" [(ngModel)]="filter.Value" />
    <input date *ngIf="field.Type == 'date' && filter.Operator != 'Empty' && filter.Operator != 'NotEmpty'" options="datePickerOptions" name="filter.Value" [(ngModel)]="filter.Value" style="width: 200px;" required />
    <input *ngIf="field.Type == 'integer' && filter.Operator != 'Empty' && filter.Operator != 'NotEmpty'" type="number" step="1" name="filter.Value" [(ngModel)]="filter.Value" required />
    <input *ngIf="field.Type == 'float' && filter.Operator != 'Empty' && filter.Operator != 'NotEmpty'" type="number" step="any" name="filter.Value" [(ngModel)]="filter.Value" required />
    <multiselect *ngIf="field.Type == 'enum' && filter.Operator != 'Empty' && filter.Operator != 'NotEmpty'" name="filter.Value" [(ngModel)]="filter.Value" width="{{field.MultiselectWidth}}">
        <multiselect-option *ngFor="let option of field.Options|keys" [value]="option" [name]="field.Options[option]"></multiselect-option>
    </multiselect>
    <ng-content></ng-content>
</div>
