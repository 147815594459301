<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('IBM MQ configuration'|translate) + ': ' + (item.Name || 'new')}}</page-title>
    <div id="userEditPage" class="noMargins">
        <div class="toolbar">
            <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        </div>

        <fieldset class="contentMargins form" [disabled]="!('ConfigEditIBMMQServerConfigurations'|hasPermission)" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="Name" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="Active" />
            </label>
            <label>
                <span class="caption">{{'Type'|translate}}</span>
                <select [(ngModel)]="item.Type" name="Type">
                    <option *ngFor="let item of configurationTypes|keyvalue" [value]="item.key">{{item.value}}</option>
                </select> 
            </label>
            <label>
                <span class="caption">{{'Connection method'|translate}}</span>
                <select [(ngModel)]="item.ConnectionMethod" name="ConnectionMethod">
                    <option *ngFor="let item of connectionMethods|keyvalue" [value]="item.key">{{item.value}}</option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Hostname'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Hostname" name="Hostname" />
            </label>
            <label>
                <span class="caption">{{'Port'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Port" name="Port" />
            </label>
            <label>
                <span class="caption">{{'Wait interval (ms)'|translate}}</span>
                <input type="text" required [(ngModel)]="item.WaitInterval" name="WaitInterval" />
            </label>
            <label>
                <span class="caption">{{'Queue manager'|translate}}</span>
                <input type="text" required [(ngModel)]="item.QueueManager" name="QueueManager" />
            </label>
            <label>
                <span class="caption">{{'Channel'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Channel" name="Channel" />
            </label>
            <label>
                <span class="caption">{{'In queue'|translate}}</span>
                <input type="text" required [(ngModel)]="item.InQueue" name="InQueue" />
            </label>
            <label>
                <span class="caption">{{'Out queue'|translate}}</span>
                <input type="text" required [(ngModel)]="item.OutQueue" name="OutQueue" />
            </label>
            <label>
                <span class="caption">{{'SSL cipher spec'|translate}}</span>
                <input type="text" [(ngModel)]="item.SSLCipherSpec" name="SSLCipherSpec" />
            </label>
            <label>
                <span class="caption">{{'SSL key repository directory'|translate}}</span>
                <input type="text" [(ngModel)]="item.SSLKeyRepository" name="SSLKeyRepository" />
            </label>
            <label>
                <span class="caption">{{'Username'|translate}}</span>
                <input type="text" [(ngModel)]="item.Username" name="Username" />
            </label>
            <label>
                <span class="caption">{{'Password'|translate}}</span>
                <input type="text" [(ngModel)]="item.Password" name="Password" />
            </label>
            <label>
                <span class="caption">{{'Forward ACARS to URL'|translate}}</span>
                <input type="text" [(ngModel)]="item.ForwardACARSToUrl" name="ForwardACARSToUrl" />
            </label>
        </fieldset>
    </div>
</form>
