<div class="reportDeicing noMargins">
    <page-title>{{'Deicing report'|translate}}</page-title>
    <form class="toolbar gridFilters" #form="ngForm">
        <div class="field inline">
            <span class="caption">{{'Client'|translate}}</span>
            <multiselect name="query.ClientId" [(ngModel)]="query.ClientId">
                <multiselect-option *ngFor="let option of clients|orderBy:'Name'" [value]="option.Id" [name]="option.Name"></multiselect-option>
            </multiselect>
        </div>
        <div class="field inline">
            <span class="caption">{{'Airport'|translate}}</span>
            <multiselect name="query.AirportId" [(ngModel)]="query.AirportId">
                <multiselect-option *ngFor="let airport of airports|orderBy:'[-HasStation, ICAOCode]'" [value]="airport.Id" [name]="airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name"></multiselect-option>
            </multiselect>
        </div>
        <div class="field inline">
            <span class="caption">{{'Upload'|translate}}</span>
            <multiselect name="query.DeicingUploadId" [(ngModel)]="query.DeicingUploadId">
                <multiselect-option *ngFor="let deicingUpload of deicingUploads|orderBy:'-Id'" [value]="deicingUpload.Id" [name]="deicingUpload.Name"></multiselect-option>
            </multiselect>
        </div>
        <label class="inline">
            <span class="caption">{{'HOT needed margin (minutes)'|translate}}</span>
            <input type="number" step="1" required [(ngModel)]="query.HotNeededMargin" style="width: 50px;" name="query.HotNeededMargin"/>
        </label>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="query.FromDate">
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
        </label>
        <label class="field inline">
            <input type="checkbox" [(ngModel)]="query.RequireDeicingRecord" name="query.RequireDeicingRecord">
            <span class="caption">{{'Require deicing record'|translate}}</span>
        </label>
        <label class="field inline">
            <input type="checkbox" [(ngModel)]="query.RequireHotRequest" name="query.RequireHotRequest">
            <span class="caption">{{'Require HOT request'|translate}}</span>
        </label>
        <div class="field inline">
            <span class="caption">{{'Reference fluids'|translate}}</span>
            <multiselect name="query.FluidId" [(ngModel)]="query.FluidId" (change)="onFluidIdChange()">
                <multiselect-option *ngFor="let fluid of fluids|orderBy:'[FluidProfileName,Type,Name]'" [value]="fluid.Id" [name]="fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' (' + fluid.Dilution + ')'"></multiselect-option>
            </multiselect>
        </div>
        <filters name="query.Filters" [(ngModel)]="query.Filters" [columnDefs]="columnDefs"></filters>
        <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button type="button" (click)="apply()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button type="button" [disabled]="!form.valid || !query.FromDate" (click)="grid.export()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
        <input type="button" (click)="summaryVisible = !summaryVisible" value="{{(summaryVisible ? 'Hide summary' : 'Show summary')|translate}}" />
        <button *ngIf="'DeicingDataDelete'|hasPermission" type="button" [disabled]="!grid.gridApi?.getSelectedRows().length" (click)="deleteSelected()"><span class="mdi mdi-delete"></span> {{'Delete selected'|translate}}</button>
    </form>
    <ng-container *ngIf="summaryVisible">
        <report-deicing-summary [query]="query"></report-deicing-summary>
    </ng-container>
    <grid #grid
          gridId="preferences.DeicingReport.ColumnExport_v13"
          [query]="query"
          [headerHeight]="74"
          [resource]="resource"
          [exportResource]="exportResourcePost"
          [columnDefs]="columnDefs"
          [components]="components"
          [rowSelection]="'multiple'"
          [gridOptions]="gridOptions">
    </grid>
</div>

<!--<div class="reportDeicing noMargins">-->

<!--</div>-->
<!--<script type="text/ng-template" id="hotErrorsDialog">-->
<!--    <table class="table" style="width: 100%;">-->
<!--        <tr>-->
<!--            <th>{{'HOT response errors'|translate}}</th>-->
<!--        </tr>-->
<!--        <tr>-->
<!--            <td>{{hot.HotResponse.Error || '&nbsp;'}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--            <th>{{'ACARS request errors'|translate}}</th>-->
<!--        </tr>-->
<!--        <tr>-->
<!--            <td>{{hot.AcarsRequest.Errors || '&nbsp;'}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--            <th>{{'HOT calculation errors'|translate}}</th>-->
<!--        </tr>-->
<!--        <tr>-->
<!--            <td>{{hot.HotResponseHot.Errors || '&nbsp;'}}</td>-->
<!--        </tr>-->
<!--        <tr>-->
<!--            <th>{{'Weather profile errors'|translate}}</th>-->
<!--        </tr>-->
<!--        <tr>-->
<!--            <td>{{hot.WeatherProfile.Errors || '&nbsp;'}}</td>-->
<!--        </tr>-->
<!--    </table>-->
<!--</script>-->
<!--<script type="text/ng-template" id="deicingReportSummaryDialog">-->
<!--    <pre>-->
<!--        {{deicingSummary}}-->
<!--    </pre>-->
<!--</script>-->

