import {Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {ChangesService, FileDownloadService, FilterPipe, OrderByPipe} from "swx.front-end-lib";
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";

@Component({
    templateUrl: './FluidEdit.component.html'
})
export class FluidEditComponent implements OnInit {
    dilutionFormats = ["50/50", "75/25", "100/0"];
    tab = 'basicInfo';
    fluidVersionTab;
    isEditionMode = false;
    isMobileEditionMode = false;
    selectedDilution;
    @ViewChild('ngForm', {static: true}) ngForm;

    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private location: Location,
                private orderBy: OrderByPipe,
                private route: ActivatedRoute,
                private ticketTrackingSystem: TicketTrackingSystemService,
                private router: Router,
                private changes: ChangesService,
                private fileDownload: FileDownloadService,
    ) {
    }
    
    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
        this.fluidVersionTab = null;
    };

    switchFluidVersion(fluidVersion) {
        this.fluidVersionTab = fluidVersion;
        fluidVersion.FluidHotSpecs =  this.orderBy.transform(fluidVersion.FluidHotSpecs, ['WeatherType', 'MinTemp', 'MaxIntensity']);
        fluidVersion.FluidMetarSpecs = this.orderBy.transform(fluidVersion.FluidMetarSpecs, ['MetarWeatherType', 'MinTemp']);
        fluidVersion.FluidDegreeSpecificMetarSpecs = this.orderBy.transform(fluidVersion.FluidDegreeSpecificMetarSpecs, ['MetarWeatherType', 'MinTemp']);
    };

    fluidTypes = this.api.FluidType.query(); //enums('FluidType');
    wingMaterialTypes = this.api.WingMaterialType.query(); //enums('WingMaterialType');
    weatherTypes = this.api.FluidHotSpecWeatherType.query(); //enums('FluidHotSpecWeatherType');
    metarWeatherTypes = this.api.MetarWeatherType.query(); //enums('MetarWeatherType');
    fluidProfiles = this.api.FluidProfile.query();
    deicingFluids = this.api.DeicingFluid.query();
    fluidSeasons = this.api.FluidSeason.query();
    math = window.Math;
    item;
    minTemps = {};
    fluidSeasonId;
    
    ngOnInit() {
        const fluidProfileId = this.route.snapshot.queryParamMap.get('fluidProfileId');
        const id = this.route.snapshot.paramMap.get('id');
        const copyId = this.route.snapshot.queryParamMap.get('copy');
        const isNew = id === 'new';
        
        if (copyId) {
            this.item = this.api.Fluid.get({ id: copyId });
            this.item.$promise.then(item => {
                this.importItem(item);
            });
        } else if (isNew) {
            this.item =  this.api.Fluid.create(); //new api.Fluid();
            this.item.FluidProfileId = fluidProfileId;
        } else {
            this.item = this.api.Fluid.get({ id: id });
        }
        
        if (this.item.$promise) {
            this.item.$promise.then(item => {
                item.FluidVersions = this.orderBy.transform(item.FluidVersions, ['Order']);
            })
        }
    }

    save() {
        var fluidProfileId = this.item.FluidProfileId;
        var returnPath = '/fluids?fluidProfileId=' + fluidProfileId;
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, returnPath);
    };

    cancel() {
        var returnPath = '/fluids?fluidProfileId=' + this.item.FluidProfileId;
        this.router.navigateByUrl(returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: 'Fluid',
            SubjectId: this.item.Id
        });
    };

    exportJson() {
        var json = JSON.stringify(this.item, (k, v) => k[0] === '$' ? undefined : v, 4);
        var blob = new Blob([json], { type: 'application/json' });
        this.fileDownload.download(blob, 'item.json');
    };
    
    importJson() {
        var fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.style.width = '0';
        fileInput.style.height = '0';
        fileInput.style.position = 'absolute';
        document.body.appendChild(fileInput)

        fileInput.addEventListener('change', () => {
            var file = fileInput.files[0];
            var reader = new FileReader();
            reader.onload = e => {
                var json = e.target.result;
                var item = JSON.parse(<string>json);
                this.importItem(item);
                document.body.removeChild(fileInput);
            }
            reader.readAsText(file);
        });

        fileInput.click();
    };

    importItem(item) {
        Object.assign(this.item, item);

        delete this.item.Id;

        if (this.item.FluidVersions) {
            this.item.FluidVersions.forEach(fluidVersion => {
                fluidVersion.FluidId = -fluidVersion.FluidId;
                fluidVersion.Id = -fluidVersion.Id;

                if (fluidVersion.FluidHotSpecs) {
                    fluidVersion.FluidHotSpecs.forEach(spec => {
                        spec.FluidVersionId = -spec.FluidVersionId;
                        spec.Id = -spec.Id;
                    });
                }

                if (fluidVersion.FluidMetarSpecs) {
                    fluidVersion.FluidMetarSpecs.forEach(spec => {
                        spec.FluidVersionId = -spec.FluidVersionId;
                        spec.Id = -spec.Id;
                    });
                }

                if (fluidVersion.FluidDegreeSpecificMetarSpecs) {
                    fluidVersion.FluidDegreeSpecificMetarSpecs.forEach(spec => {
                        spec.FluidVersionId = -spec.FluidVersionId;
                        spec.Id = -spec.Id;
                    });
                }
            });
        }
    }

    fluidSeasonsNotEnabled() {
        return this.fluidSeasons.filter(fs => !this.item.FluidVersions?.some(fv => fv.FluidSeasonId === fs.Id)) || [];
    }

    addFluidVersion(fluidSeasonId) {
        this.item.FluidVersions = this.item.FluidVersions || [];

        var maxOrder = Math.max(0, Math.max.apply(null, this.item.FluidVersions
            .map(h => h.Order)));

        var newFluidVersion: any = {
            Order: maxOrder + 1,
            Active: true,
            FluidSeasonId: fluidSeasonId,
        };

        var previousFluidVersion = this.item.FluidVersions
            .sort((fv1, fv2) => (fv1.Order < fv2.Order) ? 1 : -1)[0];

        if (previousFluidVersion != null) {
            newFluidVersion.FluidHotSpecs = previousFluidVersion.FluidHotSpecs.map(fs => {
                var fs2 = { ...fs };
                delete fs2.FluidVersionId;
                return fs2;
            });
            newFluidVersion.FluidMetarSpecs = previousFluidVersion.FluidMetarSpecs.map(fs => {
                var fs2 = { ...fs };
                delete fs2.FluidVersionId;
                return fs2;
            });
            newFluidVersion.FluidDegreeSpecificMetarSpecs = previousFluidVersion.FluidDegreeSpecificMetarSpecs.map(fs => {
                var fs2 = { ...fs };
                delete fs2.FluidVersionId;
                return fs2;
            });
        }

        this.item.FluidVersions.push(newFluidVersion);

        this.ngForm.form.markAsDirty();
    }

    addFluidHotSpec(fluidVersion) {
        fluidVersion.FluidHotSpecs = fluidVersion.FluidHotSpecs || [];
        var minTemp = 0;
        if (fluidVersion.FluidHotSpecs.length > 0) {
            minTemp = fluidVersion.FluidHotSpecs[fluidVersion.FluidHotSpecs.length - 1].MaxTemp;
        }
        fluidVersion.FluidHotSpecs.push({
            MinTemp: minTemp
        });
        this.ngForm.form.markAsDirty();
    }

    addFluidMetarSpec(fluidVersion) {
        fluidVersion.FluidMetarSpecs = fluidVersion.FluidMetarSpecs || [];
        var minTemp = 0;
        if (fluidVersion.FluidMetarSpecs.length > 0) {
            minTemp = fluidVersion.FluidMetarSpecs[fluidVersion.FluidMetarSpecs.length - 1].MaxTemp;
        }
        fluidVersion.FluidMetarSpecs.push({
            MinTemp: minTemp
        });
        this.ngForm.form.markAsDirty();
    }

    addFluidDegreeSpecificMetarSpec(fluidVersion) {
        fluidVersion.FluidDegreeSpecificMetarSpecs = fluidVersion.FluidDegreeSpecificMetarSpecs || [];
        var minTemp = 0;
        if (fluidVersion.FluidDegreeSpecificMetarSpecs.length > 0) {
            minTemp = fluidVersion.FluidDegreeSpecificMetarSpecs[fluidVersion.FluidDegreeSpecificMetarSpecs.length - 1].MaxTemp;
        }
        fluidVersion.FluidDegreeSpecificMetarSpecs.push({
            MinTemp: minTemp
        });
        this.ngForm.form.markAsDirty();
    }

    removeFluidVersion(fluidVersion) {
        this.item.FluidVersions.splice(this.item.FluidVersions.indexOf(fluidVersion), 1);
        this.ngForm.form.markAsDirty();
    }

    removeFluidHotSpec(fluidVersion, spec) {
        fluidVersion.FluidHotSpecs.splice(fluidVersion.FluidHotSpecs.indexOf(spec), 1);
        this.ngForm.form.markAsDirty();
    }

    removeFluidMetarSpec(fluidVersion, spec) {
        fluidVersion.FluidMetarSpecs.splice(fluidVersion.FluidMetarSpecs.indexOf(spec), 1);
        this.ngForm.form.markAsDirty();
    }

    removeFluidDegreeSpecificMetarSpec(fluidVersion, spec) {
        fluidVersion.FluidDegreeSpecificMetarSpecs.splice(fluidVersion.FluidDegreeSpecificMetarSpecs.indexOf(spec), 1);
        this.ngForm.form.markAsDirty();
    }

    isWingMaterialTypeEnabled(option) {
        var selected = (this.item.WingMaterialType || '').split(', ');
        return selected.indexOf(option) > -1;
    };

    toggleWingMaterialType(option) {
        var selected = this.item.WingMaterialType ? this.item.WingMaterialType.split(', ') : [];
        var index = selected.indexOf(option);
        if (index > -1) {
            selected.splice(index, 1);
        } else {
            selected.push(option);
        }
        this.item.WingMaterialType = selected.join(', ');
        this.ngForm.form.markAsDirty();
    };
}


