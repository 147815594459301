import {Pipe} from "@angular/core";
import {PipeTransform} from "@angular/core";
import {MetarWeatherTypeService} from "./MetarWeatherType.service";

@Pipe({
    name: 'metarWeatherTypeSort',
    pure: true
})
export class MetarWeatherTypeSort implements PipeTransform {

    constructor(private metarWeatherTypeService: MetarWeatherTypeService) {
    }

    transform(items, selector) {
        return this.metarWeatherTypeService.sort(items, selector);
    }
}
