import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class MetarWeatherTypeService {
    private readonly intensityFilterRegex = new RegExp('[^A-Z]', 'g');
    private readonly numericIntensityRegex = new RegExp('^[0-9]+');

    compare(mwt1, mwt2) {
        const plainWeatherCode1 = mwt1.replace(this.intensityFilterRegex, '');
        const plainWeatherCode2 = mwt2.replace(this.intensityFilterRegex, '');

        if (plainWeatherCode1 > plainWeatherCode2) {
            return 1;
        }
        else if (plainWeatherCode1 < plainWeatherCode2) {
            return -1;
        }

        // -light
        const lightIntensity1 = mwt1.length > 2 && mwt1[0] === '-';
        const lightIntensity2 = mwt2.length > 2 && mwt2[0] === '-';

        if (lightIntensity1 && !lightIntensity2) {
            return -1;
        }
        else if (!lightIntensity1 && lightIntensity2) {
            return 1;
        }

        // numeric intensity
        const numericMatch1 = this.numericIntensityRegex.exec(mwt1);
        const numericMatch2 = this.numericIntensityRegex.exec(mwt2);
        const numericIntensity1 = numericMatch1 !== null ? parseInt(numericMatch1[0]) : -1;
        const numericIntensity2 = numericMatch2 !== null ? parseInt(numericMatch2[0]) : -1;

        if (numericIntensity1 > numericIntensity2) {
            return 1;
        } else if (numericIntensity1 < numericIntensity2) {
            return -1;
        }

        // regular intensity code before other intensity qualifier (usually +)
        const isRegularIntensity1 = mwt1 === plainWeatherCode1;
        const isRegularIntensity2 = mwt2 === plainWeatherCode2;

        if (isRegularIntensity1 && !isRegularIntensity2) {
            return -1;
        }
        else if (!isRegularIntensity1 && isRegularIntensity2) {
            return 1;
        }

        // sort by plain weather code
        if (mwt1 > mwt2) {
            return 1;
        }
        else if (mwt1 < mwt2) {
            return -1;
        }
        else {
            return 0;
        }
    }

    sort(items, property) {
        items = items || [];

        return typeof property === 'function'
            ? items.sort((r1, r2) => this.compare(property(r1), property(r2)))
            : (typeof property === 'undefined' || property === null)
                ? items.sort((r1, r2) => this.compare(r1, r2))
                : items.sort((r1, r2) => this.compare(r1[property], r2[property]));
    }
}
