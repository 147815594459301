import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService } from '../shared/HasPermission.pipe';

@Component({
    templateUrl: 'SensorSelectMatrixDetail.component.html'
})
export class SensorSelectMatrixDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab;
    lweEquationProfiles = this.api.LWEEquationProfile.query();
    weatherTypeCountProfiles = this.api.WeatherTypeCountProfile.query();
    weatherTypeNgToCategoryMappingProfiles = this.api.WeatherTypeNgToCategoryMappingProfile.query();
    instantWeatherTypeAlgorithms = this.api.InstantWeatherTypeAlgorithm.query()
    instantWeatherTypeRuleProfiles = this.api.InstantWeatherTypeRuleProfile.query();
    thiesLPMWMOCodeMappingProfiles = this.api.ThiesLPMWMOCodeMappingProfile.query();
    weatherCategories = this.api.WeatherCategory.query();
    intensitySensorTypes = {
        "ThiesLPM": "Thies LPM",
        "ThiesLPMSecondary": "Thies LPM Secondary",
        "CampbellPWS100": "Campbell PWS100",
        "OTTPluvio2": "OTT Pluvio2",
        "OTTPluvio2DFAR": "OTT Pluvio2 DFAR",
        "OTTParsivel2": "OTT Parsivel2",
        "OTTParsivel2Secondary": "OTT Parsivel2 Secondary",
        "VaisalaPWD22": "Vaisala PWD22",
        "VaisalaPWD22Secondary": "Vaisala PWD22 Secondary",
        "VaisalaFD71P": "Vaisala FD71P",
        "PondHotPlate": "Pond Hot Plate",
        "GeonorT200DA": "Geonor T200DA",
    };
    weatherTypeSensorTypes = {
        "ThiesLPM": "Thies LPM",
        "ThiesLPMSecondary": "Thies LPM Secondary",
        "CampbellPWS100": "Campbell PWS100",
        "OTTParsivel2": "OTT Parsivel2",
        "OTTParsivel2Secondary": "OTT Parsivel2 Secondary",
        "VaisalaPWD22": "Vaisala PWD22",
        "VaisalaPWD22Secondary": "Vaisala PWD22 Secondary",
        "VaisalaFD71P": "Vaisala FD71P",
        "MiniOFS": "MiniOFS",
    };
    remoteEndpoints = this.api.RemoteEndpoint.query();
    weatherTypeMappingProfiles = this.api.WeatherTypeMappingProfile.query();
    groups = {
        Intensity: {},
        WMO: {},
        Wind: {},
        WindDirection: {},
        WindGustSpeed: {},
        WindGustDirection: {},
        Visibility: {},
        Temperature: {},
        Humidity: {},
        ActiveFrost: {}
    };
    sensorCombinationAggregations = {
        "Average": "Average",
        "Minimum": "Minimum",
        "Maximum": "Maximum",
        "DirectionAverage": "Direction Average",
    };
    stationType: string = 'Gen3';
    sensorType: any;
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
        
        this.returnPath = '/sensorSelectMatrices';
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';
        this.stationType = this.route.snapshot.data['stationType'];

        var resource = this.stationType == 'Simulation'
            ? this.api.SensorSelectMatrixSimulation
            : (this.stationType == 'Gen2'
                ? this.api.SensorSelectMatrixGen2
                : (this.stationType == 'Gen3'
                    ? this.api.SensorSelectMatrixGen3
                    : this.api.SensorSelectMatrixGenV));

        if (copyId) {
            this.item = resource.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.MiniOFSVisibilityWmoCodeMappings) {
                    this.item.MiniOFSVisibilityWmoCodeMappings.forEach(related => {
                        delete related.SensorSelectMatrixId;
                    });
                }

                if (this.item.SensorSelectMatrixIntensitySensorMappings) {
                    this.item.SensorSelectMatrixIntensitySensorMappings.forEach(related => {
                        delete related.SensorSelectMatrixId;
                    });
                }

                if (this.item.SensorSelectMatrixWeatherTypeSensorMappings) {
                    this.item.SensorSelectMatrixWeatherTypeSensorMappings.forEach(related => {
                        delete related.SensorSelectMatrixId;
                    });
                }
            });
        } else if (isNew) {
            this.item = resource.create({
                Active: true,

                OverrideWeatherCategoryConfiguration: {},

                IntensitySartorius: {},
                IntensityThiesLPM: {},
                IntensityThiesLPMSecondary: {},
                IntensityCampbellPWS100: {},
                LongAverageIntensityThiesLPM: {},
                LongAverageIntensityCampbellPWS100: {},
                IntensityOTTParsivel2: {},
                IntensityOTTParsivel2Secondary: {},
                IntensityVaisalaPWD22: {},
                IntensityVaisalaPWD22Secondary: {},
                IntensityVaisalaFD71P: {},
                IntensityPondHotPlate: {},

                IntensityOTTPluvio2: {},
                IntensityOTTPluvio2DFAR: {},
                IntensityGeonorT200DA: {},
                IntensityGeonorT200DFAR: {},
                
                NCARIntensityOTTPluvio2: {},
                NCARIntensityOTTPluvio2DFAR: {},
                NCARIntensityGeonorT200DA: {},
                NCARIntensityGeonorT200DFAR: {},

                WindVaisalaWS425: {},
                WindDirectionVaisalaWS425: {},
                WindThies2DCompact: {},
                WindDirectionThies2DCompact: {},
                WindThiesWP: {},
                WindDirectionThiesWP: {},
                WindGustSpeedThiesWP: {},
                WindGustDirectionThiesWP: {},
                WindVaisalaWMT700: {},
                WindDirectionVaisalaWMT700: {},

                VisibilityOTTParsivel2: {},
                VisibilityVaisalaFD71P: {},
                VisibilityCampbellPWS100: {},
                VisibilityMiniOFS: {},
                VisibilityBiralSWS200: {},
                VisibilityVaisalaFD12P: {},

                TemperatureVaisalaTempProbe: {},
                TemperatureVaisalaFD12P: {},
                TemperatureThiesTempProbe: {},
                TemperatureThiesLPM: {},
                TemperatureThies2DCompact: {},
                TemperatureCampbellPWS100: {},
                TemperatureBiralSWS200: {},
                TemperatureDigitalTempProbe: {},
                TemperatureVaisalaPWD22: {},

                HumidityVaisalaTempProbe: {},
                HumidityThiesTempProbe: {},
                HumidityCampbellPWS100: {},
                HumidityDigitalTempProbe: {},

                WMOVaisalaFD12P15Minute: {},
                WMOVaisalaFD12P: {},
                WMOThiesLPM: {},
                WMOCampbellPWS100: {},
                WMOThiesLPMSecondary: {},
                WMOOTTParsivel2: {},
                WMOOTTParsivel2Secondary: {},
                WMOVaisalaPWD22: {},
                WMOVaisalaPWD22Secondary: {},
                WMOVaisalaFD71P: {},
                WMOMiniOFS: {},

                ActiveFrost: {},
            });
        } else {
            this.item = resource.get({ id: id });
        }
        
        if (this.item.$promise) {
            this.item.$promise.then(_ => {
                this.item.VisibilityWmoCodeMappings = this.item.VisibilityWmoCodeMappings || [];

                this.item.VisibilityWmoCodeMappings = this.item.VisibilityWmoCodeMappings.sort((r1, r2) => {
                    return r1.MaxVisibility > r2.MaxVisibility
                        ? 1
                        : (r1.MaxVisibility < r2.MaxVisibility)
                            ? -1
                            : 0;
                });
                
                this.item.SensorSelectMatrixIntensitySensorMappings = this.item.SensorSelectMatrixIntensitySensorMappings || [];

                this.item.SensorSelectMatrixIntensitySensorMappings = this.item.SensorSelectMatrixIntensitySensorMappings.sort((r1, r2) => {
                    return r1.Order > r2.Order
                        ? 1
                        : (r1.Order < r2.Order)
                            ? -1
                            : 0;
                });

                this.item.SensorSelectMatrixWeatherTypeSensorMappings = this.item.SensorSelectMatrixWeatherTypeSensorMappings || [];

                this.item.SensorSelectMatrixWeatherTypeSensorMappings = this.item.SensorSelectMatrixWeatherTypeSensorMappings.sort((r1, r2) => {
                    return r1.Order > r2.Order
                        ? 1
                        : (r1.Order < r2.Order)
                            ? -1
                            : 0;
                });
            });
        }
    }
    
    save() {
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['SensorSelectMatrix' + this.stationType],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addMiniOFSVisibilityWmoCodeMapping() {
        this.item.MiniOFSVisibilityWmoCodeMappings = this.item.MiniOFSVisibilityWmoCodeMappings || [];
        this.item.MiniOFSVisibilityWmoCodeMappings.push({});
        this.ngForm.form.markAsDirty();
    }

    removeMiniOFSVisibilityWmoCodeMapping(mapping) {
        this.item.MiniOFSVisibilityWmoCodeMappings.splice(this.item.MiniOFSVisibilityWmoCodeMappings.indexOf(mapping), 1);
        this.ngForm.form.markAsDirty();
    }

    addSensorSelectMatrixIntensitySensorMapping() {
        this.item.SensorSelectMatrixIntensitySensorMappings = this.item.SensorSelectMatrixIntensitySensorMappings || [];
        var maxOrder = Math.max.apply(null, this.item.SensorSelectMatrixIntensitySensorMappings
            .map(h => h.Order));
        this.item.SensorSelectMatrixIntensitySensorMappings.push({
            Order: Math.max(1, maxOrder + 1),
            SnowLWEEndpointId: null,
            SLDLWEEndpointId: null,
            LocalLWEEquationProfileId: null,
        });
        this.ngForm.form.markAsDirty();
    }

    removeSensorSelectMatrixIntensitySensorMapping(mapping) {
        this.item.SensorSelectMatrixIntensitySensorMappings.splice(this.item.SensorSelectMatrixIntensitySensorMappings.indexOf(mapping), 1);
        this.ngForm.form.markAsDirty();
    }

    addSensorSelectMatrixWeatherTypeSensorMapping() {
        this.item.SensorSelectMatrixWeatherTypeSensorMappings = this.item.SensorSelectMatrixWeatherTypeSensorMappings || [];
        var maxOrder = Math.max.apply(null, this.item.SensorSelectMatrixWeatherTypeSensorMappings
            .map(h => h.Order));
        this.item.SensorSelectMatrixWeatherTypeSensorMappings.push({
            Order: Math.max(1, maxOrder + 1),
            WeatherCategoryEndpointId: null,
            InstantWeatherTypeAlgorithm: null,
        });
        this.ngForm.form.markAsDirty();
    }

    removeSensorSelectMatrixWeatherTypeSensorMapping(mapping) {
        this.item.SensorSelectMatrixWeatherTypeSensorMappings.splice(this.item.SensorSelectMatrixWeatherTypeSensorMappings.indexOf(mapping), 1);
        this.ngForm.form.markAsDirty();
    }
}
