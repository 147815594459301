<div class="header">
	<button class="toggleMenu" (click)="toggleMenu()"><span class="mdi mdi-menu"></span></button>
	<div class="userLogo" *ngIf="logo != null" [style.background-image]="'url(' + logo + ')'"></div>
	<h1 #title></h1>
    <div [ngClass]="{environment: environment.Id != null, notProd: environment.Id !== 'prod' }">{{environment.Id}}</div>
	<div class="logo" [title]="'SureWx'|translate"></div>
	<div class="currentUser" *ngIf="currentUser">
		<div>{{currentUser.Name}}</div>
		<a (click)="logout()">{{'Log out'|translate}}</a>
        | <a routerLink="/userProfile"><span>{{'Settings'|translate}}</span></a>
	</div>
    <busy-indicator></busy-indicator>
</div>
<div class="app-content">
    <div class="menuContainer" #menuContainer>
        <div menu *ngIf="currentUser"></div>
    </div>
    <div class="main">
        <router-outlet></router-outlet>
    </div>
</div>
