import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import { UntypedFormGroup } from "@angular/forms";
import { FileDownloadService } from "swx.front-end-lib";

@Component({
    templateUrl: 'Gen3Validation.component.html'
})
export class Gen3ValidationComponent implements OnInit {
    vm: any;

    constructor(private api: ApiService, private fileDownload: FileDownloadService) {
    }

    ngOnInit() {
        Promise.all([
            this.api.Fluid.query().$promise,
            this.api.LWEMessageMappingProfile.query().$promise,
        ]).then(([fluids, lweMessageMappingProfiles]) => {
            this.vm = {
                fluids: fluids,
                lweMessageMappingProfiles: lweMessageMappingProfiles
            };
        });
    }

    validate(form: UntypedFormGroup) {
        const f = form.value;

        this.api.Gen3Validation.exportPost({
            FluidId: f.fluidId,
            LWEMessageMappingProfileId: f.lweMessageMappingProfileId,
            Data: f.data,
        }).then(response => {
            this.fileDownload.download(response.body, (header) => response.headers.get(header));
        });
    }
}
