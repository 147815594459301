<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('IMAP configuration'|translate) + ': ' + (item.Name || 'new')}}</page-title>
    <div id="userEditPage" class="noMargins">
        <div class="toolbar">
            <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        </div>

        <fieldset class="contentMargins form" [disabled]="!('ConfigEditIMAPServerConfigurations'|hasPermission)" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="Name" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="Active" />
            </label>
            <label>
                <span class="caption">{{'Type'|translate}}</span>
                <select [(ngModel)]="item.Type" name="Type">
                    <option *ngFor="let item of configurationTypes|keyvalue" [value]="item.key">{{item.value}}</option>
                </select>
            </label>
            <label>
                <span class="caption">{{'After download'|translate}}</span>
                <select [(ngModel)]="item.ProcessOption" name="ProcessOption">
                    <option *ngFor="let item of processOptions|keyvalue" [value]="item.key">{{item.value}}</option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Hostname'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Hostname" name="Hostname" />
            </label>
            <label>
                <span class="caption">{{'Port'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Port" name="Port" />
            </label>
            <label>
                <span class="caption">{{'Encryption'|translate}}</span>
                <select [(ngModel)]="item.Encryption" name="Encryption">
                    <option *ngFor="let item of secureSocketOptions|keyvalue" [value]="item.key">{{item.value}}</option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Use Modern Authentication?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.UseModernAuthentication" name="UseModernAuthentication" />
            </label>
            <label>
                <span class="caption">{{'Username'|translate}}</span>
                <input type="text" [(ngModel)]="item.Username" name="Username" />
            </label>
            <label>
                <span class="caption">{{'Password'|translate}}</span>
                <input type="text" [(ngModel)]="item.Password" name="Password" />
            </label>
            <label>
                <span class="caption">{{'OAuth2 Client ID'|translate}}</span>
                <input type="text" [(ngModel)]="item.OAuth2ClientId" name="OAuth2ClientId" />
            </label>
        </fieldset>
    </div>
</form>

