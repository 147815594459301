<page-title>{{('Basic METAR settings'|translate)}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" class="green" value="{{'Save'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" value="{{'View history'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('METARSettings'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Source'|translate}}</span>
            <select required [(ngModel)]="settings.SourceId" name="settings.SourceId">
                <option *ngFor="let option of metarSources|keys" [ngValue]="option">
                    {{metarSources[option]}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Frequency'|translate}}</span>
            <input type="number" step="1" [(ngModel)]="settings.Frequency" name="settings.Frequency" min="1" required />
            <span>{{'Minutes'|translate}}</span>
        </label>
        <label>
            <span class="caption">{{'Max. METAR reading age'|translate}}</span>
            <input type="number" step="1" [(ngModel)]="settings.MaxMetarReadingAge" name="settings.MaxMetarReadingAge" min="1" required />
            <span>{{'Minutes'|translate}}</span>
        </label>
        <label>
            <span class="caption">{{'Max. active frost frost point'|translate}}</span>
            <input type="number" step="any" [(ngModel)]="settings.MaxActiveFrostFrostPoint" name="settings.MaxActiveFrostFrostPoint" required />
            <span>{{'C'|translate}}</span>
        </label>
        <label>
            <span class="caption">{{'Max. active frost wind speed'|translate}}</span>
            <input type="number" step="any" [(ngModel)]="settings.MaxActiveFrostWindSpeed" name="settings.MaxActiveFrostWindSpeed" required />
            <span>{{'KT'|translate}}</span>
        </label>
        <label>
            <span class="caption">{{'Max. active frost temp and frost point difference'|translate}}</span>
            <input type="number" step="any" [(ngModel)]="settings.MaxActiveFrostTempFrostPointDifference" name="settings.MaxActiveFrostTempFrostPointDifference" min="0" required />
            <span>{{'C'|translate}}</span>
        </label>
        <label>
            <span class="caption">{{'Activate Active Frost'|translate}}</span>
            <input type="number" style="width: 40px;" [(ngModel)]="settings.ActiveFrostMinutesBeforeSunset" name="settings.ActiveFrostMinutesBeforeSunset" min="0" step="1" />
            <span>{{' Minutes before sunset'|translate}}</span>
        </label>
        <label>
            <span class="caption">{{'Deactivate Active Frost'|translate}}</span>
            <input type="number" style="width: 40px;" [(ngModel)]="settings.ActiveFrostMinutesAfterSunrise" name="settings.ActiveFrostMinutesAfterSunrise" min="0" step="1" />
            <span>{{' Minutes after sunrise'|translate}}</span>
        </label>
        <label>
            <span class="caption">{{'Max. METAR temp for ticket creation for missing mapping'|translate}}</span>
            <input type="number" step="any" [(ngModel)]="settings.MaxMetarTempForTicketCreationForMissingMapping" name="settings.MaxMetarTempForTicketCreationForMissingMapping" required />
            <span>{{'C'|translate}}</span>
        </label>
    </fieldset>
</form>
