<div id="hotConditionList" class="noMargins">
    <page-title>{{('HOT conditions for dispatch portal'|translate)}}</page-title>
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="grid.searchQuery" name="searchQuery" />
        </label>
        <label class="inline">
            <input type="checkbox" [(ngModel)]="grid.includeInactive" name="includeInactive" />
            <span class="caption">{{'Include inactive'|translate}}</span>
        </label>
        <button *ngIf="'ConfigAddHotConditions'|hasPermission" routerLink="/hotConditions/new" type="button" class="green">{{'Add HOT condition'|translate}}</button>
    </form>

    <config-grid #grid
                 gridId="preferences.HotConditions.ColumnExport"
                 [query]="query"
                 [resource]="api.HotCondition"
                 [columnDefs]="columnDefs">
    </config-grid>
</div>
