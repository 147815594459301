import { Component, OnInit } from "@angular/core";
import { StorageService } from "swx.front-end-lib";
import { ApiService } from "../shared/Api.service";

@Component({
    templateUrl: './HotRequest.component.html'
})
export class HotRequestComponent implements OnInit {
    storageKey = 'debugHotRequestRequest';
    request: any;
    result: any;
    datePickerOptions: any;

    constructor(private api: ApiService, private storage: StorageService) {
    }

    ngOnInit() {
        let request = this.storage.load(this.storageKey);

        if (request) {
            request = {...request, version: request.version || 1};
        }
        else {
            request = {
                count: 1,
                interval: 1,
                equipmentTypeCode: '',
                tailNumber: ''
            };
        }

        let maxDateTime = new Date();
        maxDateTime.setDate(maxDateTime.getDate() + 1);
        this.datePickerOptions = {
            showTime: true,
            timezone: 0,
            showTimezone: true,
            maxDateTime: maxDateTime,
            dateFormat: 'yy-mm-dd',
            timeFormat: 'HH:mm:ss',
            useUtc: true,
        };

        this.request = request;
    }

    send() {
        this.storage.save(this.storageKey, this.request);

        this.api.DebugHotRequest.query(this.request).$promise.then(result => {
            this.result = result;
        });
    }
}
