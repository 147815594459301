<page-title>{{('Mobile weather menu'|translate) + ': ' + (item.Name || 'new')}}</page-title>
<div class="noMargins">
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('items')" [class.active]="tab == 'items'" [class.invalid]="!ngForm.form.controls.itemsForm?.valid">{{'Items'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" (click)="save()" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>
    
    <form #ngForm="ngForm" (submit)="save()" class="form">
        <fieldset class="contentMargins" [disabled]="!('ConfigEditMobileWeatherMappingProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="item.Name" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
            </label>
            <label>
                <span class="caption">{{'Snow visibility menu JSON (for apps < V 4.11.0)'|translate}}</span>
                <textarea [(ngModel)]="item.LegacySnowVisibilityMenuJson" name="item.LegacySnowVisibilityMenuJson"></textarea>
            </label>
        </fieldset>

        <fieldset class="contentMargins" [disabled]="!('ConfigEditMobileWeatherMappingProfiles'|hasPermission)" [hidden]="tab != 'items'" ngModelGroup="itemsForm">
            <table class="table">
                <tr>
                    <th>{{'Name'|translate}}</th>
                    <th>{{'Order'|translate}}</th>
                    <th>{{'METAR weather type'|translate}}</th>
                    <th>{{'Visibilities menu'|translate}}</th>
                    <th *ngIf="item.LegacySnowVisibilityMenuJson">{{'Custom name (for apps < V 4.10.0)'|translate}}</th>
                    <th></th>
                </tr>
                <ng-container *ngFor="let mobileWeatherTypeMapping of item.MobileWeatherTypeMappings; let i = index">
                    <tr>
                        <td>
                            <input type="text" [(ngModel)]="mobileWeatherTypeMapping.Name" [name]="'mobileWeatherTypeMapping[' + i +'].Name'" required style="width: 300px;" />
                        </td>
                        <td>
                            <input type="number" step="1" min="0" [(ngModel)]="mobileWeatherTypeMapping.Order" [name]="'mobileWeatherTypeMapping[' + i +'].Order'" required style="width: 60px;" />
                        </td>
                        <td>
                            <select [(ngModel)]="mobileWeatherTypeMapping.MetarWeatherType" [name]="'mobileWeatherTypeMapping[' + i +'].MetarWeatherType'" [selectedIndex]="-1">
                                <option [ngValue]="null">{{'Use visibilities menu or submenu'|translate}}</option>
                                <option *ngFor="let option of metarWeatherTypes|keys" [ngValue]="option">
                                    {{metarWeatherTypes[option]}}
                                </option>
                            </select>
                        </td>
                        <td>
                            <select *ngIf="mobileWeatherTypeMapping.MetarWeatherType == null" [(ngModel)]="mobileWeatherTypeMapping.MobileSnowVisibilityMenu" [name]="'mobileWeatherTypeMapping[' + i +'].MobileSnowVisibilityMenu'" [selectedIndex]="-1">
                                <option [ngValue]="null">{{'Use submenu'|translate}}</option>
                                <option *ngFor="let option of mobileSnowVisibilityMenus|keys" [ngValue]="option">
                                    {{mobileSnowVisibilityMenus[option]}}
                                </option>
                            </select>
                        </td>
                        <td *ngIf="item.LegacySnowVisibilityMenuJson">
                            <input type="text" [(ngModel)]="mobileWeatherTypeMapping.MobileCustomWeatherTypeName" [name]="'mobileWeatherTypeMapping[' + i +'].MobileCustomWeatherTypeName'" style="width: 200px;" />
                        </td>
                        <td [attr.rowspan]="mobileWeatherTypeMapping.MetarWeatherType == null && mobileWeatherTypeMapping.MobileSnowVisibilityMenu == null ? 2 : 1">
                            <a *ngIf="('ConfigDeleteMobileWeatherMappingProfiles'|hasPermission)" (click)="removeMobileWeatherTypeMapping(mobileWeatherTypeMapping)" class="mdi mdi-delete"></a>
                        </td>
                    </tr>
                    <tr *ngIf="mobileWeatherTypeMapping.MetarWeatherType == null && mobileWeatherTypeMapping.MobileSnowVisibilityMenu == null">
                        <td colspan="5" style="padding-left: 70px;">
                            <table class="table">
                                <tr>
                                    <th>{{'Name'|translate}}</th>
                                    <th>{{'Order'|translate}}</th>
                                    <th>{{'METAR weather type'|translate}}</th>
                                    <th></th>
                                </tr>
                                <ng-container *ngFor="let mobileWeatherTypeSubMapping of mobileWeatherTypeMapping.MobileWeatherTypeSubMappings; let j = index">
                                    <tr>
                                        <td>
                                            <input type="text" [(ngModel)]="mobileWeatherTypeSubMapping.Name" [name]="'mobileWeatherTypeSubMapping[' + i + '_' + j + '].Name'" required style="width: 300px;" />
                                        </td>
                                        <td>
                                            <input type="number" step="1" min="0" [(ngModel)]="mobileWeatherTypeSubMapping.Order" [name]="'mobileWeatherTypeSubMapping[' + i + '_' + j + '].Order'" required style="width: 60px;" />
                                        </td>
                                        <td>
                                            <select [(ngModel)]="mobileWeatherTypeSubMapping.MetarWeatherType" [name]="'mobileWeatherTypeSubMapping[' + i + '_' + j + '].MetarWeatherType'" required [selectedIndex]="-1">
                                                <option *ngFor="let option of metarWeatherTypes|keys" [ngValue]="option">
                                                    {{metarWeatherTypes[option]}}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <a *ngIf="('ConfigDeleteMobileWeatherMappingProfiles'|hasPermission)" (click)="removeMobileWeatherTypeSubMapping(mobileWeatherTypeMapping, mobileWeatherTypeSubMapping)" class="mdi mdi-delete"></a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </table>
                            <br />
                            <input type="button" class="green" *ngIf="('ConfigAddMobileWeatherMappingProfiles'|hasPermission)" (click)="addMobileWeatherTypeSubMapping(mobileWeatherTypeMapping)" value="{{'Add item'|translate}}" />
                        </td>
                    </tr>
                </ng-container>
            </table>
            <br />
            <input type="button" class="green" *ngIf="('ConfigAddMobileWeatherMappingProfiles'|hasPermission)" (click)="addMobileWeatherTypeMapping()" value="{{'Add item'|translate}}" />
        </fieldset>
    </form>
</div>
