import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import {ConfigGridComponent, NotificationService, TranslateService} from "swx.front-end-lib";

@Component({
    templateUrl: 'MetarWeatherCodeRemappingProfileDetail.component.html'
})
export class MetarWeatherCodeRemappingProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    @ViewChild('mappingsGrid', {static: true}) mappingsGrid: ConfigGridComponent;
    tab;
    metarWeatherTypes = this.api.MetarWeatherType.query();
    areWeatherCodeRemappingsValid = true;
    columnDefs = [
        { colId: "Id", field: "Id", headerName: "#", width: 60, pinned: "left", filterType: 'integer', cellClass: "select", cellTemplate: "<div [class.invalid]=\"!params.data._valid\">{{params.data.Id || 'new'}}</div>" },
        { colId: "OriginalWeatherCode", field: "OriginalWeatherCode", headerName: "Original weather code (regex)", width: 150, cellClass: "select", cellTemplate: `<input  type="text" required [(ngModel)]="params.data.OriginalWeatherCode" [name]="'params.data[' + params.node.id + '].OriginalWeatherCode'" style="width: 99%;" (change)='parent.checkDuplicateRemappings(params.data)' />` },
        { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport ICAO code (regex)", width: 150, cellClass: "select", cellTemplate: `<input  type="text" required [(ngModel)]="params.data.AirportICAOCode" [name]="'params.data[' + params.node.id + '].AirportICAOCode'" style="width: 99%;" (change)='parent.checkDuplicateRemappings(params.data)' />` },
        { colId: "Remarks", field: "Remarks", headerName: "Remarks (regex)", width: 350, cellClass: "select", cellTemplate: `<input  type="text" [(ngModel)]="params.data.Remarks" [name]="'params.data[' + params.node.id + '].Remarks'" style="width: 99%;" (change)='parent.checkDuplicateRemappings(params.data)' />` },
        { colId: "FinalWeatherCode", field: "FinalWeatherCode", headerName: "Final code", width: 100, cellClass: "select", cellTemplate: `<input  type="text" required [(ngModel)]="params.data.FinalWeatherCode" [name]="'params.data[' + params.node.id + '].FinalWeatherCode'" style="width: 99%;" />` },
        { colId: "Actions", field: "Id", headerName: "", width: 80, pinned: 'right', "excelIgnore": true, cellClass: "actions", cellTemplate: `<a *ngIf="!params.data.IsUsed && parent.hasPermissionService.hasPermission('ConfigEditMETARWeatherCodeRemappingProfiles')" (click)="parent.removeCodeRemapping(params.data)" class="mdi mdi-delete" title="{{'Remove'|translate}}"></a>` },
    ];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private notification: NotificationService,
        private translateService: TranslateService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.MetarWeatherCodeRemappingProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.MetarWeatherCodeRemappings) {
                    this.item.MetarWeatherCodeRemappings.forEach(related => {
                        delete related.MetarWeatherCodeRemappingProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.MetarWeatherCodeRemappingProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.MetarWeatherCodeRemappingProfile.get({ id: id });
        }

        this.item.$promise.then(item => {
            this.item.MetarWeatherCodeRemappings = this.item.MetarWeatherCodeRemappings || [];
            item.MetarWeatherCodeRemappings.forEach(row => this.checkDuplicateRemappings(row));
        });
    }

    save() {
        this.item.$promise.then(_ => {
            this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['MetarWeatherCodeRemappingProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addCodeRemapping() {
        this.item.MetarWeatherCodeRemappings.unshift({
            WeatherCode: ''
        });
        this.mappingsGrid.gridApi.applyTransaction({ addIndex: 0, add: this.mappingsGrid.rowData.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    };

    removeCodeRemapping(mapping) {
        if (confirm(this.translateService.translate('Are you sure?'))) {
            this.item.MetarWeatherCodeRemappings.splice(this.item.MetarWeatherCodeRemappings.indexOf(mapping), 1);
            this.mappingsGrid.updateFilteredRows();
            this.ngForm.form.updateValueAndValidity();
            this.ngForm.form.markAsDirty();
        }
    };

    checkDuplicateRemappings(row) {
        var duplicateWeatherCodeRemapping = (this.item.MetarWeatherCodeRemappings || []).find(weatherCodeRemapping => {
            weatherCodeRemapping._valid = true;
            return row !== weatherCodeRemapping
                && (row.OriginalWeatherCode === weatherCodeRemapping.OriginalWeatherCode)
                && (row.AirportICAOCode && row.AirportICAOCode === weatherCodeRemapping.AirportICAOCode)
                && (row.Remarks && row.Remarks === weatherCodeRemapping.Remarks);
        });

        this.areWeatherCodeRemappingsValid = typeof duplicateWeatherCodeRemapping === 'undefined';

        if (!this.areWeatherCodeRemappingsValid) {
            duplicateWeatherCodeRemapping._valid = false;
            this.notification.show(this.translateService.translate("Duplicate RegEx remapping (#" + (duplicateWeatherCodeRemapping.Id || 'new') + ": " + duplicateWeatherCodeRemapping.OriginalWeatherCode + " " + duplicateWeatherCodeRemapping.AirportICAOCode + " " + duplicateWeatherCodeRemapping.Remarks + " = " + duplicateWeatherCodeRemapping.FinalWeatherCode + ")") , { type: "error", sticky: true });
        }
    }
}
