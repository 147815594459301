<page-title>{{('Portal users'|translate) + ': ' + client.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('portalUsers')" [class.active]="tab == 'portalUsers'" [class.invalid]="!ngForm.form.controls.portalUsersForm?.valid">{{'Portal users'|translate}}</a>
        <label class="inline">
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="grid.searchQuery" name="searchQuery" />
        </label>
        <label class="inline">
            <input type="checkbox" [(ngModel)]="grid.includeInactive" name="includeInactive" />
            <span class="caption">{{'Include inactive'|translate}}</span>
        </label>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" class="green" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        <button *ngIf="('ConfigAddClientsPortal'|hasPermission) && tab == 'portalUsers'" type="button" (click)="addPortalUser()" class="green">{{'Add portal user'|translate}}</button>
        <button type="button" [disabled]="!ngForm.form.valid" (click)="exportPortalUsers()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
    </div>

    <config-grid #grid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'portalUsers'"
                 ngModelGroup="portalUsersForm"
                 #portalUsersForm="ngModelGroup"
                 [allRows]="item.PortalUsers"
                 [headerHeight]="75"
                 [columnDefs]="columnDefs">
    </config-grid>
</form>
