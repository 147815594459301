import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root'})
export class BusyIndicatorService {
    busy: boolean;
    openRequests = 0;

    setBusy(busy) {
        if (busy) {
            this.openRequests++;
        } else {
            this.openRequests--;
        }
        if (this.openRequests < 1) {
            this.busy = false;
            this.openRequests = 0;
        } else {
            this.busy = true;
        }
    }
}
