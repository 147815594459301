<page-title>{{('Thies LPM WMO code remapping profile'|translate) + ': ' + (item.Name || 'new')}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="form noMargins">
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" *ngIf="'ConfigEditThiesLPMWMOCodeMappingProfiles'|hasPermission" (click)="switchTab('mappings')" [ngClass]="{active : tab == 'mappings', invalid: !ngForm.form.controls.mappingsForm?.valid}">{{'Mappings'}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        <button type="button" *ngIf="tab == 'mappings' && 'ConfigEditThiesLPMWMOCodeMappingProfiles'|hasPermission" (click)="addMapping()" class="button green">{{'Add mapping'|translate}}</button>
    </div>

    <fieldset class="contentMargins" [disabled]="!('ConfigEditThiesLPMWMOCodeMappingProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
    </fieldset>

    <config-grid #mappingsGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'mappings'"
                 ngModelGroup="mappingsForm"
                 [allRows]="item.ThiesLPMWMOCodeMappings"
                 [includeInactive]="true"
                 [headerHeight]="75"
                 [columnDefs]="columnDefs">
    </config-grid>
</form>
