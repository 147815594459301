<div class="noMargins">
    <page-title>{{('METAR RegEx remappings'|translate)}}</page-title>
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="grid.searchQuery" name="searchQuery" />
        </label>
        <label class="inline">
            <input type="checkbox" [(ngModel)]="grid.includeInactive" name="includeInactive" />
            <span class="caption">{{'Include inactive'|translate}}</span>
        </label>
        <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
        <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button type="button" (click)="refresh()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid"><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid"><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
        <button *ngIf="'ConfigEditMETARWeatherCodeRemappingProfiles'|hasPermission" routerLink="/metarWeatherCodeRemappingProfiles/new" class="green">{{'Add profile'|translate}}</button>
    </form>

    <config-grid #grid
                 gridId="preferences.MetarWeatherCodeRemappingProfile.ColumnExport"
                 [query]="query"
                 [resource]="api.MetarWeatherCodeRemappingProfile"
                 [exportResource]="api.MetarWeatherCodeRemappingProfile"
                 [exportGet]="true"
                 [columnDefs]="columnDefs">
    </config-grid>
</div>
