import { Injectable } from '@angular/core'
import { EnvironmentConfiguration } from "./EnvironmentConfiguration.model";

@Injectable({ providedIn: 'root'})
export class GoogleMapsLoaderService {
	gmapsPromise = null;

    constructor(
        private environment: EnvironmentConfiguration,
    ) {
    }

	private requireJS(js) {
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = js;
		script.async = true;
		document.getElementsByTagName("head")[0].appendChild(script);
	}

	load(): Promise<any> {
		if (this.gmapsPromise === null) {
			this.gmapsPromise = new Promise((resolve, reject) => {
				var startFunctionName = 'startMap' + Math.random().toString(36).substr(2, 9);
				window[startFunctionName] = function() {
					var maps = window['google']['maps'];

					if (typeof window['MarkerLabel'] === 'undefined') {
						window['MarkerLabel'] = function(options) {
							this.setValues(options);
							this.span = document.createElement('span');
							this.span.className = 'map-marker-label';
						};
						var MarkerLabel = window['MarkerLabel'];

						maps.Marker.prototype._setMap = maps.Marker.prototype.setMap;
						maps.Marker.prototype.setLabel = function(label) {
							this.label = new MarkerLabel({
								map: this.map,
								marker: this,
								text: label
							});
							this.label.bindTo('position', this, 'position');
						};
						maps.Marker.prototype.setMap = function(map) {
							if (this.label) this.label.setMap(map);
							this._setMap(map);
						};

						MarkerLabel.prototype = $.extend(new maps.OverlayView(),
							{
								onAdd: function() {
									this.getPanes().overlayImage.appendChild(this.span);
									var self = this;
									this.listeners = [
										maps.event.addListener(this, 'position_changed', () => self.draw())
									];
								},
								draw: function() {
									var position = this.getProjection().fromLatLngToDivPixel(this.get('position'));
									var icon = this.get('marker').get('icon');
									this.span.innerHTML = String(this.get('text'));
									this.span.style.left =
										(position.x +
											icon.size.width / 2 -
											icon.anchor.x / 2 -
											$(this.span).width() / 2) +
										'px';
									this.span.style.top = (position.y + icon.size.height - icon.anchor.y + 1) + 'px';
								},
								onRemove: function() {
									this.span.parentNode.removeChild(this.span);
									this.span = null;
								}
							});
					}

					resolve(maps);
				};

				var src = document.location.protocol +
					'//maps.googleapis.com/maps/api/js?language=en&callback=' +
					startFunctionName +
					'&key=' +
					this.environment.googleMapsApiKey;
				this.requireJS(src);
			});
		}

		return this.gmapsPromise;
	}
}
