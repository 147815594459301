/// <reference path="../../../node_modules/@types/google.visualization/index.d.ts"/>

import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GoogleChartsService {
    constructor() {
        google.charts.load('50', { packages: ['corechart'] });
    }

    async loadGoogleCharts(): Promise<void> {
        return new Promise((resolve) => {
            google.charts.setOnLoadCallback(() => {
                resolve(undefined);
            });
        });
    }
}
