<page-title>{{('Active frost profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('airports')" [class.active]="tab == 'airports'" [class.invalid]="!ngForm.form.controls.airportsForm?.valid">{{'Airports'|translate}}</a>
        <a class="tab" (click)="switchTab('emails')" [class.active]="tab == 'emails'" [class.invalid]="!ngForm.form.controls.emailsForm?.valid">{{'Emails'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditActiveFrostProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
        <label>
            <span class="caption">{{'Client'|translate}}</span>
            <select [(ngModel)]="item.ClientId" name="item.ClientId" (ngModelChange)="updateAvailableAirports()">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let client of clients|orderBy:'Name'" [ngValue]="client.Id">
                    {{client.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Email sender'|translate}}</span>
            <select required [(ngModel)]="item.EmailFromId" name="item.EmailFromId">
                <option *ngFor="let emailFrom of emailFroms|orderBy:'Id'" [ngValue]="emailFrom.Id">
                    {{emailFrom.EmailAddress}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Use only Gen 3 sensor select matrix A'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseOnlyGen3SSMA" name="item.UseOnlyGen3SSMA" />
        </label>
        <br />
        
        <table class="table">
            <tr>
                <td>&nbsp;</td>
                <th style="text-align: center">{{'WARNINGS'|translate}}</th>
                <th style="text-align: center">{{'ALARMS'|translate}}</th>
            </tr>
            <tr>
                <th style="text-align: center" colspan="3">{{'Entering'|translate}}</th>
            </tr>
            <tr>
                <td>{{'Weather Type'|translate}}</td>
                <td>
                    <multiselect [all]="false" [none]="'Any'" [format]="'string'" [(ngModel)]="item.EnteringWeatherTypeWarningStr" [name]="'item.EnteringWeatherTypeWarningStr'">
                        <multiselect-option *ngFor="let option of weatherTypes|keys" [value]="option" [name]="weatherTypes[option]"></multiselect-option>
                    </multiselect>
                </td>
                <td>
                    <multiselect [all]="false" [none]="'Any'" [format]="'string'" [(ngModel)]="item.EnteringWeatherTypeAlarmStr" [name]="'item.EnteringWeatherTypeAlarmStr'">
                        <multiselect-option *ngFor="let option of weatherTypes|keys" [value]="option" [name]="weatherTypes[option]"></multiselect-option>
                    </multiselect>
                </td>
            </tr>
            <tr>
                <td>{{'During Weather Time (in minutes)'|translate}}</td>
                <td><input placeholder="0" [(ngModel)]="item.EnteringWeatherTimeWarning" name="item.EnteringWeatherTimeWarning" type="number" min="0" step="any" style="width: 60px;" /></td>
                <td><input placeholder="0" [(ngModel)]="item.EnteringWeatherTimeAlarm" name="item.EnteringWeatherTimeAlarm" type="number" min="0" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <th>{{'AND'|translate}}</th>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td>{{'Time of day (Day/Night)'|translate}}</td>
                <td>
                    <select [(ngModel)]="item.EnteringTimeOfDayWarning" name="item.EnteringTimeOfDayWarning">
                        <option [ngValue]="null">{{'Ignore'|translate}}</option>
                        <option *ngFor="let option of activeFrostTimes|keys" [ngValue]="option">
                            {{activeFrostTimes[option]}}
                        </option>
                    </select>
                </td>
                <td>
                    <select [(ngModel)]="item.EnteringTimeOfDayAlarm" name="item.EnteringTimeOfDayAlarm">
                        <option [ngValue]="null">{{'Ignore'|translate}}</option>
                        <option *ngFor="let option of activeFrostTimes|keys" [ngValue]="option">
                            {{activeFrostTimes[option]}}
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>{{'AND'|translate}}<br />{{'Outside Ambient Temperature &lt; (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.EnteringOutsideAmbientTemperatureWarning" name="item.EnteringOutsideAmbientTemperatureWarning" type="number" step="any" style="width: 60px;" /></td>
                <td><input required [(ngModel)]="item.EnteringOutsideAmbientTemperatureAlarm" name="item.EnteringOutsideAmbientTemperatureAlarm" type="number" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <td>{{'AND'|translate}}<br />{{'Relative Humidity &gt; (%)'|translate}}</td>
                <td><input required [(ngModel)]="item.EnteringRelativeHumidityWarning" name="item.EnteringRelativeHumidityWarning" type="number" min="0" step="any" style="width: 60px;" /></td>
                <td><input required [(ngModel)]="item.EnteringRelativeHumidityAlarm" name="item.EnteringRelativeHumidityAlarm" type="number" min="0" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <td>{{'AND'|translate}}<br />{{'Wind Speed &lt; (m/s)'|translate}}</td>
                <td><input required [(ngModel)]="item.EnteringWindSpeedWarning" name="item.EnteringWindSpeedWarning" type="number" min="0" step="any" style="width: 60px;" /></td>
                <td><input required [(ngModel)]="item.EnteringWindSpeedAlarm" name="item.EnteringWindSpeedAlarm" type="number" min="0" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <td>{{'AND'|translate}}<br />{{'Frost Point temperature &le; (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.EnteringFrostPointTemperatureWarning" name="item.EnteringFrostPointTemperatureWarning" type="number" step="any" style="width: 60px;" /></td>
                <td><input required [(ngModel)]="item.EnteringFrostPointTemperatureAlarm" name="item.EnteringFrostPointTemperatureAlarm" type="number" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <td>{{'AND'|translate}}<br />{{'(Frost Point temperature - Active Frost sensor temperature) &gt; (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.EnteringFrostTemperatureDiffWarning" name="item.EnteringFrostTemperatureDiffWarning" type="number" step="any" style="width: 60px;" /></td>
                <td><input required [(ngModel)]="item.EnteringFrostTemperatureDiffAlarm" name="item.EnteringFrostTemperatureDiffAlarm" type="number" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <td>{{'During this entire period (in minutes)'|translate}}</td>
                <td><input required [(ngModel)]="item.EnteringTimeWarning" name="item.EnteringTimeWarning" type="number" min="0" step="any" style="width: 60px;" /></td>
                <td><input required [(ngModel)]="item.EnteringTimeAlarm" name="item.EnteringTimeAlarm" type="number" min="0" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <td colspan="3">&nbsp;</td>
            </tr>
            <tr>
                <th style="text-align: center" colspan="3">{{'Exiting'|translate}}</th>
            </tr>
            <tr>
                <td>{{'Weather Type'|translate}}</td>
                <td>
                    <multiselect [all]="false" [none]="'Any'" [format]="'string'" [(ngModel)]="item.ExitingWeatherTypeWarningStr" [name]="'item.ExitingWeatherTypeWarningStr'">
                        <multiselect-option *ngFor="let option of weatherTypes|keys" [value]="option" [name]="weatherTypes[option]"></multiselect-option>
                    </multiselect>
                </td>
                <td>
                    <multiselect [all]="false" [none]="'Any'" [format]="'string'" [(ngModel)]="item.ExitingWeatherTypeAlarmStr" [name]="'item.ExitingWeatherTypeAlarmStr'">
                        <multiselect-option *ngFor="let option of weatherTypes|keys" [value]="option" [name]="weatherTypes[option]"></multiselect-option>
                    </multiselect>
                </td>
            </tr>
            <tr>
                <td>{{'During Weather Time (in minutes)'|translate}}</td>
                <td><input placeholder="0" [(ngModel)]="item.ExitingWeatherTimeWarning" name="item.ExitingWeatherTimeWarning" type="number" min="0" step="any" style="width: 60px;" /></td>
                <td><input placeholder="0" [(ngModel)]="item.ExitingWeatherTimeAlarm" name="item.ExitingWeatherTimeAlarm" type="number" min="0" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <th>{{'OR'|translate}}</th>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr>
                <td>{{'Time of day (Day/Night)'|translate}}</td>
                <td>
                    <select [(ngModel)]="item.ExitingTimeOfDayWarning" name="item.ExitingTimeOfDayWarning">
                        <option [ngValue]="null">{{'Ignore'|translate}}</option>
                        <option *ngFor="let option of activeFrostTimes|keys" [ngValue]="option">
                            {{activeFrostTimes[option]}}
                        </option>
                    </select>
                </td>
                <td>
                    <select [(ngModel)]="item.ExitingTimeOfDayAlarm" name="item.ExitingTimeOfDayAlarm">
                        <option [ngValue]="null">{{'Ignore'|translate}}</option>
                        <option *ngFor="let option of activeFrostTimes|keys" [ngValue]="option">
                            {{activeFrostTimes[option]}}
                        </option>
                    </select>
                </td>
            </tr>
            <tr>
                <td>{{'OR'|translate}}<br />{{'Outside Ambient Temperature &ge; (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.ExitingOutsideAmbientTemperatureWarning" name="item.ExitingOutsideAmbientTemperatureWarning" type="number" step="any" style="width: 60px;" /></td>
                <td><input required [(ngModel)]="item.ExitingOutsideAmbientTemperatureAlarm" name="item.ExitingOutsideAmbientTemperatureAlarm" type="number" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <td>{{'OR'|translate}}<br />{{'Relative Humidity &le; (%)'|translate}}</td>
                <td><input required [(ngModel)]="item.ExitingRelativeHumidityWarning" name="item.ExitingRelativeHumidityWarning" type="number" min="0" step="any" style="width: 60px;" /></td>
                <td><input required [(ngModel)]="item.ExitingRelativeHumidityAlarm" name="item.ExitingRelativeHumidityAlarm" type="number" min="0" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <td>{{'OR'|translate}}<br />{{'Wind Speed &ge; (m/s)'|translate}}</td>
                <td><input required [(ngModel)]="item.ExitingWindSpeedWarning" name="item.ExitingWindSpeedWarning" type="number" min="0" step="any" style="width: 60px;" /></td>
                <td><input required [(ngModel)]="item.ExitingWindSpeedAlarm" name="item.ExitingWindSpeedAlarm" type="number" min="0" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <td>{{'OR'|translate}}<br />{{'Frost Point temperature &gt; (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.ExitingFrostPointTemperatureWarning" name="item.ExitingFrostPointTemperatureWarning" type="number" step="any" style="width: 60px;" /></td>
                <td><input required [(ngModel)]="item.ExitingFrostPointTemperatureAlarm" name="item.ExitingFrostPointTemperatureAlarm" type="number" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <td>{{'OR'|translate}}<br />{{'(Frost Point temperature - Active Frost sensor temperature) &le; (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.ExitingFrostTemperatureDiffWarning" name="item.ExitingFrostTemperatureDiffWarning" type="number" step="any" style="width: 60px;" /></td>
                <td><input required [(ngModel)]="item.ExitingFrostTemperatureDiffAlarm" name="item.ExitingFrostTemperatureDiffAlarm" type="number" step="any" style="width: 60px;" /></td>
            </tr>
            <tr>
                <td>{{'During this entire period (in minutes)'|translate}}</td>
                <td><input required [(ngModel)]="item.ExitingTimeWarning" name="item.ExitingTimeWarning" type="number" min="0" step="any" style="width: 60px;" /></td>
                <td><input required [(ngModel)]="item.ExitingTimeAlarm" name="item.ExitingTimeAlarm" type="number" min="0" step="any" style="width: 60px;" /></td>
            </tr>
        </table>
    </fieldset>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditActiveFrostProfiles'|hasPermission)" [hidden]="tab != 'airports'" ngModelGroup="airportsForm">
        <select [(ngModel)]="airport" name="airport">
            <option *ngFor="let airportOption of availableAirports" [ngValue]="airportOption">
                {{airportOption.ICAOCode + '/' + airportOption.IATACode + ' - ' + airportOption.Name}}
            </option>
        </select>
        <input type="button" *ngIf="('ConfigEditActiveFrostProfiles'|hasPermission)" (click)="addActiveFrostProfileAirport(airport); airport = null;" value="{{'Add airport'|translate}}" [disabled]="airport == null" />
        <br />
        <br />
        <table class="table">
            <tr>
                <th>{{'Airport'|translate}}</th>
                <th style="width: 250px;">{{'Contact info'|translate}}</th>
                <th style="width: 70px;">{{'Has warnings?'|translate}}</th>
                <th style="width: 70px;">{{'Has alarms?'|translate}}</th>
                <th></th>
            </tr>
            <tr *ngFor="let activeFrostProfileAirport of item.ActiveFrostProfileAirports; let i = index">
                <td>{{(airports|findById:activeFrostProfileAirport.AirportId)?.ICAOCode + ' / ' + (airports|findById:activeFrostProfileAirport.AirportId)?.ICAOCode + ' - ' + (airports|findById:activeFrostProfileAirport.AirportId)?.Name}}</td>
                <td>
                    <input type="text" style="width: 100%;" [(ngModel)]="activeFrostProfileAirport.ContactInfo" [name]="'activeFrostProfileAirport[' + i + '].ContactInfo'" />
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="activeFrostProfileAirport.HasWarningSystem" [name]="'activeFrostProfileAirport[' + i + '].HasWarningSystem'" />
                </td>
                <td>
                    <input type="checkbox" [(ngModel)]="activeFrostProfileAirport.HasAlarmSystem" [name]="'activeFrostProfileAirport[' + i + '].HasAlarmSystem'" />
                </td>
                <td>
                    <a *ngIf="('ConfigEditActiveFrostProfiles'|hasPermission)" (click)="removeActiveFrostProfileAirport(activeFrostProfileAirport)" class="mdi mdi-delete"></a>
                </td>
            </tr>
        </table>
    </fieldset>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditActiveFrostProfiles'|hasPermission)" [hidden]="tab != 'emails'" ngModelGroup="emailsForm">
        <table class="table" style="width: 100%;">
            <tr>
                <td colspan="4">
                    <label>
                        <span class="caption">{{'Available tokens'|translate}}</span>
                        <span class="value" style="width: auto;">{{'{AirportCode} {ContactInfo} {OperationManagerName} {OperationManagerPhone} {OperationManagerEmailAddress} {AlertId}'}}</span>
                    </label>
                </td>
            </tr>
            <tr>
                <th colspan="4">{{'Warning entering email'|translate}}</th>
            </tr>
            <tr>
                <td colspan="4">
                    <input type="text" style="width: 100%;" [(ngModel)]="item.WarningEnteringSubject" [name]="'item.WarningEnteringSubject'" placeholder="Subject" />
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <ckeditor [editor]="Editor" [config]="ckConfig" style="width: 100%; min-height: 150px;" [(ngModel)]="item.WarningEnteringBody" [name]="'item.WarningEnteringBody'"></ckeditor>
                </td>
            </tr>
            <tr>
                <th colspan="4">{{'Warning exiting email'|translate}}</th>
            </tr>
            <tr>
                <td colspan="4">
                    <input type="text" style="width: 100%;" [(ngModel)]="item.WarningExitingSubject" [name]="'item.WarningExitingSubject'" placeholder="Subject" />
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <ckeditor [editor]="Editor" [config]="ckConfig" style="width: 100%; min-height: 150px;" [(ngModel)]="item.WarningExitingBody" [name]="'item.WarningExitingBody'"></ckeditor>
                </td>
            </tr>
            <tr>
                <th colspan="4">{{'Alarm entering email'|translate}}</th>
            </tr>
            <tr>
                <td colspan="4">
                    <input type="text" style="width: 100%;" [(ngModel)]="item.AlarmEnteringSubject" [name]="'item.AlarmEnteringSubject'" placeholder="Subject" />
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <ckeditor [editor]="Editor" [config]="ckConfig" style="width: 100%; min-height: 150px;" [(ngModel)]="item.AlarmEnteringBody" [name]="'item.AlarmEnteringBody'"></ckeditor>
                </td>
            </tr>
            <tr>
                <th colspan="4">{{'Alarm exiting email'|translate}}</th>
            </tr>
            <tr>
                <td colspan="4">
                    <input type="text" style="width: 100%;" [(ngModel)]="item.AlarmExitingSubject" [name]="'item.AlarmExitingSubject'" placeholder="Subject" />
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <ckeditor [editor]="Editor" [config]="ckConfig" style="width: 100%; min-height: 150px;" [(ngModel)]="item.AlarmExitingBody" [name]="'item.AlarmExitingBody'"></ckeditor>
                </td>
            </tr>
        </table>
    </fieldset>
</form>
