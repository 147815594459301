import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'serviceProviderNotEnabled',
    pure: false,
})
export class ServiceProviderNotEnabledPipe implements PipeTransform {
    transform(serviceProviders, sqsQueueConfigurationDeicingServiceProviders): any {
        if (sqsQueueConfigurationDeicingServiceProviders == null) return serviceProviders;
        return serviceProviders.filter(sp => sqsQueueConfigurationDeicingServiceProviders.every(csp => csp.DeicingServiceProviderId !== sp.Id));
    }
}
