import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest, HttpHandler } from '@angular/common/http';
import { StorageService } from '../Storage.service';

// Add bearer authorization to requests
@Injectable()
export class AuthInterceptorService implements HttpInterceptor {

    constructor(private storageService: StorageService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        const storedToken: { access_token: string } = this.storageService.load('access_token');

        if (storedToken) {
            const authorizedRequest = request.clone({
                headers: request.headers.append('Authorization', `Bearer ${storedToken.access_token}`)
            });

            return next.handle(authorizedRequest);
        }

        return next.handle(request);
    }
}
