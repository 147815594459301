<div class="noMargins" *ngIf="airports">
    <form class="contentMargins form" name="form" #form="ngForm" (ngSubmit)="upload()">
        <label>
            <span class="caption">{{'Airport'|translate}}</span>
            <select [(ngModel)]="airportId" required>
                <option *ngFor="let airport of airports|orderBy:'Name'" [ngValue]="airport.Id">
                    {{airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'File'|translate}}</span>
            <input type="file" required file-as-data-uri ng-model="data" />
        </label>
        <label>
            <span class="caption">{{'Skip lines (header)'|translate}}</span>
            <input type="number" ng-model="skipLines" required min="0" step="1" />
        </label>
        <br />
        <input type="submit" [disabled]="!form.valid || !form.dirty || data == null" [value]="'Upload'|translate" />
    </form>
    <div class="contentMargins result" *ngIf="!!result">
        <h2>{{'Result'|translate}}</h2>
        <div *ngFor="let kv of result">
            <label>{{kv.key}}</label>: <span *ngIf="kv.key !== 'Errors'">{{kv.value}}</span>
            <div *ngIf="kv.key == 'Errors'">
                <div style="color: red; font-weight: bold;" *ngFor="let error of kv.value">{{error}}</div>
            </div>
        </div>
    </div>
</div>


