<div class="noMargins">
    <page-title>{{'XML request'|translate}}</page-title>
    <form class="contentMargins form" #form="ngForm" (ngSubmit)="send()">
        <label>
            <textarea required [(ngModel)]="query.message" style="width: 700px; height: 500px;" name="message"></textarea>
            <br /><br />
            <span class="caption">{{'Message'|translate}}</span>
            <file-as-data [(ngModel)]="query.message" name="message"></file-as-data>
        </label>
        <br />
        <input type="submit" [disabled]="!form.valid" [value]="'Send'|translate" />
    </form>
    <div class="result" *ngIf="!!result">
        <h2>{{'Log'|translate}}</h2>
        <pre *ngFor="let log of result.Logs">{{log}}</pre>
        <h2>{{'Result'|translate}}</h2>
        <pre>{{result.XmlOutgoingMessage.RawData}}</pre>
    </div>
</div>

