<div class="reportDeicing noMargins" *ngIf="query">
    <page-title>{{'HOT report'|translate}}</page-title>
    <form #form="ngForm" class="toolbar gridFilters" (ngSubmit)="refresh()">
        <label class="inline">
            <span class="caption">{{'Client'|translate}}</span>
            <select required [(ngModel)]="query.ClientId" name="query.ClientId">
                <option *ngFor="let client of clients|orderBy:'Name'" [ngValue]="client.Id">
                    {{client.Name}}
                </option>
            </select>
        </label>
        <div class="field inline">
            <span class="caption">{{'Request type'|translate}}</span>
            <multiselect [(ngModel)]="query.HotRequestType" name="query.HotRequestType" [all]="false">
                <multiselect-option *ngFor="let hotRequestType of hotRequestTypes|keyvalue" [value]="hotRequestType.key" [name]="hotRequestType.value"></multiselect-option>
            </multiselect>
        </div>
        <div class="field inline">
            <span class="caption">{{'Airport'|translate}}</span>
            <multiselect [(ngModel)]="query.AirportId" name="query.AirportId">
                <multiselect-option *ngFor="let airport of airports|orderBy:'[\'-HasStation\', \'ICAOCode\']'" [value]="airport.Id" [name]="airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name"></multiselect-option>
            </multiselect>
        </div>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" required [(ngModel)]="query.FromDate" name="query.FromDate">
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" required [(ngModel)]="query.ToDate" name="query.ToDate">
        </label>
        <label>
            <span class="caption">{{'Maximum HOT request age in minutes'|translate}}</span>
            <input type="number" [(ngModel)]="query.MaxHotRequestAgeInMinutes" min="0" step="1" required name="query.MaxHotRequestAgeInMinutes"/>
        </label>
        <label class="inline">
            <span class="caption">{{'Include IPs'|translate}}</span>
            <input type="text" [(ngModel)]="query.IncludeIPs" style="width: 300px;" name="query.IncludeIPs"/>
        </label>
        <label class="inline">
            <span class="caption">{{'Exclude IPs'|translate}}</span>
            <input type="text" [(ngModel)]="query.ExcludeIPs" style="width: 300px;" name="query.ExcludeIPs"/>
            {{'(comma separated)'|translate}}
        </label>
        <button (click)="export()" [disabled]="!form.valid">{{'Export to Excel'|translate}}</button>
        <input type="submit" [disabled]="!form.valid" [value]="'Apply'|translate"/>
    </form>
    <div *ngIf="hotSummary">
        <div class="toolbar">
            <span class="tab" (click)="switchTab('summary')" [ngClass]="{active : tab === 'summary' }">{{'Summary'}}</span>
            <span class="tab" (click)="switchTab('flightsExceedingHot')" [ngClass]="{active : tab === 'flightsExceedingHot' }">{{'Flights exceeding HOT'}}</span>
        </div>
    </div>
    <div class="summary" *ngIf="tab === 'flightsExceedingHot' && hotSummary" style="display: flex; flex: 1 1 auto;">
        <flights-exceeding-hot [flight]="hotSummary.FlightsExceedingHot" style="display: flex; flex: 1 1 auto"></flights-exceeding-hot>
    </div>
    <div class="summary" *ngIf="tab === 'summary' && hotSummary">
        <table class="table">
            <tr>
                <th style="width: 300px;">{{'Start date:'|translate}}</th>
                <td>{{hotSummary.FromDate|utcDateTimeFormat:root.dateFormat}}</td>
            </tr>
            <tr>
                <th>{{'End date:'|translate}}</th>
                <td>{{hotSummary.ToDate|utcDateTimeFormat:root.dateFormat}}</td>
            </tr>
            <tr><td>&nbsp;</td></tr>

            <tr>
                <th colspan="2">{{'Number of SureWx Reports by Type of Message'|translate}}</th>
            </tr>
            <tr>
                <th>{{'LWE HOT'|translate}}</th>
                <td>{{hotSummary.HotResponseLWECount}}</td>
            </tr>
            <tr>
                <th>{{'METAR HOT'|translate}}</th>
                <td>{{hotSummary.HotResponseMetarCount}}</td>
            </tr>
            <tr>
                <th>{{'SureWx System not Available'|translate}}</th>
                <td>{{hotSummary.NoCount}} <a href title="Requests where no response was provided" class="help mdi mdi-help-circle" style="padding: 2px; float: right;"></a></td>
            </tr>
            <tr>
                <th>{{'METAR HOTs at LWE stations'|translate}}</th>
                <td>{{hotSummary.MetarHotAtLWEStationCount}}</td>
            </tr>
            <tr><td>&nbsp;</td></tr>

            <tr>
                <th colspan="2">{{'Deicing'|translate}} <a title="Based on ACARS deicing reports and flights" class="help mdi mdi-help-circle" style="padding: 2px; float: right;"></a></th>
            </tr>
            <tr>
                <th>{{'% of flights that requested HOTs'|translate}}</th>
                <td>{{Math.round(hotSummary.RatioOfFlightsWithHotRequests * 100)}}% <a href title="HOTs with matching flight number;&#10;Request time within 3 hours of wheels up time" class="help mdi mdi-help-circle" style="padding: 2px; float: right;"></a></td>
            </tr>
            <tr>
                <th>{{'% of flights that submitted deicing reports'|translate}}</th>
                <td>{{Math.round(hotSummary.RatioOfFlightsWithDeicingReports * 100)}}% <a href title="Flights with matching flight number;&#10;Deicing start time within 3 hours of wheels up time" class="help mdi mdi-help-circle" style="padding: 2px; float: right;"></a></td>
            </tr>
            <tr>
                <th>{{'Number of deicing reports'|translate}}</th>
                <td>{{hotSummary.NumberOfDeicingReports}} <a href title="Total number of ACARS deicing reports matching filters" class="help mdi mdi-help-circle" style="padding: 2px; float: right;"></a></td>
            </tr>
            <tr>
                <th>{{'Number of HOT requests'|translate}}</th>
                <td>{{hotSummary.NumberOfHotRequests}} <a href title="Total number of requests matching filters" class="help mdi mdi-help-circle" style="padding: 2px; float: right;"></a></td>
            </tr>
            <tr>
                <th>{{'Number of flights exceeding LWE HOT times'|translate}}</th>
                <td>{{hotSummary.FlightsExceedingHot.length}} <a href title="HOT usage = wheels up time - deicingt start time;&#10;If ACARS deicing report indicates type 4 was applied, compare to type 4 HOT, otherwise type 1" class="help mdi mdi-help-circle" style="padding: 2px; float: right;"></a></td>
            </tr>
            <tr><td>&nbsp;</td></tr>

            <tr>
                <th colspan="2">{{'Number of Requests by Interface'|translate}}</th>
            </tr>
            <tr *ngFor="let item of hotSummary.MessageCountsByRequestType">
                <th>{{item.RequestType}}</th>
                <td>{{item.Count}}</td>
            </tr>
            <tr><td>&nbsp;</td></tr>

            <tr>
                <th colspan="2">{{'Number of SureWx Reports by Fluid Type'|translate}}</th>
            </tr>
            <tr>
                <th>{{'Type 1'|translate}}</th>
                <td>{{hotSummary.FluidType1Hots}}</td>
            </tr>
            <tr>
                <th>{{'Type 2'|translate}}</th>
                <td>{{hotSummary.FluidType2Hots}}</td>
            </tr>
            <tr>
                <th>{{'Type 3'|translate}}</th>
                <td>{{hotSummary.FluidType3Hots}}</td>
            </tr>
            <tr>
                <th>{{'Type 4'|translate}}</th>
                <td>{{hotSummary.FluidType4Hots}}</td>
            </tr>
            <tr>
                <th>{{'Blank'|translate}}</th>
                <td>{{hotSummary.NoFluidHots}} <a href title="Requests where no fluid-specific HOT/message was provided" class="help mdi mdi-help-circle" style="padding: 2px; float: right;"></a></td>
            </tr>
            <tr><td>&nbsp;</td></tr>

            <tr>
                <th colspan="2">{{'Number of Requests by Error'|translate}} <a href title="Internal error conditions, ignoring custom error labels" class="help mdi mdi-help-circle" style="padding: 2px; float: right;"></a></th>
            </tr>
            <tr *ngFor="let item of hotSummary.MessageCountsByError">
                <th>{{item.Error}}</th>
                <td>{{item.Count}}</td>
            </tr>
            <tr><td>&nbsp;</td></tr>

            <tr>
                <th colspan="2">{{'Number of LWE HOT Reports by Location'|translate}}</th>
            </tr>
            <tr *ngFor="let item of hotSummary.HotResponseLWECountByAirport">
                <th>{{item.AirportCode}}</th>
                <td>{{item.Count}}</td>
            </tr>
            <tr><td>&nbsp;</td></tr>

            <tr>
                <th colspan="2">{{'USE HOLDOVER TABLES from METAR HOT Reports'|translate}} <a href title="METAR responses where no fluid-specific HOT/message was provided" class="help mdi mdi-help-circle" style="padding: 2px; float: right;"></a></th>
            </tr>
            <tr *ngFor="let item of hotSummary.HotResponseUseTablesCountByMetarWeatherType">
                <th>{{item.FinalMetarWeatherType}}</th>
                <td>{{item.Count}}</td>
            </tr>
            <tr><td>&nbsp;</td></tr>

            <tr>
                <th colspan="2">{{'Wx Conditions for METAR HOT Reports with HOT'|translate}}</th>
            </tr>
            <tr *ngFor="let item of hotSummary.HotResponseHotCountByMetarWeatherType">
                <th>{{item.FinalMetarWeatherType}}</th>
                <td>{{item.Count}}</td>
            </tr>
            <tr><td>&nbsp;</td></tr>

            <tr>
                <th colspan="2">{{'Messages'|translate}} <a href title="Custom error labels and METAR/LWE message mappings" class="help mdi mdi-help-circle" style="padding: 2px; float: right;"></a></th>
            </tr>
            <tr *ngFor="let item of hotSummary.MessageCounts">
                <th>{{item.Message}}</th>
                <td>{{item.Count}}</td>
            </tr>
        </table>
    </div>
</div>
