import {Component} from "@angular/core";

@Component({
    selector: 'swx-active-frost',
    templateUrl: './ActiveFrost.component.html',
    styles: [':host { display: flex; flex-direction: column; height: 100%; }']
})
export class ActiveFrostComponent {
    query: any = {};
    
    onQueryChange(query) {
        this.query = {...query};
    }
} 
