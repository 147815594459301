<ng-container *ngIf="configuration != null">
    <td>{{name}}</td>
    <td><input *ngIf="type != 'WMO'" required type="number" step="any" [(ngModel)]="configuration.Factor" [name]="property + '.Factor'" style="width: 50px;" /></td>
    <td><input *ngIf="type != 'WMO'" required type="number" step="any" [(ngModel)]="configuration.Offset" [name]="property + '.Offset'" style="width: 50px;" /></td>
    <td><input required type="number" step="any" [max]="configuration.DiscardMax - 1" [(ngModel)]="configuration.DiscardMin" [name]="property + '.DiscardMin'" style="width: 70px;" /></td>
    <td><input required type="number" step="any" [min]="configuration.DiscardMin + 1" [(ngModel)]="configuration.DiscardMax" [name]="property + '.DiscardMax'" style="width: 70px;" /></td>
    <td>
        <input *ngIf="type == 'Temperature'" type="number" step="any" [(ngModel)]="configuration.MaxDifferenceFromMetar" [name]="property + '.MaxDifferenceFromMetar'" style="width: 70px;" />
    </td>
    <!-- <td> -->
    <!--     <input *ngIf="type == 'Temperature'" type="number" step="any" [(ngModel)]="configuration.MaxMetarReadingAgeMinutes" [name]="property + '.MaxMetarReadingAgeMinutes'" style="width: 70px;" /> -->
    <!-- </td> -->
</ng-container>
