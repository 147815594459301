<div id="log" class="contentMargins">
    <form class="form" #form="ngForm">
        <label class="inline">
            <span class="caption">{{'Log file'|translate}}</span>
            <select required [(ngModel)]="logFile" name="logFile">
                <option *ngFor="let file of logs" [ngValue]="file">
                    {{file.FileName}}
                </option>
            </select>
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <button [disabled]="!form.valid" (click)="download()" >{{'Download log file'|translate}}</button>
        </label>
        <label class="inline">
            <span class="caption">&nbsp;</span>
            <input type="button" [disabled]="!form.valid" (click)="view()" [value]="'View'|translate" />
        </label>
    </form>
    <pre style="margin-top: 0;">{{log}}</pre>
</div>
