import {Component, OnInit, ViewChild} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {IAgGridColGroupDef, StorageService, ValueFormatters} from "swx.front-end-lib";

@Component({
    templateUrl: './openMeteoForecast.component.html'
})
export class OpenMeteoForecastComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'OpenMeteoForecastQuery';
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    airports = this.api.Airport.query();
    resource = this.api.OpenMeteoForecast;
    exportResourcePost = this.api.OpenMeteoForecastExport;

    constructor(private api: ApiService,
                private storage: StorageService,
                private formatters: ValueFormatters) {
    }
    
    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery) {
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }
    
    resetQuery() {
        this.query = {
            Filters: []
        };
    }
    
    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }
    
    columnDefs: IAgGridColGroupDef[] = [
        {
            headerName: '',
            children: [
                { colId: "OpenMeteoForecast.Id", field: "OpenMeteoForecast.Id", headerName: "#", width: 70, filterType: "integer", pinned: 'left' },
                { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 70, pinned: 'left' },
                { colId: "OpenMeteoForecast.RetrievedDate", field: "OpenMeteoForecast.RetrievedDate", headerName: "Retrieved date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "OpenMeteoForecast.RetrievedTime", field: "OpenMeteoForecast.RetrievedDate", headerName: "Retrieved time", width: 80, excelIgnore: true, searchable: false, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "OpenMeteoForecast.ForecastDate", field: "OpenMeteoForecast.ForecastHour", headerName: "Forecast date", width: 90, filterType: "date", pinned: 'left', valueFormatter: this.formatters.utcDateFormatter() },
                { colId: "OpenMeteoForecast.ForecastTime", field: "OpenMeteoForecast.ForecastHour", headerName: "Forecast time", width: 80, excelIgnore: true, pinned: 'left', searchable: false, valueFormatter: this.formatters.utcTimeFormatter() },
                { colId: "OpenMeteoForecast.TempC", field: "OpenMeteoForecast.TempC", headerName: "Temperature (°C)", width: 100, filterType: "float" },
                { colId: "OpenMeteoForecast.RelativeHumidity", field: "OpenMeteoForecast.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 100, filterType: "float" },
                { colId: "OpenMeteoForecast.Precipitation1Hour", field: "OpenMeteoForecast.Precipitation1Hour", headerName: "Precipitation 1 hour (mm)", filterType: "float", width: 110 },
                { colId: "OpenMeteoForecast.PrecipitationProbability", field: "OpenMeteoForecast.PrecipitationProbability", headerName: "Precipitation probability", filterType: "float", width: 90 },
                { colId: "OpenMeteoForecast.WMOCode", field: "OpenMeteoForecast.WMOCode", headerName: "WMO", width: 60, filterType: "integer" },
                { colId: "OpenMeteoForecast.VisibilityM", field: "OpenMeteoForecast.VisibilityM", headerName: "Visibility (m)", width: 100, filterType: "float" },
                { colId: "OpenMeteoForecast.WindSpeedMs", field: "OpenMeteoForecast.WindSpeedMs", headerName: "Wind speed (m/s)", width: 100, filterType: "float" },
                { colId: "OpenMeteoForecast.WindDirDegrees", field: "OpenMeteoForecast.WindDirDegrees", headerName: "Wind dir (degrees)", width: 90, filterType: "float" },
                { colId: "OpenMeteoForecast.WindGustMs", field: "OpenMeteoForecast.WindGustMs", headerName: "Wind gust (m/s)", width: 100, filterType: "float" },
            ]
        },
    ];
} 
