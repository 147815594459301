import {Injectable, ViewContainerRef} from "@angular/core";
import {DialogService} from "../Dialog.service";
import {AuthenticationService} from "../auth/Authentication.service";
import {ChangesDialogComponent} from "./ChangesDialog.component";
import {ExportApi} from "../ApiFactory.service";

@Injectable({ providedIn: 'root'})
export class ChangesService {
    constructor(
        private api: ExportApi,
        private dialogService: DialogService,
        private authentication: AuthenticationService,
    ) {
    }

    show(viewContainerRef: ViewContainerRef, subjectInfo) {
        this.authentication.getCurrentUser()
            .then(user => {
                this.dialogService.show(viewContainerRef, ChangesDialogComponent,
                    {
                        query: subjectInfo,
                        changeAlertsQuery: {
                            UserId: user.Id,
                            SubjectType: subjectInfo.SubjectType,
                            SubjectId: subjectInfo.SubjectId
                        }
                    },
                    {
                        title: 'Changes',
                        width: 750,
                        height: 550,
                        modal: true
                    });
            });
    }
}
