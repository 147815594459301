<page-title>{{('WMO code mapping profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('mappings')" [class.active]="tab == 'mappings'" [class.invalid]="!ngForm.form.controls.mappingsForm?.valid">{{'Mappings'|translate}}</a>
        <a class="tab" (click)="switchTab('counts')" [class.active]="tab == 'counts'" [class.invalid]="!ngForm.form.controls.countsForm?.valid">{{'Counts'|translate}}</a>
        <a class="tab" (click)="switchTab('combinations')" [class.active]="tab == 'combinations'" [class.invalid]="!ngForm.form.controls.combinationsForm?.valid">{{'Combinations'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        <button type="button" (click)="exportProfile()" [disabled]="!item.Id"><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        <button *ngIf="('ConfigAddWMOCodeMappings'|hasPermission) && tab == 'mappings'" type="button" class="green" (click)="addMapping()">{{'Add mapping'|translate}}</button>
        <button *ngIf="('ConfigAddWMOCodeMappings'|hasPermission) && tab == 'counts'" type="button" class="green" (click)="addCount()">{{'Add weather type'|translate}}</button>
        <button *ngIf="('ConfigAddWMOCodeMappings'|hasPermission) && tab == 'combinations'" type="button" class="green" (click)="addCombination()">{{'Add combination'|translate}}</button>
    </div>
    
    <fieldset class="contentMargins form" [disabled]="!('ConfigEditWMOCodeMappings'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Past weather type combination count'|translate}}</span>
            <input type="number" [(ngModel)]="item.CombinationCount" name="item.CombinationCount" min="0" step="1" max="30" style="width: 60px;" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
    </fieldset>

    <config-grid #mappingsGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'mappings'"
                 ngModelGroup="mappingsForm"
                 #mappingsForm="ngModelGroup"
                 [rowData]="item.WeatherTypeMappings"
                 [columnDefs]="mappingsColumnDefs">
    </config-grid>

    <config-grid #countsGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'counts'"
                 ngModelGroup="countsForm"
                 #countsForm="ngModelGroup"
                 [rowData]="item.WeatherTypeNgCounts"
                 [columnDefs]="countsColumnDefs">
    </config-grid>

    <config-grid #combinationsGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'combinations'"
                 ngModelGroup="combinationsForm"
                 #combinationsForm="ngModelGroup"
                 [rowData]="item.WeatherTypeNgCombination"
                 [columnDefs]="combinationsColumnDefs">
    </config-grid>
</form>
