<page-title>{{('Heat flaps alert profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('equipmentTypes')" [class.active]="tab == 'equipmentTypes'" [class.invalid]="!ngForm.form.controls.equipmentTypesForm?.valid">{{'Equipment types'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditHeatFlapsAlertProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100"/>
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active"/>
        </label>
        <label>
            <span class="caption">{{'Client'|translate}}</span>
            <select [(ngModel)]="item.ClientId" name="item.ClientId">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let client of clients|orderBy:'Name'" [ngValue]="client.Id">
                    {{client.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'IBM MQ configuration to send ACARS'|translate}}</span>
            <select [(ngModel)]="item.IBMMQServerConfigurationId" name="item.IBMMQServerConfigurationId">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let ibmMqServerConfiguration of ibmMqServerConfigurations|orderBy:'Name'" [ngValue]="ibmMqServerConfiguration.Id">
                    {{ibmMqServerConfiguration.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Minutes before estimated touchdown'|translate}}</span>
            <input type="number" [(ngModel)]="item.MinutesBeforeEstimatedTochdownTime" name="item.MinutesBeforeEstimatedTochdownTime" required step="1" min="0" />
        </label>
        <label>
            <span class="caption">{{'Minimum duration to persist alarm'|translate}}</span>
            <input type="number" required [(ngModel)]="item.MinimumDurationMinutes" name="item.MinimumDurationMinutes" step="1" min="0" />
        </label>
        <label>
            <span class="caption">{{'Message'|translate}}</span>
            <textarea required [(ngModel)]="item.AcarsContent" name="item.AcarsContent" cols="24" rows="10" style="font-family: monospace; white-space: pre; overflow: auto;"></textarea>
        </label>
    </fieldset>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditHeatFlapsAlertProfiles'|hasPermission)" [hidden]="tab != 'equipmentTypes'" ngModelGroup="equipmentTypesForm">
        <select [(ngModel)]="heatFlapsAlertProfileEquipmentTypeOption" name="item.heatFlapsAlertProfileEquipmentTypeOption">
            <option *ngFor="let equipmentType of equipmentTypesNotEnabled()|orderBy:['IATACode', 'ICAOCode']" [ngValue]="equipmentType">
                {{equipmentType.IATACode + '/' + (equipmentType.ICAOCode || ('N/A'|translate)) + ' ' + equipmentType.ManufacturerAndModelDescription}}
            </option>
        </select>
        <input type="button" *ngIf="('ConfigEditHeatFlapsAlertProfiles'|hasPermission)" (click)="addHeatFlapsAlertProfileEquipmentType(item, heatFlapsAlertProfileEquipmentTypeOption); heatFlapsAlertProfileEquipmentTypeOption = null;" value="{{'Add equipment type'|translate}}" [disabled]="heatFlapsAlertProfileEquipmentTypeOption == null" />
        <br />
        <br />
        <table class="table">
            <tr>
                <th>{{'Equipment type'|translate}}</th>
                <th>{{'Minimum temperature (°C)'|translate}}</th>
                <th></th>
            </tr>
            <tr *ngFor="let heatFlapsAlertProfileEquipmentType of item.HeatFlapsAlertProfileEquipmentTypes|orderBy:'Order'; let i = index">
                <td>{{(equipmentTypes|findById:heatFlapsAlertProfileEquipmentType.EquipmentTypeId)?.IATACode + ' / ' + ((equipmentTypes|findById:heatFlapsAlertProfileEquipmentType.EquipmentTypeId)?.ICAOCode || ('N/A'|translate))}}</td>
                <td><input type="number" required step="any" [(ngModel)]="heatFlapsAlertProfileEquipmentType.MinTemperature" [name]="'heatFlapsAlertProfileEquipmentType[' + i + '].MinTemperature'" style="width: 60px;" /></td>
                <td>
                    <a *ngIf="('ConfigEditHeatFlapsAlertProfiles'|hasPermission)" (click)="removeHeatFlapsAlertProfileEquipmentType(item, heatFlapsAlertProfileEquipmentType)" class="mdi mdi-delete"></a>
                </td>
            </tr>
        </table>
    </fieldset>
</form>
