<div class="noMargins" *ngIf="vm">
    <page-title>{{'Gen 2 Validation'|translate}}</page-title>
    <form class="contentMargins form" #form="ngForm" (ngSubmit)="validate(form.form)">
        <label>
            <span class="caption">{{'Station'|translate}}</span>
            <select [(ngModel)]="stationId" required name="stationId">
                <option *ngFor="let station of vm.stations|orderBy:'Name'" [ngValue]="station.Id">
                    {{station.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Sensor select matrix'|translate}}</span>
            <select [(ngModel)]="sensorSelectMatrixId" required name="sensorSelectMatrixId">
                <option *ngFor="let ssm of vm.sensorSelectMatrices|orderBy:'Name'" [ngValue]="ssm.Id">
                    {{ssm.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Fluid'|translate}}</span>
            <select [(ngModel)]="fluidId" required name="fluidId">
                <option *ngFor="let fluid of vm.fluids|orderBy:['FluidProfileName', 'Type', 'Name']" [ngValue]="fluid.Id">
                    {{fluid.FluidProfileName + ' - ' + fluid.Type + ' - ' + fluid.Name + ' (' + fluid.Dilution + ')'}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'LWE message mappings'|translate}}</span>
            <select [(ngModel)]="lweMessageMappingProfileId" name="lweMessageMappingProfileId">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let mapping of vm.lweMessageMappingProfiles|orderBy:'Name'" [ngValue]="mapping.Id">
                    {{mapping.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'File'|translate}}</span>
            <file-as-data-uri required  [(ngModel)]="data" name="data"></file-as-data-uri>
        </label>
        <br />
        <input type="submit" [disabled]="!form.valid || !form.dirty || data == null" [value]="'Validate'|translate" />
    </form>
</div>


