<th>{{'Sensor'|translate}}</th>
<th>{{'Factor'|translate}}</th>
<th>{{'Offset'|translate}}</th>
<th width="80">{{'Discard minimum'|translate}}</th>
<th width="80">{{'Discard maximum'|translate}}</th>
<th width="120">
    <span *ngIf="type == 'Temperature'">{{'Discard maximum difference from METAR'|translate}}</span>
</th>
<!-- <th width="120"> -->
<!--     <span *ngIf="type == 'Temperature'">{{'Maximum METAR age in minutes'|translate}}</span> -->
<!-- </th> -->
