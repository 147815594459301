import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
   name: 'airlineNotEnabled' 
})
export class AirlineNotEnabledPipe implements PipeTransform {
    transform(airlines, clientApiTokenMobileInitFlightDeicingAirlines): any {
        return (airlines || []).filter(a => !(clientApiTokenMobileInitFlightDeicingAirlines || []).some(tda => tda.DeicingAirlineId === a.Id));
    }
}
