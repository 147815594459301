<div class="noMargins">
    <form #form #ngForm="ngForm" (submit)="save()">
        <page-title>{{('App'|translate) + ': ' + (item.Name || 'new')}}</page-title>
        <div class="toolbar">
            <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <a class="tab" *ngIf="item.Id" (click)="switchTab('releases')" [class.active]="tab == 'releases'">{{'Releases'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}"/>
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}"/>
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}"/>
            <button type="button" *ngIf="'ConfigEditMobileAppReleases'|hasPermission" routerLink="/mobileAppReleases/new" class="green">{{'Add version'|translate}}</button>
        </div>
        <fieldset class="contentMargins form" [disabled]="!('ConfigEditMobileApps'|hasPermission)" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.AppName" name="item.AppName" />
            </label>
            <label>
                <span class="caption">{{'Title under the icon'|translate}}</span>
                <input type="text" required [(ngModel)]="item.AppTitle" name="item.AppTitle"/>
            </label>
            <label>
                <span class="caption">{{'Priority'|translate}}</span>
                <input type="number" [(ngModel)]="item.Priority" name="item.Priority"/>
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="item.Active"/>
            </label>
            <label>
                <span class="caption">{{'App Configuration'|translate}}</span>
                <select [(ngModel)]="item.ClientApiTokenId" name="item.ClientApiTokenId" required [selectedIndex]="-1">
                    <option *ngFor="let clientApiToken of clientApiTokens|orderBy:'APIClientCode'" [ngValue]="clientApiToken.Id">
                        {{clientApiToken.APIClientCode}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Audience'|translate}}</span>
                <select [(ngModel)]="item.Audience" name="item.Audience" required [selectedIndex]="-1">
                    <option *ngFor="let option of mobileAppAudiences|keys" [ngValue]="option">
                        {{mobileAppAudiences[option]}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Format'|translate}}</span>
                <select [(ngModel)]="item.Platform" name="item.Platform" required [selectedIndex]="-1">
                    <option *ngFor="let option of mobileAppPlatforms|keys" [ngValue]="option">
                        {{mobileAppPlatforms[option]}}
                    </option>
                </select>
            </label>

            <ng-container *ngIf="isIos">
                <label>
                    <span class="caption">{{'App Icon'|translate}}</span>
                    <a *ngIf="item.AppIcon" (click)="confirmDeleteIcon()" class="mdi mdi-delete" title="{{'Delete'|translate}}"></a>
                    <file-as-data-uri accept="image/*" [ngModel]="item.AppIcon" name="AppIcon" (ngModelChange)="appIconChanged($event)"></file-as-data-uri>
                    <br/>
                    <img *ngIf="item.AppIcon" [src]="item.AppIcon" alt="App Icon" width="100" height="100"/>
                </label>
                <label>
                    <span class="caption">{{'Splash Screen'|translate}}</span>
                    <a *ngIf="item.SplashScreen" (click)="confirmDeleteSplashScreen()" class="mdi mdi-delete" title="{{'Delete'|translate}}"></a>
                    <file-as-data-uri accept="image/*" [ngModel]="item.SplashScreen" name="AppIcon" (ngModelChange)="splashScreenChanged($event)"></file-as-data-uri>
                    <br/>
                    <img *ngIf="item.SplashScreen" [src]="item.SplashScreen" alt="Splash Screen" width="100" height="100"/>
                </label>
                <label>
                    <span class="caption">{{'Bundle ID'|translate}}</span>
                    <input type="text" required [(ngModel)]="item.BundleId" name="item.AppId" style="width: 250px;"/>
                </label>
            </ng-container>
            <div class="field">
                <span class="caption">{{'Assets bundle .zip'|translate}}</span>
                <file-upload [(ngModel)]="item.AssetsArchiveFileUploadId" name="item.AssetsArchiveFileUploadId"></file-upload>
            </div>
            <label>
                <span class="caption">{{'Comments'|translate}}</span>
                <textarea [(ngModel)]="item.Comments" name="item.Comments" rows="4" cols="60"></textarea>
            </label>
        </fieldset>
    </form>
    <config-grid #releasesGrid
                 *ngIf="item.Id"
                 [parent]="this"
                 [hidden]="tab != 'releases'"
                 [allRows]="releases"
                 [columnDefs]="releaseColumnDefs">
    </config-grid>
    
    <div *ngIf="tab === 'basicInfo' && item.AppStoreConnectAppId && item.Platform === 'IOSTestFlight'" class="contentMargins">
        <swx-test-flight-details [appId]="item.AppStoreConnectAppId"></swx-test-flight-details>
    </div>
</div>
