<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('Equipment type'|translate) }}</page-title>
    <div id="equipmentTypeEditPage" class="noMargins">
        <div class="toolbar">
            <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        </div>
        <fieldset class="form contentMargins" [disabled]="!('ConfigEditEquipmentTypes'|hasPermission)" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'IATA code'|translate}}</span>
                <input type="text" required [(ngModel)]="item.IATACode" name="IATACode" maxlength="3" style="width: 50px;" />
            </label>
            <label>
                <span class="caption">{{'ICAO code'|translate}}</span>
                <input type="text" [(ngModel)]="item.ICAOCode" name="ICAOCode" maxlength="4" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="Active"/>
            </label>
            <label>
                <span class="caption">{{'Ignore ICAO code for lookup'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.DontUseICAOCodeForLookup" name="DontUseICAOCodeForLookup"/>
            </label>
            <label>
                <span class="caption">{{'Manufacturer and Model'|translate}}</span>
                <input type="text" required [(ngModel)]="item.ManufacturerAndModelDescription" name="ManufacturerAndModelDescription" style="width: 250px;" />
            </label>
            <label>
                <span class="caption">{{'Wing Surface Type'|translate}}</span>
                <select [(ngModel)]="item.WingMaterialType" name="WingMaterialType" required>
                    <option *ngFor="let wingMaterialType of wingMaterialTypes|keyvalue" [value]="wingMaterialType.key">
                        {{wingMaterialType.value}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Use adjusted HOT'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.UseAdjustedHot" name="UseAdjustedHot" />
            </label>
            <label>
                <span class="caption">{{'Body type'|translate}}</span>
                <select [(ngModel)]="item.BodyType" name="BodyType">
                    <option [ngValue]="null">{{'Undefined'|translate}}</option>
                    <option *ngFor="let bodyType of bodyTypes|keyvalue" [ngValue]="bodyType.key">
                        {{bodyType.value}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Number of engines'|translate}}</span>
                <input type="number" min="0" step="1" [(ngModel)]="item.NumberOfEngines" name="NumberOfEngines" style="width: 60px;" />
            </label>
            <label>
                <span class="caption">{{'Fuel burn rate (kg/min/engine)'|translate}}</span>
                <input type="number" min="0" step="any" [(ngModel)]="item.FuelBurnLitresPerMinute" name="FuelBurnLitresPerMinute" style="width: 80px;" />
            </label>
            <label>
                <span class="caption">{{'Anti-icing fluid quantity wings (L)'|translate}}</span>
                <input type="number" min="0" step="any" [(ngModel)]="item.FluidQuantityWingsLitres" name="FluidQuantityWingsLitres" style="width: 80px;" />
            </label>
            <label>
                <span class="caption">{{'Anti-icing fluid quantity tail (L)'|translate}}</span>
                <input type="number" min="0" step="any" [(ngModel)]="item.FluidQuantityTailLitres" name="FluidQuantityTailLitres" style="width: 80px;" />
            </label>
            <br />
            <h3>{{'ACARS display'|translate}}</h3>
            <label>
                <span class="caption">{{'Number of characters per line'|translate}}</span>
                <input required type="number" min="24" step="1" [(ngModel)]="item.AcarsNumberOfCharactersPerLine" name="AcarsNumberOfCharactersPerLine" style="width: 100px;" />
            </label>
            <label>
                <span class="caption">{{'Number of lines per page'|translate}}</span>
                <input required type="number" min="9" step="1" [(ngModel)]="item.AcarsNumberOfLinePerPage" name="AcarsNumberOfLinePerPage" style="width: 100px;" />
            </label>
        </fieldset>

    </div>
</form>


