<page-title>{{('Weather type NG to category mapping profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins">
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('mappings')" [class.active]="tab == 'mappings'" [class.invalid]="!ngForm.form.controls.mappingsForm?.valid">{{'Mappings'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}"/>
        <input type="button" *ngIf="'DataChanges'|hasPermission" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}"/>
        <button *ngIf="('ConfigEditWeatherTypeNgToCategoryMappingProfiles'|hasPermission) && tab == 'mappings'" type="button" class="green" (click)="addMapping()">{{'Add mapping'|translate}}</button>
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditWeatherTypeNgToCategoryMappingProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
    </fieldset>

    <fieldset class="form" [disabled]="!('ConfigEditWeatherTypeNgToCategoryMappingProfiles'|hasPermission)" [hidden]="tab != 'mappings'" ngModelGroup="mappingsForm">
        <table class="table">
            <tr>
                <th>{{'Weather type NG'|translate}}</th>
                <th>{{'Weather category'|translate}}</th>
                <th></th>
            </tr>
            <tr *ngFor="let mapping of item.WeatherTypeNgToCategoryMappings; let i = index">
                <td>
                    <select [(ngModel)]="mapping.WeatherTypeNg" [name]="'mapping[' + i + '].WeatherTypeNg'" required>
                        <option *ngFor="let option of weatherTypeNgs|keys" [ngValue]="option">
                            {{option + ' (' + weatherTypeNgs[option] + ')'}}
                        </option>
                    </select>
                </td>
                <td>
                    <select [(ngModel)]="mapping.WeatherCategory" [name]="'mapping[' + i + '].WeatherCategory'" required>
                        <option *ngFor="let option of weatherCategories|keys" [ngValue]="option">
                            {{option + ' (' + weatherCategories[option] + ')'}}
                        </option>
                    </select>
                </td>
                <td>
                    <a *ngIf="('ConfigEditWeatherTypeNgToCategoryMappingProfiles'|hasPermission)" (click)="removeMapping(mapping)" class="mdi mdi-delete"></a>
                </td>
            </tr>
        </table>
    </fieldset>
</form>
