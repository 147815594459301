import {ModuleWithProviders, NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import { FormsModule } from '@angular/forms';
import { AgGridModule } from "ag-grid-angular";
import { SwxModule } from "../Swx.module";
import {ChangesDialogComponent} from "./ChangesDialog.component";
import {DiffDialogComponent} from "./DiffDialog.component";
import {GridModule} from "../grid/Grid.module";
import {EnvironmentConfiguration} from "../EnvironmentConfiguration.model";

@NgModule({
    imports: [
        CommonModule,
        AgGridModule,
        SwxModule,
        FormsModule,
        GridModule,
    ],
    declarations: [
        ChangesDialogComponent,
        DiffDialogComponent,
    ],
    exports: [
        ChangesDialogComponent,
        DiffDialogComponent,
    ],
})
export class ChangesModule {
    static forRoot(
        environment: EnvironmentConfiguration,
    ): ModuleWithProviders<ChangesModule> {
        return {
            ngModule: ChangesModule,
            providers: [
                {
                    provide: EnvironmentConfiguration,
                    useValue: environment
                },
            ],
        }
    }
}
