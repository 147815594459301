<div style="display: flex; flex-direction: column; height: 100%">
    <div class="toolbar gridFilters">
        <div class="field inline">
            <span class="caption">{{'Client'|translate}}</span>
            <multiselect [(ngModel)]="query.ClientId" name="query.ClientId">
                <multiselect-option *ngFor="let client of clients|orderBy:'Name'" [value]="client.Id" [name]="client.Name"></multiselect-option>
            </multiselect>
        </div>
        <div class="field inline">
            <span class="caption">{{'Airport'|translate}}</span>
            <multiselect [(ngModel)]="query.AirportId" name="query.AirportId">
                <multiselect-option *ngFor="let airport of airports|orderBy:['-HasStation', 'ICAOCode']" [value]="airport.Id" [name]="airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name"></multiselect-option>
            </multiselect>
        </div>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="query.FromDate">
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
        </label>
        <input type="button" (click)="refresh()" [value]="'Apply'|translate" />
    </div>
    <grid #grid gridId="noaaWpcAlerts" [resource]="resource" [query]="query" [columnDefs]="columnDefs" [parent]="this" [headerHeight]="90"></grid>
</div>
