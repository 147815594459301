<div class="noMargins">
    <page-title>{{'Event test'|translate}}</page-title>
    <form class="contentMargins form" #form="ngForm" (ngSubmit)="send()">
        <label>
            <span class="caption">{{'Event name'|translate}}</span>
            <input type="text" required [(ngModel)]="query.EventName" style="width: 400px;" name="eventName"/>
        </label>
        <label>
            <span class="caption">{{'Data'|translate}}</span>
            <textarea required [(ngModel)]="query.Data" style="width: 600px; height: 200px;" name="data"></textarea>
        </label>
        <br />
        <input type="submit" [disabled]="!form.valid" [value]="'Send'|translate" />
    </form>
    <div class="result" *ngIf="!!result">
        <h2>{{'Result'|translate}}</h2>
        <pre>{{result|json}}</pre>
    </div>
</div>

