import {ModuleWithProviders, NgModule} from "@angular/core";
import {OAuthService} from "./OAuth.service";
import {AuthConfig} from "./AuthConfig.service";
import {AuthInterceptorService} from "./AuthInterceptor.service";
import { AuthenticationService } from './Authentication.service';

@NgModule()
export class AuthModule {
    static forRoot(config: AuthConfig): ModuleWithProviders<AuthModule> {
        return {
            ngModule: AuthModule,
            providers: [{
                provide: AuthConfig,
                useValue: config
            },
                OAuthService,
                AuthInterceptorService,
                AuthenticationService,
            ]
        }
    }
}
