<div id="userList" class="noMargins">
    <page-title>{{('Users'|translate)}}</page-title>
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="grid.searchQuery" name="searchQuery" />
        </label>
        <label class="inline">
            <input type="checkbox" [(ngModel)]="grid.includeInactive" name="includeInactive" />
            <span class="caption">{{'Include inactive'|translate}}</span>
        </label>
        <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
        <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button type="button" (click)="refresh()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid"><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid"><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
        <button *ngIf="'ConfigEditUsers'|hasPermission" routerLink="/users/new" type="button" class="green">{{'Add user'|translate}}</button>
    </form>

    <config-grid #grid
          gridId="preferences.User.ColumnExport"
          [query]="query"
          [resource]="api.User"
          [exportResource]="api.UserExport"
          [columnDefs]="columnDefs">
    </config-grid>
</div>
