<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('SFTP servers'|translate) + ': ' + (item.Name || 'new')}}</page-title>
    <div id="userEditPage" class="noMargins">
        <div class="toolbar">
            <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <a class="tab" (click)="switchTab('serviceProviders')" [class.active]="tab == 'serviceProviders'" [class.invalid]="!ngForm.form.controls.serviceProvidersForm?.valid">{{'Deicing Service Providers'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        </div>

        <fieldset class="contentMargins form" [disabled]="!('ConfigEditSFTPServerConfigurations'|hasPermission)" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="Name" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="Active" />
            </label>
            <label>
                <span class="caption">{{'Type'|translate}}</span>
                <select [(ngModel)]="item.Type" name="item.Tyoe" required [selectedIndex]="-1">
                    <option *ngFor="let option of configurationTypes|keys" [ngValue]="option" [class]="option">
                        {{configurationTypes[option]}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Host SFTP server'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Host" name="Host" />
            </label>
            <label>
                <span class="caption">{{'Hostname'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Hostname" name="Hostname" />
            </label>
            <label>
                <span class="caption">{{'Port'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Port" name="Port" />
            </label>
            <label>
                <span class="caption">{{'Username'|translate}}</span>
                <input type="text" [(ngModel)]="item.Username" name="Username" required />
            </label>
            <label>
                <span class="caption">{{'Password'|translate}}</span>
                <input type="text" [(ngModel)]="item.Password" name="Password" required />
            </label>
            <label>
                <span class="caption">{{'Folder'|translate}}</span>
                <input type="text" [(ngModel)]="item.Folder" name="Folder" required />
            </label>
            <label *ngIf="!item.Host">
                <span class="caption">{{'Refresh interval (seconds)'|translate}}</span>
                <input type="number" [(ngModel)]="item.RefreshIntervalSeconds" name="RefreshIntervalSeconds" />
            </label>
            <label *ngIf="!item.Host">
                <span class="caption">{{'Delete processed files?'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.DeleteProcessedFiles" name="DeleteProcessedFiles" />
            </label>
            <label *ngIf="item.Host">
                <span class="caption">{{'Client'|translate}}</span>
                <select [(ngModel)]="item.ClientId" name="item.ClientId" required>
                    <option *ngFor="let option of clients|orderBy:'Name'" [ngValue]="option.Id">
                        {{option.Name}}
                    </option>
                </select>
            </label>
        </fieldset>

        <fieldset class="contentMargins form" [disabled]="!('ConfigEditSFTPServerConfigurations'|hasPermission)" [style.display]="tab == 'serviceProviders' ? '' :'none'" ngModelGroup="serviceProvidersForm">
            <label class="inline">
                <select [(ngModel)]="serviceProvider" name="serviceProvider">
                    <option *ngFor="let serviceProvider of serviceProviders|sftpServerServiceProviderNotEnabled:item.SFTPServerConfigurationDeicingServiceProviders|orderBy:'Name'" [value]="serviceProvider.Id">
                        {{serviceProvider.Name}}
                    </option>
                </select>
                <input type="button" class="green" *ngIf="'ConfigEditSFTPServerConfigurations'|hasPermission" (click)="addServiceProvider()" value="{{'Add service provider'|translate}}" [disabled]="serviceProvider == null" />
            </label>
            <br />
            <table class="table">
                <tr>
                    <th>{{'Name'|translate}}</th>
                    <th></th>
                </tr>
                <tr *ngFor="let SFTPServerConfigurationDeicingServiceProvider of item.SFTPServerConfigurationDeicingServiceProviders">
                    <td>{{(serviceProviders|findById:SFTPServerConfigurationDeicingServiceProvider.DeicingServiceProviderId).Name}}</td>
                    <td>
                        <a *ngIf="'ConfigEditSFTPServerConfigurations'|hasPermission" (click)="removeServiceProvider(SFTPServerConfigurationDeicingServiceProvider)" class="mdi mdi-delete"></a>
                    </td>
                </tr>
            </table>
        </fieldset>
    </div>
</form>
