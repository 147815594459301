<div class="noMargins" *ngIf="vm">
    <page-title>{{'HOT simulator'|translate}}</page-title>
    <form class="contentMargins form" #form="ngForm" (ngSubmit)="send()">
        <label>
            <span class="caption">{{'Client'|translate}}</span>
            <select [(ngModel)]="request.ClientId" required name="ClientId">
                <option *ngFor="let client of vm.clients|orderBy:'Name'" [ngValue]="client.Id">{{client.Name}}</option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Airport'|translate}}</span>
            <select [(ngModel)]="request.AirportId" required name="AirportId">
                <option *ngFor="let airport of vm.airports|orderBy:['-HasStation', 'ICAOCode']" [ngValue]="airport.Id">{{airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name}}</option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Equipment type'|translate}}</span>
            <select [(ngModel)]="request.EquipmentTypeId" name="EquipmentTypeId">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let equipmentType of vm.equipmentTypes|orderBy:['IATACode', 'ICAOCode']" [ngValue]="equipmentType.Id">
                    {{equipmentType.IATACode + '/' + (equipmentType.ICAOCode || ('N/A'|translate)) + ' ' + equipmentType.ManufacturerAndModelDescription}}
                </option>
            </select>
        </label>

        <fieldset style="border: 1px solid gray; margin: 10px 0; padding: 10px;">
            <legend>{{'Weather profile'|translate}}</legend>
            <label>
                <span class="caption">{{'Weather category'|translate}}</span>
                <select [(ngModel)]="request.WeatherCategory" name="WeatherCategory">
                    <option [ngValue]="null">{{'Not available'}}</option>
                    <option *ngFor="let weatherCategory of vm.weatherCategories|keys" [ngValue]="weatherCategory">{{vm.weatherCategories[weatherCategory] + ' (' + weatherCategory + ')'}}</option>
                </select>
            </label>
            <label>
                <span class="caption">{{'LWE (g/dm²/h)'|translate}}</span>
                <input type="number" [(ngModel)]="request.LWE" step="any" name="LWE"/>
            </label>
            <label>
                <span class="caption">{{'Temperature (°C)'|translate}}</span>
                <input type="number" [(ngModel)]="request.LWETemperature" step="any" name="LWETemperature"/>
            </label>
        </fieldset>

        <fieldset style="border: 1px solid gray; margin: 10px 0; padding: 10px;">
            <legend>{{'METAR'|translate}}</legend>
            <label>
                <span class="caption">{{'Weather type'|translate}}</span>
                <select [(ngModel)]="request.MetarWeatherType" name="MetarWeatherType">
                    <option [ngValue]="null">{{'Not available'}}</option>
                    <option *ngFor="let metarWeatherType of vm.metarWeatherTypes|keys" [ngValue]="metarWeatherType">
                        {{vm.metarWeatherTypes[metarWeatherType]}}
                    </option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Temperature (C)'|translate}}</span>
                <input type="number" [(ngModel)]="request.METARTemperature" step="1" name="METARTemperature"/>
            </label>
            <label>
                <span class="caption">{{'Visibility'|translate}}</span>
                <input type="text" [(ngModel)]="request.METARVisibility" name="METARVisibility"/>
            </label>
        </fieldset>

        <label>
            <span class="caption">{{'Override temperature (C)'|translate}}</span>
            <input type="number" [(ngModel)]="request.OverrideTemperature" step="1" name="OverrideTemperature"/>
        </label>

        <br />
        <input type="submit" [disabled]="!form.valid" [value]="'Send'|translate" />
        
        <div class="result" *ngIf="!!result">
            <h2>{{'Result'|translate}}</h2>
            <pre>{{result|json}}</pre>
        </div>
    </form>
</div>


