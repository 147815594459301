<table class="table" style="width: 100%;">
    <tr>
        <th>{{'HOT response errors'|translate}}</th>
    </tr>
    <tr>
        <td>{{hot.HotResponse.Error || '&nbsp;'}}</td>
    </tr>
    <tr>
        <th>{{'ACARS request errors'|translate}}</th>
    </tr>
    <tr>
        <td>{{hot.AcarsRequestErrors || '&nbsp;'}}</td>
    </tr>
    <tr>
        <th>{{'HOT calculation errors'|translate}}</th>
    </tr>
    <tr *ngIf="hot.HotResponseHot">
        <td>{{hot.HotResponseHot.Errors || '&nbsp;'}}</td>
    </tr>
    <!-- TODO: fluidHOt? -->
<!--    <tr *ngIf="fluidHot in hot.Hots">-->
<!--        <td>{{fluidHot.FluidName + ': ' + fluidHot.HotResponseHot.Errors}}</td>-->
<!--    </tr>-->
    <tr>
        <th>{{'Weather profile errors'|translate}}</th>
    </tr>
    <tr>
        <td>{{hot.WeatherProfile.Errors || '&nbsp;'}}</td>
    </tr>
</table>
