<page-title>{{('FZFG alert profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('equipmentTypes')" [class.active]="tab == 'equipmentTypes'" [class.invalid]="!ngForm.form.controls.equipmentTypesForm?.valid">{{'Equipment types'|translate}}</a>
        <a class="tab" (click)="switchTab('acars')" [class.active]="tab == 'acars'" [class.invalid]="!ngForm.form.controls.acarsForm?.valid">{{'ACARS'|translate}}</a>
        <a class="tab" (click)="switchTab('emails')" [class.active]="tab == 'emails'" [class.invalid]="!ngForm.form.controls.emailsForm?.valid">{{'Emails'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditFreezingFogAlertProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100"/>
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active"/>
        </label>
        <label>
            <span class="caption">{{'Client'|translate}}</span>
            <select [(ngModel)]="item.ClientId" name="item.ClientId">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let client of clients|orderBy:'Name'" [ngValue]="client.Id">
                    {{client.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Email sender'|translate}}</span>
            <select required [(ngModel)]="item.EmailFromId" name="item.EmailFromId">
                <option *ngFor="let emailFrom of emailFroms|orderBy:'Id'" [ngValue]="emailFrom.Id">
                    {{emailFrom.EmailAddress}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'ON->IN timeout (minutes)'|translate}}</span>
            <input type="number" [(ngModel)]="item.InTimeoutMinutes" name="item.InTimeoutMinutes" required step="1" min="0" style="width: 70px;" />
        </label>
        <label>
            <span class="caption">{{'IN->OUT timeout (minutes)'|translate}}</span>
            <input type="number" [(ngModel)]="item.OutTimeoutMinutes" name="item.OutTimeoutMinutes" required step="1" min="0" style="width: 70px;" />
        </label>
        <label>
            <span class="caption">{{'OUT->OFF timeout (minutes)'|translate}}</span>
            <input type="number" [(ngModel)]="item.OffTimeoutMinutes" name="item.OffTimeoutMinutes" required step="1" min="0" style="width: 70px;" />
        </label>
        <label>
            <span class="caption">{{'Max. # of ACARS alerts per hour'|translate}}</span>
            <input type="number" [(ngModel)]="item.MaxAlertsPerHour" name="item.MaxAlertsPerHour" step="1" min="0" style="width: 70px;" />
        </label>
        <label>
            <span class="caption">{{'IBM MQ configuration to send ACARS'|translate}}</span>
            <select [(ngModel)]="item.IBMMQServerConfigurationId" name="item.IBMMQServerConfigurationId">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let ibmMqServerConfiguration of ibmMqServerConfigurations|orderBy:'Name'" [ngValue]="ibmMqServerConfiguration.Id">
                    {{ibmMqServerConfiguration.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'ACARS datalink service provider'|translate}}</span>
            <select required [(ngModel)]="item.DatalinkServiceProvider" name="item.DatalinkServiceProvider">
                <option *ngFor="let option of datalinkServiceProviders|keys" [ngValue]="option">
                    {{datalinkServiceProviders[option]}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'ACARS destination address'|translate}}</span>
            <input type="text" required [(ngModel)]="item.ACARSDestinationAddress" name="item.ACARSDestinationAddress" style="width: 100px;" />
        </label>
    </fieldset>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditFreezingFogAlertProfiles'|hasPermission)" [hidden]="tab != 'equipmentTypes'" ngModelGroup="equipmentTypesForm">
        <select [(ngModel)]="freezingFogAlertProfileEquipmentTypeOption" name="item.freezingFogAlertProfileEquipmentTypeOption">
            <option *ngFor="let equipmentType of equipmentTypesNotEnabled()|orderBy:['IATACode', 'ICAOCode']" [ngValue]="equipmentType">
                {{equipmentType.IATACode + '/' + (equipmentType.ICAOCode || ('N/A'|translate)) + ' ' + equipmentType.ManufacturerAndModelDescription}}
            </option>
        </select>
        <input type="button" *ngIf="('ConfigEditFreezingFogAlertProfiles'|hasPermission)" (click)="addFreezingFogAlertProfileEquipmentType(item, freezingFogAlertProfileEquipmentTypeOption); freezingFogAlertProfileEquipmentTypeOption = null;" value="{{'Add equipment type'|translate}}" [disabled]="freezingFogAlertProfileEquipmentTypeOption == null" />
        <br />
        <br />
        <table class="table">
            <tr>
                <th>{{'Equipment type'|translate}}</th>
                <th></th>
            </tr>
            <tr *ngFor="let freezingFogAlertProfileEquipmentType of item.FreezingFogAlertProfileEquipmentTypes|orderBy:'Order'; let i = index">
                <td>{{(equipmentTypes|findById:freezingFogAlertProfileEquipmentType.EquipmentTypeId)?.IATACode + ' / ' + ((equipmentTypes|findById:freezingFogAlertProfileEquipmentType.EquipmentTypeId)?.ICAOCode || ('N/A'|translate))}}</td>
                <td>
                    <a *ngIf="('ConfigEditFreezingFogAlertProfiles'|hasPermission)" (click)="removeFreezingFogAlertProfileEquipmentType(item, freezingFogAlertProfileEquipmentType)" class="mdi mdi-delete"></a>
                </td>
            </tr>
        </table>

    </fieldset>
    
    <fieldset class="contentMargins form" [disabled]="!('ConfigEditFreezingFogAlertProfiles'|hasPermission)" [hidden]="tab != 'acars'" ngModelGroup="acarsForm">
        <label>
            <span class="caption">{{'Available tokens'|translate}}</span>
            <span class="value">{{'{airportCode} {tailNumber} {flightNumber} {fzfgMinutes}'}}</span>
        </label>
        <br />
        <table class="table">
            <tr>
                <th>
                    {{'FZFG interval every'}}
                    <input type="number" [(ngModel)]="item.IntervalAlertACARSRepeatMinutes" name="item.IntervalAlertACARSRepeatMinutes" step="1" min="0" style="width: 70px;" />
                    {{'minutes'|translate}}
                </th>
                <th>{{'IN'}}</th>
                <th>{{'OUT'}}</th>
                <th>{{'OFF'}}</th>
            </tr>
            <tr>
                <td>
                    <table class="table">
                        <tr><th>{{'Subject line'|translate}}</th></tr>
                        <tr><td><input type="text" [(ngModel)]="item.IntervalAlertACARSSubject" name="item.IntervalAlertACARSSubject" style="width: 270px;" /></td></tr>
                        <tr><th>{{'Content'|translate}}</th></tr>
                        <tr><td><textarea [(ngModel)]="item.IntervalAlertACARSBody" name="item.IntervalAlertACARSBody" cols="24" rows="10" style="font-family: monospace; white-space: pre; overflow: auto;"></textarea></td></tr>
                    </table>
                </td>
                <td>
                    <table class="table">
                        <tr><th>{{'Subject line'|translate}}</th></tr>
                        <tr><td><input type="text" [(ngModel)]="item.InACARSSubject" name="item.InACARSSubject" style="width: 270px;" /></td></tr>
                        <tr><th>{{'Content'|translate}}</th></tr>
                        <tr><td><textarea [(ngModel)]="item.InACARSBody" name="item.InACARSBody" cols="24" rows="10" style="font-family: monospace; white-space: pre; overflow: auto;"></textarea></td></tr>
                    </table>
                </td>
                <td>
                    <table class="table">
                        <tr><th>{{'Subject line'|translate}}</th></tr>
                        <tr><td><input type="text" [(ngModel)]="item.OutACARSSubject" name="item.OutACARSSubject" style="width: 270px;" /></td></tr>
                        <tr><th>{{'Content'|translate}}</th></tr>
                        <tr><td><textarea [(ngModel)]="item.OutACARSBody" name="item.OutACARSBody" cols="24" rows="10" style="font-family: monospace; white-space: pre; overflow: auto;"></textarea></td></tr>
                    </table>
                </td>
                <td>
                    <table class="table">
                        <tr><th>{{'Subject line'|translate}}</th></tr>
                        <tr><td><input type="text" [(ngModel)]="item.OffACARSSubject" name="item.OffACARSSubject" style="width: 270px;" /></td></tr>
                        <tr><th>{{'Content'|translate}}</th></tr>
                        <tr><td><textarea [(ngModel)]="item.OffACARSBody" name="item.OffACARSBody" cols="24" rows="10" style="font-family: monospace; white-space: pre; overflow: auto;"></textarea></td></tr>
                    </table>
                </td>
            </tr>
        </table>
    </fieldset>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditFreezingFogAlertProfiles'|hasPermission)" [hidden]="tab != 'emails'" ngModelGroup="emailsForm">
        <label>
            <span class="caption">{{'Available tokens'|translate}}</span>
            <span class="value">{{'{airportCode} {tailNumber} {flightNumber} {fzfgMinutes}'}}</span>
        </label>
        <br />
        <table class="table" style="margin-bottom: 20px; width: 100%;">
            <tr>
                <th>
                    {{'FZFG interval every'}}
                    <input type="number" [(ngModel)]="item.IntervalAlertEmailRepeatMinutes" name="item.IntervalAlertEmailRepeatMinutes" step="1" min="0" style="width: 70px;" />
                    {{'minutes'|translate}}
                </th>
            </tr>
            <tr><td><input type="text" [(ngModel)]="item.IntervalAlertEmailRecipient" name="item.IntervalAlertEmailRecipient" style="width: 450px;" [placeholder]="'Recipients'|translate" /></td></tr>
            <tr><td><input type="text" [(ngModel)]="item.IntervalAlertEmailSubject" name="item.IntervalAlertEmailSubject" style="width: 450px;" [placeholder]="'Subject'|translate" /></td></tr>
            <tr><td><ckeditor [editor]="IntervalEditor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="item.IntervalAlertEmailBody" [name]="'item.IntervalAlertEmailBody'"></ckeditor></td></tr>
        </table>

        <table class="table" style="margin-bottom: 20px; width: 100%;">
            <tr>
                <th>{{'In'|translate}}</th>
            </tr>
            <tr><td><input type="text" [(ngModel)]="item.InEmailRecipient" name="item.InEmailRecipient" style="width: 450px;" [placeholder]="'Recipients'|translate" /></td></tr>
            <tr><td><input type="text" [(ngModel)]="item.InEmailSubject" name="item.InEmailSubject" style="width: 450px;" [placeholder]="'Subject'|translate" /></td></tr>
            <tr><td><ckeditor [editor]="InEditor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="item.InEmailBody" [name]="'item.InEmailBody'"></ckeditor></td></tr>
        </table>

        <table class="table" style="margin-bottom: 20px; width: 100%;">
            <tr>
                <th>{{'Out'|translate}}</th>
            </tr>
            <tr><td><input type="text" [(ngModel)]="item.OutEmailRecipient" name="item.OutEmailRecipient" style="width: 450px;" [placeholder]="'Recipients'|translate" /></td></tr>
            <tr><td><input type="text" [(ngModel)]="item.OutEmailSubject" name="item.OutEmailSubject" style="width: 450px;" [placeholder]="'Subject'|translate" /></td></tr>
            <tr><td><ckeditor [editor]="OutEditor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="item.OutEmailBody" [name]="'item.OutEmailBody'"></ckeditor></td></tr>
        </table>

        <table class="table" style="margin-bottom: 20px; width: 100%;">
            <tr>
                <th>{{'OFF'|translate}}</th>
            </tr>
            <tr><td><input type="text" [(ngModel)]="item.OffEmailRecipient" name="item.OffEmailRecipient" style="width: 450px;" [placeholder]="'Recipients'|translate" /></td></tr>
            <tr><td><input type="text" [(ngModel)]="item.OffEmailSubject" name="item.OffEmailSubject" style="width: 450px;" [placeholder]="'Subject'|translate" /></td></tr>
            <tr><td><ckeditor [editor]="OffEditor" [config]="ckConfig" style="width: 100%;" [(ngModel)]="item.OffEmailBody" [name]="'item.OffEmailBody'"></ckeditor></td></tr>
        </table>
    </fieldset>
</form>
