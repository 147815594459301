import {Component, Inject, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import {
    ChangesService,
    ConfirmService,
    EllipsisPipe,
    FileDownloadService,
    IAgGridColGroupDef,
    IAgGridColumnDef,
    IResourceItem,
    NotificationService,
    QuerySerializerService,
    TranslateService
} from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { FilterPipe } from 'swx.front-end-lib';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import moment from "moment/moment";
import {MDMStateCellRendererService} from "./MDMStateCellRenderer.service";
import {RootScope} from "../shared/RootScope.service";
import {DOCUMENT} from "@angular/common";
import {AppstoreconnectService} from "../appstoreconnect/appstoreconnect.service";


@Component({
    templateUrl: 'MobileAppDetail.component.html',
})
export class MobileAppDetailComponent implements OnInit {
    item;
    returnPath;
    @ViewChild('ngForm', { static: true }) ngForm;
    tab: string;
    clientApiTokens = this.api.ClientApiToken.query();
    mobileAppPlatforms = this.api.MobileAppPlatform.query();
    mobileAppAudiences = this.api.MobileAppAudience.query();
    releases = [];
    releaseColumnDefs: IAgGridColumnDef[] = [
        { colId: "Id", field: "Id", headerName: "#", width: 60, filterType: "integer" },
        { colId: "Active", field: "Active", headerName: "Active?", width: 70, valueFormatter: c => c.value == null ? '' : c.value ? 'Yes' : 'No', filterType: 'boolean', },
        { colId: "Version", field: "Version", headerName: "Version", width: 100, cellRenderer: c => {
            if (c.data == null) return '';
            var container = this.document.createElement('div');
            container.innerHTML = '<a href="/mobileAppReleases/' + c.data.Id + '" onclick="event.preventDefault();">' + c.value + '</a>';
            container.querySelector('a').addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href')));
            return container;
        } },
        { colId: "ShowOnPortal", field: "ShowOnPortal", headerName: "Show on portal?", width: 80, cellRenderer: c => {
            if (c.data == null) return '';
            var container = this.document.createElement('div');
            container.innerHTML = '<input type="checkbox">';
            var checkbox = container.querySelector('[type=checkbox]') as HTMLInputElement;
            checkbox.checked = c.value;
            checkbox.addEventListener('change', () => {
                this.api.MobileAppRelease.patch(c.data.Id,
                    {
                        ShowOnPortal: checkbox.checked,
                    });
            });
            return container;
        } },
        { colId: "Uploaded", field: "Uploaded", headerName: "Upload", width: 160, cellRenderer: c => this.mdmStateCellRendererService.mdmStateCellRenderer(c, () => this.refreshReleases(), this.viewContainerRef, true), filterType: 'boolean', },
        { colId: "MobileConfigurationRequestDate", field: "MobileConfigurationRequestDate", headerName: "First launch date", width: 90, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateFormat), filterType: "date" },
        { colId: "Comments", field: "Comments", headerName: "Developer comments", width: 120, valueFormatter: c => c.value == null ? '' : EllipsisPipe.instance.transform(c.value, 50) },
        { colId: "PortalComments", field: "PortalComments", headerName: "Portal comments", width: 120, valueFormatter: c => c.value == null ? '' : EllipsisPipe.instance.transform(c.value, 50) },
        { colId: "ExpirationDate", field: "ExpirationDate", headerName: "Expiration date", width: 100, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateFormat), filterType: "date" },
        { colId: "BuildFilename", field: "BuildFilename", headerName: "Build", width: 200, cellTemplate: "<a (click)='parent.downloadBuildFile(params.data)'>{{params.value}}</a>" },
        { colId: "XArchiveFilename", field: "XArchiveFilename", headerName: ".xarchive", width: 200, cellRenderer: c => {
            var container = this.document.createElement('a');
            if (c.data?.XArchiveFileUploadId != null) {
                container.innerHTML = c.data.XArchiveFilename;
                container.addEventListener('click', () => this.api.FileUploadUrl.get(c.data.XArchiveFileUploadId).$promise
                    .then(response => this.fileDownloadService.downloadUrl(response.Url, response.Filename)));
            }
            return container;
        } },
        { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 120, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateTimeFormat), filterType: "date" },
        { colId: "CreatedByUserName", field: "CreatedByUserName", headerName: "Created by", width: 120 },
        { colId: "LastModified", field: "LastModified", headerName: "Modified date", width: 120, valueFormatter: c => c.value == null ? '' : moment.utc(c.value).format(this.$root.dateTimeFormat), filterType: "date" },
        { colId: "LastModifiedByUserName", field: "LastModifiedByUserName", headerName: "Modified by", width: 120 },
        { colId: "_spacer", flex: 1, excelIgnore: true },
        { colId: "Actions", field: "Id", headerName: "", width: 80, cellClass: "actions", cellRenderer: c => {
            if (c.data == null) return '';
            var container = this.document.createElement('div');
            container.innerHTML = '<a href="/mobileAppReleases/' + c.data.Id + '" class="mdi mdi-pencil" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();"></a>'
                + (this.hasPermissionService.hasPermission('ConfigEditMobileAppReleases') ? '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>' : '')
                + (this.hasPermissionService.hasPermission('ConfigEditMobileAppReleases') ? '<a href="/mobileAppReleases/new?copy=' + c.data.Id + '" class="mdi mdi-content-copy" title="' + this.translateService.translate('Copy') + '" onclick="event.preventDefault();"></a>' : '');
            Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href'))));
            Array.from(container.querySelectorAll('.mdi-delete')).forEach(n => n.addEventListener('click', () => this.deleteRelease(c.data)));
            return container;
        }, pinned: "right", searchable: false, excelIgnore: true },
    ];
    
    get isIos(): boolean {
        return !!(this.item?.Platform === 'IOSEnterprise' || 
            this.item?.Platform === 'IOSVPP' ||
            this.item?.Platform === 'IOSUnlistedApp' ||
            this.item?.Platform === 'IOSTestFlight' ||
            this.item?.Platform === 'IOSXCArchive'); 
    }
    
    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private translateService: TranslateService,
        private fileDownloadService: FileDownloadService,
        private confirmService: ConfirmService,
        private mdmStateCellRendererService: MDMStateCellRendererService,
        private $root: RootScope,
        private querySerializer: QuerySerializerService,
        @Inject(DOCUMENT) private document: Document,
        private notification: NotificationService,
        private appStore: AppstoreconnectService,
    ) {
        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';
        
        if (copyId) {
            this.item = this.api.MobileApp.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;
            });
        } else if (isNew) {
            this.item = this.api.MobileApp.create({
                Active: true,
            });
        } else {
            this.item = this.api.MobileApp.get({ id: id });
        }

        this.item.$promise.then((item) => {
            this.refreshReleases();
        });

        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';
    }
    
    filter = (items, searchQuery) => FilterPipe.instance.transform(items, searchQuery);

    save() {
        console.log('save')
        this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['MobileApp'],
            SubjectId: this.item.Id
        });
    }

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    }

    refreshReleases() {
        if (this.item.Id) {
            this.api.MobileAppRelease
                .query(this.querySerializer.toHttpParams({
                    Filters: [
                        {
                            Field: 'MobileAppId',
                            Operator: 'Equals',
                            Value: this.item.Id
                        }
                    ]
                }))
                .$promise
                .then(result => {
                    this.releases = result;
                });
        }
    }

    deleteRelease(item: IResourceItem) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.ticketTrackingSystem.trackAndDelete(this.viewContainerRef, item, () => this.refreshReleases());
        }).catch(() => {});
    }

    downloadBuildFile(row) {
        this.api.FileUploadUrl.get(row.BuildFileUploadId).$promise
            .then(response => this.fileDownloadService.downloadUrl(response.Url, response.Filename));
    }
    
    confirmDeleteIcon() {
        const confirm = this.document.defaultView.confirm;
        if (confirm(this.translateService.translate('Are you sure?'))) {
            this.item.AppIcon = null;
            this.ngForm.form.markAsDirty();
        }
    }

    confirmDeleteSplashScreen() {
        const confirm = this.document.defaultView.confirm;
        if (confirm(this.translateService.translate('Are you sure?'))) {
            this.item.SplashScreen = null;
            this.ngForm.form.markAsDirty();
        }
    }
    appIconChanged(newIcon: string) {
        const logoSize = 1024;
        const isValidIcon = (img: HTMLImageElement) => img.width === logoSize && img.height === logoSize;
        const img = new Image();
        img.src = newIcon;

        img.onload = () => {
            if (isValidIcon(img)) {
                this.item.AppIcon = newIcon;
            }
            else {
                this.notification.show(
                    this.translateService.translate(`App Icon must be ${logoSize}x${logoSize}`), { type: 'error' });
            }
        };
    }

    splashScreenChanged(newSplashScreen: string) {
        const logoSize = 2732;
        const isValid = (img: HTMLImageElement) => img.width === logoSize && img.height === logoSize;
        const img = new Image();
        img.src = newSplashScreen;

        img.onload = () => {
            if (isValid(img)) {
                this.item.SplashScreen = newSplashScreen;
            }
            else {
                this.notification.show(
                    this.translateService.translate(`Splash screen must be ${logoSize}x${logoSize}`), { type: 'error' });
            }
        };
    }
    bundleId: string;
    
    ngOnInit() {
        this.item.$promise.then((mobileAppResource: any) => {
            if (mobileAppResource.Platform === 'IOSVPP') {
                this.bundleId = mobileAppResource.AppId;
            }
        })
    }
}
