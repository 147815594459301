<filter *ngFor="let filter of filters" [field]="availableFields.get(filter.Field)" [filter]="filter">
    <button type="button" (click)="removeFilter(filter)"><span class="mdi">❌</span></button>
</filter>
<div class="field">
    <span class="caption"><span class="mdi mdi-filter" style="margin-right: 5px;"></span></span>
    <select #availableFieldElement [(ngModel)]="availableField" (change)="addFilter(availableField); availableFieldElement.selectedIndex = -1;">
        <ng-container *ngFor="let group of groups">
            <optgroup *ngIf="group.Label" [label]="group.Label">
                <option *ngFor="let availableFieldName of group.Fields" [ngValue]="availableFields.get(availableFieldName)">{{availableFields.get(availableFieldName).Label}}</option>
            </optgroup>
            <ng-container *ngIf="!group.Label">
                <option *ngFor="let availableFieldName of group.Fields" [ngValue]="availableFields.get(availableFieldName)">{{availableFields.get(availableFieldName).Label}}</option>
            </ng-container>
        </ng-container>
    </select>
</div>
