<div class="noMargins">
    <page-title>{{('Changes'|translate)}}</page-title>
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <div class="field inline" *ngIf="users.length > 1">
            <span class="caption">{{'User'|translate}}</span>
            <multiselect name="query.UserId" [(ngModel)]="query.UserId">
                <multiselect-option *ngFor="let option of users|orderBy:'Name'" [value]="option.Id" [name]="option.Name"></multiselect-option>
            </multiselect>
        </div>
        <div class="field inline">
            <span class="caption">{{'Section'|translate}}</span>
            <multiselect name="query.SubjectType" [(ngModel)]="query.SubjectType">
                <multiselect-option *ngFor="let option of subjectTypes|keys" [value]="option" [name]="subjectTypes[option]"></multiselect-option>
            </multiselect>
        </div>
        <label class="inline">
            <span class="caption">{{'Ticket number'|translate}}</span>
            <input type="number" min="0" step="1" [(ngModel)]="query.TrackedTicketId" name="query.TrackedTicketId" style="width: 60px;" />
        </label>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="FromDate" />
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="ToDate" />
        </label>
        <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
        <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button type="button" (click)="refresh()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid" ><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
    </form>

    <grid #grid
          gridId="preferences.Change.ColumnExport"
          [query]="query"
          [resource]="api.Change"
          [exportResource]="api.ChangeExport"
          [components]="components"
          [columnDefs]="columnDefs">
    </grid>
</div>
