<div id="stationDataGenV" class="noMargins">
    <page-title>{{'Station data for Gen V'|translate}}</page-title>
    <form #form="ngForm" class="toolbar gridFilters">
        <div class="field inline">
            <span class="caption">{{'Station'|translate}}</span>
            <multiselect [(ngModel)]="query.StationId" name="query.StationId">
                <multiselect-option *ngFor="let station of stations|filter:{StationType: 'GenV'}|orderBy:'Name'" [value]="station.Id" [name]="station.Name"></multiselect-option>
            </multiselect>
        </div>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="query.FromDate">
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
        </label>
        <filters [(ngModel)]="query.Filters" [columnDefs]="columnDefs" name="query.Filters"></filters>
        <button type="button" (click)="resetQuery()"><span class="mdi">❌</span> {{'Reset'|translate}}</button>
        <button type="button" (click)="refresh()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
        <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        <button type="button" (click)="grid.selectColumns()" [disabled]="!form.valid" ><span class="mdi mdi-table-edit"></span>{{'Columns'|translate}}</button>
    </form>
    <grid #grid gridId='preferences.StationDataGenV.ColumnExport' [resource]="resource" [exportResource]="exportResourcePost" [query]="query" [columnDefs]="columnDefs" [components]="components" [headerHeight]="70"></grid>
</div>
