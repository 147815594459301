<page-title>{{'Manual station entry'|translate}}</page-title>
<form class="form contentMargins" #entryForm="ngForm" (ngSubmit)="decode()">
    <label>
        <span class="caption">{{'Station'|translate}}</span>
        <select [(ngModel)]="stationId" required name="stationId">
            <option *ngFor="let station of stations|orderBy:'Name'" [ngValue]="station.Id">
                {{station.Name}}
            </option>
        </select>
    </label>
    <label>
        <span class="caption">{{'Station string'|translate}}</span>
        <textarea required [(ngModel)]="rawData" style="width: 600px; height: 400px;" name="rawData"></textarea>
    </label>
    <br />
    <input type="submit" [disabled]="!entryForm.valid" [value]="'Decode'|translate" />
</form>
<div class="result" *ngIf="!!result">
    <h2>{{'Result'|translate}}</h2>
    <pre>{{result|json}}</pre>
</div>
