import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";

@Component({
    templateUrl: 'StationReadingImport.component.html'
})
export class StationReadingImportComponent implements OnInit {
    stations: any;
    query: any;
    datePickerOptions: any;
    running = false;
    results: any;

    constructor(private api: ApiService) {
    }

    ngOnInit() {
        this.query = {
            DeleteExistingStationReadings: true,
            GenerateWeatherProfiles: true,
        };

        this.datePickerOptions = {
            maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
            dateFormat: 'yy-mm-dd',
            useUtc: true
        };

        this.api.Station.query({ IncludeInactive: false }).$promise.then(stations => {
            this.stations = stations;
        });
    }

    start() {
        this.running = true;
        this.results = '';

        this.api.StationReadingImport.post(this.query).then(response => {
            let subscription = this.api.StationReadingImportTask.stream({ taskId: response.TaskId }).subscribe((result:any) => {
                this.results += result + '\n';

                if (result === 'Done') {
                    subscription.unsubscribe();
                    this.running = false;
                }
            });
        }).catch(err => {
            this.running = false;
        });
    }
}

