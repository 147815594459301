import {AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {ConfirmService, IAgGridColumnDef, IResourceItem, TranslateService, ValueFormatters} from "swx.front-end-lib";
import {HasPermissionPipe, HasPermissionService} from "../shared/HasPermission.pipe";
import {Router} from "@angular/router";
import {TicketTrackingSystemService} from "../shared/TicketTrackingSystem.service";

@Component({
    templateUrl: './HoldoverTaxiTimePredictionList.component.html'
})
export class HoldoverTaxiTimePredictionListComponent implements AfterViewInit{
    @ViewChild('grid') grid;
    searchQuery = '';
    resource = this.api.HoldoverTaxiTimeModelConfiguration;
    exportResourcePost = this.api.HoldoverTaxiTimeModelConfigurationExport;
    includeInactive: boolean;
    query: any;
    columnDefs: IAgGridColumnDef[] = [
        { colId: "Id", field: "Id", headerName: "#", width: 50, filterType: 'integer', pinned: 'left' },
        { colId: "Name", field: "Name", headerName: "Name", width: 300, sortable: true, pinned: 'left', cellTemplate: `<a [routerLink]="['/holdoverTaxiTimePredictions', params.data?.Id]">{{params.data?.Name}}</a>` },
        { colId: "Active", field: "Active", headerName: "Active?", width: 70, filterType: 'boolean',  valueFormatter: this.formatters.yesNoFormatter() },
        { colId: "Actions", field: "Id", headerName: "", width: 80, cellClass: "actions", cellRenderer: c => {
                if (c.data == null) return '';
                var container = document.createElement('div');
                container.innerHTML = '<a href="/holdoverTaxiTimePredictions/' + c.data.Id + '" class="mdi mdi-pencil" title="' + this.translateService.translate('Edit') + '" onclick="event.preventDefault();"></a>'
                    + (this.hasPermission.hasPermission('ConfigEditHoldoverTaxiTimeModelConfigurations') ? '<a class="mdi mdi-delete" title="' + this.translateService.translate('Delete') + '" onclick="event.preventDefault();"></a>' : '')
                    + (this.hasPermission.hasPermission('ConfigEditHoldoverTaxiTimeModelConfigurations') ? '<a href="/holdoverTaxiTimePredictions/new?copy=' + c.data.Id + '" class="mdi mdi-content-copy" title="' + this.translateService.translate('Copy') + '" onclick="event.preventDefault();"></a>' : '');
                Array.from(container.querySelectorAll('a[href]')).forEach(n => n.addEventListener('click', e => this.router.navigateByUrl(e.target['getAttribute']('href'))));
                Array.from(container.querySelectorAll('.mdi-delete')).forEach(n => n.addEventListener('click', () => this.delete(c.data)));
                return container;
            }, pinned: "right", searchable: false, excelIgnore: true },
        { colId: "LastModified", field: "LastModified", headerName: "Modified date", width: 150, filterType: 'date', valueFormatter: this.formatters.utcDateTimeFormatter() },
        { colId: "LastModifiedByUserName", field: "LastModifiedByUserName", headerName: "Modified by", width: 100 },
        { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 150, filterType: 'date', valueFormatter: this.formatters.utcDateTimeFormatter() },
        { colId: "CreatedByUserName", field: "CreatedByUserName", headerName: "Created by", width: 100 },
        { colId: "InUseBy", field: "InUseBy", headerName: "In use by", width: 250 },
    ];
    
    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private formatters: ValueFormatters,
                private hasPermission: HasPermissionService,
                private translateService: TranslateService,
                private router: Router,
                private confirmService: ConfirmService,
                private ticketTrackingSystem: TicketTrackingSystemService) {
    }
    
    ngAfterViewInit() {
        this.refresh();
    }
    
    refresh() {
        this.grid.refresh();
    }

    delete(item: IResourceItem) {
        this.confirmService.confirm(this.translateService.translate('Are you sure?')).then(() => {
            this.ticketTrackingSystem.trackAndDelete(this.viewContainerRef, item, () => this.refresh());
        }).catch(() => {});
    }
}
