<sortable class="stationMonitoringConditionListSortable" #sortable [options]="{ placeholder: 'placeholder item', opacity: 0.6, cursor: 'move', connectWith : '.stationMonitoringConditionListSortable' }" (receive)="$event.item.ParentConditionId = parent?.Id; ngForm.form.markAsDirty();">
    <sortable-item class="item" *ngFor="let condition of childConditions()" [(ngModel)]="condition.Order" [name]="'condition[' + condition.Id + '].Order'" [item]="condition">
        <label class="dragIcon"><span class="mdi mdi-adjust"></span></label>
        <label class="actions">
            <a *ngIf="('ConfigDeleteStationMonitoringProfiles'|hasPermission)" (click)="removeStationMonitoringCondition(parent, condition);" class="mdi mdi-delete"></a>
        </label>

        <ng-container *ngIf="condition.ConditionType === 'Filter' && availableFields">
            <div class="field fieldName">
                {{condition.Field ? availableFields.get(condition.Field)?.GroupLabel : ''}}
                <select [(ngModel)]="condition.Field" [name]="'condition[' + condition.Id + '].Field'" required>
                    <ng-container *ngFor="let group of fieldGroups">
                        <optgroup *ngIf="group.Label" [label]="group.Label">
                            <option *ngFor="let availableFieldName of group.Fields" [ngValue]="availableFieldName">{{availableFields.get(availableFieldName)?.Label}}</option>
                        </optgroup>
                        <ng-container *ngIf="!group.Label">
                            <option *ngFor="let availableFieldName of group.Fields" [ngValue]="availableFieldName">{{availableFields.get(availableFieldName)?.Label}}</option>
                        </ng-container>
                    </ng-container>
                </select>
            </div>

            <div class="field condition" *ngIf="condition.Field && availableFields.get(condition.Field)">
                <select [name]="'condition[' + condition.Id + '].Operator'" [(ngModel)]="condition.Operator" required>
                    <option *ngFor="let option of availableFields.get(condition.Field).Operators|keys" [ngValue]="option">
                        {{availableFields.get(condition.Field).Operators[option]}}
                    </option>
                </select>
                <select *ngIf="availableFields.get(condition.Field).Type == 'boolean' && condition.Operator != 'Empty' && condition.Operator != 'NotEmpty'" [name]="'condition[' + condition.Id + '].Value'" [(ngModel)]="condition.Value">
                    <option [ngValue]="'true'">Yes</option>
                    <option [ngValue]="'false'">No</option>
                </select>
                <input *ngIf="(!availableFields.get(condition.Field).Type || availableFields.get(condition.Field).Type == 'string') && condition.Operator != 'Empty' && condition.Operator != 'NotEmpty'" type="text" [name]="'condition[' + condition.Id + '].Value'" [(ngModel)]="condition.Value" />
                <input date *ngIf="availableFields.get(condition.Field).Type == 'date' && condition.Operator != 'Empty' && condition.Operator != 'NotEmpty'" options="datePickerOptions" [name]="'condition[' + condition.Id + '].Value'" [(ngModel)]="condition.Value" style="width: 200px;" required />
                <input *ngIf="availableFields.get(condition.Field).Type == 'integer' && condition.Operator != 'Empty' && condition.Operator != 'NotEmpty'" type="number" step="1" [name]="'condition[' + condition.Id + '].Value'" [(ngModel)]="condition.Value" required />
                <input *ngIf="availableFields.get(condition.Field).Type == 'float' && condition.Operator != 'Empty' && condition.Operator != 'NotEmpty'" type="number" step="any" [name]="'condition[' + condition.Id + '].Value'" [(ngModel)]="condition.Value" required />
                <multiselect *ngIf="availableFields.get(condition.Field).Type == 'enum' && condition.Operator != 'Empty' && condition.Operator != 'NotEmpty'" [name]="'condition[' + condition.Id + '].Value'" [(ngModel)]="condition.Value" width="{{availableFields.get(condition.Field).MultiselectWidth}}" [all]="false" [format]="'string'">
                    <multiselect-option *ngFor="let option of availableFields.get(condition.Field).Options|keys" [value]="option" [name]="availableFields.get(condition.Field).Options[option]"></multiselect-option>
                </multiselect>
            </div>
        </ng-container>
        <ng-container *ngIf="condition.ConditionType === 'AndGroup' || condition.ConditionType === 'OrGroup'">
            <div class="field">
                <select required [(ngModel)]="condition.ConditionType" [name]="'condition[' + condition.Id + '].ConditionType'">
                    <option [ngValue]="'OrGroup'">{{'Any of'|translate}}</option>
                    <option [ngValue]="'AndGroup'">{{'All of'|translate}}</option>
                </select>
            </div>
            <div class="children">
                <station-monitoring-condition-list [ngForm]="ngForm" [conditions]="conditions" [parent]="condition" [parentList]="this"></station-monitoring-condition-list>
            </div>
        </ng-container>
    </sortable-item>
</sortable>
<button type="button" class="green add" *ngIf="('ConfigAddStationMonitoringProfiles'|hasPermission)" (click)="addCondition(parent, 'Filter')"><span class="mdi mdi-plus"></span> {{'Condition'|translate}}</button>
<button type="button" class="green add" *ngIf="('ConfigAddStationMonitoringProfiles'|hasPermission)" (click)="addCondition(parent, 'OrGroup')"><span class="mdi mdi-plus"></span> {{'Group'|translate}}</button>
