import { Component, Input } from '@angular/core';
import { Filter, Field } from "./Query.model";

@Component({
    selector: 'filter',
    templateUrl: 'Filter.component.html'
})
export class FilterComponent {
    datePickerOptions: JQueryUI.DatepickerOptions;
    @Input('filter') filter: Filter = null;
    @Input('field') field: Field = null;
    @Input('caption') caption = true;

    constructor() {
        var maxDateTime = new Date();
        maxDateTime.setDate(maxDateTime.getDate() + 1);
        this.datePickerOptions = {
            maxDate: maxDateTime,
        }
    }
}
