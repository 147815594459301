import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../shared/Api.service';
import { ChangesService } from 'swx.front-end-lib';
import { TicketTrackingSystemService } from '../shared/TicketTrackingSystem.service';
import { HasPermissionService } from '../shared/HasPermission.pipe';
import {ConfigGridComponent, IAgGridColGroupDef, FileDownloadService} from "swx.front-end-lib";

@Component({
    templateUrl: 'MetarMessageMappingProfileDetail.component.html'
})
export class MetarMessageMappingProfileDetailComponent {
    item: any;
    returnPath;
    @ViewChild('ngForm', {static: true}) ngForm;
    @ViewChild('mappingsGrid', { static: true }) mappingsGrid: ConfigGridComponent;
    tab;
    metarWeatherTypes = this.api.MetarWeatherType.query();
    metarWeatherMappingProfiles = this.api.MetarWeatherMappingProfile.query();
    fluidTypeFlags = this.api.FluidTypeFlags.query();
    equipmentTypes = this.api.EquipmentType.query();
    hotConditions = this.api.HotCondition.query();
    mappingsColumnDefs: IAgGridColGroupDef[] = [{
        children: [
            { colId: "Id", field: "Id", headerName: "#", width: 50, pinned: 'left', filterType: "integer" },
            { colId: "FluidType", field: "FluidType", headerName: "Fluid types", width: 230, cellClass: "select", cellTemplate: `
                <multiselect-enum [enum]="parent.fluidTypeFlags" width="220" required [(ngModel)]="params.data.FluidType" [name]="'params.data[' + params.node.id + '].FluidType'"></multiselect-enum>`, pinned: 'left' },
            { colId: "WeatherTypeNames", field: "WeatherTypeNames", headerName: "Weather types", width: 230, cellClass: "select", cellTemplate: `
                <multiselect-enum [enum]="parent.metarWeatherTypes" [format]="'array'" width="220" required [(ngModel)]="params.data.MetarWeatherTypes" [name]="'params.data[' + params.node.id + '].MetarWeatherTypes'"></multiselect-enum>`, pinned: 'left' },
            { colId: "EquipmentTypeNames", field: "EquipmentTypeNames", headerName: "Equipment types", width: 230, cellClass: "select", cellTemplate: `
                <multiselect width="220" [none]="'All'" [(ngModel)]="params.data.EquipmentTypeIds" [name]="'params.data[' + params.node.id + '].EquipmentTypeIds'">
                    <multiselect-option *ngFor="let equipmentType of parent.equipmentTypes|orderBy:['IATACode', 'ICAOCode']" [value]="equipmentType.Id" [name]="equipmentType.IATACode + '/' + (equipmentType.ICAOCode || ('N/A'|translate))"></multiselect-option>
                </multiselect>`, pinned: 'left' },
            { colId: "MaxTemp", field: "MaxTemp", headerName: "Temp <= (°C)", width: 85, cellTemplate: `<input required type="number" step="any" [(ngModel)]="params.data.MaxTemp" [name]="'params.data[' + params.node.id + '].MaxTemp'" style="width: 70px;" />`, pinned: 'left' },
            { colId: "OverrideLwe", field: "OverrideLwe", headerName: "Override LWE?", width: 100, cellClass: "select", cellTemplate: `<input type="checkbox" [(ngModel)]="params.data.OverrideLwe" [name]="'params.data[' + params.node.id + '].OverrideLwe'" />` },
            { colId: "Message", field: "Message", headerName: "Message for ACARS, web portal and API responses", width: 300, cellClass: "select", cellTemplate: `<input  type="text" pattern="^[0-9a-zA-Z•\\t\\- ':\.,\\\\/@\+]+$" [(ngModel)]="params.data.Message" [name]="'params.data[' + params.node.id + '].Message'" style="width: 99%;" />` },
            { colId: "ShortMobileMessage", field: "ShortMobileMessage", headerName: "Short message for mobile app only", width: 300, cellClass: "select", cellTemplate: `<input  type="text" pattern="^[0-9a-zA-Z•\\t\\- ':\.,\\\\/@\+]+$" [(ngModel)]="params.data.ShortMobileMessage" [name]="'params.data[' + params.node.id + '].ShortMobileMessage'" style="width: 99%;" />` },
            { colId: "LongMobileMessage", field: "LongMobileMessage", headerName: "Long message for mobile app only", width: 350, cellClass: "textarea", cellTemplate: `<textarea style="width: 99%; height: 52px;" [(ngModel)]="params.data.LongMobileMessage" [name]="'params.data[' + params.node.id + '].LongMobileMessage'"></textarea>` },
            { colId: "ShowHotAndMessage", field: "ShowHotAndMessage", headerName: "Show HOT and message?", width: 100, cellClass: "select", cellTemplate: `<input  type="checkbox" [(ngModel)]="params.data.ShowHotAndMessage" [name]="'params.data[' + params.node.id + '].ShowHotAndMessage'" />` },
            { colId: "IsRestrictiveCondition", field: "IsRestrictiveCondition", headerName: "Is restrictive condition?", width: 100, cellClass: "select", cellTemplate: `<input type="checkbox" [(ngModel)]="params.data.IsRestrictiveCondition" [name]="'params.data[' + params.node.id + '].IsRestrictiveCondition'" />` },
            { colId: "IsPTOCCNotAuthorized", field: "IsPTOCCNotAuthorized", headerName: "Is PTOCC not authorized?", width: 100, cellClass: "select", cellTemplate: `<input type="checkbox" [(ngModel)]="params.data.IsPTOCCNotAuthorized" [name]="'params.data[' + params.node.id + '].IsPTOCCNotAuthorized'" />` },
            { colId: "HotConditionName", field: "HotConditionName", headerName: "HOT condition for dispatch portal", width: 200, cellClass: "select", cellTemplate: `
                <select [(ngModel)]="params.data.HotConditionId" [name]="'params.data[' + params.node.id + '].HotConditionId'" style="width: 98%;">
                    <option [ngValue]="null">{{'None'|translate}}</option>
                    <option *ngFor="let option of parent.hotConditions" [ngValue]="option.Id">
                        {{option.Name}}
                    </option>
                </select>` },
            { colId: "Actions", field: "Id", headerName: "", width: 80, pinned: 'right', cellClass: "actions", cellTemplate: `<a *ngIf="parent.hasPermissionService.hasPermission('ConfigEditMETARMessageMappingProfiles')" (click)="parent.removeMetarMessageMapping(params.data)" class="mdi mdi-delete" title="{{'Remove'|translate}}"></a>` },
        ],
    }];

    constructor(
        private router: Router,
        private viewContainerRef: ViewContainerRef,
        private route: ActivatedRoute,
        private api: ApiService,
        private changes: ChangesService,
        private ticketTrackingSystem: TicketTrackingSystemService,
        public hasPermissionService: HasPermissionService,
        private fileDownload: FileDownloadService,
    ) {
        this.tab = location.hash ? location.hash.substring(1) : 'basicInfo';

        this.returnPath = this.route.snapshot.url[0].path.replace('/:id', '');
        const id = this.route.snapshot.params['id'];
        const copyId = this.route.snapshot.queryParams['copy'];
        const isNew = id === 'new';

        if (copyId) {
            this.item = this.api.MetarMessageMappingProfile.get({ id: copyId });
            this.item.$promise.then(() => {
                delete this.item.Id;

                if (this.item.MetarMessageMappings) {
                    this.item.MetarMessageMappings.forEach(related => {
                        delete related.MetarMessageMappingProfileId;
                    });
                }
            });
        } else if (isNew) {
            this.item = this.api.MetarMessageMappingProfile.create({
                Active: true,
            });
        } else {
            this.item = this.api.MetarMessageMappingProfile.get({ id: id });
        }

        Promise.all([this.item.$promise, this.metarWeatherTypes.$promise, this.equipmentTypes.$promise]).then(() => {
            var metarWeatherTypesKeys = Object.keys(this.metarWeatherTypes);
            this.hotConditions.$promise.then(() => {
                this.item.MetarMessageMappings = this.item.MetarMessageMappings || [];
                
                this.item.MetarMessageMappings.forEach(mm => {
                    mm.MetarWeatherTypes = (mm.MetarMessageMappingMetarWeatherTypes || [])
                        .map(mmmmwt => mmmmwt.MetarWeatherType);

                    mm.WeatherTypeNames = (mm.MetarMessageMappingMetarWeatherTypes || [])
                        .map(mmmmwt => metarWeatherTypesKeys.find(mwt => mwt === mmmmwt.MetarWeatherType))
                        .join(', ');

                    mm.EquipmentTypeIds = (mm.MetarMessageMappingEquipmentTypes || [])
                        .map(mmeq => mmeq.EquipmentTypeId);

                    mm.EquipmentTypeNames = (mm.MetarMessageMappingEquipmentTypes || [])
                        .map(mmeq => this.equipmentTypes.find(mwt => mwt === mmeq.EquipmentTypeId))
                        .join(', ');

                    if (mm.HotConditionId) {
                        mm.HotConditionName = this.hotConditions.find(hc => hc.Id === mm.HotConditionId).Name;
                    }
                });
            });
        });
    }

    save() {
        this.item.$promise.then(_ => {
            this.item.MetarMessageMappings.forEach(mm => {
                mm.MetarMessageMappingMetarWeatherTypes = (mm.MetarWeatherTypes || [])
                    .map(mwt => ({
                        MetarMessageMappingId: mm.Id,
                        MetarWeatherType: mwt
                    }));

                mm.MetarMessageMappingEquipmentTypes = (mm.EquipmentTypeIds || [])
                    .map(eq => ({
                        MetarMessageMappingId: mm.Id,
                        EquipmentTypeId: eq
                    }));
            });
            
            this.ticketTrackingSystem.trackAndSave(this.viewContainerRef, this.item, this.returnPath);
        });
    }

    cancel() {
        this.router.navigateByUrl(this.returnPath);
    }

    viewHistory() {
        this.changes.show(this.viewContainerRef, {
            SubjectType: ['MetarMessageMappingProfile'],
            SubjectId: this.item.Id
        });
    };

    switchTab(tab) {
        location.hash = tab;
        this.tab = tab;
    };

    addMetarMessageMapping() {
        this.item.MetarMessageMappings = this.item.MetarMessageMappings || [];
        this.item.MetarMessageMappings.unshift({});
        this.mappingsGrid.gridApi.applyTransaction({ addIndex: 0, add: this.mappingsGrid.rowData.slice(0, 1) })
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    };

    removeMetarMessageMapping(mapping) {
        this.item.MetarMessageMappings.splice(this.item.MetarMessageMappings.indexOf(mapping), 1);
        this.mappingsGrid.updateFilteredRows();
        this.ngForm.form.updateValueAndValidity();
        this.ngForm.form.markAsDirty();
    };

    exportProfile() {
        var query = {
            Id: this.item.Id,
            ColumnDefs: this.mappingsColumnDefs,
        };

        this.api.MetarMessageMappingProfileDetailExport
            .exportPost(query)
            .then(response => {
                this.fileDownload.download(response.body, (header) => response.headers.get(header));
            });
    };
}
