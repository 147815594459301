import {Component, OnInit, ViewChild} from "@angular/core";
import {IAgGridColumnDef, StorageService, ValueFormatters} from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";

@Component({
    templateUrl: './AidxEvent.component.html'
})
export class AidxEventComponent implements OnInit {
    @ViewChild('grid') grid;
    storeKey = 'aidxEventQuery';
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    query: any;

    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.AidxEvent;
    exportResourcePost = this.api.AidxEventExport;

    constructor(private storage: StorageService, 
                private api: ApiService,
                private formatters: ValueFormatters) {
    }
    
    ngOnInit() {
       const storedQuery = this.storage.load(this.storeKey);
       if (storedQuery){
           this.query = storedQuery;
       }
       else {
           this.resetQuery();
       }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    };
    
    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }

    columnDefs: IAgGridColumnDef[] = [
        { colId: "AidxEvent.Id", field: "AidxEvent.Id", headerName: "#", width: 80, filterType: "integer", pinned: 'left' },
        { colId: "AidxEvent.TimeStampDate", field: "AidxEvent.TimeStamp", headerName: "Date", width: 90, valueFormatter: this.formatters.utcDateFormatter(), pinned: 'left', filterType: "date" },
        { colId: "AidxEvent.TimeStampTime", field: "AidxEvent.TimeStamp", headerName: "Time", width: 60, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, pinned: 'left', searchable: false },
        { colId: "AidxEvent.TransactionIdentifier", field: "AidxEvent.TransactionIdentifier", headerName: "Transaction identifier", width: 150, pinned: 'left' },
        { colId: "AidxEvent.FlightKey", field: "AidxEvent.FlightKey", headerName: "Flight key", width: 250, pinned: 'left' },
        { colId: "AidxEvent.AidxEventCode", field: "AidxEvent.AidxEventCode", headerName: "AIDX event code", width: 80 },
        { colId: "AidxEvent.UflifoEventCode", field: "AidxEvent.UflifoEventCode", headerName: "UFLIFO event code", width: 90 },
        { colId: "AidxEvent.AirlineCode", field: "AidxEvent.AirlineCode", headerName: "Airline code", width: 60 },
        { colId: "AidxEvent.DepartureAirportCode", field: "AidxEvent.DepartureAirportCode", headerName: "Departure airport code", width: 80 },
        { colId: "AidxEvent.ArrivalAirportCode", field: "AidxEvent.ArrivalAirportCode", headerName: "Arrival airport code", width: 80 },
        { colId: "FlightIdentifier", field: "FlightIdentifier", headerName: "Flight #", width: 80 },
        { colId: "AidxEvent.RepeatNumber", field: "AidxEvent.RepeatNumber", headerName: "Repeat number", width: 80 },
        { colId: "AidxEvent.EquipmentTypeIdentifier", field: "AidxEvent.EquipmentTypeIdentifier", headerName: "Aircraft type", width: 80 },
        { colId: "AidxEvent.RegistrationNumber", field: "AidxEvent.RegistrationNumber", headerName: "Registration #", width: 100 },
        { colId: "AidxEvent.TailNumber", field: "AidxEvent.TailNumber", headerName: "Tail #", width: 80 },
        { colId: "AidxEvent.DepartureGate", field: "AidxEvent.DepartureGate", headerName: "Departure gate", width: 80 },
        { colId: "AidxEvent.ScheduledOffBlockDate", field: "AidxEvent.ScheduledOffBlockTime", headerName: "Scheduled off block date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.ScheduledOffBlockTime", field: "AidxEvent.ScheduledOffBlockTime", headerName: "Scheduled off block time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.EstimatedOffBlockDate", field: "AidxEvent.EstimatedOffBlockTime", headerName: "Estimated off block date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.EstimatedOffBlockTime", field: "AidxEvent.EstimatedOffBlockTime", headerName: "Estimated off block time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.ActualOffBlockDate", field: "AidxEvent.ActualOffBlockTime", headerName: "Actual off block date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.ActualOffBlockTime", field: "AidxEvent.ActualOffBlockTime", headerName: "Actual off block time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.ScheduledOnBlockDate", field: "AidxEvent.ScheduledOnBlockTime", headerName: "Scheduled on block date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.ScheduledOnBlockTime", field: "AidxEvent.ScheduledOnBlockTime", headerName: "Scheduled on block time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.EstimatedOnBlockDate", field: "AidxEvent.EstimatedOnBlockTime", headerName: "Estimated on block date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.EstimatedOnBlockTime", field: "AidxEvent.EstimatedOnBlockTime", headerName: "Estimated on block time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.ActualOnBlockDate", field: "AidxEvent.ActualOnBlockTime", headerName: "Actual on block date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.ActualOnBlockTime", field: "AidxEvent.ActualOnBlockTime", headerName: "Actual on block time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.ScheduledTouchdownDate", field: "AidxEvent.ScheduledTouchdownTime", headerName: "Scheduled touchdown date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.ScheduledTouchdownTime", field: "AidxEvent.ScheduledTouchdownTime", headerName: "Scheduled touchdown time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.EstimatedTouchdownDate", field: "AidxEvent.EstimatedTouchdownTime", headerName: "Estimated touchdown date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.EstimatedTouchdownTime", field: "AidxEvent.EstimatedTouchdownTime", headerName: "Estimated touchdown time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.ActualTouchdownDate", field: "AidxEvent.ActualTouchdownTime", headerName: "Actual touchdown date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.ActualTouchdownTime", field: "AidxEvent.ActualTouchdownTime", headerName: "Actual touchdown time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.ScheduledTakeOffDate", field: "AidxEvent.ScheduledTakeOffTime", headerName: "Scheduled take off date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.ScheduledTakeOffTime", field: "AidxEvent.ScheduledTakeOffTime", headerName: "Scheduled take off time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.EstimatedTakeOffDate", field: "AidxEvent.EstimatedTakeOffTime", headerName: "Estimated take off date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.EstimatedTakeOffTime", field: "AidxEvent.EstimatedTakeOffTime", headerName: "Estimated take off time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.ActualTakeOffDate", field: "AidxEvent.ActualTakeOffTime", headerName: "Actual take off date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.ActualTakeOffTime", field: "AidxEvent.ActualTakeOffTime", headerName: "Actual take off time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.AcarsInitDate", field: "AidxEvent.AcarsInitTime", headerName: "ACARS init date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.AcarsInitTime", field: "AidxEvent.AcarsInitTime", headerName: "ACARS init time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.BreakSetDate", field: "AidxEvent.BreakSetTime", headerName: "Break set date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.BreakSetTime", field: "AidxEvent.BreakSetTime", headerName: "Break set time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
        { colId: "AidxEvent.BreakReleaseDate", field: "AidxEvent.BreakReleaseTime", headerName: "Break release date", width: 100, valueFormatter: this.formatters.utcDateFormatter(), filterType: "date" },
        { colId: "AidxEvent.BreakReleaseTime", field: "AidxEvent.BreakReleaseTime", headerName: "Break release time", width: 100, valueFormatter: this.formatters.utcTimeFormatter(), excelIgnore: true, searchable: false },
    ];
} 
