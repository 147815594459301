import { Component, Input } from '@angular/core';
import { ControlContainer, NgModelGroup } from "@angular/forms";

@Component({
    selector: '[sensor-calibration-configuration]',
    templateUrl: 'SensorCalibrationConfiguration.component.html',
    viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }]
})
export class SensorCalibrationConfigurationComponent {
    @Input('sensor-calibration-configuration') configuration;
    @Input() name: string;
    @Input() type: string;
    @Input() property: string;
}
