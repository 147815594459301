import { Component, OnInit, OnDestroy } from "@angular/core";
import {IAgGridColGroupDef, IAgGridColumnDef, IBaseQuery, ColumnPreferenceGroup, ColumnPreference} from './Query.model';
import { ColumnPreferencesService } from './ColumnPreferences.service';
import { ExportApi, IResource } from "../ApiFactory.service";
import { QuerySerializerService } from "../QuerySerializer.service";

@Component({
    templateUrl: "./ExportDialog.component.html",
    styleUrls: ['ExportDialog.component.scss']
})
export class ExportDialogComponent implements OnInit, OnDestroy {
    container: JQuery;
    query: IBaseQuery = {};
    columnPreferences: ColumnPreferenceGroup[];
    close;
    selectedCount: number;
    totalCount: number;
    infoMessage: string;
    columnDefs: (IAgGridColumnDef | IAgGridColGroupDef)[];
    
    running = false;
    status = '';
    taskId = '';
    subscription: any;
    columnPreferencesStorageKey: string;
    exportResource: IResource<any>;
    exportGet = false;
    api: ExportApi;
    exportFormat = 'Excel';

    constructor(
        private columnPreferencesService: ColumnPreferencesService,
        private querySerializerService: QuerySerializerService,
    )
    { }
    
    ngOnInit(): void {
        this.columnPreferences = this.columnPreferencesService
            .loadColumnPreferences(this.columnPreferencesStorageKey + '_export', this.columnDefs)
            .map(g => ({
                ...g,
                children: g.children.filter(c => c.excelIgnore == null || !c.excelIgnore),
            }));
        this.updateTotals();

        window.onbeforeunload = () => {
	        if (this.running) {
		        this.cancel();
	        }
        };
    }

    ngOnDestroy(): void {
	    if (this.running) {
		    this.cancel();
	    }
    }

    // Close the dialog
    dismiss() {
        this.container.dialog('close');
    }

    export() {
	    // Export only preferred columns
	    let selectedColumns = this.columnPreferences
            .map(g => ({
                ...g,
                children: g.children.filter(col => col.selected)
            }))
            .filter(g => g.children.length > 0);

	    const query = Object.assign({
		    Output: this.exportFormat,
		    ColumnDefs: selectedColumns,
	    }, this.query);
        
	    this.running = true;
	    this.status = 'Preparing';

        this.columnPreferencesService.savePreferences(this.columnPreferencesStorageKey + '_export', this.columnPreferences);

        (this.exportGet
            ? this.exportResource.queryObject(this.querySerializerService.toHttpParams(query)).$promise
            : this.exportResource.post(query))
		    .then(response => {
			    this.taskId = response.TaskId;

			    this.subscription = this.api.ExcelExportTask.stream({ taskId: this.taskId }).subscribe(update => {
				    this.status = update;
				    if (update === "Ready") {
					    this.subscription.unsubscribe();
					    this.running = false;

					    this.api.ExcelExportResult.download(response.Filename, { taskId: this.taskId, format: this.exportFormat });

                        setTimeout(() => {
	                        this.dismiss();
                        });
				    }
                    
                    if (update.indexOf('Error:') !== -1) {
                        this.subscription.unsubscribe();
                        this.running = false;
                    }
			    });
		    })
		    .catch(err => {
			    this.running = false;
		    });
    }

    cancel() {
	    if (this.running) {
		    this.running = false;
		    this.subscription.unsubscribe();
		    this.api.ExcelExportTask.put({ id: this.taskId }, { "Status": "Cancel" });
	    }
	    this.container.dialog('close');
    }

    // Check/uncheck column
    toggleSelected(checked: boolean, column: ColumnPreference) {
        column.selected = checked;
        this.updateTotals();
    }

    // Check/uncheck everything
    toggleSelectedAll(checked: boolean) {
        this.columnPreferences.forEach(g => g.children.forEach(c => c.selected = checked));
        this.updateTotals();
    }

    // Check/uncheck everything in group
    toggleSelectedAllGroup(checked: boolean, group: ColumnPreferenceGroup) {
        group.children.forEach(c => c.selected = checked);
        this.updateTotals();
    }

    // Helper: Returns an appropriate text message depending on toggle selection
    updateTotals() {
        this.selectedCount = this.columnPreferences.reduce((sum, group) => sum + group.children.filter(c => c.selected).length, 0);
        this.totalCount = this.columnPreferences.reduce((sum, group) => sum + group.children.length, 0);
        this.columnPreferences.forEach(g => g.allSelected = g.children.every(c => c.selected));
        
        if (this.selectedCount === 0) {
            this.infoMessage = 'None selected';
        } else if (this.selectedCount === this.totalCount) {
            this.infoMessage = 'All selected';
        } else {
            this.infoMessage = 'Custom selection';
        }
    }
}
