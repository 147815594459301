import { Injectable } from '@angular/core';
import { StorageService } from '../Storage.service';
import { IAgGridColGroupDef, IAgGridColumnDef, ColumnPreferenceGroup } from './Query.model';

@Injectable({ providedIn: 'root' })
export class ColumnPreferencesService {
    constructor(
        private storageService: StorageService,
    ) {}

    loadColumnPreferences(storageKey: string, columnDefs: IAgGridColumnDef[] | IAgGridColGroupDef[]): ColumnPreferenceGroup[] {
        // load column preferences, or select them all by default
        let selectedColumnIds = this.storageService.load(storageKey) as string[];
        
        return ColumnPreferencesService.normalizeColumnDefs(columnDefs)
            .map((g: IAgGridColGroupDef) => ({
                headerName: g.headerName,
                children: g.children.map(c => {
                    const selected = selectedColumnIds == null ? true : selectedColumnIds?.includes(c.colId);

                    return {
                        ...c,
                        selected: selected,
                    };
                }),
            }));
    }

    savePreferences(storageKey: string, groups: ColumnPreferenceGroup[]): void {
        this.storageService.save(storageKey, groups.reduce((a, b) => a.concat(b.children
            .filter(c => c.selected)
            .map(c => c.colId)), []) || []);
    }
    
    public static normalizeColumnDefs(columnDefs: IAgGridColumnDef[] | IAgGridColGroupDef[]): IAgGridColGroupDef[] {
        if (columnDefs == null) return [];
        if ("children" in columnDefs[0]) {
            return columnDefs.map((g: IAgGridColGroupDef) => ({
                headerName: g.headerName,
                children: g.children,
            }));
        } else {
            return [{
                headerName: '',
                children: columnDefs,
            }];
        }
    }
}
