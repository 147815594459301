<div id="sqsQueueConfigurationList" class="noMargins">
    <page-title>{{('SQS Queues'|translate)}}</page-title>
    <form #form="ngForm" (submit)="refresh()" class="toolbar gridFilters">
        <label class="inline">
            <span class="caption">{{'Search'|translate}}</span>
            <input [(ngModel)]="grid.searchQuery" name="searchQuery" />
        </label>
        <label class="inline">
            <input type="checkbox" [(ngModel)]="grid.includeInactive" name="includeInactive" />
            <span class="caption">{{'Include inactive'|translate}}</span>
        </label>
        <button *ngIf="'ConfigAddSQSQueueConfigurations'|hasPermission" routerLink="/sqsQueueConfigurations/new" type="button" class="green">{{'Add configuration'|translate}}</button>
    </form>

    <config-grid #grid
                 gridId="preferences.sqsQueueConfiguration.ColumnExport"
                 [query]="query"
                 [resource]="api.SQSQueueConfiguration"
                 [columnDefs]="columnDefs">
    </config-grid>
</div>
