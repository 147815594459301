import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

@Injectable({ providedIn: 'root'})
export class QuerySerializerService {
    private flattenValue(target, value, property) {
        if (Array.isArray(value)) {
            for (var i = 0; i < value.length; i++) {
                this.flattenValue(target, value[i], property + '[' + i + ']');
            }
        } else if (typeof (value) === 'object') {
            for (var nestedProperty in value) {
                this.flattenValue(target, value[nestedProperty], property + '.' + nestedProperty);
            }
        } else {
            target[property] = value;
        }
    }

    private flatten(object) {
        var flattened = {};
        for (var property in object) {
            this.flattenValue(flattened, object[property], property);
        }
        return flattened;
    };

    serialize(query: any): string {
        var flattened = this.flatten(query);
        return Object.keys(flattened)
            .filter(property => flattened[property] != null)
            .map(property => encodeURIComponent(property) + '=' + encodeURIComponent(flattened[property]))
            .join('&');
    }

    toHttpParams(query: any): HttpParams {
        var params = new HttpParams();
        params.toString = () => this.serialize(query);
        return params;
    }
}
