<div class="legend">
    <button class="legend-toggle" (click)="toggle()">Legend <span [ngClass]="getToggleIcon()"></span></button>
    <ul class="legend-content" [ngStyle]="{ display: this.open ? 'block' : 'none' }">
        <li><img src="/images/marker_00FF00.png" /> {{'Connected & receiving data'}}</li>
        <li><img src="/images/marker_FFFF00.png" /> {{'Alarm, generating LWEs'}}</li>
        <li><img src="/images/marker_FF8000.png" /> {{'Alarm'}}</li>
        <li><img src="/images/marker_FF0000.png" /> {{'Connection problem'}}</li>
        <li><img src="/images/marker_33FFFF.png" /> {{'Under maintenance'}}</li>
    </ul>
</div>

