<page-title>{{('Barrel icing profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('airports')" [class.active]="tab == 'airports'" [class.invalid]="!ngForm.form.controls.airportsForm?.valid">{{'Airports'|translate}}</a>
        <a class="tab" (click)="switchTab('equipmentTypes')" [class.active]="tab == 'equipmentTypes'" [class.invalid]="!ngForm.form.controls.equipmentTypesForm?.valid">{{'Equipment types'|translate}}</a>
        <a class="tab" (click)="switchTab('emails')" [class.active]="tab == 'emails'" [class.invalid]="!ngForm.form.controls.emailsForm?.valid">{{'Emails & ACARS'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditBarrelIcingProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100" />
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
        </label>
        <label>
            <span class="caption">{{'Client'|translate}}</span>
            <select [(ngModel)]="item.ClientId" name="item.ClientId" (ngModelChange)="updateAvailableAirports()">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let client of clients|orderBy:'Name'" [ngValue]="client.Id">
                    {{client.Name}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Email sender'|translate}}</span>
            <select required [(ngModel)]="item.EmailFromId" name="item.EmailFromId">
                <option *ngFor="let emailFrom of emailFroms|orderBy:'Id'" [ngValue]="emailFrom.Id">
                    {{emailFrom.EmailAddress}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'Use only Gen 3 sensor select matrix A'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseOnlyGen3SSMA" name="item.UseOnlyGen3SSMA" />
        </label>
        <br />

        <table class="table">
            <tr>
                <th style="text-align: center" colspan="2">{{'Internal Alert Criteria for System Problem'|translate}}</th>
            </tr>
            <tr>
                <td>{{'Do not send alert above temperature (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.OfflineTemperatureThreshold" name="item.OfflineTemperatureThreshold" type="number" step="any" style="width: 50px;" /></td>
            </tr>
            <tr>
                <td>{{'Email interval between alerts (minutes)'|translate}}</td>
                <td><input required [(ngModel)]="item.OfflineEmailInterval" name="item.OfflineEmailInterval" type="number" min="1" step="any" style="width: 50px;" /></td>
            </tr>
            <tr>
                <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
                <th style="text-align: center" colspan="2">{{'Entry Criteria'|translate}}</th>
            </tr>
            <tr>
                <td>{{'Temp &gt; (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.EnteringMinTemperature" name="item.EnteringMinTemperature" type="number" step="any" style="width: 50px;" /></td>
            </tr>
            <tr>
                <td>{{'AND'|translate}}<br />{{'Temp &lt; (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.EnteringMaxTemperature" name="item.EnteringMaxTemperature" type="number" step="any" style="width: 50px;" /></td>
            </tr>
            <tr>
                <td>{{'AND'|translate}}<br />{{'(Temperature - Dew point) &lt; (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.EnteringMinTemperatureDewPointDiff" name="item.EnteringMinTemperatureDewPointDiff" type="number" min="0" step="1" style="width: 50px;" /></td>
            </tr>
            <tr>
                <td>{{'AND'|translate}}<br />{{'Precipitation LWE &gt; (g/dm²/h)'|translate}}</td>
                <td><input required [(ngModel)]="item.EnteringMinPrecipitation" name="item.EnteringMinPrecipitation" type="number" min="0" step="1" style="width: 50px;" /></td>
            </tr>
            <tr>
                <td>{{'AND'|translate}}<br />{{'During this entire period (in minutes)'|translate}}</td>
                <td><input required [(ngModel)]="item.EnteringPersistenceDuration" name="item.EnteringPersistenceDuration" type="number" min="1" step="1" style="width: 50px;" /></td>
            </tr>
            <tr>
                <th colspan="2">{{'Notifications'|translate}}</th>
            </tr>
            <tr>
                <td>
                    <div style="color: gray;">
                        {{'Emails are sent as soon as entry criteria are met'|translate}}
                        <br />{{'to recipients on the barrel icing warning list.'|translate}}
                    </div>
                    <div>
                        {{'ACARS to all aircraft that requested a HOT'|translate}}
                        <br />{{'within the last minutes.'|translate}}
                    </div>
                </td>
                <td><br /><br /><input required [(ngModel)]="item.EnteringTime" name="item.EnteringTime" type="number" min="0" step="1" style="width: 50px;" /></td>
            </tr>
            <tr>
                <td colspan="2">&nbsp;</td>
            </tr>
            <tr>
                <th style="text-align: center" colspan="2">{{'Exit Criteria'|translate}}</th>
            </tr>
            <tr>
                <td>{{'Temp &gt; (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.ExitingMinTemperature" name="item.ExitingMinTemperature" type="number" step="any" style="width: 50px;" /></td>
            </tr>
            <tr>
                <td>{{'OR'|translate}}<br />{{'Temp &lt; (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.ExitingMaxTemperature" name="item.ExitingMaxTemperature" type="number" step="any" style="width: 50px;" /></td>
            </tr>
            <tr>
                <td>{{'OR'|translate}}<br />{{'(Temperature - Dew point) &gt; (°C)'|translate}}</td>
                <td><input required [(ngModel)]="item.ExitingMaxTemperatureDewPointDiff" name="item.ExitingMaxTemperatureDewPointDiff" type="number" min="0" step="1" style="width: 50px;" /></td>
            </tr>
            <tr>
                <td>{{'OR'|translate}}<br />{{'Precipitation LWE &lt; (g/dm²/h)'|translate}}</td>
                <td><input required [(ngModel)]="item.ExitingMaxPrecipitation" name="item.ExitingMaxPrecipitation" type="number" min="0" step="1" style="width: 50px;" /></td>
            </tr>
            <tr>
                <th colspan="2">{{'Notifications'|translate}}</th>
            </tr>
            <tr>
                <td>
                    <div>{{'Duration for which any of the exit criteria must'|translate}}</div>
                    <div>{{'persist before declaring exit (minutes)'|translate}}</div>
                </td>
                <td><input required [(ngModel)]="item.ExitingTime" name="item.ExitingTime" type="number" min="0" step="1" style="width: 50px;" /></td>
            </tr>
        </table>
    </fieldset>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditBarrelIcingProfiles'|hasPermission)" [hidden]="tab != 'airports'" ngModelGroup="airportsForm">
        <select [(ngModel)]="airport" name="airport">
            <option *ngFor="let airportOption of availableAirports" [ngValue]="airportOption">
                {{airportOption.ICAOCode + '/' + airportOption.IATACode + ' - ' + airportOption.Name}}
            </option>
        </select>
        <input type="button" *ngIf="('ConfigEditBarrelIcingProfiles'|hasPermission)" (click)="addBarrelIcingProfileAirport(airport); airport = null;" value="{{'Add airport'|translate}}" [disabled]="airport == null" />
        <br />
        <br />
        <table class="table">
            <tr>
                <th>{{'Airport'|translate}}</th>
                <th style="width: 250px;">{{'Contact info'|translate}}</th>
                <th></th>
            </tr>
            <tr *ngFor="let barrelIcingProfileAirport of item.BarrelIcingProfileAirports; let i = index">
                <td>{{(airports|findById:barrelIcingProfileAirport.AirportId)?.ICAOCode + ' / ' + (airports|findById:barrelIcingProfileAirport.AirportId)?.ICAOCode + ' - ' + (airports|findById:barrelIcingProfileAirport.AirportId)?.Name}}</td>
                <td>
                    <input type="text" style="width: 100%;" [(ngModel)]="barrelIcingProfileAirport.ContactInfo" [name]="'barrelIcingProfileAirport[' + i + '].ContactInfo'" />
                </td>
                <td>
                    <a *ngIf="('ConfigEditBarrelIcingProfiles'|hasPermission)" (click)="removeBarrelIcingProfileAirport(barrelIcingProfileAirport)" class="mdi mdi-delete"></a>
                </td>
            </tr>
        </table>
    </fieldset>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditBarrelIcingProfiles'|hasPermission)" [hidden]="tab != 'equipmentTypes'" ngModelGroup="equipmentTypesForm">
        <select [(ngModel)]="equipmentType" name="equipmentType">
            <option *ngFor="let equipmentType of equipmentTypesNotEnabled()|orderBy:['IATACode', 'ICAOCode']" [ngValue]="equipmentType">
                {{equipmentType.IATACode + '/' + (equipmentType.ICAOCode || ('N/A'|translate)) + ' ' + equipmentType.ManufacturerAndModelDescription}}
            </option>
        </select>
        <input type="button" *ngIf="('ConfigEditBarrelIcingProfiles'|hasPermission)" (click)="addBarrelIcingEquipmentType();" value="{{'Add equipment type'|translate}}" [disabled]="equipmentType == null" />
        <br />
        <br />
        <table class="table">
            <tr>
                <th>{{'Equipment type'|translate}}</th>
                <th *ngFor="let barrelIcingProfileAirport of item.BarrelIcingProfileAirports; let i = index">{{(airports|findById:barrelIcingProfileAirport.AirportId)?.ICAOCode + ' / ' + (airports|findById:barrelIcingProfileAirport.AirportId)?.ICAOCode + ' - ' + (airports|findById:barrelIcingProfileAirport.AirportId)?.Name}}</th>
                <th></th>
            </tr>
            <tr *ngFor="let barrelIcingEquipmentType of item.BarrelIcingEquipmentTypes; let i = index">
                <td>{{(equipmentTypes|findById:barrelIcingEquipmentType.EquipmentTypeId).IATACode + ' / ' + ((equipmentTypes|findById:barrelIcingEquipmentType.EquipmentTypeId).ICAOCode || ('N/A'|translate))}}</td>
                <td *ngFor="let barrelIcingProfileAirport of item.BarrelIcingProfileAirports; let i = index">
                    <input type="checkbox" [checked]="isBarrelIcingEquipmentTypeAirportEnabled(barrelIcingEquipmentType, barrelIcingProfileAirport.AirportId)" *ngIf="('ConfigEditBarrelIcingProfiles'|hasPermission)" (click)="toggleBarrelIcingEquipmentTypeAirport(barrelIcingEquipmentType, barrelIcingProfileAirport.AirportId)" />
                </td>
                <td>
                    <a *ngIf="('ConfigEditBarrelIcingProfiles'|hasPermission)" (click)="removeBarrelIcingEquipmentType(barrelIcingEquipmentType)" class="mdi mdi-delete"></a>
                </td>
            </tr>
        </table>
    </fieldset>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditBarrelIcingProfiles'|hasPermission)" [hidden]="tab != 'emails'" ngModelGroup="emailsForm">
        <table class="table" style="width: 100%;">
            <tr>
                <td colspan="4">
                    <label>
                        <span class="caption">{{'Available tokens'|translate}}</span>
                        <span class="value" style="width: auto;">{{'{AirportCode} {ContactInfo} {OperationManagerName} {OperationManagerPhone} {OperationManagerEmailAddress} {AlertId}'}}</span>
                    </label>
                </td>
            </tr>
            <tr>
                <th colspan="4">{{'ACARS'|translate}}</th>
            </tr>
            <tr>
                <td colspan="4">
                    <textarea style="width: 100%; height: 150px; font-family: monospace; white-space: pre;" [(ngModel)]="item.AcarsMessage" [name]="'item.AcarsMessage'"></textarea>
                </td>
            </tr>
            <tr>
                <th colspan="4">{{'Entering email'|translate}}</th>
            </tr>
            <tr>
                <td colspan="4">
                    <input type="text" style="width: 100%;" [(ngModel)]="item.EnteringSubject" [name]="'item.EnteringSubject'" placeholder="Subject" />
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <ckeditor [editor]="Editor" [config]="ckConfig" style="width: 100%; min-height: 150px;" [(ngModel)]="item.EnteringBody" [name]="'item.EnteringBody'"></ckeditor>
                </td>
            </tr>
            <tr>
                <th colspan="4">{{'Exiting email'|translate}}</th>
            </tr>
            <tr>
                <td colspan="4">
                    <input type="text" style="width: 100%;" [(ngModel)]="item.ExitingSubject" [name]="'item.ExitingSubject'" placeholder="Subject" />
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <ckeditor [editor]="Editor" [config]="ckConfig" style="width: 100%; min-height: 150px;" [(ngModel)]="item.ExitingBody" [name]="'item.ExitingBody'"></ckeditor>
                </td>
            </tr>
            <tr>
                <th colspan="4">{{'Out of service'|translate}}</th>
            </tr>
            <tr>
                <td colspan="4">
                    <input type="text" style="width: 100%;" [(ngModel)]="item.OutOfServiceSubject" [name]="'item.OutOfServiceSubject'" placeholder="Subject" />
                </td>
            </tr>
            <tr>
                <td colspan="4">
                    <ckeditor [editor]="Editor" [config]="ckConfig" style="width: 100%; min-height: 150px;" [(ngModel)]="item.OutOfServiceBody" [name]="'item.OutOfServiceBody'"></ckeditor>
                </td>
            </tr>
        </table>
    </fieldset>
</form>
