export function Base64UploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new Base64UploadAdapter(loader);
    };
}

class Base64UploadAdapter {
    loader;

    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                const reader = new window.FileReader();
                reader.addEventListener('load', () => {
                    resolve({ default: reader.result });
                });
                reader.addEventListener('error', err => {
                    reject(err);
                });
                reader.addEventListener('abort', () => {
                    reject();
                });
                this.loader.file.then(file => {
                    reader.readAsDataURL(file);
                });
            }));
    }

    abort() {
    }
}
