import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root'})
export class FileDownloadService {
    download(blob: Blob, getHeader: any): void {
        // check for a filename
        let filename = "";
        if (typeof getHeader === 'function') {
            const disposition = getHeader('Content-Disposition');
            if (disposition && disposition.indexOf('attachment') !== -1) {
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
            }
        } else {
            filename = getHeader;
        }

        var URL = window.URL;
        if (typeof URL === 'undefined' || URL === null) URL = window['webkitURL'];
        var downloadUrl = URL.createObjectURL(blob);

        if (filename) {
            this.downloadUrl(downloadUrl, filename);
        } else {
            window.location.href = downloadUrl;
        }

        setTimeout(() => { URL.revokeObjectURL(downloadUrl); }, 5000); // cleanup
    }

    downloadUrl(downloadUrl: string, filename: string): void {
        // use HTML5 a[download] attribute to specify filename
        const a = document.createElement("a");
        a.setAttribute('href', downloadUrl);
        a.setAttribute('download', filename);
        a.setAttribute('target', '_blank');
        document.body.appendChild(a);
        setTimeout(() => { a.click(); }, 1);
    }
}
