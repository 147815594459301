import { OnInit, ElementRef, Directive, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

declare var jscolor: any;

@Directive({
    selector: '[color-picker]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColorPickerDirective),
            multi: true
        }
    ]
})
export class ColorPickerDirective implements ControlValueAccessor, OnInit {
    onchange: (_: any) => void = () => { };
    ontouched: (_: any) => void = () => { };
    input: HTMLInputElement;
    jsc;

    constructor(
        private elementRef: ElementRef
    ) {
    }

    ngOnInit() {
        this.input = this.elementRef.nativeElement;
        
        jscolor.dir = "/scripts/jscolor/dummy/";
        this.jsc = new jscolor.color(this.input, { required: false, pickerPosition: 'right' });

        this.input.addEventListener('change', () => {
            var value = this.jsc.toString();
            this.onchange(value);
            this.ontouched(value);
        });
    }

    writeValue(value): void {
        this.input.value = value;
        this.jsc = new jscolor.color(this.input, { required: false, pickerPosition: 'right' });
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void {
        this.ontouched = fn;
    }
}