import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CellRendererFactories {
    input(type: string, properties: Partial<HTMLInputElement>): HTMLInputElement {
        var checkbox = document.createElement('input');
        checkbox.type = type;
        Object.keys(properties).forEach(property => {
            checkbox[property] = properties[property];
        });
        return checkbox;
    }
}
