import {AfterViewInit, Component, ElementRef, forwardRef, Input, ViewEncapsulation} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/autocomplete.js';

@Component({
    selector: 'autocomplete',
    template: `<input class="display" type="text" [value]="value"/><div class="button mdi mdi-chevron-down"></div>`,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['Autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteComponent),
            multi: true
        }
    ]
})
export class AutocompleteComponent implements ControlValueAccessor, AfterViewInit {
    @Input('options') options: Array<string>;
    @Input() autocompleteOptions: JQueryUI.AutocompleteOptions;
    @Input('placeholder') placeholder?: string;
    value: string;
    onchange: (_: any) => void = () => { };
    ontouched: (_: any) => void = () => { };
    input: JQuery;

    constructor(
        private elementRef: ElementRef
    ) {
    }

    ngAfterViewInit(): void {
        this.autocompleteOptions = this.autocompleteOptions || {};
        let element = jQuery(this.elementRef.nativeElement);
        this.input = element.find('input');
        this.input.attr("placeholder", this.placeholder);
        var wasOpen = false;

        const defaultAutocompleteOptions = {
            delay: 0,
            minLength: 0,
            source: (request, responseCallback) => responseCallback(this.options == null
                ? null
                : this.options.filter(o => typeof (o) === 'string' &&
                    (!request.term || o.toLowerCase().indexOf(request.term.toLowerCase()) === 0))),
            classes: {
                'ui-autocomplete': 'autocomplete-component'
            },
            change: (e) => {
                this.value = (e.target as HTMLInputElement).value;
                this.onchange(this.value);
                this.ontouched(this.value);
            }
        };

        this.autocompleteOptions = this.autocompleteOptions || {};
        this.input.autocomplete({ ...defaultAutocompleteOptions, ...this.autocompleteOptions });

        element
            .on('mousedown', () => wasOpen = this.input.autocomplete('widget').is(':visible'))
            .on('click',
                () => {
                    this.input.trigger('focus');
                    if (wasOpen) return;
                    this.input.autocomplete('search', '');
                });
    }

    writeValue(value): void {
        this.value = value;
        this.input.val(value);
    }

    registerOnChange(fn): void {
        this.onchange = fn;
    }

    registerOnTouched(fn): void {
        this.ontouched = fn;
    }
}
