<ag-grid-angular class="ag-theme-swx"
         [gridOptions]="gridOptions"
         [columnDefs]="_columnDefs"
         [rowModelType]="'clientSide'"
         [headerHeight]="headerHeight"
         [rowSelection]="rowSelection"
         [rowHeight]="rowHeight"
         [groupHeaderHeight]="20"
         (gridReady)="onGridReady($event)"
         [components]="components"
         [suppressColumnVirtualisation]="true"
         [debounceVerticalScrollbar]="true"
         [suppressCellFocus]="true"
         [suppressRowTransform]="true"
         [rowData]="rowData"
         [alwaysShowHorizontalScroll]="true"
         [alwaysShowVerticalScroll]="true">
</ag-grid-angular>
