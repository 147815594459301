import { Component, OnInit } from "@angular/core";
import { ApiService } from "../shared/Api.service";
import {FileDownloadService} from "swx.front-end-lib";
import { UntypedFormGroup } from "@angular/forms";

@Component({
    templateUrl: 'Gen2Validation.component.html'
})
export class Gen2ValidationComponent implements OnInit {
    vm: any;

    constructor(
        private api: ApiService,
        private fileDownload: FileDownloadService) {
    }

    ngOnInit() {
        Promise.all([
            this.api.SensorSelectMatrix.query({StationType: 'Gen2'}).$promise,
            this.api.Station.query().$promise,
            this.api.Fluid.query().$promise,
            this.api.LWEMessageMappingProfile.query().$promise
        ]).then(([sensorSelectMatrices, stations, fluids, lweMessageMappingProfiles]) => {
            this.vm = {
                sensorSelectMatrices: sensorSelectMatrices,
                stations: stations,
                fluids: fluids,
                lweMessageMappingProfiles: lweMessageMappingProfiles
            }
        });
    }

    validate(form: UntypedFormGroup) {
        const f = form.value;

        this.api.Gen2Validation.exportPost({
            StationId: f.stationId,
            SensorSelectMatrixId: f.sensorSelectMatrixId,
            FluidId: f.fluidId,
            LWEMessageMappingProfileId: f.lweMessageMappingProfileId,
            Data: f.data,
        }).then(response => {
            this.fileDownload.download(response.body, (header) => response.headers.get(header));
        });
    }
}

