<div class="noMargins">
    <page-title>{{'Human observations with weather profiles'}}</page-title>
    <form class="toolbar gridFilters" #form="ngForm">
        <label class="inline">
            <span class="caption">{{'Station'|translate}}</span>
            <select [(ngModel)]="query.StationId" required name="query.StationId">
                <option *ngFor="let station of stations|orderBy:'Name'" [ngValue]="station.Id">
                    {{station.Name}}
                </option>
            </select>
        </label>
        <label class="inline">
            <span class="caption">{{'Sensor select matrix'|translate}}</span>
            <select [(ngModel)]="query.SensorSelectMatrixId" required name="query.SensorSelectMatrixId">
                <ng-container *ngIf="stations.length > 0">
                    <option *ngFor="let ssm of (sensorSelectMatrices|filter:{StationType:(stations | findById:query.StationId)[0].StationType})|orderBy:'Name'" [ngValue]="ssm.Id">
                        {{ssm.Name}}
                    </option>
                </ng-container>
            </select>
        </label>
        <ng-container *ngIf="stations.length">
            <label class="inline" *ngIf="((stations|filter:{Id: query.StationId})[0].StationType === 'Gen3') || ((stations|filter:{Id: query.StationId})[0].StationType === 'GenV')">
                <span class="caption">{{'METAR client weather configuration'|translate}}</span>
                <select [(ngModel)]="query.MetarWeatherMappingProfileId" [required]="((stations|filter:{Id: query.StationId})[0].StationType == 'Gen3') || ((stations|filter:{Id: query.StationId})[0].StationType == 'GenV')" name="query.MetarWeatherMappingProfileId">
                    <option *ngFor="let metarWeatherMappingProfile of metarWeatherMappingProfiles|orderBy:'Name'" [ngValue]="metarWeatherMappingProfile.Id">
                        {{metarWeatherMappingProfile.Name}}
                    </option>
                </select>
            </label>
        </ng-container>
        <label class="inline">
            <span class="caption">{{'From'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" name="query.FromDate">
        </label>
        <label class="inline">
            <span class="caption">{{'To'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
        </label>
        <input type="button" (click)="grid.refresh()" ng-disabled="!form.valid" value="{{'Apply'|translate}}" />
        <button type="button" [disabled]="!form.valid || !query.FromDate" (click)="grid.export()"><span class="mdi mdi-download"></span> {{'Export'|translate}}</button>
    </form>
    <grid #grid
          gridId="preferences.DeicingRecord.ColumnExport_v13"
          [query]="query"
          [resource]="resource"
          [exportResource]="exportResourcePost"
          [columnDefs]="columnDefs">
    </grid>
</div>

