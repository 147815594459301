import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {environment} from "../environments/environment";
import {AppDetail} from "./model/AppDetail";
import {BusyIndicatorService} from "swx.front-end-lib";


@Injectable({providedIn: 'root'})
export class AppstoreconnectService {
    baseUrl = `${environment.apiUrl}/appstore`
    
    constructor(private http: HttpClient) {
    }
    
    getAppDetails(appId: string) {
        return this.http.get<AppDetail>(`${this.baseUrl}/apps/${appId}`);
    }
}
