import { Directive, forwardRef, Input } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
    selector: '[requireItems]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => RequireItemsDirective),
            multi: true
        }
    ]
})
export class RequireItemsDirective implements Validator {
    @Input('requireItems') items;

    validate(control: AbstractControl): { [index: string]: any; } {
        return this.items !== null && typeof this.items !== 'undefined' && this.items.length > 0
            ? null
            : {
                'requireItems': false
            };
    }
}