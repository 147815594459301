import {Component, Input} from "@angular/core";
import {TestFlightGroup} from "../../model/TestFlightGroup";

@Component({
    selector: 'swx-test-flight-group-details',
    templateUrl: './testFlightGroupDetails.component.html'
})
export class TestFlightGroupDetailsComponent {
    @Input() group: TestFlightGroup;
}
