import {Component, ViewContainerRef} from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";
import {ICellRendererParams} from "ag-grid-community";
import {DialogService} from "swx.front-end-lib";
import {WeatherOverrideDeicingReportsDialogComponent} from "./WeatherOverrideDeicingReportsDialog.component";
import {ApiService} from "../shared/Api.service";

@Component({
    template: `<a  *ngIf='data?.HasWeatherOverrideDeicingReports' (click)='showWeatherOverrideDeicingReports()' class='mdi mdi-eye'></a>`
})
export class WeatherOverrideDeicingReportCellRenderer implements ICellRendererAngularComp {
    data: any;
    
    constructor(private dialog: DialogService,
                private viewContainerRef: ViewContainerRef,
                private api: ApiService) {
    }

    agInit(params: ICellRendererParams): void {
        this.data = params.data;
    }

    refresh(params: ICellRendererParams): boolean {
        return false;
    }
    
    showWeatherOverrideDeicingReports() {
        this.dialog.show(this.viewContainerRef, WeatherOverrideDeicingReportsDialogComponent, {
            deicingReports: this.api.DeicingReport.query({
                WeatherOverride: true,
                HotResponseId: this.data.HotResponse.Id,
            })
        },
        {
            title: "Weather Override Deicing Reports",
            width: 700,
            height: 'auto',
            modal: true,
        })
    }
}
