<div class="noMargins">
    <page-title>{{'HOT request'|translate}}</page-title>
    <form class="contentMargins form" #form="ngForm" (ngSubmit)="send()" >
        <label>
            <span class="caption">{{'Client/airline code'|translate}}</span>
            <input type="text" required [(ngModel)]="request.clientCode" name="clientCode"/>
        </label>
        <label>
            <span class="caption">{{'Airport ICAO code'|translate}}</span>
            <input type="text" required [(ngModel)]="request.airportCode" maxlength="4" name="airportCode"/>
        </label>
        <label>
            <span class="caption">{{'Aircraft tail number'|translate}}</span>
            <input type="text" [(ngModel)]="request.tailNumber" name="tailNumber"/>
        </label>
        <label>
            <span class="caption">{{'ICAO Equipment type'|translate}}</span>
            <input type="text" [(ngModel)]="request.equipmentTypeCode" name="equipmentTypeCode"/>
        </label>
        <label>
            <span class="caption">{{'Date'|translate}}</span>
            <input date-time [options]="datePickerOptions" [(ngModel)]="request.dateTime" name="dateTime" format="YYYY-MM-DDTHH:mm:ss[Z]" displayFormat="YYYY-MM-DD HH:mm:ss"> Z
        </label>
        <label>
            <span class="caption">{{'HOT start time'|translate}}</span>
            <input date-time [options]="datePickerOptions" [(ngModel)]="request.hotStartDateTime" name="hotStartDateTime" format="YYYY-MM-DDTHH:mm:ss[Z]" displayFormat="YYYY-MM-DD HH:mm:ss"> Z
        </label>
        <label>
            <span class="caption">{{'Override temperature (C)'|translate}}</span>
            <input type="number" [(ngModel)]="request.overrideTemperature" min="-50" max="50" step="1" name="overrideTemperature"/>
        </label>
        <label>
            <span class="caption">{{'Count'|translate}}</span>
            <input type="number" [(ngModel)]="request.count" min="1" step="1" name="count"/>
        </label>
        <label>
            <span class="caption">{{'Interval (minutes)'|translate}}</span>
            <input type="number" [(ngModel)]="request.interval" min="1" step="1" name="interval"/>
        </label>
        <br />
        <input type="submit" [disabled]="!form.valid" [value]="'Send'|translate" />
    </form>
    <div class="contentMargins result" *ngIf="!!result">
        <h2>{{'Result'|translate}}</h2>
        <pre>{{result|json}}</pre>
    </div>
</div>
