import {Injectable, Component, Input, ViewContainerRef} from '@angular/core'
import { IResourceItem, DialogService, AuthenticationService, TranslateService, NotificationService } from 'swx.front-end-lib';
import { Router } from '@angular/router';

@Injectable()
export class TicketTrackingSystemService {
    constructor(
        private dialogService: DialogService,
        private authentication: AuthenticationService,
        private router: Router,
        private translateService: TranslateService,
        private notification: NotificationService,
    ) {
    }

    getComment(viewContainerRef: ViewContainerRef) {
        return new Promise<TicketTrackingCommentInfo>((resolve, _) => {
            this.dialogService.show(viewContainerRef, TicketTrackingCommentDialogComponent,
                {
                    close: resolve
                },
                {
                    title: 'Comments',
                    width: 435,
                    modal: true
                });
        });
    }

    trackAndSave(viewContainerRef: ViewContainerRef, item: IResourceItem, returnPath: string) {
        return this.authentication.getCurrentUser().then(currentUser => {
            if (currentUser.TicketTrackingRequired) {
                return this.getComment(viewContainerRef)
                    .then(result => {
                        return item.$save({}, {
                            comment: result.Comment,
                            ticketNumber: result.TicketNumber,
                        }).then(saveResult => {
                            this.notification.show(this.translateService.translate('The changes have been saved and the ticket comment has been added.'));
                            this.router.navigateByUrl(returnPath);
                            return saveResult;
                        });
                    });
            } else {
                return item.$save().then(saveResult => {
                    this.notification.show(this.translateService.translate('The changes have been saved.'));
                    this.router.navigateByUrl(returnPath);
                    return saveResult;
                });
            }
        });
        
    }

    trackAndDelete(viewContainerRef: ViewContainerRef, item: IResourceItem, callback: () => any) {
        return this.authentication.getCurrentUser().then(currentUser => {
            if (currentUser.TicketTrackingRequired) {
                return this.getComment(viewContainerRef)
                    .then(result => {
                        return item.$delete({}, {
                            comment: result.Comment,
                            ticketNumber: result.TicketNumber,
                        }).then(deleteResult => {
                            this.notification.show(this.translateService.translate('The item has been deleted and the ticket comment has been added.'));
                            callback();
                            return(deleteResult);
                        });
                    });
            } else {
                return item.$delete().then(deleteResult => {
                    this.notification.show(this.translateService.translate('The item has been deleted.'));
                    callback();
                    return(deleteResult);
                });
            }
        });
    }
}

@Component({
    template: `<form class="form" #form #ngForm="ngForm" (submit)="save()">
    <fieldset class="contentMargins">
        <label>
            <span class="caption">{{'Ticket Number'|translate}}</span>
            <input type="number" min="0" required [(ngModel)]="ticketNumber" name="ticketNumber" style="width: 100px;" />
        </label>
        <label>
            <span class="caption">{{'Comments'|translate}}</span>
            <textarea required [(ngModel)]="comments" name="comments" style="width: 400px; height: 75px;"></textarea>
        </label>
        <label>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        </label>
    </fieldset>
</form>`
})
export class TicketTrackingCommentDialogComponent {
    @Input('container') container: JQuery;
    @Input('close') close: (TicketTrackingCommentInfo) => any;
    ticketNumber;
    comments;

    save() {
        this.close({
            TicketNumber: this.ticketNumber,
            Comment: this.comments
        });
        this.container.dialog('close');
    }

    cancel() {
        this.container.dialog('close');
    }
}

class TicketTrackingCommentInfo {
    TicketNumber;
    Comment;
}
