<page-title>{{('METAR RegEx remapping profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('mappings')" [class.active]="tab == 'mappings'" [class.invalid]="!ngForm.form.controls.mappingsForm?.valid || !areWeatherCodeRemappingsValid">{{'Mappings'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine || !areWeatherCodeRemappingsValid" class="green" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        <button *ngIf="('ConfigEditMETARWeatherCodeRemappingProfiles'|hasPermission) && tab == 'mappings'" type="button" (click)="addCodeRemapping()" class="green">{{'Add code remapping'|translate}}</button>
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditMETARWeatherCodeRemappingProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100"/>
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active"/>
        </label>
    </fieldset>

    <config-grid #mappingsGrid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'mappings'"
                 ngModelGroup="mappingsForm"
                 #mappingsForm="ngModelGroup"
                 [rowData]="item.MetarWeatherCodeRemappings"
                 [columnDefs]="columnDefs"
                 [headerHeight]="56">
    </config-grid>
</form>
