import { Injectable } from '@angular/core'

@Injectable()
export class XmlToJsonService {
    srcDom(strXml: string) {
        const parser = new DOMParser();
        const srcDom = parser.parseFromString(strXml, "application/xml");

        return srcDom;
    }

    convert(srcDom: any) {
        const children = [...srcDom.children];

        if (!children.length) {
            return srcDom.innerHTML;
        }

        // initializing object to be returned.
        const jsonResult: any = {};
        for (let child of children) {

            // checking is child has siblings of same name.
            const childIsArray = children.filter(eachChild => eachChild.nodeName === child.nodeName).length > 1;

            // if child is array, save the values as array, else as strings.
            if (childIsArray) {
                if (jsonResult[child.nodeName] === undefined) {
                    jsonResult[child.nodeName] = [this.convert(child)];
                } else {
                    jsonResult[child.nodeName].push(this.convert(child));
                }
            } else {
                jsonResult[child.nodeName] = this.convert(child);
            }
        }

        return jsonResult;
    }

    isJson(str: any) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    isXml(str: any) {
        try {
            const parser = new DOMParser();
            parser.parseFromString(str, "application/xml");
        } catch (e) {
            return false;
        }
        return true;
    }

    getKeysFromJson(jsonObject: any, prepend : string | null = null, output = new Array<any>()) {
        for (let propertyKey in jsonObject) {
            if (jsonObject.hasOwnProperty(propertyKey)) {
                const property = jsonObject[propertyKey];
                const name = prepend ? prepend + '.' + propertyKey : propertyKey;
                const value = property;
                if (typeof property === 'object') {
	                this.getKeysFromJson(property, name, output);
                } else {
                    output.push({
                        FieldPath: name,
                        FieldValue: value,
                        FieldName: propertyKey
                    });
                }
            }
        }

        return output;
    }

    getInfo(strInput: string) {
        var input = "";
        if (this.isJson(strInput)) {
            input = JSON.parse(strInput);
        } else if (this.isXml(strInput)) {
            input = this.convert(this.srcDom(strInput));
        }

        if (!input) {
            return null;
        }

        return this.getKeysFromJson(input);
    }

    getResponse(input: any, clientSetting: any) {
        const isJsonObject = input !== null && typeof input === 'object';
        const isString = typeof input === 'string';

        if (!clientSetting) { // Keeping first client(Finnair) behaviour
            try {
                return isString ? JSON.parse(input) : input;
            } catch(e) {
                return null;
            }
        }

        const data = isJsonObject
            ? this.getKeysFromJson(input)
            : (isString ? this.getInfo(input) : null);

        if (!data) return null;

        const response = {
            OnlineRequestTimeoutMilliseconds: "",
            Aircraft: {
                CallSign: "",
                TailNumber: "",
                EquipmentType: "",
                WingMaterialType: ""
            },
            Airport: {
                ICAOCode: ""
            }
        };

        const matches = data.filter(a => clientSetting.some((b: any) => a.FieldPath === b.FieldPath));
        matches.forEach(item => {
            var mappingInfo = clientSetting.find((x: any) => x.FieldPath === item.FieldPath);
            var match = this.getKeysFromJson(response).find(item => item.FieldName === mappingInfo.FieldName);
            var keys = match.FieldPath.split('.');
            keys.reduce(function (prev: any, curr: string, index: number) {
                if (keys.length - 1 === index) {
                    prev[curr] = item.FieldValue;
                }
                return prev ? prev[curr] : null;
            },
            response || {});
        });

        return response;
    }

    getJsonFromXml(strInput: string) {
        return this.convert(this.srcDom(strInput));
    }
}
