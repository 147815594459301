import {Component, Input} from "@angular/core";

@Component({
    templateUrl: './TemplatePreviewDialog.component.html'
})
export class TemplatePreviewDialogComponent {
    @Input() cols: number;
    @Input() rows: number;
    @Input() output: string;
}
