<page-title>{{('LWE equation profile'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins">
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <a class="tab" (click)="switchTab('equations')" [class.active]="tab == 'equations'" [class.invalid]="!ngForm.form.controls.equationsForm?.valid">{{'Equations'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" class="green" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        <button *ngIf="('ConfigEditLWEEquations'|hasPermission) && tab == 'equations'" type="button" (click)="addEquation()" class="green">{{'Add equation'|translate}}</button>
        <input type="button" (click)="exportProfile()" [disabled]="!ngForm.form.pristine || !item.Id" value="{{'Export to Excel'|translate}}" />
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditLWEEquations'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100"/>
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active"/>
        </label>
        <label>
            <span class="caption">{{'Apply regressions before looking up operational tolerances'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.ApplyRegressionsFirst" name="item.ApplyRegressionsFirst" />
        </label>
    </fieldset>

    <config-grid #grid
                 class="form"
                 [parent]="this"
                 [hidden]="tab != 'equations'"
                 ngModelGroup="equationsForm"
                 #equationsForm="ngModelGroup"
                 [rowData]="item.LWEEquations"
                 [headerHeight]="75"
                 [columnDefs]="columnDefs">
    </config-grid>
</form>
