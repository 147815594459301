import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewContainerRef} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {
    DialogService,
    GoogleMapsLoaderService,
    IAgGridColumnDef,
    QuerySerializerService,
    StorageService,
    ValueFormatters,
} from "swx.front-end-lib";
import {MetarReadingDialogComponent} from "./MetarReadingDialog.component";

@Component({
    templateUrl: './MetarReading.component.html',
    styleUrls: ['MetarReading.component.scss'],
})
export class MetarReadingComponent implements OnInit {
    @ViewChild('grid') grid;
    @ViewChild('metarMap', { static: false }) metarMap: ElementRef<HTMLElement>;
    storeKey = 'metarReadingQuery';
    tab = '';
    query: any;
    airports = this.api.Airport.query();
    resource = this.api.MetarReading;
    exportResourcePost = this.api.MetarReadingExport;
    clients = this.api.Client.query();
    fluids = this.api.Fluid.query();
    metarWeatherTypes = this.api.MetarWeatherType.query();
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };
    mapLoaded: Promise<any> = null;
    markers = [];

    constructor(private api: ApiService,
                private viewContainerRef: ViewContainerRef,
                private storage: StorageService,
                private formatters: ValueFormatters,
                private googleMapsLoaderService: GoogleMapsLoaderService,
                private dialog: DialogService,
                private querySerializerService: QuerySerializerService,
    ) {
    }
    
    ngOnInit() {
        this.tab = 'grid';
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery) {
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }
    
    switchTab(tab: string) {
        this.tab = tab;
        
        if (tab === 'map') {
            if (this.mapLoaded !== null) return;

            this.mapLoaded = new Promise((resolve, reject) => {
                this.googleMapsLoaderService.load().then(maps => {
                    
                    var mapElement = this.metarMap.nativeElement;

                    resolve(new maps.Map(mapElement,
                        {
                            zoom: 3,
                            center: new maps.LatLng(45.5017, -73.5673),
                            mapTypeId: maps.MapTypeId.TERRAIN
                        }));
                });
            });

            this.mapLoaded.then(() => {
                this.refreshMap();
            });
        }
    }
    
    resetQuery() {
        this.query = {
            Source: 0,
            Filters: []
        }    
    }
    
    refreshMap() {
        this.googleMapsLoaderService.load().then(maps => {
            this.mapLoaded.then(map => {
                let airports = this.api.Airport.query();
                let latestMetarReadings = this.api.MetarSnapshot.query();
                airports.$promise.then(() => {
                    latestMetarReadings.$promise.then(() => {
                        this.markers.forEach(marker => marker.setMap(null));
                        this.markers.length = 0;

                        airports.forEach(airport => {
                            var metarReading = null;
                            latestMetarReadings.forEach((candidate:any) => {
                                if (candidate.AirportId === airport.Id) {
                                    metarReading = candidate;
                                    //return false
                                }
                            });

                            var loc = new maps.LatLng(airport.Latitude, airport.Longitude);

                            var icon;
                            if (metarReading === null) {
                                icon = new maps.MarkerImage("//storage.googleapis.com/support-kms-prod/SNP_2752125_en_v0", new maps.Size(9, 9), new maps.Point(0, 0), new maps.Point(0, 0));
                            } else if (metarReading.TempC === null) {
                                icon = new maps.MarkerImage("//storage.googleapis.com/support-kms-prod/SNP_2752063_en_v0", new maps.Size(9, 9), new maps.Point(0, 0), new maps.Point(0, 0));
                            } else if (metarReading.WxString !== null && metarReading.WxString !== '' && metarReading.TempC <= 1) {
                                icon = new maps.MarkerImage("//storage.googleapis.com/support-kms-prod/SNP_2752068_en_v0", new maps.Size(9, 9), new maps.Point(0, 0), new maps.Point(0, 0));
                            } else {
                                icon = new maps.MarkerImage("//storage.googleapis.com/support-kms-prod/SNP_2752129_en_v0", new maps.Size(9, 9), new maps.Point(0, 0), new maps.Point(0, 0));
                            }

                            var marker = new maps.Marker({
                                map: map,
                                position: loc,
                                icon: icon,
                                title: airport.ICAOCode + (metarReading === null || metarReading.TempC === null ? "" : (": " + metarReading.WxString + ' ' + metarReading.TempC + 'C'))
                            });

                            this.markers.push(marker);

                            maps.event.addListener(marker, 'click', () => this.dialog.show(this.viewContainerRef, MetarReadingDialogComponent, {
                                airport: airport,
                                metarReading: metarReading,
                            },{
                                title: airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name, 
                                width: 'auto',
                                modal: true,
                            }))
                        });
                    });
                });
            }) 
        })
        
    }
    
    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }

    columnDefs: IAgGridColumnDef[] = [
        { colId: "MetarReading.TimestampDate", field: "MetarReading.Timestamp", headerName: "Date", width: 90, pinned: 'left', filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
        { colId: "MetarReading.TimestampTime", field: "MetarReading.Timestamp", headerName: "Time (UTC)", width: 60, excelIgnore: true, pinned: 'left', filterType: "date", valueFormatter: this.formatters.utcTimeFormatter() },
        { colId: "MetarReading.Id", field: "MetarReading.Id", headerName: "#", width: 80, filterType: "integer", pinned: 'left' },
        { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 70, pinned: 'left' },
        { colId: "AirportName", field: "AirportName", headerName: 'Name', width: 160, pinned: 'left' },
        { colId: "MetarReading.WxString", field: "MetarReading.WxString", headerName: "Weather string", width: 120, pinned: 'left' },
        { colId: "MetarReading.CreatedDate", field: "MetarReading.CreatedDate", headerName: "Received date", width: 90, filterType: "date", valueFormatter: this.formatters.utcDateFormatter() },
        { colId: "MetarReading.CreatedTime", field: "MetarReading.CreatedDate", headerName: "Received time (UTC)", width: 80, excelIgnore: true, filterType: "date", valueFormatter: this.formatters.utcTimeFormatter() },
        { colId: "MetarReading.Remarks", field: "MetarReading.Remarks", headerName: "Remarks", width: 120 },
        { colId: "MetarReading.RemappedWeatherCodes", field: "MetarReading.RemappedWeatherCodes", headerName: "Remapped weather code", width: 120 },
        { colId: "FinalWeatherType", field: "FinalWeatherType", headerName: "Final weather Type", width: 90, valueFormatter: c => c.value == null ? "" : this.metarWeatherTypes[c.value], filterType: "enum", source: "MetarWeatherType" },
        { colId: "MetarReading.MetarType", field: "MetarReading.MetarType", headerName: "METAR type", width: 90 },
        { colId: "MetarReading.TempC", field: "MetarReading.TempC", headerName: "Temperature (°C)", width: 60, filterType: "float" },
        { colId: "MetarReading.DewPointC", field: "MetarReading.DewPointC", headerName: "Dew point (°C)", width: 60, filterType: "float" },
        { colId: "MetarReading.FrostPointC", field: "MetarReading.FrostPointC", headerName: "Frost point (°C)", width: 60, filterType: "float" },
        { colId: "MetarReading.RelativeHumidity", field: "MetarReading.RelativeHumidity", headerName: "Relative humidity (RH%)", width: 80, filterType: "float" },
        { colId: "MetarReading.IsClearSky", field: "MetarReading.IsClearSky", headerName: "Clear sky?", width: 70, filterType: "integer" },
        { colId: "MetarReading.IsActiveFrost", field: "MetarReading.IsActiveFrost", headerName: "Active frost?", width: 80, filterType: "integer" },
        { colId: "MetarReading.VisibilityStatuteMi", field: "MetarReading.VisibilityStatuteMi", headerName: "Visibility (SM)", width: 80, filterType: "float" },
        { colId: "MetarReading.SurfaceVisibilityStatuteMi", field: "MetarReading.SurfaceVisibilityStatuteMi", headerName: "Surface visibility (SM)", width: 80, filterType: "float" },
        { colId: "MetarReading.WindDirDegrees", field: "MetarReading.WindDirDegrees", headerName: "Wind dir degrees (degrees)", width: 90, filterType: "float" },
        { colId: "MetarReadingWindSpeedKt", field: "MetarReadingWindSpeedKt", headerName: "Wind speed (KT)", width: 60, filterType: "float" },
        { colId: "MetarReading.WindGustKt", field: "MetarReading.WindGustKt", headerName: "Wind gust (KT)", width: 60, filterType: "float" },
        { colId: "MetarReading.SeaLevelPressureMb", field: "MetarReading.SeaLevelPressureMb", headerName: "Sea Level Pressure (mbar)", width: 80, filterType: "float" },
        { colId: "MetarReading.AltimInHg", field: "MetarReading.AltimInHg", headerName: "Altimeter (Hg)", width: 80, filterType: "float" },
        { colId: "MetarReading.SunRise", field: "MetarReading.SunRise", headerName: "Sunrise (UTC)", width: 60, filterType: "date", valueFormatter: this.formatters.utcTimeFormatter() },
        { colId: "MetarReading.SunSet", field: "MetarReading.SunSet", headerName: "Sunset (UTC)", width: 60, filterType: "date", valueFormatter: this.formatters.utcTimeFormatter() },
        { colId: "MetarReading.Errors", field: "MetarReading.Errors", headerName: "Errors", width: 200, filterType: "enum", source: "MetarReadingError" },
        { colId: "MinHot", field: "MinHot", headerName: "Min. HOT (minutes)", width: 70, filterType: "integer" },
        { colId: "MaxHot", field: "MaxHot", headerName: "Max. HOT (minutes)", width: 70, filterType: "integer" },
        { colId: "HotMessage", field: "HotMessage", headerName: "HOT message", width: 200 },
        { colId: "MetarReading.MetarSource", field: "MetarReading.MetarSource", headerName: "Source", width: 110, filterType: "enum", source: "MetarSource" },
        { colId: "MetarReading.RawData", field: "MetarReading.RawData", headerName: "Raw", width: 1500 }
    ];

}
