<div class="noMargins">
    <page-title>{{'Human observations'|translate}}</page-title>
    <form #form="ngForm">
        <div class="toolbar">
            <label class="inline">
                <span class="caption">{{'Airport'|translate}}</span>
                <multiselect [(ngModel)]="query.AirportId" name="query.AirportId" >
                    <multiselect-option *ngFor="let airport of airports|orderBy:['-HasStation','ICAOCode']" [value]="airport.Id" [name]="airport.ICAOCode + '/' + airport.IATACode + ' - ' + airport.Name"></multiselect-option>
                </multiselect>
            </label>
            <label class="inline">
                <span class="caption">{{'From'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.FromDate" required placeholder="Start Date" name="query.FromDate">
            </label>
            <label class="inline">
                <span class="caption">{{'To'|translate}}</span>
                <input date [options]="datePickerOptions" [(ngModel)]="query.ToDate" name="query.ToDate">
            </label>
            <button type="button" (click)="grid.refresh()" [disabled]="!form.valid"><span class="mdi mdi-refresh"></span> {{'Apply'|translate}}</button>
            <button type="button" (click)="grid.export()" [disabled]="!form.valid" ><span class="mdi mdi-download"></span>{{'Export'|translate}}</button>
        </div>
    </form>
    <grid #grid [resource]="resource" [exportResource]="exportResourcePost" [columnDefs]="columnDefs" gridId="humanObservations" [query]="query" [headerHeight]="75"></grid>
</div>
