import { Pipe } from '@angular/core';

@Pipe({
    name: 'ellipsis',
    pure: false
})
export class EllipsisPipe {
    public static readonly instance = new EllipsisPipe();

    transform(value: string, max: any = 30): string {
        if (!value) return '';

        max = parseInt(max, 10);
        if (value.length <= max) return value;

        value = value.substr(0, max);
        const lastspace = value.lastIndexOf(' ');
        if (lastspace !== -1) {
            value = value.substr(0, lastspace);
        }

        return value + ' …';
    }
}
