import { Injectable } from '@angular/core'
import { DialogService } from './Dialog.service';
import jQuery from 'jquery';

@Injectable({ providedIn: 'root'})
export class ConfirmService {
    constructor(
        private dialogService: DialogService
    ) {
    }

    confirm(message: string): Promise<any> {
        return new Promise((resolve, reject) => {
            let dialog = this.dialogService.showForHtml(message,
                {
                    title: 'Confirm',
                    width: 300,
                    height: 150,
                    modal: true,
                    buttons: [
                        {
                            text: 'Cancel',
                            'class': 'button',
                            click: () => {
                                reject();
                                jQuery(dialog).dialog('close');
                            }
                        },
                        {
                            text: 'OK',
                            'class': 'button button-positive',
                            click: () => {
                                resolve(true);
                                jQuery(dialog).dialog('close');
                            }
                        },
                    ]
                });
        });
    }
}
