import {Component, ViewChild, ViewContainerRef} from "@angular/core";
import {DialogService, IAgGridColumnDef, StorageService, ValueFormatters} from "swx.front-end-lib";
import {ApiService} from "../shared/Api.service";
import {RawAcarsDialogComponent} from "../acarsOooiReports/AcarsOooiReports.component";

@Component({
    templateUrl: './AcarsOutgoingMessage.component.html'
})
export class AcarsOutgoingMessageComponent {
    storeKey = 'acarsOutgoingMessageQuery'
    @ViewChild('grid') grid;
    query: any;
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };

    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.AcarsOutgoingMessage;
    exportResourcePost = this.api.AcarsOutgoingMessageExport;

    constructor(private storage: StorageService,
                private viewContainerRef: ViewContainerRef,
                private api: ApiService,
                private dialog: DialogService,
                private formatters: ValueFormatters) {
    }

    ngOnInit() {
        const storedQuery = this.storage.load(this.storeKey);
        if (storedQuery){
            this.query = storedQuery;
        }
        else {
            this.resetQuery();
        }
    }

    resetQuery() {
        this.query = {
            Filters: []
        };
    };

    apply() {
        this.storage.save(this.storeKey, this.query);
        this.grid.refresh();
    }


    columnDefs: IAgGridColumnDef[] = [
        { colId: "AcarsOutgoingMessage.Date", field: "AcarsOutgoingMessage.CreatedDate", headerName: "Date", width: 90, pinned: 'left', valueFormatter: this.formatters.utcDateFormatter() },
        { colId: "AcarsOutgoingMessage.Time", field: "AcarsOutgoingMessage.CreatedDate", headerName: "Time", width: 75, excelIgnore: true, pinned: 'left', valueFormatter: this.formatters.utcTimeFormatter() },
        { colId: "ClientName", field: "ClientName", headerName: "Client", width: 120 },
        { colId: "AirportCode", field: "AirportCode", headerName: "Airport", width: 80 },
        { colId: "AcarsOutgoingMessage.Type", field: "AcarsOutgoingMessage.Type", headerName: "Type", width: 100, filterType: "enum", source: "AcarsResponseType" },
        { colId: "AcarsOutgoingMessage.Target", field: "AcarsOutgoingMessage.Target", headerName: "Target", width: 200, filterType: "enum", source: "AcarsTo" },
        { colId: "AcarsOutgoingMessage.RawData", field: "AcarsOutgoingMessage.RawData", headerName: "Raw data", width: 1000, cellTemplate: "<a (click)='parent.showRawData(params.data)'>{{params.data?.AcarsOutgoingMessage.RawData}}</a>" },
    ];

    showRawData(data) {
        this.dialog.show(this.viewContainerRef, RawAcarsDialogComponent,{
                data: data?.AcarsOutgoingMessage.RawData
            },
            {
                title: 'ACARS',
                width: 360,
                height: 540,
                modal: true,
            });
    }
} 
