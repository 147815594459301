import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dump',
    pure: false
})
export class DumpPipe implements PipeTransform {
    transform(value): string {
        var seen = [];
        return JSON.stringify(value, (key, val) => {
            if (val != null && typeof val == "object") {
                if (seen.indexOf(val) >= 0) {
                    return;
                }
                seen.push(val);
            }
            return val;
        }, 4);
    }
}
