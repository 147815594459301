import {Injectable} from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {environment} from "../environments/environment";

@Injectable({ providedIn: 'root' })
export class AirportService {
    constructor(private http: HttpClient) {
    }
    
    airportCountries$ = this.http.get<string[]>(`${environment.apiUrl}/airportCountry`);
    airportRegions$ = this.http.get<string[]>(`${environment.apiUrl}/airportRegion`);
}
