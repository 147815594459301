import {Component, Input} from "@angular/core";
import {FileDownloadService} from "swx.front-end-lib";

@Component({
    templateUrl: './RawDataDialog.component.html'
})
export class RawDataDialogComponent {
    @Input() hot;
    
    constructor(private download: FileDownloadService) {
    }
    
    onDownload(data: string, fileName: string) {
        this.download.download(new Blob([data]), fileName)
    }
}
