<div class="noMargins">
    <page-title>{{'METAR Final Types'|translate}}</page-title>
    <form class="contentMargins form" #form="ngForm" (ngSubmit)="getFinalTypes()">
        <label>
            <span class="caption">{{'METAR reference date'|translate}}</span>
            <input date [options]="datePickerOptions" [(ngModel)]="query.ReferenceDate" name="ReferenceDate">
        </label>
        <label>
            <span class="caption">{{'Raw METAR or XML'|translate}}</span>
            <textarea required [(ngModel)]="query.MetarData" style="width: 900px; height: 300px;" name="MetarData"></textarea>
        </label>
        <br/>
        <input type="submit" [disabled]="!form.valid" [value]="'View final types'|translate"/>
    </form>
    <div class="contentMargins form">
        <div *ngFor="let item of items">
            <label>
                <span class="caption">{{'Airport name (if known)'|translate}}</span>
                <span>{{item.AirportName}}</span>
            </label>
            <label>
                <span class="caption">{{'METAR string'|translate}}</span>
                <span>{{item.MetarData.RawData}}</span>
            </label>
            <label>
                <span class="caption">{{'Observation time'|translate}}</span>
                <span>{{item.MetarData.Timestamp|utcDateTimeFormat:dateTimeFormat}}</span>
            </label>
            <label>
                <span class="caption">{{'Sunrise'|translate}}</span>
                <span>{{item.MetarData.SunRise|utcDateTimeFormat:dateTimeFormat}}</span>
            </label>
            <label>
                <span class="caption">{{'Day'|translate}}</span>
                <span>{{item.DayTime|utcDateTimeFormat:dateTimeFormat}}</span>
            </label>
            <label>
                <span class="caption">{{'Sunset'|translate}}</span>
                <span>{{item.MetarData.SunSet|utcDateTimeFormat:dateTimeFormat}}</span>
            </label>
            <label>
                <span class="caption">{{'Night'|translate}}</span>
                <span>{{item.NightTime|utcDateTimeFormat:dateTimeFormat}}</span>
            </label>
            <label>
                <span class="caption">{{'Weather codes'|translate}}</span>
                <span>{{item.MetarData.WxString}}</span>
            </label>

            <table class="table">
                <tr>
                    <th>{{'#'|translate}}</th>
                    <th>{{'Profile'|translate}}</th>
                    <th>{{'Weather Type Result'|translate}}</th>
                    <th>{{'Mixed precipitation profile #'|translate}}</th>
                </tr>
                <tr *ngFor="let type of getTypeDetails(item.MetarData.Id)">
                    <td>{{type.WeatherType.MetarWeatherMappingProfileId}}</td>
                    <td>{{type.ProfileName}}</td>
                    <td>{{type.WeatherType.MetarWeatherTypeResult}}</td>
                    <td>{{type.WeatherType.MetarMixedPrecipitationProfileId}}</td>
                </tr>
            </table>
            <br/><br/><hr/>
        </div>
    </div>
</div>




