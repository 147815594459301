import { AfterViewInit, Component } from "@angular/core";
import { ApiService } from "../shared/Api.service";

@Component({
    templateUrl: './ManualStationEntry.component.html'
})
export class ManualStationEntryCompoment implements AfterViewInit {
    stations: any;
    stationId: any;
    rawData: any;
    result: any;

    constructor(private api: ApiService) {
    }

    ngAfterViewInit() {
        this.api.Station.query().$promise.then(result => {
            this.stations = result;
        })
    }

    decode() {
        this.api.StationDecode.queryObject({
            stationId: this.stationId,
            rawData: this.rawData,
        }).$promise.then(result => {
            this.result = result;
        });
    }
}
