<form #form #ngForm="ngForm" (submit)="save()">
    <page-title>{{('MQTT broker'|translate) + ': ' + (item.Name || 'new')}}</page-title>
    <div id="userEditPage" class="noMargins">
        <div class="toolbar">
            <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
            <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" value="{{'Save'|translate}}" />
            <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
            <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        </div>

        <fieldset class="contentMargins form" [disabled]="!('ConfigEditMQTTBrokers'|hasPermission)" [style.display]="tab == 'basicInfo' ? '' :'none'" ngModelGroup="basicInfoForm">
            <label>
                <span class="caption">{{'Name'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Name" name="item.Name" />
            </label>
            <label>
                <span class="caption">{{'Active'|translate}}</span>
                <input type="checkbox" [(ngModel)]="item.Active" name="item.Active" />
            </label>
            <label>
                <span class="caption">{{'Authentication type'|translate}}</span>
                <select [(ngModel)]="item.MQTTAuthenticationType" name="item.MQTTAuthenticationType" [selectedIndex]="-1">
                    <option [ngValue]="null">{{'None'|translate}}</option>
                    <option *ngFor="let item of authenticationTypes|keyvalue" [value]="item.key">{{item.value}}</option>
                </select>
            </label>
            <label>
                <span class="caption">{{'Hostname'|translate}}</span>
                <input type="text" required [(ngModel)]="item.Hostname" name="item.Hostname" style="width: 400px;" />
            </label>
            <label>
                <span class="caption">{{'Port'|translate}}</span>
                <input type="number" required [(ngModel)]="item.Port" name="item.Port" min="1" max="65535" />
            </label>
            <ng-container *ngIf="item.MQTTAuthenticationType == 'ClientCertificate'">
                <label>
                    <span class="caption">{{'Override SNI hostname'|translate}}</span>
                    <input type="text" [(ngModel)]="item.SNIHostname" name="item.SNIHostname" style="width: 400px;" />
                </label>
                <label>
                    <span class="caption">{{'Client certificate (PEM)'|translate}}</span>
                    <textarea rows="30" cols="75" required [(ngModel)]="item.ClientCertificatePEM" name="item.ClientCertificatePEM"></textarea>
                </label>
                <label>
                    <span class="caption">{{'Private key (PEM)'|translate}}</span>
                    <textarea rows="30" cols="75" required [(ngModel)]="item.PrivateKeyPEM" name="item.PrivateKeyPEM"></textarea>
                </label>
                <label>
                    <span class="caption">{{'Private key password'|translate}}</span>
                    <input type="text" [(ngModel)]="item.PrivateKeyPassword" name="item.PrivateKeyPassword" />
                </label>
                <label>
                    <span class="caption">{{'CA certificate (PEM)'|translate}}</span>
                    <textarea rows="30" cols="75" [(ngModel)]="item.CACertificatePEM" name="item.CACertificatePEM"></textarea>
                </label>
            </ng-container>
            <ng-container *ngIf="item.MQTTAuthenticationType == 'UsernamePassword'">
                <label>
                    <span class="caption">{{'Username'|translate}}</span>
                    <input required type="text" [(ngModel)]="item.Username" name="item.Username" />
                </label>
                <label>
                    <span class="caption">{{'Password'|translate}}</span>
                    <input required type="text" [(ngModel)]="item.Password" name="item.Password" />
                </label>
            </ng-container>
        </fieldset>
    </div>
</form>
