import {Component, EventEmitter, Output, ViewChild} from "@angular/core";
import {ApiService} from "../shared/Api.service";
import {IAgGridColumnDef, ValueFormatters} from "swx.front-end-lib";

@Component({
    selector: 'swx-noaa-wpc-alert-list',
    templateUrl: 'NoaaWpcAlertList.component.html'
})
export class NoaaWpcAlertListComponent {

    @ViewChild('grid') grid;
    @Output() queryChange = new EventEmitter();
    clients = this.api.Client.query();
    airports = this.api.Airport.query();
    resource = this.api.NoaaWpcAlert;
    query: any = {};
    datePickerOptions = {
        maxDate: new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), new Date().getUTCHours(), new Date().getUTCMinutes(), new Date().getUTCSeconds()),
        dateFormat: 'yy-mm-dd',
        useUtc: true
    };

    constructor(private api: ApiService,
                private formatters: ValueFormatters,
    ) {
    }

    columnDefs: IAgGridColumnDef[] = [
        { colId: "CreatedDate", field: "CreatedDate", headerName: "Created date", width: 140, pinned: 'left', valueFormatter: this.formatters.utcDateTimeFormatter() },  
        { colId: "Type", field: "Type", headerName: "Type", width: 90, pinned: 'left' },
        { colId: "Id", field: "Id", headerName: "#", width: 90 },
        { colId: "ClientName", field: "ClientName", headerName: "Client", width: 140 },
        { colId: "AirportICAOCode", field: "AirportICAOCode", headerName: "Airport", width: 80 },
        { colId: "NoaaWpcForecast.ProbabilitySnowAccumulationGE2", field: "NoaaWpcForecast.ProbabilitySnowAccumulationGE2", headerName: "12h probability snow accumulation (%) >= 2\"" ,
            cellStyle: (params) => {
                if (params.data?.Type != "Exit" && params.data?.NoaaWpcForecast.ProbabilitySnowAccumulationGE2 >= params.data?.RequiredProbabilitySnowAccumulationGE2) { 
                    return {
                        backgroundColor: params.data?.Type.toLowerCase(),
                        color: 'white'
                    }
                }
                return null;
            },
        width: 100, filterType: 'float'},
        { colId: "NoaaWpcForecast.ProbabilitySnowAccumulationGE4", field: "NoaaWpcForecast.ProbabilitySnowAccumulationGE4", headerName: "12h probability snow accumulation (%) >= 4\"" ,

            cellStyle: (params) => {
                if (params.data?.Type != "Exit" && params.data?.NoaaWpcForecast.ProbabilitySnowAccumulationGE4 >= params.data?.RequiredProbabilitySnowAccumulationGE4) {
                    return {
                        backgroundColor: params.data?.Type.toLowerCase(),
                        color: 'white'
                    }
                }
                return null;
            },
             width: 100, filterType: "float" },
        { colId: "NoaaWpcForecast.ProbabilitySnowAccumulationGE6", field: "NoaaWpcForecast.ProbabilitySnowAccumulationGE6", headerName: "12h probability snow accumulation (%) >= 6\"" ,

            cellStyle: (params) => {
                if (params.data?.Type != "Exit" && params.data?.NoaaWpcForecast.ProbabilitySnowAccumulationGE6 >= params.data?.RequiredProbabilitySnowAccumulationGE6) {
                    return {
                        backgroundColor: params.data?.Type.toLowerCase(),
                        color: 'white'
                    }
                }
                return null;
            },
            width: 100, filterType: "float" },
        { colId: "NoaaWpcForecast.ProbabilitySnowAccumulationGE8", field: "NoaaWpcForecast.ProbabilitySnowAccumulationGE8", headerName: "12h probability snow accumulation (%) >= 8\"" ,

            cellStyle: (params) => {
                if (params.data?.Type != "Exit" && params.data?.NoaaWpcForecast.ProbabilitySnowAccumulationGE8 >= params.data?.RequiredProbabilitySnowAccumulationGE8) {
                    return {
                        backgroundColor: params.data?.Type.toLowerCase(),
                        color: 'white'
                    }
                }
                return null;
            },
            width: 100, filterType: "float" },
        { colId: "NoaaWpcForecast.ProbabilitySnowAccumulationGE12", field: "NoaaWpcForecast.ProbabilitySnowAccumulationGE12", headerName: "12h probability snow accumulation (%) >= 12\"" ,

            cellStyle: (params) => {
                if (params.data?.Type != "Exit" && params.data?.NoaaWpcForecast.ProbabilitySnowAccumulationGE12 >= params.data?.RequiredProbabilitySnowAccumulationGE12) {
                    return {
                        backgroundColor: params.data?.Type.toLowerCase(),
                        color: 'white'
                    }
                }
                return null;
            },
            width: 100, filterType: "float" },
        { colId: "NoaaWpcForecast.ProbabilitySnowAccumulationGE18", field: "NoaaWpcForecast.ProbabilitySnowAccumulationGE18", headerName: "12h probability snow accumulation (%) >= 18\"" ,

            cellStyle: (params) => {
                if (params.data?.Type != "Exit" && params.data?.NoaaWpcForecast.ProbabilitySnowAccumulationGE18 >= params.data?.RequiredProbabilitySnowAccumulationGE18) {
                    return {
                        backGroundColor: params.data?.Type.toLowerCase(),
                        color: 'white'
                    }
                }
                return null;
            },
            width: 100, filterType: "float" },
    ];

    refresh() {
        this.queryChange.emit(this.query);
        this.grid.refresh();
    }
}
