<div class="contentMargins">
    <page-title>{{'IBM MQ PUT'|translate}}</page-title>
    <form class="form" #form="ngForm" (ngSubmit)="send()">
        <label>
            <span class="caption">{{'Load configuration'|translate}}</span>
            <select [(ngModel)]="configuration" name="configuration" (change)="selectConfiguration()">
                <option *ngFor="let option of configurations|orderBy:'Name'" [ngValue]="option">{{option.Name}}</option>
            </select>
        </label>
        <br />
        <br />
        <label>
            <textarea required [(ngModel)]="query.Message" style="width: 300px; height: 200px;" name="query.Message"></textarea>
            <br /><br />
            <span class="caption">{{'Message'|translate}}</span>
            <file-as-data [(ngModel)]="query.Message" name="query.Message"></file-as-data>
        </label>
        <br />
        <br />
        <label>
            <span class="caption">{{'Hostname/IP'|translate}}</span>
            <input type="text" required [(ngModel)]="query.Hostname" name="hostname"/>
        </label>
        <label>
            <span class="caption">{{'Port'|translate}}</span>
            <input type="number" required min="1" max="65535" step="1" [(ngModel)]="query.Port" name="port" />
        </label>
        <label>
            <span class="caption">{{'Queue manager'|translate}}</span>
            <input type="text" [(ngModel)]="query.QueueManager" name="queueManager"/>
        </label>
        <label>
            <span class="caption">{{'Channel'|translate}}</span>
            <input type="text" required [(ngModel)]="query.Channel" name="channel" />
        </label>
        <label>
            <span class="caption">{{'In queue'|translate}}</span>
            <input type="text" required [(ngModel)]="query.InQueue" name="inQueue"/>
        </label>
        <label>
            <span class="caption">{{'Out queue'|translate}}</span>
            <input type="text" required [(ngModel)]="query.OutQueue" name="outQueue"/>
        </label>
        <label>
            <span class="caption">{{'SSL cipher spec'|translate}}</span>
            <input type="text" [(ngModel)]="query.SSLCipherSpec" name="sslCipherSpec"/>
        </label>
        <label>
            <span class="caption">{{'SSL key repository directory'|translate}}</span>
            <input type="text" [(ngModel)]="query.SSLKeyRepository" name="sslKeyRepository"/>
        </label>
        <label>
            <span class="caption">{{'Username'|translate}}</span>
            <input type="text" [(ngModel)]="query.Username" name="username"/>
        </label>
        <label>
            <span class="caption">{{'Password'|translate}}</span>
            <input type="text" [(ngModel)]="query.Password" name="password"/>
        </label>
        <br />
        <input type="submit" [disabled]="!form.valid" [value]="'Send'|translate" />
    </form>
    <div class="result" *ngIf="!!result">
        <h2>{{'Result'|translate}}</h2>
        <pre>{{result.Result}}</pre>
    </div>
</div>
