<form #changePasswordForm="ngForm" (submit)="changePassword()" class="contentMargins">
    <page-title>{{'User settings'|translate}}</page-title>
    <fieldset class="form">
        <h2>{{'Update password'|translate}}</h2>
        <h4 style="margin-top: 0;">{{'Please enter your old and new password'|translate}}</h4>
        <label>
            <span class="caption">{{'Old password'|translate}}</span>
            <input type="password" required class="form-control" [(ngModel)]="currentPassword" name="currentPassword">
        </label>
        <label>
            <span class="caption">{{'New password'|translate}}</span>
            <input type="password" required class="form-control" [(ngModel)]="newPassword" name="newPassword">
        </label>
        <label>
            <span class="caption">{{'Repeat password'|translate}}</span>
            <input type="password" required class="form-control" [(ngModel)]="repeatPassword" name="repeatPassword" [password-match]="newPassword">
        </label>
        <br />
        <label>
            <input type="submit" class="btn btn-info" [disabled]="!changePasswordForm.form.valid" value="{{'Change password'|translate}}" />
        </label>
    </fieldset>
</form>
