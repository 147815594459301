<page-title>{{('METAR client weather configuration'|translate) + ': ' + item.Name}}</page-title>
<form #ngForm="ngForm" (submit)="save()" class="noMargins" >
    <div class="toolbar">
        <a class="tab" (click)="switchTab('basicInfo')" [class.active]="tab == 'basicInfo'" [class.invalid]="!ngForm.form.controls.basicInfoForm?.valid">{{'Basic info'|translate}}</a>
        <input type="submit" [disabled]="!ngForm.form.valid || ngForm.form.pristine" class="green" value="{{'Save'|translate}}" />
        <input type="button" (click)="cancel()" value="{{'Cancel'|translate}}" />
        <input type="button" *ngIf="('DataChanges'|hasPermission)" (click)="viewHistory()" [disabled]="!item.Id" value="{{'View history'|translate}}" />
        <button type="button" (click)="exportProfile()" [disabled]="!item.Id"><span class="mdi mdi-download"></span>{{'Excel'|translate}}</button>
    </div>

    <fieldset class="contentMargins form" [disabled]="!('ConfigEditMETARWeatherCodeMappingProfiles'|hasPermission)" [hidden]="tab != 'basicInfo'" ngModelGroup="basicInfoForm">
        <label>
            <span class="caption">{{'Name'|translate}}</span>
            <input type="text" required [(ngModel)]="item.Name" name="item.Name" maxlength="100"/>
        </label>
        <label>
            <span class="caption">{{'Active'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.Active" name="item.Active"/>
        </label>
        <label>
            <span class="caption">{{'Use surface visibility if available'|translate}}</span>
            <input type="checkbox" [(ngModel)]="item.UseSurfaceVisibilityIfAvailable" name="item.UseSurfaceVisibilityIfAvailable"/>
        </label>
        <label>
            <span class="caption">{{'Comment'|translate}}</span>
            <textarea [(ngModel)]="item.Comment" name="item.Comment" rows="4" cols="50"></textarea>
        </label>
        <label>
            <span class="caption">{{'Agency table to use for snow visibility/intensity'|translate}}</span>
            <select required [(ngModel)]="item.SnowVisibilityRegulatoryAgency" name="item.SnowVisibilityRegulatoryAgency">
                <option *ngFor="let option of regulatoryAgencies|keys" [ngValue]="option">
                    {{regulatoryAgencies[option]}}
                </option>
            </select>
        </label>
        <label>
            <span class="caption">{{'METAR RegEx remapping profile'|translate}}</span>
            <select [(ngModel)]="item.MetarWeatherCodeRemappingProfileId" name="item.MetarWeatherCodeRemappingProfileId">
                <option [ngValue]="null">{{'None'|translate}}</option>
                <option *ngFor="let profile of metarWeatherCodeRemappingProfiles|orderBy:['Name']" [ngValue]="profile.Id">
                    {{profile.Name}}
                </option>
            </select>
        </label>

        <h3>{{'METAR single weather code mapping profiles'|translate}}</h3>

        <span class="field">
            <select [(ngModel)]="metarWeatherCodeMappingProfile" name="metarWeatherCodeMappingProfile">
                <option *ngFor="let profile of metarWeatherCodeMappingProfileNotEnabled(metarWeatherCodeMappingProfiles, item.MetarWeatherMappingProfileMetarWeatherCodeMappingProfiles)|orderBy:'Name'|orderBy:['Name']" [ngValue]="profile">
                    {{profile.Name}}
                </option>
            </select>
            <input type="button" class="green" *ngIf="('ConfigEditMETARWeatherMappingProfiles'|hasPermission)" (click)="addMetarWeatherCodeMappingProfile()" value="{{'Add profile'|translate}}" [disabled]="metarWeatherCodeMappingProfile == null" />
        </span>
        <br />

        <table class="table">
            <tr>
                <th>{{'#'|translate}}</th>
                <th>{{'Order'|translate}}</th>
                <th>{{'Name'|translate}}</th>
                <th></th>
            </tr>
            <tr *ngFor="let metarWeatherMappingProfileMetarWeatherCodeMappingProfile of item.MetarWeatherMappingProfileMetarWeatherCodeMappingProfiles; let i = index">
                <td>
                    {{metarWeatherMappingProfileMetarWeatherCodeMappingProfile.MetarWeatherCodeMappingProfileId}}
                </td>
                <td>
                    <input required type="number" step="1" min="0" [(ngModel)]="metarWeatherMappingProfileMetarWeatherCodeMappingProfile.Order" [name]="'metarWeatherMappingProfileMetarWeatherCodeMappingProfile[' + i + '].Order'" style="width: 60px;" />
                </td>
                <td>
                    {{(metarWeatherCodeMappingProfiles|findById:metarWeatherMappingProfileMetarWeatherCodeMappingProfile.MetarWeatherCodeMappingProfileId)?.Name}}
                </td>
                <td>
                    <a *ngIf="('ConfigEditMETARWeatherMappingProfiles'|hasPermission)" (click)="removeMetarWeatherMappingProfileMetarWeatherCodeMappingProfile(metarWeatherMappingProfileMetarWeatherCodeMappingProfile)" class="mdi mdi-delete"></a>
                </td>
            </tr>
        </table>

        <h3>{{'METAR weather combination profiles'|translate}}</h3>

        <span class="field">
            <select [(ngModel)]="metarWeatherTypeMappingProfile" name="metarWeatherTypeMappingProfile">
                <option *ngFor="let profile of metarWeatherTypeMappingProfileNotEnabled(metarWeatherTypeMappingProfiles, item.MetarWeatherMappingProfileMetarWeatherTypeMappingProfiles)|orderBy:'Name'|orderBy:['Name']" [ngValue]="profile">
                    {{profile.Name}}
                </option>
            </select>
            <input type="button" class="green" *ngIf="('ConfigEditMETARWeatherMappingProfiles'|hasPermission)" (click)="addMetarWeatherTypeMappingProfile()" value="{{'Add profile'|translate}}" [disabled]="metarWeatherTypeMappingProfile == null" />
        </span>
        <br />

        <table class="table">
            <tr>
                <th>{{'#'|translate}}</th>
                <th>{{'Order'|translate}}</th>
                <th>{{'Name'|translate}}</th>
                <th></th>
            </tr>
            <tr *ngFor="let metarWeatherMappingProfileMetarWeatherTypeMappingProfile of item.MetarWeatherMappingProfileMetarWeatherTypeMappingProfiles; let i = index">
                <td>
                    {{metarWeatherMappingProfileMetarWeatherTypeMappingProfile.MetarWeatherTypeMappingProfileId}}
                </td>
                <td>
                    <input required type="number" step="1" min="0" [(ngModel)]="metarWeatherMappingProfileMetarWeatherTypeMappingProfile.Order" [name]="'metarWeatherMappingProfileMetarWeatherTypeMappingProfile[' + i + '].Order'" style="width: 60px;" />
                </td>
                <td>
                    {{(metarWeatherTypeMappingProfiles|findById:metarWeatherMappingProfileMetarWeatherTypeMappingProfile.MetarWeatherTypeMappingProfileId)?.Name}}
                </td>
                <td>
                    <a *ngIf="('ConfigEditMETARWeatherMappingProfiles'|hasPermission)" (click)="removeMetarWeatherMappingProfileMetarWeatherTypeMappingProfile(metarWeatherMappingProfileMetarWeatherTypeMappingProfile)" class="mdi mdi-delete"></a>
                </td>
            </tr>
        </table>
    </fieldset>
</form>
